import { ListingsConstants } from "constants/Listings.constants";
import { ListingsClass } from "enums/Listings/ListingClass.enum";
import { ListingStatus } from "enums/Listings/ListingStatus.enum";
import millify from "millify";
import { Listing } from "models/listings/listing.model";
import { store } from "redux/store";
import { numberWithCommas, renderCurrencySign } from "./functions";
import moment from "moment";

export class ListingsHelper {
  static convertPercentageToPrice(percentage: number, type: "sale" | "lease") {
    if (percentage === 0 || percentage === 100) {
      return null;
    }

    switch (type) {
      case "sale":
        return percentage * 50000;
      case "lease":
        return percentage * 100;
      default:
        return percentage;
    }
  }

  static convertPriceToPercentage(
    price: number | null,
    type: "sale" | "lease",
    bound: "lower" | "upper"
  ) {
    if (price === null) {
      return bound === "lower" ? 0 : 100;
    }
    switch (type) {
      case "sale":
        return price / 50000;
      case "lease":
        return price / 100;
      default:
        return price;
    }
  }

  static convertDateFilterToFilterOption() {
    const filters = store.getState().filters.value;
    const {
      minListDate,
      maxListDate,
      minUpdatedOn,
      maxUpdatedOn,
      minSoldDate,
      maxSoldDate,
    } = filters;

    if (minSoldDate && !maxSoldDate) {
      const numberOfDaysFromCurrentDate = Math.floor(
        (new Date().getTime() - new Date(minSoldDate).getTime()) /
          (1000 * 60 * 60 * 24)
      );
      return ListingsConstants.UnavailableListingsDateFilterOptions.find(
        (option) => option.value === numberOfDaysFromCurrentDate
      )?.value;
    }

    if (minSoldDate && maxSoldDate) {
      const yearOfMinSoldDate = new Date(minSoldDate).getFullYear();
      return ListingsConstants.UnavailableListingsDateFilterOptions.find(
        (option) => option.value === yearOfMinSoldDate
      )?.value;
    }

    if (minUpdatedOn && !maxUpdatedOn) {
      const numberOfDaysFromCurrentDate = Math.floor(
        (new Date().getTime() - new Date(minUpdatedOn).getTime()) /
          (1000 * 60 * 60 * 24)
      );
      return ListingsConstants.UnavailableListingsDateFilterOptions.find(
        (option) => option.value === numberOfDaysFromCurrentDate
      )?.value;
    }

    if (minUpdatedOn && maxUpdatedOn) {
      const yearOfMinUpdatedOn = new Date(minUpdatedOn).getFullYear();
      return ListingsConstants.UnavailableListingsDateFilterOptions.find(
        (option) => option.value === yearOfMinUpdatedOn
      )?.value;
    }

    if (minListDate && !maxListDate) {
      const numberOfDaysFromCurrentDate = Math.floor(
        (new Date().getTime() - new Date(minListDate).getTime()) /
          (1000 * 60 * 60 * 24)
      );

      return ListingsConstants.ActiveListingsDateFilterOptions.find(
        (option) => option.value === numberOfDaysFromCurrentDate
      )?.value;
    }

    if (maxListDate && !minListDate) {
      const numberOfDaysFromCurrentDate = Math.floor(
        (new Date().getTime() - new Date(maxListDate).getTime()) /
          (1000 * 60 * 60 * 24)
      );
      return ListingsConstants.ActiveListingsDateFilterOptions.find(
        (option) => option.value === -numberOfDaysFromCurrentDate
      )?.value;
    }

    return 0;
  }

  static getListingDateFilterOptions() {
    const filters = store.getState().filters.value;
    const status: "A" | "U" = filters.status;

    if (status === "A") {
      return ListingsConstants.ActiveListingsDateFilterOptions;
    } else {
      return ListingsConstants.UnavailableListingsDateFilterOptions;
    }
  }

  static getListingPropertyTypesByClass() {
    const filters = store.getState().filters.value;
    const listingsClass: ListingsClass[] = filters.class;

    const types: { label: string; value: string }[] = [];

    if (listingsClass.includes(ListingsClass.Residential)) {
      types.push(...ListingsConstants.ResidentialPropertyTypes);
    }

    if (listingsClass.includes(ListingsClass.Condo)) {
      types.push(...ListingsConstants.CondoPropertyTypes);
    }

    if (listingsClass.includes(ListingsClass.Commercial)) {
      types.push(...ListingsConstants.CommercialPropertyTypes);
    }

    return types;
  }

  static getListingPropertyTypeLabel() {
    const filters = store.getState().filters.value;
    const selectedValue: string[] = filters.propertyType;
    const allTypes = ListingsHelper.getListingPropertyTypesByClass();
    if (selectedValue.length === 0) {
      return "Property type";
    } else {
      if (selectedValue.length === 1) {
        return allTypes.find((type) => type.value === selectedValue[0])?.label;
      } else {
        const firstType = allTypes.find(
          (type) => type.value === selectedValue[0]
        )?.label;

        return `${firstType} +${selectedValue.length - 1}`;
      }
    }
  }

  static getListingPriceLabel() {
    const filters = store.getState().filters.value;
    const minPrice = filters.minPrice;
    const maxPrice = filters.maxPrice;

    if (minPrice && maxPrice) {
      return `$${millify(+minPrice)} - $${millify(+maxPrice)}`;
    } else if (minPrice) {
      return `$${millify(+minPrice)}+`;
    } else if (maxPrice) {
      return `$${millify(+maxPrice)}+`;
    } else {
      return "Price";
    }
  }

  static getListingNumberOfBedsLabel() {
    const filters = store.getState().filters.value;
    const minBeds = filters.minBeds;
    const maxBeds = filters.maxBeds;

    if (minBeds && maxBeds) {
      return `${minBeds} - ${maxBeds} beds`;
    } else if (minBeds) {
      return `${minBeds}+ beds`;
    } else if (maxBeds) {
      return `${maxBeds}+ beds`;
    } else {
      return "Beds";
    }
  }

  static getListingAddress(
    address: any,
    accurate = false,
    isNavigation = false
  ) {
    let fullAddress = "";
    if (address.unitNumber && !accurate && !isNavigation)
      fullAddress += `${address.unitNumber} - `;
    if (address.streetNumber) fullAddress += `${address.streetNumber} `;
    if (address.streetName) fullAddress += `${address.streetName} `;
    if (address.streetSuffix) fullAddress += `${address.streetSuffix} `;
    if (address.streetDirection) fullAddress += `${address.streetDirection} `;
    if (accurate && address.zip) fullAddress += `${address.zip}`;

    return fullAddress.trim();
  }

  static getListingThumbnail(listing: Listing, size = "small") {
    const image = listing.images ? listing.images[0] : "";

    return image ? `https://cdn.repliers.io/${image}?class=${size}` : "";
  }

  static getListingFormattedPrice(listing: Listing) {
    let price: number = 0;

    if (listing.status === ListingStatus.Active) {
      price = +listing.listPrice;
    } else {
      const soldPrice = listing.soldPrice ? +listing.soldPrice : 0;

      price = soldPrice ? soldPrice : +listing.listPrice;
    }

    const currentClient = store.getState().client.currentClient;
    const clientCurrency = currentClient?.currency;
    const rates = store.getState().currency.rates;

    return clientCurrency
      ? `${renderCurrencySign(clientCurrency, false)}${millify(
          price * rates[clientCurrency.toUpperCase()]
        )}`
      : `${renderCurrencySign(clientCurrency, false)}${numberWithCommas(
          price
        )}`;
  }

  static getListingFormattedPriceDifference(listing: Listing) {
    const currentClient = store.getState().client.currentClient;
    const clientCurrency = currentClient?.currency;
    const rates = store.getState().currency.rates;

    const soldPrice = listing.soldPrice ? +listing.soldPrice : 0;
    const listPrice = +listing.listPrice;

    const difference = listPrice - soldPrice;

    return clientCurrency
      ? `${renderCurrencySign(clientCurrency, false)}${millify(
          difference * rates[clientCurrency.toUpperCase()]
        )}`
      : `${renderCurrencySign(clientCurrency, false)}${numberWithCommas(
          difference
        )}`;
  }

  static getListingFormattedDate(listing: Listing) {
    if (listing.status === ListingStatus.Active) {
      return moment().diff(moment(listing.listDate), "days");
    } else {
      return moment().diff(moment(listing.soldDate), "days");
    }
  }

  static getListingPropertyType = (type: string) => {
    const allTypes = [
      ...ListingsConstants.ResidentialPropertyTypes,
      ...ListingsConstants.CondoPropertyTypes,
      ...ListingsConstants.CommercialPropertyTypes,
    ];
    const t = allTypes.find((t) => t.value === type);
  
    if (t) {
      return t.label;
    } else return type;
  };
}
