import MapButton from "../MapButton/map-button.component";
import { ReactComponent as DrawIcon } from "../../../../assets/svg/draw.svg";
import { ReactComponent as SchoolIcon } from "../../../../assets/svg/school.svg";
import { ReactComponent as SatelliteIcon } from "../../../../assets/svg/satellite-view.svg";
import { ReactComponent as MapIcon } from "../../../../assets/svg/map-view.svg";
import { useSelector } from "react-redux";
import { selectSchools } from "../../../../redux/schools/schools.selectors";
import { selectDrawMode } from "../../../../redux/ui/ui.selectors";
import styles from "./map-buttons-top.module.scss";

const MapButtonsTop = ({
  onChangeMapTyoe,
  onStartDrawing,
  isDrawingDisabled,
  mapType,
}: {
  onChangeMapTyoe: () => void;
  onStartDrawing: () => void;
  isDrawingDisabled: boolean;
  mapType: "roadmap" | "satellite";
}) => {
  const schools = useSelector(selectSchools);
  const drawMode = useSelector(selectDrawMode);
  const handleSchoolsFilter = () => {};

  return (
    <div className={styles.container}>
      <MapButton
        icon={<SchoolIcon />}
        onClick={handleSchoolsFilter}
        active={schools.active}
      />
      <MapButton
        icon={<DrawIcon />}
        onClick={onStartDrawing}
        active={drawMode.active}
        disabled={isDrawingDisabled}
      />
      <MapButton
        icon={mapType === "roadmap" ? <SatelliteIcon /> : <MapIcon />}
        onClick={onChangeMapTyoe}
      />
    </div>
  );
};

export default MapButtonsTop;
{
  /* <div
className={`${styles.button} ${schoolsMode && styles.enabledButton}`}
onClick={schoolsHandler}>
<SchoolIcon
  className={schoolsMode ? styles.enabledIcon : styles.icon}
/>
</div>

<div
className={`${styles.button} ${active && styles.enabledButton}`}
onClick={drawBtnHandler}>
<DrawIcon
  className={`${
    disableDrawing
      ? styles.disabledIcon
      : active
      ? styles.enabledIcon
      : styles.icon
  }`}
/>
</div>
<div className={styles.button} onClick={mapTypeHandler}>
{mapType === "roadmap" ? (
  <SatelliteIcon className={styles.icon} />
) : (
  <MapIcon className={styles.icon} />
)}
</div> */
}
