import { IonRouterLink } from "@ionic/react";
import moment from "moment";
import Card from "../../../../../components/Card/card.component";
import { capitalize } from "../../../../../utils/functions";
import styles from "./tour-card.module.scss";
import { TourService } from "../../../../../services/tourService";

const TourCard = ({ item }) => {

  return (
    <IonRouterLink
      routerLink={`/tabs/tours/${item.id}`}
      routerDirection="forward"
      className={styles.container}>
      <Card style={{ padding: "1.5rem" }}>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.details}>
          <div className={styles.row}>
            <span>Date</span>
            <span className={styles.bold}>{item.date}</span>
          </div>
          <div className={styles.row}>
            <span>Client</span>
            <span className={styles.bold}>
              {TourService.getTourClient(item).displayName}
            </span>
          </div>
          <div className={styles.row}>
            <span>Total time</span>
            <span className={styles.bold}>
              {item.startTime
                ? moment(item.startTime, "HH:mm").format("hh:mm A")
                : "-"}
              -
              {item.endTime
                ? moment(item.endTime, "HH:mm").format("hh:mm A")
                : "-"}
            </span>
          </div>
          <div className={styles.row}>
            <span>Primary Agent</span>
            <span className={styles.bold}>
              {TourService.getTourPrimaryAgent(item).displayName}
            </span>
          </div>
          {TourService.getTourShowingAgents(item)
            .map((showingAgent, id) => (
              <div key={id} className={styles.row}>
                <span>Showing agent</span>
                <span className={styles.bold}>{showingAgent.displayName}</span>
              </div>
            ))}
          <div className={styles.row}>
            <span># of stops</span>
            <span className={styles.bold}>{item.tourItems.items.length}</span>
          </div>
          <div className={`${styles.row} ${styles.lastRow}`}>
            <span>Status of tour</span>
            <span className={`${styles.status} ${styles[item.status]}`}>
              {capitalize(item.status)}
            </span>
          </div>
        </div>
      </Card>
    </IonRouterLink>
  );
};

export default TourCard;
