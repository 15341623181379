import styles from "./pre-deposit.structure.module.scss";

const PreDepositStructure = ({ project }) => {
  if (!project) return <></>;
  const { depositStructure, incentives } = project;

  const d = depositStructure ? JSON.parse(depositStructure) : null;
  const i = incentives ? JSON.parse(incentives) : null;

  return (
    <div className={styles.preDepositStructure}>
      <div className={styles.infos}>
        <div className={styles.details}>
          <div>
            <span className={styles.bold}>Deposit Structure</span>
          </div>

          {d && d.length > 0 ? (
            d.map((item, index) => (
              <div key={item.id}>
                <span>{item.value}</span>
              </div>
            ))
          ) : (
            <div>Not available</div>
          )}
        </div>
        <div className={styles.details}>
          <div style={{ marginTop: "2rem" }}>
            <span className={styles.bold}>Incentives</span>
          </div>
          <div style={{ border: "none" }}>
            {i && i.length > 0 ? (
              i.map((item, index) => (
                <div key={index}>
                  <span>{item.value}</span>
                </div>
              ))
            ) : (
              <div>Not available </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreDepositStructure;
