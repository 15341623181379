import { numberWithCommas } from "../../../../../../../../utils/functions";
import styles from "./pre-overview.module.scss";

const PreOverview = ({ project }) => {
  const {
    name,
    builder,
    province,
    city,
    streetName,
    streetNumber,
    startPrice,
    endPrice,
    occupancy,
  } = project;
  return (
    <div className={styles.preOverview}>
      <div className={styles.infos}>
        <div className={styles.details}>
          <div>
            <span className={styles.bold}>Name of project</span>
            <span>{name}</span>
          </div>

          <div>
            <span className={styles.bold}>Address</span>
            <span>{`${streetNumber} ${streetName}, ${city}, ${province}`}</span>
          </div>

          <div>
            <span className={styles.bold}>Builder / developer</span>
            <span>{builder ? builder.name : "-"}</span>
          </div>

          <div>
            <span className={styles.bold}>Price</span>
            <span>
              From {startPrice ? `$${numberWithCommas(startPrice)}` : "-"} to{" "}
              {endPrice ? `$${numberWithCommas(endPrice)}` : "-"}
            </span>
          </div>

          <div>
            <span className={styles.bold}>Occupancy</span>
            <span>{occupancy ? occupancy : "-"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreOverview;
