import { takeLatest, call, put, all } from "redux-saga/effects";
import { LocationActionTypes } from "./locations.types";
import {
  fetchLocationsSuccess,
  fetchLocationsFailure,
} from "./locations.actions";
import { getLocations } from "../../api/repliers";

export function* fetchLocationsAsync() {
  try {
    const locations = yield call(getLocations);

    yield put(fetchLocationsSuccess(locations));
  } catch (error) {
    yield put(fetchLocationsFailure(error.message));
  }
}
export function* fetchLocationsStart() {
  yield takeLatest(
    LocationActionTypes.FETCH_LOCATIONS_START,
    fetchLocationsAsync
  );
}
export function* locationsSagas() {
  yield all([call(fetchLocationsStart)]);
}
