import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { createFilter } from "redux-persist-transform-filter";
import localforage from "localforage";
import mapReducer from "./map/map.reducer";
import filtersReducer from "./filters/filters.reducer";
import locationsReducer from "./locations/locations.reducer";
import userReducer from "./user/user.reducer";
import currencyReducer from "./currency/currency.reducer";
import clientReducer from "./client/client.reducer";
import uiReducer from "./ui/ui.reducer";
import schoolsReducer from "./schools/schools.reducer";
import tourReducer from "./tour/tour.reducer";

const persistConfig = {
  key: "root",
  storage: localforage,
  // whitelist: ["locations", "user", "client"],
  whitelist: ["locations", "user"],
  transforms: [createFilter("user", ["currentUser", "isAgent"])],
};

const rootReducer = combineReducers({
  map: mapReducer,
  filters: filtersReducer,
  locations: locationsReducer,
  currency: currencyReducer,
  user: userReducer,
  client: clientReducer,
  tours: tourReducer,
  ui: uiReducer,
  schools: schoolsReducer,
});

export default persistReducer(persistConfig, rootReducer);
