/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { getPlatforms } from "@ionic/react";

const awsmobile = {
  aws_project_region: "ca-central-1",
  aws_cloud_logic_custom: [
    {
      name: "AdminQueries",
      endpoint: "https://8h672huhh9.execute-api.ca-central-1.amazonaws.com/lsg",
      region: "ca-central-1",
    },
    {
      name: "ettieREST",
      endpoint: "https://r23ptd3ls0.execute-api.ca-central-1.amazonaws.com/lsg",
      region: "ca-central-1",
    },
  ],
  aws_appsync_graphqlEndpoint:
    "https://vg6fykj5pndi7iutgcysoysfji.appsync-api.ca-central-1.amazonaws.com/graphql",
  aws_appsync_region: "ca-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_cognito_identity_pool_id:
    "ca-central-1:c92b50c2-3db8-4a47-9582-aaf8a121b108",
  aws_cognito_region: "ca-central-1",
  aws_user_pools_id: "ca-central-1_YbCUmRasz",
  aws_user_pools_web_client_id: "5tlear54fockoigmd4vfiugl2p",
  oauth: {
    domain: "aecorn-lsg.auth.ca-central-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: getPlatforms().includes("capacitor")
      ? "aecorealty://develop.dil6cxt4xelr.amplifyapp.com/"
      : "https://www.develop.dil6cxt4xelr.amplifyapp.com/",
    redirectSignOut: getPlatforms().includes("capacitor")
      ? "aecorealty://develop.dil6cxt4xelr.amplifyapp.com/"
      : "https://www.develop.dil6cxt4xelr.amplifyapp.com/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL", "FAMILY_NAME", "GIVEN_NAME"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket: "ettieusermedia121413-lsg",
  aws_user_files_s3_bucket_region: "ca-central-1",
  aws_dynamodb_all_tables_region: "ca-central-1",
  aws_dynamodb_table_schemas: [
    {
      tableName: "utils-lsg",
      region: "ca-central-1",
    },
  ],
  aws_cognito_login_mechanisms: ["EMAIL", "FACEBOOK", "GOOGLE", "APPLE"],
};

export default awsmobile;
