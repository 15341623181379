import { useState, useEffect } from "react";
import { IonIcon, IonButton } from "@ionic/react";
import {
  closeOutline,
  arrowBackOutline,
  personAddOutline,
  personOutline,
} from "ionicons/icons";
import styles from "./tour-attendees.module.scss";
import Button from "../../../../../components/Form/Button/button.component";
import { getAgents, getClients } from "../../../../../amplify/graphql.utils";
import IsVisibleTag from "../../../../../components/IsVisibleTag/is-visible-tag.component";
import SearchableSelect from "../../../../../components/Form/SearchableSelect/searchable-search.component";
import { UserService } from "../../../../../services/userService";

const TourAttendees = ({ tour, back, next, reset }) => {
  const [client, setClient] = useState(tour.client);
  const [guests, setGuests] = useState(tour.guests || []);
  const [leadAgent, setLeadAgent] = useState(tour.leadAgent);
  const [showingAgents, setShowingAgents] = useState(tour.showingAgents || []);
  const [agents, setAgents] = useState([]);
  const [clientsList, setClientsList] = useState([]);

  useEffect(() => {
    const fetchAllClinets = async () => {
      try {
        const clients = await UserService.getClientUsers();
        setClientsList(clients);
      } catch (err) {
        console.log(err);
      }
    };

    const fetchAgents = async () => {
      try {
        const agents = await UserService.getAgentUsers();
        setAgents(agents);
      } catch (err) {
        console.log(err);
      }
    };

    fetchAllClinets();
    fetchAgents();
  }, [tour]);

  const handleClear = () => {
    setClient();
    setGuests([]);
    setAgents([]);
    setShowingAgents([]);
  };

  const handleSubmit = () => {
    next({
      client,
      guests,
      leadAgent,
      showingAgents,
    });
  };

  const handleAgentsChange = async (val) => {
    setAgents(await getAgents(val));
  };

  const handleChange = async (val) => {
    setClientsList(await getClients(val));
  };

  return (
    <div className={styles.tourAttendees}>
      <div className={styles.details}>
        <div className={styles.label}>Tour attendees</div>
        <IsVisibleTag isVisible={true} />
      </div>
      <div className={styles.attendees}>
        <div className={styles.rows}>
          <SearchableSelect
            title="Select the main client"
            type="radio"
            items={clientsList && clientsList.filter((c) => !c.deactivated)}
            selected={client?.id}
            setSelected={setClient}
            label={client ? `Client: ${client.displayName}` : "Select client"}
            onSave={(client) => setClient(client)}
            onChange={handleChange}
            icon={client ? personOutline : personAddOutline}
            style={{ "--background": "white" }}
          />

          {client && (
            <SearchableSelect
              title="Select additional guests"
              type="checkbox"
              items={
                clientsList &&
                clientsList
                  .filter((c) => !c.deactivated)
                  .filter((c) => c.id !== client.id)
              }
              selected={guests?.map(g => g.id)}
              setSelected={setGuests}
              onChange={handleChange}
              label="Add additional guests"
              icon={personAddOutline}
            />
          )}
        </div>
        {guests.length > 0 && (
          <div className={styles.guests}>
            {guests
              .filter((g) => g !== null)
              .map((g, i) => (
                <div className={styles.guest} key={i}>
                  <IonIcon className={styles.icon} icon={personOutline} />
                  <span className={styles.label}>
                    Additional guest: {g.displayName}
                  </span>
                  <IonIcon
                    className={styles.closeIcon}
                    icon={closeOutline}
                    onClick={() =>
                      setGuests(guests.filter((guest) => guest.id !== g.id))
                    }
                  />
                </div>
              ))}
          </div>
        )}

        <div className={styles.rows}>
          <SearchableSelect
            title="Select showing agents"
            type="checkbox"
            required={true}
            items={agents.filter((a) => !a.deactivated)}
            selected={showingAgents?.map((a) => a.id)}
            setSelected={setShowingAgents}
            customIdentifier="userId"
            onChange={handleAgentsChange}
            label={
              showingAgents.length
                ? `${showingAgents.length} showing agents selected`
                : "Add showing agents"
            }
            icon={personAddOutline}
          />
        </div>
      </div>
      <Button
        title="Complete tour set up"
        type="green"
        style={{
          border:
            client && showingAgents.length
              ? "1px solid #1F7A60"
              : "1px solid #e5e5e5",
          width: "100%",
          height: "5rem",
          marginTop: "2rem",
        }}
        disabled={!showingAgents.length}
        onClick={handleSubmit}
      />
      <div className={styles.btns}>
        <IonButton
          fill="clear"
          className={`${styles.btn} ${styles.back}`}
          onClick={() => back()}>
          <IonIcon icon={arrowBackOutline} className={styles.icon} />
          Back
        </IonButton>

        <IonButton
          fill="clear"
          className={`${styles.btn} ${styles.close}`}
          onClick={handleClear}>
          <IonIcon icon={closeOutline} className={styles.icon} />
          Clear
        </IonButton>
      </div>
    </div>
  );
};

export default TourAttendees;
