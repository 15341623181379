import { IonIcon } from "@ionic/react";
import { useDispatch } from "react-redux";
import { shareSocialOutline } from "ionicons/icons";
import numeral from "numeral";
import { openAuthModal, openModal } from "../../../../redux/ui/ui.actions";
import Button from "../../../../components/Form/Button/button.component";
import styles from "./pre-right-section.module.scss";
import { numberWithCommas } from "../../../../utils/functions";

const PreRightSection = ({ project }) => {
  const dispatch = useDispatch();

  const data =
    project.name +
    " at " +
    project.streetNumber +
    " " +
    project.streetName +
    ", " +
    project.city +
    ", starting from " +
    numeral(project.startPrice).format("$1,000,000");

  const shareListingHandler = () => {
    dispatch(
      openModal({
        current: "shareListing",
        data: {
          mlsNumber: project.id,
          text: data,
          listing: project,
          isPreCon: true,
        },
      })
    );
  };

  const preRequestFormHandler = () => {
    dispatch(
      openModal({
        current: "preRequestForm",
        data: { isUnit: false, info: data },
      })
    );
  };

  return (
    <div className={styles.preRightSection}>
      <Button
        title="Share this project"
        onClick={shareListingHandler}
        type="grayLight"
        style={{
          height: "5rem",
          width: "25.5rem",
          boxShadow: "1px 1px 5px #DBDBDB",
          border: "1px solid #DBDBDB",
          borderRadius: "25px",
          backgroundColor: "white",
          color: "#363636",
          marginBottom: "2rem",
          display: "grid",
          gridTemplateColumns: "max-content auto",
          justifyContent: "start",
        }}
      >
        <IonIcon
          icon={shareSocialOutline}
          style={{
            height: "2.4rem",
            widht: "2.4rem",
            color: "#363636",
            marginLeft: "1rem",
          }}
        />
      </Button>

      <div className={styles.info}>
        <div className={styles.status}>For sale</div>
        {project.startPrice !== 0 && (
          <div className={styles.from}>Starting from</div>
        )}
      </div>

      {project.startPrice !== 0 && (
        <div className={styles.price}>
          {numberWithCommas(project.startPrice, true)}
        </div>
      )}

      {project.startPrice === 0 && (
        <div className={styles.noPrice}>Coming soon!</div>
      )}

      <Button
        title="Interested?"
        type="green"
        border={true}
        style={{
          height: "5rem",
          width: "25.5rem",
          marginBottom: "2rem",
        }}
        onClick={preRequestFormHandler}
      />
    </div>
  );
};

export default PreRightSection;
