/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { getPlatforms } from "@ionic/react";

const awsmobile = {
  aws_project_region: "ca-central-1",
  aws_cloud_logic_custom: [
    {
      name: "AdminQueries",
      endpoint:
        "https://cjq210hepj.execute-api.ca-central-1.amazonaws.com/release",
      region: "ca-central-1",
    },
    {
      name: "ettieREST",
      endpoint:
        "https://rvxf7459xj.execute-api.ca-central-1.amazonaws.com/release",
      region: "ca-central-1",
    },
  ],
  aws_appsync_graphqlEndpoint:
    "https://4rk6sqdkkjd7xln7vz7cedwnny.appsync-api.ca-central-1.amazonaws.com/graphql",
  aws_appsync_region: "ca-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-fzpnx5ymprhg5dqej3auefo72a",
  aws_cognito_identity_pool_id:
    "ca-central-1:c38d9f84-6c54-41d7-bd2e-53c6d2051c2a",
  aws_cognito_region: "ca-central-1",
  aws_user_pools_id: "ca-central-1_98csCF0HM",
  aws_user_pools_web_client_id: "4tf57p48ov5qjsni71ubjp31ke",
  oauth: {
    domain: "auth.aecorn.ca",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: getPlatforms().includes("capacitor")
      ? "aecorealty://aecorn.ca/"
      : "https://www.aecorn.ca/",
    redirectSignOut: getPlatforms().includes("capacitor")
      ? "aecorealty://aecorn.ca/"
      : "https://www.aecorn.ca/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE", "APPLE"],
  aws_cognito_signup_attributes: ["EMAIL", "FAMILY_NAME", "GIVEN_NAME"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket: "ettieusermediadff80-release",
  aws_user_files_s3_bucket_region: "ca-central-1",
  aws_dynamodb_all_tables_region: "ca-central-1",
  aws_dynamodb_table_schemas: [
    {
      tableName: "utils-release",
      region: "ca-central-1",
    },
  ],
  aws_cognito_login_mechanisms: ["EMAIL", "FACEBOOK", "GOOGLE", "APPLE"],
};

export default awsmobile;
