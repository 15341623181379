import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { chevronForwardOutline } from "ionicons/icons";
import { IonIcon, IonLoading, IonSkeletonText } from "@ionic/react";
import styles from "./tour-details.module.scss";
import ShowingDetails from "./component/ShowingDetails/showing-details.component";
import Overview from "./component/Overview/overview.component";
import Photos from "../../components/Photos/photos.component";
import Notes from "../../components/Notes/notes.component";
import Recordings from "../../components/Recordings/recordings.component";
import LoadingFullPage from "../../components/Loading/loading-full-page.component";
import { buildAddress, hapticsImpact } from "../../utils/functions";
import { updateToast } from "../../redux/ui/ui.actions";
import withAuthentication from "../../HOC/withAuthentication/with-authentication";
import { TourItemService } from "../../services/tourItemService";
import { TourItemMediaService } from "../../services/tourItemMediaService";
import { ListingService } from "../../services/listingService";
import { AuthenticationService } from "../../services/authenticationService";
import { TourService } from "../../services/tourService";
import { TourRole } from "../../API";

const TourDetails = ({
  match: {
    params: { tourItemId },
  },
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [tour, setTour] = useState();
  const [tourItem, setTourItem] = useState();
  const [listing, setListing] = useState();
  const [reviews, setReviews] = useState();
  const [showPhotos, setShowPhotos] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showRecordings, setShowRecordings] = useState(false);
  const [playing, setPlaying] = useState();

  const refresh = async () => {
    const item = await TourItemService.getTourItemById(tourItemId);
    setTourItem(item);
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const isUserAgent = await AuthenticationService.isCurrentUserAgent();
        if (!isUserAgent) {
          history.replace("/tours");
          return;
        }
        const item = await TourItemService.getTourItemById(tourItemId);
        setTourItem(item);

        const client = TourService.getTourClient(item.tour);
        const guests = await TourService.getTourGuests(item.tour);
        const clients = [client, ...guests];

        const allReviews = [];
        clients.forEach((client) => {
          const review = item.reviews.items.find((r) => r.userId === client.id);
            allReviews.push({
              name: client.givenName,
              rate: review ? review.rate : undefined,
            });
        });
        setReviews([...allReviews]);
        setTour(item.tour);
        const listing = await ListingService.getListingByMlsNumber(item.mlsNumber);
        setListing(listing);
      } catch (err) {
        console.log(err);
        history.replace("/tours");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [history, tourItemId]);

  const updateDetails = async (updates) => {
    setLoading(true);
    try {
      const item = await TourItemService.updateTourItem(updates);
      setTourItem(item);
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Showing successfully updated.",
        })
      );
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
    setLoading(false);
  };

  const removeShowing = async () => {
    setLoading(true);
    try {
      await TourItemService.deleteTourItem(tourItem);
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Showing successfully removed.",
        })
      );
      history.replace(`/tours/${tour.id}`);
    } catch (err) {
      console.log(err);
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
    setLoading(false);
  };

  const handleDeletePhoto = async (photo) => {
    try {
      await hapticsImpact();
      setUploading(true);
      await TourItemMediaService.deletePhoto(photo);
      refresh(tourItem.id);
      setUploading(false);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };

  const handleDeleteRecording = async (recording) => {
    try {
      await hapticsImpact();
      setUploading(true);
      await TourItemMediaService.deleteRecording(recording);
      refresh(tourItem.id);
      setUploading(false);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };

  return (
    <div className={styles.tourDetails}>
      <IonLoading isOpen={loading} />
      {!tour || !tourItem || !listing ? (
        <div className={styles.loading}>
          <LoadingFullPage />
        </div>
      ) : (
        <>
          <div className={styles.nav}>
            <span onClick={() => history.push("/tours")}>All tours</span>
            <span className={styles.icon}>
              <IonIcon icon={chevronForwardOutline} />
            </span>
            <span onClick={() => history.push(`/tours/${tour.id}`)}>
              {tour ? (
                tour.title
              ) : (
                <IonSkeletonText style={{ width: "8rem" }} animated={true} />
              )}
            </span>
            <span className={styles.icon}>
              <IonIcon icon={chevronForwardOutline} />
            </span>
            <span className={styles.bold}>
              {listing ? (
                buildAddress(listing.address)
              ) : (
                <IonSkeletonText style={{ width: "12rem" }} animated={true} />
              )}
            </span>
          </div>
          <div className={styles.details}>
            <div>
              <ShowingDetails
                //tour={tourItem}
                listing={listing}
                details={{ tourItem, listing }}
                update={(updates) => updateDetails(updates)}
                tour={tour}
              />
              <Overview
                listing={listing}
                reviews={reviews}
                remove={removeShowing}
                tour={tour}
              />
            </div>
            <div key={tourItem?.id}>
              <Photos
                photoItems={tourItem.photos.items}
                tourItem={tourItem}
                listing={listing}
                isOpen={showPhotos}
                setIsOpen={setShowPhotos}
                handleDelete={
                  handleDeletePhoto
                }
                tour={tour}
              />
              <Notes
                tourItem={tourItem}
                noteItems={tourItem.notes.items}
                listing={listing}
                isOpen={showNotes}
                setIsOpen={setShowNotes}
                tour={tour}
              />
              <Recordings
                recordingItems={tourItem.recordings.items}
                isOpen={showRecordings}
                setIsOpen={setShowRecordings}
                tourItem={tourItem}
                listing={listing}
                handleDelete={handleDeleteRecording}
                playing={playing}
                setPlaying={setPlaying}
                tour={tour}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withAuthentication(TourDetails);
