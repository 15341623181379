import styles from "./tabs.module.scss";
import { ReactComponent as Curve } from "../../../../assets/svg/tabs/1pxtab.svg";
import { ReactComponent as CurveLarge } from "../../../../assets/svg/tabs/1pxtablarge.svg";

const Tabs = ({ tabs, active, setActive }) => {
  const handleOnClick = (e, tab) => {
    if (tab.disabled) {
      e.persist();
    } else {
      setActive(tab.value);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <div
            key={tab.label}
            className={`${styles.tab} ${active === tab.value.toLowerCase() &&
              styles.active} ${styles[tab.size]}`}
          >
            {tab.size === "small" ? (
              <Curve
                className={styles.curve}
                style={{
                  fill:
                    active === tab.value.toLowerCase() ? "white" : "#DBDBDB",
                  zIndex: active === tab.value.toLowerCase() ? 10 : tab.order,
                }}
              />
            ) : (
              <CurveLarge
                className={styles.curve}
                style={{
                  fill:
                    active === tab.value.toLowerCase() ? "white" : "#DBDBDB",
                  zIndex: active === tab.value.toLowerCase() ? 10 : tab.order,
                }}
              />
            )}

            <div
              className={styles.content}
              onClick={(e) => handleOnClick(e, tab)}
            >
              {tab.icon && <span className={styles.icon}>{tab.icon}</span>}
              <span className={styles.label}>{tab.label}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
