import {
  IonModal,
  IonContent,
  IonItem,
  IonLabel,
  IonToast,
  getPlatforms,
} from "@ionic/react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import CustomModalHeader from "../CustomModalHeader/custom-modal-header.component";
import styles from "./share-modal.module.scss";
import numeral from "numeral";
import {
  chatboxEllipsesOutline,
  linkOutline,
  logoFacebook,
  logoInstagram,
  logoLinkedin,
  logoTwitter,
  logoWhatsapp,
  mailOutline,
} from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { SocialSharing } from "@ionic-native/social-sharing";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { Clipboard } from "@ionic-native/clipboard";
import { SMS } from "@ionic-native/sms";
import { AndroidPermissions } from "@ionic-native/android-permissions";

const IMAGE_API = "https://cdn.repliers.io";

const ShareModal = ({
  listing,
  showModal,
  setShowModal,
  isInvite,
  isPreCon = false,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const url = useLocation().pathname;

  const data = isPreCon
    ? listing.name +
      " at " +
      listing.streetNumber +
      " " +
      listing.streetName +
      ", " +
      listing.city +
      ", starting from " +
      numeral(listing.startPrice).format("$1,000,000")
    : listing.details.numBedrooms +
      " bed " +
      listing.details.propertyType +
      " at " +
      listing.address.unitNumber +
      " - " +
      listing.address.streetNumber +
      " " +
      listing.address.streetName +
      " " +
      listing.address.streetSuffix +
      ", " +
      listing.address.area +
      " for " +
      numeral(listing.listPrice).format("$1,000,000");

  const text = isPreCon
    ? `Check this project out on AECORN!%0D%0A${data}%0D%0Ahttps://aecorn.ca${url}%0D%0A`
    : `Check this listing out on AECORN!%0D%0A${data}%0D%0Ahttps://aecorn.ca${url}%0D%0A`;
  const emailText = isPreCon
    ? `Check this project out on AECORN!%0D%0A${data}%0D%0Ahttps://aecorn.ca${url}%0D%0A`
    : `Check this listing out on AECORN!%0D%0A${data}%0D%0Ahttps://aecorn.ca${url}%0D%0A`;

  const shareViaWeb = (platform) => {
    switch (platform) {
      case "facebook":
        InAppBrowser.create(
          `https://www.facebook.com/sharer/sharer.php?u=https://aecorn.ca${url}`,
          "_blank",
          {
            hideurlbar: true,
            hidenavigationbutton: true,
            toolbar: "yes",
            toolbarcolor: "#363636",
            closebuttoncolor: "#f2f2f2",
            closebuttoncaption: "Back",
            toolbarposition: "top",
          }
        );
        break;
      case "twitter":
        InAppBrowser.create(
          `https://twitter.com/intent/tweet?text=${encodeURI(
            isPreCon ? "Check this project out!" : "Check this listing out!"
          )}%0A${encodeURI(text)}%0A${encodeURI(`https://aecorn.ca${url}`)}`,
          "_blank",
          {
            hideurlbar: true,
            hidenavigationbutton: true,
            toolbar: "yes",
            toolbarcolor: "#363636",
            closebuttoncolor: "#f2f2f2",
            closebuttoncaption: "Back",
            toolbarposition: "top",
          }
        );
        break;
      case "linkedin":
        InAppBrowser.create(
          `https://www.linkedin.com/sharing/share-offsite/?url=https://aecorn.ca${url}`,
          "_blank",
          {
            hideurlbar: true,
            hidenavigationbutton: true,
            toolbar: "yes",
            toolbarcolor: "#363636",
            closebuttoncolor: "#f2f2f2",
            closebuttoncaption: "Back",
            toolbarposition: "top",
          }
        );
        break;
      case "email":
        InAppBrowser.create(
          isPreCon
            ? `mailto:yourfriend@email.com?Subject=Check%20this%20project%20out%20on%20AECORN!&body=${emailText}`
            : `mailto:yourfriend@email.com?Subject=Check%20this%20listing%20out%20on%20AECORN!&body=${emailText}`,
          "_blank",
          {
            hideurlbar: true,
            hidenavigationbutton: true,
            toolbar: "yes",
            toolbarcolor: "#363636",
            closebuttoncolor: "#f2f2f2",
            closebuttoncaption: "Back",
            toolbarposition: "top",
          }
        );
        break;
      default:
        return;
    }
  };

  const share = async (platform) => {
    try {
      const text = isPreCon
        ? `Check this project out on AECORN!%0D%0A${data}%0D%0Ahttps://aecorn.ca${url}%0D%0A`
        : `Check this listing out on AECORN!%0D%0A${data}%0D%0Ahttps://aecorn.ca${url}%0D%0A`;
      switch (platform) {
        case "email":
          await SocialSharing.shareViaEmail(
            text,
            isPreCon
              ? "Check this project out on AECORN!"
              : "Check this listing out on AECORN!"
          );
          break;
        case "sms":
          SMS.send("", text);
          if (getPlatforms().includes("android")) {
            AndroidPermissions.checkPermission(
              AndroidPermissions.PERMISSION.SEND_SMS
            ).then(
              (res) => {
                if (res.hasPermission) {
                  SMS.send("", text);
                } else {
                  AndroidPermissions.requestPermission(
                    AndroidPermissions.PERMISSION.SEND_SMS
                  )
                    .then((res) => SMS.send("", text))
                    .catch((err) => console.log(err));
                }
              },
              (err) =>
                AndroidPermissions.requestPermissions([
                  AndroidPermissions.PERMISSION.SEND_SMS,
                ])
            );
          } else {
            SMS.hasPermission()
              .then((res) => {
                SMS.send("", text);
              })
              .catch((err) => console.log(err));
          }
          break;
        case "facebook":
          await SocialSharing.shareViaFacebook(
            text,
            `${IMAGE_API}/${listing.images[0]}`,
            `https://aecorn.ca${url}`
          );
          break;
        case "twitter":
          await SocialSharing.shareViaTwitter(
            text,
            `${IMAGE_API}/${listing.images[0]}`,
            `https://aecorn.ca${url}`
          );
          break;
        case "linkedin":
          await SocialSharing.shareVia(
            "linkedin",
            text,
            `${IMAGE_API}/${listing.images[0]}`,
            `https://aecorn.ca${url}`
          );
          break;
        case "instagram":
          await SocialSharing.shareViaInstagram(
            text,
            `${IMAGE_API}/${listing.images[0]}`
          );
          break;
        case "whatsapp":
          try {
            await SocialSharing.shareViaWhatsApp(
              text,
              isPreCon && `${IMAGE_API}/${listing.images[0]}`,
              `https://aecorn.ca${url}`
            );
          } catch (err) {
            isPreCon
              ? window.open(`whatsapp://send?text=${text}`)
              : window.open(`whatsapp://send?text=${text}`);
          }
          break;
        default:
          return;
      }
    } catch (err) {
      try {
        await SocialSharing.shareWithOptions({
          message: text,
          subject: isPreCon
            ? "Check this project out on AECORN!"
            : "Check this listing out on AECORN!",
          files: [`${IMAGE_API}/${listing.images[0]}`],
          url: `https://aecorn.ca${url}`,
          chooserTitle: isPreCon ? "Share this project" : "Share this listing",
        });
      } catch (err) {
        shareViaWeb(platform);
      }
    }
  };

  const copyToClipboard = async () => {
    try {
      await Clipboard.copy(text);
      setIsCopied(true);
    } catch (err) {
      navigator.clipboard.writeText(`https://aecorn.ca${url}`);
      setIsCopied(true);
    }
  };

  return (
    <IonModal isOpen={showModal}>
      <CustomModalHeader
        title={
          isInvite
            ? "Invite others!"
            : isPreCon
            ? "Share project"
            : "Share listing"
        }
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <IonContent>
        <IonToast
          isOpen={isCopied}
          duration={2000}
          message={
            isPreCon
              ? "Project link has been copied to clipboard."
              : "Property link has been copied to clipboard."
          }
          position="top"
          cssClass={styles.toast}
          onDidDismiss={() => setIsCopied(false)}
          buttons={[
            {
              side: "end",
              icon: "close",
              handler: () => setIsCopied(false),
            },
          ]}
        />

        <div className={styles.container}>
          {isInvite && (
            <div className={styles.invite}>
              <p>
                Enjoying AECORN? Fantastic! Invite friends, family or your agent
                to take advantage of AECORN's great features.
              </p>
            </div>
          )}

          <div
            className={styles.btns}
            style={{ marginTop: isInvite ? "" : "1rem" }}
          >
            <span className={styles.btn} onClick={copyToClipboard}>
              <IonIcon icon={linkOutline} className={styles.icon} />
              <span className={styles.label}>Copy link</span>
            </span>

            <span className={styles.btn} onClick={() => share("email")}>
              <IonIcon icon={mailOutline} className={styles.icon} />
              <span className={styles.label}>Email</span>
            </span>
          </div>

          <div className={styles.options}>
            {getPlatforms().includes("capacitor") && (
              <IonItem
                className={styles.option}
                lines="full"
                onClick={() => share("sms")}
              >
                <IonIcon
                  className={styles.icon}
                  icon={chatboxEllipsesOutline}
                />
                <IonLabel className={styles.label}>Text</IonLabel>
              </IonItem>
            )}

            <IonItem
              className={styles.option}
              lines="full"
              onClick={() => share("facebook")}
            >
              <IonIcon className={styles.icon} icon={logoFacebook} />
              <IonLabel className={styles.label}>Facebook</IonLabel>
            </IonItem>

            {getPlatforms().includes("capacitor") && (
              <IonItem
                className={styles.option}
                lines="full"
                onClick={() => share("instagram")}
              >
                <IonIcon className={styles.icon} icon={logoInstagram} />
                <IonLabel className={styles.label}>Instagram</IonLabel>
              </IonItem>
            )}

            <IonItem
              className={styles.option}
              lines="full"
              onClick={() => share("linkedin")}
            >
              <IonIcon className={styles.icon} icon={logoLinkedin} />
              <IonLabel className={styles.label}>Linkedin</IonLabel>
            </IonItem>

            <IonItem
              className={styles.option}
              lines="full"
              onClick={() => share("twitter")}
            >
              <IonIcon className={styles.icon} icon={logoTwitter} />
              <IonLabel className={styles.label}>Twitter</IonLabel>
            </IonItem>

            <IonItem
              className={styles.option}
              lines="full"
              onClick={() => share("whatsapp")}
            >
              <IonIcon className={styles.icon} icon={logoWhatsapp} />
              <IonLabel className={styles.label}>WhatsApp</IonLabel>
            </IonItem>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ShareModal;
