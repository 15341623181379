import { store } from "../redux/store";
import { Auth, API, graphqlOperation } from "aws-amplify";
import {
  updateAgent,
  createClient as createNewClient,
  deleteProfile,
  updateUser,
} from "../graphql/mutations";
import { CognitoIdentityProvider } from "@aws-sdk/client-cognito-identity-provider";
import { createClient, removeSearch } from "../api/repliers";
import { fetchClientStart } from "../redux/client/client.actions";
import awsconfig from "../aws-exports-dev";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import { updateClient } from "./graphql.utils";
import {
  federatedLoadingEnd,
  federatedLoadingStart,
} from "../redux/ui/ui.actions";

export const isAgent = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();

    const isAgent =
      user.signInUserSession.accessToken.payload["cognito:groups"].includes(
        "Agents"
      );
    return isAgent;
  } catch (err) {
    return false;
  }
};

export const checkIsAgent = (user) => {
  if (!user) return false;
  const isAgent =
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "Agents"
    );

  return isAgent;
};

export const signUp = async ({
  username,
  password,
  given_name,
  family_name,
  subscribed,
}) => {
  const data = await Auth.signUp({
    username,
    password,
    attributes: {
      given_name,
      family_name,
      "custom:subscribed": subscribed ? "1" : "0",
    },
  });
  return data;
};

export const confirmSignUp = async (username, code) => {
  const res = await Auth.confirmSignUp(username, code);
  return res;
};

export const resendConfirmationCode = async (username) => {
  const res = await Auth.resendSignUp(username);
  return res;
};

export const verifyUserAttribute = async (type) => {
  try {
    await Auth.verifyCurrentUserAttribute(type);
  } catch (err) {
    console.log(err);
  }
};

export const verifyUserAttributeConfirm = async (
  type,
  code,
  clientId,
  notifications
) => {
  const res = await Auth.verifyCurrentUserAttributeSubmit(type, code);
  if (clientId) {
    const agent = await isAgent();
    await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: clientId,
          phone: notifications.phone,
          notifications: JSON.stringify(notifications),
        },
      })
    );
  }

  return res;
};

export const sendForgotPasswordCode = async (username) => {
  const res = await Auth.forgotPassword(username);
  return res;
};

export const resetPasswordCheckCode = async (username, code) => {
  return Auth.forgotPasswordSubmit(
    username,
    code,
    "some_wrong_password_to_check_if_code_is_right"
  )
    .then((data) => {
      return true;
    })
    .catch((err) => {
      return false;
    });
};

export const resetPassword = async (username, code, newPassword) => {
  return Auth.forgotPasswordSubmit(username, code, newPassword)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const signIn = async (username, password) => {
  const user = await Auth.signIn(username, password);
  return user;
};

export const federatedSignIn = async (provider) => {
  try {
    await Auth.federatedSignIn({ provider });
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const signOut = async () => {
  try {
    const collapsedPreference = localStorage.getItem("collapsed");
    const locationPreference = localStorage.getItem("location");
    const res = await Auth.signOut();
    localStorage.clear();
    localStorage.setItem("collapsed", collapsedPreference);
    localStorage.setItem("location", locationPreference);
    return res;
  } catch (err) {
    return err;
  }
};

export const getGuestUser = async () => {
  try {
    const { authenticated } = await Auth.currentUserCredentials();
    const guest = new AWSAppSyncClient({
      disableOffline: true,
      url: awsconfig.aws_appsync_graphqlEndpoint,
      region: awsconfig.aws_appsync_region,
      auth: {
        type: authenticated
          ? AUTH_TYPE.AMAZON_COGNITO_USER_POOLS
          : AUTH_TYPE.AWS_IAM,
        credentials: !authenticated
          ? () => Auth.currentUserCredentials()
          : null,
        jwtToken: authenticated
          ? async () =>
              await (await Auth.currentSession()).getIdToken().getJwtToken()
          : null,
      },
    });

    return guest;
  } catch (err) {
    console.log(err);
    return;
  }
};

export const getCurrentUser = async (bypassCache) => {

  try {
    store.dispatch(federatedLoadingStart());
    const user = await Auth.currentAuthenticatedUser({ bypassCache });
    store.dispatch(federatedLoadingEnd());
    return user;
  } catch (err) {
    store.dispatch(federatedLoadingEnd());
    return null;
  }
};

export const updatePassword = async (oldPassword, newPassword) => {
  const user = await Auth.currentAuthenticatedUser();
  const res = await Auth.changePassword(user, oldPassword, newPassword);

  return res;
};

export const updatePhone = async (clientId, phone_number) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      phone_number,
    });

    await verifyUserAttribute("phone_number");

    await updateClient({ id: clientId, updates: { phone: phone_number } });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAccount = async (password, client) => {
  const user = await Auth.currentAuthenticatedUser();

  return Auth.signIn({ username: user.username, password })
    .then(async (user) => {
      const resDisableAccount = await API.graphql(
        graphqlOperation(updateUser, {
          input: { id: client.id, deactivated: true },
        })
      );
      const resDeleteProfile = await API.graphql(
        graphqlOperation(deleteProfile, { input: { id: client.profileId } })
      );
      if (resDisableAccount && resDeleteProfile) {
        const cognitoIdentityProvider = new CognitoIdentityProvider({
          region: "ca-central-1",
        });
        let params = {
          AccessToken: user.signInUserSession.accessToken.jwtToken,
        };

        const deleteRes = await cognitoIdentityProvider.deleteUser(params);

        if (deleteRes) {
          await client.searches.items.forEach(async (s) => {
            await removeSearch(s.repliersID);
          });
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    })
    .catch((err) => {
      return err;
    });
};

export const deleteFederatedAccount = async (client) => {
  const user = await Auth.currentAuthenticatedUser();
  const resDisableAccount = await API.graphql(
    graphqlOperation(updateUser, {
      input: { id: client.id, deactivated: true },
    })
  );

  const resDeleteProfile = await API.graphql(
    graphqlOperation(deleteProfile, { input: { id: client.profileId } })
  );

  if (resDisableAccount && resDeleteProfile) {
    const cognitoIdentityProvider = new CognitoIdentityProvider({
      region: "ca-central-1",
    });
    let params = {
      AccessToken: user.signInUserSession.accessToken.jwtToken,
    };

    const deleteRes = await cognitoIdentityProvider.deleteUser(params);

    if (deleteRes) {
      await client.searches.items.forEach(async (s) => {
        await removeSearch(s.repliersID);
      });

      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const addUserToDynamoDB = async (user) => {
  const repliersID = await createClient({
    email: user.attributes.email,
    givenName: user.attributes["given_name"],
    familyName: user.attributes["family_name"],
  });

  try {
    const { data } = await API.graphql(
      graphqlOperation(createNewClient, {
        input: {
          id: user.username,
          email: user.attributes.email,
          givenName: user.attributes["given_name"],
          familyName: user.attributes["family_name"],
          name:
            user.attributes["given_name"] +
            " " +
            user.attributes["family_name"],
          agentId: "4f18558a-c7c6-4bdd-ae59-e6c1f343a707",
          brokerageId: "676acc4d-a45f-4013-887c-1a4b22a56d9d",
          repliersID: repliersID,
          subscribed: true,
          currency: "cad",
          measurement: "imperial",
          notifications: JSON.stringify({
            schedule: "daily",
            email: user.attributes.email,
            phone: null,
            types: {
              email: true,
              phone: false,
              push: false,
            },
          }),
          deactivated: false,
        },
      })
    );

    return data.createClient;
  } catch (err) {
    return null;
  }
};
