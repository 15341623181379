import { getPlatforms, IonCol, IonFooter, IonIcon } from "@ionic/react";
import { cameraOutline, micOutline, stopCircleOutline } from "ionicons/icons";
import styles from "./media-nav.module.scss";
import greenEditIcon from "../../../../../assets/svg/REA103_Icons-01a_Edit saved search-green.svg";
import withClient from "../../../../../HOC/withClient/with-client";

const MediaNav = ({
  disabled,
  tourItem,
  currentClient,
  handlePhoto,
  handleNote,
  isRecording,
  startRecording,
  stopRecording,
}) => {
  const isAuthorized = () => {
    if (disabled) return false;
    if (!getPlatforms().includes("capacitor")) return false;

    const members = tourItem.tour.users.items.map((u) => u.userId);
    const { id: clientId } = currentClient;
    return members.includes(clientId);
  };

  const handleAudioClick = () => {
    const isAllowed = isAuthorized();
    if (!isAllowed) {
      return;
    } else {
      if (isRecording) {
        stopRecording();
      } else {
        startRecording();
      }
    }
  };

  return (
    <div className={styles.footer}>
      <div className={styles.icons}>
        <IonCol
          className={`${styles.icon} ${
            !isAuthorized() ? styles.disabled : styles.camera
          }`}
          onClick={!isAuthorized() ? undefined : handlePhoto}
        >
          <IonIcon icon={cameraOutline} />
          <span>New</span>
          <span>photo</span>
        </IonCol>
        <IonCol
          className={`${styles.icon} ${styles.note} ${
            disabled && styles.disabled
          }`}
          onClick={disabled ? undefined : handleNote}
        >
          <IonIcon
            src={greenEditIcon}
            className={disabled && styles.disabledNoteIcon}
          />
          <span>Add</span>
          <span>note</span>
        </IonCol>
        <IonCol
          className={`${styles.icon} ${
            !isAuthorized() ? styles.disabled : styles.mic
          }`}
          onClick={handleAudioClick}
        >
          <IonIcon icon={isRecording ? stopCircleOutline : micOutline} />
          {isRecording ? (
            <>
              <span>Stop</span>
              <span>recording</span>
            </>
          ) : (
            <>
              <span>Record</span>
              <span>voice</span>
            </>
          )}
        </IonCol>
      </div>
    </div>
  );
};

export default withClient(MediaNav);
