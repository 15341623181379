import { useSelector, useDispatch } from "react-redux";
import Select from "../../Form/Select/select.component";
import withFilters from "../../../HOC/withFilters/with-filters";
import styles from "../filters-menu.module.scss";
import TypeFilter from "../../TypeFilter/type-filter.component";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import moment from "moment";
import { openAuthModal } from "../../../redux/ui/ui.actions";
import RadioFilter from "../../RadioFilter/radio-filter.component";

const StatusFilterItem = ({
  buttonStyle,
  filters: { status, lastStatus, type },
  updateMultipleFilters,
  tabName,
  tab,
  setTab,
  disabled,
}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectCurrentUser);

  const checkValue = () => {
    if (status === "A") return "Active";
    if (type === "sale" && lastStatus === "Sld") return "Sold";
    if (type === "lease" && lastStatus === "Lsd") return "Leased";
    return "De-listed";
  };

  const handleOnChange = ({ value }) => {
    if (!isAuthenticated && (value === "inactive" || value === "de-listed")) {
      dispatch(openAuthModal({ current: "login" }));
    } else {
      updateMultipleFilters({
        status: value === "active" ? "A" : "U",
        lastStatus:
          value === "active"
            ? null
            : value === "inactive"
            ? type === "sale"
              ? "Sld"
              : "Lsd"
            : ["Sus", "Exp", "Ter"],
        displayPublic: value === "active" ? "Y" : null,
        displayAddressOnInternet: value === "active" ? "Y" : null,
        minListDate: null,
        maxSoldDate:
          value === "active" || value === "de-listed"
            ? null
            : moment(new Date()).format("YYYY-MM-DD"),
        minSoldDate:
          value === "active" || value === "de-listed"
            ? null
            : moment()
                .subtract(90, "days")
                .format("YYYY-MM-DD"),
        resultsPerPage: value === "de-listed" ? 250 : 2500,
        sortBy: value === "de-listed" ? "updatedOnDesc" : "createdOnDesc",
      });
    }
  };

  return (
    <div className={styles.item}>
      <Select
        buttonStyle={buttonStyle}
        title="Status"
        open={tab === tabName}
        onClick={() => setTab(tabName)}
        dropdown={false}
        disabled={disabled}
      />
      <RadioFilter
        multiple={false}
        open={tab === tabName}
        options={
          type === "sale"
            ? [
                { label: "Active", value: "active" },
                { label: "Sold", value: "inactive" },
                { label: "De-listed", value: "de-listed" },
              ]
            : [
                { label: "Active", value: "active" },
                { label: "Leased", value: "inactive" },
                { label: "De-listed", value: "de-listed" },
              ]
        }
        value={checkValue()}
        dropdown={false}
        onClick={handleOnChange}
      />
    </div>
  );
};

export default withFilters(StatusFilterItem);
