import { IonIcon } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { exitOutline } from "ionicons/icons";
import styles from "./profile-dropdown.module.scss";
import DropdownContainer from "../Form/DropdownContainer/dropdown-container.component";
import { useHistory } from "react-router-dom";
import useComponentVisible from "../../hooks/useComponentVisible";
import { signOutStart } from "../../redux/user/user.actions";
import { selectCurrentClient } from "../../redux/client/client.selectors";
import { selectIsAdmin, selectIsAgent } from "../../redux/user/user.selectors";
import { openModal } from "../../redux/ui/ui.actions";
import { capitalize } from "../../utils/functions";

const ProfileDropdown = ({
  givenName,
  familyName,
  currency,
  isHomepage = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const client = useSelector(selectCurrentClient);
  const isAgent = useSelector(selectIsAgent);
  const isAdmin = useSelector(selectIsAdmin);
  const { ref, isOpen, setIsOpen } = useComponentVisible(false);

  const handleClick = (destination) => {
    setIsOpen(false);
    history.push(`/${destination}`);
  };

  const handleSignOut = () => {
    dispatch(signOutStart());
  };

  const handleCurrency = () => {
    dispatch(openModal({ current: "editCurrency" }));
  };

  return (
    <div ref={ref} className={styles.container}>
      <div className={styles.profile} onClick={() => setIsOpen(!isOpen)}>
        <div
          className={styles.name}
          style={isHomepage ? { color: "#00e0b7" } : { color: "#363636" }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {givenName ? capitalize(givenName) : "User"}
          {isAgent && (
            <div
              className={styles.agentProfile}
              style={isHomepage ? { color: "#fff" } : { color: "#6e6e6e" }}
            >
              Agent profile{" "}
            </div>
          )}
        </div>
        {!isHomepage && (
          <div className={styles.imageContainer}>
            <span className={styles.initials}>
              {givenName.charAt(0).toUpperCase() +
                familyName.charAt(0).toUpperCase()}
            </span>
          </div>
        )}

        {/* <div
          className={styles.iconContainer}
          onClick={() => setIsOpen(!isOpen)}
        >
          <IonIcon
            className={`${styles.icon} ${isOpen ? styles.iconReversed : ""}`}
            icon={chevronDownOutline}
          />
        </div> */}
      </div>

      <DropdownContainer
        open={isOpen}
        openHandler={setIsOpen}
        style={{
          minWidth: "20rem",
          left: "-10rem",
          position: "absolute",
          zIndex: "1500",
          boxShadow: isHomepage && "none",
        }}
      >
        <div className={styles.profileDropdownContainer}>
          {isAdmin && (
            <div
              className={`${styles.firstRow} ${isHomepage &&
                styles.homePageFont}`}
              onClick={() => handleClick("admin")}
            >
              Admin panel
            </div>
          )}
          <div
            className={`${styles.firstRow} ${isHomepage &&
              styles.homePageFont}`}
            onClick={() => handleClick("user")}
          >
            Account settings
          </div>
          {client && client.currency && (
            <div
              className={`${styles.firstRow} ${isHomepage &&
                styles.homePageFont}`}
              onClick={handleCurrency}
            >
              Currency: <u>{client.currency.toUpperCase()}</u>
            </div>
          )}

          <div
            className={`${styles.secondRow} ${isHomepage &&
              styles.homePageFont}`}
            onClick={handleSignOut}
          >
            Log out <IonIcon icon={exitOutline} />
          </div>
        </div>
      </DropdownContainer>
    </div>
  );
};

export default ProfileDropdown;
