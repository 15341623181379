import useComponentVisible from "../../../../hooks/useComponentVisible";
import Select from "../../../Form/Select/select.component";
import TypeFilter from "../../../TypeFilter/type-filter.component";
import {
  residentialTypes,
  commercialTypes,
  preTypes,
} from "../../data/filters.data";

const PropertyTypeFilter = ({
  isHomepage = false,
  filters: { class: classFilter, propertyType, type },
  updateSingleFilter,
  isPreConstruction,
  disabled,
}) => {
  const { ref, isOpen, setIsOpen } = useComponentVisible(false);

  const options =
    type.toLowerCase() === "pre-construction"
      ? preTypes
      : classFilter.includes("residential")
      ? residentialTypes
      : commercialTypes;

  const PropertyTypeHandler = (item) => {
    if (item === "Any type") {
      updateSingleFilter({
        propertyType: null,
      });
    } else {
      if (!propertyType) {
        updateSingleFilter({
          propertyType: propertyType ? [...propertyType, item] : [item],
        });
      } else {
        const index = propertyType.indexOf(item);
        if (index > -1) {
          const updatedTypes = propertyType.filter((type) => type !== item);
          if (updatedTypes.length) {
            updateSingleFilter({
              propertyType: propertyType.filter((type) => type !== item),
            });
          } else {
            updateSingleFilter({
              propertyType: null,
            });
          }
        } else {
          updateSingleFilter({
            propertyType: propertyType ? [...propertyType, item] : [item],
          });
        }
      }
    }
  };

  const onTypeChange = () => {
    if (!propertyType) {
      return isHomepage ? "Property Type" : "any";
    } else if (propertyType.length === 1) {
      const selectedOption = options.find(
        (option) => option.value === propertyType[0].replaceAll("'", "")
      );
      if (selectedOption) {
        return selectedOption.name;
      } else {
        return "any";
      }
    } else {
      let count = propertyType.length;
      return `${isHomepage ? "Multiple" : "multiple"}(${count})`;
    }
  };

  return (
    <div ref={ref}>
      <Select
        label={!isHomepage && "property type"}
        title={onTypeChange()}
        open={isOpen}
        onClick={() => setIsOpen((isOpen) => !isOpen)}
        disabled={disabled}
        buttonStyle={
          isHomepage
            ? {
                border: "none",
                fontSize: "1.5rem",
                color: "white",
                backgroundColor: "#494949",
                borderColor: "#3d3d3d",
                iconColor: "#ffde6d",
              }
            : null
        }
      />

      <TypeFilter
        open={isOpen}
        items={options}
        value={propertyType ? propertyType : ["Any type"]}
        onClickHandler={PropertyTypeHandler}
        isHomepage={isHomepage}
      />
    </div>
  );
};

export default PropertyTypeFilter;
