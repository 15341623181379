import { useState } from "react";
import { useDispatch } from "react-redux";
import { IonButton, IonIcon, IonAlert } from "@ionic/react";
import { closeOutline, eyeOffOutline } from "ionicons/icons";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import DetailsHeader from "../../../../components/TourDetailsHeader/tour-details-header.module";
import styles from "./agent-details.module.scss";
import Button from "../../../../components/Form/Button/button.component";
import { updateToast } from "../../../../redux/ui/ui.actions";
import TextArea from "../../../../components/Form/TextArea/textarea.component";
import { TourService } from "../../../../services/tourService";
import { ShareStatus } from "../../../../API";

const AgentDetails = ({ tour, isOpen, setIsOpen, update, setTour }) => {
  const [note, setNote] = useState(tour.privateNote);
  const [archive, setArchive] = useState(false);
  const [cancel, setCancel] = useState(false);
  const dispatch = useDispatch();

  const handleArchive = async () => {
    try {
      const updatedTour = await TourService.archiveTour(tour.id);
      setTour(updatedTour);
      setArchive(false);
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Tour has been archived successfully.",
        })
      );
    } catch (err) {
      setArchive(false);
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };

  const handleCancel = async () => {
    try {
      const updatedTour = await TourService.cancelTour(tour.id);
      setTour(updatedTour);
      setCancel(false);
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Tour has been cancelled successfully.",
        })
      );
    } catch (err) {
      setCancel(false);
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };

  const handleCancelAndEmail = async () => {
    const updatedTour = await TourService.cancelTour(tour.id, tour.shared === ShareStatus.shared);
    setTour(updatedTour);

    dispatch(
      updateToast({
        open: true,
        type: "success",
        message: "Tour has been cancelled successfully.",
      })
    );

    
  };

  return (
    <>
      <IonAlert
        isOpen={archive}
        backdropDismiss={true}
        onDidDismiss={() => setArchive(false)}
        header="Archive tour"
        message="By archiving a tour, tour attendants will no longer be able to update its content."
        subHeader="Are you sure you want to archive this tour?"
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => setArchive(false),
          },
          {
            text: "Archive",
            role: "submit",
            handler: () => handleArchive(),
          },
        ]}
      />

      <IonAlert
        isOpen={cancel}
        backdropDismiss={true}
        onDidDismiss={() => setCancel(false)}
        header="Cancel tour"
        subHeader="Do you want to inform attendees that this tour has been cancelled?"
        buttons={[
          {
            text: "Keep tour active",
            role: "cancel",
            handler: () => setCancel(false),
            cssClass: styles.btn,
          },
          {
            text: "Cancel the tour only",
            cssClass: styles.btn,
            handler: () => handleCancel(),
          },
          {
            text: "Cancel and update attendees",
            cssClass: styles.btn,
            handler: () => handleCancelAndEmail(),
          },
        ]}
      />

      <CardContainer>
        <DetailsHeader
          title="Agent details"
          icon={eyeOffOutline}
          isVisible={false}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />

        {isOpen && (
          <div className={styles.inputs}>
            <TextArea
              name="note"
              value={note}
              onChange={(val) => {
                setNote(val);
              }}
              label="Private agent tour notes"
              placeholder="Private agent tour notes"
            />

            <Button
              title="Save changes"
              type="green"
              style={{
                border: "1px solid #1F7A60",
                width: "100%",
                height: "5rem",
                marginTop: "2rem",
              }}
              onClick={() => update({ privateNote: note, id: tour.id })}
              disabled={!tour || !tour.status || tour.status === "archived"}
            />

            <div className={styles.note}>
              These fields will not be shared with the client.
            </div>

            <div className={styles.remove}>
              <IonButton
                fill="clear"
                className={styles.button}
                onClick={() => setArchive(true)}
                disabled={tour.status === "archived"}
              >
                {tour.status !== "archived" ? (
                  <>
                    <IonIcon icon={closeOutline} />
                    Archive tour
                  </>
                ) : (
                  "This tour is archived"
                )}
              </IonButton>
              <IonButton
                fill="clear"
                className={styles.button}
                onClick={() => setCancel(true)}
                disabled={["archived", "cancelled", "completed"].includes(
                  tour.status
                ) || !TourService.isCurrentUserTourPrimaryAgent(tour)}
              >
                {tour.status !== "cancelled" ? (
                  <>
                    <IonIcon icon={closeOutline} />
                    Cancel tour
                  </>
                ) : (
                  "This tour is canceled"
                )}
              </IonButton>
            </div>
          </div>
        )}
      </CardContainer>
    </>
  );
};

export default AgentDetails;
