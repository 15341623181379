import { UIActionTypes } from "./ui.types";
const INITIAL_STATE = {
  modal: {
    isOpen: false,
    current: null,
    isProtected: false,
    data: null,
    closable: true,
    overflow: false,
    step: null,
  },
  authModal: { isOpen: false, current: null },
  selectedTab: "home",
  isFiltersOpen: false,
  badge: {
    favourites: new Set(),
  },
  menu: {
    collapsed: false,
    tab: "/listings",
  },
  ettieOffer: null,
  mortgageRate: 5,
  notifications: { favourites: [], tours: [] },
  updatedFavourite: undefined,
  drawMode: { active: false, isSchool: false, ref: null },
  toast: {
    open: false,
    message: "",
    header: undefined,
    position: undefined,
    type: undefined,
    act: undefined,
  },
  federatedLoading: false,
};
const uiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UIActionTypes.OPEN_MODAL:
      return { ...state, modal: { ...action.payload, isOpen: true } };
    case UIActionTypes.CLOSE_MODAL:
      return { ...state, modal: INITIAL_STATE.modal };
    case UIActionTypes.OPEN_AUTH_MODAL:
      return { ...state, authModal: { ...action.payload, isOpen: true } };
    case UIActionTypes.CLOSE_AUTH_MODAL:
      return { ...state, authModal: INITIAL_STATE.modal };
    case UIActionTypes.UPDATE_BADGE:
      return {
        ...state,
        badge: {
          ...state.badge,
          [action.payload.type]: state.badge[action.payload.type].add(
            action.payload.value
          ),
        },
      };
    case UIActionTypes.UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          favourites: action.payload.value.favourites,
          tours: action.payload.value.tours,
        },
      };
    case UIActionTypes.MARK_NOTIFICATION:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          favourites: state.notifications.favourites.filter(
            (fav) => fav.mlsNumber !== action.payload
          ),
          tours: state.notifications.tours.filter(
            (tour) => tour.id !== action.payload
          ),
        },
      };
    case UIActionTypes.RESET_NOTIFICATION:
      return { ...INITIAL_STATE, ettieOffer: state.ettieOffer };
    case UIActionTypes.COLLAPSE_MENU:
      if (!state.menu.collapsed) {
        localStorage.setItem("collapsed", "true");
      } else {
        localStorage.removeItem("collapsed");
      }
      return {
        ...state,
        menu: {
          ...state.menu,
          collapsed: !state.menu.collapsed,
        },
      };
    case UIActionTypes.UPDATE_MENU_TAB:
      return { ...state, menu: { ...state.menu, tab: action.payload } };
    case UIActionTypes.TOGGLE_FILTERS:
      return { ...state, isFiltersOpen: !state.isFiltersOpen };
    case UIActionTypes.UPDATE_DRAW_MODE:
      return { ...state, drawMode: { ...state.drawMode, ...action.payload } };
    case UIActionTypes.UPDATE_TOAST:
      return {
        ...state,
        toast: {
          ...action.payload,
          act: action.payload.act ? action.payload.act : undefined,
        },
      };
    case UIActionTypes.UPDATE_CHANGED_FAVOURITE:
      return { ...state, updatedFavourite: action.payload };
    case UIActionTypes.FETCH_OFFER_START:
      return { ...state };
    case UIActionTypes.FETCH_OFFER_SUCCESS:
      return {
        ...state,
        ettieOffer: action.payload,
      };
    case UIActionTypes.FETCH_OFFER_FAILURE:
      return { ...state };
    case UIActionTypes.FETCH_RATE_START:
      return { ...state };
    case UIActionTypes.FETCH_RATE_SUCCESS:
      return {
        ...state,
        mortgageRate: action.payload,
      };
    case UIActionTypes.FETCH_RATE_FAILURE:
      return { ...state };
    case UIActionTypes.FEDERATED_LOADING_START:
      return { ...state, federatedLoading: true };
    case UIActionTypes.FEDERATED_LOADING_END:
      return { ...state, federatedLoading: false };
    case UIActionTypes.UPDATE_SELECTED_TAB:
      return { ...state, selectedTab: action.payload };
    default:
      return state;
  }
};
export default uiReducer;
