import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectMapSelectedLocation,
  selectMapError,
  selectMapListings,
  selectMapLoading,
  selectMapRef,
  selectMapLocations,
  selectMapFavourites,
  selectMapClusters,
  selectMapSelectedListings,
  selectMapSelectedCluster,
} from "../../redux/map/map.selectors";
import {
  updateMapRef,
  updateSelectedListing,
  updateLocations,
  updateListings,
} from "../../redux/map/map.actions";
import { selectCurrentClient } from "../../redux/client/client.selectors";
import { fetchFavouriteItems } from "../../amplify/graphql.utils";
import { ListingService } from "../../services/listingService";

const withMap = (Component) => (props) => {
  const dispatch = useDispatch();
  const mapClusters = useSelector(selectMapClusters);
  const mapListings = useSelector(selectMapListings);
  const selectedCluster = useSelector(selectMapSelectedCluster);
  const selectedListings = useSelector(selectMapSelectedListings);
  const mapError = useSelector(selectMapError);
  const mapLoading = useSelector(selectMapLoading);
  const mapRef = useSelector(selectMapRef);
  const mapSelectedLocation = useSelector(selectMapSelectedLocation);
  const mapLocations = useSelector(selectMapLocations);
  const client = useSelector(selectCurrentClient);
  const favouritesOnly = useSelector(selectMapFavourites);

  const fetchFavourites = useCallback(
    async (items) => {
      const promises = items.map(async (item) => {
        return await ListingService.getListingByMlsNumber(item.mlsNumber);
      });

      const listings = await Promise.all(promises);
      dispatch(updateListings(listings));
    },
    [dispatch]
  );

  useEffect(() => {
    const getFavs = async () => {
      const favourites = await fetchFavouriteItems();

      if (favourites) fetchFavourites(favourites);
    };
    if (favouritesOnly) {
      getFavs();
    }
  }, [client, favouritesOnly, fetchFavourites]);

  const updateRef = (ref) => {
    dispatch(updateMapRef(ref));
  };

  const updateSelected = (location) => {
    dispatch(updateSelectedListing(location));
  };

  const updateMapLocations = (locations) => {
    if (locations.city && !locations.neighborhood) {
      const updatedCoordinates = mapLocations.coordinates
        ? mapLocations.coordinates.filter((c) => c.city !== locations.city)
        : null;

      dispatch(
        updateLocations({
          center: locations.center,
          coordinates: updatedCoordinates,
        })
      );
    }

    if (locations.city && locations.neighborhood) {
      dispatch(
        updateLocations({
          center: locations.center,
          coordinates: mapLocations.coordinates
            ? [
                ...mapLocations.coordinates,
                {
                  name: locations.neighborhood,
                  city: locations.city,
                  coords: locations.coordinates,
                },
              ]
            : [
                {
                  name: locations.neighborhood,
                  city: locations.city,
                  coords: locations.coordinates,
                },
              ],
        })
      );
    }

    if (locations.neighborhood && !locations.city) {
      dispatch(
        updateLocations({
          ...mapLocations,
          coordinates: mapLocations.coordinates
            ? mapLocations.coordinates.filter(
                (c) => c.name !== locations.neighborhood
              )
            : null,
        })
      );
    }

    if (locations.reset) {
      dispatch(updateLocations({ center: null, coordinates: null }));
    }

    if (locations.load) {
      dispatch(
        updateLocations({
          center: locations.center,
          coordinates: locations.coordinates,
        })
      );
    }
  };

  return (
    <Component
      {...props}
      mapRef={mapRef}
      mapClusters={mapClusters}
      mapListings={mapListings}
      selectedCluster={selectedCluster}
      selectedListings={selectedListings}
      mapLoading={mapLoading}
      mapError={mapError}
      mapSelectedLocation={mapSelectedLocation}
      mapLocations={mapLocations}
      updateMapLocations={updateMapLocations}
      updateMapRef={updateRef}
      updateMapSelectedLocation={updateSelected}
      favouritesOnly={favouritesOnly}
    />
  );
};

export default withMap;
