import { useState, useEffect } from "react";
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
  IonTitle,
} from "@ionic/react";
import styles from "./searchable-select.module.scss";
import { closeOutline } from "ionicons/icons";

const SearchableSelect = ({
  title,
  items,
  selected,
  setSelected,
  onChange,
  canRemove,
  onRemove,
  type = "checkbox",
  label,
  icon,
  required,
  style,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [list, setList] = useState();

  const renderCheckbox = () => {
    const items =
      list &&
      list.map((item) => (
        <IonItem key={item.id} className={styles.listItem}>
          <div className={styles.labels}>
            <IonLabel className={styles.main}>{item.displayName}</IonLabel>
            <IonLabel className={styles.secondary}>{item.email}</IonLabel>
          </div>
          <IonCheckbox
            className={styles.checkbox}
            slot="start"
            value={item.id}
            checked={selected && selected.find((id) => id === item.id)}
            onIonChange={({ detail: { checked } }) => {
              if (selected && !selected.find((id) => id === item.id)) {
                setSelected([...selected, item]);
              } else {
                setSelected(selected.filter((id) => id !== item.id));
              }
            }}
          />
        </IonItem>
      ));

    return items && items.length ? (
      items
    ) : (
      <IonItem className={styles.listItem} lines="full">
        <IonLabel>No matching result found.</IonLabel>
      </IonItem>
    );
  };

  const renderRadio = () => {
    const items =
      list &&
      list.map((item) => (
        <IonItem key={item.id} className={styles.listItem} lines="full">
          <div className={styles.labels}>
            <IonLabel className={styles.main}>{item.displayName}</IonLabel>
            <IonLabel className={styles.secondary}>{item.email}</IonLabel>
          </div>

          <IonRadio slot="start" value={item.id} className={styles.radio} />
        </IonItem>
      ));
    return items && items.length ? (
      items
    ) : (
      <IonItem className={styles.listItem} lines="full">
        <IonLabel>No matching result found.</IonLabel>
      </IonItem>
    );
  };

  useEffect(() => {
    if (items) setList(items);
  }, [items]);

  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        // swipeToClose={true}
      >
        <IonHeader className={styles.header}>
          <IonTitle className={styles.title}>{title}</IonTitle>
          <IonIcon icon={closeOutline} onClick={() => setIsOpen(false)} />
        </IonHeader>
        <IonContent className={styles.content} scrollY>
          <div className={styles.closeIcon}></div>
          <IonSearchbar
            placeholder={title}
            className={styles.search}
            onIonChange={({ detail: { value } }) => onChange(value)}
            onIonClear={() => onChange("value")}
          />
          <IonList>
            {type === "radio" && (
              <IonRadioGroup
                value={selected}
                onIonChange={({ detail: { value } }) =>
                  setSelected(list.find((i) => i.id === value))
                }
              >
                {renderRadio()}
              </IonRadioGroup>
            )}

            {type === "checkbox" && <>{renderCheckbox()}</>}
          </IonList>
          <div className={styles.footer}>
            <IonButton
              shape="round"
              expand="block"
              className={styles.btn}
              onClick={() => setIsOpen(false)}
            >
              Done
            </IonButton>
          </div>
        </IonContent>
      </IonModal>
      <IonItem
        style={style}
        onClick={() => setIsOpen(true)}
        className={`${styles.item} ${icon && styles.addItem}`}
        lines="none"
      >
        <IonLabel>
          {icon && <IonIcon icon={icon} />}
          {label}
          {required &&
            (Array.isArray(selected) ? !selected.length : !selected) &&
            "*"}
        </IonLabel>
        {!icon && <IonLabel>{selected && list.find(item => item.id === selected)?.displayName}</IonLabel>}
        {onRemove && canRemove && (
          <IonIcon icon={closeOutline} onClick={onRemove} />
        )}
      </IonItem>
    </>
  );
};

export default SearchableSelect;
