import { cloneElement } from "react";
import { Redirect } from "react-router-dom";
import withAuthentication from "../HOC/withAuthentication/with-authentication";
import withClient from "../HOC/withClient/with-client";

const AdminRoute = ({
  user,
  currentClient,
  component,
  isWeb = false,
  ...rest
}) => {
  return user &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "Admins"
    ) ? (
    cloneElement(component, { currentClient, user, ...rest })
  ) : (
    <Redirect to={"/"} />
  );
};

export default withAuthentication(withClient(AdminRoute));
