import { useHistory } from "react-router-dom";
import { capitalize } from "../../../utils/functions";
import styles from "./styles.module.scss";

const PATHS = {
  projects: "/admin/projects",
  builders: "/admin/builders",
  designers: "/admin/designers",
  architects: "/admin/architects",
  units: "/admin/units",
  amenities: "/admin/amenities",
};

const BreadCrumbs = ({ type, sub = undefined }) => {
  const history = useHistory();
  return (
    <ul className={styles.breadCrumbs}>
      <li
        className={`${styles.crumb} ${styles.clickable}`}
        onClick={() => history.push("/admin")}
      >
        Admin Page
      </li>
      <li className={styles.separator}>/</li>
      <li
        className={`${styles.crumb} ${sub ? styles.clickable : styles.active}`}
        onClick={() => sub && history.push(PATHS[type])}
      >
        {capitalize(type)}
      </li>
      {sub && (
        <>
          <li className={styles.separator}>/</li>
          <li className={`${styles.crumb} ${styles.active}`}>
            {capitalize(sub)}
          </li>
        </>
      )}
    </ul>
  );
};

export default BreadCrumbs;
