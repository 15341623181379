import { IonContent } from "@ionic/react";
import styles from "./tour-element-content.module.scss";
import TourDetails from "./component/TourDetails/tour-details.component";
import TourAttendees from "./component/TourAttendees/tour-attendees.component";
import QuickContact from "./component/QickContact/quick-contact.component";
import CustomModalHeader from "../../../../components/CustomModalHeader/custom-modal-header.component";

const TourElementContent = ({ tour, setIsOpen, type }) => {
  return (
    <>
      <CustomModalHeader
        title={
          type === "tourDetails"
            ? "Tour details"
            : type === "tourAttendees"
            ? "Tour attendees"
            : "Quick contact"
        }
        setShowModal={setIsOpen}
        hasBackbtn={true}
        disable={true}
      />
      <IonContent className={styles.tourElementContent}>
        {type === "tourDetails" && <TourDetails tour={tour} />}
        {type === "tourAttendees" && <TourAttendees tour={tour} />}
        {type === "quickContact" && (
          <QuickContact contacts={tour.users.items} />
        )}
      </IonContent>
    </>
  );
};

export default TourElementContent;
