import { useState, useEffect } from "react";
import moment from "moment";
import Button from "../../../../../components/Form/Button/button.component";
import Input from "../../../../../components/Form/Input/input.component";
import {
  IonIcon,
  IonButton,
  IonItem,
  IonLabel,
  IonDatetimeButton,
  IonModal,
  IonDatetime,
} from "@ionic/react";
import { closeOutline, arrowBackOutline } from "ionicons/icons";
import { ranges } from "./utils";
import useComponentVisible from "../../../../../hooks/useComponentVisible";
import styles from "./tour-details.module.scss";
import Select from "../../../../../components/Form/Select/select.component";
import DropdownContainer from "../../../../../components/Form/DropdownContainer/dropdown-container.component";
import IsVisibleTag from "../../../../../components/IsVisibleTag/is-visible-tag.component";

const TourDetails = ({ tour, back, next }) => {
  const [date, setDate] = useState(
    tour ? tour.date : moment().format("YYYY-MM-DD")
  );
  const [meetup, setMeetup] = useState({
    address: "",
    time: undefined,
    description: "",
  });
  const [note, setNote] = useState("");
  const [time, setTime] = useState({ start: undefined, end: undefined });

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const {
    ref: refMeetupTime,
    isOpen: isOpenMeetupTime,
    setIsOpen: setIsOpenMeetupTime,
  } = useComponentVisible(false);

  const {
    ref: refFrom,
    isOpen: isOpenFrom,
    setIsOpen: setIsOpenFrom,
  } = useComponentVisible(false);

  const {
    ref: refTo,
    isOpen: isOpenTo,
    setIsOpen: setIsOpenTo,
  } = useComponentVisible(false);

  const handleClear = () => {
    setTime({ start: undefined, end: undefined });
    setDate(undefined);
    setNote("");
    setMeetup({ description: "", address: "", time: undefined });
  };

  const handleSubmit = async () => {
    next({
      date,
      meetupDescription: meetup.description,
      meetupLocation: meetup.address,
      meetupTime: meetup.time,
      startTime: time.start,
      endTime: time.end,
      generalNote: note,
    });
  };

  useEffect(() => {
    if (tour) {
      setMeetup({
        address: tour.meetupLocation ? tour.meetupLocation : "",
        time: tour.meetupTime ? tour.meetupTime : undefined,
        description: tour.meetupDescription ? tour.meetupDescription : "",
      });
      setNote(tour.generalNote ? tour.generalNote : "");
      setTime({
        start: tour.startTime ? tour.startTime : undefined,
        end: tour.endTime ? tour.endTime : undefined,
      });
      setDate(tour.date ? tour.date : moment().format("YYYY-MM-DD"));
      const div = document.getElementById("new_tour");
      div?.scrollIntoView();
    }
  }, [tour]);

  return (
    <div className={styles.tourDetails}>
      <div className={styles.details}>
        <div className={styles.label}>Tour details</div>
        <IsVisibleTag isVisible={true} />
      </div>

      <div className={styles.firstRow}>
        <IonItem lines="none">
          <IonLabel>Date</IonLabel>
          <IonDatetimeButton datetime="datetime" mode="date">
            {!date && <div slot="date-target">Select</div>}
          </IonDatetimeButton>
        </IonItem>
        <IonModal keepContentsMounted>
          <IonDatetime
            id="datetime"
            presentation="date"
            value={date}
            onIonChange={handleDateChange}
            showDefaultButtons
            min={moment().format("YYYY-MM-DD")}
          />
        </IonModal>
        <Input
          name="address"
          label="Meetup address"
          placeholder="Meetup address"
          mode="search"
          style={{ width: "100%" }}
          value={meetup.address}
          onChange={(value, name) => setMeetup({ ...meetup, address: value })}
        />
        <div ref={refMeetupTime} className={styles.container}>
          <Select
            label=""
            title={meetup.time ? meetup.time : "Meetup time"}
            style={{ height: "4rem", width: "100%" }}
            buttonStyle={{ height: "100%" }}
            open={isOpenMeetupTime}
            onClick={() => setIsOpenMeetupTime(!isOpenMeetupTime)}
          />
          <DropdownContainer
            open={isOpenMeetupTime}
            style={{
              position: "absolute",
              top: "auto",
              left: "auto",
              // width: "24rem",
              height: "15rem",
            }}>
            {ranges.map((range, index) => (
              <div
                key={index}
                className={styles.range}
                onClick={() => {
                  setMeetup({
                    ...meetup,
                    time: moment(range, "HH:mm:ss a").format("HH:mm"),
                  });
                  setIsOpenMeetupTime(false);
                }}>
                {range}
              </div>
            ))}
          </DropdownContainer>
        </div>
      </div>

      <div className={styles.secondRow}>
        <div className={styles.times}>
          <div ref={refFrom} className={styles.container}>
            <Select
              label=""
              title={time.start ? time.start : "Tour start"}
              style={{ height: "4rem", width: "100%" }}
              buttonStyle={{ height: "100%" }}
              open={isOpenFrom}
              onClick={() => setIsOpenFrom(!isOpenFrom)}
            />
            <DropdownContainer
              open={isOpenFrom}
              style={{
                position: "absolute",
                top: "auto",
                left: "auto",
                // width: "20rem",
                height: "15rem",
              }}>
              {ranges.map((range, index) => (
                <div
                  key={index}
                  className={styles.range}
                  onClick={() => {
                    setTime({
                      ...time,
                      start: moment(range, "HH:mm:ss a").format("HH:mm"),
                    });
                    setIsOpenFrom(false);
                  }}>
                  {range}
                </div>
              ))}
            </DropdownContainer>
          </div>
          <div className={styles.hyphen}>-</div>
          <div ref={refTo} className={styles.container}>
            <Select
              label=""
              title={time.end ? time.end : "Tour end"}
              style={{ height: "4rem", width: "100%" }}
              buttonStyle={{ height: "100%" }}
              open={isOpenTo}
              onClick={() => setIsOpenTo(!isOpenTo)}
            />
            <DropdownContainer
              open={isOpenTo}
              style={{
                position: "absolute",
                top: "auto",
                left: "auto",
                // width: "20rem",
                height: "15rem",
              }}>
              {ranges.map((range, index) => (
                <div
                  key={index}
                  className={styles.range}
                  onClick={() => {
                    setTime({
                      ...time,
                      end: moment(range, "HH:mm:ss a").format("HH:mm"),
                    });
                    setIsOpenTo(false);
                  }}>
                  {range}
                </div>
              ))}
            </DropdownContainer>
          </div>
        </div>

        <Input
          name="description"
          label="Meetup description"
          placeholder="Meetup description"
          value={meetup.description}
          style={{ width: "100%" }}
          onChange={(value, name) =>
            setMeetup({ ...meetup, description: value })
          }
        />
      </div>

      <textarea
        className={styles.textarea}
        name="note"
        placeholder="General notes for client"
        value={note}
        onChange={(event) => {
          setNote(event.target.value);
        }}></textarea>

      <Button
        title="Save and continue"
        type="green"
        style={{
          border: "1px solid #1F7A60",
          width: "100%",
          height: "5rem",
        }}
        onClick={handleSubmit}
      />
      <div className={styles.btns}>
        <IonButton
          fill="clear"
          className={`${styles.btn} ${styles.back}`}
          onClick={() => back()}>
          <IonIcon icon={arrowBackOutline} className={styles.icon} />
          Back
        </IonButton>

        <IonButton
          fill="clear"
          className={`${styles.btn} ${styles.close}`}
          onClick={handleClear}>
          <IonIcon icon={closeOutline} className={styles.icon} />
          Clear
        </IonButton>
      </div>
    </div>
  );
};

export default TourDetails;
