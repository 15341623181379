import { useState, useEffect, useRef, createRef } from "react";
import moment from "moment";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonItem,
  IonDatetime,
  IonButton,
  IonLabel,
  IonActionSheet,
  IonDatetimeButton,
  IonModal,
  IonTextarea,
} from "@ionic/react";
import styles from "./tour-details.module.scss";
import IsVisibleTag from "../../../../../../../components/IsVisibleTag/is-visible-tag.component";
import Input from "../../../../../../../components/Form/Input/input.component";
import { capitalize } from "../../../../../../../utils/functions";
import Select from "../../../../../../../components/Form/Select/select.component";
import { TourStatus } from "../../../../../../../utils/constants";
import useComponentVisible from "../../../../../../../hooks/useComponentVisible";

const TourDetails = ({ tour, update }) => {
  const minDate = moment().format("YYYY-MM-DD");
  const {
    ref: refStatus,
    isOpen: isOpenStatus,
    setIsOpen: setIsOpenStatus,
  } = useComponentVisible(false);

  const [status, setStatus] = useState();
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const [date, setDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [meetup, setMeetup] = useState({
    address: "",
    time: undefined,
    description: "",
  });

  const inputRefs = useRef({
    tour_title: createRef(),
  });

  const ACTION_SHEET_BTNS = TourStatus.map((s) => {
    return { text: s.title, handler: () => setStatus(s.title.toLowerCase()) };
  });

  const handleDateChange = (date) => {
    if (date) setDate(date);
  };

  useEffect(() => {
    if (tour) {
      setNote(tour.generalNote ? tour.generalNote : "");
      setDate(tour.date);
      setStartTime(tour.startTime);
      setEndTime(tour.endTime);
      setMeetup({
        address: tour.meetupLocation,
        time: tour.meetupTime,
        description: tour.meetupDescription,
      });
      setStatus(tour.status);
      setTitle(tour.title);
    }
  }, [tour]);

  return (
    <IonGrid className={styles.tourDetails}>
      <IonRow>
        <IonCol>
          <IsVisibleTag isVisible={true} expand="full" />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <Input
            name="tour_title"
            label="Tour title"
            placeholder="Tour title"
            required
            ref={inputRefs.current.tour_title}
            value={title}
            onChange={(value, name) => setTitle(value)}
            validation="required"
          />
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <div ref={refStatus} className={styles.container}>
            <Select
              label="Status of tour"
              title={status && capitalize(status)}
              style={{
                height: "4rem",
                width: "100%",
                textTransform: "inherit",
              }}
              buttonStyle={{ height: "100%" }}
              open={isOpenStatus}
              onClick={() => setIsOpenStatus(!isOpenStatus)}
            />
            <IonActionSheet
              header={"Tour Status"}
              isOpen={isOpenStatus}
              onDidDismiss={() => setIsOpenStatus(false)}
              buttons={[...ACTION_SHEET_BTNS]}
              cssClass={styles.actionSheet}
            />
          </div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem className={styles.item} lines="none">
            <IonLabel>Date</IonLabel>
            <IonDatetimeButton datetime="date">
              {!date && <div slot="date-target">Select</div>}
            </IonDatetimeButton>
            <IonModal keepContentsMounted={true}>
              <IonDatetime
                id="date"
                displayFormat="MMM DD YYYY"
                showDefaultButtons
                presentation="date"
                min={minDate}
                placeholder="Date of tour"
                value={date}
                onIonChange={(e) => handleDateChange(e.detail.value)}
                className={styles.picker}></IonDatetime>
            </IonModal>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem className={styles.item} lines="none">
            <IonLabel>Start Time</IonLabel>
            <IonDatetimeButton datetime="start-time">
              {!startTime && <div slot="time-target">Select</div>}
            </IonDatetimeButton>
            <IonModal keepContentsMounted={true}>
              <IonDatetime
                id="start-time"
                presentation="time"
                showDefaultButtons
                placeholder="Tour start time"
                pickerFormat="h:mm A"
                displayFormat="h:mm A"
                minuteValues="0,15,30,45"
                value={startTime}
                onIonChange={(e) =>
                  setStartTime(
                    moment(e.detail.value, "HH:mm:ss a").format("HH:mm")
                  )
                }
                className={styles.picker}></IonDatetime>
            </IonModal>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem className={styles.item} lines="none">
            <IonLabel>End Time</IonLabel>
            <IonDatetimeButton datetime="end-time">
              {!endTime && <div slot="time-target">Select</div>}
            </IonDatetimeButton>
            <IonModal keepContentsMounted={true}>
              <IonDatetime
                id="end-time"
                presentation="time"
                showDefaultButtons
                placeholder="Tour end time"
                displayFormat="h:mm A"
                minuteValues="0,15,30,45"
                value={endTime}
                onIonChange={(e) =>
                  setEndTime(
                    moment(e.detail.value, "HH:mm:ss a").format("HH:mm")
                  )
                }
                className={styles.picker}></IonDatetime>
            </IonModal>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <Input
            name="address"
            label="Meetup address"
            mode="search"
            showLabel={true}
            placeholder="Meetup address"
            style={{ width: "100%" }}
            value={meetup.address ? meetup.address : ""}
            onChange={(value, name) => setMeetup({ ...meetup, address: value })}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem className={styles.item} lines="none">
            <IonLabel>Meetup Time</IonLabel>
            <IonDatetimeButton datetime="meetup-time">
              {!endTime && <div slot="time-target">Select</div>}
            </IonDatetimeButton>
            <IonModal keepContentsMounted={true}>
              <IonDatetime
                id="meetup-time"
                presentation="time"
                showDefaultButtons
                placeholder="Tour end time"
                displayFormat="h:mm A"
                minuteValues="0,15,30,45"
                value={meetup.time}
                onIonChange={(e) =>
                  setMeetup({
                    ...meetup,
                    time: moment(e.detail.value, "HH:mm:ss a").format("HH:mm"),
                  })
                }
                className={styles.picker}></IonDatetime>
            </IonModal>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <Input
            showLabel={true}
            name="description"
            label="Meetup description"
            placeholder="Meetup description"
            value={meetup.description ? meetup.description : ""}
            style={{ width: "100%" }}
            onChange={(value, name) =>
              setMeetup({ ...meetup, description: value })
            }
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem className={styles.item} lines="none">
            <IonLabel>General notes</IonLabel>
            <IonTextarea
              placeholder="General notes for client"
              value={note ? note : ""}
              onIonChange={(e) => setNote(e.detail.value)}></IonTextarea>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonButton
            disabled={
              !tour ||
              !tour.status ||
              tour.status === "archived" ||
              !title ||
              !status
            }
            shape="round"
            expand="block"
            className={styles.button}
            onClick={() =>
              update({
                date: moment(date).toISOString().substring(0, 10),
                startTime: startTime,
                endTime: endTime,
                meetupLocation: meetup.address,
                meetupTime: meetup.time,
                meetupDescription: meetup.description,
                generalNote: note,
                status,
                title,
              })
            }>
            Save changes
          </IonButton>
        </IonCol>
      </IonRow>
      {tour.shared === "unshared" && (
        <IonRow>
          <IonCol className={styles.note}>
            The tour will not be available to the client until you share it.
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
};

export default TourDetails;
