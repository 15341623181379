import { createAnimation } from "@ionic/core/components";

export const fadeAnimation = (baseEl, opts) => {
  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("opacity", 0, 1)
    .duration(200);

  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo("opacity", 1, 0)
    .duration(200);

  const animation = createAnimation()
    .addAnimation(enteringAnimation)
    .addAnimation(leavingAnimation);

  return animation;
};

export const fastFadeAnimation = (baseEl, opts) => {
  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("opacity", 0, 1)
    .duration(250);

  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo("opacity", 1, 0)
    .duration(250);

  const animation = createAnimation()
    .addAnimation(enteringAnimation)
    .addAnimation(leavingAnimation);

  return animation;
};
