import { useState, useEffect } from "react";
import { IonIcon, IonButton } from "@ionic/react";
import {
  closeOutline,
  peopleOutline,
  personAddOutline,
  personOutline,
} from "ionicons/icons";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import DetailsHeader from "../../../../components/TourDetailsHeader/tour-details-header.module";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import styles from "./tour-attendees.module.scss";
import Select from "../../../../components/Form/Select/select.component";
import DropdownContainer from "../../../../components/Form/DropdownContainer/dropdown-container.component";
import { getAgents, getClients } from "../../../../amplify/graphql.utils";
import SearchableSelect from "../../../../components/Form/SearchableSelect/searchable-search.component";

const TourAttendees = ({ isOpen, setIsOpen, tour, update }) => {
  const {
    users: { items: attendees },
    shared,
  } = tour;

  const [client, setClient] = useState(
    attendees.find((a) => a.role === "client")?.user
  );
  const [guests, setGuests] = useState(
    attendees.filter((a) => a.role === "guest").map((a) => a.user)
  );
  const [leadAgent, setLeadAgent] = useState(
    attendees.find((a) => a.role === "primaryagent")?.user
  );
  const [showingAgents, setShowingAgents] = useState(
    attendees.filter((a) => a.role === "showingagent").map((a) => a.user)
  );
  const [agents, setAgents] = useState([]);
  const [clientsList, setClientsList] = useState([]);

  const {
    ref: refAgent,
    isOpen: isOpenAgent,
    setIsOpen: setIsOpenAgent,
  } = useComponentVisible(false);

  useEffect(() => {
    const fetchAllClinets = async () => setClientsList(await getClients());

    const fetchAgents = async () => {
      try {
        const agents = await getAgents();
        setAgents(agents);
      } catch (err) {
        console.log(err);
      }
    };

    if (isOpen) {
      fetchAllClinets();
      fetchAgents();
    }
  }, [attendees, isOpen]);

  const handleUpdate = () => {
    update({
      client,
      guests,
      leadAgent,
      showingAgents,
    });
  };

  const handleClientsChange = async (val) => {
    setClientsList(await getClients(val));
  };

  const handleAgentsChange = async (val) => {
    setAgents(await getAgents(val));
  };

  return (
    <CardContainer>
      <div className={styles.attendees}>
        <DetailsHeader
          title="Tour attendees"
          icon={peopleOutline}
          isVisible={true}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        {isOpen && (
          <div className={styles.inputs}>
            <SearchableSelect
              title="Select the main client"
              type="radio"
              required={true}
              items={clientsList && clientsList.filter((c) => !c.deactivated)}
              selected={client?.id}
              setSelected={setClient}
              label={client ? `Client: ${client.displayName}` : "Select client"}
              onSave={(client) => setClient(client)}
              onChange={handleClientsChange}
              icon={client ? personOutline : personAddOutline}
              style={{ "--background": "white" }}
              canRemove={guests.length === 0}
              onRemove={(e) => {
                e.stopPropagation();
                setClient(null);
              }}
            />

            {guests.length > 0 && (
              <div className={styles.guests}>
                {guests
                  .filter((g) => g !== null)
                  .map((g, i) => (
                    <div className={styles.guest} key={i}>
                      <IonIcon className={styles.icon} icon={personOutline} />
                      <span className={styles.label}>
                        Additional guest: {g.displayName}
                      </span>
                      <IonIcon
                        className={styles.closeIcon}
                        icon={closeOutline}
                        onClick={() =>
                          setGuests(guests.filter((guest) => guest.id !== g.id))
                        }
                      />
                    </div>
                  ))}
              </div>
            )}

            {client && (
              <SearchableSelect
                title="Select additional guests"
                type="checkbox"
                items={
                  clientsList &&
                  clientsList
                    .filter((c) => !c.deactivated)
                    .filter((c) => c.id !== client.id)
                }
                selected={guests.map((g) => g.id)}
                setSelected={setGuests}
                onChange={handleClientsChange}
                label={
                  guests.length
                    ? `${guests.length} guests selected`
                    : "Add additional guests"
                }
                icon={personAddOutline}
              />
            )}

            <div ref={refAgent} className={styles.container}>
              <Select
                label="Lead agent"
                title={leadAgent && leadAgent.displayName}
                style={{ height: "4rem", width: "100%" }}
                buttonStyle={{ height: "100%" }}
                open={isOpenAgent}
                onClick={() => setIsOpenAgent(!isOpenAgent)}
              />
              <DropdownContainer
                open={isOpenAgent}
                style={{
                  position: "absolute",
                  top: "auto",
                  left: "auto",
                  maxHeight: "15rem",
                }}
              >
                {agents.map((agent) => (
                  <div
                    key={agent.id}
                    onClick={() => {
                      setLeadAgent(agent);
                      setIsOpenAgent(false);
                    }}
                    className={styles.agentDropdown}
                  >
                    {agent.displayName}
                  </div>
                ))}
              </DropdownContainer>
            </div>
            {leadAgent && (
              <SearchableSelect
                title="Select showing agents"
                type="checkbox"
                required={true}
                items={agents.filter((a) => !a.deactivated)}
                selected={showingAgents.map((a) => a.id)}
                setSelected={setShowingAgents}
                onChange={handleAgentsChange}
                label={
                  showingAgents.length
                    ? `${showingAgents.length} showing agents selected`
                    : "Add showing agents"
                }
                icon={personAddOutline}
              />
            )}

            <IonButton
              shape="round"
              expand="block"
              className={styles.button}
              disabled={
                !client ||
                !leadAgent ||
                !showingAgents.length ||
                !tour ||
                !tour.status ||
                tour.status === "archived"
              }
              onClick={handleUpdate}
            >
              Save changes
            </IonButton>

            <div className={styles.note}>
              {shared == "shared"
                ? "These saved changes will be visible to the client."
                : "The tour will not be available to the client until you share it."}
            </div>
          </div>
        )}
      </div>
    </CardContainer>
  );
};
export default TourAttendees;
