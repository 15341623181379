import {
  IonCol,
  IonGrid,
  IonRow,
  IonIcon,
  IonButton,
  IonContent,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  eyeOutline,
  homeOutline,
  searchOutline,
  starOutline,
} from "ionicons/icons";
import CustomModalHeader from "../../CustomModalHeader/custom-modal-header.component";
import styles from "./welcome.module.scss";

const Welcome = ({ setConfirmRegistration, setShowModal }) => {
  const history = useHistory();

  const handleClick = () => {
    setConfirmRegistration(false);
    setShowModal(false);
    history.replace("/home");
  };

  return (
    <>
      <CustomModalHeader title="Hello!" setShowModal={handleClick} />
      <IonContent>
        <IonGrid className={styles.welcome}>
          <IonRow>
            <IonCol className={styles.title}>
              Welcome to AECORN!{" "}
              <span>It’s wonderful to meet you.</span>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.subtitle}>
              Thank you for confirming your account! You’ve unlocked the ability
              to:
            </IonCol>
          </IonRow>
          <div className={styles.line} />
          <IonRow className={styles.infos}>
            <IonCol className={styles.icon}>
              <IonIcon icon={homeOutline} />
            </IonCol>
            <IonCol className={styles.content}>
              see all property details and request showing
            </IonCol>
          </IonRow>
          <div className={styles.line} />
          <IonRow className={styles.infos}>
            <IonCol className={styles.icon}>
              <IonIcon icon={searchOutline} />
            </IonCol>
            <IonCol className={styles.content}>
              <u>save your searches </u> for quick access
            </IonCol>
          </IonRow>
          <div className={styles.line} />
          <IonRow className={styles.infos}>
            <IonCol className={styles.icon}>
              <IonIcon icon={starOutline} />
            </IonCol>
            <IonCol className={styles.content}>
              <u>save favourites</u> and recieve update alerts
            </IonCol>
          </IonRow>
          <div className={styles.line} />
          <IonRow className={styles.infos}>
            <IonCol className={styles.icon}>
              <IonIcon icon={eyeOutline} />
            </IonCol>
            <IonCol className={styles.content}>
              schedule a <a>showing tour </a> with a AECORN agent
            </IonCol>
          </IonRow>
          <div className={styles.line} />

          <IonRow>
            <IonCol>
              <IonButton
                shape="round"
                className={styles.button}
                onClick={handleClick}
              >
                What are we waiting for?
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default Welcome;
