import { useState, useEffect, useRef } from "react";
import {
  IonLoading,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonDatetime,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonSkeletonText,
  IonDatetimeButton,
  IonModal,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonList,
  useIonAlert,
  IonSearchbar,
  IonNote,
} from "@ionic/react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';
import styles from "./add-showing.module.scss";
import {
  addOutline,
  calendarNumberOutline,
  chevronBackOutline,
  closeOutline,
  starOutline,
} from "ionicons/icons";
import Input from "../../../../../components/Form/Input/input.component";
import ListingDetails from "./component/listing-details.component";
import { getSearchResults } from "../../../../../api/repliers";
import Map from "./component/map.component";
import MapListingCard from "../../../../../components/Map/components/MapListingCard/map-listing.card.component";
import { buildAddress } from "../../../../../utils/functions";
import { TourService } from "../../../../../services/tourService";
import { FavoritesService } from "../../../../../services/favoritesService";
import moment from "moment";
import { ListingService } from "../../../../../services/listingService";

const AddShowing = ({
  tour,
  tourItems,
  update,
  close,
  nextListingOrder,
}) => {
  const [presentAlert] = useIonAlert();
  const searchbarRef = useRef();
  const [listing, setListing] = useState();
  const [toggleFavourites, setToggleFavourites] = useState(false);
  const [toggleBookedShowings, setToggleBookedShowings] = useState(false);
  const [favourites, setFavourites] = useState();
  const [bookedShowings, setBookedShowings] = useState([]);
  const [favLoading, setFavLoading] = useState(false);
  const [bookingsLoading, setBookingsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [status, setStatus] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [entryInfo, setEntryInfo] = useState("");
  const [note, setNote] = useState();

  const handleSearch = async (event) => {
    try {
      setLoading(true);
      let query = "";
      const target = event.target;
      if (target) {
        query = target.value.toLowerCase();
      }
      if (query.length > 0) {
        const listings = await getSearchResults(query, true);
        setResults(listings);
      } else {
        setResults([]);
      }
    } catch (error) {
      console.error(error);
      setResults([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchFavourites = async () => {
      setFavLoading(true);
      const favs = await FavoritesService.getFavorites();
      setFavourites(favs);
      setFavLoading(false);
    };

    const fetchBookedShowings = async () => {
      if (!tour.date) {
        return;
      }
      setBookingsLoading(true);
      const bookings = await TourService.getBookedShowingsByDate(tour.date);
      setBookedShowings(bookings);
      setBookingsLoading(false);
    };

    fetchFavourites();
    fetchBookedShowings();
  }, [tour]);

  const reset = () => {
    setListing(null);
    setStartTime(null);
    setEndTime(null);
    setListing(null);
    setStatus(null);
    setEntryInfo("");
    setNote();
  };
  const handleSubmit = async () => {
    setSubmitLoading(true);
    try {
      const showing = await TourService.addShowingToTour(tour, {
        mlsNumber: listing.mlsNumber,
        status,
        entryInfo,
        showingNote: note,
        startTime,
        endTime,
        order: nextListingOrder
      })
      update(showing);
      reset();
    } catch (err) {
      console.log(err);
    }
    setSubmitLoading(false);
  };

  const slideOpts = {
    spaceBetween: 20,
    slidesPerView: 1.2,
    initialSlide: 0,
    centeredSlides: true,
    loop: true,
  };

  const handleStartTimeChange = (event) => {
    const value = event.detail.value;
    const time = moment(value).format("HH:mm");
    setStartTime(time);
  };

  const handleEndTimeChange = (event) => {
    const value = event.detail.value;
    const time = moment(value).format("HH:mm");
    setEndTime(time);
  };

  const addFromFavourites = async (mls) => {
    reset();
    const listing = await ListingService.getListingByMlsNumber(mls);
    if (listing) {
      setListing(listing);
    }
    setToggleFavourites(false);
  };

  const addBookedShowing = (showing) => () => {
    reset();
    setStartTime(showing.startTime);
    setEndTime(showing.endTime);
    setListing(showing.listing);
    setStatus("confirmed");

    const instructionItems = showing.instructions.split("\n");
    const codeText = instructionItems.find((item) => item.includes("Code:"));
    if (codeText) {
      const code = codeText.split(":")[1].trim();
      setEntryInfo(code);
    }

    setNote(showing.instructions);
    setToggleBookedShowings(false);

    presentAlert({
      header: "Verify showing details",
      message:
        "Please verify the showing details and entry information. Make sure to remove any sensitive information from the client notes.",
      buttons: [
        {
          text: "OK",
          role: "confirm",
        },
      ],
    });
  };

  const renderBookedShowings = () => {
    return bookedShowings.map((showing) => {
      const listing = showing.listing;
      const isAlreadyAdded = tourItems.find(
        (item) => item.mlsNumber === listing.mlsNumber
      );
      const instructionItems = showing.instructions.split("\n");

      return (
        <IonCard key={showing.id}>
          <IonCardHeader>
            <IonCardTitle>{buildAddress(listing.address)}</IonCardTitle>
            <IonCardSubtitle>MLS#: {listing.mlsNumber}</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem lines="none" color="light">
                <IonLabel>Start Time</IonLabel>
                <IonLabel>{showing.startTime}</IonLabel>
              </IonItem>
              <IonItem lines="none" color="light">
                <IonLabel>End Time</IonLabel>
                <IonLabel>{showing.endTime}</IonLabel>
              </IonItem>
              <IonItem lines="none" color="light">
                <IonLabel style={{ fontWeight: "bold" }}>
                  Instructions:
                </IonLabel>
              </IonItem>
              <IonGrid>
                {instructionItems.map((item, index) => (
                  <IonRow key={index}>
                    {instructionItems
                      .slice(index, index + 2)
                      .map((subItem, subIndex) => (
                        <IonCol size="6" key={subIndex}>
                          <IonLabel>{subItem}</IonLabel>
                        </IonCol>
                      ))}
                  </IonRow>
                ))}
              </IonGrid>
            </IonList>
            <IonButton
              className={styles.button}
              shape="round"
              expand="full"
              disabled={isAlreadyAdded}
              onClick={addBookedShowing(showing)}>
              <IonIcon icon={addOutline} />
              Add
            </IonButton>
          </IonCardContent>
        </IonCard>
      );
    });
  };

  return (
    <>
      <IonHeader className={styles.appHeader}>
        <IonToolbar className={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              className={styles.backButton}
              slot="start"
              text="Back"
              onClick={close}>
              <IonIcon icon={chevronBackOutline} />
              Back
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton className={styles.clearButton} onClick={reset}>
              Clear
            </IonButton>
          </IonButtons>
          <IonTitle className={styles.title}>Add showing</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.addShowing}>
        <IonLoading isOpen={submitLoading} />
        <IonGrid className={styles.inputs}>
          <IonRow>
            <IonCol>
              <div className={styles.container}>
                {listing ? (
                  <IonItem
                    color="secondary"
                    detail
                    detailIcon={closeOutline}
                    onClick={() => reset()}>
                    <IonLabel>{buildAddress(listing.address)}</IonLabel>
                    <IonNote slot="end">MLS#: {listing.mlsNumber}</IonNote>
                  </IonItem>
                ) : (
                  <>
                    <IonSearchbar
                      ref={searchbarRef}
                      debounce={1000}
                      placeholder="location or MLS #"
                      onIonInput={(ev) => handleSearch(ev)}></IonSearchbar>
                    <IonList>
                      {loading ? (
                        <>
                          <IonSkeletonText animated />
                          <IonSkeletonText animated />
                          <IonSkeletonText animated />
                          <IonSkeletonText animated />
                        </>
                      ) : (
                        results.map((listing) => (
                          <IonItem
                            key={listing.mlsNumber}
                            color="light"
                            onClick={() => {
                              setListing(listing);
                              setResults([]);
                              searchbarRef.current.value = "";
                            }}>
                            <IonLabel>{buildAddress(listing.address)}</IonLabel>
                            <IonNote slot="end">
                              MLS#: {listing.mlsNumber}
                            </IonNote>
                          </IonItem>
                        ))
                      )}
                    </IonList>
                  </>
                )}
              </div>
            </IonCol>
          </IonRow>
          {!listing && (
            <>
              <IonRow>
                <IonCol>
                  <IonItem
                    className={`${styles.item} ${styles.favourite}`}
                    detail
                    detailIcon={
                      toggleBookedShowings
                        ? closeOutline
                        : calendarNumberOutline
                    }
                    lines="none"
                    onClick={() =>
                      setToggleBookedShowings(
                        (toggleBookedShowings) => !toggleBookedShowings
                      )
                    }>
                    <IonLabel>
                      {toggleBookedShowings
                        ? "Close booked showings"
                        : "Or choose from your booked showings"}
                    </IonLabel>
                  </IonItem>
                  {toggleBookedShowings && (
                    <>
                      {bookingsLoading ? (
                        <IonSkeletonText
                          animated
                          style={{ height: "8rem", padding: "0 1.5rem" }}
                        />
                      ) : bookedShowings && bookedShowings.length > 0 ? (
                        <div className={styles.bookedShowings}>
                          {renderBookedShowings()}
                        </div>
                      ) : (
                        <div className={styles.noFav}>
                          Search the listings or review your saved searches to
                          find new favourites.
                        </div>
                      )}
                    </>
                  )}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem
                    className={`${styles.item} ${styles.favourite}`}
                    detail
                    detailIcon={toggleFavourites ? closeOutline : starOutline}
                    lines="none"
                    onClick={() =>
                      setToggleFavourites(
                        (toggleFavourites) => !toggleFavourites
                      )
                    }>
                    <IonLabel>
                      {toggleFavourites
                        ? "Close favourites"
                        : "Or choose from your favourites"}
                    </IonLabel>
                  </IonItem>
                  {toggleFavourites && (
                    <>
                      {favLoading ? (
                        <IonSkeletonText
                          animated
                          style={{ height: "8rem", padding: "0 1.5rem" }}
                        />
                      ) : favourites && favourites.length > 0 ? (
                        <Swiper options={slideOpts}>
                          {favourites.map((fav) => (
                            <SwiperSlide key={fav.id}>
                              <MapListingCard
                                listing={JSON.parse(fav.listing)}
                                addShowing
                                handleAddShowing={() =>
                                  addFromFavourites(fav.mlsNumber)
                                }
                              />
                            </SwiperSlide>
                          ))}
                         </Swiper>
                      ) : (
                        <div className={styles.noFav}>
                          Search the listings or review your saved searches to
                          find new favourites.
                        </div>
                      )}
                    </>
                  )}
                </IonCol>
              </IonRow>
            </>
          )}

          <IonRow>
            <IonCol>
              <IonItem className={styles.item} lines="none">
                <IonLabel>Start Time</IonLabel>
                <IonDatetimeButton datetime="start-time" mode="time">
                  {!startTime && <div slot="time-target">Select</div>}
                </IonDatetimeButton>
                <IonModal keepContentsMounted={true}>
                  <IonDatetime
                    id="start-time"
                    presentation="time"
                    placeholder="Tour start time"
                    displayFormat="h:mm A"
                    minuteValues="0,15,30,45"
                    value={startTime}
                    onIonChange={handleStartTimeChange}
                    showDefaultButtons
                    className={styles.picker}></IonDatetime>
                </IonModal>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className={styles.item} lines="none">
                <IonLabel>End Time</IonLabel>
                <IonDatetimeButton datetime="end-time" mode="time">
                  {!endTime && <div slot="time-target">Select</div>}
                </IonDatetimeButton>
                <IonModal keepContentsMounted={true}>
                  <IonDatetime
                    id="end-time"
                    presentation="time"
                    placeholder="Tour end time"
                    displayFormat="h:mm A"
                    minuteValues="0,15,30,45"
                    value={endTime}
                    onIonChange={handleEndTimeChange}
                    showDefaultButtons
                    className={styles.picker}></IonDatetime>
                </IonModal>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className={styles.item} lines="none">
                <IonLabel>Showing status</IonLabel>
                <IonSelect
                  value={status}
                  okText="Save"
                  cancelText="Cancel"
                  onIonChange={(e) => setStatus(e.detail.value)}>
                  <IonSelectOption value="requested">Requested</IonSelectOption>
                  <IonSelectOption value="confirmed">Confirmed</IonSelectOption>
                  <IonSelectOption value="completed">Completed</IonSelectOption>
                  <IonSelectOption value="skipped">Skipped</IonSelectOption>
                  <IonSelectOption value="cancelled">Cancelled</IonSelectOption>
                  <IonSelectOption value="rejected">Rejected</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className={styles.container}>
                <Input
                  name="entryInfo"
                  label="Lockbox and entry info"
                  showLabel={false}
                  placeholder="Lockbox and entry info"
                  value={entryInfo}
                  onChange={(value, name) => setEntryInfo(value)}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className={styles.container}>
                <div className={styles.textareaLabel}>
                  Showing notes for client
                </div>
                <textarea
                  className={styles.textarea}
                  name="note"
                  placeholder="Showing notes. eg. Year built, offer date, etc."
                  value={note}
                  onChange={(event) => {
                    setNote(event.target.value);
                  }}></textarea>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div
                className={`${styles.map} ${listing && styles.mapTransparent}`}>
                {listing && <Map location={listing.map} />}
              </div>
            </IonCol>
          </IonRow>

          {listing && <ListingDetails listing={listing} />}
          <IonButton
            shape="round"
            expand="block"
            className={styles.button}
            disabled={!startTime || !endTime || !status}
            onClick={handleSubmit}>
            <IonIcon icon={addOutline} />
            Add showing to tour
          </IonButton>
        </IonGrid>
      </IonContent>
    </>
  );
};
export default AddShowing;
