import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";
import { PriceDataFormater } from "../../../../../../../../../../../utils/functions";
import { numberWithCommas } from "../../../../../../../../../../../utils/functions";
import styles from "../median-sold-price-and-list-price.module.scss";

const MedianSoldPriceAndListPriceChart = ({ marketData, isApp }) => {
  const CustomizedTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customTooltip}>
          <div className={styles.date}>{payload[0]["payload"]["date"]}</div>
          <div className={styles.area}>{`${
            payload[0]["name"]
          } : ${numberWithCommas(payload[0].value, true)}`}</div>
          <div className={styles.bar}>{`${
            payload[1]["name"]
          } : ${+payload[1].value.toFixed(2)}`}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      styles={{ position: "inherit" }}
    >
      <>
        <ComposedChart
          width={isApp ? 350 : 700}
          height={400}
          data={marketData}
          margin={{
            top: 10,
            right: 0,
            bottom: 20,
            left: -15,
          }}
        >
          {/* <LineChart
      width={800}
      height={450}
      data={marketData}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    > */}
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey="date"
            // scale="band"
            angle={-45}
            padding={{ top: 15 }}
            tickMargin={22}
            stroke="#6e6e6e"
            style={{ fontSize: "1.2rem" }}
          />
          <YAxis
            tickFormatter={PriceDataFormater}
            stroke="#6e6e6e"
            style={{ fontSize: "1.4rem" }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            stroke="#6e6e6e"
            style={{ fontSize: "1.4rem" }}
          />
          <Tooltip content={<CustomizedTooltip />} />
          <Legend
            verticalAlign="top"
            layout="vertical"
            margin={{ bottom: 8 }}
          />
          <Line
            type="monotone"
            dataKey="Median List Price"
            stroke="#007b94"
            dot={false}
          />
          {/* <Line
        type="monotone"
        dataKey="Med Sold Price"
        stroke="#6e6e6e"
        dot={false}
      /> */}
          {/* <Scatter
            yAxisId="right"
            dataKey="Sold Per List Price Ratio"
            fill="#6e6e6e"
          /> */}
          <Line
            yAxisId="right"
            type="monotone"
            dataKey="Sold Per List Price Ratio"
            stroke="#6e6e6e"
            dot={false}
          />
          {/* </LineChart> */}
        </ComposedChart>
      </>
    </ResponsiveContainer>
  );
};

export default MedianSoldPriceAndListPriceChart;
