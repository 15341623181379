import { useState, useEffect, useCallback, useRef, createRef } from "react";
import { useDispatch } from "react-redux";
import {
  IonModal,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLoading,
} from "@ionic/react";
import CustomModalHeader from "../../CustomModalHeader/custom-modal-header.component";
import withClient from "../../../../HOC/withClient/with-client";
import { sendRequestToAgent } from "../../../../api/ettie";
import styles from "./pre-request-form.module.scss";
import Input from "../../../../components/Form/Input/input.component";
import { updateToast } from "../../../../redux/ui/ui.actions";

const PreRequestForm = ({
  currentClient,
  info,
  showModal,
  setShowModal,
  parentRef,
  isUnit = false,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [text, setText] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    if (value) setText();
  }, [value]);

  const inputRefs = useRef({
    given_name: createRef(),
    family_name: createRef(),
    email: createRef(),
    phone_number: createRef(),
  });

  const [formData, setFormData] = useState({
    given_name: "",
    family_name: "",
    email: "",
    phone_number: "",
  });

  useEffect(() => {
    if (currentClient) {
      setFormData({
        email: currentClient.email,
        given_name: currentClient.givenName,
        family_name: currentClient.familyName,
        phone_number: currentClient.phone ? currentClient.phone.substr(2) : "",
      });
    }
  }, [currentClient]);

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleMessage = (event) => {
    setText(event.target.value);
  };

  const handlePreConRequest = async () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }

    if (isValid) {
      setLoading(true);
      setError("");

      const res = await sendRequestToAgent({
        name: `${formData.given_name} ${formData.family_name}`,
        givenName: formData.given_name,
        email: formData.email,
        phone: formData.phone_number,
        message: text,
        info: info,
        preCon: true,
      });
      if (res.success) {
        setLoading(false);
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: "Request sent successfully.",
          })
        );
        setShowModal(false);
      } else {
        setLoading(false);
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong! Please try again.",
          })
        );
      }
    }
  };

  return (
    <IonModal
      isOpen={showModal}
      swipeToClose={true}
      onDidDismiss={() => setShowModal(false)}
      presentingElement={parentRef}
    >
      <CustomModalHeader
        title={"Request form"}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <IonContent>
        <IonLoading isOpen={loading} />

        <IonGrid className={styles.requestContainer}>
          <IonRow>
            <IonCol className={styles.title}>
              {isUnit
                ? "Want more info on this unit?"
                : "Want more info on pre-con projects?"}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.subtitle}>
              We're excited to work with you! Submit this form and we will be in
              touch soon!
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.label}>Contact information</IonCol>
          </IonRow>
          <div className={styles.formFields}>
            <IonRow>
              <IonCol>
                <Input
                  required
                  name="given_name"
                  label="First name"
                  placeholder="First name"
                  type="text"
                  ref={inputRefs.current.given_name}
                  value={formData["given_name"]}
                  defaultValue={currentClient && currentClient.givenName}
                  onChange={(value, name) => handleChange(value, name)}
                  validation="required|min:2"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Input
                  required
                  name="family_name"
                  label="Last name"
                  placeholder="Last name"
                  type="text"
                  ref={inputRefs.current.family_name}
                  value={formData["family_name"]}
                  defaultValue={currentClient && currentClient.familyName}
                  onChange={(value, name) => handleChange(value, name)}
                  validation="required|min:2"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Input
                  required
                  name="phone_number"
                  label="Phone"
                  placeholder="Phone"
                  type="phone"
                  mode="tel"
                  ref={inputRefs.current.phone_number}
                  value={formData["phone_number"]}
                  defaultValue={
                    currentClient && currentClient.phone
                      ? currentClient.phone.substring(2)
                      : ""
                  }
                  onChange={(value, name) => handleChange(value, name)}
                  validation="required|phone"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Input
                  required
                  name="email"
                  label="Email"
                  placeholder="Email"
                  type="email"
                  mode="email"
                  ref={inputRefs.current.email}
                  value={formData["email"]}
                  defaultValue={currentClient && currentClient.email}
                  onChange={(value, name) =>
                    handleChange(value.toLowerCase(), name)
                  }
                  validation="required|email"
                />
              </IonCol>
            </IonRow>
          </div>
          <IonRow>
            <IonCol>
              <span className={styles.textarealabel}>Message</span>
              <textarea
                className={styles.textarea}
                placeholder={
                  isUnit
                    ? "I’d like to get more information on this unit."
                    : "I’d like to get more information on pre-construction projects."
                }
                name="textarea"
                value={text}
                onChange={handleMessage}
              ></textarea>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton
                shape="round"
                expand="block"
                className={styles.button}
                onClick={handlePreConRequest}
              >
                Submit
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.consent}>
              By submitting this form, I understand AECORN will share my
              information with registered real estate professionals.
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default withClient(PreRequestForm);
