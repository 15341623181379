import Select from "../../../Form/Select/select.component";
import RangeFilter from "../../../RangeFilter/range-filter.component";
import { price } from "../../data/filters.data";
import millify from "millify";
import useComponentVisible from "../../../../hooks/useComponentVisible";

const PriceFilter = ({
  isHomepage = false,
  filters: { minPrice, maxPrice, type },
  updateSingleFilter,
  disabled,
}) => {
  const { ref, isOpen, setIsOpen } = useComponentVisible(false);
  const range = ["sale", "sold", "pre-construction"].includes(type)
    ? price.sale
    : price.lease;

  const onChangeHandler = ({ type, value }) => {
    switch (type) {
      case "min":
        if (value > maxPrice) {
          updateSingleFilter({ minPrice: maxPrice, maxPrice: value });
        } else {
          updateSingleFilter({ minPrice: value });
        }

        break;
      case "max":
        if (value < minPrice) {
          updateSingleFilter({ minPrice: value, maxPrice: minPrice });
        } else {
          updateSingleFilter({ maxPrice: value });
        }
        break;
      default:
        return;
    }
  };

  const renderTitle = () => {
    if (isHomepage) {
      if ((!minPrice || +minPrice === 0) && (!maxPrice || maxPrice === "max")) {
        return "Price";
      } else
        return `$${millify(minPrice)}-${
          maxPrice === "max" ? maxPrice : millify(maxPrice)
        }`;
    } else
      return `$${millify(minPrice)}-${
        maxPrice === "max" ? maxPrice : millify(maxPrice)
      }`;
  };

  return (
    <div ref={ref}>
      <Select
        label={!isHomepage && "price range"}
        title={renderTitle()}
        open={isOpen}
        onClick={() => setIsOpen((open) => !open)}
        disabled={disabled}
        buttonStyle={
          isHomepage
            ? {
                border: "none",
                fontSize: "1.5rem",
                color: "white",
                backgroundColor: "#494949",
                borderColor: "#3d3d3d",
                iconColor: "#ffde6d",
              }
            : null
        }
      />

      <RangeFilter
        open={isOpen}
        range={range}
        value={{ min: minPrice, max: maxPrice }}
        onChange={onChangeHandler}
        isHomepage={isHomepage}
      />
    </div>
  );
};

export default PriceFilter;
