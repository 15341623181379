/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateBuilderInput = {
  id?: string | null,
  name: string,
  logo?: string | null,
};

export type ModelBuilderConditionInput = {
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  and?: Array< ModelBuilderConditionInput | null > | null,
  or?: Array< ModelBuilderConditionInput | null > | null,
  not?: ModelBuilderConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Builder = {
  __typename: "Builder",
  id: string,
  name: string,
  projects?: ModelProjectConnection | null,
  logo?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection",
  items:  Array<Project | null >,
  nextToken?: string | null,
};

export type Project = {
  __typename: "Project",
  name: string,
  streetNumber: string,
  streetName: string,
  city: string,
  province: string,
  latitude?: number | null,
  longitude?: number | null,
  builderId: string,
  builder?: Builder | null,
  architectId?: string | null,
  architect?: Architect | null,
  interiorDesignerId?: string | null,
  interiorDesigner?: InteriorDesigner | null,
  occupancy?: string | null,
  units?: number | null,
  stories?: number | null,
  startPrice?: number | null,
  endPrice?: number | null,
  minBeds?: number | null,
  maxBeds?: number | null,
  minSqft?: number | null,
  maxSqft?: number | null,
  pricePerSqft?: number | null,
  maintenanceFees?: string | null,
  parkingPrice?: number | null,
  lockerPrice?: number | null,
  phase?: ProjectPhase | null,
  availability?: ProjectAvailability | null,
  types?: Array< PropertyType | null > | null,
  description?: string | null,
  incentives?: string | null,
  amenities?: ModelProjectAmenitiesConnection | null,
  depositStructure?: string | null,
  logo?: string | null,
  media?: string | null,
  projectUnits?: ModelProjectUnitConnection | null,
  lastUpdatedOn?: string | null,
  isSoldOut?: boolean | null,
  commission?: number | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  builderProjectsId?: string | null,
  architectProjectsId?: string | null,
  interiorDesignerProjectsId?: string | null,
};

export type Architect = {
  __typename: "Architect",
  name: string,
  projects?: ModelProjectConnection | null,
  logo?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type InteriorDesigner = {
  __typename: "InteriorDesigner",
  name: string,
  projects?: ModelProjectConnection | null,
  logo?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export enum ProjectPhase {
  preconstruction = "preconstruction",
  underconstruction = "underconstruction",
  completed = "completed",
}


export enum ProjectAvailability {
  available = "available",
  comingsoon = "comingsoon",
}


export enum PropertyType {
  condo = "condo",
  townhouse = "townhouse",
  single = "single",
}


export type ModelProjectAmenitiesConnection = {
  __typename: "ModelProjectAmenitiesConnection",
  items:  Array<ProjectAmenities | null >,
  nextToken?: string | null,
};

export type ProjectAmenities = {
  __typename: "ProjectAmenities",
  id: string,
  projectID: string,
  amenityID: string,
  project: Project,
  amenity: Amenity,
  createdAt: string,
  updatedAt: string,
};

export type Amenity = {
  __typename: "Amenity",
  name: string,
  description?: string | null,
  projects?: ModelProjectAmenitiesConnection | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelProjectUnitConnection = {
  __typename: "ModelProjectUnitConnection",
  items:  Array<ProjectUnit | null >,
  nextToken?: string | null,
};

export type ProjectUnit = {
  __typename: "ProjectUnit",
  numBeds?: number | null,
  numBaths?: number | null,
  numParkings?: number | null,
  numLockers?: number | null,
  sqft?: number | null,
  price?: number | null,
  pricePerSqft?: number | null,
  projectId: string,
  project?: Project | null,
  type?: PropertyType | null,
  available?: number | null,
  sold?: number | null,
  media?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  projectProjectUnitsId?: string | null,
};

export type UpdateBuilderInput = {
  id: string,
  name?: string | null,
  logo?: string | null,
};

export type DeleteBuilderInput = {
  id: string,
};

export type CreateArchitectInput = {
  name: string,
  logo?: string | null,
  id?: string | null,
};

export type ModelArchitectConditionInput = {
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  and?: Array< ModelArchitectConditionInput | null > | null,
  or?: Array< ModelArchitectConditionInput | null > | null,
  not?: ModelArchitectConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateArchitectInput = {
  name?: string | null,
  logo?: string | null,
  id: string,
};

export type DeleteArchitectInput = {
  id: string,
};

export type CreateInteriorDesignerInput = {
  name: string,
  logo?: string | null,
  id?: string | null,
};

export type ModelInteriorDesignerConditionInput = {
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  and?: Array< ModelInteriorDesignerConditionInput | null > | null,
  or?: Array< ModelInteriorDesignerConditionInput | null > | null,
  not?: ModelInteriorDesignerConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateInteriorDesignerInput = {
  name?: string | null,
  logo?: string | null,
  id: string,
};

export type DeleteInteriorDesignerInput = {
  id: string,
};

export type CreateProjectInput = {
  name: string,
  streetNumber: string,
  streetName: string,
  city: string,
  province: string,
  latitude?: number | null,
  longitude?: number | null,
  builderId: string,
  architectId?: string | null,
  interiorDesignerId?: string | null,
  occupancy?: string | null,
  units?: number | null,
  stories?: number | null,
  startPrice?: number | null,
  endPrice?: number | null,
  minBeds?: number | null,
  maxBeds?: number | null,
  minSqft?: number | null,
  maxSqft?: number | null,
  pricePerSqft?: number | null,
  maintenanceFees?: string | null,
  parkingPrice?: number | null,
  lockerPrice?: number | null,
  phase?: ProjectPhase | null,
  availability?: ProjectAvailability | null,
  types?: Array< PropertyType | null > | null,
  description?: string | null,
  incentives?: string | null,
  depositStructure?: string | null,
  logo?: string | null,
  media?: string | null,
  lastUpdatedOn?: string | null,
  isSoldOut?: boolean | null,
  commission?: number | null,
  id?: string | null,
  builderProjectsId?: string | null,
  architectProjectsId?: string | null,
  interiorDesignerProjectsId?: string | null,
};

export type ModelProjectConditionInput = {
  name?: ModelStringInput | null,
  streetNumber?: ModelStringInput | null,
  streetName?: ModelStringInput | null,
  city?: ModelStringInput | null,
  province?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  builderId?: ModelIDInput | null,
  architectId?: ModelIDInput | null,
  interiorDesignerId?: ModelIDInput | null,
  occupancy?: ModelStringInput | null,
  units?: ModelIntInput | null,
  stories?: ModelIntInput | null,
  startPrice?: ModelFloatInput | null,
  endPrice?: ModelFloatInput | null,
  minBeds?: ModelFloatInput | null,
  maxBeds?: ModelFloatInput | null,
  minSqft?: ModelIntInput | null,
  maxSqft?: ModelIntInput | null,
  pricePerSqft?: ModelFloatInput | null,
  maintenanceFees?: ModelStringInput | null,
  parkingPrice?: ModelFloatInput | null,
  lockerPrice?: ModelFloatInput | null,
  phase?: ModelProjectPhaseInput | null,
  availability?: ModelProjectAvailabilityInput | null,
  types?: ModelPropertyTypeListInput | null,
  description?: ModelStringInput | null,
  incentives?: ModelStringInput | null,
  depositStructure?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  media?: ModelStringInput | null,
  lastUpdatedOn?: ModelStringInput | null,
  isSoldOut?: ModelBooleanInput | null,
  commission?: ModelFloatInput | null,
  and?: Array< ModelProjectConditionInput | null > | null,
  or?: Array< ModelProjectConditionInput | null > | null,
  not?: ModelProjectConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  builderProjectsId?: ModelIDInput | null,
  architectProjectsId?: ModelIDInput | null,
  interiorDesignerProjectsId?: ModelIDInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelProjectPhaseInput = {
  eq?: ProjectPhase | null,
  ne?: ProjectPhase | null,
};

export type ModelProjectAvailabilityInput = {
  eq?: ProjectAvailability | null,
  ne?: ProjectAvailability | null,
};

export type ModelPropertyTypeListInput = {
  eq?: Array< PropertyType | null > | null,
  ne?: Array< PropertyType | null > | null,
  contains?: PropertyType | null,
  notContains?: PropertyType | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateProjectInput = {
  name?: string | null,
  streetNumber?: string | null,
  streetName?: string | null,
  city?: string | null,
  province?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  builderId?: string | null,
  architectId?: string | null,
  interiorDesignerId?: string | null,
  occupancy?: string | null,
  units?: number | null,
  stories?: number | null,
  startPrice?: number | null,
  endPrice?: number | null,
  minBeds?: number | null,
  maxBeds?: number | null,
  minSqft?: number | null,
  maxSqft?: number | null,
  pricePerSqft?: number | null,
  maintenanceFees?: string | null,
  parkingPrice?: number | null,
  lockerPrice?: number | null,
  phase?: ProjectPhase | null,
  availability?: ProjectAvailability | null,
  types?: Array< PropertyType | null > | null,
  description?: string | null,
  incentives?: string | null,
  depositStructure?: string | null,
  logo?: string | null,
  media?: string | null,
  lastUpdatedOn?: string | null,
  isSoldOut?: boolean | null,
  commission?: number | null,
  id: string,
  builderProjectsId?: string | null,
  architectProjectsId?: string | null,
  interiorDesignerProjectsId?: string | null,
};

export type DeleteProjectInput = {
  id: string,
};

export type CreateProjectUnitInput = {
  numBeds?: number | null,
  numBaths?: number | null,
  numParkings?: number | null,
  numLockers?: number | null,
  sqft?: number | null,
  price?: number | null,
  pricePerSqft?: number | null,
  projectId: string,
  type?: PropertyType | null,
  available?: number | null,
  sold?: number | null,
  media?: string | null,
  id?: string | null,
  projectProjectUnitsId?: string | null,
};

export type ModelProjectUnitConditionInput = {
  numBeds?: ModelFloatInput | null,
  numBaths?: ModelFloatInput | null,
  numParkings?: ModelFloatInput | null,
  numLockers?: ModelFloatInput | null,
  sqft?: ModelIntInput | null,
  price?: ModelFloatInput | null,
  pricePerSqft?: ModelFloatInput | null,
  projectId?: ModelIDInput | null,
  type?: ModelPropertyTypeInput | null,
  available?: ModelIntInput | null,
  sold?: ModelIntInput | null,
  media?: ModelStringInput | null,
  and?: Array< ModelProjectUnitConditionInput | null > | null,
  or?: Array< ModelProjectUnitConditionInput | null > | null,
  not?: ModelProjectUnitConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  projectProjectUnitsId?: ModelIDInput | null,
};

export type ModelPropertyTypeInput = {
  eq?: PropertyType | null,
  ne?: PropertyType | null,
};

export type UpdateProjectUnitInput = {
  numBeds?: number | null,
  numBaths?: number | null,
  numParkings?: number | null,
  numLockers?: number | null,
  sqft?: number | null,
  price?: number | null,
  pricePerSqft?: number | null,
  projectId?: string | null,
  type?: PropertyType | null,
  available?: number | null,
  sold?: number | null,
  media?: string | null,
  id: string,
  projectProjectUnitsId?: string | null,
};

export type DeleteProjectUnitInput = {
  id: string,
};

export type CreateAmenityInput = {
  name: string,
  description?: string | null,
  id?: string | null,
};

export type ModelAmenityConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelAmenityConditionInput | null > | null,
  or?: Array< ModelAmenityConditionInput | null > | null,
  not?: ModelAmenityConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateAmenityInput = {
  name?: string | null,
  description?: string | null,
  id: string,
};

export type DeleteAmenityInput = {
  id: string,
};

export type CreateFeaturedInput = {
  title: string,
  subtitle?: string | null,
  filters?: string | null,
  isNearby?: boolean | null,
  isFavourite?: boolean | null,
  isSchool?: boolean | null,
  order?: number | null,
  status: FeaturedStatus,
  id?: string | null,
};

export enum FeaturedStatus {
  active = "active",
  inactive = "inactive",
}


export type ModelFeaturedConditionInput = {
  title?: ModelStringInput | null,
  subtitle?: ModelStringInput | null,
  filters?: ModelStringInput | null,
  isNearby?: ModelBooleanInput | null,
  isFavourite?: ModelBooleanInput | null,
  isSchool?: ModelBooleanInput | null,
  order?: ModelIntInput | null,
  status?: ModelFeaturedStatusInput | null,
  and?: Array< ModelFeaturedConditionInput | null > | null,
  or?: Array< ModelFeaturedConditionInput | null > | null,
  not?: ModelFeaturedConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelFeaturedStatusInput = {
  eq?: FeaturedStatus | null,
  ne?: FeaturedStatus | null,
};

export type Featured = {
  __typename: "Featured",
  title: string,
  subtitle?: string | null,
  filters?: string | null,
  isNearby?: boolean | null,
  isFavourite?: boolean | null,
  isSchool?: boolean | null,
  order?: number | null,
  status: FeaturedStatus,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFeaturedInput = {
  title?: string | null,
  subtitle?: string | null,
  filters?: string | null,
  isNearby?: boolean | null,
  isFavourite?: boolean | null,
  isSchool?: boolean | null,
  order?: number | null,
  status?: FeaturedStatus | null,
  id: string,
};

export type DeleteFeaturedInput = {
  id: string,
};

export type CreateHomeCardInput = {
  title: string,
  text?: string | null,
  image?: string | null,
  actionLabel?: string | null,
  actionLink?: string | null,
  inAppAction?: boolean | null,
  order?: number | null,
  status: FeaturedStatus,
  id?: string | null,
};

export type ModelHomeCardConditionInput = {
  title?: ModelStringInput | null,
  text?: ModelStringInput | null,
  image?: ModelStringInput | null,
  actionLabel?: ModelStringInput | null,
  actionLink?: ModelStringInput | null,
  inAppAction?: ModelBooleanInput | null,
  order?: ModelIntInput | null,
  status?: ModelFeaturedStatusInput | null,
  and?: Array< ModelHomeCardConditionInput | null > | null,
  or?: Array< ModelHomeCardConditionInput | null > | null,
  not?: ModelHomeCardConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type HomeCard = {
  __typename: "HomeCard",
  title: string,
  text?: string | null,
  image?: string | null,
  actionLabel?: string | null,
  actionLink?: string | null,
  inAppAction?: boolean | null,
  order?: number | null,
  status: FeaturedStatus,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateHomeCardInput = {
  title?: string | null,
  text?: string | null,
  image?: string | null,
  actionLabel?: string | null,
  actionLink?: string | null,
  inAppAction?: boolean | null,
  order?: number | null,
  status?: FeaturedStatus | null,
  id: string,
};

export type DeleteHomeCardInput = {
  id: string,
};

export type CreateProfileInput = {
  userId: string,
  buyingSelling?: boolean | null,
  leasingRenting?: boolean | null,
  justLooking?: boolean | null,
  preConstruction?: boolean | null,
  isRealtor?: boolean | null,
  id?: string | null,
};

export type ModelProfileConditionInput = {
  userId?: ModelStringInput | null,
  buyingSelling?: ModelBooleanInput | null,
  leasingRenting?: ModelBooleanInput | null,
  justLooking?: ModelBooleanInput | null,
  preConstruction?: ModelBooleanInput | null,
  isRealtor?: ModelBooleanInput | null,
  and?: Array< ModelProfileConditionInput | null > | null,
  or?: Array< ModelProfileConditionInput | null > | null,
  not?: ModelProfileConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Profile = {
  __typename: "Profile",
  userId: string,
  buyingSelling?: boolean | null,
  leasingRenting?: boolean | null,
  justLooking?: boolean | null,
  preConstruction?: boolean | null,
  isRealtor?: boolean | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateProfileInput = {
  userId?: string | null,
  buyingSelling?: boolean | null,
  leasingRenting?: boolean | null,
  justLooking?: boolean | null,
  preConstruction?: boolean | null,
  isRealtor?: boolean | null,
  id: string,
};

export type DeleteProfileInput = {
  id: string,
};

export type CreateFavouriteInput = {
  userId?: string | null,
  repliersID?: string | null,
  mlsNumber: string,
  listing: string,
  listingUpdate?: string | null,
  notification?: boolean | null,
  id?: string | null,
};

export type ModelFavouriteConditionInput = {
  userId?: ModelStringInput | null,
  repliersID?: ModelStringInput | null,
  mlsNumber?: ModelStringInput | null,
  listing?: ModelStringInput | null,
  listingUpdate?: ModelStringInput | null,
  notification?: ModelBooleanInput | null,
  and?: Array< ModelFavouriteConditionInput | null > | null,
  or?: Array< ModelFavouriteConditionInput | null > | null,
  not?: ModelFavouriteConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Favourite = {
  __typename: "Favourite",
  userId?: string | null,
  repliersID?: string | null,
  mlsNumber: string,
  listing: string,
  listingUpdate?: string | null,
  notification?: boolean | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFavouriteInput = {
  userId?: string | null,
  repliersID?: string | null,
  mlsNumber?: string | null,
  listing?: string | null,
  listingUpdate?: string | null,
  notification?: boolean | null,
  id: string,
};

export type DeleteFavouriteInput = {
  id: string,
};

export type CreatePreFavouriteInput = {
  userId?: string | null,
  projectId: string,
  notification?: boolean | null,
  id?: string | null,
};

export type ModelPreFavouriteConditionInput = {
  userId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  notification?: ModelBooleanInput | null,
  and?: Array< ModelPreFavouriteConditionInput | null > | null,
  or?: Array< ModelPreFavouriteConditionInput | null > | null,
  not?: ModelPreFavouriteConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type PreFavourite = {
  __typename: "PreFavourite",
  userId?: string | null,
  projectId: string,
  project?: Project | null,
  notification?: boolean | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePreFavouriteInput = {
  userId?: string | null,
  projectId?: string | null,
  notification?: boolean | null,
  id: string,
};

export type DeletePreFavouriteInput = {
  id: string,
};

export type CreateSearchInput = {
  name?: string | null,
  userId?: string | null,
  repliersID?: string | null,
  value?: string | null,
  notification?: boolean | null,
  id?: string | null,
};

export type ModelSearchConditionInput = {
  name?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  repliersID?: ModelStringInput | null,
  value?: ModelStringInput | null,
  notification?: ModelBooleanInput | null,
  and?: Array< ModelSearchConditionInput | null > | null,
  or?: Array< ModelSearchConditionInput | null > | null,
  not?: ModelSearchConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Search = {
  __typename: "Search",
  name?: string | null,
  userId?: string | null,
  repliersID?: string | null,
  value?: string | null,
  notification?: boolean | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSearchInput = {
  name?: string | null,
  userId?: string | null,
  repliersID?: string | null,
  value?: string | null,
  notification?: boolean | null,
  id: string,
};

export type DeleteSearchInput = {
  id: string,
};

export type CreateNotificationInput = {
  userId: string,
  schedule?: NotificationSchedule | null,
  type?: Array< NotificationType | null > | null,
  id?: string | null,
};

export enum NotificationSchedule {
  instantly = "instantly",
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
}


export enum NotificationType {
  email = "email",
  push = "push",
  sms = "sms",
}


export type ModelNotificationConditionInput = {
  userId?: ModelStringInput | null,
  schedule?: ModelNotificationScheduleInput | null,
  type?: ModelNotificationTypeListInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelNotificationScheduleInput = {
  eq?: NotificationSchedule | null,
  ne?: NotificationSchedule | null,
};

export type ModelNotificationTypeListInput = {
  eq?: Array< NotificationType | null > | null,
  ne?: Array< NotificationType | null > | null,
  contains?: NotificationType | null,
  notContains?: NotificationType | null,
};

export type Notification = {
  __typename: "Notification",
  userId: string,
  schedule?: NotificationSchedule | null,
  type?: Array< NotificationType | null > | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateNotificationInput = {
  userId?: string | null,
  schedule?: NotificationSchedule | null,
  type?: Array< NotificationType | null > | null,
  id: string,
};

export type DeleteNotificationInput = {
  id: string,
};

export type CreateUserInput = {
  repliersID?: string | null,
  givenName: string,
  familyName: string,
  name: string,
  displayName: string,
  email: string,
  phone?: string | null,
  profilePicture?: string | null,
  role: UserRole,
  subscribed?: boolean | null,
  currency?: string | null,
  measurement?: Measurement | null,
  deviceToken?: string | null,
  requestedTour?: boolean | null,
  toursNote?: string | null,
  deactivated?: boolean | null,
  id?: string | null,
  userAgentId?: string | null,
  userClientId?: string | null,
  userNotificationsId?: string | null,
  userProfileId?: string | null,
};

export enum UserRole {
  admin = "admin",
  agent = "agent",
  client = "client",
}


export enum Measurement {
  imperial = "imperial",
  metric = "metric",
}


export type ModelUserConditionInput = {
  repliersID?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  profilePicture?: ModelStringInput | null,
  role?: ModelUserRoleInput | null,
  subscribed?: ModelBooleanInput | null,
  currency?: ModelStringInput | null,
  measurement?: ModelMeasurementInput | null,
  deviceToken?: ModelStringInput | null,
  requestedTour?: ModelBooleanInput | null,
  toursNote?: ModelStringInput | null,
  deactivated?: ModelBooleanInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userAgentId?: ModelIDInput | null,
  userClientId?: ModelIDInput | null,
  userNotificationsId?: ModelIDInput | null,
  userProfileId?: ModelIDInput | null,
  id?: ModelStringInput | null,
};

export type ModelUserRoleInput = {
  eq?: UserRole | null,
  ne?: UserRole | null,
};

export type ModelMeasurementInput = {
  eq?: Measurement | null,
  ne?: Measurement | null,
};

export type User = {
  __typename: "User",
  repliersID?: string | null,
  givenName: string,
  familyName: string,
  name: string,
  displayName: string,
  email: string,
  phone?: string | null,
  profilePicture?: string | null,
  role: UserRole,
  agent?: Agent | null,
  client?: Client | null,
  subscribed?: boolean | null,
  currency?: string | null,
  measurement?: Measurement | null,
  notifications?: Notification | null,
  deviceToken?: string | null,
  profile?: Profile | null,
  requestedTour?: boolean | null,
  tours?: ModelUserTourConnection | null,
  toursNote?: string | null,
  deactivated?: boolean | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  userAgentId?: string | null,
  userClientId?: string | null,
  userNotificationsId?: string | null,
  userProfileId?: string | null,
};

export type Agent = {
  __typename: "Agent",
  userId: string,
  website?: string | null,
  licenseNumber?: string | null,
  areas?: Array< string | null > | null,
  availability?: Array< string | null > | null,
  brokerage?: Brokerage | null,
  clients?: ModelClientConnection | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  brokerageAgentsId?: string | null,
};

export type Brokerage = {
  __typename: "Brokerage",
  name: string,
  agents?: ModelAgentConnection | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelAgentConnection = {
  __typename: "ModelAgentConnection",
  items:  Array<Agent | null >,
  nextToken?: string | null,
};

export type ModelClientConnection = {
  __typename: "ModelClientConnection",
  items:  Array<Client | null >,
  nextToken?: string | null,
};

export type Client = {
  __typename: "Client",
  userId: string,
  agent?: Agent | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  agentClientsId?: string | null,
  clientAgentId?: string | null,
};

export type ModelUserTourConnection = {
  __typename: "ModelUserTourConnection",
  items:  Array<UserTour | null >,
  nextToken?: string | null,
};

export type UserTour = {
  __typename: "UserTour",
  creator?: string | null,
  userId: string,
  tourId: string,
  user?: User | null,
  tour?: Tour | null,
  role: TourRole,
  seen?: boolean | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  userToursId?: string | null,
  tourUsersId?: string | null,
};

export type Tour = {
  __typename: "Tour",
  title?: string | null,
  creator?: string | null,
  users?: ModelUserTourConnection | null,
  clients?: Array< string | null > | null,
  primaryAgent?: string | null,
  showingAgents?: Array< string | null > | null,
  status?: TourStatus | null,
  privateNote?: string | null,
  generalNote?: string | null,
  tourItems?: ModelTourItemConnection | null,
  meetupLocation?: string | null,
  meetupDescription?: string | null,
  meetupTime?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  date?: string | null,
  shared?: ShareStatus | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export enum TourStatus {
  pending = "pending",
  ready = "ready",
  cancelled = "cancelled",
  archived = "archived",
  draft = "draft",
  completed = "completed",
}


export type ModelTourItemConnection = {
  __typename: "ModelTourItemConnection",
  items:  Array<TourItem | null >,
  nextToken?: string | null,
};

export type TourItem = {
  __typename: "TourItem",
  creator?: string | null,
  tourId: string,
  tour?: Tour | null,
  clients?: Array< string | null > | null,
  primaryAgent?: string | null,
  showingAgents?: Array< string | null > | null,
  mlsNumber?: string | null,
  status?: TourItemStatus | null,
  startTime?: string | null,
  endTime?: string | null,
  order?: number | null,
  showingNote?: string | null,
  entryInfo?: string | null,
  reviews?: ModelReviewConnection | null,
  notes?: ModelNoteConnection | null,
  photos?: ModelPhotoConnection | null,
  recordings?: ModelRecordingConnection | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  tourTourItemsId?: string | null,
};

export enum TourItemStatus {
  completed = "completed",
  confirmed = "confirmed",
  requested = "requested",
  cancelled = "cancelled",
  rejected = "rejected",
  skipped = "skipped",
}


export type ModelReviewConnection = {
  __typename: "ModelReviewConnection",
  items:  Array<Review | null >,
  nextToken?: string | null,
};

export type Review = {
  __typename: "Review",
  userId?: string | null,
  tourItemId: string,
  rate?: number | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  tourItemReviewsId?: string | null,
};

export type ModelNoteConnection = {
  __typename: "ModelNoteConnection",
  items:  Array<Note | null >,
  nextToken?: string | null,
};

export type Note = {
  __typename: "Note",
  userId?: string | null,
  content?: string | null,
  tourItemId: string,
  id: string,
  createdAt: string,
  updatedAt: string,
  tourItemNotesId?: string | null,
};

export type ModelPhotoConnection = {
  __typename: "ModelPhotoConnection",
  items:  Array<Photo | null >,
  nextToken?: string | null,
};

export type Photo = {
  __typename: "Photo",
  userId?: string | null,
  file?: S3Object | null,
  path?: string | null,
  createdAt: string,
  tourItemId: string,
  id: string,
  updatedAt: string,
  tourItemPhotosId?: string | null,
};

export type S3Object = {
  __typename: "S3Object",
  bucket: string,
  region: string,
  key: string,
};

export type ModelRecordingConnection = {
  __typename: "ModelRecordingConnection",
  items:  Array<Recording | null >,
  nextToken?: string | null,
};

export type Recording = {
  __typename: "Recording",
  userId?: string | null,
  file?: S3Object | null,
  path?: string | null,
  createdAt: string,
  tourItemId: string,
  id: string,
  updatedAt: string,
  tourItemRecordingsId?: string | null,
};

export enum ShareStatus {
  shared = "shared",
  unshared = "unshared",
}


export enum TourRole {
  primaryagent = "primaryagent",
  showingagent = "showingagent",
  client = "client",
  guest = "guest",
}


export type UpdateUserInput = {
  repliersID?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  name?: string | null,
  displayName?: string | null,
  email?: string | null,
  phone?: string | null,
  profilePicture?: string | null,
  role?: UserRole | null,
  subscribed?: boolean | null,
  currency?: string | null,
  measurement?: Measurement | null,
  deviceToken?: string | null,
  requestedTour?: boolean | null,
  toursNote?: string | null,
  deactivated?: boolean | null,
  id: string,
  userAgentId?: string | null,
  userClientId?: string | null,
  userNotificationsId?: string | null,
  userProfileId?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateAgentInput = {
  userId: string,
  website?: string | null,
  licenseNumber?: string | null,
  areas?: Array< string | null > | null,
  availability?: Array< string | null > | null,
  id?: string | null,
  brokerageAgentsId?: string | null,
};

export type ModelAgentConditionInput = {
  userId?: ModelStringInput | null,
  website?: ModelStringInput | null,
  licenseNumber?: ModelStringInput | null,
  areas?: ModelStringInput | null,
  availability?: ModelStringInput | null,
  and?: Array< ModelAgentConditionInput | null > | null,
  or?: Array< ModelAgentConditionInput | null > | null,
  not?: ModelAgentConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  brokerageAgentsId?: ModelIDInput | null,
};

export type UpdateAgentInput = {
  userId?: string | null,
  website?: string | null,
  licenseNumber?: string | null,
  areas?: Array< string | null > | null,
  availability?: Array< string | null > | null,
  id: string,
  brokerageAgentsId?: string | null,
};

export type DeleteAgentInput = {
  id: string,
};

export type CreateClientInput = {
  userId: string,
  id?: string | null,
  agentClientsId?: string | null,
  clientAgentId?: string | null,
};

export type ModelClientConditionInput = {
  userId?: ModelStringInput | null,
  and?: Array< ModelClientConditionInput | null > | null,
  or?: Array< ModelClientConditionInput | null > | null,
  not?: ModelClientConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  agentClientsId?: ModelIDInput | null,
  clientAgentId?: ModelIDInput | null,
};

export type UpdateClientInput = {
  userId?: string | null,
  id: string,
  agentClientsId?: string | null,
  clientAgentId?: string | null,
};

export type DeleteClientInput = {
  id: string,
};

export type CreateBrokerageInput = {
  name: string,
  id?: string | null,
};

export type ModelBrokerageConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelBrokerageConditionInput | null > | null,
  or?: Array< ModelBrokerageConditionInput | null > | null,
  not?: ModelBrokerageConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateBrokerageInput = {
  name?: string | null,
  id: string,
};

export type DeleteBrokerageInput = {
  id: string,
};

export type CreateUserTourInput = {
  creator?: string | null,
  userId: string,
  tourId: string,
  role: TourRole,
  seen?: boolean | null,
  id?: string | null,
  userToursId?: string | null,
  tourUsersId?: string | null,
};

export type ModelUserTourConditionInput = {
  creator?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  tourId?: ModelIDInput | null,
  role?: ModelTourRoleInput | null,
  seen?: ModelBooleanInput | null,
  and?: Array< ModelUserTourConditionInput | null > | null,
  or?: Array< ModelUserTourConditionInput | null > | null,
  not?: ModelUserTourConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userToursId?: ModelIDInput | null,
  tourUsersId?: ModelIDInput | null,
};

export type ModelTourRoleInput = {
  eq?: TourRole | null,
  ne?: TourRole | null,
};

export type UpdateUserTourInput = {
  creator?: string | null,
  userId?: string | null,
  tourId?: string | null,
  role?: TourRole | null,
  seen?: boolean | null,
  id: string,
  userToursId?: string | null,
  tourUsersId?: string | null,
};

export type DeleteUserTourInput = {
  id: string,
};

export type CreateTourInput = {
  title?: string | null,
  creator?: string | null,
  clients?: Array< string | null > | null,
  primaryAgent?: string | null,
  showingAgents?: Array< string | null > | null,
  status?: TourStatus | null,
  privateNote?: string | null,
  generalNote?: string | null,
  meetupLocation?: string | null,
  meetupDescription?: string | null,
  meetupTime?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  date?: string | null,
  shared?: ShareStatus | null,
  id?: string | null,
};

export type ModelTourConditionInput = {
  title?: ModelStringInput | null,
  creator?: ModelIDInput | null,
  clients?: ModelIDInput | null,
  primaryAgent?: ModelIDInput | null,
  showingAgents?: ModelIDInput | null,
  status?: ModelTourStatusInput | null,
  privateNote?: ModelStringInput | null,
  generalNote?: ModelStringInput | null,
  meetupLocation?: ModelStringInput | null,
  meetupDescription?: ModelStringInput | null,
  meetupTime?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  date?: ModelStringInput | null,
  shared?: ModelShareStatusInput | null,
  and?: Array< ModelTourConditionInput | null > | null,
  or?: Array< ModelTourConditionInput | null > | null,
  not?: ModelTourConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelTourStatusInput = {
  eq?: TourStatus | null,
  ne?: TourStatus | null,
};

export type ModelShareStatusInput = {
  eq?: ShareStatus | null,
  ne?: ShareStatus | null,
};

export type UpdateTourInput = {
  title?: string | null,
  creator?: string | null,
  clients?: Array< string | null > | null,
  primaryAgent?: string | null,
  showingAgents?: Array< string | null > | null,
  status?: TourStatus | null,
  privateNote?: string | null,
  generalNote?: string | null,
  meetupLocation?: string | null,
  meetupDescription?: string | null,
  meetupTime?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  date?: string | null,
  shared?: ShareStatus | null,
  id: string,
};

export type DeleteTourInput = {
  id: string,
};

export type CreateTourItemInput = {
  creator?: string | null,
  tourId: string,
  clients?: Array< string | null > | null,
  primaryAgent?: string | null,
  showingAgents?: Array< string | null > | null,
  mlsNumber?: string | null,
  status?: TourItemStatus | null,
  startTime?: string | null,
  endTime?: string | null,
  order?: number | null,
  showingNote?: string | null,
  entryInfo?: string | null,
  id?: string | null,
  tourTourItemsId?: string | null,
};

export type ModelTourItemConditionInput = {
  creator?: ModelIDInput | null,
  tourId?: ModelIDInput | null,
  clients?: ModelIDInput | null,
  primaryAgent?: ModelIDInput | null,
  showingAgents?: ModelIDInput | null,
  mlsNumber?: ModelStringInput | null,
  status?: ModelTourItemStatusInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  order?: ModelIntInput | null,
  showingNote?: ModelStringInput | null,
  entryInfo?: ModelStringInput | null,
  and?: Array< ModelTourItemConditionInput | null > | null,
  or?: Array< ModelTourItemConditionInput | null > | null,
  not?: ModelTourItemConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  tourTourItemsId?: ModelIDInput | null,
};

export type ModelTourItemStatusInput = {
  eq?: TourItemStatus | null,
  ne?: TourItemStatus | null,
};

export type UpdateTourItemInput = {
  creator?: string | null,
  tourId?: string | null,
  clients?: Array< string | null > | null,
  primaryAgent?: string | null,
  showingAgents?: Array< string | null > | null,
  mlsNumber?: string | null,
  status?: TourItemStatus | null,
  startTime?: string | null,
  endTime?: string | null,
  order?: number | null,
  showingNote?: string | null,
  entryInfo?: string | null,
  id: string,
  tourTourItemsId?: string | null,
};

export type DeleteTourItemInput = {
  id: string,
};

export type CreateShowingInput = {
  userId: string,
  mlsNumber?: string | null,
  date?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  instructions?: string | null,
  id?: string | null,
};

export type ModelShowingConditionInput = {
  userId?: ModelStringInput | null,
  mlsNumber?: ModelStringInput | null,
  date?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  and?: Array< ModelShowingConditionInput | null > | null,
  or?: Array< ModelShowingConditionInput | null > | null,
  not?: ModelShowingConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Showing = {
  __typename: "Showing",
  userId: string,
  mlsNumber?: string | null,
  date?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  instructions?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateShowingInput = {
  userId?: string | null,
  mlsNumber?: string | null,
  date?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  instructions?: string | null,
  id: string,
};

export type DeleteShowingInput = {
  id: string,
};

export type CreateReviewInput = {
  userId?: string | null,
  tourItemId: string,
  rate?: number | null,
  id?: string | null,
  tourItemReviewsId?: string | null,
};

export type ModelReviewConditionInput = {
  userId?: ModelStringInput | null,
  tourItemId?: ModelIDInput | null,
  rate?: ModelIntInput | null,
  and?: Array< ModelReviewConditionInput | null > | null,
  or?: Array< ModelReviewConditionInput | null > | null,
  not?: ModelReviewConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  tourItemReviewsId?: ModelIDInput | null,
};

export type UpdateReviewInput = {
  userId?: string | null,
  tourItemId?: string | null,
  rate?: number | null,
  id: string,
  tourItemReviewsId?: string | null,
};

export type DeleteReviewInput = {
  id: string,
};

export type CreateNoteInput = {
  userId?: string | null,
  content?: string | null,
  tourItemId: string,
  id?: string | null,
  tourItemNotesId?: string | null,
};

export type ModelNoteConditionInput = {
  userId?: ModelStringInput | null,
  content?: ModelStringInput | null,
  tourItemId?: ModelIDInput | null,
  and?: Array< ModelNoteConditionInput | null > | null,
  or?: Array< ModelNoteConditionInput | null > | null,
  not?: ModelNoteConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  tourItemNotesId?: ModelIDInput | null,
};

export type UpdateNoteInput = {
  userId?: string | null,
  content?: string | null,
  tourItemId?: string | null,
  id: string,
  tourItemNotesId?: string | null,
};

export type DeleteNoteInput = {
  id: string,
};

export type CreatePhotoInput = {
  userId?: string | null,
  file?: S3ObjectInput | null,
  path?: string | null,
  createdAt?: string | null,
  tourItemId: string,
  id?: string | null,
  tourItemPhotosId?: string | null,
};

export type S3ObjectInput = {
  bucket: string,
  region: string,
  key: string,
};

export type ModelPhotoConditionInput = {
  userId?: ModelStringInput | null,
  path?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  tourItemId?: ModelIDInput | null,
  and?: Array< ModelPhotoConditionInput | null > | null,
  or?: Array< ModelPhotoConditionInput | null > | null,
  not?: ModelPhotoConditionInput | null,
  updatedAt?: ModelStringInput | null,
  tourItemPhotosId?: ModelIDInput | null,
};

export type UpdatePhotoInput = {
  userId?: string | null,
  file?: S3ObjectInput | null,
  path?: string | null,
  createdAt?: string | null,
  tourItemId?: string | null,
  id: string,
  tourItemPhotosId?: string | null,
};

export type DeletePhotoInput = {
  id: string,
};

export type CreateRecordingInput = {
  userId?: string | null,
  file?: S3ObjectInput | null,
  path?: string | null,
  createdAt?: string | null,
  tourItemId: string,
  id?: string | null,
  tourItemRecordingsId?: string | null,
};

export type ModelRecordingConditionInput = {
  userId?: ModelStringInput | null,
  path?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  tourItemId?: ModelIDInput | null,
  and?: Array< ModelRecordingConditionInput | null > | null,
  or?: Array< ModelRecordingConditionInput | null > | null,
  not?: ModelRecordingConditionInput | null,
  updatedAt?: ModelStringInput | null,
  tourItemRecordingsId?: ModelIDInput | null,
};

export type UpdateRecordingInput = {
  userId?: string | null,
  file?: S3ObjectInput | null,
  path?: string | null,
  createdAt?: string | null,
  tourItemId?: string | null,
  id: string,
  tourItemRecordingsId?: string | null,
};

export type DeleteRecordingInput = {
  id: string,
};

export type CreateSchoolInput = {
  objVer?: string | null,
  name?: string | null,
  isCatholic?: boolean | null,
  isPublic?: boolean | null,
  isUseSchool?: boolean | null,
  gradeFrom?: number | null,
  gradeEnd?: number | null,
  isElementary?: boolean | null,
  isMiddle?: boolean | null,
  isHigh?: boolean | null,
  isEnglish?: boolean | null,
  isFrenchImmersion?: boolean | null,
  isExtendedFrench?: boolean | null,
  isAP?: boolean | null,
  isArts?: boolean | null,
  isGifted?: boolean | null,
  isIB?: boolean | null,
  isSport?: boolean | null,
  g3Avg?: number | null,
  g3WeightedAvg?: number | null,
  g6Avg?: number | null,
  g6WeightedAvg?: number | null,
  g9Avg?: number | null,
  g9APMWeightedAvg?: number | null,
  g9ACMWeightedAvg?: number | null,
  g10Avg?: number | null,
  ettieOverallAvg?: number | null,
  g3Rank?: number | null,
  g3RankTotal?: number | null,
  g6Rank?: number | null,
  g6RankTotal?: number | null,
  g9Rank?: number | null,
  g9RankTotal?: number | null,
  g9ACRank?: number | null,
  g9ACTotalRank?: number | null,
  g9APRank?: number | null,
  g9APTotalRank?: number | null,
  g10Rank?: number | null,
  g10RankTotal?: number | null,
  EQAOScore?: number | null,
  yearMark?: string | null,
  province?: string | null,
  nation?: string | null,
  url?: string | null,
  schoolBoardId?: string | null,
  address?: string | null,
  city?: string | null,
  postalCode?: string | null,
  phone?: string | null,
  fax?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  comments?: string | null,
  boundaryArray?: string | null,
  schoolsAssocationArray?: string | null,
  lastReviewDate?: string | null,
  id?: string | null,
  schoolBoardSchoolsId?: string | null,
};

export type ModelSchoolConditionInput = {
  objVer?: ModelStringInput | null,
  name?: ModelStringInput | null,
  isCatholic?: ModelBooleanInput | null,
  isPublic?: ModelBooleanInput | null,
  isUseSchool?: ModelBooleanInput | null,
  gradeFrom?: ModelIntInput | null,
  gradeEnd?: ModelIntInput | null,
  isElementary?: ModelBooleanInput | null,
  isMiddle?: ModelBooleanInput | null,
  isHigh?: ModelBooleanInput | null,
  isEnglish?: ModelBooleanInput | null,
  isFrenchImmersion?: ModelBooleanInput | null,
  isExtendedFrench?: ModelBooleanInput | null,
  isAP?: ModelBooleanInput | null,
  isArts?: ModelBooleanInput | null,
  isGifted?: ModelBooleanInput | null,
  isIB?: ModelBooleanInput | null,
  isSport?: ModelBooleanInput | null,
  g3Avg?: ModelFloatInput | null,
  g3WeightedAvg?: ModelFloatInput | null,
  g6Avg?: ModelFloatInput | null,
  g6WeightedAvg?: ModelFloatInput | null,
  g9Avg?: ModelFloatInput | null,
  g9APMWeightedAvg?: ModelFloatInput | null,
  g9ACMWeightedAvg?: ModelFloatInput | null,
  g10Avg?: ModelFloatInput | null,
  ettieOverallAvg?: ModelFloatInput | null,
  g3Rank?: ModelIntInput | null,
  g3RankTotal?: ModelIntInput | null,
  g6Rank?: ModelIntInput | null,
  g6RankTotal?: ModelIntInput | null,
  g9Rank?: ModelIntInput | null,
  g9RankTotal?: ModelIntInput | null,
  g9ACRank?: ModelIntInput | null,
  g9ACTotalRank?: ModelIntInput | null,
  g9APRank?: ModelIntInput | null,
  g9APTotalRank?: ModelIntInput | null,
  g10Rank?: ModelIntInput | null,
  g10RankTotal?: ModelIntInput | null,
  EQAOScore?: ModelFloatInput | null,
  yearMark?: ModelStringInput | null,
  province?: ModelStringInput | null,
  nation?: ModelStringInput | null,
  url?: ModelStringInput | null,
  schoolBoardId?: ModelIDInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  fax?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  comments?: ModelStringInput | null,
  boundaryArray?: ModelStringInput | null,
  schoolsAssocationArray?: ModelStringInput | null,
  lastReviewDate?: ModelStringInput | null,
  and?: Array< ModelSchoolConditionInput | null > | null,
  or?: Array< ModelSchoolConditionInput | null > | null,
  not?: ModelSchoolConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  schoolBoardSchoolsId?: ModelIDInput | null,
};

export type School = {
  __typename: "School",
  objVer?: string | null,
  name?: string | null,
  isCatholic?: boolean | null,
  isPublic?: boolean | null,
  isUseSchool?: boolean | null,
  gradeFrom?: number | null,
  gradeEnd?: number | null,
  isElementary?: boolean | null,
  isMiddle?: boolean | null,
  isHigh?: boolean | null,
  isEnglish?: boolean | null,
  isFrenchImmersion?: boolean | null,
  isExtendedFrench?: boolean | null,
  isAP?: boolean | null,
  isArts?: boolean | null,
  isGifted?: boolean | null,
  isIB?: boolean | null,
  isSport?: boolean | null,
  g3Avg?: number | null,
  g3WeightedAvg?: number | null,
  g6Avg?: number | null,
  g6WeightedAvg?: number | null,
  g9Avg?: number | null,
  g9APMWeightedAvg?: number | null,
  g9ACMWeightedAvg?: number | null,
  g10Avg?: number | null,
  ettieOverallAvg?: number | null,
  g3Rank?: number | null,
  g3RankTotal?: number | null,
  g6Rank?: number | null,
  g6RankTotal?: number | null,
  g9Rank?: number | null,
  g9RankTotal?: number | null,
  g9ACRank?: number | null,
  g9ACTotalRank?: number | null,
  g9APRank?: number | null,
  g9APTotalRank?: number | null,
  g10Rank?: number | null,
  g10RankTotal?: number | null,
  EQAOScore?: number | null,
  yearMark?: string | null,
  province?: string | null,
  nation?: string | null,
  url?: string | null,
  schoolBoardId?: string | null,
  schoolBoard?: SchoolBoard | null,
  ratings?: ModelSchoolRatingConnection | null,
  address?: string | null,
  city?: string | null,
  postalCode?: string | null,
  phone?: string | null,
  fax?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  comments?: string | null,
  boundaryArray?: string | null,
  schoolsAssocationArray?: string | null,
  lastReviewDate?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  schoolBoardSchoolsId?: string | null,
};

export type SchoolBoard = {
  __typename: "SchoolBoard",
  objVer?: string | null,
  name?: string | null,
  url?: string | null,
  boardType?: number | null,
  province?: string | null,
  nation?: string | null,
  address?: string | null,
  city?: string | null,
  postalCode?: string | null,
  phone?: string | null,
  fax?: string | null,
  schools?: ModelSchoolConnection | null,
  scores?: ModelSchoolBoardScoreConnection | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelSchoolConnection = {
  __typename: "ModelSchoolConnection",
  items:  Array<School | null >,
  nextToken?: string | null,
};

export type ModelSchoolBoardScoreConnection = {
  __typename: "ModelSchoolBoardScoreConnection",
  items:  Array<SchoolBoardScore | null >,
  nextToken?: string | null,
};

export type SchoolBoardScore = {
  __typename: "SchoolBoardScore",
  schoolBoardId?: string | null,
  schoolBoard?: SchoolBoard | null,
  yearMark?: string | null,
  year?: number | null,
  totalAvg?: number | null,
  g3STU?: number | null,
  g6STU?: number | null,
  g9ACSTU?: number | null,
  g9APSTU?: number | null,
  g3TS?: number | null,
  g6TS?: number | null,
  g9TS?: number | null,
  g10OSSLTFSTUA?: number | null,
  g10OSSLTFSTUP?: number | null,
  g10OSSLTFSUCCESS?: number | null,
  g3AR?: number | null,
  g3AW?: number | null,
  g3AM?: number | null,
  g6AR?: number | null,
  g6AW?: number | null,
  g6AM?: number | null,
  g9APM?: number | null,
  g9ACM?: number | null,
  g3Last5Avg?: number | null,
  g6Last5Avg?: number | null,
  g9Last5Avg?: number | null,
  g3FindSchoolRating?: number | null,
  g6FindSchoolRating?: number | null,
  g9FindSchoolRating?: number | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  schoolBoardScoresId?: string | null,
};

export type ModelSchoolRatingConnection = {
  __typename: "ModelSchoolRatingConnection",
  items:  Array<SchoolRating | null >,
  nextToken?: string | null,
};

export type SchoolRating = {
  __typename: "SchoolRating",
  objVer?: string | null,
  schoolId?: string | null,
  school?: School | null,
  yearMark?: string | null,
  year?: number | null,
  G3STU?: number | null,
  G3AR?: number | null,
  G3AW?: number | null,
  G3AM?: number | null,
  G3Rank?: number | null,
  G3Total?: number | null,
  G3FindSchoolRating?: number | null,
  G6STU?: number | null,
  G6AR?: number | null,
  G6AW?: number | null,
  G6AM?: number | null,
  G6Rank?: number | null,
  G6Total?: number | null,
  G6FindSchoolRating?: number | null,
  G9ACSTU?: number | null,
  G9ACM?: number | null,
  G9ACRank?: number | null,
  G9ACTotal?: number | null,
  G9APSTU?: number | null,
  G9APM?: number | null,
  G9APRank?: number | null,
  G9APTotal?: number | null,
  G9Rank?: number | null,
  G9Total?: number | null,
  G9FindSchoolRating?: number | null,
  G10OSSLTFSTUA?: number | null,
  G10OSSLTFSTUP?: number | null,
  G10OSSLTFSUCCESS?: number | null,
  G3Last5Avg?: number | null,
  G6Last5Avg?: number | null,
  G9Last5Avg?: number | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  schoolRatingsId?: string | null,
};

export type UpdateSchoolInput = {
  objVer?: string | null,
  name?: string | null,
  isCatholic?: boolean | null,
  isPublic?: boolean | null,
  isUseSchool?: boolean | null,
  gradeFrom?: number | null,
  gradeEnd?: number | null,
  isElementary?: boolean | null,
  isMiddle?: boolean | null,
  isHigh?: boolean | null,
  isEnglish?: boolean | null,
  isFrenchImmersion?: boolean | null,
  isExtendedFrench?: boolean | null,
  isAP?: boolean | null,
  isArts?: boolean | null,
  isGifted?: boolean | null,
  isIB?: boolean | null,
  isSport?: boolean | null,
  g3Avg?: number | null,
  g3WeightedAvg?: number | null,
  g6Avg?: number | null,
  g6WeightedAvg?: number | null,
  g9Avg?: number | null,
  g9APMWeightedAvg?: number | null,
  g9ACMWeightedAvg?: number | null,
  g10Avg?: number | null,
  ettieOverallAvg?: number | null,
  g3Rank?: number | null,
  g3RankTotal?: number | null,
  g6Rank?: number | null,
  g6RankTotal?: number | null,
  g9Rank?: number | null,
  g9RankTotal?: number | null,
  g9ACRank?: number | null,
  g9ACTotalRank?: number | null,
  g9APRank?: number | null,
  g9APTotalRank?: number | null,
  g10Rank?: number | null,
  g10RankTotal?: number | null,
  EQAOScore?: number | null,
  yearMark?: string | null,
  province?: string | null,
  nation?: string | null,
  url?: string | null,
  schoolBoardId?: string | null,
  address?: string | null,
  city?: string | null,
  postalCode?: string | null,
  phone?: string | null,
  fax?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  comments?: string | null,
  boundaryArray?: string | null,
  schoolsAssocationArray?: string | null,
  lastReviewDate?: string | null,
  id: string,
  schoolBoardSchoolsId?: string | null,
};

export type DeleteSchoolInput = {
  id: string,
};

export type CreateSchoolBoardInput = {
  objVer?: string | null,
  name?: string | null,
  url?: string | null,
  boardType?: number | null,
  province?: string | null,
  nation?: string | null,
  address?: string | null,
  city?: string | null,
  postalCode?: string | null,
  phone?: string | null,
  fax?: string | null,
  id?: string | null,
};

export type ModelSchoolBoardConditionInput = {
  objVer?: ModelStringInput | null,
  name?: ModelStringInput | null,
  url?: ModelStringInput | null,
  boardType?: ModelIntInput | null,
  province?: ModelStringInput | null,
  nation?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  fax?: ModelStringInput | null,
  and?: Array< ModelSchoolBoardConditionInput | null > | null,
  or?: Array< ModelSchoolBoardConditionInput | null > | null,
  not?: ModelSchoolBoardConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateSchoolBoardInput = {
  objVer?: string | null,
  name?: string | null,
  url?: string | null,
  boardType?: number | null,
  province?: string | null,
  nation?: string | null,
  address?: string | null,
  city?: string | null,
  postalCode?: string | null,
  phone?: string | null,
  fax?: string | null,
  id: string,
};

export type DeleteSchoolBoardInput = {
  id: string,
};

export type CreateSchoolBoardScoreInput = {
  schoolBoardId?: string | null,
  yearMark?: string | null,
  year?: number | null,
  totalAvg?: number | null,
  g3STU?: number | null,
  g6STU?: number | null,
  g9ACSTU?: number | null,
  g9APSTU?: number | null,
  g3TS?: number | null,
  g6TS?: number | null,
  g9TS?: number | null,
  g10OSSLTFSTUA?: number | null,
  g10OSSLTFSTUP?: number | null,
  g10OSSLTFSUCCESS?: number | null,
  g3AR?: number | null,
  g3AW?: number | null,
  g3AM?: number | null,
  g6AR?: number | null,
  g6AW?: number | null,
  g6AM?: number | null,
  g9APM?: number | null,
  g9ACM?: number | null,
  g3Last5Avg?: number | null,
  g6Last5Avg?: number | null,
  g9Last5Avg?: number | null,
  g3FindSchoolRating?: number | null,
  g6FindSchoolRating?: number | null,
  g9FindSchoolRating?: number | null,
  id?: string | null,
  schoolBoardScoresId?: string | null,
};

export type ModelSchoolBoardScoreConditionInput = {
  schoolBoardId?: ModelIDInput | null,
  yearMark?: ModelStringInput | null,
  year?: ModelIntInput | null,
  totalAvg?: ModelFloatInput | null,
  g3STU?: ModelIntInput | null,
  g6STU?: ModelIntInput | null,
  g9ACSTU?: ModelIntInput | null,
  g9APSTU?: ModelIntInput | null,
  g3TS?: ModelIntInput | null,
  g6TS?: ModelIntInput | null,
  g9TS?: ModelIntInput | null,
  g10OSSLTFSTUA?: ModelIntInput | null,
  g10OSSLTFSTUP?: ModelIntInput | null,
  g10OSSLTFSUCCESS?: ModelIntInput | null,
  g3AR?: ModelFloatInput | null,
  g3AW?: ModelFloatInput | null,
  g3AM?: ModelFloatInput | null,
  g6AR?: ModelFloatInput | null,
  g6AW?: ModelFloatInput | null,
  g6AM?: ModelFloatInput | null,
  g9APM?: ModelFloatInput | null,
  g9ACM?: ModelFloatInput | null,
  g3Last5Avg?: ModelFloatInput | null,
  g6Last5Avg?: ModelFloatInput | null,
  g9Last5Avg?: ModelFloatInput | null,
  g3FindSchoolRating?: ModelFloatInput | null,
  g6FindSchoolRating?: ModelFloatInput | null,
  g9FindSchoolRating?: ModelFloatInput | null,
  and?: Array< ModelSchoolBoardScoreConditionInput | null > | null,
  or?: Array< ModelSchoolBoardScoreConditionInput | null > | null,
  not?: ModelSchoolBoardScoreConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  schoolBoardScoresId?: ModelIDInput | null,
};

export type UpdateSchoolBoardScoreInput = {
  schoolBoardId?: string | null,
  yearMark?: string | null,
  year?: number | null,
  totalAvg?: number | null,
  g3STU?: number | null,
  g6STU?: number | null,
  g9ACSTU?: number | null,
  g9APSTU?: number | null,
  g3TS?: number | null,
  g6TS?: number | null,
  g9TS?: number | null,
  g10OSSLTFSTUA?: number | null,
  g10OSSLTFSTUP?: number | null,
  g10OSSLTFSUCCESS?: number | null,
  g3AR?: number | null,
  g3AW?: number | null,
  g3AM?: number | null,
  g6AR?: number | null,
  g6AW?: number | null,
  g6AM?: number | null,
  g9APM?: number | null,
  g9ACM?: number | null,
  g3Last5Avg?: number | null,
  g6Last5Avg?: number | null,
  g9Last5Avg?: number | null,
  g3FindSchoolRating?: number | null,
  g6FindSchoolRating?: number | null,
  g9FindSchoolRating?: number | null,
  id: string,
  schoolBoardScoresId?: string | null,
};

export type DeleteSchoolBoardScoreInput = {
  id: string,
};

export type CreateSchoolRatingInput = {
  objVer?: string | null,
  schoolId?: string | null,
  yearMark?: string | null,
  year?: number | null,
  G3STU?: number | null,
  G3AR?: number | null,
  G3AW?: number | null,
  G3AM?: number | null,
  G3Rank?: number | null,
  G3Total?: number | null,
  G3FindSchoolRating?: number | null,
  G6STU?: number | null,
  G6AR?: number | null,
  G6AW?: number | null,
  G6AM?: number | null,
  G6Rank?: number | null,
  G6Total?: number | null,
  G6FindSchoolRating?: number | null,
  G9ACSTU?: number | null,
  G9ACM?: number | null,
  G9ACRank?: number | null,
  G9ACTotal?: number | null,
  G9APSTU?: number | null,
  G9APM?: number | null,
  G9APRank?: number | null,
  G9APTotal?: number | null,
  G9Rank?: number | null,
  G9Total?: number | null,
  G9FindSchoolRating?: number | null,
  G10OSSLTFSTUA?: number | null,
  G10OSSLTFSTUP?: number | null,
  G10OSSLTFSUCCESS?: number | null,
  G3Last5Avg?: number | null,
  G6Last5Avg?: number | null,
  G9Last5Avg?: number | null,
  id?: string | null,
  schoolRatingsId?: string | null,
};

export type ModelSchoolRatingConditionInput = {
  objVer?: ModelStringInput | null,
  schoolId?: ModelIDInput | null,
  yearMark?: ModelStringInput | null,
  year?: ModelIntInput | null,
  G3STU?: ModelIntInput | null,
  G3AR?: ModelIntInput | null,
  G3AW?: ModelIntInput | null,
  G3AM?: ModelIntInput | null,
  G3Rank?: ModelIntInput | null,
  G3Total?: ModelIntInput | null,
  G3FindSchoolRating?: ModelIntInput | null,
  G6STU?: ModelIntInput | null,
  G6AR?: ModelIntInput | null,
  G6AW?: ModelIntInput | null,
  G6AM?: ModelIntInput | null,
  G6Rank?: ModelIntInput | null,
  G6Total?: ModelIntInput | null,
  G6FindSchoolRating?: ModelIntInput | null,
  G9ACSTU?: ModelIntInput | null,
  G9ACM?: ModelIntInput | null,
  G9ACRank?: ModelIntInput | null,
  G9ACTotal?: ModelIntInput | null,
  G9APSTU?: ModelIntInput | null,
  G9APM?: ModelIntInput | null,
  G9APRank?: ModelIntInput | null,
  G9APTotal?: ModelIntInput | null,
  G9Rank?: ModelIntInput | null,
  G9Total?: ModelIntInput | null,
  G9FindSchoolRating?: ModelIntInput | null,
  G10OSSLTFSTUA?: ModelIntInput | null,
  G10OSSLTFSTUP?: ModelIntInput | null,
  G10OSSLTFSUCCESS?: ModelIntInput | null,
  G3Last5Avg?: ModelIntInput | null,
  G6Last5Avg?: ModelIntInput | null,
  G9Last5Avg?: ModelIntInput | null,
  and?: Array< ModelSchoolRatingConditionInput | null > | null,
  or?: Array< ModelSchoolRatingConditionInput | null > | null,
  not?: ModelSchoolRatingConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  schoolRatingsId?: ModelIDInput | null,
};

export type UpdateSchoolRatingInput = {
  objVer?: string | null,
  schoolId?: string | null,
  yearMark?: string | null,
  year?: number | null,
  G3STU?: number | null,
  G3AR?: number | null,
  G3AW?: number | null,
  G3AM?: number | null,
  G3Rank?: number | null,
  G3Total?: number | null,
  G3FindSchoolRating?: number | null,
  G6STU?: number | null,
  G6AR?: number | null,
  G6AW?: number | null,
  G6AM?: number | null,
  G6Rank?: number | null,
  G6Total?: number | null,
  G6FindSchoolRating?: number | null,
  G9ACSTU?: number | null,
  G9ACM?: number | null,
  G9ACRank?: number | null,
  G9ACTotal?: number | null,
  G9APSTU?: number | null,
  G9APM?: number | null,
  G9APRank?: number | null,
  G9APTotal?: number | null,
  G9Rank?: number | null,
  G9Total?: number | null,
  G9FindSchoolRating?: number | null,
  G10OSSLTFSTUA?: number | null,
  G10OSSLTFSTUP?: number | null,
  G10OSSLTFSUCCESS?: number | null,
  G3Last5Avg?: number | null,
  G6Last5Avg?: number | null,
  G9Last5Avg?: number | null,
  id: string,
  schoolRatingsId?: string | null,
};

export type DeleteSchoolRatingInput = {
  id: string,
};

export type CreateProjectAmenitiesInput = {
  id?: string | null,
  projectID: string,
  amenityID: string,
};

export type ModelProjectAmenitiesConditionInput = {
  projectID?: ModelIDInput | null,
  amenityID?: ModelIDInput | null,
  and?: Array< ModelProjectAmenitiesConditionInput | null > | null,
  or?: Array< ModelProjectAmenitiesConditionInput | null > | null,
  not?: ModelProjectAmenitiesConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateProjectAmenitiesInput = {
  id: string,
  projectID?: string | null,
  amenityID?: string | null,
};

export type DeleteProjectAmenitiesInput = {
  id: string,
};

export type ModelBuilderFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBuilderFilterInput | null > | null,
  or?: Array< ModelBuilderFilterInput | null > | null,
  not?: ModelBuilderFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelBuilderConnection = {
  __typename: "ModelBuilderConnection",
  items:  Array<Builder | null >,
  nextToken?: string | null,
};

export type ModelArchitectFilterInput = {
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelArchitectFilterInput | null > | null,
  or?: Array< ModelArchitectFilterInput | null > | null,
  not?: ModelArchitectFilterInput | null,
};

export type ModelArchitectConnection = {
  __typename: "ModelArchitectConnection",
  items:  Array<Architect | null >,
  nextToken?: string | null,
};

export type ModelInteriorDesignerFilterInput = {
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInteriorDesignerFilterInput | null > | null,
  or?: Array< ModelInteriorDesignerFilterInput | null > | null,
  not?: ModelInteriorDesignerFilterInput | null,
};

export type ModelInteriorDesignerConnection = {
  __typename: "ModelInteriorDesignerConnection",
  items:  Array<InteriorDesigner | null >,
  nextToken?: string | null,
};

export type ModelProjectFilterInput = {
  name?: ModelStringInput | null,
  streetNumber?: ModelStringInput | null,
  streetName?: ModelStringInput | null,
  city?: ModelStringInput | null,
  province?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  builderId?: ModelIDInput | null,
  architectId?: ModelIDInput | null,
  interiorDesignerId?: ModelIDInput | null,
  occupancy?: ModelStringInput | null,
  units?: ModelIntInput | null,
  stories?: ModelIntInput | null,
  startPrice?: ModelFloatInput | null,
  endPrice?: ModelFloatInput | null,
  minBeds?: ModelFloatInput | null,
  maxBeds?: ModelFloatInput | null,
  minSqft?: ModelIntInput | null,
  maxSqft?: ModelIntInput | null,
  pricePerSqft?: ModelFloatInput | null,
  maintenanceFees?: ModelStringInput | null,
  parkingPrice?: ModelFloatInput | null,
  lockerPrice?: ModelFloatInput | null,
  phase?: ModelProjectPhaseInput | null,
  availability?: ModelProjectAvailabilityInput | null,
  types?: ModelPropertyTypeListInput | null,
  description?: ModelStringInput | null,
  incentives?: ModelStringInput | null,
  depositStructure?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  media?: ModelStringInput | null,
  lastUpdatedOn?: ModelStringInput | null,
  isSoldOut?: ModelBooleanInput | null,
  commission?: ModelFloatInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProjectFilterInput | null > | null,
  or?: Array< ModelProjectFilterInput | null > | null,
  not?: ModelProjectFilterInput | null,
  builderProjectsId?: ModelIDInput | null,
  architectProjectsId?: ModelIDInput | null,
  interiorDesignerProjectsId?: ModelIDInput | null,
};

export type ModelProjectUnitFilterInput = {
  numBeds?: ModelFloatInput | null,
  numBaths?: ModelFloatInput | null,
  numParkings?: ModelFloatInput | null,
  numLockers?: ModelFloatInput | null,
  sqft?: ModelIntInput | null,
  price?: ModelFloatInput | null,
  pricePerSqft?: ModelFloatInput | null,
  projectId?: ModelIDInput | null,
  type?: ModelPropertyTypeInput | null,
  available?: ModelIntInput | null,
  sold?: ModelIntInput | null,
  media?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProjectUnitFilterInput | null > | null,
  or?: Array< ModelProjectUnitFilterInput | null > | null,
  not?: ModelProjectUnitFilterInput | null,
  projectProjectUnitsId?: ModelIDInput | null,
};

export type ModelAmenityFilterInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAmenityFilterInput | null > | null,
  or?: Array< ModelAmenityFilterInput | null > | null,
  not?: ModelAmenityFilterInput | null,
};

export type ModelAmenityConnection = {
  __typename: "ModelAmenityConnection",
  items:  Array<Amenity | null >,
  nextToken?: string | null,
};

export type ModelFeaturedFilterInput = {
  title?: ModelStringInput | null,
  subtitle?: ModelStringInput | null,
  filters?: ModelStringInput | null,
  isNearby?: ModelBooleanInput | null,
  isFavourite?: ModelBooleanInput | null,
  isSchool?: ModelBooleanInput | null,
  order?: ModelIntInput | null,
  status?: ModelFeaturedStatusInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFeaturedFilterInput | null > | null,
  or?: Array< ModelFeaturedFilterInput | null > | null,
  not?: ModelFeaturedFilterInput | null,
};

export type ModelFeaturedConnection = {
  __typename: "ModelFeaturedConnection",
  items:  Array<Featured | null >,
  nextToken?: string | null,
};

export type ModelHomeCardFilterInput = {
  title?: ModelStringInput | null,
  text?: ModelStringInput | null,
  image?: ModelStringInput | null,
  actionLabel?: ModelStringInput | null,
  actionLink?: ModelStringInput | null,
  inAppAction?: ModelBooleanInput | null,
  order?: ModelIntInput | null,
  status?: ModelFeaturedStatusInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelHomeCardFilterInput | null > | null,
  or?: Array< ModelHomeCardFilterInput | null > | null,
  not?: ModelHomeCardFilterInput | null,
};

export type ModelHomeCardConnection = {
  __typename: "ModelHomeCardConnection",
  items:  Array<HomeCard | null >,
  nextToken?: string | null,
};

export type ModelProfileFilterInput = {
  userId?: ModelStringInput | null,
  buyingSelling?: ModelBooleanInput | null,
  leasingRenting?: ModelBooleanInput | null,
  justLooking?: ModelBooleanInput | null,
  preConstruction?: ModelBooleanInput | null,
  isRealtor?: ModelBooleanInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProfileFilterInput | null > | null,
  or?: Array< ModelProfileFilterInput | null > | null,
  not?: ModelProfileFilterInput | null,
};

export type ModelProfileConnection = {
  __typename: "ModelProfileConnection",
  items:  Array<Profile | null >,
  nextToken?: string | null,
};

export type ModelFavouriteFilterInput = {
  userId?: ModelStringInput | null,
  repliersID?: ModelStringInput | null,
  mlsNumber?: ModelStringInput | null,
  listing?: ModelStringInput | null,
  listingUpdate?: ModelStringInput | null,
  notification?: ModelBooleanInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFavouriteFilterInput | null > | null,
  or?: Array< ModelFavouriteFilterInput | null > | null,
  not?: ModelFavouriteFilterInput | null,
};

export type ModelFavouriteConnection = {
  __typename: "ModelFavouriteConnection",
  items:  Array<Favourite | null >,
  nextToken?: string | null,
};

export type ModelPreFavouriteFilterInput = {
  userId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  notification?: ModelBooleanInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPreFavouriteFilterInput | null > | null,
  or?: Array< ModelPreFavouriteFilterInput | null > | null,
  not?: ModelPreFavouriteFilterInput | null,
};

export type ModelPreFavouriteConnection = {
  __typename: "ModelPreFavouriteConnection",
  items:  Array<PreFavourite | null >,
  nextToken?: string | null,
};

export type ModelSearchFilterInput = {
  name?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  repliersID?: ModelStringInput | null,
  value?: ModelStringInput | null,
  notification?: ModelBooleanInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSearchFilterInput | null > | null,
  or?: Array< ModelSearchFilterInput | null > | null,
  not?: ModelSearchFilterInput | null,
};

export type ModelSearchConnection = {
  __typename: "ModelSearchConnection",
  items:  Array<Search | null >,
  nextToken?: string | null,
};

export type ModelNotificationFilterInput = {
  userId?: ModelStringInput | null,
  schedule?: ModelNotificationScheduleInput | null,
  type?: ModelNotificationTypeListInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  repliersID?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  profilePicture?: ModelStringInput | null,
  role?: ModelUserRoleInput | null,
  subscribed?: ModelBooleanInput | null,
  currency?: ModelStringInput | null,
  measurement?: ModelMeasurementInput | null,
  deviceToken?: ModelStringInput | null,
  requestedTour?: ModelBooleanInput | null,
  toursNote?: ModelStringInput | null,
  deactivated?: ModelBooleanInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  userAgentId?: ModelIDInput | null,
  userClientId?: ModelIDInput | null,
  userNotificationsId?: ModelIDInput | null,
  userProfileId?: ModelIDInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelAgentFilterInput = {
  userId?: ModelStringInput | null,
  website?: ModelStringInput | null,
  licenseNumber?: ModelStringInput | null,
  areas?: ModelStringInput | null,
  availability?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAgentFilterInput | null > | null,
  or?: Array< ModelAgentFilterInput | null > | null,
  not?: ModelAgentFilterInput | null,
  brokerageAgentsId?: ModelIDInput | null,
};

export type ModelClientFilterInput = {
  userId?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClientFilterInput | null > | null,
  or?: Array< ModelClientFilterInput | null > | null,
  not?: ModelClientFilterInput | null,
  agentClientsId?: ModelIDInput | null,
  clientAgentId?: ModelIDInput | null,
};

export type ModelBrokerageFilterInput = {
  name?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBrokerageFilterInput | null > | null,
  or?: Array< ModelBrokerageFilterInput | null > | null,
  not?: ModelBrokerageFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelBrokerageConnection = {
  __typename: "ModelBrokerageConnection",
  items:  Array<Brokerage | null >,
  nextToken?: string | null,
};

export type ModelUserTourFilterInput = {
  creator?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  tourId?: ModelIDInput | null,
  role?: ModelTourRoleInput | null,
  seen?: ModelBooleanInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserTourFilterInput | null > | null,
  or?: Array< ModelUserTourFilterInput | null > | null,
  not?: ModelUserTourFilterInput | null,
  userToursId?: ModelIDInput | null,
  tourUsersId?: ModelIDInput | null,
};

export type ModelTourFilterInput = {
  title?: ModelStringInput | null,
  creator?: ModelIDInput | null,
  clients?: ModelIDInput | null,
  primaryAgent?: ModelIDInput | null,
  showingAgents?: ModelIDInput | null,
  status?: ModelTourStatusInput | null,
  privateNote?: ModelStringInput | null,
  generalNote?: ModelStringInput | null,
  meetupLocation?: ModelStringInput | null,
  meetupDescription?: ModelStringInput | null,
  meetupTime?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  date?: ModelStringInput | null,
  shared?: ModelShareStatusInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTourFilterInput | null > | null,
  or?: Array< ModelTourFilterInput | null > | null,
  not?: ModelTourFilterInput | null,
};

export type ModelTourConnection = {
  __typename: "ModelTourConnection",
  items:  Array<Tour | null >,
  nextToken?: string | null,
};

export type ModelTourItemFilterInput = {
  creator?: ModelIDInput | null,
  tourId?: ModelIDInput | null,
  clients?: ModelIDInput | null,
  primaryAgent?: ModelIDInput | null,
  showingAgents?: ModelIDInput | null,
  mlsNumber?: ModelStringInput | null,
  status?: ModelTourItemStatusInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  order?: ModelIntInput | null,
  showingNote?: ModelStringInput | null,
  entryInfo?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTourItemFilterInput | null > | null,
  or?: Array< ModelTourItemFilterInput | null > | null,
  not?: ModelTourItemFilterInput | null,
  tourTourItemsId?: ModelIDInput | null,
};

export type ModelShowingFilterInput = {
  userId?: ModelStringInput | null,
  mlsNumber?: ModelStringInput | null,
  date?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelShowingFilterInput | null > | null,
  or?: Array< ModelShowingFilterInput | null > | null,
  not?: ModelShowingFilterInput | null,
};

export type ModelShowingConnection = {
  __typename: "ModelShowingConnection",
  items:  Array<Showing | null >,
  nextToken?: string | null,
};

export type ModelSchoolFilterInput = {
  objVer?: ModelStringInput | null,
  name?: ModelStringInput | null,
  isCatholic?: ModelBooleanInput | null,
  isPublic?: ModelBooleanInput | null,
  isUseSchool?: ModelBooleanInput | null,
  gradeFrom?: ModelIntInput | null,
  gradeEnd?: ModelIntInput | null,
  isElementary?: ModelBooleanInput | null,
  isMiddle?: ModelBooleanInput | null,
  isHigh?: ModelBooleanInput | null,
  isEnglish?: ModelBooleanInput | null,
  isFrenchImmersion?: ModelBooleanInput | null,
  isExtendedFrench?: ModelBooleanInput | null,
  isAP?: ModelBooleanInput | null,
  isArts?: ModelBooleanInput | null,
  isGifted?: ModelBooleanInput | null,
  isIB?: ModelBooleanInput | null,
  isSport?: ModelBooleanInput | null,
  g3Avg?: ModelFloatInput | null,
  g3WeightedAvg?: ModelFloatInput | null,
  g6Avg?: ModelFloatInput | null,
  g6WeightedAvg?: ModelFloatInput | null,
  g9Avg?: ModelFloatInput | null,
  g9APMWeightedAvg?: ModelFloatInput | null,
  g9ACMWeightedAvg?: ModelFloatInput | null,
  g10Avg?: ModelFloatInput | null,
  ettieOverallAvg?: ModelFloatInput | null,
  g3Rank?: ModelIntInput | null,
  g3RankTotal?: ModelIntInput | null,
  g6Rank?: ModelIntInput | null,
  g6RankTotal?: ModelIntInput | null,
  g9Rank?: ModelIntInput | null,
  g9RankTotal?: ModelIntInput | null,
  g9ACRank?: ModelIntInput | null,
  g9ACTotalRank?: ModelIntInput | null,
  g9APRank?: ModelIntInput | null,
  g9APTotalRank?: ModelIntInput | null,
  g10Rank?: ModelIntInput | null,
  g10RankTotal?: ModelIntInput | null,
  EQAOScore?: ModelFloatInput | null,
  yearMark?: ModelStringInput | null,
  province?: ModelStringInput | null,
  nation?: ModelStringInput | null,
  url?: ModelStringInput | null,
  schoolBoardId?: ModelIDInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  fax?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  comments?: ModelStringInput | null,
  boundaryArray?: ModelStringInput | null,
  schoolsAssocationArray?: ModelStringInput | null,
  lastReviewDate?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSchoolFilterInput | null > | null,
  or?: Array< ModelSchoolFilterInput | null > | null,
  not?: ModelSchoolFilterInput | null,
  schoolBoardSchoolsId?: ModelIDInput | null,
};

export type ModelSchoolBoardFilterInput = {
  objVer?: ModelStringInput | null,
  name?: ModelStringInput | null,
  url?: ModelStringInput | null,
  boardType?: ModelIntInput | null,
  province?: ModelStringInput | null,
  nation?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  fax?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSchoolBoardFilterInput | null > | null,
  or?: Array< ModelSchoolBoardFilterInput | null > | null,
  not?: ModelSchoolBoardFilterInput | null,
};

export type ModelSchoolBoardConnection = {
  __typename: "ModelSchoolBoardConnection",
  items:  Array<SchoolBoard | null >,
  nextToken?: string | null,
};

export type ModelSchoolBoardScoreFilterInput = {
  schoolBoardId?: ModelIDInput | null,
  yearMark?: ModelStringInput | null,
  year?: ModelIntInput | null,
  totalAvg?: ModelFloatInput | null,
  g3STU?: ModelIntInput | null,
  g6STU?: ModelIntInput | null,
  g9ACSTU?: ModelIntInput | null,
  g9APSTU?: ModelIntInput | null,
  g3TS?: ModelIntInput | null,
  g6TS?: ModelIntInput | null,
  g9TS?: ModelIntInput | null,
  g10OSSLTFSTUA?: ModelIntInput | null,
  g10OSSLTFSTUP?: ModelIntInput | null,
  g10OSSLTFSUCCESS?: ModelIntInput | null,
  g3AR?: ModelFloatInput | null,
  g3AW?: ModelFloatInput | null,
  g3AM?: ModelFloatInput | null,
  g6AR?: ModelFloatInput | null,
  g6AW?: ModelFloatInput | null,
  g6AM?: ModelFloatInput | null,
  g9APM?: ModelFloatInput | null,
  g9ACM?: ModelFloatInput | null,
  g3Last5Avg?: ModelFloatInput | null,
  g6Last5Avg?: ModelFloatInput | null,
  g9Last5Avg?: ModelFloatInput | null,
  g3FindSchoolRating?: ModelFloatInput | null,
  g6FindSchoolRating?: ModelFloatInput | null,
  g9FindSchoolRating?: ModelFloatInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSchoolBoardScoreFilterInput | null > | null,
  or?: Array< ModelSchoolBoardScoreFilterInput | null > | null,
  not?: ModelSchoolBoardScoreFilterInput | null,
  schoolBoardScoresId?: ModelIDInput | null,
};

export type ModelSchoolRatingFilterInput = {
  objVer?: ModelStringInput | null,
  schoolId?: ModelIDInput | null,
  yearMark?: ModelStringInput | null,
  year?: ModelIntInput | null,
  G3STU?: ModelIntInput | null,
  G3AR?: ModelIntInput | null,
  G3AW?: ModelIntInput | null,
  G3AM?: ModelIntInput | null,
  G3Rank?: ModelIntInput | null,
  G3Total?: ModelIntInput | null,
  G3FindSchoolRating?: ModelIntInput | null,
  G6STU?: ModelIntInput | null,
  G6AR?: ModelIntInput | null,
  G6AW?: ModelIntInput | null,
  G6AM?: ModelIntInput | null,
  G6Rank?: ModelIntInput | null,
  G6Total?: ModelIntInput | null,
  G6FindSchoolRating?: ModelIntInput | null,
  G9ACSTU?: ModelIntInput | null,
  G9ACM?: ModelIntInput | null,
  G9ACRank?: ModelIntInput | null,
  G9ACTotal?: ModelIntInput | null,
  G9APSTU?: ModelIntInput | null,
  G9APM?: ModelIntInput | null,
  G9APRank?: ModelIntInput | null,
  G9APTotal?: ModelIntInput | null,
  G9Rank?: ModelIntInput | null,
  G9Total?: ModelIntInput | null,
  G9FindSchoolRating?: ModelIntInput | null,
  G10OSSLTFSTUA?: ModelIntInput | null,
  G10OSSLTFSTUP?: ModelIntInput | null,
  G10OSSLTFSUCCESS?: ModelIntInput | null,
  G3Last5Avg?: ModelIntInput | null,
  G6Last5Avg?: ModelIntInput | null,
  G9Last5Avg?: ModelIntInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSchoolRatingFilterInput | null > | null,
  or?: Array< ModelSchoolRatingFilterInput | null > | null,
  not?: ModelSchoolRatingFilterInput | null,
  schoolRatingsId?: ModelIDInput | null,
};

export type ModelProjectAmenitiesFilterInput = {
  id?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  amenityID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProjectAmenitiesFilterInput | null > | null,
  or?: Array< ModelProjectAmenitiesFilterInput | null > | null,
  not?: ModelProjectAmenitiesFilterInput | null,
};

export type ModelSubscriptionFavouriteFilterInput = {
  repliersID?: ModelSubscriptionStringInput | null,
  mlsNumber?: ModelSubscriptionStringInput | null,
  listing?: ModelSubscriptionStringInput | null,
  listingUpdate?: ModelSubscriptionStringInput | null,
  notification?: ModelSubscriptionBooleanInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFavouriteFilterInput | null > | null,
  or?: Array< ModelSubscriptionFavouriteFilterInput | null > | null,
  userId?: ModelStringInput | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionPreFavouriteFilterInput = {
  projectId?: ModelSubscriptionIDInput | null,
  notification?: ModelSubscriptionBooleanInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPreFavouriteFilterInput | null > | null,
  or?: Array< ModelSubscriptionPreFavouriteFilterInput | null > | null,
  userId?: ModelStringInput | null,
};

export type ModelSubscriptionSearchFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  repliersID?: ModelSubscriptionStringInput | null,
  value?: ModelSubscriptionStringInput | null,
  notification?: ModelSubscriptionBooleanInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSearchFilterInput | null > | null,
  or?: Array< ModelSubscriptionSearchFilterInput | null > | null,
  userId?: ModelStringInput | null,
};

export type ModelSubscriptionReviewFilterInput = {
  tourItemId?: ModelSubscriptionIDInput | null,
  rate?: ModelSubscriptionIntInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReviewFilterInput | null > | null,
  or?: Array< ModelSubscriptionReviewFilterInput | null > | null,
  userId?: ModelStringInput | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionNoteFilterInput = {
  content?: ModelSubscriptionStringInput | null,
  tourItemId?: ModelSubscriptionIDInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNoteFilterInput | null > | null,
  or?: Array< ModelSubscriptionNoteFilterInput | null > | null,
  userId?: ModelStringInput | null,
};

export type ModelSubscriptionPhotoFilterInput = {
  path?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  tourItemId?: ModelSubscriptionIDInput | null,
  id?: ModelSubscriptionIDInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPhotoFilterInput | null > | null,
  or?: Array< ModelSubscriptionPhotoFilterInput | null > | null,
  userId?: ModelStringInput | null,
};

export type ModelSubscriptionRecordingFilterInput = {
  path?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  tourItemId?: ModelSubscriptionIDInput | null,
  id?: ModelSubscriptionIDInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRecordingFilterInput | null > | null,
  or?: Array< ModelSubscriptionRecordingFilterInput | null > | null,
  userId?: ModelStringInput | null,
};

export type ModelSubscriptionProjectAmenitiesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  amenityID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProjectAmenitiesFilterInput | null > | null,
  or?: Array< ModelSubscriptionProjectAmenitiesFilterInput | null > | null,
};

export type CreateBuilderMutationVariables = {
  input: CreateBuilderInput,
  condition?: ModelBuilderConditionInput | null,
};

export type CreateBuilderMutation = {
  createBuilder?:  {
    __typename: "Builder",
    id: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    logo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBuilderMutationVariables = {
  input: UpdateBuilderInput,
  condition?: ModelBuilderConditionInput | null,
};

export type UpdateBuilderMutation = {
  updateBuilder?:  {
    __typename: "Builder",
    id: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    logo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBuilderMutationVariables = {
  input: DeleteBuilderInput,
  condition?: ModelBuilderConditionInput | null,
};

export type DeleteBuilderMutation = {
  deleteBuilder?:  {
    __typename: "Builder",
    id: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    logo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateArchitectMutationVariables = {
  input: CreateArchitectInput,
  condition?: ModelArchitectConditionInput | null,
};

export type CreateArchitectMutation = {
  createArchitect?:  {
    __typename: "Architect",
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    logo?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateArchitectMutationVariables = {
  input: UpdateArchitectInput,
  condition?: ModelArchitectConditionInput | null,
};

export type UpdateArchitectMutation = {
  updateArchitect?:  {
    __typename: "Architect",
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    logo?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteArchitectMutationVariables = {
  input: DeleteArchitectInput,
  condition?: ModelArchitectConditionInput | null,
};

export type DeleteArchitectMutation = {
  deleteArchitect?:  {
    __typename: "Architect",
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    logo?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateInteriorDesignerMutationVariables = {
  input: CreateInteriorDesignerInput,
  condition?: ModelInteriorDesignerConditionInput | null,
};

export type CreateInteriorDesignerMutation = {
  createInteriorDesigner?:  {
    __typename: "InteriorDesigner",
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    logo?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateInteriorDesignerMutationVariables = {
  input: UpdateInteriorDesignerInput,
  condition?: ModelInteriorDesignerConditionInput | null,
};

export type UpdateInteriorDesignerMutation = {
  updateInteriorDesigner?:  {
    __typename: "InteriorDesigner",
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    logo?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteInteriorDesignerMutationVariables = {
  input: DeleteInteriorDesignerInput,
  condition?: ModelInteriorDesignerConditionInput | null,
};

export type DeleteInteriorDesignerMutation = {
  deleteInteriorDesigner?:  {
    __typename: "InteriorDesigner",
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    logo?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProjectMutationVariables = {
  input: CreateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type CreateProjectMutation = {
  createProject?:  {
    __typename: "Project",
    name: string,
    streetNumber: string,
    streetName: string,
    city: string,
    province: string,
    latitude?: number | null,
    longitude?: number | null,
    builderId: string,
    builder?:  {
      __typename: "Builder",
      id: string,
      name: string,
      logo?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    architectId?: string | null,
    architect?:  {
      __typename: "Architect",
      name: string,
      logo?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    interiorDesignerId?: string | null,
    interiorDesigner?:  {
      __typename: "InteriorDesigner",
      name: string,
      logo?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    occupancy?: string | null,
    units?: number | null,
    stories?: number | null,
    startPrice?: number | null,
    endPrice?: number | null,
    minBeds?: number | null,
    maxBeds?: number | null,
    minSqft?: number | null,
    maxSqft?: number | null,
    pricePerSqft?: number | null,
    maintenanceFees?: string | null,
    parkingPrice?: number | null,
    lockerPrice?: number | null,
    phase?: ProjectPhase | null,
    availability?: ProjectAvailability | null,
    types?: Array< PropertyType | null > | null,
    description?: string | null,
    incentives?: string | null,
    amenities?:  {
      __typename: "ModelProjectAmenitiesConnection",
      nextToken?: string | null,
    } | null,
    depositStructure?: string | null,
    logo?: string | null,
    media?: string | null,
    projectUnits?:  {
      __typename: "ModelProjectUnitConnection",
      nextToken?: string | null,
    } | null,
    lastUpdatedOn?: string | null,
    isSoldOut?: boolean | null,
    commission?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    builderProjectsId?: string | null,
    architectProjectsId?: string | null,
    interiorDesignerProjectsId?: string | null,
  } | null,
};

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type UpdateProjectMutation = {
  updateProject?:  {
    __typename: "Project",
    name: string,
    streetNumber: string,
    streetName: string,
    city: string,
    province: string,
    latitude?: number | null,
    longitude?: number | null,
    builderId: string,
    builder?:  {
      __typename: "Builder",
      id: string,
      name: string,
      logo?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    architectId?: string | null,
    architect?:  {
      __typename: "Architect",
      name: string,
      logo?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    interiorDesignerId?: string | null,
    interiorDesigner?:  {
      __typename: "InteriorDesigner",
      name: string,
      logo?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    occupancy?: string | null,
    units?: number | null,
    stories?: number | null,
    startPrice?: number | null,
    endPrice?: number | null,
    minBeds?: number | null,
    maxBeds?: number | null,
    minSqft?: number | null,
    maxSqft?: number | null,
    pricePerSqft?: number | null,
    maintenanceFees?: string | null,
    parkingPrice?: number | null,
    lockerPrice?: number | null,
    phase?: ProjectPhase | null,
    availability?: ProjectAvailability | null,
    types?: Array< PropertyType | null > | null,
    description?: string | null,
    incentives?: string | null,
    amenities?:  {
      __typename: "ModelProjectAmenitiesConnection",
      nextToken?: string | null,
    } | null,
    depositStructure?: string | null,
    logo?: string | null,
    media?: string | null,
    projectUnits?:  {
      __typename: "ModelProjectUnitConnection",
      nextToken?: string | null,
    } | null,
    lastUpdatedOn?: string | null,
    isSoldOut?: boolean | null,
    commission?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    builderProjectsId?: string | null,
    architectProjectsId?: string | null,
    interiorDesignerProjectsId?: string | null,
  } | null,
};

export type DeleteProjectMutationVariables = {
  input: DeleteProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type DeleteProjectMutation = {
  deleteProject?:  {
    __typename: "Project",
    name: string,
    streetNumber: string,
    streetName: string,
    city: string,
    province: string,
    latitude?: number | null,
    longitude?: number | null,
    builderId: string,
    builder?:  {
      __typename: "Builder",
      id: string,
      name: string,
      logo?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    architectId?: string | null,
    architect?:  {
      __typename: "Architect",
      name: string,
      logo?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    interiorDesignerId?: string | null,
    interiorDesigner?:  {
      __typename: "InteriorDesigner",
      name: string,
      logo?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    occupancy?: string | null,
    units?: number | null,
    stories?: number | null,
    startPrice?: number | null,
    endPrice?: number | null,
    minBeds?: number | null,
    maxBeds?: number | null,
    minSqft?: number | null,
    maxSqft?: number | null,
    pricePerSqft?: number | null,
    maintenanceFees?: string | null,
    parkingPrice?: number | null,
    lockerPrice?: number | null,
    phase?: ProjectPhase | null,
    availability?: ProjectAvailability | null,
    types?: Array< PropertyType | null > | null,
    description?: string | null,
    incentives?: string | null,
    amenities?:  {
      __typename: "ModelProjectAmenitiesConnection",
      nextToken?: string | null,
    } | null,
    depositStructure?: string | null,
    logo?: string | null,
    media?: string | null,
    projectUnits?:  {
      __typename: "ModelProjectUnitConnection",
      nextToken?: string | null,
    } | null,
    lastUpdatedOn?: string | null,
    isSoldOut?: boolean | null,
    commission?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    builderProjectsId?: string | null,
    architectProjectsId?: string | null,
    interiorDesignerProjectsId?: string | null,
  } | null,
};

export type CreateProjectUnitMutationVariables = {
  input: CreateProjectUnitInput,
  condition?: ModelProjectUnitConditionInput | null,
};

export type CreateProjectUnitMutation = {
  createProjectUnit?:  {
    __typename: "ProjectUnit",
    numBeds?: number | null,
    numBaths?: number | null,
    numParkings?: number | null,
    numLockers?: number | null,
    sqft?: number | null,
    price?: number | null,
    pricePerSqft?: number | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null,
    type?: PropertyType | null,
    available?: number | null,
    sold?: number | null,
    media?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    projectProjectUnitsId?: string | null,
  } | null,
};

export type UpdateProjectUnitMutationVariables = {
  input: UpdateProjectUnitInput,
  condition?: ModelProjectUnitConditionInput | null,
};

export type UpdateProjectUnitMutation = {
  updateProjectUnit?:  {
    __typename: "ProjectUnit",
    numBeds?: number | null,
    numBaths?: number | null,
    numParkings?: number | null,
    numLockers?: number | null,
    sqft?: number | null,
    price?: number | null,
    pricePerSqft?: number | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null,
    type?: PropertyType | null,
    available?: number | null,
    sold?: number | null,
    media?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    projectProjectUnitsId?: string | null,
  } | null,
};

export type DeleteProjectUnitMutationVariables = {
  input: DeleteProjectUnitInput,
  condition?: ModelProjectUnitConditionInput | null,
};

export type DeleteProjectUnitMutation = {
  deleteProjectUnit?:  {
    __typename: "ProjectUnit",
    numBeds?: number | null,
    numBaths?: number | null,
    numParkings?: number | null,
    numLockers?: number | null,
    sqft?: number | null,
    price?: number | null,
    pricePerSqft?: number | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null,
    type?: PropertyType | null,
    available?: number | null,
    sold?: number | null,
    media?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    projectProjectUnitsId?: string | null,
  } | null,
};

export type CreateAmenityMutationVariables = {
  input: CreateAmenityInput,
  condition?: ModelAmenityConditionInput | null,
};

export type CreateAmenityMutation = {
  createAmenity?:  {
    __typename: "Amenity",
    name: string,
    description?: string | null,
    projects?:  {
      __typename: "ModelProjectAmenitiesConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAmenityMutationVariables = {
  input: UpdateAmenityInput,
  condition?: ModelAmenityConditionInput | null,
};

export type UpdateAmenityMutation = {
  updateAmenity?:  {
    __typename: "Amenity",
    name: string,
    description?: string | null,
    projects?:  {
      __typename: "ModelProjectAmenitiesConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAmenityMutationVariables = {
  input: DeleteAmenityInput,
  condition?: ModelAmenityConditionInput | null,
};

export type DeleteAmenityMutation = {
  deleteAmenity?:  {
    __typename: "Amenity",
    name: string,
    description?: string | null,
    projects?:  {
      __typename: "ModelProjectAmenitiesConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFeaturedMutationVariables = {
  input: CreateFeaturedInput,
  condition?: ModelFeaturedConditionInput | null,
};

export type CreateFeaturedMutation = {
  createFeatured?:  {
    __typename: "Featured",
    title: string,
    subtitle?: string | null,
    filters?: string | null,
    isNearby?: boolean | null,
    isFavourite?: boolean | null,
    isSchool?: boolean | null,
    order?: number | null,
    status: FeaturedStatus,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFeaturedMutationVariables = {
  input: UpdateFeaturedInput,
  condition?: ModelFeaturedConditionInput | null,
};

export type UpdateFeaturedMutation = {
  updateFeatured?:  {
    __typename: "Featured",
    title: string,
    subtitle?: string | null,
    filters?: string | null,
    isNearby?: boolean | null,
    isFavourite?: boolean | null,
    isSchool?: boolean | null,
    order?: number | null,
    status: FeaturedStatus,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFeaturedMutationVariables = {
  input: DeleteFeaturedInput,
  condition?: ModelFeaturedConditionInput | null,
};

export type DeleteFeaturedMutation = {
  deleteFeatured?:  {
    __typename: "Featured",
    title: string,
    subtitle?: string | null,
    filters?: string | null,
    isNearby?: boolean | null,
    isFavourite?: boolean | null,
    isSchool?: boolean | null,
    order?: number | null,
    status: FeaturedStatus,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateHomeCardMutationVariables = {
  input: CreateHomeCardInput,
  condition?: ModelHomeCardConditionInput | null,
};

export type CreateHomeCardMutation = {
  createHomeCard?:  {
    __typename: "HomeCard",
    title: string,
    text?: string | null,
    image?: string | null,
    actionLabel?: string | null,
    actionLink?: string | null,
    inAppAction?: boolean | null,
    order?: number | null,
    status: FeaturedStatus,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateHomeCardMutationVariables = {
  input: UpdateHomeCardInput,
  condition?: ModelHomeCardConditionInput | null,
};

export type UpdateHomeCardMutation = {
  updateHomeCard?:  {
    __typename: "HomeCard",
    title: string,
    text?: string | null,
    image?: string | null,
    actionLabel?: string | null,
    actionLink?: string | null,
    inAppAction?: boolean | null,
    order?: number | null,
    status: FeaturedStatus,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteHomeCardMutationVariables = {
  input: DeleteHomeCardInput,
  condition?: ModelHomeCardConditionInput | null,
};

export type DeleteHomeCardMutation = {
  deleteHomeCard?:  {
    __typename: "HomeCard",
    title: string,
    text?: string | null,
    image?: string | null,
    actionLabel?: string | null,
    actionLink?: string | null,
    inAppAction?: boolean | null,
    order?: number | null,
    status: FeaturedStatus,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProfileMutationVariables = {
  input: CreateProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type CreateProfileMutation = {
  createProfile?:  {
    __typename: "Profile",
    userId: string,
    buyingSelling?: boolean | null,
    leasingRenting?: boolean | null,
    justLooking?: boolean | null,
    preConstruction?: boolean | null,
    isRealtor?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProfileMutationVariables = {
  input: UpdateProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type UpdateProfileMutation = {
  updateProfile?:  {
    __typename: "Profile",
    userId: string,
    buyingSelling?: boolean | null,
    leasingRenting?: boolean | null,
    justLooking?: boolean | null,
    preConstruction?: boolean | null,
    isRealtor?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProfileMutationVariables = {
  input: DeleteProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type DeleteProfileMutation = {
  deleteProfile?:  {
    __typename: "Profile",
    userId: string,
    buyingSelling?: boolean | null,
    leasingRenting?: boolean | null,
    justLooking?: boolean | null,
    preConstruction?: boolean | null,
    isRealtor?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFavouriteMutationVariables = {
  input: CreateFavouriteInput,
  condition?: ModelFavouriteConditionInput | null,
};

export type CreateFavouriteMutation = {
  createFavourite?:  {
    __typename: "Favourite",
    userId?: string | null,
    repliersID?: string | null,
    mlsNumber: string,
    listing: string,
    listingUpdate?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFavouriteMutationVariables = {
  input: UpdateFavouriteInput,
  condition?: ModelFavouriteConditionInput | null,
};

export type UpdateFavouriteMutation = {
  updateFavourite?:  {
    __typename: "Favourite",
    userId?: string | null,
    repliersID?: string | null,
    mlsNumber: string,
    listing: string,
    listingUpdate?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFavouriteMutationVariables = {
  input: DeleteFavouriteInput,
  condition?: ModelFavouriteConditionInput | null,
};

export type DeleteFavouriteMutation = {
  deleteFavourite?:  {
    __typename: "Favourite",
    userId?: string | null,
    repliersID?: string | null,
    mlsNumber: string,
    listing: string,
    listingUpdate?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePreFavouriteMutationVariables = {
  input: CreatePreFavouriteInput,
  condition?: ModelPreFavouriteConditionInput | null,
};

export type CreatePreFavouriteMutation = {
  createPreFavourite?:  {
    __typename: "PreFavourite",
    userId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePreFavouriteMutationVariables = {
  input: UpdatePreFavouriteInput,
  condition?: ModelPreFavouriteConditionInput | null,
};

export type UpdatePreFavouriteMutation = {
  updatePreFavourite?:  {
    __typename: "PreFavourite",
    userId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePreFavouriteMutationVariables = {
  input: DeletePreFavouriteInput,
  condition?: ModelPreFavouriteConditionInput | null,
};

export type DeletePreFavouriteMutation = {
  deletePreFavourite?:  {
    __typename: "PreFavourite",
    userId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSearchMutationVariables = {
  input: CreateSearchInput,
  condition?: ModelSearchConditionInput | null,
};

export type CreateSearchMutation = {
  createSearch?:  {
    __typename: "Search",
    name?: string | null,
    userId?: string | null,
    repliersID?: string | null,
    value?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSearchMutationVariables = {
  input: UpdateSearchInput,
  condition?: ModelSearchConditionInput | null,
};

export type UpdateSearchMutation = {
  updateSearch?:  {
    __typename: "Search",
    name?: string | null,
    userId?: string | null,
    repliersID?: string | null,
    value?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSearchMutationVariables = {
  input: DeleteSearchInput,
  condition?: ModelSearchConditionInput | null,
};

export type DeleteSearchMutation = {
  deleteSearch?:  {
    __typename: "Search",
    name?: string | null,
    userId?: string | null,
    repliersID?: string | null,
    value?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    userId: string,
    schedule?: NotificationSchedule | null,
    type?: Array< NotificationType | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    userId: string,
    schedule?: NotificationSchedule | null,
    type?: Array< NotificationType | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    userId: string,
    schedule?: NotificationSchedule | null,
    type?: Array< NotificationType | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    repliersID?: string | null,
    givenName: string,
    familyName: string,
    name: string,
    displayName: string,
    email: string,
    phone?: string | null,
    profilePicture?: string | null,
    role: UserRole,
    agent?:  {
      __typename: "Agent",
      userId: string,
      website?: string | null,
      licenseNumber?: string | null,
      areas?: Array< string | null > | null,
      availability?: Array< string | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      brokerageAgentsId?: string | null,
    } | null,
    client?:  {
      __typename: "Client",
      userId: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      agentClientsId?: string | null,
      clientAgentId?: string | null,
    } | null,
    subscribed?: boolean | null,
    currency?: string | null,
    measurement?: Measurement | null,
    notifications?:  {
      __typename: "Notification",
      userId: string,
      schedule?: NotificationSchedule | null,
      type?: Array< NotificationType | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceToken?: string | null,
    profile?:  {
      __typename: "Profile",
      userId: string,
      buyingSelling?: boolean | null,
      leasingRenting?: boolean | null,
      justLooking?: boolean | null,
      preConstruction?: boolean | null,
      isRealtor?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    requestedTour?: boolean | null,
    tours?:  {
      __typename: "ModelUserTourConnection",
      nextToken?: string | null,
    } | null,
    toursNote?: string | null,
    deactivated?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    userAgentId?: string | null,
    userClientId?: string | null,
    userNotificationsId?: string | null,
    userProfileId?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    repliersID?: string | null,
    givenName: string,
    familyName: string,
    name: string,
    displayName: string,
    email: string,
    phone?: string | null,
    profilePicture?: string | null,
    role: UserRole,
    agent?:  {
      __typename: "Agent",
      userId: string,
      website?: string | null,
      licenseNumber?: string | null,
      areas?: Array< string | null > | null,
      availability?: Array< string | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      brokerageAgentsId?: string | null,
    } | null,
    client?:  {
      __typename: "Client",
      userId: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      agentClientsId?: string | null,
      clientAgentId?: string | null,
    } | null,
    subscribed?: boolean | null,
    currency?: string | null,
    measurement?: Measurement | null,
    notifications?:  {
      __typename: "Notification",
      userId: string,
      schedule?: NotificationSchedule | null,
      type?: Array< NotificationType | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceToken?: string | null,
    profile?:  {
      __typename: "Profile",
      userId: string,
      buyingSelling?: boolean | null,
      leasingRenting?: boolean | null,
      justLooking?: boolean | null,
      preConstruction?: boolean | null,
      isRealtor?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    requestedTour?: boolean | null,
    tours?:  {
      __typename: "ModelUserTourConnection",
      nextToken?: string | null,
    } | null,
    toursNote?: string | null,
    deactivated?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    userAgentId?: string | null,
    userClientId?: string | null,
    userNotificationsId?: string | null,
    userProfileId?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    repliersID?: string | null,
    givenName: string,
    familyName: string,
    name: string,
    displayName: string,
    email: string,
    phone?: string | null,
    profilePicture?: string | null,
    role: UserRole,
    agent?:  {
      __typename: "Agent",
      userId: string,
      website?: string | null,
      licenseNumber?: string | null,
      areas?: Array< string | null > | null,
      availability?: Array< string | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      brokerageAgentsId?: string | null,
    } | null,
    client?:  {
      __typename: "Client",
      userId: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      agentClientsId?: string | null,
      clientAgentId?: string | null,
    } | null,
    subscribed?: boolean | null,
    currency?: string | null,
    measurement?: Measurement | null,
    notifications?:  {
      __typename: "Notification",
      userId: string,
      schedule?: NotificationSchedule | null,
      type?: Array< NotificationType | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceToken?: string | null,
    profile?:  {
      __typename: "Profile",
      userId: string,
      buyingSelling?: boolean | null,
      leasingRenting?: boolean | null,
      justLooking?: boolean | null,
      preConstruction?: boolean | null,
      isRealtor?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    requestedTour?: boolean | null,
    tours?:  {
      __typename: "ModelUserTourConnection",
      nextToken?: string | null,
    } | null,
    toursNote?: string | null,
    deactivated?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    userAgentId?: string | null,
    userClientId?: string | null,
    userNotificationsId?: string | null,
    userProfileId?: string | null,
  } | null,
};

export type CreateAgentMutationVariables = {
  input: CreateAgentInput,
  condition?: ModelAgentConditionInput | null,
};

export type CreateAgentMutation = {
  createAgent?:  {
    __typename: "Agent",
    userId: string,
    website?: string | null,
    licenseNumber?: string | null,
    areas?: Array< string | null > | null,
    availability?: Array< string | null > | null,
    brokerage?:  {
      __typename: "Brokerage",
      name: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    clients?:  {
      __typename: "ModelClientConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    brokerageAgentsId?: string | null,
  } | null,
};

export type UpdateAgentMutationVariables = {
  input: UpdateAgentInput,
  condition?: ModelAgentConditionInput | null,
};

export type UpdateAgentMutation = {
  updateAgent?:  {
    __typename: "Agent",
    userId: string,
    website?: string | null,
    licenseNumber?: string | null,
    areas?: Array< string | null > | null,
    availability?: Array< string | null > | null,
    brokerage?:  {
      __typename: "Brokerage",
      name: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    clients?:  {
      __typename: "ModelClientConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    brokerageAgentsId?: string | null,
  } | null,
};

export type DeleteAgentMutationVariables = {
  input: DeleteAgentInput,
  condition?: ModelAgentConditionInput | null,
};

export type DeleteAgentMutation = {
  deleteAgent?:  {
    __typename: "Agent",
    userId: string,
    website?: string | null,
    licenseNumber?: string | null,
    areas?: Array< string | null > | null,
    availability?: Array< string | null > | null,
    brokerage?:  {
      __typename: "Brokerage",
      name: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    clients?:  {
      __typename: "ModelClientConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    brokerageAgentsId?: string | null,
  } | null,
};

export type CreateClientMutationVariables = {
  input: CreateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type CreateClientMutation = {
  createClient?:  {
    __typename: "Client",
    userId: string,
    agent?:  {
      __typename: "Agent",
      userId: string,
      website?: string | null,
      licenseNumber?: string | null,
      areas?: Array< string | null > | null,
      availability?: Array< string | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      brokerageAgentsId?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    agentClientsId?: string | null,
    clientAgentId?: string | null,
  } | null,
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type UpdateClientMutation = {
  updateClient?:  {
    __typename: "Client",
    userId: string,
    agent?:  {
      __typename: "Agent",
      userId: string,
      website?: string | null,
      licenseNumber?: string | null,
      areas?: Array< string | null > | null,
      availability?: Array< string | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      brokerageAgentsId?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    agentClientsId?: string | null,
    clientAgentId?: string | null,
  } | null,
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput,
  condition?: ModelClientConditionInput | null,
};

export type DeleteClientMutation = {
  deleteClient?:  {
    __typename: "Client",
    userId: string,
    agent?:  {
      __typename: "Agent",
      userId: string,
      website?: string | null,
      licenseNumber?: string | null,
      areas?: Array< string | null > | null,
      availability?: Array< string | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      brokerageAgentsId?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    agentClientsId?: string | null,
    clientAgentId?: string | null,
  } | null,
};

export type CreateBrokerageMutationVariables = {
  input: CreateBrokerageInput,
  condition?: ModelBrokerageConditionInput | null,
};

export type CreateBrokerageMutation = {
  createBrokerage?:  {
    __typename: "Brokerage",
    name: string,
    agents?:  {
      __typename: "ModelAgentConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateBrokerageMutationVariables = {
  input: UpdateBrokerageInput,
  condition?: ModelBrokerageConditionInput | null,
};

export type UpdateBrokerageMutation = {
  updateBrokerage?:  {
    __typename: "Brokerage",
    name: string,
    agents?:  {
      __typename: "ModelAgentConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteBrokerageMutationVariables = {
  input: DeleteBrokerageInput,
  condition?: ModelBrokerageConditionInput | null,
};

export type DeleteBrokerageMutation = {
  deleteBrokerage?:  {
    __typename: "Brokerage",
    name: string,
    agents?:  {
      __typename: "ModelAgentConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateUserTourMutationVariables = {
  input: CreateUserTourInput,
  condition?: ModelUserTourConditionInput | null,
};

export type CreateUserTourMutation = {
  createUserTour?:  {
    __typename: "UserTour",
    creator?: string | null,
    userId: string,
    tourId: string,
    user?:  {
      __typename: "User",
      repliersID?: string | null,
      givenName: string,
      familyName: string,
      name: string,
      displayName: string,
      email: string,
      phone?: string | null,
      profilePicture?: string | null,
      role: UserRole,
      subscribed?: boolean | null,
      currency?: string | null,
      measurement?: Measurement | null,
      deviceToken?: string | null,
      requestedTour?: boolean | null,
      toursNote?: string | null,
      deactivated?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      userAgentId?: string | null,
      userClientId?: string | null,
      userNotificationsId?: string | null,
      userProfileId?: string | null,
    } | null,
    tour?:  {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    role: TourRole,
    seen?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    userToursId?: string | null,
    tourUsersId?: string | null,
  } | null,
};

export type UpdateUserTourMutationVariables = {
  input: UpdateUserTourInput,
  condition?: ModelUserTourConditionInput | null,
};

export type UpdateUserTourMutation = {
  updateUserTour?:  {
    __typename: "UserTour",
    creator?: string | null,
    userId: string,
    tourId: string,
    user?:  {
      __typename: "User",
      repliersID?: string | null,
      givenName: string,
      familyName: string,
      name: string,
      displayName: string,
      email: string,
      phone?: string | null,
      profilePicture?: string | null,
      role: UserRole,
      subscribed?: boolean | null,
      currency?: string | null,
      measurement?: Measurement | null,
      deviceToken?: string | null,
      requestedTour?: boolean | null,
      toursNote?: string | null,
      deactivated?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      userAgentId?: string | null,
      userClientId?: string | null,
      userNotificationsId?: string | null,
      userProfileId?: string | null,
    } | null,
    tour?:  {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    role: TourRole,
    seen?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    userToursId?: string | null,
    tourUsersId?: string | null,
  } | null,
};

export type DeleteUserTourMutationVariables = {
  input: DeleteUserTourInput,
  condition?: ModelUserTourConditionInput | null,
};

export type DeleteUserTourMutation = {
  deleteUserTour?:  {
    __typename: "UserTour",
    creator?: string | null,
    userId: string,
    tourId: string,
    user?:  {
      __typename: "User",
      repliersID?: string | null,
      givenName: string,
      familyName: string,
      name: string,
      displayName: string,
      email: string,
      phone?: string | null,
      profilePicture?: string | null,
      role: UserRole,
      subscribed?: boolean | null,
      currency?: string | null,
      measurement?: Measurement | null,
      deviceToken?: string | null,
      requestedTour?: boolean | null,
      toursNote?: string | null,
      deactivated?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      userAgentId?: string | null,
      userClientId?: string | null,
      userNotificationsId?: string | null,
      userProfileId?: string | null,
    } | null,
    tour?:  {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    role: TourRole,
    seen?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    userToursId?: string | null,
    tourUsersId?: string | null,
  } | null,
};

export type CreateTourMutationVariables = {
  input: CreateTourInput,
  condition?: ModelTourConditionInput | null,
};

export type CreateTourMutation = {
  createTour?:  {
    __typename: "Tour",
    title?: string | null,
    creator?: string | null,
    users?:  {
      __typename: "ModelUserTourConnection",
      nextToken?: string | null,
    } | null,
    clients?: Array< string | null > | null,
    primaryAgent?: string | null,
    showingAgents?: Array< string | null > | null,
    status?: TourStatus | null,
    privateNote?: string | null,
    generalNote?: string | null,
    tourItems?:  {
      __typename: "ModelTourItemConnection",
      nextToken?: string | null,
    } | null,
    meetupLocation?: string | null,
    meetupDescription?: string | null,
    meetupTime?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    date?: string | null,
    shared?: ShareStatus | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTourMutationVariables = {
  input: UpdateTourInput,
  condition?: ModelTourConditionInput | null,
};

export type UpdateTourMutation = {
  updateTour?:  {
    __typename: "Tour",
    title?: string | null,
    creator?: string | null,
    users?:  {
      __typename: "ModelUserTourConnection",
      nextToken?: string | null,
    } | null,
    clients?: Array< string | null > | null,
    primaryAgent?: string | null,
    showingAgents?: Array< string | null > | null,
    status?: TourStatus | null,
    privateNote?: string | null,
    generalNote?: string | null,
    tourItems?:  {
      __typename: "ModelTourItemConnection",
      nextToken?: string | null,
    } | null,
    meetupLocation?: string | null,
    meetupDescription?: string | null,
    meetupTime?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    date?: string | null,
    shared?: ShareStatus | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTourMutationVariables = {
  input: DeleteTourInput,
  condition?: ModelTourConditionInput | null,
};

export type DeleteTourMutation = {
  deleteTour?:  {
    __typename: "Tour",
    title?: string | null,
    creator?: string | null,
    users?:  {
      __typename: "ModelUserTourConnection",
      nextToken?: string | null,
    } | null,
    clients?: Array< string | null > | null,
    primaryAgent?: string | null,
    showingAgents?: Array< string | null > | null,
    status?: TourStatus | null,
    privateNote?: string | null,
    generalNote?: string | null,
    tourItems?:  {
      __typename: "ModelTourItemConnection",
      nextToken?: string | null,
    } | null,
    meetupLocation?: string | null,
    meetupDescription?: string | null,
    meetupTime?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    date?: string | null,
    shared?: ShareStatus | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTourItemMutationVariables = {
  input: CreateTourItemInput,
  condition?: ModelTourItemConditionInput | null,
};

export type CreateTourItemMutation = {
  createTourItem?:  {
    __typename: "TourItem",
    creator?: string | null,
    tourId: string,
    tour?:  {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    clients?: Array< string | null > | null,
    primaryAgent?: string | null,
    showingAgents?: Array< string | null > | null,
    mlsNumber?: string | null,
    status?: TourItemStatus | null,
    startTime?: string | null,
    endTime?: string | null,
    order?: number | null,
    showingNote?: string | null,
    entryInfo?: string | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
    } | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      nextToken?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelPhotoConnection",
      nextToken?: string | null,
    } | null,
    recordings?:  {
      __typename: "ModelRecordingConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourTourItemsId?: string | null,
  } | null,
};

export type UpdateTourItemMutationVariables = {
  input: UpdateTourItemInput,
  condition?: ModelTourItemConditionInput | null,
};

export type UpdateTourItemMutation = {
  updateTourItem?:  {
    __typename: "TourItem",
    creator?: string | null,
    tourId: string,
    tour?:  {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    clients?: Array< string | null > | null,
    primaryAgent?: string | null,
    showingAgents?: Array< string | null > | null,
    mlsNumber?: string | null,
    status?: TourItemStatus | null,
    startTime?: string | null,
    endTime?: string | null,
    order?: number | null,
    showingNote?: string | null,
    entryInfo?: string | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
    } | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      nextToken?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelPhotoConnection",
      nextToken?: string | null,
    } | null,
    recordings?:  {
      __typename: "ModelRecordingConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourTourItemsId?: string | null,
  } | null,
};

export type DeleteTourItemMutationVariables = {
  input: DeleteTourItemInput,
  condition?: ModelTourItemConditionInput | null,
};

export type DeleteTourItemMutation = {
  deleteTourItem?:  {
    __typename: "TourItem",
    creator?: string | null,
    tourId: string,
    tour?:  {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    clients?: Array< string | null > | null,
    primaryAgent?: string | null,
    showingAgents?: Array< string | null > | null,
    mlsNumber?: string | null,
    status?: TourItemStatus | null,
    startTime?: string | null,
    endTime?: string | null,
    order?: number | null,
    showingNote?: string | null,
    entryInfo?: string | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
    } | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      nextToken?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelPhotoConnection",
      nextToken?: string | null,
    } | null,
    recordings?:  {
      __typename: "ModelRecordingConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourTourItemsId?: string | null,
  } | null,
};

export type CreateShowingMutationVariables = {
  input: CreateShowingInput,
  condition?: ModelShowingConditionInput | null,
};

export type CreateShowingMutation = {
  createShowing?:  {
    __typename: "Showing",
    userId: string,
    mlsNumber?: string | null,
    date?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    instructions?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateShowingMutationVariables = {
  input: UpdateShowingInput,
  condition?: ModelShowingConditionInput | null,
};

export type UpdateShowingMutation = {
  updateShowing?:  {
    __typename: "Showing",
    userId: string,
    mlsNumber?: string | null,
    date?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    instructions?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteShowingMutationVariables = {
  input: DeleteShowingInput,
  condition?: ModelShowingConditionInput | null,
};

export type DeleteShowingMutation = {
  deleteShowing?:  {
    __typename: "Showing",
    userId: string,
    mlsNumber?: string | null,
    date?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    instructions?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReviewMutationVariables = {
  input: CreateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type CreateReviewMutation = {
  createReview?:  {
    __typename: "Review",
    userId?: string | null,
    tourItemId: string,
    rate?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemReviewsId?: string | null,
  } | null,
};

export type UpdateReviewMutationVariables = {
  input: UpdateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type UpdateReviewMutation = {
  updateReview?:  {
    __typename: "Review",
    userId?: string | null,
    tourItemId: string,
    rate?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemReviewsId?: string | null,
  } | null,
};

export type DeleteReviewMutationVariables = {
  input: DeleteReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type DeleteReviewMutation = {
  deleteReview?:  {
    __typename: "Review",
    userId?: string | null,
    tourItemId: string,
    rate?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemReviewsId?: string | null,
  } | null,
};

export type CreateNoteMutationVariables = {
  input: CreateNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type CreateNoteMutation = {
  createNote?:  {
    __typename: "Note",
    userId?: string | null,
    content?: string | null,
    tourItemId: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemNotesId?: string | null,
  } | null,
};

export type UpdateNoteMutationVariables = {
  input: UpdateNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type UpdateNoteMutation = {
  updateNote?:  {
    __typename: "Note",
    userId?: string | null,
    content?: string | null,
    tourItemId: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemNotesId?: string | null,
  } | null,
};

export type DeleteNoteMutationVariables = {
  input: DeleteNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type DeleteNoteMutation = {
  deleteNote?:  {
    __typename: "Note",
    userId?: string | null,
    content?: string | null,
    tourItemId: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemNotesId?: string | null,
  } | null,
};

export type CreatePhotoMutationVariables = {
  input: CreatePhotoInput,
  condition?: ModelPhotoConditionInput | null,
};

export type CreatePhotoMutation = {
  createPhoto?:  {
    __typename: "Photo",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemPhotosId?: string | null,
  } | null,
};

export type UpdatePhotoMutationVariables = {
  input: UpdatePhotoInput,
  condition?: ModelPhotoConditionInput | null,
};

export type UpdatePhotoMutation = {
  updatePhoto?:  {
    __typename: "Photo",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemPhotosId?: string | null,
  } | null,
};

export type DeletePhotoMutationVariables = {
  input: DeletePhotoInput,
  condition?: ModelPhotoConditionInput | null,
};

export type DeletePhotoMutation = {
  deletePhoto?:  {
    __typename: "Photo",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemPhotosId?: string | null,
  } | null,
};

export type CreateRecordingMutationVariables = {
  input: CreateRecordingInput,
  condition?: ModelRecordingConditionInput | null,
};

export type CreateRecordingMutation = {
  createRecording?:  {
    __typename: "Recording",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemRecordingsId?: string | null,
  } | null,
};

export type UpdateRecordingMutationVariables = {
  input: UpdateRecordingInput,
  condition?: ModelRecordingConditionInput | null,
};

export type UpdateRecordingMutation = {
  updateRecording?:  {
    __typename: "Recording",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemRecordingsId?: string | null,
  } | null,
};

export type DeleteRecordingMutationVariables = {
  input: DeleteRecordingInput,
  condition?: ModelRecordingConditionInput | null,
};

export type DeleteRecordingMutation = {
  deleteRecording?:  {
    __typename: "Recording",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemRecordingsId?: string | null,
  } | null,
};

export type CreateSchoolMutationVariables = {
  input: CreateSchoolInput,
  condition?: ModelSchoolConditionInput | null,
};

export type CreateSchoolMutation = {
  createSchool?:  {
    __typename: "School",
    objVer?: string | null,
    name?: string | null,
    isCatholic?: boolean | null,
    isPublic?: boolean | null,
    isUseSchool?: boolean | null,
    gradeFrom?: number | null,
    gradeEnd?: number | null,
    isElementary?: boolean | null,
    isMiddle?: boolean | null,
    isHigh?: boolean | null,
    isEnglish?: boolean | null,
    isFrenchImmersion?: boolean | null,
    isExtendedFrench?: boolean | null,
    isAP?: boolean | null,
    isArts?: boolean | null,
    isGifted?: boolean | null,
    isIB?: boolean | null,
    isSport?: boolean | null,
    g3Avg?: number | null,
    g3WeightedAvg?: number | null,
    g6Avg?: number | null,
    g6WeightedAvg?: number | null,
    g9Avg?: number | null,
    g9APMWeightedAvg?: number | null,
    g9ACMWeightedAvg?: number | null,
    g10Avg?: number | null,
    ettieOverallAvg?: number | null,
    g3Rank?: number | null,
    g3RankTotal?: number | null,
    g6Rank?: number | null,
    g6RankTotal?: number | null,
    g9Rank?: number | null,
    g9RankTotal?: number | null,
    g9ACRank?: number | null,
    g9ACTotalRank?: number | null,
    g9APRank?: number | null,
    g9APTotalRank?: number | null,
    g10Rank?: number | null,
    g10RankTotal?: number | null,
    EQAOScore?: number | null,
    yearMark?: string | null,
    province?: string | null,
    nation?: string | null,
    url?: string | null,
    schoolBoardId?: string | null,
    schoolBoard?:  {
      __typename: "SchoolBoard",
      objVer?: string | null,
      name?: string | null,
      url?: string | null,
      boardType?: number | null,
      province?: string | null,
      nation?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone?: string | null,
      fax?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    ratings?:  {
      __typename: "ModelSchoolRatingConnection",
      nextToken?: string | null,
    } | null,
    address?: string | null,
    city?: string | null,
    postalCode?: string | null,
    phone?: string | null,
    fax?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    comments?: string | null,
    boundaryArray?: string | null,
    schoolsAssocationArray?: string | null,
    lastReviewDate?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    schoolBoardSchoolsId?: string | null,
  } | null,
};

export type UpdateSchoolMutationVariables = {
  input: UpdateSchoolInput,
  condition?: ModelSchoolConditionInput | null,
};

export type UpdateSchoolMutation = {
  updateSchool?:  {
    __typename: "School",
    objVer?: string | null,
    name?: string | null,
    isCatholic?: boolean | null,
    isPublic?: boolean | null,
    isUseSchool?: boolean | null,
    gradeFrom?: number | null,
    gradeEnd?: number | null,
    isElementary?: boolean | null,
    isMiddle?: boolean | null,
    isHigh?: boolean | null,
    isEnglish?: boolean | null,
    isFrenchImmersion?: boolean | null,
    isExtendedFrench?: boolean | null,
    isAP?: boolean | null,
    isArts?: boolean | null,
    isGifted?: boolean | null,
    isIB?: boolean | null,
    isSport?: boolean | null,
    g3Avg?: number | null,
    g3WeightedAvg?: number | null,
    g6Avg?: number | null,
    g6WeightedAvg?: number | null,
    g9Avg?: number | null,
    g9APMWeightedAvg?: number | null,
    g9ACMWeightedAvg?: number | null,
    g10Avg?: number | null,
    ettieOverallAvg?: number | null,
    g3Rank?: number | null,
    g3RankTotal?: number | null,
    g6Rank?: number | null,
    g6RankTotal?: number | null,
    g9Rank?: number | null,
    g9RankTotal?: number | null,
    g9ACRank?: number | null,
    g9ACTotalRank?: number | null,
    g9APRank?: number | null,
    g9APTotalRank?: number | null,
    g10Rank?: number | null,
    g10RankTotal?: number | null,
    EQAOScore?: number | null,
    yearMark?: string | null,
    province?: string | null,
    nation?: string | null,
    url?: string | null,
    schoolBoardId?: string | null,
    schoolBoard?:  {
      __typename: "SchoolBoard",
      objVer?: string | null,
      name?: string | null,
      url?: string | null,
      boardType?: number | null,
      province?: string | null,
      nation?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone?: string | null,
      fax?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    ratings?:  {
      __typename: "ModelSchoolRatingConnection",
      nextToken?: string | null,
    } | null,
    address?: string | null,
    city?: string | null,
    postalCode?: string | null,
    phone?: string | null,
    fax?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    comments?: string | null,
    boundaryArray?: string | null,
    schoolsAssocationArray?: string | null,
    lastReviewDate?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    schoolBoardSchoolsId?: string | null,
  } | null,
};

export type DeleteSchoolMutationVariables = {
  input: DeleteSchoolInput,
  condition?: ModelSchoolConditionInput | null,
};

export type DeleteSchoolMutation = {
  deleteSchool?:  {
    __typename: "School",
    objVer?: string | null,
    name?: string | null,
    isCatholic?: boolean | null,
    isPublic?: boolean | null,
    isUseSchool?: boolean | null,
    gradeFrom?: number | null,
    gradeEnd?: number | null,
    isElementary?: boolean | null,
    isMiddle?: boolean | null,
    isHigh?: boolean | null,
    isEnglish?: boolean | null,
    isFrenchImmersion?: boolean | null,
    isExtendedFrench?: boolean | null,
    isAP?: boolean | null,
    isArts?: boolean | null,
    isGifted?: boolean | null,
    isIB?: boolean | null,
    isSport?: boolean | null,
    g3Avg?: number | null,
    g3WeightedAvg?: number | null,
    g6Avg?: number | null,
    g6WeightedAvg?: number | null,
    g9Avg?: number | null,
    g9APMWeightedAvg?: number | null,
    g9ACMWeightedAvg?: number | null,
    g10Avg?: number | null,
    ettieOverallAvg?: number | null,
    g3Rank?: number | null,
    g3RankTotal?: number | null,
    g6Rank?: number | null,
    g6RankTotal?: number | null,
    g9Rank?: number | null,
    g9RankTotal?: number | null,
    g9ACRank?: number | null,
    g9ACTotalRank?: number | null,
    g9APRank?: number | null,
    g9APTotalRank?: number | null,
    g10Rank?: number | null,
    g10RankTotal?: number | null,
    EQAOScore?: number | null,
    yearMark?: string | null,
    province?: string | null,
    nation?: string | null,
    url?: string | null,
    schoolBoardId?: string | null,
    schoolBoard?:  {
      __typename: "SchoolBoard",
      objVer?: string | null,
      name?: string | null,
      url?: string | null,
      boardType?: number | null,
      province?: string | null,
      nation?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone?: string | null,
      fax?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    ratings?:  {
      __typename: "ModelSchoolRatingConnection",
      nextToken?: string | null,
    } | null,
    address?: string | null,
    city?: string | null,
    postalCode?: string | null,
    phone?: string | null,
    fax?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    comments?: string | null,
    boundaryArray?: string | null,
    schoolsAssocationArray?: string | null,
    lastReviewDate?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    schoolBoardSchoolsId?: string | null,
  } | null,
};

export type CreateSchoolBoardMutationVariables = {
  input: CreateSchoolBoardInput,
  condition?: ModelSchoolBoardConditionInput | null,
};

export type CreateSchoolBoardMutation = {
  createSchoolBoard?:  {
    __typename: "SchoolBoard",
    objVer?: string | null,
    name?: string | null,
    url?: string | null,
    boardType?: number | null,
    province?: string | null,
    nation?: string | null,
    address?: string | null,
    city?: string | null,
    postalCode?: string | null,
    phone?: string | null,
    fax?: string | null,
    schools?:  {
      __typename: "ModelSchoolConnection",
      nextToken?: string | null,
    } | null,
    scores?:  {
      __typename: "ModelSchoolBoardScoreConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSchoolBoardMutationVariables = {
  input: UpdateSchoolBoardInput,
  condition?: ModelSchoolBoardConditionInput | null,
};

export type UpdateSchoolBoardMutation = {
  updateSchoolBoard?:  {
    __typename: "SchoolBoard",
    objVer?: string | null,
    name?: string | null,
    url?: string | null,
    boardType?: number | null,
    province?: string | null,
    nation?: string | null,
    address?: string | null,
    city?: string | null,
    postalCode?: string | null,
    phone?: string | null,
    fax?: string | null,
    schools?:  {
      __typename: "ModelSchoolConnection",
      nextToken?: string | null,
    } | null,
    scores?:  {
      __typename: "ModelSchoolBoardScoreConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSchoolBoardMutationVariables = {
  input: DeleteSchoolBoardInput,
  condition?: ModelSchoolBoardConditionInput | null,
};

export type DeleteSchoolBoardMutation = {
  deleteSchoolBoard?:  {
    __typename: "SchoolBoard",
    objVer?: string | null,
    name?: string | null,
    url?: string | null,
    boardType?: number | null,
    province?: string | null,
    nation?: string | null,
    address?: string | null,
    city?: string | null,
    postalCode?: string | null,
    phone?: string | null,
    fax?: string | null,
    schools?:  {
      __typename: "ModelSchoolConnection",
      nextToken?: string | null,
    } | null,
    scores?:  {
      __typename: "ModelSchoolBoardScoreConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSchoolBoardScoreMutationVariables = {
  input: CreateSchoolBoardScoreInput,
  condition?: ModelSchoolBoardScoreConditionInput | null,
};

export type CreateSchoolBoardScoreMutation = {
  createSchoolBoardScore?:  {
    __typename: "SchoolBoardScore",
    schoolBoardId?: string | null,
    schoolBoard?:  {
      __typename: "SchoolBoard",
      objVer?: string | null,
      name?: string | null,
      url?: string | null,
      boardType?: number | null,
      province?: string | null,
      nation?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone?: string | null,
      fax?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    yearMark?: string | null,
    year?: number | null,
    totalAvg?: number | null,
    g3STU?: number | null,
    g6STU?: number | null,
    g9ACSTU?: number | null,
    g9APSTU?: number | null,
    g3TS?: number | null,
    g6TS?: number | null,
    g9TS?: number | null,
    g10OSSLTFSTUA?: number | null,
    g10OSSLTFSTUP?: number | null,
    g10OSSLTFSUCCESS?: number | null,
    g3AR?: number | null,
    g3AW?: number | null,
    g3AM?: number | null,
    g6AR?: number | null,
    g6AW?: number | null,
    g6AM?: number | null,
    g9APM?: number | null,
    g9ACM?: number | null,
    g3Last5Avg?: number | null,
    g6Last5Avg?: number | null,
    g9Last5Avg?: number | null,
    g3FindSchoolRating?: number | null,
    g6FindSchoolRating?: number | null,
    g9FindSchoolRating?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    schoolBoardScoresId?: string | null,
  } | null,
};

export type UpdateSchoolBoardScoreMutationVariables = {
  input: UpdateSchoolBoardScoreInput,
  condition?: ModelSchoolBoardScoreConditionInput | null,
};

export type UpdateSchoolBoardScoreMutation = {
  updateSchoolBoardScore?:  {
    __typename: "SchoolBoardScore",
    schoolBoardId?: string | null,
    schoolBoard?:  {
      __typename: "SchoolBoard",
      objVer?: string | null,
      name?: string | null,
      url?: string | null,
      boardType?: number | null,
      province?: string | null,
      nation?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone?: string | null,
      fax?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    yearMark?: string | null,
    year?: number | null,
    totalAvg?: number | null,
    g3STU?: number | null,
    g6STU?: number | null,
    g9ACSTU?: number | null,
    g9APSTU?: number | null,
    g3TS?: number | null,
    g6TS?: number | null,
    g9TS?: number | null,
    g10OSSLTFSTUA?: number | null,
    g10OSSLTFSTUP?: number | null,
    g10OSSLTFSUCCESS?: number | null,
    g3AR?: number | null,
    g3AW?: number | null,
    g3AM?: number | null,
    g6AR?: number | null,
    g6AW?: number | null,
    g6AM?: number | null,
    g9APM?: number | null,
    g9ACM?: number | null,
    g3Last5Avg?: number | null,
    g6Last5Avg?: number | null,
    g9Last5Avg?: number | null,
    g3FindSchoolRating?: number | null,
    g6FindSchoolRating?: number | null,
    g9FindSchoolRating?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    schoolBoardScoresId?: string | null,
  } | null,
};

export type DeleteSchoolBoardScoreMutationVariables = {
  input: DeleteSchoolBoardScoreInput,
  condition?: ModelSchoolBoardScoreConditionInput | null,
};

export type DeleteSchoolBoardScoreMutation = {
  deleteSchoolBoardScore?:  {
    __typename: "SchoolBoardScore",
    schoolBoardId?: string | null,
    schoolBoard?:  {
      __typename: "SchoolBoard",
      objVer?: string | null,
      name?: string | null,
      url?: string | null,
      boardType?: number | null,
      province?: string | null,
      nation?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone?: string | null,
      fax?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    yearMark?: string | null,
    year?: number | null,
    totalAvg?: number | null,
    g3STU?: number | null,
    g6STU?: number | null,
    g9ACSTU?: number | null,
    g9APSTU?: number | null,
    g3TS?: number | null,
    g6TS?: number | null,
    g9TS?: number | null,
    g10OSSLTFSTUA?: number | null,
    g10OSSLTFSTUP?: number | null,
    g10OSSLTFSUCCESS?: number | null,
    g3AR?: number | null,
    g3AW?: number | null,
    g3AM?: number | null,
    g6AR?: number | null,
    g6AW?: number | null,
    g6AM?: number | null,
    g9APM?: number | null,
    g9ACM?: number | null,
    g3Last5Avg?: number | null,
    g6Last5Avg?: number | null,
    g9Last5Avg?: number | null,
    g3FindSchoolRating?: number | null,
    g6FindSchoolRating?: number | null,
    g9FindSchoolRating?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    schoolBoardScoresId?: string | null,
  } | null,
};

export type CreateSchoolRatingMutationVariables = {
  input: CreateSchoolRatingInput,
  condition?: ModelSchoolRatingConditionInput | null,
};

export type CreateSchoolRatingMutation = {
  createSchoolRating?:  {
    __typename: "SchoolRating",
    objVer?: string | null,
    schoolId?: string | null,
    school?:  {
      __typename: "School",
      objVer?: string | null,
      name?: string | null,
      isCatholic?: boolean | null,
      isPublic?: boolean | null,
      isUseSchool?: boolean | null,
      gradeFrom?: number | null,
      gradeEnd?: number | null,
      isElementary?: boolean | null,
      isMiddle?: boolean | null,
      isHigh?: boolean | null,
      isEnglish?: boolean | null,
      isFrenchImmersion?: boolean | null,
      isExtendedFrench?: boolean | null,
      isAP?: boolean | null,
      isArts?: boolean | null,
      isGifted?: boolean | null,
      isIB?: boolean | null,
      isSport?: boolean | null,
      g3Avg?: number | null,
      g3WeightedAvg?: number | null,
      g6Avg?: number | null,
      g6WeightedAvg?: number | null,
      g9Avg?: number | null,
      g9APMWeightedAvg?: number | null,
      g9ACMWeightedAvg?: number | null,
      g10Avg?: number | null,
      ettieOverallAvg?: number | null,
      g3Rank?: number | null,
      g3RankTotal?: number | null,
      g6Rank?: number | null,
      g6RankTotal?: number | null,
      g9Rank?: number | null,
      g9RankTotal?: number | null,
      g9ACRank?: number | null,
      g9ACTotalRank?: number | null,
      g9APRank?: number | null,
      g9APTotalRank?: number | null,
      g10Rank?: number | null,
      g10RankTotal?: number | null,
      EQAOScore?: number | null,
      yearMark?: string | null,
      province?: string | null,
      nation?: string | null,
      url?: string | null,
      schoolBoardId?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone?: string | null,
      fax?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      comments?: string | null,
      boundaryArray?: string | null,
      schoolsAssocationArray?: string | null,
      lastReviewDate?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolBoardSchoolsId?: string | null,
    } | null,
    yearMark?: string | null,
    year?: number | null,
    G3STU?: number | null,
    G3AR?: number | null,
    G3AW?: number | null,
    G3AM?: number | null,
    G3Rank?: number | null,
    G3Total?: number | null,
    G3FindSchoolRating?: number | null,
    G6STU?: number | null,
    G6AR?: number | null,
    G6AW?: number | null,
    G6AM?: number | null,
    G6Rank?: number | null,
    G6Total?: number | null,
    G6FindSchoolRating?: number | null,
    G9ACSTU?: number | null,
    G9ACM?: number | null,
    G9ACRank?: number | null,
    G9ACTotal?: number | null,
    G9APSTU?: number | null,
    G9APM?: number | null,
    G9APRank?: number | null,
    G9APTotal?: number | null,
    G9Rank?: number | null,
    G9Total?: number | null,
    G9FindSchoolRating?: number | null,
    G10OSSLTFSTUA?: number | null,
    G10OSSLTFSTUP?: number | null,
    G10OSSLTFSUCCESS?: number | null,
    G3Last5Avg?: number | null,
    G6Last5Avg?: number | null,
    G9Last5Avg?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    schoolRatingsId?: string | null,
  } | null,
};

export type UpdateSchoolRatingMutationVariables = {
  input: UpdateSchoolRatingInput,
  condition?: ModelSchoolRatingConditionInput | null,
};

export type UpdateSchoolRatingMutation = {
  updateSchoolRating?:  {
    __typename: "SchoolRating",
    objVer?: string | null,
    schoolId?: string | null,
    school?:  {
      __typename: "School",
      objVer?: string | null,
      name?: string | null,
      isCatholic?: boolean | null,
      isPublic?: boolean | null,
      isUseSchool?: boolean | null,
      gradeFrom?: number | null,
      gradeEnd?: number | null,
      isElementary?: boolean | null,
      isMiddle?: boolean | null,
      isHigh?: boolean | null,
      isEnglish?: boolean | null,
      isFrenchImmersion?: boolean | null,
      isExtendedFrench?: boolean | null,
      isAP?: boolean | null,
      isArts?: boolean | null,
      isGifted?: boolean | null,
      isIB?: boolean | null,
      isSport?: boolean | null,
      g3Avg?: number | null,
      g3WeightedAvg?: number | null,
      g6Avg?: number | null,
      g6WeightedAvg?: number | null,
      g9Avg?: number | null,
      g9APMWeightedAvg?: number | null,
      g9ACMWeightedAvg?: number | null,
      g10Avg?: number | null,
      ettieOverallAvg?: number | null,
      g3Rank?: number | null,
      g3RankTotal?: number | null,
      g6Rank?: number | null,
      g6RankTotal?: number | null,
      g9Rank?: number | null,
      g9RankTotal?: number | null,
      g9ACRank?: number | null,
      g9ACTotalRank?: number | null,
      g9APRank?: number | null,
      g9APTotalRank?: number | null,
      g10Rank?: number | null,
      g10RankTotal?: number | null,
      EQAOScore?: number | null,
      yearMark?: string | null,
      province?: string | null,
      nation?: string | null,
      url?: string | null,
      schoolBoardId?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone?: string | null,
      fax?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      comments?: string | null,
      boundaryArray?: string | null,
      schoolsAssocationArray?: string | null,
      lastReviewDate?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolBoardSchoolsId?: string | null,
    } | null,
    yearMark?: string | null,
    year?: number | null,
    G3STU?: number | null,
    G3AR?: number | null,
    G3AW?: number | null,
    G3AM?: number | null,
    G3Rank?: number | null,
    G3Total?: number | null,
    G3FindSchoolRating?: number | null,
    G6STU?: number | null,
    G6AR?: number | null,
    G6AW?: number | null,
    G6AM?: number | null,
    G6Rank?: number | null,
    G6Total?: number | null,
    G6FindSchoolRating?: number | null,
    G9ACSTU?: number | null,
    G9ACM?: number | null,
    G9ACRank?: number | null,
    G9ACTotal?: number | null,
    G9APSTU?: number | null,
    G9APM?: number | null,
    G9APRank?: number | null,
    G9APTotal?: number | null,
    G9Rank?: number | null,
    G9Total?: number | null,
    G9FindSchoolRating?: number | null,
    G10OSSLTFSTUA?: number | null,
    G10OSSLTFSTUP?: number | null,
    G10OSSLTFSUCCESS?: number | null,
    G3Last5Avg?: number | null,
    G6Last5Avg?: number | null,
    G9Last5Avg?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    schoolRatingsId?: string | null,
  } | null,
};

export type DeleteSchoolRatingMutationVariables = {
  input: DeleteSchoolRatingInput,
  condition?: ModelSchoolRatingConditionInput | null,
};

export type DeleteSchoolRatingMutation = {
  deleteSchoolRating?:  {
    __typename: "SchoolRating",
    objVer?: string | null,
    schoolId?: string | null,
    school?:  {
      __typename: "School",
      objVer?: string | null,
      name?: string | null,
      isCatholic?: boolean | null,
      isPublic?: boolean | null,
      isUseSchool?: boolean | null,
      gradeFrom?: number | null,
      gradeEnd?: number | null,
      isElementary?: boolean | null,
      isMiddle?: boolean | null,
      isHigh?: boolean | null,
      isEnglish?: boolean | null,
      isFrenchImmersion?: boolean | null,
      isExtendedFrench?: boolean | null,
      isAP?: boolean | null,
      isArts?: boolean | null,
      isGifted?: boolean | null,
      isIB?: boolean | null,
      isSport?: boolean | null,
      g3Avg?: number | null,
      g3WeightedAvg?: number | null,
      g6Avg?: number | null,
      g6WeightedAvg?: number | null,
      g9Avg?: number | null,
      g9APMWeightedAvg?: number | null,
      g9ACMWeightedAvg?: number | null,
      g10Avg?: number | null,
      ettieOverallAvg?: number | null,
      g3Rank?: number | null,
      g3RankTotal?: number | null,
      g6Rank?: number | null,
      g6RankTotal?: number | null,
      g9Rank?: number | null,
      g9RankTotal?: number | null,
      g9ACRank?: number | null,
      g9ACTotalRank?: number | null,
      g9APRank?: number | null,
      g9APTotalRank?: number | null,
      g10Rank?: number | null,
      g10RankTotal?: number | null,
      EQAOScore?: number | null,
      yearMark?: string | null,
      province?: string | null,
      nation?: string | null,
      url?: string | null,
      schoolBoardId?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone?: string | null,
      fax?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      comments?: string | null,
      boundaryArray?: string | null,
      schoolsAssocationArray?: string | null,
      lastReviewDate?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolBoardSchoolsId?: string | null,
    } | null,
    yearMark?: string | null,
    year?: number | null,
    G3STU?: number | null,
    G3AR?: number | null,
    G3AW?: number | null,
    G3AM?: number | null,
    G3Rank?: number | null,
    G3Total?: number | null,
    G3FindSchoolRating?: number | null,
    G6STU?: number | null,
    G6AR?: number | null,
    G6AW?: number | null,
    G6AM?: number | null,
    G6Rank?: number | null,
    G6Total?: number | null,
    G6FindSchoolRating?: number | null,
    G9ACSTU?: number | null,
    G9ACM?: number | null,
    G9ACRank?: number | null,
    G9ACTotal?: number | null,
    G9APSTU?: number | null,
    G9APM?: number | null,
    G9APRank?: number | null,
    G9APTotal?: number | null,
    G9Rank?: number | null,
    G9Total?: number | null,
    G9FindSchoolRating?: number | null,
    G10OSSLTFSTUA?: number | null,
    G10OSSLTFSTUP?: number | null,
    G10OSSLTFSUCCESS?: number | null,
    G3Last5Avg?: number | null,
    G6Last5Avg?: number | null,
    G9Last5Avg?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    schoolRatingsId?: string | null,
  } | null,
};

export type CreateProjectAmenitiesMutationVariables = {
  input: CreateProjectAmenitiesInput,
  condition?: ModelProjectAmenitiesConditionInput | null,
};

export type CreateProjectAmenitiesMutation = {
  createProjectAmenities?:  {
    __typename: "ProjectAmenities",
    id: string,
    projectID: string,
    amenityID: string,
    project:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    },
    amenity:  {
      __typename: "Amenity",
      name: string,
      description?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProjectAmenitiesMutationVariables = {
  input: UpdateProjectAmenitiesInput,
  condition?: ModelProjectAmenitiesConditionInput | null,
};

export type UpdateProjectAmenitiesMutation = {
  updateProjectAmenities?:  {
    __typename: "ProjectAmenities",
    id: string,
    projectID: string,
    amenityID: string,
    project:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    },
    amenity:  {
      __typename: "Amenity",
      name: string,
      description?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProjectAmenitiesMutationVariables = {
  input: DeleteProjectAmenitiesInput,
  condition?: ModelProjectAmenitiesConditionInput | null,
};

export type DeleteProjectAmenitiesMutation = {
  deleteProjectAmenities?:  {
    __typename: "ProjectAmenities",
    id: string,
    projectID: string,
    amenityID: string,
    project:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    },
    amenity:  {
      __typename: "Amenity",
      name: string,
      description?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetBuilderQueryVariables = {
  id: string,
};

export type GetBuilderQuery = {
  getBuilder?:  {
    __typename: "Builder",
    id: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    logo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBuildersQueryVariables = {
  id?: string | null,
  filter?: ModelBuilderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBuildersQuery = {
  listBuilders?:  {
    __typename: "ModelBuilderConnection",
    items:  Array< {
      __typename: "Builder",
      id: string,
      name: string,
      logo?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetArchitectQueryVariables = {
  id: string,
};

export type GetArchitectQuery = {
  getArchitect?:  {
    __typename: "Architect",
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    logo?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListArchitectsQueryVariables = {
  filter?: ModelArchitectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArchitectsQuery = {
  listArchitects?:  {
    __typename: "ModelArchitectConnection",
    items:  Array< {
      __typename: "Architect",
      name: string,
      logo?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInteriorDesignerQueryVariables = {
  id: string,
};

export type GetInteriorDesignerQuery = {
  getInteriorDesigner?:  {
    __typename: "InteriorDesigner",
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    logo?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListInteriorDesignersQueryVariables = {
  filter?: ModelInteriorDesignerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInteriorDesignersQuery = {
  listInteriorDesigners?:  {
    __typename: "ModelInteriorDesignerConnection",
    items:  Array< {
      __typename: "InteriorDesigner",
      name: string,
      logo?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProjectQueryVariables = {
  id: string,
};

export type GetProjectQuery = {
  getProject?:  {
    __typename: "Project",
    name: string,
    streetNumber: string,
    streetName: string,
    city: string,
    province: string,
    latitude?: number | null,
    longitude?: number | null,
    builderId: string,
    builder?:  {
      __typename: "Builder",
      id: string,
      name: string,
      logo?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    architectId?: string | null,
    architect?:  {
      __typename: "Architect",
      name: string,
      logo?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    interiorDesignerId?: string | null,
    interiorDesigner?:  {
      __typename: "InteriorDesigner",
      name: string,
      logo?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    occupancy?: string | null,
    units?: number | null,
    stories?: number | null,
    startPrice?: number | null,
    endPrice?: number | null,
    minBeds?: number | null,
    maxBeds?: number | null,
    minSqft?: number | null,
    maxSqft?: number | null,
    pricePerSqft?: number | null,
    maintenanceFees?: string | null,
    parkingPrice?: number | null,
    lockerPrice?: number | null,
    phase?: ProjectPhase | null,
    availability?: ProjectAvailability | null,
    types?: Array< PropertyType | null > | null,
    description?: string | null,
    incentives?: string | null,
    amenities?:  {
      __typename: "ModelProjectAmenitiesConnection",
      nextToken?: string | null,
    } | null,
    depositStructure?: string | null,
    logo?: string | null,
    media?: string | null,
    projectUnits?:  {
      __typename: "ModelProjectUnitConnection",
      nextToken?: string | null,
    } | null,
    lastUpdatedOn?: string | null,
    isSoldOut?: boolean | null,
    commission?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    builderProjectsId?: string | null,
    architectProjectsId?: string | null,
    interiorDesignerProjectsId?: string | null,
  } | null,
};

export type ListProjectsQueryVariables = {
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectsQuery = {
  listProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectByNameQuery = {
  projectByName?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectByBuilderQueryVariables = {
  builderId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectByBuilderQuery = {
  projectByBuilder?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectByArchitectQueryVariables = {
  architectId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectByArchitectQuery = {
  projectByArchitect?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectByInteriorDesignerQueryVariables = {
  interiorDesignerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectByInteriorDesignerQuery = {
  projectByInteriorDesigner?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProjectUnitQueryVariables = {
  id: string,
};

export type GetProjectUnitQuery = {
  getProjectUnit?:  {
    __typename: "ProjectUnit",
    numBeds?: number | null,
    numBaths?: number | null,
    numParkings?: number | null,
    numLockers?: number | null,
    sqft?: number | null,
    price?: number | null,
    pricePerSqft?: number | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null,
    type?: PropertyType | null,
    available?: number | null,
    sold?: number | null,
    media?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    projectProjectUnitsId?: string | null,
  } | null,
};

export type ListProjectUnitsQueryVariables = {
  filter?: ModelProjectUnitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectUnitsQuery = {
  listProjectUnits?:  {
    __typename: "ModelProjectUnitConnection",
    items:  Array< {
      __typename: "ProjectUnit",
      numBeds?: number | null,
      numBaths?: number | null,
      numParkings?: number | null,
      numLockers?: number | null,
      sqft?: number | null,
      price?: number | null,
      pricePerSqft?: number | null,
      projectId: string,
      type?: PropertyType | null,
      available?: number | null,
      sold?: number | null,
      media?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      projectProjectUnitsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAmenityQueryVariables = {
  id: string,
};

export type GetAmenityQuery = {
  getAmenity?:  {
    __typename: "Amenity",
    name: string,
    description?: string | null,
    projects?:  {
      __typename: "ModelProjectAmenitiesConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAmenitiesQueryVariables = {
  filter?: ModelAmenityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAmenitiesQuery = {
  listAmenities?:  {
    __typename: "ModelAmenityConnection",
    items:  Array< {
      __typename: "Amenity",
      name: string,
      description?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFeaturedQueryVariables = {
  id: string,
};

export type GetFeaturedQuery = {
  getFeatured?:  {
    __typename: "Featured",
    title: string,
    subtitle?: string | null,
    filters?: string | null,
    isNearby?: boolean | null,
    isFavourite?: boolean | null,
    isSchool?: boolean | null,
    order?: number | null,
    status: FeaturedStatus,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFeaturedsQueryVariables = {
  filter?: ModelFeaturedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeaturedsQuery = {
  listFeatureds?:  {
    __typename: "ModelFeaturedConnection",
    items:  Array< {
      __typename: "Featured",
      title: string,
      subtitle?: string | null,
      filters?: string | null,
      isNearby?: boolean | null,
      isFavourite?: boolean | null,
      isSchool?: boolean | null,
      order?: number | null,
      status: FeaturedStatus,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHomeCardQueryVariables = {
  id: string,
};

export type GetHomeCardQuery = {
  getHomeCard?:  {
    __typename: "HomeCard",
    title: string,
    text?: string | null,
    image?: string | null,
    actionLabel?: string | null,
    actionLink?: string | null,
    inAppAction?: boolean | null,
    order?: number | null,
    status: FeaturedStatus,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListHomeCardsQueryVariables = {
  filter?: ModelHomeCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHomeCardsQuery = {
  listHomeCards?:  {
    __typename: "ModelHomeCardConnection",
    items:  Array< {
      __typename: "HomeCard",
      title: string,
      text?: string | null,
      image?: string | null,
      actionLabel?: string | null,
      actionLink?: string | null,
      inAppAction?: boolean | null,
      order?: number | null,
      status: FeaturedStatus,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProfileQueryVariables = {
  id: string,
};

export type GetProfileQuery = {
  getProfile?:  {
    __typename: "Profile",
    userId: string,
    buyingSelling?: boolean | null,
    leasingRenting?: boolean | null,
    justLooking?: boolean | null,
    preConstruction?: boolean | null,
    isRealtor?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProfilesQueryVariables = {
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesQuery = {
  listProfiles?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      userId: string,
      buyingSelling?: boolean | null,
      leasingRenting?: boolean | null,
      justLooking?: boolean | null,
      preConstruction?: boolean | null,
      isRealtor?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFavouriteQueryVariables = {
  id: string,
};

export type GetFavouriteQuery = {
  getFavourite?:  {
    __typename: "Favourite",
    userId?: string | null,
    repliersID?: string | null,
    mlsNumber: string,
    listing: string,
    listingUpdate?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFavouritesQueryVariables = {
  filter?: ModelFavouriteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFavouritesQuery = {
  listFavourites?:  {
    __typename: "ModelFavouriteConnection",
    items:  Array< {
      __typename: "Favourite",
      userId?: string | null,
      repliersID?: string | null,
      mlsNumber: string,
      listing: string,
      listingUpdate?: string | null,
      notification?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFavouriteByMlsNumberQueryVariables = {
  mlsNumber: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFavouriteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetFavouriteByMlsNumberQuery = {
  getFavouriteByMlsNumber?:  {
    __typename: "ModelFavouriteConnection",
    items:  Array< {
      __typename: "Favourite",
      userId?: string | null,
      repliersID?: string | null,
      mlsNumber: string,
      listing: string,
      listingUpdate?: string | null,
      notification?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPreFavouriteQueryVariables = {
  id: string,
};

export type GetPreFavouriteQuery = {
  getPreFavourite?:  {
    __typename: "PreFavourite",
    userId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPreFavouritesQueryVariables = {
  filter?: ModelPreFavouriteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPreFavouritesQuery = {
  listPreFavourites?:  {
    __typename: "ModelPreFavouriteConnection",
    items:  Array< {
      __typename: "PreFavourite",
      userId?: string | null,
      projectId: string,
      notification?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPreFavouriteByProjectIdQueryVariables = {
  projectId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPreFavouriteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetPreFavouriteByProjectIdQuery = {
  getPreFavouriteByProjectId?:  {
    __typename: "ModelPreFavouriteConnection",
    items:  Array< {
      __typename: "PreFavourite",
      userId?: string | null,
      projectId: string,
      notification?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSearchQueryVariables = {
  id: string,
};

export type GetSearchQuery = {
  getSearch?:  {
    __typename: "Search",
    name?: string | null,
    userId?: string | null,
    repliersID?: string | null,
    value?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSearchesQueryVariables = {
  filter?: ModelSearchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSearchesQuery = {
  listSearches?:  {
    __typename: "ModelSearchConnection",
    items:  Array< {
      __typename: "Search",
      name?: string | null,
      userId?: string | null,
      repliersID?: string | null,
      value?: string | null,
      notification?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSearchByRepliersIdQueryVariables = {
  repliersID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSearchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSearchByRepliersIdQuery = {
  getSearchByRepliersId?:  {
    __typename: "ModelSearchConnection",
    items:  Array< {
      __typename: "Search",
      name?: string | null,
      userId?: string | null,
      repliersID?: string | null,
      value?: string | null,
      notification?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    userId: string,
    schedule?: NotificationSchedule | null,
    type?: Array< NotificationType | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      userId: string,
      schedule?: NotificationSchedule | null,
      type?: Array< NotificationType | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    repliersID?: string | null,
    givenName: string,
    familyName: string,
    name: string,
    displayName: string,
    email: string,
    phone?: string | null,
    profilePicture?: string | null,
    role: UserRole,
    agent?:  {
      __typename: "Agent",
      userId: string,
      website?: string | null,
      licenseNumber?: string | null,
      areas?: Array< string | null > | null,
      availability?: Array< string | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      brokerageAgentsId?: string | null,
    } | null,
    client?:  {
      __typename: "Client",
      userId: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      agentClientsId?: string | null,
      clientAgentId?: string | null,
    } | null,
    subscribed?: boolean | null,
    currency?: string | null,
    measurement?: Measurement | null,
    notifications?:  {
      __typename: "Notification",
      userId: string,
      schedule?: NotificationSchedule | null,
      type?: Array< NotificationType | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    deviceToken?: string | null,
    profile?:  {
      __typename: "Profile",
      userId: string,
      buyingSelling?: boolean | null,
      leasingRenting?: boolean | null,
      justLooking?: boolean | null,
      preConstruction?: boolean | null,
      isRealtor?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    requestedTour?: boolean | null,
    tours?:  {
      __typename: "ModelUserTourConnection",
      nextToken?: string | null,
    } | null,
    toursNote?: string | null,
    deactivated?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    userAgentId?: string | null,
    userClientId?: string | null,
    userNotificationsId?: string | null,
    userProfileId?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      repliersID?: string | null,
      givenName: string,
      familyName: string,
      name: string,
      displayName: string,
      email: string,
      phone?: string | null,
      profilePicture?: string | null,
      role: UserRole,
      subscribed?: boolean | null,
      currency?: string | null,
      measurement?: Measurement | null,
      deviceToken?: string | null,
      requestedTour?: boolean | null,
      toursNote?: string | null,
      deactivated?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      userAgentId?: string | null,
      userClientId?: string | null,
      userNotificationsId?: string | null,
      userProfileId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserByNameQuery = {
  getUserByName?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      repliersID?: string | null,
      givenName: string,
      familyName: string,
      name: string,
      displayName: string,
      email: string,
      phone?: string | null,
      profilePicture?: string | null,
      role: UserRole,
      subscribed?: boolean | null,
      currency?: string | null,
      measurement?: Measurement | null,
      deviceToken?: string | null,
      requestedTour?: boolean | null,
      toursNote?: string | null,
      deactivated?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      userAgentId?: string | null,
      userClientId?: string | null,
      userNotificationsId?: string | null,
      userProfileId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserByEmailQuery = {
  getUserByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      repliersID?: string | null,
      givenName: string,
      familyName: string,
      name: string,
      displayName: string,
      email: string,
      phone?: string | null,
      profilePicture?: string | null,
      role: UserRole,
      subscribed?: boolean | null,
      currency?: string | null,
      measurement?: Measurement | null,
      deviceToken?: string | null,
      requestedTour?: boolean | null,
      toursNote?: string | null,
      deactivated?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      userAgentId?: string | null,
      userClientId?: string | null,
      userNotificationsId?: string | null,
      userProfileId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAgentQueryVariables = {
  id: string,
};

export type GetAgentQuery = {
  getAgent?:  {
    __typename: "Agent",
    userId: string,
    website?: string | null,
    licenseNumber?: string | null,
    areas?: Array< string | null > | null,
    availability?: Array< string | null > | null,
    brokerage?:  {
      __typename: "Brokerage",
      name: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    clients?:  {
      __typename: "ModelClientConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    brokerageAgentsId?: string | null,
  } | null,
};

export type ListAgentsQueryVariables = {
  filter?: ModelAgentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgentsQuery = {
  listAgents?:  {
    __typename: "ModelAgentConnection",
    items:  Array< {
      __typename: "Agent",
      userId: string,
      website?: string | null,
      licenseNumber?: string | null,
      areas?: Array< string | null > | null,
      availability?: Array< string | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      brokerageAgentsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClientQueryVariables = {
  id: string,
};

export type GetClientQuery = {
  getClient?:  {
    __typename: "Client",
    userId: string,
    agent?:  {
      __typename: "Agent",
      userId: string,
      website?: string | null,
      licenseNumber?: string | null,
      areas?: Array< string | null > | null,
      availability?: Array< string | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      brokerageAgentsId?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    agentClientsId?: string | null,
    clientAgentId?: string | null,
  } | null,
};

export type ListClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientsQuery = {
  listClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      userId: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      agentClientsId?: string | null,
      clientAgentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBrokerageQueryVariables = {
  id: string,
};

export type GetBrokerageQuery = {
  getBrokerage?:  {
    __typename: "Brokerage",
    name: string,
    agents?:  {
      __typename: "ModelAgentConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListBrokeragesQueryVariables = {
  filter?: ModelBrokerageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrokeragesQuery = {
  listBrokerages?:  {
    __typename: "ModelBrokerageConnection",
    items:  Array< {
      __typename: "Brokerage",
      name: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserTourQueryVariables = {
  id: string,
};

export type GetUserTourQuery = {
  getUserTour?:  {
    __typename: "UserTour",
    creator?: string | null,
    userId: string,
    tourId: string,
    user?:  {
      __typename: "User",
      repliersID?: string | null,
      givenName: string,
      familyName: string,
      name: string,
      displayName: string,
      email: string,
      phone?: string | null,
      profilePicture?: string | null,
      role: UserRole,
      subscribed?: boolean | null,
      currency?: string | null,
      measurement?: Measurement | null,
      deviceToken?: string | null,
      requestedTour?: boolean | null,
      toursNote?: string | null,
      deactivated?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      userAgentId?: string | null,
      userClientId?: string | null,
      userNotificationsId?: string | null,
      userProfileId?: string | null,
    } | null,
    tour?:  {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    role: TourRole,
    seen?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    userToursId?: string | null,
    tourUsersId?: string | null,
  } | null,
};

export type ListUserToursQueryVariables = {
  filter?: ModelUserTourFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserToursQuery = {
  listUserTours?:  {
    __typename: "ModelUserTourConnection",
    items:  Array< {
      __typename: "UserTour",
      creator?: string | null,
      userId: string,
      tourId: string,
      role: TourRole,
      seen?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      userToursId?: string | null,
      tourUsersId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTourQueryVariables = {
  id: string,
};

export type GetTourQuery = {
  getTour?:  {
    __typename: "Tour",
    title?: string | null,
    creator?: string | null,
    users?:  {
      __typename: "ModelUserTourConnection",
      nextToken?: string | null,
    } | null,
    clients?: Array< string | null > | null,
    primaryAgent?: string | null,
    showingAgents?: Array< string | null > | null,
    status?: TourStatus | null,
    privateNote?: string | null,
    generalNote?: string | null,
    tourItems?:  {
      __typename: "ModelTourItemConnection",
      nextToken?: string | null,
    } | null,
    meetupLocation?: string | null,
    meetupDescription?: string | null,
    meetupTime?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    date?: string | null,
    shared?: ShareStatus | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListToursQueryVariables = {
  filter?: ModelTourFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListToursQuery = {
  listTours?:  {
    __typename: "ModelTourConnection",
    items:  Array< {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetToursByStatusQueryVariables = {
  status: TourStatus,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTourFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetToursByStatusQuery = {
  getToursByStatus?:  {
    __typename: "ModelTourConnection",
    items:  Array< {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetToursByDateQueryVariables = {
  date: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTourFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetToursByDateQuery = {
  getToursByDate?:  {
    __typename: "ModelTourConnection",
    items:  Array< {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetToursByShareStatusQueryVariables = {
  shared: ShareStatus,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTourFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetToursByShareStatusQuery = {
  getToursByShareStatus?:  {
    __typename: "ModelTourConnection",
    items:  Array< {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTourItemQueryVariables = {
  id: string,
};

export type GetTourItemQuery = {
  getTourItem?:  {
    __typename: "TourItem",
    creator?: string | null,
    tourId: string,
    tour?:  {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    clients?: Array< string | null > | null,
    primaryAgent?: string | null,
    showingAgents?: Array< string | null > | null,
    mlsNumber?: string | null,
    status?: TourItemStatus | null,
    startTime?: string | null,
    endTime?: string | null,
    order?: number | null,
    showingNote?: string | null,
    entryInfo?: string | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
    } | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      nextToken?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelPhotoConnection",
      nextToken?: string | null,
    } | null,
    recordings?:  {
      __typename: "ModelRecordingConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourTourItemsId?: string | null,
  } | null,
};

export type ListTourItemsQueryVariables = {
  filter?: ModelTourItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTourItemsQuery = {
  listTourItems?:  {
    __typename: "ModelTourItemConnection",
    items:  Array< {
      __typename: "TourItem",
      creator?: string | null,
      tourId: string,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      mlsNumber?: string | null,
      status?: TourItemStatus | null,
      startTime?: string | null,
      endTime?: string | null,
      order?: number | null,
      showingNote?: string | null,
      entryInfo?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      tourTourItemsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTourItemsByOrderQueryVariables = {
  order: number,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTourItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTourItemsByOrderQuery = {
  getTourItemsByOrder?:  {
    __typename: "ModelTourItemConnection",
    items:  Array< {
      __typename: "TourItem",
      creator?: string | null,
      tourId: string,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      mlsNumber?: string | null,
      status?: TourItemStatus | null,
      startTime?: string | null,
      endTime?: string | null,
      order?: number | null,
      showingNote?: string | null,
      entryInfo?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      tourTourItemsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShowingQueryVariables = {
  id: string,
};

export type GetShowingQuery = {
  getShowing?:  {
    __typename: "Showing",
    userId: string,
    mlsNumber?: string | null,
    date?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    instructions?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListShowingsQueryVariables = {
  filter?: ModelShowingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShowingsQuery = {
  listShowings?:  {
    __typename: "ModelShowingConnection",
    items:  Array< {
      __typename: "Showing",
      userId: string,
      mlsNumber?: string | null,
      date?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      instructions?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShowingsByDateQueryVariables = {
  date: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShowingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetShowingsByDateQuery = {
  getShowingsByDate?:  {
    __typename: "ModelShowingConnection",
    items:  Array< {
      __typename: "Showing",
      userId: string,
      mlsNumber?: string | null,
      date?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      instructions?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReviewQueryVariables = {
  id: string,
};

export type GetReviewQuery = {
  getReview?:  {
    __typename: "Review",
    userId?: string | null,
    tourItemId: string,
    rate?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemReviewsId?: string | null,
  } | null,
};

export type GetSchoolQueryVariables = {
  id: string,
};

export type GetSchoolQuery = {
  getSchool?:  {
    __typename: "School",
    objVer?: string | null,
    name?: string | null,
    isCatholic?: boolean | null,
    isPublic?: boolean | null,
    isUseSchool?: boolean | null,
    gradeFrom?: number | null,
    gradeEnd?: number | null,
    isElementary?: boolean | null,
    isMiddle?: boolean | null,
    isHigh?: boolean | null,
    isEnglish?: boolean | null,
    isFrenchImmersion?: boolean | null,
    isExtendedFrench?: boolean | null,
    isAP?: boolean | null,
    isArts?: boolean | null,
    isGifted?: boolean | null,
    isIB?: boolean | null,
    isSport?: boolean | null,
    g3Avg?: number | null,
    g3WeightedAvg?: number | null,
    g6Avg?: number | null,
    g6WeightedAvg?: number | null,
    g9Avg?: number | null,
    g9APMWeightedAvg?: number | null,
    g9ACMWeightedAvg?: number | null,
    g10Avg?: number | null,
    ettieOverallAvg?: number | null,
    g3Rank?: number | null,
    g3RankTotal?: number | null,
    g6Rank?: number | null,
    g6RankTotal?: number | null,
    g9Rank?: number | null,
    g9RankTotal?: number | null,
    g9ACRank?: number | null,
    g9ACTotalRank?: number | null,
    g9APRank?: number | null,
    g9APTotalRank?: number | null,
    g10Rank?: number | null,
    g10RankTotal?: number | null,
    EQAOScore?: number | null,
    yearMark?: string | null,
    province?: string | null,
    nation?: string | null,
    url?: string | null,
    schoolBoardId?: string | null,
    schoolBoard?:  {
      __typename: "SchoolBoard",
      objVer?: string | null,
      name?: string | null,
      url?: string | null,
      boardType?: number | null,
      province?: string | null,
      nation?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone?: string | null,
      fax?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    ratings?:  {
      __typename: "ModelSchoolRatingConnection",
      nextToken?: string | null,
    } | null,
    address?: string | null,
    city?: string | null,
    postalCode?: string | null,
    phone?: string | null,
    fax?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    comments?: string | null,
    boundaryArray?: string | null,
    schoolsAssocationArray?: string | null,
    lastReviewDate?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    schoolBoardSchoolsId?: string | null,
  } | null,
};

export type ListSchoolsQueryVariables = {
  filter?: ModelSchoolFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSchoolsQuery = {
  listSchools?:  {
    __typename: "ModelSchoolConnection",
    items:  Array< {
      __typename: "School",
      objVer?: string | null,
      name?: string | null,
      isCatholic?: boolean | null,
      isPublic?: boolean | null,
      isUseSchool?: boolean | null,
      gradeFrom?: number | null,
      gradeEnd?: number | null,
      isElementary?: boolean | null,
      isMiddle?: boolean | null,
      isHigh?: boolean | null,
      isEnglish?: boolean | null,
      isFrenchImmersion?: boolean | null,
      isExtendedFrench?: boolean | null,
      isAP?: boolean | null,
      isArts?: boolean | null,
      isGifted?: boolean | null,
      isIB?: boolean | null,
      isSport?: boolean | null,
      g3Avg?: number | null,
      g3WeightedAvg?: number | null,
      g6Avg?: number | null,
      g6WeightedAvg?: number | null,
      g9Avg?: number | null,
      g9APMWeightedAvg?: number | null,
      g9ACMWeightedAvg?: number | null,
      g10Avg?: number | null,
      ettieOverallAvg?: number | null,
      g3Rank?: number | null,
      g3RankTotal?: number | null,
      g6Rank?: number | null,
      g6RankTotal?: number | null,
      g9Rank?: number | null,
      g9RankTotal?: number | null,
      g9ACRank?: number | null,
      g9ACTotalRank?: number | null,
      g9APRank?: number | null,
      g9APTotalRank?: number | null,
      g10Rank?: number | null,
      g10RankTotal?: number | null,
      EQAOScore?: number | null,
      yearMark?: string | null,
      province?: string | null,
      nation?: string | null,
      url?: string | null,
      schoolBoardId?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone?: string | null,
      fax?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      comments?: string | null,
      boundaryArray?: string | null,
      schoolsAssocationArray?: string | null,
      lastReviewDate?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolBoardSchoolsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSchoolBoardQueryVariables = {
  id: string,
};

export type GetSchoolBoardQuery = {
  getSchoolBoard?:  {
    __typename: "SchoolBoard",
    objVer?: string | null,
    name?: string | null,
    url?: string | null,
    boardType?: number | null,
    province?: string | null,
    nation?: string | null,
    address?: string | null,
    city?: string | null,
    postalCode?: string | null,
    phone?: string | null,
    fax?: string | null,
    schools?:  {
      __typename: "ModelSchoolConnection",
      nextToken?: string | null,
    } | null,
    scores?:  {
      __typename: "ModelSchoolBoardScoreConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSchoolBoardsQueryVariables = {
  filter?: ModelSchoolBoardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSchoolBoardsQuery = {
  listSchoolBoards?:  {
    __typename: "ModelSchoolBoardConnection",
    items:  Array< {
      __typename: "SchoolBoard",
      objVer?: string | null,
      name?: string | null,
      url?: string | null,
      boardType?: number | null,
      province?: string | null,
      nation?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone?: string | null,
      fax?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSchoolBoardScoreQueryVariables = {
  id: string,
};

export type GetSchoolBoardScoreQuery = {
  getSchoolBoardScore?:  {
    __typename: "SchoolBoardScore",
    schoolBoardId?: string | null,
    schoolBoard?:  {
      __typename: "SchoolBoard",
      objVer?: string | null,
      name?: string | null,
      url?: string | null,
      boardType?: number | null,
      province?: string | null,
      nation?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone?: string | null,
      fax?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    yearMark?: string | null,
    year?: number | null,
    totalAvg?: number | null,
    g3STU?: number | null,
    g6STU?: number | null,
    g9ACSTU?: number | null,
    g9APSTU?: number | null,
    g3TS?: number | null,
    g6TS?: number | null,
    g9TS?: number | null,
    g10OSSLTFSTUA?: number | null,
    g10OSSLTFSTUP?: number | null,
    g10OSSLTFSUCCESS?: number | null,
    g3AR?: number | null,
    g3AW?: number | null,
    g3AM?: number | null,
    g6AR?: number | null,
    g6AW?: number | null,
    g6AM?: number | null,
    g9APM?: number | null,
    g9ACM?: number | null,
    g3Last5Avg?: number | null,
    g6Last5Avg?: number | null,
    g9Last5Avg?: number | null,
    g3FindSchoolRating?: number | null,
    g6FindSchoolRating?: number | null,
    g9FindSchoolRating?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    schoolBoardScoresId?: string | null,
  } | null,
};

export type ListSchoolBoardScoresQueryVariables = {
  filter?: ModelSchoolBoardScoreFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSchoolBoardScoresQuery = {
  listSchoolBoardScores?:  {
    __typename: "ModelSchoolBoardScoreConnection",
    items:  Array< {
      __typename: "SchoolBoardScore",
      schoolBoardId?: string | null,
      yearMark?: string | null,
      year?: number | null,
      totalAvg?: number | null,
      g3STU?: number | null,
      g6STU?: number | null,
      g9ACSTU?: number | null,
      g9APSTU?: number | null,
      g3TS?: number | null,
      g6TS?: number | null,
      g9TS?: number | null,
      g10OSSLTFSTUA?: number | null,
      g10OSSLTFSTUP?: number | null,
      g10OSSLTFSUCCESS?: number | null,
      g3AR?: number | null,
      g3AW?: number | null,
      g3AM?: number | null,
      g6AR?: number | null,
      g6AW?: number | null,
      g6AM?: number | null,
      g9APM?: number | null,
      g9ACM?: number | null,
      g3Last5Avg?: number | null,
      g6Last5Avg?: number | null,
      g9Last5Avg?: number | null,
      g3FindSchoolRating?: number | null,
      g6FindSchoolRating?: number | null,
      g9FindSchoolRating?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolBoardScoresId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSchoolRatingQueryVariables = {
  id: string,
};

export type GetSchoolRatingQuery = {
  getSchoolRating?:  {
    __typename: "SchoolRating",
    objVer?: string | null,
    schoolId?: string | null,
    school?:  {
      __typename: "School",
      objVer?: string | null,
      name?: string | null,
      isCatholic?: boolean | null,
      isPublic?: boolean | null,
      isUseSchool?: boolean | null,
      gradeFrom?: number | null,
      gradeEnd?: number | null,
      isElementary?: boolean | null,
      isMiddle?: boolean | null,
      isHigh?: boolean | null,
      isEnglish?: boolean | null,
      isFrenchImmersion?: boolean | null,
      isExtendedFrench?: boolean | null,
      isAP?: boolean | null,
      isArts?: boolean | null,
      isGifted?: boolean | null,
      isIB?: boolean | null,
      isSport?: boolean | null,
      g3Avg?: number | null,
      g3WeightedAvg?: number | null,
      g6Avg?: number | null,
      g6WeightedAvg?: number | null,
      g9Avg?: number | null,
      g9APMWeightedAvg?: number | null,
      g9ACMWeightedAvg?: number | null,
      g10Avg?: number | null,
      ettieOverallAvg?: number | null,
      g3Rank?: number | null,
      g3RankTotal?: number | null,
      g6Rank?: number | null,
      g6RankTotal?: number | null,
      g9Rank?: number | null,
      g9RankTotal?: number | null,
      g9ACRank?: number | null,
      g9ACTotalRank?: number | null,
      g9APRank?: number | null,
      g9APTotalRank?: number | null,
      g10Rank?: number | null,
      g10RankTotal?: number | null,
      EQAOScore?: number | null,
      yearMark?: string | null,
      province?: string | null,
      nation?: string | null,
      url?: string | null,
      schoolBoardId?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone?: string | null,
      fax?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      comments?: string | null,
      boundaryArray?: string | null,
      schoolsAssocationArray?: string | null,
      lastReviewDate?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolBoardSchoolsId?: string | null,
    } | null,
    yearMark?: string | null,
    year?: number | null,
    G3STU?: number | null,
    G3AR?: number | null,
    G3AW?: number | null,
    G3AM?: number | null,
    G3Rank?: number | null,
    G3Total?: number | null,
    G3FindSchoolRating?: number | null,
    G6STU?: number | null,
    G6AR?: number | null,
    G6AW?: number | null,
    G6AM?: number | null,
    G6Rank?: number | null,
    G6Total?: number | null,
    G6FindSchoolRating?: number | null,
    G9ACSTU?: number | null,
    G9ACM?: number | null,
    G9ACRank?: number | null,
    G9ACTotal?: number | null,
    G9APSTU?: number | null,
    G9APM?: number | null,
    G9APRank?: number | null,
    G9APTotal?: number | null,
    G9Rank?: number | null,
    G9Total?: number | null,
    G9FindSchoolRating?: number | null,
    G10OSSLTFSTUA?: number | null,
    G10OSSLTFSTUP?: number | null,
    G10OSSLTFSUCCESS?: number | null,
    G3Last5Avg?: number | null,
    G6Last5Avg?: number | null,
    G9Last5Avg?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    schoolRatingsId?: string | null,
  } | null,
};

export type ListSchoolRatingsQueryVariables = {
  filter?: ModelSchoolRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSchoolRatingsQuery = {
  listSchoolRatings?:  {
    __typename: "ModelSchoolRatingConnection",
    items:  Array< {
      __typename: "SchoolRating",
      objVer?: string | null,
      schoolId?: string | null,
      yearMark?: string | null,
      year?: number | null,
      G3STU?: number | null,
      G3AR?: number | null,
      G3AW?: number | null,
      G3AM?: number | null,
      G3Rank?: number | null,
      G3Total?: number | null,
      G3FindSchoolRating?: number | null,
      G6STU?: number | null,
      G6AR?: number | null,
      G6AW?: number | null,
      G6AM?: number | null,
      G6Rank?: number | null,
      G6Total?: number | null,
      G6FindSchoolRating?: number | null,
      G9ACSTU?: number | null,
      G9ACM?: number | null,
      G9ACRank?: number | null,
      G9ACTotal?: number | null,
      G9APSTU?: number | null,
      G9APM?: number | null,
      G9APRank?: number | null,
      G9APTotal?: number | null,
      G9Rank?: number | null,
      G9Total?: number | null,
      G9FindSchoolRating?: number | null,
      G10OSSLTFSTUA?: number | null,
      G10OSSLTFSTUP?: number | null,
      G10OSSLTFSUCCESS?: number | null,
      G3Last5Avg?: number | null,
      G6Last5Avg?: number | null,
      G9Last5Avg?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      schoolRatingsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProjectAmenitiesQueryVariables = {
  id: string,
};

export type GetProjectAmenitiesQuery = {
  getProjectAmenities?:  {
    __typename: "ProjectAmenities",
    id: string,
    projectID: string,
    amenityID: string,
    project:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    },
    amenity:  {
      __typename: "Amenity",
      name: string,
      description?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProjectAmenitiesQueryVariables = {
  filter?: ModelProjectAmenitiesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectAmenitiesQuery = {
  listProjectAmenities?:  {
    __typename: "ModelProjectAmenitiesConnection",
    items:  Array< {
      __typename: "ProjectAmenities",
      id: string,
      projectID: string,
      amenityID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnFavouriteUpdateByUserIdSubscriptionVariables = {
  userId: string,
};

export type OnFavouriteUpdateByUserIdSubscription = {
  onFavouriteUpdateByUserId?:  {
    __typename: "Favourite",
    userId?: string | null,
    repliersID?: string | null,
    mlsNumber: string,
    listing: string,
    listingUpdate?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnPreFavouriteUpdateByUserIdSubscriptionVariables = {
  userId: string,
};

export type OnPreFavouriteUpdateByUserIdSubscription = {
  onPreFavouriteUpdateByUserId?:  {
    __typename: "PreFavourite",
    userId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTourByAgentsSubscriptionVariables = {
  agentId?: string | null,
  creator?: string | null,
  showingAgentId?: string | null,
};

export type OnCreateTourByAgentsSubscription = {
  onCreateTourByAgents?:  {
    __typename: "Tour",
    title?: string | null,
    creator?: string | null,
    users?:  {
      __typename: "ModelUserTourConnection",
      nextToken?: string | null,
    } | null,
    clients?: Array< string | null > | null,
    primaryAgent?: string | null,
    showingAgents?: Array< string | null > | null,
    status?: TourStatus | null,
    privateNote?: string | null,
    generalNote?: string | null,
    tourItems?:  {
      __typename: "ModelTourItemConnection",
      nextToken?: string | null,
    } | null,
    meetupLocation?: string | null,
    meetupDescription?: string | null,
    meetupTime?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    date?: string | null,
    shared?: ShareStatus | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTourByTourIdSubscriptionVariables = {
  id: string,
};

export type OnUpdateTourByTourIdSubscription = {
  onUpdateTourByTourId?:  {
    __typename: "Tour",
    title?: string | null,
    creator?: string | null,
    users?:  {
      __typename: "ModelUserTourConnection",
      nextToken?: string | null,
    } | null,
    clients?: Array< string | null > | null,
    primaryAgent?: string | null,
    showingAgents?: Array< string | null > | null,
    status?: TourStatus | null,
    privateNote?: string | null,
    generalNote?: string | null,
    tourItems?:  {
      __typename: "ModelTourItemConnection",
      nextToken?: string | null,
    } | null,
    meetupLocation?: string | null,
    meetupDescription?: string | null,
    meetupTime?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    date?: string | null,
    shared?: ShareStatus | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTourItemByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdateTourItemByIdSubscription = {
  onUpdateTourItemById?:  {
    __typename: "TourItem",
    creator?: string | null,
    tourId: string,
    tour?:  {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    clients?: Array< string | null > | null,
    primaryAgent?: string | null,
    showingAgents?: Array< string | null > | null,
    mlsNumber?: string | null,
    status?: TourItemStatus | null,
    startTime?: string | null,
    endTime?: string | null,
    order?: number | null,
    showingNote?: string | null,
    entryInfo?: string | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
    } | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      nextToken?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelPhotoConnection",
      nextToken?: string | null,
    } | null,
    recordings?:  {
      __typename: "ModelRecordingConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourTourItemsId?: string | null,
  } | null,
};

export type OnUpdateTourItemByTourIdSubscriptionVariables = {
  tourId: string,
};

export type OnUpdateTourItemByTourIdSubscription = {
  onUpdateTourItemByTourId?:  {
    __typename: "TourItem",
    creator?: string | null,
    tourId: string,
    tour?:  {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    clients?: Array< string | null > | null,
    primaryAgent?: string | null,
    showingAgents?: Array< string | null > | null,
    mlsNumber?: string | null,
    status?: TourItemStatus | null,
    startTime?: string | null,
    endTime?: string | null,
    order?: number | null,
    showingNote?: string | null,
    entryInfo?: string | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
    } | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      nextToken?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelPhotoConnection",
      nextToken?: string | null,
    } | null,
    recordings?:  {
      __typename: "ModelRecordingConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourTourItemsId?: string | null,
  } | null,
};

export type OnCreateTourItemByTourIdSubscriptionVariables = {
  tourId: string,
};

export type OnCreateTourItemByTourIdSubscription = {
  onCreateTourItemByTourId?:  {
    __typename: "TourItem",
    creator?: string | null,
    tourId: string,
    tour?:  {
      __typename: "Tour",
      title?: string | null,
      creator?: string | null,
      clients?: Array< string | null > | null,
      primaryAgent?: string | null,
      showingAgents?: Array< string | null > | null,
      status?: TourStatus | null,
      privateNote?: string | null,
      generalNote?: string | null,
      meetupLocation?: string | null,
      meetupDescription?: string | null,
      meetupTime?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      date?: string | null,
      shared?: ShareStatus | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    clients?: Array< string | null > | null,
    primaryAgent?: string | null,
    showingAgents?: Array< string | null > | null,
    mlsNumber?: string | null,
    status?: TourItemStatus | null,
    startTime?: string | null,
    endTime?: string | null,
    order?: number | null,
    showingNote?: string | null,
    entryInfo?: string | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
    } | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      nextToken?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelPhotoConnection",
      nextToken?: string | null,
    } | null,
    recordings?:  {
      __typename: "ModelRecordingConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourTourItemsId?: string | null,
  } | null,
};

export type OnCreatePhotoByTourItemIdSubscriptionVariables = {
  tourItemId: string,
};

export type OnCreatePhotoByTourItemIdSubscription = {
  onCreatePhotoByTourItemId?:  {
    __typename: "Photo",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemPhotosId?: string | null,
  } | null,
};

export type OnCreateRecordingByTourItemIdSubscriptionVariables = {
  tourItemId: string,
};

export type OnCreateRecordingByTourItemIdSubscription = {
  onCreateRecordingByTourItemId?:  {
    __typename: "Recording",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemRecordingsId?: string | null,
  } | null,
};

export type OnCreateNoteByTourItemIdSubscriptionVariables = {
  tourItemId: string,
};

export type OnCreateNoteByTourItemIdSubscription = {
  onCreateNoteByTourItemId?:  {
    __typename: "Note",
    userId?: string | null,
    content?: string | null,
    tourItemId: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemNotesId?: string | null,
  } | null,
};

export type OnDeletePhotoByTourItemIdSubscriptionVariables = {
  tourItemId: string,
};

export type OnDeletePhotoByTourItemIdSubscription = {
  onDeletePhotoByTourItemId?:  {
    __typename: "Photo",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemPhotosId?: string | null,
  } | null,
};

export type OnDeleteRecordingByTourItemIdSubscriptionVariables = {
  tourItemId: string,
};

export type OnDeleteRecordingByTourItemIdSubscription = {
  onDeleteRecordingByTourItemId?:  {
    __typename: "Recording",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemRecordingsId?: string | null,
  } | null,
};

export type OnUpdateNoteByTourItemIdSubscriptionVariables = {
  tourItemId: string,
};

export type OnUpdateNoteByTourItemIdSubscription = {
  onUpdateNoteByTourItemId?:  {
    __typename: "Note",
    userId?: string | null,
    content?: string | null,
    tourItemId: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemNotesId?: string | null,
  } | null,
};

export type OnCreateReviewByTourItemIdSubscriptionVariables = {
  tourItemId: string,
};

export type OnCreateReviewByTourItemIdSubscription = {
  onCreateReviewByTourItemId?:  {
    __typename: "Review",
    userId?: string | null,
    tourItemId: string,
    rate?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemReviewsId?: string | null,
  } | null,
};

export type OnUpdateReviewByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdateReviewByIdSubscription = {
  onUpdateReviewById?:  {
    __typename: "Review",
    userId?: string | null,
    tourItemId: string,
    rate?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemReviewsId?: string | null,
  } | null,
};

export type OnCreateFavouriteSubscriptionVariables = {
  filter?: ModelSubscriptionFavouriteFilterInput | null,
  userId?: string | null,
};

export type OnCreateFavouriteSubscription = {
  onCreateFavourite?:  {
    __typename: "Favourite",
    userId?: string | null,
    repliersID?: string | null,
    mlsNumber: string,
    listing: string,
    listingUpdate?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFavouriteSubscriptionVariables = {
  filter?: ModelSubscriptionFavouriteFilterInput | null,
  userId?: string | null,
};

export type OnUpdateFavouriteSubscription = {
  onUpdateFavourite?:  {
    __typename: "Favourite",
    userId?: string | null,
    repliersID?: string | null,
    mlsNumber: string,
    listing: string,
    listingUpdate?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFavouriteSubscriptionVariables = {
  filter?: ModelSubscriptionFavouriteFilterInput | null,
  userId?: string | null,
};

export type OnDeleteFavouriteSubscription = {
  onDeleteFavourite?:  {
    __typename: "Favourite",
    userId?: string | null,
    repliersID?: string | null,
    mlsNumber: string,
    listing: string,
    listingUpdate?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePreFavouriteSubscriptionVariables = {
  filter?: ModelSubscriptionPreFavouriteFilterInput | null,
  userId?: string | null,
};

export type OnCreatePreFavouriteSubscription = {
  onCreatePreFavourite?:  {
    __typename: "PreFavourite",
    userId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePreFavouriteSubscriptionVariables = {
  filter?: ModelSubscriptionPreFavouriteFilterInput | null,
  userId?: string | null,
};

export type OnUpdatePreFavouriteSubscription = {
  onUpdatePreFavourite?:  {
    __typename: "PreFavourite",
    userId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePreFavouriteSubscriptionVariables = {
  filter?: ModelSubscriptionPreFavouriteFilterInput | null,
  userId?: string | null,
};

export type OnDeletePreFavouriteSubscription = {
  onDeletePreFavourite?:  {
    __typename: "PreFavourite",
    userId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    } | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSearchSubscriptionVariables = {
  filter?: ModelSubscriptionSearchFilterInput | null,
  userId?: string | null,
};

export type OnCreateSearchSubscription = {
  onCreateSearch?:  {
    __typename: "Search",
    name?: string | null,
    userId?: string | null,
    repliersID?: string | null,
    value?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSearchSubscriptionVariables = {
  filter?: ModelSubscriptionSearchFilterInput | null,
  userId?: string | null,
};

export type OnUpdateSearchSubscription = {
  onUpdateSearch?:  {
    __typename: "Search",
    name?: string | null,
    userId?: string | null,
    repliersID?: string | null,
    value?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSearchSubscriptionVariables = {
  filter?: ModelSubscriptionSearchFilterInput | null,
  userId?: string | null,
};

export type OnDeleteSearchSubscription = {
  onDeleteSearch?:  {
    __typename: "Search",
    name?: string | null,
    userId?: string | null,
    repliersID?: string | null,
    value?: string | null,
    notification?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
  userId?: string | null,
};

export type OnCreateReviewSubscription = {
  onCreateReview?:  {
    __typename: "Review",
    userId?: string | null,
    tourItemId: string,
    rate?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemReviewsId?: string | null,
  } | null,
};

export type OnUpdateReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
  userId?: string | null,
};

export type OnUpdateReviewSubscription = {
  onUpdateReview?:  {
    __typename: "Review",
    userId?: string | null,
    tourItemId: string,
    rate?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemReviewsId?: string | null,
  } | null,
};

export type OnDeleteReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
  userId?: string | null,
};

export type OnDeleteReviewSubscription = {
  onDeleteReview?:  {
    __typename: "Review",
    userId?: string | null,
    tourItemId: string,
    rate?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemReviewsId?: string | null,
  } | null,
};

export type OnCreateNoteSubscriptionVariables = {
  filter?: ModelSubscriptionNoteFilterInput | null,
  userId?: string | null,
};

export type OnCreateNoteSubscription = {
  onCreateNote?:  {
    __typename: "Note",
    userId?: string | null,
    content?: string | null,
    tourItemId: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemNotesId?: string | null,
  } | null,
};

export type OnUpdateNoteSubscriptionVariables = {
  filter?: ModelSubscriptionNoteFilterInput | null,
  userId?: string | null,
};

export type OnUpdateNoteSubscription = {
  onUpdateNote?:  {
    __typename: "Note",
    userId?: string | null,
    content?: string | null,
    tourItemId: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemNotesId?: string | null,
  } | null,
};

export type OnDeleteNoteSubscriptionVariables = {
  filter?: ModelSubscriptionNoteFilterInput | null,
  userId?: string | null,
};

export type OnDeleteNoteSubscription = {
  onDeleteNote?:  {
    __typename: "Note",
    userId?: string | null,
    content?: string | null,
    tourItemId: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    tourItemNotesId?: string | null,
  } | null,
};

export type OnCreatePhotoSubscriptionVariables = {
  filter?: ModelSubscriptionPhotoFilterInput | null,
  userId?: string | null,
};

export type OnCreatePhotoSubscription = {
  onCreatePhoto?:  {
    __typename: "Photo",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemPhotosId?: string | null,
  } | null,
};

export type OnUpdatePhotoSubscriptionVariables = {
  filter?: ModelSubscriptionPhotoFilterInput | null,
  userId?: string | null,
};

export type OnUpdatePhotoSubscription = {
  onUpdatePhoto?:  {
    __typename: "Photo",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemPhotosId?: string | null,
  } | null,
};

export type OnDeletePhotoSubscriptionVariables = {
  filter?: ModelSubscriptionPhotoFilterInput | null,
  userId?: string | null,
};

export type OnDeletePhotoSubscription = {
  onDeletePhoto?:  {
    __typename: "Photo",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemPhotosId?: string | null,
  } | null,
};

export type OnCreateRecordingSubscriptionVariables = {
  filter?: ModelSubscriptionRecordingFilterInput | null,
  userId?: string | null,
};

export type OnCreateRecordingSubscription = {
  onCreateRecording?:  {
    __typename: "Recording",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemRecordingsId?: string | null,
  } | null,
};

export type OnUpdateRecordingSubscriptionVariables = {
  filter?: ModelSubscriptionRecordingFilterInput | null,
  userId?: string | null,
};

export type OnUpdateRecordingSubscription = {
  onUpdateRecording?:  {
    __typename: "Recording",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemRecordingsId?: string | null,
  } | null,
};

export type OnDeleteRecordingSubscriptionVariables = {
  filter?: ModelSubscriptionRecordingFilterInput | null,
  userId?: string | null,
};

export type OnDeleteRecordingSubscription = {
  onDeleteRecording?:  {
    __typename: "Recording",
    userId?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    path?: string | null,
    createdAt: string,
    tourItemId: string,
    id: string,
    updatedAt: string,
    tourItemRecordingsId?: string | null,
  } | null,
};

export type OnCreateProjectAmenitiesSubscriptionVariables = {
  filter?: ModelSubscriptionProjectAmenitiesFilterInput | null,
};

export type OnCreateProjectAmenitiesSubscription = {
  onCreateProjectAmenities?:  {
    __typename: "ProjectAmenities",
    id: string,
    projectID: string,
    amenityID: string,
    project:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    },
    amenity:  {
      __typename: "Amenity",
      name: string,
      description?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProjectAmenitiesSubscriptionVariables = {
  filter?: ModelSubscriptionProjectAmenitiesFilterInput | null,
};

export type OnUpdateProjectAmenitiesSubscription = {
  onUpdateProjectAmenities?:  {
    __typename: "ProjectAmenities",
    id: string,
    projectID: string,
    amenityID: string,
    project:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    },
    amenity:  {
      __typename: "Amenity",
      name: string,
      description?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProjectAmenitiesSubscriptionVariables = {
  filter?: ModelSubscriptionProjectAmenitiesFilterInput | null,
};

export type OnDeleteProjectAmenitiesSubscription = {
  onDeleteProjectAmenities?:  {
    __typename: "ProjectAmenities",
    id: string,
    projectID: string,
    amenityID: string,
    project:  {
      __typename: "Project",
      name: string,
      streetNumber: string,
      streetName: string,
      city: string,
      province: string,
      latitude?: number | null,
      longitude?: number | null,
      builderId: string,
      architectId?: string | null,
      interiorDesignerId?: string | null,
      occupancy?: string | null,
      units?: number | null,
      stories?: number | null,
      startPrice?: number | null,
      endPrice?: number | null,
      minBeds?: number | null,
      maxBeds?: number | null,
      minSqft?: number | null,
      maxSqft?: number | null,
      pricePerSqft?: number | null,
      maintenanceFees?: string | null,
      parkingPrice?: number | null,
      lockerPrice?: number | null,
      phase?: ProjectPhase | null,
      availability?: ProjectAvailability | null,
      types?: Array< PropertyType | null > | null,
      description?: string | null,
      incentives?: string | null,
      depositStructure?: string | null,
      logo?: string | null,
      media?: string | null,
      lastUpdatedOn?: string | null,
      isSoldOut?: boolean | null,
      commission?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      builderProjectsId?: string | null,
      architectProjectsId?: string | null,
      interiorDesignerProjectsId?: string | null,
    },
    amenity:  {
      __typename: "Amenity",
      name: string,
      description?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
