// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserRole = {
  "ADMIN": "admin",
  "AGENT": "agent",
  "CLIENT": "client"
};

const TourRole = {
  "PRIMARYAGENT": "primaryagent",
  "SHOWINGAGENT": "showingagent",
  "CLIENT": "client",
  "GUEST": "guest"
};

const NotificationSchedule = {
  "INSTANTLY": "instantly",
  "DAILY": "daily",
  "WEEKLY": "weekly",
  "MONTHLY": "monthly"
};

const NotificationType = {
  "EMAIL": "email",
  "PUSH": "push",
  "SMS": "sms"
};

const FeaturedStatus = {
  "ACTIVE": "active",
  "INACTIVE": "inactive"
};

const ShareStatus = {
  "SHARED": "shared",
  "UNSHARED": "unshared"
};

const TourStatus = {
  "PENDING": "pending",
  "READY": "ready",
  "CANCELLED": "cancelled",
  "ARCHIVED": "archived",
  "DRAFT": "draft",
  "COMPLETED": "completed"
};

const TourItemStatus = {
  "COMPLETED": "completed",
  "CONFIRMED": "confirmed",
  "REQUESTED": "requested",
  "CANCELLED": "cancelled",
  "REJECTED": "rejected",
  "SKIPPED": "skipped"
};

const PropertyType = {
  "CONDO": "condo",
  "TOWNHOUSE": "townhouse",
  "SINGLE": "single"
};

const ProjectAvailability = {
  "AVAILABLE": "available",
  "COMINGSOON": "comingsoon"
};

const ProjectPhase = {
  "PRECONSTRUCTION": "preconstruction",
  "UNDERCONSTRUCTION": "underconstruction",
  "COMPLETED": "completed"
};

const Measurement = {
  "IMPERIAL": "imperial",
  "METRIC": "metric"
};

const { Builder, Architect, InteriorDesigner, Project, ProjectUnit, Amenity, Featured, HomeCard, Profile, Favourite, PreFavourite, Search, Notification, User, Agent, Client, Brokerage, UserTour, Tour, TourItem, Review, Note, Photo, Recording, School, SchoolBoard, SchoolBoardScore, SchoolRating, ProjectAmenities, S3Object } = initSchema(schema);

export {
  Builder,
  Architect,
  InteriorDesigner,
  Project,
  ProjectUnit,
  Amenity,
  Featured,
  HomeCard,
  Profile,
  Favourite,
  PreFavourite,
  Search,
  Notification,
  User,
  Agent,
  Client,
  Brokerage,
  UserTour,
  Tour,
  TourItem,
  Review,
  Note,
  Photo,
  Recording,
  School,
  SchoolBoard,
  SchoolBoardScore,
  SchoolRating,
  ProjectAmenities,
  UserRole,
  TourRole,
  NotificationSchedule,
  NotificationType,
  FeaturedStatus,
  ShareStatus,
  TourStatus,
  TourItemStatus,
  PropertyType,
  ProjectAvailability,
  ProjectPhase,
  Measurement,
  S3Object
};