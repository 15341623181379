import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonContent,
  IonButton,
  IonIcon,
  getPlatforms,
  IonLoading,
} from "@ionic/react";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { cameraOutline, micOutline } from "ionicons/icons";
import styles from "./medias.module.scss";
import Photos from "./component/Photos/photos.component";
import Notes from "./component/Notes/notes.component";
import Recordings from "./component/Recordings/recordings.component";
import { updateToast } from "../../../redux/ui/ui.actions";
import withClient from "../../../HOC/withClient/with-client";
import CustomModalHeader from "../CustomModalHeader/custom-modal-header.component";
import { hapticsImpact } from "../../../utils/functions";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { VoiceRecorder } from "capacitor-voice-recorder";
import { TourItemMediaService } from "../../../services/tourItemMediaService";

const Medias = ({
  disabled,
  currentClient,
  tourItem,
  listing,
  type,
  setIsOpen,
  refresh,
  tour,
}) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [playing, setPlaying] = useState();
  const [isAudioRecording, setIsAudioRecording] = useState(false);

  useEffect(() => {
    const platforms = getPlatforms();
    if (platforms.includes("capacitor")) {
      VoiceRecorder.requestAudioRecordingPermission();
    }
  }, []);

  const handleTakePhoto = async () => {
    const takePicture = async () => {
      const image = await Camera.getPhoto({
        quality: 75,
        allowEditing: false,
        source: CameraSource.Camera,
        resultType: CameraResultType.Base64,
        height: 1280,
        width: 1280,
      });
      if (image) {
        try {
          setUploading(true);
          await TourItemMediaService.addPhoto(tourItem.id, image);
          dispatch(
            updateToast({
              open: true,
              message: "Photo uploaded successfully.",
              type: "success",
            })
          );
          refresh(tourItem.id);
        } catch (err) {
          dispatch(
            updateToast({
              open: true,
              message: "Something went wrong!",
              type: "error",
            })
          );
        } finally {
          setUploading(false);
        }
      }
    };
    await Camera.checkPermissions().then(async (res) => {
      if (res.camera === "granted") {
        await takePicture();
      }
      if (res.camera === "denied") {
        return;
      }
      if (res.camera === "prompt") {
        await Camera.requestPermissions()
          .then(async (res) => {
            if (res.camera === "granted") {
              await takePicture();
            }
          })
          .catch((err) => {
            return;
          });
      }
    });
  };

  const handleDeletePhoto = async (photo) => {
    await hapticsImpact();
    try {
      setUploading(true);
      await TourItemMediaService.deletePhoto(photo);
      refresh(tourItem.id);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteRecording = async (recording) => {
    await hapticsImpact();
    try {
      setUploading(true);
      await TourItemMediaService.deleteRecording(recording);
      refresh(tourItem.id);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    } finally {
      setUploading(false);
    }
  };

  const startRecording = () => {
    if (isAudioRecording) {
      return;
    }

    VoiceRecorder.startRecording().then(() => {
      setIsAudioRecording(true);
    });
  };

  const stopRecording = () => {
    if (!isAudioRecording) {
      return;
    }

    VoiceRecorder.stopRecording().then(async (result) => {
      setIsAudioRecording(false);
      if (result && result.value && result.value.recordDataBase64) {
        const recordData = result.value.recordDataBase64;
        const fileName = new Date().getTime() + ".wav";
        await Filesystem.writeFile({
          path: fileName,
          directory: Directory.Data,
          data: recordData,
        });
        const file = await Filesystem.readFile({
          path: fileName,
          directory: Directory.Data,
        });
        const data = file.data;

        try {
          setUploading(true);
          await TourItemMediaService.addRecording(tourItem.id, data);
          dispatch(
            updateToast({
              open: true,
              message: "Recording uploaded successfully.",
              type: "success",
            })
          );
          refresh(tourItem.id);
        } catch (err) {
          dispatch(
            updateToast({
              open: true,
              message: "Something went wrong!",
              type: "error",
            })
          );
        } finally {
          setUploading(false);
        }
      }
    });
  };

  const isAuthorized = () => {
    if (disabled) return false;
    if (!getPlatforms().includes("capacitor")) return false;
    const members = tour.users.items;
    const { id: clientId } = currentClient;
    const allowed = members.map((m) => m.userId);
    return !allowed.includes(clientId) ? false : true;
  };

  return (
    <>
      <CustomModalHeader
        title={
          type === "photos"
            ? "All photos"
            : type === "notes"
            ? "All notes"
            : "All recordings"
        }
        setShowModal={() => setIsOpen(false)}
        hasBackbtn={true}
        disable={true}
      />
      <IonLoading isOpen={uploading} />
      <IonContent
        className={`${styles.medias} ${
          type === "notes" && styles.greyBackground
        }`}
      >
        {type === "photos" && (
          <Photos
            disabled={disabled}
            tourItem={tourItem}
            photoItems={tourItem.photos.items}
            listing={listing}
            handleDelete={handleDeletePhoto}
            tour={tour}
          />
        )}

        {type === "notes" && (
          <Notes
            disabled={disabled}
            tourItem={tourItem}
            noteItems={tourItem.notes.items}
            listing={listing}
            refresh={() => refresh(tourItem.id)}
            tour={tour}
          />
        )}
        {type === "recordings" && (
          <Recordings
            disabled={disabled}
            tourItem={tourItem}
            recordingItems={tourItem.recordings.items}
            listing={listing}
            handleDelete={handleDeleteRecording}
            playing={playing}
            setPlaying={setPlaying}
            tour={tour}
          />
        )}
      </IonContent>

      {type === "recordings" && (
        <div
          className={`${styles.addRecording} ${
            playing ? styles.hideBtn : styles.showBtn
          }`}
        >
          <IonButton
            shape="round"
            expand="block"
            className={styles.recordingBtn}
            disabled={!isAuthorized()}
            onClick={isAudioRecording ? stopRecording : startRecording}
          >
            <IonIcon icon={micOutline} />
            {!isAudioRecording ? "Record voice" : "Stop recording"}
          </IonButton>
        </div>
      )}

      {type === "photos" && (
        <div className={styles.addPhoto}>
          <IonButton
            shape="round"
            expand="block"
            className={styles.photoBtn}
            onClick={handleTakePhoto}
            disabled={!isAuthorized()}
          >
            <IonIcon icon={cameraOutline} />
            New photo
          </IonButton>
        </div>
      )}
    </>
  );
};

export default withClient(Medias);
