export const UIActionTypes = {
  UPDATE_BADGE: "UPDATE_BADGE",
  UPDATE_SELECTED_TAB: "UPDATE_SELECTED_TAB",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  MARK_NOTIFICATION: "MARK_NOTIFICATION",
  RESET_NOTIFICATION: "RESET_NOTIFICATION",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  OPEN_AUTH_MODAL: "OPEN_AUTH_MODAL",
  CLOSE_AUTH_MODAL: "CLOSE_AUTH_MODAL",
  COLLAPSE_MENU: "COLLAPSE_MENU",
  UPDATE_MENU_TAB: "UPDATE_MENU_TAB",
  TOGGLE_FILTERS: "TOGGLE_FILTERS",
  UPDATE_DRAW_MODE: "UPDATE_DRAW_MODE",
  UPDATE_TOAST: "UPDATE_TOAST",
  UPDATE_CHANGED_FAVOURITE: "UPDATE_CHANGED_FAVOURITE",
  FETCH_OFFER_START: "FETCH_OFFER_START",
  FETCH_OFFER_SUCCESS: "FETCH_OFFER_SUCCESS",
  FETCH_OFFER_FAILURE: "FETCH_OFFER_FAILURE",
  FETCH_RATE_START: "FETCH_RATE_START",
  FETCH_RATE_SUCCESS: "FETCH_RATE_SUCCESS",
  FETCH_RATE_FAILURE: "FETCH_RATE_FAILURE",
  FEDERATED_LOADING_START: "FEDERATED_LOADING_START",
  FEDERATED_LOADING_END: "FEDERATED_LOADING_END",
};
