import moment from "moment";
import Button from "../../../../../../../../components/Form/Button/button.component";
import { rightSection } from "../../../../../../../../data/detailsPage";
import styles from "./sold-status.module.scss";

const SoldStatus = ({
  listDate,
  soldPrice,
  lastStatus,
  status,
  diffDate,
  renderPriceDiff,
  requestFormHandler,
  language,
}) => (
  <div className={styles.soldRightNavbar}>
    <div className={styles.soldInfo}>
      <div className={styles.soldStatus}>{status}</div>
      <div className={styles.soldDate}>
        {moment(listDate).format("D MMM YYYY")}
      </div>
    </div>
    <div className={styles.soldPrice}>{soldPrice}</div>
    {["Sld", "Lsd"].includes(lastStatus) && (
      <div className={styles.onMarket}>
        {diffDate} {diffDate > 1 ? "days" : "day"} on market
      </div>
    )}
    {["Sld", "Lsd"].includes(lastStatus) && (
      <div className={styles.priceDiff}>{renderPriceDiff()}</div>
    )}
    <Button
      title={rightSection[language].contact}
      type="green"
      border={true}
      style={{
        height: "5rem",
        width: "25.5rem",
        marginTop: "2rem",
      }}
      onClick={requestFormHandler}
    />
  </div>
);

export default SoldStatus;
