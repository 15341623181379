import { MapActionTypes } from "./map.types";

export const updateListingsLoading = (loading) => ({ type: MapActionTypes.UPDATE_LOADING, payload: loading });

export const updateListings = (listings) => ({ type: MapActionTypes.UPDATE_LISTINGS, payload: listings });

export const clearListings = () => ({ type: MapActionTypes.CLEAR_LISTINGS });

export const updateSelectedListings = (listings) => ({
  type: MapActionTypes.UPDATE_SELECTED_LISTINGS,
  payload: listings,
});

export const updateSelectedListing = (location) => ({
  type: MapActionTypes.UPDATE_SELECTED_LOCATION,
  payload: location,
});

export const updateMapRef = (ref) => ({
  type: MapActionTypes.UPDATE_MAP_REF,
  payload: ref,
});

export const updateLocations = (locations) => ({
  type: MapActionTypes.UPDATE_LOCATIONS,
  payload: locations,
});

export const toggleFavourites = (fromFavouritesPage) => ({
  type: MapActionTypes.TOGGLE_FAVOURITES,
  payload: fromFavouritesPage,
});

export const updateActiveListing = (mlsNumber) => ({
  type: MapActionTypes.UPDATE_ACTIVE_LISTING,
  payload: mlsNumber,
});

export const updateDetailsListing = (data) => ({
  type: MapActionTypes.UPDATE_DETAILS_LISTING,
  payload: data,
});

export const updateBoundaries = (boundary) => ({
  type: MapActionTypes.UPDATE_BOUNDARIES,
  payload: boundary,
});
