import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
} from "@ionic/react";
import { getMarketData } from "../../../../../../../../api/repliers";
import MedianSoldPriceWithAvgDOM from "./components/MedianSoldPriceWithAvgDOM/median-sold-price-with-avg-dom.component";
import MedianSoldPriceAndListPrice from "./components/MedianSoldPriceAndListPrice/median-sold-price-and-list-price.component";
import SoldAndActiveAndNewListings from "./components/SoldAndActiveAndNewListings/sold-and-active-and-new-listings.component";
import styles from "./market-data.module.scss";
import MarketSummary from "./components/MarketSummary/market-summary.component";

const MarketData = ({ listing, isApp = false }) => {
  const currentDate = moment()
    .subtract(1, "M")
    .format("MMM YYYY");
  const [segment, setSegment] = useState("5");
  const {
    type,
    details: { propertyType },
    address: { area, neighborhood },
  } = listing;

  const [summaryMarketData, setSummaryMarketData] = useState();
  const [soldWAvgDomMarketData, setSoldWAvgDomMarketData] = useState();
  const [soldListPriceMarketData, setSoldListPriceMarketData] = useState();
  const [soldActiveNewMarketData, setSoldActiveNewMarketData] = useState();

  useEffect(() => {
    const getMarketDataHandler = async () => {
      const response = await getMarketData(
        type,
        area,
        neighborhood,
        propertyType
      );
      const { statistics } = response;
      const {
        listPrice,
        soldPrice,
        daysOnMarket,
        new: newListings,
      } = statistics;
      const { mth: soldPriceMonthly } = soldPrice;
      const { mth: listPriceMonthly } = listPrice;
      const { mth: daysOnMarketMonthly } = daysOnMarket;
      const { mth: newListingsMonthly } = newListings;

      let summaryData = {};
      let soldWithDomData = [];
      let soldListData = [];
      let soldActiveNewData = [];

      const lastMonth = moment()
        .subtract(1, "M")
        .format("YYYY-MM");

      const lastYear = moment()
        .subtract(13, "M")
        .format("YYYY-MM");

      const lastFiveYear = moment()
        .subtract(61, "M")
        .format("YYYY-MM");

      const lastTenYear = moment()
        .subtract(121, "M")
        .format("YYYY-MM");

      const medSoldPriceLastMonth = soldPriceMonthly[lastMonth]["med"];
      const medSoldPriceLastYear = soldPriceMonthly[lastYear]["med"];
      const medSoldPriceLastFiveYear = soldPriceMonthly[lastFiveYear]["med"];
      const medSoldPriceLastTenYear = soldPriceMonthly[lastTenYear]["med"];
      const cntNewListings = newListingsMonthly[lastMonth]["count"];

      let oneYearValue;
      let fiveYearValue;
      let tenYearValue;

      const tempOneY = (medSoldPriceLastMonth / medSoldPriceLastYear).toFixed(
        2
      );
      if (tempOneY > 1) {
        oneYearValue = `+${((tempOneY - 1) * 100)
          .toFixed(2)
          .replace(/(\.0+|0+)$/, "")}%`;
      } else {
        oneYearValue = `-${((1 - tempOneY) * 100)
          .toFixed(2)
          .replace(/(\.0+|0+)$/, "")}%`;
      }

      const tempFiveY = (
        medSoldPriceLastMonth / medSoldPriceLastFiveYear
      ).toFixed(2);
      if (tempFiveY > 1) {
        fiveYearValue = `+${((tempFiveY - 1) * 100)
          .toFixed(2)
          .replace(/(\.0+|0+)$/, "")}%`;
      } else {
        fiveYearValue = `-${((1 - tempFiveY) * 100)
          .toFixed(2)
          .replace(/(\.0+|0+)$/, "")}%`;
      }

      const tempTenY = (
        medSoldPriceLastMonth / medSoldPriceLastTenYear
      ).toFixed(2);
      if (tempTenY > 1) {
        tenYearValue = `+${((tempTenY - 1) * 100)
          .toFixed(2)
          .replace(/(\.0+|0+)$/, "")}%`;
      } else {
        tenYearValue = `-${((1 - tempTenY) * 100)
          .toFixed(2)
          .replace(/(\.0+|0+)$/, "")}%`;
      }

      summaryData = {
        medSoldPriceLastMonth: medSoldPriceLastMonth,
        cntNewListings: cntNewListings,
        oneYearValue: oneYearValue,
        fiveYearValue: fiveYearValue,
        tenYearValue: tenYearValue,
      };
      setSummaryMarketData(summaryData);

      for (const item of Object.entries(soldPriceMonthly)) {
        let key = item[0];
        let averageDOM = daysOnMarketMonthly[key]
          ? daysOnMarketMonthly[key]["avg"]
          : "-";
        let tempObj = {
          date: key,
          "Average DOM": averageDOM,
          "Median Sold Price": item[1]["med"],
          "Average Sold Price": item[1]["avg"],
        };
        soldWithDomData.push(tempObj);
      }
      soldWithDomData = soldWithDomData.reverse();
      setSoldWAvgDomMarketData(soldWithDomData);

      for (const item of Object.entries(soldPriceMonthly)) {
        let key = item[0];
        let medListPrice = listPriceMonthly[key]
          ? listPriceMonthly[key]["med"]
          : "-";
        let tempObj = {
          date: key,
          "Median List Price": medListPrice,
          "Median Sold Price": item[1]["med"],
          "Sold Per List Price Ratio": item[1]["med"] / medListPrice,
        };
        soldListData.push(tempObj);
      }
      soldListData = soldListData.reverse();
      setSoldListPriceMarketData(soldListData);

      for (const item of Object.entries(soldPriceMonthly)) {
        let key = item[0];
        let newCnt = newListingsMonthly[key]
          ? newListingsMonthly[key]["count"]
          : "-";
        let tempObj = {
          date: key,
          "New listings": newCnt,
          "Sold listings": item[1]["count"],
        };

        soldActiveNewData.push(tempObj);
      }
      soldActiveNewData = soldActiveNewData.reverse();
      setSoldActiveNewMarketData(soldActiveNewData);
    };

    getMarketDataHandler();
  }, []);

  return (
    <div className={styles.marketData}>
      {summaryMarketData && (
        <div className={styles.summary}>
          <MarketSummary
            currentDate={currentDate}
            marketData={summaryMarketData}
          />
        </div>
      )}

      <div className={styles.charts}>
        <div className={`${styles.segments} ${styles.row}`}>
          <IonSegment
            onIonChange={(e) => setSegment(e.detail.value)}
            className={styles.segment}
            value={segment}
          >
            <IonSegmentButton value="5">
              <IonLabel>{"5 Years"}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="10">
              <IonLabel>{"10 Years"}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="15">
              <IonLabel>{"15 Years"}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </div>

        <div>
          {soldWAvgDomMarketData &&
          soldListPriceMarketData &&
          soldActiveNewMarketData ? (
            <div>
              <MedianSoldPriceWithAvgDOM
                segment={segment}
                area={area}
                neighborhood={neighborhood}
                propertyType={propertyType}
                marketData={soldWAvgDomMarketData}
                isApp={isApp}
              />

              <SoldAndActiveAndNewListings
                segment={segment}
                area={area}
                neighborhood={neighborhood}
                propertyType={propertyType}
                marketData={soldActiveNewMarketData}
                isApp={isApp}
              />

              <MedianSoldPriceAndListPrice
                segment={segment}
                area={area}
                neighborhood={neighborhood}
                propertyType={propertyType}
                marketData={soldListPriceMarketData}
                isApp={isApp}
              />

              <div className={styles.disclaimer}>
                *Source: This market data is based on historical data of the
                respective real estate boards. This data provided is solely for
                informational purposes.
              </div>
            </div>
          ) : (
            <IonSpinner name="dots" className={styles.spinner} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketData;
