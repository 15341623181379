import {configureStore} from '@reduxjs/toolkit'
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import rootReducer from "./root-reducer";

const sagaMiddleware = createSagaMiddleware();

const middlewares: any[] = [sagaMiddleware];

const logger = createLogger({ collapsed: true });

if (process.env.NODE_ENV === "development") {
    middlewares.push(logger)
}

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: middlewares
});
  
export const persistor = persistStore(store);

const Store = { store, persistor, sagaMiddleware };

export default Store;
