import { useState } from "react";
import { useDispatch } from "react-redux";
import ModalHeader from "../../../../../../app/components/Header/modal-header.component";
import { IonIcon, IonToggle, IonItem, IonInput, IonLabel } from "@ionic/react";
import withMap from "../../../../../../HOC/withMap/with-map";
import millify from "millify";
import styles from "./save-search-modal.module.scss";
import { notificationsOutline, schoolOutline } from "ionicons/icons";
import { ReactComponent as SearchIcon } from "../../../../../../assets/svg/search.svg";
import Button from "../../../../../Form/Button/button.component";
import {
  addSearch,
  updateClient,
  updateUserNotification,
} from "../../../../../../amplify/graphql.utils";
import { updateToast } from "../../../../../../redux/ui/ui.actions";
import { getClientSearchCount } from "../../../../../../api/repliers";

const SaveSearchModal = ({
  close,
  filters,
  extraFilters,
  mapLocations,
  client,
  clientError,
  drawMode,
  schoolMode = { active: false, schools: [] },
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [searchName, setSearchName] = useState(
    schoolMode.active && schoolMode.schools.length
      ? schoolMode.schools.length > 1
        ? "Listing around multiple schools"
        : `Listings around ${schoolMode.schools[0]?.name}`
      : ""
  );
  const notifications = client.notifications;

  const {
    propertyType,
    minPrice,
    maxPrice,
    minBeds,
    maxBeds,
    minBaths,
    maxBaths,
  } = filters;

  const handleSubmit = async () => {
    if (searchName.length > 0) {
      setLoading(true);
      try {
        await addSearch(client.id, client.repliersID, {
          searchName,
          filters,
          extraFilters,
          mapLocations,
          drawMode,
          schoolMode,
          alert,
        });
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: "Search saved successfully.",
          })
        );
        setTimeout(() => {
          close();
        }, 1500);
      } catch (err) {
        const count = await getClientSearchCount(client.repliersID);

        let isLimitExceeded = false;
        if (count === 5) {
          isLimitExceeded = true;
        }

        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: isLimitExceeded
              ? "A client can have a maximum of 5 searches."
              : "Your search is too big. Try setting more specific filters.",
          })
        );
      }
      setLoading(false);
    } else {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Search name is required.",
        })
      );
    }
  };

  const handleAlert = async () => {
    const { type, schedule } = notifications;
    if (!alert) {
      const email = type.includes("email");
      const phone = type.includes("phone");
      const push = type.includes("push");
      if (!email && !phone && !push) {
        await updateUserNotification({
          id: notifications.id,
          schedule: schedule ? schedule : "daily",
          types: ["email"],
        });
      }
    }
    setAlert(!alert);
  };

  return (
    <>
      <ModalHeader title="Save search" close={close} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.text}>
            Quickly re-load your search parameters later, or set up alerts and
            receive up to 100 relevant listings a day per saved search.
          </div>
          <div className={styles.form}>
            <div className={styles.search}>
              <div className={styles.name}>
                <span className={styles.title}>Name your search:</span>

                <IonInput
                  value={searchName}
                  onIonChange={(e) => setSearchName(e.detail.value)}
                  required
                  className={styles.input}
                />
                {(error || clientError) && (
                  <div className={styles.error}>
                    <span>{error ? error : clientError}</span>
                  </div>
                )}
              </div>
              <div className={styles.params}>
                <span className={styles.title}>Parameters saved:</span>
                <div className={styles.filters}>
                  <div className={styles.item}>
                    <span className={styles.label}>Property Type:</span>
                    <span className={styles.filter}>
                      {propertyType
                        ? propertyType.length > 1
                          ? "Several"
                          : propertyType
                        : "Any"}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span className={styles.label}>Price:</span>
                    <span className={styles.filter}>
                      {minPrice === "0" && maxPrice === "max"
                        ? "Any"
                        : `$${millify(+minPrice)}-${
                            maxPrice === "max"
                              ? "max"
                              : `$${millify(+maxPrice)}`
                          }`}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span className={styles.label}>Beds:</span>
                    <span className={styles.filter}>
                      {!minBeds && !maxBeds
                        ? "Any"
                        : minBeds && !maxBeds
                        ? `${minBeds}+`
                        : !minBeds && maxBeds && maxBeds !== "any"
                        ? `0-${maxBeds}`
                        : minBeds && maxBeds === "any"
                        ? `${minBeds}+`
                        : !minBeds && maxBeds === "any"
                        ? "Any"
                        : `${minBeds}-${maxBeds}`}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span className={styles.label}>Baths:</span>
                    <span className={styles.filter}>
                      {!minBaths && !maxBaths
                        ? "Any"
                        : minBaths && !maxBaths
                        ? `${minBaths}+`
                        : !minBaths && maxBaths && maxBaths !== "any"
                        ? `0-${maxBaths}`
                        : minBaths && maxBaths === "any"
                        ? `${minBaths}+`
                        : !minBaths && maxBaths === "any"
                        ? "Any"
                        : `${minBaths}-${maxBaths}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {schoolMode.active && schoolMode.schools.length > 0 && (
              <div className={styles.schools}>
                {schoolMode.schools.map((s, i) => (
                  <div className={styles.school} key={i}>
                    <span className={styles.iconContainer}>
                      <IonIcon icon={schoolOutline} className={styles.icon} />
                      <span className={styles.title}>School</span>
                    </span>

                    <span className={styles.label}>
                      <IonItem lines="none" className={styles.schoolName}>
                        <IonLabel>{s.name}</IonLabel>
                      </IonItem>
                    </span>
                  </div>
                ))}
              </div>
            )}

            <div className={styles.alerts}>
              <span className={styles.iconContainer}>
                <IonIcon icon={notificationsOutline} className={styles.icon} />
                <span className={styles.title}>Alerts</span>
              </span>
              <IonItem className={styles.toggleContainer} lines="none">
                <IonToggle
                  className={styles.toggle}
                  checked={alert}
                  onIonChange={handleAlert}
                />
              </IonItem>
            </div>
          </div>
          <Button
            type="green"
            border
            title="Save search"
            style={{ width: "100%", height: "5rem", marginTop: "2rem" }}
            onClick={handleSubmit}
            loading={loading}
          >
            <SearchIcon />
          </Button>
          <div
            className={styles.text}
            style={{ marginTop: "1.5rem", textAlign: "center" }}
          >
            Manage alert style from <u>account settings</u>, <u>saved search</u>{" "}
            or <u>favourites</u> pages.
          </div>
        </div>
      </div>
    </>
  );
};

export default withMap(SaveSearchModal);
