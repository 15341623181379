import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IonSpinner, IonIcon } from "@ionic/react";
import styles from "./favorite-icon.module.scss";
import {
  openModal,
  updateChangedFavourite,
  updateToast,
} from "../../../../redux/ui/ui.actions";
import favIcon from "../../../../assets/svg/favourite.svg";
import notIcon from "../../../../assets/svg/notifications.svg";
import { selectCurrentUser } from "../../../../redux/user/user.selectors";
import { hapticsImpact } from "../../../../utils/functions";
import { useHistory } from "react-router-dom";
import { selectUpdatedFavourite } from "../../../../redux/ui/ui.selectors";
import {
  FavoritesService
} from "../../../../services/favoritesService";

const FavoriteIcon = ({
  listing,
  map,
  isApp = false,
  iconStyle,
  favIconStyle,
  notifIconStyle,
  isActiveComparables,
  reload,
  isPreconstruction,
  ...rest
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const updatedFavourite = useSelector(selectUpdatedFavourite);
  const isAuthenticated = useSelector(selectCurrentUser);
  const [favourite, setFavourite] = useState();
  const [loading, setLoading] = useState(false);


  const id = isPreconstruction ? listing?.id : listing?.mlsNumber;

  useEffect(() => {
    const fetchFavorite = async () => {
      try {
        const fav = await FavoritesService.getFavoriteByMlsNumber(id);
        setFavourite(fav);
      } catch (err) {
        console.log(err);
      }
    };

    if (isAuthenticated) {
      fetchFavorite();
    }
  }, [id, isAuthenticated]);

  useEffect(() => {
    const fetchFavourite = async () => {
      try {
        const fav = await FavoritesService.getFavoriteByMlsNumber(id);
        setFavourite(fav);
        dispatch(updateChangedFavourite(undefined));
      } catch (err) {
        console.log(err);
      }
    };

    if (isAuthenticated && updatedFavourite === id) {
      fetchFavourite();
    }
  }, [id, updatedFavourite, isAuthenticated, dispatch]);

  const unauthorizedClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isApp) {
      history.push("/join");
    } else {
      dispatch(openModal({ current: "join" }));
    }
  };

  const handleFavClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await hapticsImpact();
    if (favourite) {
      removeFromFavorites();
    } else {
      addToFavorites();
    }
  };

  const addToFavorites = async () => {
    try {
      setLoading(true);
      const favorite = await FavoritesService.createFavorite(listing);
      setFavourite(favorite);
    } catch (err) {
      console.log(err);
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const removeFromFavorites = async () => {
    try {
      setLoading(true);
      await FavoritesService.removeFavorite(favourite);
      setFavourite();
    } catch (err) {
      console.log(err);
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleNotClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await hapticsImpact();
    if (!favourite) {
      dispatch(
        updateToast({
          open: true,
          message: "Favourite a listing before activating its alert.",
          type: "info",
        })
      );
      return;
    }

    try {
      setLoading(true);
      const fav = FavoritesService.toggleFavoriteNotification(favourite);
      setFavourite(fav);
    } catch (err) {
      console.log(err);
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return isAuthenticated ? (
    loading ? (
      <div className={styles.spinnerContainer}>
        {loading !== "initial" && (
          <IonSpinner
            name="dots"
            className={styles.spinner}
            style={iconStyle}
          />
        )}
      </div>
    ) : (
      <div ref={ref} className={styles.container}>
        <span
          className={`${styles.favorite} ${map && styles.map}`}
          onClick={handleFavClick}>
          <IonIcon
            src={favIcon}
            className={`${styles.favIcon} ${
              favourite && styles.invertedFavIcon
            }`}
            style={favIconStyle}
          />
        </span>
        {!isPreconstruction &&
          (listing.status === "A" || isActiveComparables) && (
            <span
              className={`${styles.notification} ${map && styles.map}`}
              onClick={handleNotClick}>
              <IonIcon
                src={notIcon}
                className={`${styles.notIcon} ${
                  favourite && favourite.notification && styles.invertedNotIcon
                } ${!favourite && styles.disabled}`}
                style={notifIconStyle}
              />
            </span>
          )}
      </div>
    )
  ) : (
    <div ref={ref} className={styles.container}>
      <span
        className={`${styles.favorite} ${map && styles.map}`}
        onClick={unauthorizedClickHandler}>
        <IonIcon
          src={favIcon}
          className={`${styles.favIcon} ${isApp && styles.app}`}
        />
      </span>
      {!isPreconstruction && (
        <span
          className={`${styles.notification} ${map && styles.map}`}
          onClick={unauthorizedClickHandler}>
          <IonIcon
            src={notIcon}
            className={`${styles.notIcon} ${isApp && styles.app}`}
          />
        </span>
      )}
    </div>
  );
};

export default FavoriteIcon;
