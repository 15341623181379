import { IonItem, IonLabel, IonIcon, IonInput } from "@ionic/react";
import { addOutline, removeOutline } from "ionicons/icons";

const PreMultiple = ({ label, styles, items, onAdd, onChange, onRemove }) => {
  return (
    <>
      <IonItem lines="none">
        <IonLabel position="stack">{label}</IonLabel>
        <IonIcon icon={addOutline} className={styles.addIcon} onClick={onAdd} />
      </IonItem>
      <div>
        {items.map((item, i) => (
          <IonItem key={item.id}>
            <IonLabel>{i + 1}) </IonLabel>
            <IonInput
              value={item.value}
              clearInput
              type="text"
              onIonChange={(e) => onChange({ index: i, value: e.detail.value })}
            />
            <IonIcon
              icon={removeOutline}
              className={styles.addIcon}
              onClick={() => onRemove(item.id)}
            />
          </IonItem>
        ))}
      </div>
    </>
  );
};

export default PreMultiple;
