import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { capitalize } from "lodash";
import numeral from "numeral";
import millify from "millify";
import { IonIcon } from "@ionic/react";
import {
  arrowDownOutline,
  arrowUpOutline,
  checkmarkOutline,
  shareSocialOutline,
} from "ionicons/icons";
import { openModal } from "../../../../redux/ui/ui.actions";
import { selectCurrentClient } from "../../../../redux/client/client.selectors";
import { selectCurrencyRates } from "../../../../redux/currency/currency.selectors";
import Button from "../../../../components/Form/Button/button.component";
import {
  diffDate,
  getPricesPercentageDiff,
  getTagTitle,
  renderEstimate,
} from "../../../../utils/functions";
import styles from "./tablet-right-section.module.scss";

const TabletRightSection = ({ listing, language, estimate, isFavourite }) => {
  const dispatch = useDispatch();
  const data =
    listing.details.numBedrooms +
    " bed " +
    listing.details.propertyType +
    " at " +
    listing.address.unitNumber +
    " - " +
    listing.address.streetNumber +
    " " +
    listing.address.streetName +
    " " +
    listing.address.streetSuffix +
    ", " +
    listing.address.area +
    " for " +
    numeral(listing.listPrice).format("$1,000,000");

  const shareListingHandler = () => {
    dispatch(
      openModal({
        current: "shareListing",
        data: {
          mlsNumber: listing.mlsNumber,
          text: data,
          listing: listing,
          isFavourite,
        },
      })
    );
  };
  const requestFormHandler = () => {
    dispatch(
      openModal({
        current: "requestForm",
        overflow: true,
        data: {
          address: listing.address,
          mlsNumber: listing.mlsNumber,
          status: listing.type,
          active: listing.status === "A" ? true : false,
        },
      })
    );
  };

  const client = useSelector(selectCurrentClient);
  const rates = useSelector(selectCurrencyRates);
  const now = new Date();
  const { listDate, soldDate, type, status, lastStatus, listPrice, soldPrice } =
    listing;

  let currency;
  if (client && rates) {
    if (client.currency !== "cad") currency = client.currency.toUpperCase();
  }

  // const diffDates = () => {
  //   const date = status === "A" ? new Date(listDate) : new Date(soldDate);
  //   const diff = Math.floor(
  //     (Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()) -
  //       Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) /
  //       (1000 * 60 * 60 * 24)
  //   );
  //   let onMarket;
  //   if (status === "U") {
  //     onMarket = moment(new Date(soldDate)).diff(new Date(listDate), "days");
  //   }
  //   return { onMarket };
  // };

  const renderPriceDiff = () => {
    let diff = soldPrice - listPrice;
    if (currency) diff = (+diff / rates.CAD) * rates[currency];

    if (diff > 0) {
      return (
        <span className={styles.increased}>
          <IonIcon icon={arrowUpOutline} />
          <span className={styles.value}>
            {millify(diff, { precision: 2 })}
          </span>
          <span className={styles.text}>above asking</span>
        </span>
      );
    }

    if (diff < 0) {
      return (
        <span className={styles.decreased}>
          <IonIcon icon={arrowDownOutline} />
          <span className={styles.value}>
            {millify(Math.abs(diff), { precision: 2 })}
          </span>
          <span className={styles.text}>below asking</span>
        </span>
      );
    }

    return (
      <span className={styles.asking}>
        <IonIcon icon={checkmarkOutline} />
        <span className={styles.text}>
          {type.toLowerCase() === "sale" ? "sold" : "leased"} at asking
        </span>
      </span>
    );
  };

  const getTagTitle = () => {
    switch (lastStatus) {
      case "New":
        return "For " + type.toLowerCase();
      case "Sus":
        return "suspended";
      case "Ter":
        return "terminated";
      case "Dft":
        return "deal fell through";
      case "Pc":
        return type.toLowerCase();
      case "Ext":
        return "extended";
      case "Exp":
        return "expired";
      case "Sc":
        return "sold conditional";
      case "Lc":
        return "leased conditional";
      case "Sld":
        return "sold";
      case "Lsd":
        return "leased";
      default:
        return type.toLowerCase();
    }
  };

  const renderEstimateWithDiff = () => {
    const estimateValue = renderEstimate(currency, estimate, rates);
    const price = listing.soldPrice > 0 ? listing.soldPrice : listing.listPrice;

    const difference = getPricesPercentageDiff(price, estimate);

    return (
      <div>
        <span className={styles.estimateValue}>{estimateValue}</span>
        <span className={styles.estimateDiff}>
          {difference > 0 ? (
            <span className={styles.increased}>
              <IonIcon icon={arrowUpOutline} />
              <span className={styles.diffText}>{difference}%</span>
            </span>
          ) : (
            <span className={styles.decreased}>
              <IonIcon icon={arrowDownOutline} />
              <span className={styles.diffText}>{difference}%</span>
            </span>
          )}
        </span>
      </div>
    );
  };

  return (
    <div className={styles.rightSection}>
      <div className={styles.shareBtn}>
        <Button
          title={"Share"}
          onClick={shareListingHandler}
          type="grayLight"
          style={{
            height: "5rem",
            width: "11.8rem",
            borderRadius: "25px",
            backgroundColor: "#F2F2F2",
            color: "#363636",
          }}>
          <IonIcon
            icon={shareSocialOutline}
            style={{
              height: "2.4rem",
              widht: "2.4rem",
              color: "#363636",
            }}
          />
        </Button>
      </div>
      {
        // lastStatus !== "Sld" && lastStatus !== "Lsd" ?
        status === "A" ? (
          <>
            <div>
              <span className={styles.status}>{getTagTitle()}</span>
              <span className={styles.daysNum}>
                {
                  diffDate(status, listDate, soldDate).label
                  // moment(listDate).fromNow()
                }
              </span>
            </div>
            <div className={styles.price}>
              <div
                className={styles.listPrice}
                style={{ fontSize: estimate ? "3rem" : "4rem" }}>
                {numeral(listPrice).format("$1,000,000")}
              </div>
              {estimate && (
                <div className={styles.estimate}>
                  Estimated value: {estimate ? renderEstimateWithDiff() : "N/A"}
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className={styles.soldFirstCol}>
              <div>
                <span className={styles.soldStatus}>{getTagTitle()}</span>
                <span className={styles.soldDate}>
                  {moment(moment.utc(soldDate)).format("D MMM YYYY")}
                </span>
              </div>
              {["Sld", "Lsd"].includes(lastStatus) && (
                <div className={styles.onMarket}>
                  <span>
                    {capitalize(
                      diffDate(
                        status,
                        moment.utc(listDate),
                        moment.utc(soldDate)
                      ).onMarket
                    )}
                  </span>
                </div>
              )}
            </div>

            <div>
              <div className={styles.price}>
                {numeral(soldPrice).format("$1,000,000")}
              </div>
              {["Sld", "Lsd"].includes(lastStatus) && (
                <div className={styles.priceDiff}>{renderPriceDiff()}</div>
              )}
            </div>
          </>
        )
      }
      <div className={styles.contact}>
        <Button
          title="Contact or request viewing"
          type="green"
          border={true}
          style={{
            height: "5rem",
            width: "25.5rem",
          }}
          onClick={requestFormHandler}
        />
      </div>
    </div>
  );
};

export default TabletRightSection;
