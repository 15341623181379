import Toggle from "../../../../../../../../components/Form/Toggle/toggle-component";
import { rooms } from "../../../../../../../../data/detailsPage";
import styles from "./rooms.module.scss";

const Rooms = ({ roomDetails, isFeet, onToggle, language }) => (
  <div className={styles.rooms}>
    <div className={styles.infos}>
      <div className={styles.roomsHeader}>
        <span>{rooms[language].name}</span>
        <span className={styles.size}>
          <span className={styles.txt}>{rooms[language].size}</span>
          <Toggle
            color="gray"
            active={isFeet}
            isSize={true}
            onToggle={onToggle}
            style={{ width: "8rem" }}
            labelStyle={{ fontSize: "1.2rem" }}
          />
        </span>
        <span>{rooms[language].feature}</span>
      </div>
      <div>{roomDetails()}</div>
    </div>
  </div>
);

export default Rooms;
