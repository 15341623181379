import { useCallback, useEffect, useState } from "react";
import {  IonIcon, IonModal, IonRouterLink } from "@ionic/react";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import numeral from "numeral";
import _, { capitalize } from "lodash";
import moment from "moment";
import millify from "millify";
import { useSelector } from "react-redux";
// import {
//   openModal,
//   closeModal,
//   openAuthModal,
// } from "../../../../../../../redux/ui/ui.actions";
import VirtualTourIcon from "../../../../../../../assets/svg/REA103_Icons-01a_VirtualTour.svg";
import MapViewIcon from "../../../../../../../assets/svg/map-view.svg";
import BathIcon from "../../../../../../../assets/svg/REA103_Icons-01a_Bath.svg";
import ParkingIcon from "../../../../../../../assets/svg/REA103_Icons-01a_Parking.svg";
import SqftIcon from "../../../../../../../assets/svg/REA103_Icons-01a_SquareFeet.svg";
import styles from "./listing-content.module.scss";
import {
  buildAddress,
  diffDate,
  getTagTitle,
  buildPropertyInfo,
  buildParkingInfo,
  handleAppNavigation,
  calculateCashback,
  getLastStatusLabel,
  isActiveTag,
  getBoundingCoords,
} from "../../../../../../../utils/functions";
import bedOutline from "../../../../../../../assets/svg/REA103_Icons-01a_IonicBed.svg";
import NavigateIcon from "../../../../../../../assets/svg/navigate-outline.svg";
import DetailPageDropdown from "../../../../../../../pages/Detail/Sections/LeftSection/components/DetailPageDropdown/detail-page-dropdown.component";
import Essentials from "../../../../../../../pages/Detail/Sections/LeftSection/components/DetailPageInformation/components/Essentials/essentials.component";
import Overview from "../../../../../../../pages/Detail/Sections/LeftSection/components/DetailPageInformation/components/Overview/overview.component";
import Rooms from "../../../../../../../pages/Detail/Sections/LeftSection/components/DetailPageInformation/components/Rooms/rooms.component";
import PriceHistory from "../../../../../../../pages/Detail/Sections/LeftSection/components/DetailPageInformation/components/PriceHistory/price-history.component";
import Calculators from "../../../../../../../pages/Detail/Sections/LeftSection/components/DetailPageInformation/components/Calculators/calculators.component";
import { selectCurrencyRates } from "../../../../../../../redux/currency/currency.selectors";
import ComparableListings from "../ComparableListings/comparable-listings.component";
import MapViewModal from "../../../../../../components/CustomModals/MapView/mapview.component";
import Button from "../../../../../../../components/Form/Button/button.component";
import RequestForm from "../../../../../../components/CustomModals/RequestForm/request-form-component";
import {
  updateDetailsListing,
  updateSelectedListing,
} from "../../../../../../../redux/map/map.actions";
import { selectCurrentUser } from "../../../../../../../redux/user/user.selectors";
import PropertyIcon from "../../../../../../../assets/svg/REA103_Icons-01a_PropertyType.svg";
import LocationIcon from "../../../../../../../assets/svg/location-pin.svg";
import Schools from "../../../../../../../pages/Detail/Sections/LeftSection/components/DetailPageInformation/components/Schools/schools.component";
import { useHistory } from "react-router-dom";
import CollapsableText from "../../../../../../../components/CollapsableText/collapsable-text.component";
import {
  selectEttieOffer,
  selectSelectedTab,
} from "../../../../../../../redux/ui/ui.selectors";
import MarketData from "../../../../../../../pages/Detail/Sections/LeftSection/components/DetailPageInformation/components/MarketData/market-data.component";
import { getSimilarListing } from "../../../../../../../api/repliers";
import { SchoolsService } from "services/schoolsService";

const ListingContent = ({ listing, client, containerRef }) => {
  const history = useHistory();
  const [tabs, setTabs] = useState({
    essentials: true,
    overview: false,
    rooms: false,
    priceHistory: false,
    schoolsInfo: false,
    marketData: false,
    calculators: false,
  });
  const [offerModal, setOfferModal] = useState(false);
  const [cashbackModal, setCashbackModal] = useState(false);
  const [mapView, setMapView] = useState(false);
  const [similarListings, setSimilarListings] = useState({});
  const [schools, setSchools] = useState({});
  const user = useSelector(selectCurrentUser);
  const selectedTab = useSelector(selectSelectedTab);
  const offer = useSelector(selectEttieOffer);

  const fetchSimilarListings = useCallback(async (mlsNumber) => {
    try {
      const response = await getSimilarListing(mlsNumber);
      return response;
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchSchools = useCallback(async (latitude, longitude) => {
    const { topLeft, topRight, bottomRight, bottomLeft } = getBoundingCoords(
      parseFloat(latitude),
      parseFloat(longitude),
      8000
    );

    const schools = await SchoolsService.findNearbySchools({
      boundary: [
        [topLeft.lng, topLeft.lat],
        [topRight.lng, topRight.lat],
        [bottomRight.lng, bottomRight.lat],
        [bottomLeft.lng, bottomLeft.lat],
      ],
    });

    return schools;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const similarListings = await fetchSimilarListings(listing.mlsNumber);
      setSimilarListings(similarListings);
      const schools = await fetchSchools(
        listing.map.latitude,
        listing.map.longitude
      );
      setSchools(schools);
    };

    fetchData();
  }, [fetchSchools, fetchSimilarListings, listing]);

  const {
    status,
    lastStatus,
    type,
    listDate,
    soldDate,
    updatedOn,
    address,
    class: listingClass,
    details: {
      virtualTourUrl,
      propertyType,
      style,
      description,
      extras,
      numBedrooms,
      numBedroomsPlus,
      numBathrooms,
      numBathroomsPlus,
      numParkingSpaces,
      numGarageSpaces,
      sqft,
    },
    comparables,
  } = listing;

  const virtualTourHandler = () => {
    InAppBrowser.create(virtualTourUrl, "_blank", {
      hideurlbar: true,
      hidenavigationbutton: true,
      toolbar: "yes",
      toolbarcolor: "#dff7f0",
      closebuttoncolor: "#006653",
      closebuttoncaption: "Back",
      toolbarposition: "top",
    });
  };

  const rates = useSelector(selectCurrencyRates);

  let currency;
  if (client && rates) {
    if (client.currency !== "cad") currency = client.currency.toUpperCase();
  }

  const toggleTabs = (tabName) => {
    setTabs({ ...tabs, [tabName]: !tabs[tabName] });
  };

  const {
    essentials,
    overview,
    rooms,
    priceHistory,
    schoolsInfo,
    marketData,
    calculators,
  } = tabs;

  const [feet, setFeet] = useState(
    client && client.measurement === "metric" ? false : true
  );

  const onToggle = () => {
    setFeet(!feet);
  };

  const roomDetails = () => {
    return Object.keys(listing.rooms).map((key) => {
      if (listing.rooms[key].description !== "") {
        return (
          <div className={styles.roomdetails} key={key}>
            <span className={styles.bold}>
              {listing.rooms[key].description}
            </span>
            <span>
              {listing.rooms[key].length && listing.rooms[key].width
                ? `${
                    feet
                      ? `${listing.rooms[key].length} ft`
                      : `${numeral(+listing.rooms[key].length * 0.3048).format(
                          "99.99"
                        )} m`
                  } x ${
                    feet
                      ? `${listing.rooms[key].width} ft`
                      : `${numeral(+listing.rooms[key].width * 0.3048).format(
                          "99.99"
                        )} m`
                  }`
                : "Measurement not provided"}
            </span>
            <span>{`${
              listing.rooms[key].features && listing.rooms[key].features
            }${
              listing.rooms[key].features2 &&
              ", " + listing.rooms[key].features2
            }${
              listing.rooms[key].features3 &&
              ", " + listing.rooms[key].features3
            } 
           `}</span>
          </div>
        );
      }
      return null;
    });
  };

  const historyDetails = () => {
    return listing.history && listing.history.length > 0 ? (
      _.sortBy(listing.history, "listDate").map((item) => {
        return (
          <div className={styles.priceDetails} key={item.mlsNumber}>
            <div>
              <span className={styles.bold}>Original price: </span>
              {item.listPrice
                ? currency
                  ? `${currency} ${millify(
                      (+item.listPrice / rates.CAD) * rates[currency]
                    )}`
                  : numeral(item.listPrice).format("$1,000,000")
                : "-"}
            </div>

            <div>
              <span className={styles.bold}>Listing date: </span>
              {moment(item.listDate).format("ll")}
            </div>

            {["Sld", "Lsd"].includes(item.lastStatus) ? (
              <div>
                <span className={styles.bold}>
                  {item.lastStatus === "Sld" ? "Sold" : "Leased"} date:{" "}
                </span>
                {item.soldDate
                  ? moment(item.soldDate).format("ll") !== "Invalid date"
                    ? moment(item.soldDate).format("ll")
                    : "-"
                  : "-"}
              </div>
            ) : item.soldDate ? (
              <div>
                <span className={styles.bold}>Sold/Leased date: </span>
                {(item.soldDate === null ||
                  item.soldDate === "0000-00-00 00:00:00") &&
                  " -"}
              </div>
            ) : null}

            {["Sld", "Lsd"].includes(item.lastStatus) ? (
              <div>
                <span className={styles.bold}>
                  {item.lastStatus === "Sld" ? "Sold" : "Leased"} price:{" "}
                </span>
                {item.soldPrice
                  ? currency
                    ? `${currency} ${millify(
                        (+item.soldPrice / rates.CAD) * rates[currency]
                      )}`
                    : numeral(item.soldPrice).format("$1,000,000")
                  : "-"}
              </div>
            ) : item.soldPrice ? (
              <div>
                <span className={styles.bold}>Sold/Leased price: </span>
                {parseFloat(item.soldPrice) === 0.0 && " -"}
              </div>
            ) : null}

            <div>
              <span className={styles.bold}>Status: </span>
              {item.lastStatus}
            </div>

            <div>
              <span className={styles.bold}>MLS#: </span>
              {item.mlsNumber !== listing.mlsNumber && (
                <IonRouterLink
                  style={{ color: "#1F7A60" }}
                  routerLink={`/listings/${item.mlsNumber}`}>
                  {item.mlsNumber}
                </IonRouterLink>
              )}
            </div>
          </div>
        );
      })
    ) : (
      <div className={styles.noData}>No listing history available.</div>
    );
  };

  const signInHandler = () => history.push("/signin", { direction: "forward" });

  const signUpHandler = () => history.push("/signup", { direction: "forward" });

  // const cashbackFormHandler = () => {
  //   dispatch(
  //     openModal({
  //       current: "requestForm",
  //       overflow: true,
  //       data: {
  //         cashback: true,
  //         isSale: listing.type.toLowerCase() === "sale",
  //       },
  //     })
  //   );
  // };

  const getCashbackTitle = () => {
    if (listingClass === "CommercialProperty")
      return "Contact us for AECORN cashback offers!";
    if (listing.type.toLowerCase() === "sale") {
      return `$${calculateCashback(+listing.listPrice, "sale")} cashback!`;
    } else {
      return `$${calculateCashback(+listing.listPrice, "lease")} cashback!`;
    }
  };

  const getCashbackAmount = () => {
    if (listing.type.toLowerCase() === "sale") {
      return calculateCashback(+listing.listPrice, "sale");
    } else {
      return calculateCashback(+listing.listPrice, "lease");
    }
  };

  return (
    <>
      <div className={styles.container} tabIndex="-1">
        <IonModal
          isOpen={mapView}
          swipeToClose={false}
          presentingElement={containerRef.current}
          onDidDismiss={() => setMapView(false)}>
          <MapViewModal
            location={listing.map}
            close={() => setMapView(false)}
          />
        </IonModal>

        <RequestForm
          isOffer
          showModal={offerModal}
          setShowModal={setOfferModal}
          listing={listing}
          parentRef={containerRef}
        />

        <RequestForm
          isCashback
          isSale={listing.type.toLowerCase() === "sale"}
          showModal={cashbackModal}
          setShowModal={setCashbackModal}
          listing={listing}
          cashbackAmount={getCashbackAmount()}
          parentRef={containerRef}
        />

        <div className={styles.topBtns}>
          <div
            className={`${styles.btn} ${!virtualTourUrl && styles.disabled}`}
            onClick={virtualTourHandler}>
            <IonIcon
              src={VirtualTourIcon}
              className={!virtualTourUrl ? styles.disabledIcon : styles.icon}
            />
            <span
              className={!virtualTourUrl ? styles.disabledLabel : styles.label}>
              Virtual tour
            </span>
          </div>

          {/* <IonRouterLink
            className={styles.btn}
            routerLink={
              selectedTab === "listings"
                ? "/listings"
                : `/${selectedTab}/listings`
            }
            onClick={() => {
              dispatch(updateSelectedListing(null));
              dispatch(updateDetailsListing({ listing, view: true }));
            }}>
            <IonIcon src={MapViewIcon} className={styles.icon} />
            <span className={styles.label}>View on map</span>
          </IonRouterLink> */}
          <div
            className={styles.btn}
            onClick={() =>
              handleAppNavigation({
                map: listing.map,
                address: listing.address,
                byAddress: true,
              })
            }>
            <IonIcon src={NavigateIcon} className={styles.icon} />
            <span className={styles.label}>Navigate</span>
          </div>
        </div>
        {/* {listing.status === "A" && offer && (
          <div className={styles.offer}>
            <IonButton
              className={styles.cashbackBtn}
              onClick={() => setCashbackModal(true)}
            >
              {listingClass !== "CommercialProperty" && "Get up to"}
              <span>{getCashbackTitle()}</span>
            </IonButton>
          </div>
        )} */}
        <div className={styles.description}>
          <div className={styles.title}>Description</div>
          <div className={`${styles.tag} ${styles.row}`}>
            <div className={styles[isActiveTag(lastStatus)]}>
              {["sale", "lease"].includes(
                getTagTitle(lastStatus, type).toLowerCase()
              )
                ? `for ${getTagTitle(lastStatus, type)}`
                : getTagTitle(lastStatus, type)}
            </div>
          </div>

          <div className={`${styles.dates} ${styles.row}`}>
            <div className={styles.lastChange}>
              {status === "A" ? "Listed" : getLastStatusLabel(lastStatus)}
              {` ${moment
                .utc(
                  status === "U" ? (soldDate ? soldDate : updatedOn) : listDate
                )
                .format("MMM Do YYYY")}`}
            </div>
            {status === "A" ? (
              <span className={styles.date}>
                {capitalize(diffDate(status, listDate, soldDate).label)}
              </span>
            ) : (
              diffDate(status, listDate, soldDate).onMarket && (
                <span className={styles.date}>
                  <span>
                    Listed {moment.utc(listDate).format("MMM Do YYYY")}
                  </span>
                </span>
              )
            )}
          </div>
          {status !== "A" && (
            <div className={`${styles.dates} ${styles.row}`}>
              <span className={styles.onMarket}>
                {capitalize(
                  diffDate(
                    status,
                    moment.utc(listDate),
                    moment.utc(soldDate ? soldDate : updatedOn)
                  ).onMarket
                )}
              </span>
            </div>
          )}
          <div className={`${styles.info} ${styles.row}`}>
            <IonIcon src={LocationIcon} className={styles.icon} />
            <span className={styles.label}>{buildAddress(address)}</span>
          </div>
          <div className={`${styles.info} ${styles.row}`}>
            <IonIcon src={PropertyIcon} className={styles.icon} />
            <span className={styles.label}>
              {buildPropertyInfo(listingClass, propertyType, style)}
            </span>
          </div>
          <div className={`${styles.detail} ${styles.row}`}>
            <div>
              <IonIcon icon={bedOutline} className={styles.icon} />
              <span>
                {`${numBedrooms ? numBedrooms : "-"} ${
                  numBedroomsPlus !== "" && numBedroomsPlus !== null
                    ? " + " + numBedroomsPlus
                    : ""
                }`}{" "}
                bed
              </span>
            </div>
            <div>
              <IonIcon src={BathIcon} className={styles.icon} />
              <span>
                {`${numBathrooms ? numBathrooms : "-"} ${
                  numBathroomsPlus !== null ? " + " + numBathroomsPlus : ""
                }`}{" "}
                bath
              </span>
            </div>
          </div>
          <div className={`${styles.detail} ${styles.row}`}>
            <div>
              <IonIcon src={ParkingIcon} className={styles.icon} />
              <span>{buildParkingInfo(numGarageSpaces, numParkingSpaces)}</span>
            </div>
            {sqft && sqft.length > 0 && (
              <div>
                <IonIcon src={SqftIcon} className={styles.icon} />
                <span>{sqft} sqft</span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.listingDiscription}>
            <CollapsableText text={`${description}\n${extras}`} lines="5" />
          </div>
        </div>
        {listing.status === "A" && (
          <div className={styles.offer}>
            <Button
              title="Make an offer"
              type="blue"
              style={{ height: "5rem" }}
              border
              onClick={() => setOfferModal(true)}
            />
          </div>
        )}

        <div className={`${styles.description} ${styles.dropdowns}`}>
          <div className={styles.title}>Property information</div>

          <DetailPageDropdown
            title="Essentials"
            open={essentials}
            onClick={() => toggleTabs("essentials")}
          />

          {essentials && <Essentials listing={listing} language="en" />}
          <DetailPageDropdown
            title="Details"
            open={overview}
            onClick={() => toggleTabs("overview")}
          />
          {overview && <Overview listing={listing} language="en" />}
          <DetailPageDropdown
            title="Room details"
            open={rooms}
            hasToggle={true}
            isFeet={feet}
            onToggle={onToggle}
            onClick={() => toggleTabs("rooms")}
            buttonStyle={{ display: "flex", alignItems: "center" }}
          />
          {rooms && (
            <Rooms
              roomDetails={roomDetails}
              // isFeet={feet}
              // onToggle={onToggle}
              language="en"
            />
          )}
          <DetailPageDropdown
            title="Listing history"
            open={priceHistory}
            onClick={() => toggleTabs("priceHistory")}
          />
          {priceHistory && (
            <PriceHistory
              user={user}
              historyDetails={historyDetails}
              signUpHandler={signUpHandler}
              signInHandler={signInHandler}
              language="en"
            />
          )}
          {listing.class !== "CommercialProperty" && (
            <>
              <DetailPageDropdown
                title="Schools"
                open={schoolsInfo}
                onClick={() => toggleTabs("schoolsInfo")}
              />
              {schoolsInfo && <Schools schools={schools} listing={listing} />}
            </>
          )}

          {listing.class !== "CommercialProperty" && (
            <>
              <DetailPageDropdown
                title="Market Data"
                open={marketData}
                onClick={() => toggleTabs("marketData")}
              />
              {marketData && <MarketData listing={listing} isApp={true} />}
            </>
          )}

          {listing.type === "Sale" &&
            listing.class !== "CommercialProperty" && (
              <>
                <DetailPageDropdown
                  title="Calculators"
                  open={calculators}
                  onClick={() => toggleTabs("calculators")}
                />
                {calculators && <Calculators listing={listing} isApp={true} />}
              </>
            )}
        </div>
        <div className={styles.comparables}>
          {similarListings && similarListings.length > 0 && (
            <ComparableListings
              title="Similar active listings"
              listings={similarListings}
              isActive={true}
            />
          )}

          {comparables && comparables.length > 0 && (
            <ComparableListings
              title={
                comparables[0].type === "Sale"
                  ? "Similar sold listings"
                  : "Similar leased listings"
              }
              listings={comparables}
              user={user}
              signInHandler={signInHandler}
              signUpHandler={signUpHandler}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ListingContent;
