import { IonButton } from '@ionic/react';
import styles from './map-buttons-bottom.module.scss';
import { useSelector } from 'react-redux';
import { selectMapFavourites } from '../../../../redux/map/map.selectors';
import { selectFiltersValue } from '../../../../redux/filters/filters.selectors';
import { ListingStatus } from '../../../../enums/Listings/ListingStatus.enum';
import { ReactComponent as NearbyIcon } from '../../../../assets/svg/nearby.svg';
import MapButton from '../MapButton/map-button.component';

const MapButtonsBottom = ({isCentered, onLocate} : {isCentered: boolean; onLocate: () => void}) => {
    const isFavouritesOnlyEnabled = useSelector(selectMapFavourites);
    const filters = useSelector(selectFiltersValue);

    const handleSaveSearch = () => {};

    return <div className={styles.container}>
        <IonButton
          onClick={handleSaveSearch}
          disabled={isFavouritesOnlyEnabled || filters.status === ListingStatus.Unavailable}
          shape="round"
          fill="outline"
          color="dark">
          Save Search
        </IonButton>
        <MapButton icon={<NearbyIcon />} onClick={onLocate} disabled={false} active={isCentered} />
    </div>
};

export default MapButtonsBottom;