import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styles from "./search-modal.module.scss";
import {
  IonSearchbar,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonAvatar,
} from "@ionic/react";
import { getMapListings } from "../../../../../../api/repliers";
import { selectLocationsCitiesAndNeighbourhoods } from "../../../../../../redux/locations/locations.selectors";
import SearchResult from "../../../../../LocationSearch/components/search-result.component";
import { warningOutline } from "ionicons/icons";
import { Geolocation } from "@ionic-native/geolocation";
import { NativeGeocoder } from "@ionic-native/native-geocoder";
import {
  buildAddress,
  getHighlightedLength,
} from "../../../../../../utils/functions";
import { ListingService } from "../../../../../../services/listingService";
import MapListingCard from "../../../MapListingCard/map-listing.card.component";

const SearchModal = ({ close, mapRef }) => {
  const history = useHistory();
  const locations = useSelector(selectLocationsCitiesAndNeighbourhoods);
  const input = useRef(null);
  const [term, setTerm] = useState("");
  const [results, setResults] = useState();
  const [location, setLocation] = useState({
    coords: { latitude: null, longitude: null },
    address: null,
    listings: null,
  });

  const handleChange = async ({ detail: { value: term } }) => {
    setTerm(term);

    if (!term) {
      setResults(null);
    } else {
        try {
          const {listings} = term.length >= 3 ? await ListingService.searchListingsAndLocations(term) : {listings: []};
          const foundLocations = locations.find((location) =>
            location.name.toLowerCase().includes(term.toLowerCase())
          );

          setResults({
            listings,
            locations: foundLocations ? [foundLocations] : null,
          });
        } catch (err) {
          setResults(null);
        }
      
    }
  };

  const onSearchResultClick = (item, type) => {
    if (type === "listing") {
      history.push(`/listings/${item.mlsNumber}`);
    } else if (type === "currentLocation") {
      mapRef.panTo({
        lat: item.latitude,
        lng: item.longitude,
      });
    } else {
      mapRef.panTo({
        lat: item.location.lat,
        lng: item.location.lng,
      });
    }
    close();
  };

  useEffect(() => {
    const locate = async () => {
      try {
        const c = await Geolocation.getCurrentPosition();

        if (c) {
          const addresses = await NativeGeocoder.reverseGeocode(
            c.coords.latitude,
            c.coords.longitude,
            { useLocale: true, maxResults: 1 }
          );
          const address = addresses[0];
          const response = await getMapListings(address.subLocality);
          setLocation({
            coords: {
              latitude: c.coords.latitude,
              longitude: c.coords.longitude,
            },
            address,
            listings: response.length ? [...response] : null,
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    locate();
  }, []);

  const compareListings = (a, b) => {
    const aMatch = getHighlightedLength(
      `${a.address.city} - ${buildAddress(a.address)}`,
      term
    );

    const bMatch = getHighlightedLength(
      `${b.address.city} - ${buildAddress(b.address)}`,
      term
    );

    if (aMatch > bMatch) return -1;
    if (aMatch === bMatch) return 0;
    return 1;
  };

  useEffect(() => {
    if (input.current) {
      input.current.setFocus();
    }
  }, [input]);

  return (
    <div className={styles.container}>
      <div className={styles.searchbar}>
        <IonSearchbar
          ref={input}
          placeholder="location or MLS #"
          animated={true}
          autocomplete="address-level2"
          inputMode="text"
          showCancelButton="always"
          showClearButton="never"
          className={styles.input}
          onIonCancel={close}
          cancelButtonText="Done"
          onIonInput={handleChange}
          debounce={1000}
        />
      </div>
      <div className={styles.results}>
        {((results && results.locations) || location.address) && (
          <div className={styles.result}>
            <IonItem lines="full" className={styles.title}>
              <IonLabel>LOCATIONS</IonLabel>
            </IonItem>
            <IonList className={styles.list}>
              {location && location.address && (
                <SearchResult
                  type="current location"
                  item={{
                    name:
                      location.address.subLocality +
                      ", " +
                      location.address.subAdministrativeArea,
                  }}
                  onClick={() =>
                    onSearchResultClick(location.coords, "currentLocation")
                  }
                />
              )}

              {results &&
                results.locations &&
                results.locations.map((location) => (
                  <SearchResult
                    key={location.name}
                    item={location}
                    term={term}
                    type={location.type}
                    onClick={() => onSearchResultClick(location, "location")}
                  />
                ))}
            </IonList>
          </div>
        )}

        {results && results.listings && (
          <div className={styles.result}>
            <IonItem lines="full" className={styles.title}>
              <IonLabel>LISTINGS</IonLabel>
            </IonItem>
            <IonList className={styles.list}>
              {results &&
                results.listings &&
                results.listings
                  .sort(compareListings)
                  .map((listing) => (
                    <IonItem key={listing.mlsNumber} button routerLink={`/listings/${listing.mlsNumber}`}>
                      <IonAvatar aria-hidden="true" slot="start">
                        <img src={listing.images[0]} alt="listing" />
                      </IonAvatar>
                      <IonLabel>{buildAddress(listing.address)}</IonLabel>
                    </IonItem>
                  ))}
            </IonList>
          </div>
        )}

        {location && location.listings && !term && (
          <div className={styles.result}>
            <IonItem lines="full" className={styles.title}>
              <IonLabel>NEARBY LISTINGS</IonLabel>
            </IonItem>
            <IonList className={styles.list}>
              {location &&
                location.listings &&
                !term &&
                location.listings.map((listing) => (
                  <SearchResult
                    key={listing.mlsNumber}
                    item={listing}
                    term={location.address.subLocality}
                    type="listing"
                    onClick={() => onSearchResultClick(listing, "listing")}
                  />
                ))}
            </IonList>
          </div>
        )}

        {results && !results.listings && !results.locations && (
          <div className={styles.noResult}>
            <IonIcon icon={warningOutline} className={styles.icon} />
            <IonLabel className={styles.label}>
              No listing found. Try revising your search.
            </IonLabel>
          </div>
        )}
      </div>
    </div>
  );
};
export default SearchModal;
