import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router";
import styles from "./pre-listing-card.module.scss";
import moment from "moment";
import {
  IonCard,
  IonCardHeader,
  IonImg,
  IonCardContent,
  IonSpinner,
  IonIcon,
} from "@ionic/react";
import Fade from "react-reveal/Fade";
import withClient from "../../../HOC/withClient/with-client";
import DefaultImage from "../../../assets/img/no-photo.png";
import millify from "millify";
import {
  copyToClipboard,
  numberWithCommas,
  renderCurrencySign,
} from "../../../utils/functions";
import { selectCurrencyRates } from "../../../redux/currency/currency.selectors";
import FavoriteIcon from "../../../components/ListingCard/components/FavoriteIcon/favorite-icon.component";
import ListingTag from "../../../components/ListingCard/components/ListingTag/listing-tag.component";
import { fadeAnimation } from "../../../utils/animations";
import { updateToast } from "../../../redux/ui/ui.actions";

export const PHASES = {
  preconstruction: "Pre-construction",
  underconstruction: "Under construction",
  completed: "Completed",
};

const PreListingCard = ({
  listing,
  currentClient,
  updates,
  route,
  expand = false,
  animated = false,
  reloadFav,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const rates = useSelector(selectCurrencyRates);

  const {
    id,
    name,
    builder,
    phase,
    province,
    city,
    streetName,
    streetNumber,
    startPrice,
    endPrice,
    logo,
    minBeds,
    maxBeds,
    minSqft,
    maxSqft,
  } = listing;

  const [imgLoading, setImgLoading] = useState(true);
  const now = new Date();

  let currency;
  if (currentClient && rates) {
    if (currentClient.currency !== "cad")
      currency = currentClient.currency.toUpperCase();
  }

  const renderPrice = () => {
    if (startPrice)
      return currency
        ? `${renderCurrencySign(currency, false)}${millify(
            (+startPrice / rates.CAD) * rates[currency]
          )}`
        : `${renderCurrencySign(currency, false)}${numberWithCommas(
            startPrice
          )}`;
  };

  const buildAddress = () => {
    let fullAddress = "";
    if (streetNumber) fullAddress += `${streetNumber} - `;
    if (streetName) fullAddress += `${streetName} `;

    return fullAddress.trim();
  };

  const renderPhase = () => {
    if (!phase) return;
    return PHASES[phase];
  };

  const card = (
    <IonCard
      className={styles.card}
      routerDirection="forward"
      //onClick={() => dispatch(updateSelectedListing(null))}
      routerLink={`/projects/${id}${route ? `?route=${route}` : ""}`}
      routerAnimation={fadeAnimation}
    >
      <IonCardHeader className={styles.header}>
        {updates && <ListingTag updates={updates} isApp={true} />}
        {imgLoading && (
          <IonSpinner name="crescent" className={styles.spinner} />
        )}
        {logo ? (
          <IonImg
            src={logo}
            onIonError={(e) => (e.target.src = DefaultImage)}
            onIonImgDidLoad={() => setImgLoading(false)}
            className={styles.image}
          />
        ) : (
          <IonImg
            src={DefaultImage}
            onIonImgDidLoad={() => setImgLoading(false)}
            className={styles.image}
          />
        )}
      </IonCardHeader>
      <IonCardContent className={styles.content}>
        <div className={styles.icons}>
          <FavoriteIcon
            reload={reloadFav}
            listing={listing}
            isApp={true}
            isActiveComparables={false}
            isPreconstruction={true}
          />
        </div>
        <div className={styles.title}>{name}</div>
        <div className={styles.price}>
          {startPrice === 0 ? "Coming soon" : `Starting at ${renderPrice()}`}
        </div>
        <div className={styles.info}>
          <div>{buildAddress()}</div>
          <div>Project by {builder?.name}</div>
          <div>{renderPhase()}</div>
          <div>
            <span className={styles.beds}>
              {minBeds} {maxBeds !== 0 && `- ${maxBeds}`} bed
            </span>
            <span className={styles.sqft}>
              {minSqft !== 0 && `${numberWithCommas(minSqft)} - `}{" "}
              {maxSqft !== 0 ? numberWithCommas(maxSqft) : "-"} sqft
            </span>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );

  return animated ? (
    <div className={styles.animated}>
      <Fade>{card}</Fade>
    </div>
  ) : (
    card
  );
};

export default withClient(PreListingCard);
