import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styles from "../sold-and-active-and-new-listings.module.scss";

const SoldAndActiveAndNewListingsChart = ({ marketData, isApp }) => {
  const CustomizedTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customTooltip}>
          <div className={styles.date}>{payload[0]["payload"]["date"]}</div>
          <div
            className={styles.area}
          >{`${payload[0]["name"]} : ${payload[0].value}`}</div>
          <div
            className={styles.bar}
          >{`${payload[1]["name"]} : ${payload[1].value}`}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      styles={{ position: "inherit" }}
    >
      <>
        <ComposedChart
          width={isApp ? 320 : 700}
          height={400}
          data={marketData}
          margin={{
            top: 10,
            right: 0,
            bottom: 20,
            left: -15,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey="date"
            scale="band"
            angle={-45}
            padding={{ top: 15 }}
            tickMargin={22}
            stroke="#6e6e6e"
            style={{ fontSize: "1.2rem" }}
          />
          <YAxis stroke="#6e6e6e" style={{ fontSize: "1.4rem" }} />
          <Tooltip content={<CustomizedTooltip />} />
          <Legend
            verticalAlign="top"
            layout="vertical"
            margin={{ bottom: 8 }}
          />
          <Area
            type="monotone"
            dataKey="New listings"
            fill="#1aaecc"
            stroke="#007b94"
            fillOpacity={0.03}
          />
          <Area
            type="monotone"
            dataKey="Sold listings"
            fill="#6e6e6e"
            stroke="#6e6e6e"
            fillOpacity={0.02}
          />
        </ComposedChart>
      </>
    </ResponsiveContainer>
  );
};

export default SoldAndActiveAndNewListingsChart;
