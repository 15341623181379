import {
  capitalize,
  numberWithCommas,
} from "../../../../../../../../utils/functions";
import styles from "./pre-pricing-and-fees.module.scss";

const PrePricingAndFees = ({ project }) => {
  return (
    <div className={styles.prePricingAndFees}>
      <div className={styles.infos}>
        <div className={styles.details}>
          <div>
            <span className={styles.bold}>Price range</span>
            <span>
              From{" "}
              {project.startPrice
                ? numberWithCommas(project.startPrice, true)
                : "-"}{" "}
              to over{" "}
              {project.endPrice
                ? numberWithCommas(project.endPrice, true)
                : "-"}
            </span>
          </div>

          <div>
            <span className={styles.bold}>Parking</span>
            <span>
              {project.parkingPrice
                ? numberWithCommas(project.parkingPrice, true)
                : "-"}
            </span>
          </div>

          <div>
            <span className={styles.bold}>Locker</span>
            <span>
              {project.lockerPrice
                ? numberWithCommas(project.lockerPrice, true)
                : "-"}
            </span>
          </div>

          <div>
            <span className={styles.bold}>Average price per sq.ft.</span>
            <span>
              {project.pricePerSqft
                ? `${numberWithCommas(project.pricePerSqft, true)} per SqFt`
                : "-"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrePricingAndFees;
