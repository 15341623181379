/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getBuilder = /* GraphQL */ `query GetBuilder($id: ID!) {
  getBuilder(id: $id) {
    id
    name
    projects {
      nextToken
      __typename
    }
    logo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBuilderQueryVariables,
  APITypes.GetBuilderQuery
>;
export const listBuilders = /* GraphQL */ `query ListBuilders(
  $id: ID
  $filter: ModelBuilderFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBuilders(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      logo
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBuildersQueryVariables,
  APITypes.ListBuildersQuery
>;
export const getArchitect = /* GraphQL */ `query GetArchitect($id: ID!) {
  getArchitect(id: $id) {
    name
    projects {
      nextToken
      __typename
    }
    logo
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetArchitectQueryVariables,
  APITypes.GetArchitectQuery
>;
export const listArchitects = /* GraphQL */ `query ListArchitects(
  $filter: ModelArchitectFilterInput
  $limit: Int
  $nextToken: String
) {
  listArchitects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      name
      logo
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListArchitectsQueryVariables,
  APITypes.ListArchitectsQuery
>;
export const getInteriorDesigner = /* GraphQL */ `query GetInteriorDesigner($id: ID!) {
  getInteriorDesigner(id: $id) {
    name
    projects {
      nextToken
      __typename
    }
    logo
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInteriorDesignerQueryVariables,
  APITypes.GetInteriorDesignerQuery
>;
export const listInteriorDesigners = /* GraphQL */ `query ListInteriorDesigners(
  $filter: ModelInteriorDesignerFilterInput
  $limit: Int
  $nextToken: String
) {
  listInteriorDesigners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      name
      logo
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInteriorDesignersQueryVariables,
  APITypes.ListInteriorDesignersQuery
>;
export const getProject = /* GraphQL */ `query GetProject($id: ID!) {
  getProject(id: $id) {
    name
    streetNumber
    streetName
    city
    province
    latitude
    longitude
    builderId
    builder {
      id
      name
      logo
      createdAt
      updatedAt
      __typename
    }
    architectId
    architect {
      name
      logo
      id
      createdAt
      updatedAt
      __typename
    }
    interiorDesignerId
    interiorDesigner {
      name
      logo
      id
      createdAt
      updatedAt
      __typename
    }
    occupancy
    units
    stories
    startPrice
    endPrice
    minBeds
    maxBeds
    minSqft
    maxSqft
    pricePerSqft
    maintenanceFees
    parkingPrice
    lockerPrice
    phase
    availability
    types
    description
    incentives
    amenities {
      nextToken
      __typename
    }
    depositStructure
    logo
    media
    projectUnits {
      nextToken
      __typename
    }
    lastUpdatedOn
    isSoldOut
    commission
    id
    createdAt
    updatedAt
    builderProjectsId
    architectProjectsId
    interiorDesignerProjectsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectQueryVariables,
  APITypes.GetProjectQuery
>;
export const listProjects = /* GraphQL */ `query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectsQueryVariables,
  APITypes.ListProjectsQuery
>;
export const projectByName = /* GraphQL */ `query ProjectByName(
  $name: String!
  $sortDirection: ModelSortDirection
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  projectByName(
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProjectByNameQueryVariables,
  APITypes.ProjectByNameQuery
>;
export const projectByBuilder = /* GraphQL */ `query ProjectByBuilder(
  $builderId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  projectByBuilder(
    builderId: $builderId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProjectByBuilderQueryVariables,
  APITypes.ProjectByBuilderQuery
>;
export const projectByArchitect = /* GraphQL */ `query ProjectByArchitect(
  $architectId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  projectByArchitect(
    architectId: $architectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProjectByArchitectQueryVariables,
  APITypes.ProjectByArchitectQuery
>;
export const projectByInteriorDesigner = /* GraphQL */ `query ProjectByInteriorDesigner(
  $interiorDesignerId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  projectByInteriorDesigner(
    interiorDesignerId: $interiorDesignerId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProjectByInteriorDesignerQueryVariables,
  APITypes.ProjectByInteriorDesignerQuery
>;
export const getProjectUnit = /* GraphQL */ `query GetProjectUnit($id: ID!) {
  getProjectUnit(id: $id) {
    numBeds
    numBaths
    numParkings
    numLockers
    sqft
    price
    pricePerSqft
    projectId
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    type
    available
    sold
    media
    id
    createdAt
    updatedAt
    projectProjectUnitsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectUnitQueryVariables,
  APITypes.GetProjectUnitQuery
>;
export const listProjectUnits = /* GraphQL */ `query ListProjectUnits(
  $filter: ModelProjectUnitFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      numBeds
      numBaths
      numParkings
      numLockers
      sqft
      price
      pricePerSqft
      projectId
      type
      available
      sold
      media
      id
      createdAt
      updatedAt
      projectProjectUnitsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectUnitsQueryVariables,
  APITypes.ListProjectUnitsQuery
>;
export const getAmenity = /* GraphQL */ `query GetAmenity($id: ID!) {
  getAmenity(id: $id) {
    name
    description
    projects {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAmenityQueryVariables,
  APITypes.GetAmenityQuery
>;
export const listAmenities = /* GraphQL */ `query ListAmenities(
  $filter: ModelAmenityFilterInput
  $limit: Int
  $nextToken: String
) {
  listAmenities(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      name
      description
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAmenitiesQueryVariables,
  APITypes.ListAmenitiesQuery
>;
export const getFeatured = /* GraphQL */ `query GetFeatured($id: ID!) {
  getFeatured(id: $id) {
    title
    subtitle
    filters
    isNearby
    isFavourite
    isSchool
    order
    status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFeaturedQueryVariables,
  APITypes.GetFeaturedQuery
>;
export const listFeatureds = /* GraphQL */ `query ListFeatureds(
  $filter: ModelFeaturedFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeatureds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      title
      subtitle
      filters
      isNearby
      isFavourite
      isSchool
      order
      status
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFeaturedsQueryVariables,
  APITypes.ListFeaturedsQuery
>;
export const getHomeCard = /* GraphQL */ `query GetHomeCard($id: ID!) {
  getHomeCard(id: $id) {
    title
    text
    image
    actionLabel
    actionLink
    inAppAction
    order
    status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHomeCardQueryVariables,
  APITypes.GetHomeCardQuery
>;
export const listHomeCards = /* GraphQL */ `query ListHomeCards(
  $filter: ModelHomeCardFilterInput
  $limit: Int
  $nextToken: String
) {
  listHomeCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      title
      text
      image
      actionLabel
      actionLink
      inAppAction
      order
      status
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHomeCardsQueryVariables,
  APITypes.ListHomeCardsQuery
>;
export const getProfile = /* GraphQL */ `query GetProfile($id: ID!) {
  getProfile(id: $id) {
    userId
    buyingSelling
    leasingRenting
    justLooking
    preConstruction
    isRealtor
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileQueryVariables,
  APITypes.GetProfileQuery
>;
export const listProfiles = /* GraphQL */ `query ListProfiles(
  $filter: ModelProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      userId
      buyingSelling
      leasingRenting
      justLooking
      preConstruction
      isRealtor
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProfilesQueryVariables,
  APITypes.ListProfilesQuery
>;
export const getFavourite = /* GraphQL */ `query GetFavourite($id: ID!) {
  getFavourite(id: $id) {
    userId
    repliersID
    mlsNumber
    listing
    listingUpdate
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFavouriteQueryVariables,
  APITypes.GetFavouriteQuery
>;
export const listFavourites = /* GraphQL */ `query ListFavourites(
  $filter: ModelFavouriteFilterInput
  $limit: Int
  $nextToken: String
) {
  listFavourites(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      userId
      repliersID
      mlsNumber
      listing
      listingUpdate
      notification
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFavouritesQueryVariables,
  APITypes.ListFavouritesQuery
>;
export const getFavouriteByMlsNumber = /* GraphQL */ `query GetFavouriteByMlsNumber(
  $mlsNumber: String!
  $sortDirection: ModelSortDirection
  $filter: ModelFavouriteFilterInput
  $limit: Int
  $nextToken: String
) {
  getFavouriteByMlsNumber(
    mlsNumber: $mlsNumber
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      repliersID
      mlsNumber
      listing
      listingUpdate
      notification
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFavouriteByMlsNumberQueryVariables,
  APITypes.GetFavouriteByMlsNumberQuery
>;
export const getPreFavourite = /* GraphQL */ `query GetPreFavourite($id: ID!) {
  getPreFavourite(id: $id) {
    userId
    projectId
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPreFavouriteQueryVariables,
  APITypes.GetPreFavouriteQuery
>;
export const listPreFavourites = /* GraphQL */ `query ListPreFavourites(
  $filter: ModelPreFavouriteFilterInput
  $limit: Int
  $nextToken: String
) {
  listPreFavourites(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      userId
      projectId
      notification
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPreFavouritesQueryVariables,
  APITypes.ListPreFavouritesQuery
>;
export const getPreFavouriteByProjectId = /* GraphQL */ `query GetPreFavouriteByProjectId(
  $projectId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPreFavouriteFilterInput
  $limit: Int
  $nextToken: String
) {
  getPreFavouriteByProjectId(
    projectId: $projectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      projectId
      notification
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPreFavouriteByProjectIdQueryVariables,
  APITypes.GetPreFavouriteByProjectIdQuery
>;
export const getSearch = /* GraphQL */ `query GetSearch($id: ID!) {
  getSearch(id: $id) {
    name
    userId
    repliersID
    value
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSearchQueryVariables, APITypes.GetSearchQuery>;
export const listSearches = /* GraphQL */ `query ListSearches(
  $filter: ModelSearchFilterInput
  $limit: Int
  $nextToken: String
) {
  listSearches(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      name
      userId
      repliersID
      value
      notification
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSearchesQueryVariables,
  APITypes.ListSearchesQuery
>;
export const getSearchByRepliersId = /* GraphQL */ `query GetSearchByRepliersId(
  $repliersID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSearchFilterInput
  $limit: Int
  $nextToken: String
) {
  getSearchByRepliersId(
    repliersID: $repliersID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      name
      userId
      repliersID
      value
      notification
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSearchByRepliersIdQueryVariables,
  APITypes.GetSearchByRepliersIdQuery
>;
export const getNotification = /* GraphQL */ `query GetNotification($id: ID!) {
  getNotification(id: $id) {
    userId
    schedule
    type
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationQueryVariables,
  APITypes.GetNotificationQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      userId
      schedule
      type
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    repliersID
    givenName
    familyName
    name
    displayName
    email
    phone
    profilePicture
    role
    agent {
      userId
      website
      licenseNumber
      areas
      availability
      id
      createdAt
      updatedAt
      brokerageAgentsId
      __typename
    }
    client {
      userId
      id
      createdAt
      updatedAt
      agentClientsId
      clientAgentId
      __typename
    }
    subscribed
    currency
    measurement
    notifications {
      userId
      schedule
      type
      id
      createdAt
      updatedAt
      __typename
    }
    deviceToken
    profile {
      userId
      buyingSelling
      leasingRenting
      justLooking
      preConstruction
      isRealtor
      id
      createdAt
      updatedAt
      __typename
    }
    requestedTour
    tours {
      nextToken
      __typename
    }
    toursNote
    deactivated
    id
    createdAt
    updatedAt
    userAgentId
    userClientId
    userNotificationsId
    userProfileId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      repliersID
      givenName
      familyName
      name
      displayName
      email
      phone
      profilePicture
      role
      subscribed
      currency
      measurement
      deviceToken
      requestedTour
      toursNote
      deactivated
      id
      createdAt
      updatedAt
      userAgentId
      userClientId
      userNotificationsId
      userProfileId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getUserByName = /* GraphQL */ `query GetUserByName(
  $name: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserByName(
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      repliersID
      givenName
      familyName
      name
      displayName
      email
      phone
      profilePicture
      role
      subscribed
      currency
      measurement
      deviceToken
      requestedTour
      toursNote
      deactivated
      id
      createdAt
      updatedAt
      userAgentId
      userClientId
      userNotificationsId
      userProfileId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByNameQueryVariables,
  APITypes.GetUserByNameQuery
>;
export const getUserByEmail = /* GraphQL */ `query GetUserByEmail(
  $email: AWSEmail!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      repliersID
      givenName
      familyName
      name
      displayName
      email
      phone
      profilePicture
      role
      subscribed
      currency
      measurement
      deviceToken
      requestedTour
      toursNote
      deactivated
      id
      createdAt
      updatedAt
      userAgentId
      userClientId
      userNotificationsId
      userProfileId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByEmailQueryVariables,
  APITypes.GetUserByEmailQuery
>;
export const getAgent = /* GraphQL */ `query GetAgent($id: ID!) {
  getAgent(id: $id) {
    userId
    website
    licenseNumber
    areas
    availability
    brokerage {
      name
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    clients {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    brokerageAgentsId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAgentQueryVariables, APITypes.GetAgentQuery>;
export const listAgents = /* GraphQL */ `query ListAgents(
  $filter: ModelAgentFilterInput
  $limit: Int
  $nextToken: String
) {
  listAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      userId
      website
      licenseNumber
      areas
      availability
      id
      createdAt
      updatedAt
      brokerageAgentsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAgentsQueryVariables,
  APITypes.ListAgentsQuery
>;
export const getClient = /* GraphQL */ `query GetClient($id: ID!) {
  getClient(id: $id) {
    userId
    agent {
      userId
      website
      licenseNumber
      areas
      availability
      id
      createdAt
      updatedAt
      brokerageAgentsId
      __typename
    }
    id
    createdAt
    updatedAt
    agentClientsId
    clientAgentId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientQueryVariables, APITypes.GetClientQuery>;
export const listClients = /* GraphQL */ `query ListClients(
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      userId
      id
      createdAt
      updatedAt
      agentClientsId
      clientAgentId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClientsQueryVariables,
  APITypes.ListClientsQuery
>;
export const getBrokerage = /* GraphQL */ `query GetBrokerage($id: ID!) {
  getBrokerage(id: $id) {
    name
    agents {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBrokerageQueryVariables,
  APITypes.GetBrokerageQuery
>;
export const listBrokerages = /* GraphQL */ `query ListBrokerages(
  $filter: ModelBrokerageFilterInput
  $limit: Int
  $nextToken: String
) {
  listBrokerages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      name
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBrokeragesQueryVariables,
  APITypes.ListBrokeragesQuery
>;
export const getUserTour = /* GraphQL */ `query GetUserTour($id: ID!) {
  getUserTour(id: $id) {
    creator
    userId
    tourId
    user {
      repliersID
      givenName
      familyName
      name
      displayName
      email
      phone
      profilePicture
      role
      subscribed
      currency
      measurement
      deviceToken
      requestedTour
      toursNote
      deactivated
      id
      createdAt
      updatedAt
      userAgentId
      userClientId
      userNotificationsId
      userProfileId
      __typename
    }
    tour {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    role
    seen
    id
    createdAt
    updatedAt
    userToursId
    tourUsersId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserTourQueryVariables,
  APITypes.GetUserTourQuery
>;
export const listUserTours = /* GraphQL */ `query ListUserTours(
  $filter: ModelUserTourFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserTours(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      creator
      userId
      tourId
      role
      seen
      id
      createdAt
      updatedAt
      userToursId
      tourUsersId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserToursQueryVariables,
  APITypes.ListUserToursQuery
>;
export const getTour = /* GraphQL */ `query GetTour($id: ID!) {
  getTour(id: $id) {
    title
    creator
    users {
      nextToken
      __typename
    }
    clients
    primaryAgent
    showingAgents
    status
    privateNote
    generalNote
    tourItems {
      nextToken
      __typename
    }
    meetupLocation
    meetupDescription
    meetupTime
    startTime
    endTime
    date
    shared
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTourQueryVariables, APITypes.GetTourQuery>;
export const listTours = /* GraphQL */ `query ListTours(
  $filter: ModelTourFilterInput
  $limit: Int
  $nextToken: String
) {
  listTours(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListToursQueryVariables, APITypes.ListToursQuery>;
export const getToursByStatus = /* GraphQL */ `query GetToursByStatus(
  $status: TourStatus!
  $sortDirection: ModelSortDirection
  $filter: ModelTourFilterInput
  $limit: Int
  $nextToken: String
) {
  getToursByStatus(
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetToursByStatusQueryVariables,
  APITypes.GetToursByStatusQuery
>;
export const getToursByDate = /* GraphQL */ `query GetToursByDate(
  $date: AWSDate!
  $sortDirection: ModelSortDirection
  $filter: ModelTourFilterInput
  $limit: Int
  $nextToken: String
) {
  getToursByDate(
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetToursByDateQueryVariables,
  APITypes.GetToursByDateQuery
>;
export const getToursByShareStatus = /* GraphQL */ `query GetToursByShareStatus(
  $shared: ShareStatus!
  $sortDirection: ModelSortDirection
  $filter: ModelTourFilterInput
  $limit: Int
  $nextToken: String
) {
  getToursByShareStatus(
    shared: $shared
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetToursByShareStatusQueryVariables,
  APITypes.GetToursByShareStatusQuery
>;
export const getTourItem = /* GraphQL */ `query GetTourItem($id: ID!) {
  getTourItem(id: $id) {
    creator
    tourId
    tour {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    clients
    primaryAgent
    showingAgents
    mlsNumber
    status
    startTime
    endTime
    order
    showingNote
    entryInfo
    reviews {
      nextToken
      __typename
    }
    notes {
      nextToken
      __typename
    }
    photos {
      nextToken
      __typename
    }
    recordings {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    tourTourItemsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTourItemQueryVariables,
  APITypes.GetTourItemQuery
>;
export const listTourItems = /* GraphQL */ `query ListTourItems(
  $filter: ModelTourItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listTourItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      creator
      tourId
      clients
      primaryAgent
      showingAgents
      mlsNumber
      status
      startTime
      endTime
      order
      showingNote
      entryInfo
      id
      createdAt
      updatedAt
      tourTourItemsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTourItemsQueryVariables,
  APITypes.ListTourItemsQuery
>;
export const getTourItemsByOrder = /* GraphQL */ `query GetTourItemsByOrder(
  $order: Int!
  $sortDirection: ModelSortDirection
  $filter: ModelTourItemFilterInput
  $limit: Int
  $nextToken: String
) {
  getTourItemsByOrder(
    order: $order
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      creator
      tourId
      clients
      primaryAgent
      showingAgents
      mlsNumber
      status
      startTime
      endTime
      order
      showingNote
      entryInfo
      id
      createdAt
      updatedAt
      tourTourItemsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTourItemsByOrderQueryVariables,
  APITypes.GetTourItemsByOrderQuery
>;
export const getShowing = /* GraphQL */ `query GetShowing($id: ID!) {
  getShowing(id: $id) {
    userId
    mlsNumber
    date
    startTime
    endTime
    instructions
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetShowingQueryVariables,
  APITypes.GetShowingQuery
>;
export const listShowings = /* GraphQL */ `query ListShowings(
  $filter: ModelShowingFilterInput
  $limit: Int
  $nextToken: String
) {
  listShowings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      userId
      mlsNumber
      date
      startTime
      endTime
      instructions
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListShowingsQueryVariables,
  APITypes.ListShowingsQuery
>;
export const getShowingsByDate = /* GraphQL */ `query GetShowingsByDate(
  $date: AWSDate!
  $sortDirection: ModelSortDirection
  $filter: ModelShowingFilterInput
  $limit: Int
  $nextToken: String
) {
  getShowingsByDate(
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      mlsNumber
      date
      startTime
      endTime
      instructions
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetShowingsByDateQueryVariables,
  APITypes.GetShowingsByDateQuery
>;
export const getReview = /* GraphQL */ `query GetReview($id: ID!) {
  getReview(id: $id) {
    userId
    tourItemId
    rate
    id
    createdAt
    updatedAt
    tourItemReviewsId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReviewQueryVariables, APITypes.GetReviewQuery>;
export const getSchool = /* GraphQL */ `query GetSchool($id: ID!) {
  getSchool(id: $id) {
    objVer
    name
    isCatholic
    isPublic
    isUseSchool
    gradeFrom
    gradeEnd
    isElementary
    isMiddle
    isHigh
    isEnglish
    isFrenchImmersion
    isExtendedFrench
    isAP
    isArts
    isGifted
    isIB
    isSport
    g3Avg
    g3WeightedAvg
    g6Avg
    g6WeightedAvg
    g9Avg
    g9APMWeightedAvg
    g9ACMWeightedAvg
    g10Avg
    ettieOverallAvg
    g3Rank
    g3RankTotal
    g6Rank
    g6RankTotal
    g9Rank
    g9RankTotal
    g9ACRank
    g9ACTotalRank
    g9APRank
    g9APTotalRank
    g10Rank
    g10RankTotal
    EQAOScore
    yearMark
    province
    nation
    url
    schoolBoardId
    schoolBoard {
      objVer
      name
      url
      boardType
      province
      nation
      address
      city
      postalCode
      phone
      fax
      id
      createdAt
      updatedAt
      __typename
    }
    ratings {
      nextToken
      __typename
    }
    address
    city
    postalCode
    phone
    fax
    latitude
    longitude
    comments
    boundaryArray
    schoolsAssocationArray
    lastReviewDate
    id
    createdAt
    updatedAt
    schoolBoardSchoolsId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSchoolQueryVariables, APITypes.GetSchoolQuery>;
export const listSchools = /* GraphQL */ `query ListSchools(
  $filter: ModelSchoolFilterInput
  $limit: Int
  $nextToken: String
) {
  listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      objVer
      name
      isCatholic
      isPublic
      isUseSchool
      gradeFrom
      gradeEnd
      isElementary
      isMiddle
      isHigh
      isEnglish
      isFrenchImmersion
      isExtendedFrench
      isAP
      isArts
      isGifted
      isIB
      isSport
      g3Avg
      g3WeightedAvg
      g6Avg
      g6WeightedAvg
      g9Avg
      g9APMWeightedAvg
      g9ACMWeightedAvg
      g10Avg
      ettieOverallAvg
      g3Rank
      g3RankTotal
      g6Rank
      g6RankTotal
      g9Rank
      g9RankTotal
      g9ACRank
      g9ACTotalRank
      g9APRank
      g9APTotalRank
      g10Rank
      g10RankTotal
      EQAOScore
      yearMark
      province
      nation
      url
      schoolBoardId
      address
      city
      postalCode
      phone
      fax
      latitude
      longitude
      comments
      boundaryArray
      schoolsAssocationArray
      lastReviewDate
      id
      createdAt
      updatedAt
      schoolBoardSchoolsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSchoolsQueryVariables,
  APITypes.ListSchoolsQuery
>;
export const getSchoolBoard = /* GraphQL */ `query GetSchoolBoard($id: ID!) {
  getSchoolBoard(id: $id) {
    objVer
    name
    url
    boardType
    province
    nation
    address
    city
    postalCode
    phone
    fax
    schools {
      nextToken
      __typename
    }
    scores {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSchoolBoardQueryVariables,
  APITypes.GetSchoolBoardQuery
>;
export const listSchoolBoards = /* GraphQL */ `query ListSchoolBoards(
  $filter: ModelSchoolBoardFilterInput
  $limit: Int
  $nextToken: String
) {
  listSchoolBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      objVer
      name
      url
      boardType
      province
      nation
      address
      city
      postalCode
      phone
      fax
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSchoolBoardsQueryVariables,
  APITypes.ListSchoolBoardsQuery
>;
export const getSchoolBoardScore = /* GraphQL */ `query GetSchoolBoardScore($id: ID!) {
  getSchoolBoardScore(id: $id) {
    schoolBoardId
    schoolBoard {
      objVer
      name
      url
      boardType
      province
      nation
      address
      city
      postalCode
      phone
      fax
      id
      createdAt
      updatedAt
      __typename
    }
    yearMark
    year
    totalAvg
    g3STU
    g6STU
    g9ACSTU
    g9APSTU
    g3TS
    g6TS
    g9TS
    g10OSSLTFSTUA
    g10OSSLTFSTUP
    g10OSSLTFSUCCESS
    g3AR
    g3AW
    g3AM
    g6AR
    g6AW
    g6AM
    g9APM
    g9ACM
    g3Last5Avg
    g6Last5Avg
    g9Last5Avg
    g3FindSchoolRating
    g6FindSchoolRating
    g9FindSchoolRating
    id
    createdAt
    updatedAt
    schoolBoardScoresId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSchoolBoardScoreQueryVariables,
  APITypes.GetSchoolBoardScoreQuery
>;
export const listSchoolBoardScores = /* GraphQL */ `query ListSchoolBoardScores(
  $filter: ModelSchoolBoardScoreFilterInput
  $limit: Int
  $nextToken: String
) {
  listSchoolBoardScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      schoolBoardId
      yearMark
      year
      totalAvg
      g3STU
      g6STU
      g9ACSTU
      g9APSTU
      g3TS
      g6TS
      g9TS
      g10OSSLTFSTUA
      g10OSSLTFSTUP
      g10OSSLTFSUCCESS
      g3AR
      g3AW
      g3AM
      g6AR
      g6AW
      g6AM
      g9APM
      g9ACM
      g3Last5Avg
      g6Last5Avg
      g9Last5Avg
      g3FindSchoolRating
      g6FindSchoolRating
      g9FindSchoolRating
      id
      createdAt
      updatedAt
      schoolBoardScoresId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSchoolBoardScoresQueryVariables,
  APITypes.ListSchoolBoardScoresQuery
>;
export const getSchoolRating = /* GraphQL */ `query GetSchoolRating($id: ID!) {
  getSchoolRating(id: $id) {
    objVer
    schoolId
    school {
      objVer
      name
      isCatholic
      isPublic
      isUseSchool
      gradeFrom
      gradeEnd
      isElementary
      isMiddle
      isHigh
      isEnglish
      isFrenchImmersion
      isExtendedFrench
      isAP
      isArts
      isGifted
      isIB
      isSport
      g3Avg
      g3WeightedAvg
      g6Avg
      g6WeightedAvg
      g9Avg
      g9APMWeightedAvg
      g9ACMWeightedAvg
      g10Avg
      ettieOverallAvg
      g3Rank
      g3RankTotal
      g6Rank
      g6RankTotal
      g9Rank
      g9RankTotal
      g9ACRank
      g9ACTotalRank
      g9APRank
      g9APTotalRank
      g10Rank
      g10RankTotal
      EQAOScore
      yearMark
      province
      nation
      url
      schoolBoardId
      address
      city
      postalCode
      phone
      fax
      latitude
      longitude
      comments
      boundaryArray
      schoolsAssocationArray
      lastReviewDate
      id
      createdAt
      updatedAt
      schoolBoardSchoolsId
      __typename
    }
    yearMark
    year
    G3STU
    G3AR
    G3AW
    G3AM
    G3Rank
    G3Total
    G3FindSchoolRating
    G6STU
    G6AR
    G6AW
    G6AM
    G6Rank
    G6Total
    G6FindSchoolRating
    G9ACSTU
    G9ACM
    G9ACRank
    G9ACTotal
    G9APSTU
    G9APM
    G9APRank
    G9APTotal
    G9Rank
    G9Total
    G9FindSchoolRating
    G10OSSLTFSTUA
    G10OSSLTFSTUP
    G10OSSLTFSUCCESS
    G3Last5Avg
    G6Last5Avg
    G9Last5Avg
    id
    createdAt
    updatedAt
    schoolRatingsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSchoolRatingQueryVariables,
  APITypes.GetSchoolRatingQuery
>;
export const listSchoolRatings = /* GraphQL */ `query ListSchoolRatings(
  $filter: ModelSchoolRatingFilterInput
  $limit: Int
  $nextToken: String
) {
  listSchoolRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      objVer
      schoolId
      yearMark
      year
      G3STU
      G3AR
      G3AW
      G3AM
      G3Rank
      G3Total
      G3FindSchoolRating
      G6STU
      G6AR
      G6AW
      G6AM
      G6Rank
      G6Total
      G6FindSchoolRating
      G9ACSTU
      G9ACM
      G9ACRank
      G9ACTotal
      G9APSTU
      G9APM
      G9APRank
      G9APTotal
      G9Rank
      G9Total
      G9FindSchoolRating
      G10OSSLTFSTUA
      G10OSSLTFSTUP
      G10OSSLTFSUCCESS
      G3Last5Avg
      G6Last5Avg
      G9Last5Avg
      id
      createdAt
      updatedAt
      schoolRatingsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSchoolRatingsQueryVariables,
  APITypes.ListSchoolRatingsQuery
>;
export const getProjectAmenities = /* GraphQL */ `query GetProjectAmenities($id: ID!) {
  getProjectAmenities(id: $id) {
    id
    projectID
    amenityID
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    amenity {
      name
      description
      id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectAmenitiesQueryVariables,
  APITypes.GetProjectAmenitiesQuery
>;
export const listProjectAmenities = /* GraphQL */ `query ListProjectAmenities(
  $filter: ModelProjectAmenitiesFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectAmenities(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectID
      amenityID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectAmenitiesQueryVariables,
  APITypes.ListProjectAmenitiesQuery
>;
