export const listTours = /* GraphQL */ `query ListTours(
  $filter: ModelTourFilterInput
  $limit: Int
  $nextToken: String
) {
  listTours(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
      tourItems {
        items {
          id
          mlsNumber
          status
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          role
          seen
          id
          userId 
          user {
            id
            givenName
            familyName
            name 
            displayName
            email
            phone 
          }
        }
        nextToken
        __typename  
      }
    }
    nextToken
    __typename
  }
}
`;

export const getClientTour = /* GraphQL */ `query GetTour($id: ID!) {
  getTour(id: $id) {
    title
    creator
    users {
      items {
        role
        seen
        id
        userId
        user {
          id
          givenName
          familyName
          name
          displayName
          email
          phone
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    clients
    primaryAgent
    showingAgents
    status
    generalNote
    tourItems {
      items {
        id
        order 
        startTime
        endTime
        mlsNumber
        status
        __typename
      }
      nextToken
      __typename
    }
    meetupLocation
    meetupDescription
    meetupTime
    startTime
    endTime
    date
    shared
    id
    createdAt
    updatedAt
    __typename
  }
}
`;

export const getAgentTour = /* GraphQL */ `query GetTour($id: ID!) {
  getTour(id: $id) {
    title
    creator
    users {
      items {
        role
        seen
        id
        userId
        user {
          id
          givenName
          familyName
          name
          displayName
          email
          phone
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    clients
    primaryAgent
    showingAgents
    status
    generalNote
    privateNote
    tourItems {
      items {
        id
        order 
        startTime
        endTime
        mlsNumber
        status
        __typename
      }
      nextToken
      __typename
    }
    meetupLocation
    meetupDescription
    meetupTime
    startTime
    endTime
    date
    shared
    id
    createdAt
    updatedAt
    __typename
  }
}
`;

export const listUserTours = /* GraphQL */ `query ListUserTours(
  $filter: ModelUserTourFilterInput
  $limit: Int
  $nextToken: String
  $filterTour: ModelTourFilterInput
) {
  listUserTours(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      tour(filter: $filterTour) {
        title
        creator
        clients
        primaryAgent
        showingAgents
        status
        privateNote
        generalNote
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        id
        createdAt
        updatedAt
        __typename
      }
      id
      userId
      role
      seen
      tourId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
`;