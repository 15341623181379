export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      objVer
      name
      isCatholic
      isPublic
      isUseSchool
      gradeFrom
      gradeEnd
      isElementary
      isMiddle
      isHigh
      isEnglish
      isFrenchImmersion
      isExtendedFrench
      isAP
      isArts
      isGifted
      isIB
      isSport
      g3Avg
      g3WeightedAvg
      g6Avg
      g6WeightedAvg
      g9Avg
      g9APMWeightedAvg
      g9ACMWeightedAvg
      g10Avg
      ettieOverallAvg
      g3Rank
      g3RankTotal
      g6Rank
      g6RankTotal
      g9Rank
      g9RankTotal
      g9ACRank
      g9ACTotalRank
      g9APRank
      g9APTotalRank
      g10Rank
      g10RankTotal
      EQAOScore
      yearMark
      province
      nation
      url
      schoolBoardId
      address
      city
      postalCode
      phone
      fax
      latitude
      longitude
      comments
      boundaryArray
      schoolsAssocationArray
      lastReviewDate
      id
      createdAt
      updatedAt
      schoolBoardSchoolsId
      ratings {
        items {
          objVer
          schoolId
          yearMark
          year
          G3STU
          G3AR
          G3AW
          G3AM
          G3Rank
          G3Total
          G3FindSchoolRating
          G6STU
          G6AR
          G6AW
          G6AM
          G6Rank
          G6Total
          G6FindSchoolRating
          G9ACSTU
          G9ACM
          G9ACRank
          G9ACTotal
          G9APSTU
          G9APM
          G9APRank
          G9APTotal
          G9Rank
          G9Total
          G9FindSchoolRating
          G10OSSLTFSTUA
          G10OSSLTFSTUP
          G10OSSLTFSUCCESS
          G3Last5Avg
          G6Last5Avg
          G9Last5Avg
          id
          createdAt
          updatedAt
          schoolRatingsId
        }
      }
      schoolBoard {
        objVer
        name
        url
        boardType
        province
        nation
        address
        city
        postalCode
        phone
        fax
        id
        createdAt
        updatedAt
        scores {
          items {
            schoolBoardId
            yearMark
            year
            totalAvg
            g3STU
            g6STU
            g9ACSTU
            g9APSTU
            g3TS
            g6TS
            g9TS
            g10OSSLTFSTUA
            g10OSSLTFSTUP
            g10OSSLTFSUCCESS
            g3AR
            g3AW
            g3AM
            g6AR
            g6AW
            g6AM
            g9APM
            g9ACM
            g3Last5Avg
            g6Last5Avg
            g9Last5Avg
            g3FindSchoolRating
            g6FindSchoolRating
            g9FindSchoolRating
            id
            createdAt
            updatedAt
            schoolBoardScoresId
          }
        }
        __typename
      }
      __typename
    }
  }
`;

export const listSchools = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        objVer
        name
        isCatholic
        isPublic
        isUseSchool
        gradeFrom
        gradeEnd
        isElementary
        isMiddle
        isHigh
        isEnglish
        isFrenchImmersion
        isExtendedFrench
        isAP
        isArts
        isGifted
        isIB
        isSport
        g3Avg
        g3WeightedAvg
        g6Avg
        g6WeightedAvg
        g9Avg
        g9APMWeightedAvg
        g9ACMWeightedAvg
        g10Avg
        ettieOverallAvg
        g3Rank
        g3RankTotal
        g6Rank
        g6RankTotal
        g9Rank
        g9RankTotal
        g9ACRank
        g9ACTotalRank
        g9APRank
        g9APTotalRank
        g10Rank
        g10RankTotal
        EQAOScore
        yearMark
        province
        nation
        url
        schoolBoardId
        address
        city
        postalCode
        phone
        fax
        latitude
        longitude
        comments
        boundaryArray
        schoolsAssocationArray
        lastReviewDate
        id
        createdAt
        updatedAt
        schoolBoardSchoolsId
        ratings {
          items {
            objVer
            schoolId
            yearMark
            year
            G3STU
            G3AR
            G3AW
            G3AM
            G3Rank
            G3Total
            G3FindSchoolRating
            G6STU
            G6AR
            G6AW
            G6AM
            G6Rank
            G6Total
            G6FindSchoolRating
            G9ACSTU
            G9ACM
            G9ACRank
            G9ACTotal
            G9APSTU
            G9APM
            G9APRank
            G9APTotal
            G9Rank
            G9Total
            G9FindSchoolRating
            G10OSSLTFSTUA
            G10OSSLTFSTUP
            G10OSSLTFSUCCESS
            G3Last5Avg
            G6Last5Avg
            G9Last5Avg
            id
            createdAt
            updatedAt
            schoolRatingsId
          }
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
