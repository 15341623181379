import { useDispatch, useSelector } from "react-redux";
import { IonButton } from "@ionic/react";
import numeral from "numeral";
import DetailPageButton from "../../../../../Detail/Sections/LeftSection/components/DetailPageButtons/DetailPageButton/detail-page-button.component";
import MapView from "../../../../../../assets/svg/map-details.svg";
import ImageView from "../../../../../../assets/svg/image-view.svg";
import { videocamOutline } from "ionicons/icons";
import { openModal } from "../../../../../../redux/ui/ui.actions";
import NavigateIcon from "../../../../../../assets/svg/navigate-outline.svg";
import { calculateCashback } from "../../../../../../utils/functions";
import styles from "./pre-con-buttons.module.scss";

const PreConButtons = ({
  project,
  showMap,
  MediaHandler,
  mapViewHandler,
  hasMedia,
  navigateHandler,
}) => {
  const dispatch = useDispatch();

  const info =
    project.name +
    " at " +
    project.streetNumber +
    " " +
    project.streetName +
    ", " +
    project.city +
    ", starting from " +
    numeral(project.startPrice).format("$1,000,000");

  const getCashbackTitle = () => {
    // if (!project.startPrice || +project.startPrice === 0) {
    return "Receive up to 3% cashback!";
    // } else {
    //   return `$${calculateCashback(+project.startPrice, "precon")} cashback!`;
    // }
  };

  const cashbackFormHandler = () => {
    dispatch(
      openModal({
        current: "requestForm",
        overflow: true,
        data: {
          info: info,
          cashback: true,
          isPrecon: true,
        },
      })
    );
  };

  return (
    <div>
      <div className={styles.btns}>
        <DetailPageButton
          icon={videocamOutline}
          label="Media"
          imgheight="42px"
          imgwidth="30.72px"
          onClick={MediaHandler}
          disable={!hasMedia}
          isImage={false}
        />
        <DetailPageButton
          icon={showMap ? ImageView : MapView}
          label={showMap ? "Gallery" : "Map view"}
          imgheight="37.69px"
          imgwidth="42.13px"
          onClick={mapViewHandler}
          isImage={false}
        />
        <DetailPageButton
          icon={NavigateIcon}
          label="Navigate"
          imgheight="37.69px"
          imgwidth="42.13px"
          onClick={navigateHandler}
          isImage={false}
        />
      </div>
    </div>
  );
};

export default PreConButtons;
