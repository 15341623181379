const AMENITIES = [
  { label: "Pool", value: "pool" },
  { label: "Gym", value: "gym" },
  { label: "Pet wash", value: "petWash" },
  { label: "Dog park", value: "dogPark" },
  { label: "Concierge", value: "concierge" },
  { label: "Party room", value: "partyRoom" },
  { label: "Flex room", value: "flexRoom" },
];

const TYPES = [
  { label: "Condo", value: "condo" },
  { label: "Townhouse", value: "townhouse" },
  { label: "Single Family Residence", value: "single" },
];

export { AMENITIES, TYPES };
