import { useState, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import jsonObj from "../../../../package.json";
import Footer from "../../../components/Footer/footer.component";
import CardContainer from "../../Tours/component/CardContainer/card-container.component";
import Input from "../../../components/Form/Input/input.component";
import TextArea from "../../../components/Form/TextArea/textarea.component";
import styles from "./feedback.module.scss";
import { sendFeedback } from "../../../api/ettie";
import { updateToast } from "../../../redux/ui/ui.actions";
import Button from "../../../components/Form/Button/button.component";

const Feedback = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const client = useSelector(selectCurrentClient);

  const inputRefs = useRef({
    email: createRef(),
    given_name: createRef(),
    family_name: createRef(),
    phone: createRef(),
    os: createRef(),
    device: createRef(),
  });

  const [formData, setFormData] = useState({
    email: client ? client.email : "",
    given_name: client ? client.givenName : "",
    family_name: client ? client.familyName : "",
    phone: "",
    os: "",
    device: "",
    message: "",
  });

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }
    if (isValid) {
      setLoading(true);
      try {
        await sendFeedback({
          name: `${formData.given_name} ${formData.family_name}`,
          email: formData.email,
          message: formData.message,
          os: formData.os,
          device: formData.device,
          version: jsonObj.version,
        });
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: "Message sent successfully.",
          })
        );
      } catch (err) {
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong!",
          })
        );
      }
      setLoading(false);
    } else {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message:
            "An entry is required or has an invalid value. Please correct and try again.",
        })
      );
    }
  };

  return (
    <div className={styles.feedback}>
      <div className={styles.cards}>
        <CardContainer>
          <IonGrid className={styles.content}>
            <IonRow>
              <IonCol className={styles.title}>
                Hello <span>{client ? client["givenName"] : "there"}!</span>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className={styles.subtitle}>
                We highly appreciate all your feeback on AECORN's experience.
                Whether you have comments, suggestions for improvement or have
                identified a bug in the software, we would love to hear about
                it. We are dedicated to continuously improving our software,
                both technically and through user experience.
              </IonCol>
            </IonRow>

            <IonRow className={styles.version}>
              <IonCol>App version</IonCol>
              <IonCol className={styles.versionNumber}>
                {jsonObj.version}
              </IonCol>
            </IonRow>
          </IonGrid>
        </CardContainer>
        <CardContainer>
          <IonGrid>
            <IonRow className={styles.form}>
              <IonCol>
                <div className={styles.formlabel}>Submit feedback</div>
                <div className={styles.formsubtitle}>
                  Send us a message or an email, and we will get back to you
                  within 24 hours.
                </div>
                <div className={styles.inputs}>
                  <IonRow className={styles.input}>
                    <IonCol>
                      <Input
                        name="given_name"
                        label="given name"
                        placeholder="given name"
                        required
                        ref={inputRefs.current.given_name}
                        value={formData["given_name"]}
                        defaultValue={client && client.givenName}
                        onChange={(value, name) => handleChange(value, name)}
                        validation="required|min:2"
                        isApp
                        enterkeyhint="next"
                        submit={() =>
                          inputRefs.current.family_name.current.next()
                        }
                      />
                    </IonCol>
                    <IonCol>
                      <Input
                        name="family_name"
                        label="family name"
                        placeholder="family name"
                        required
                        ref={inputRefs.current.family_name}
                        value={formData["family_name"]}
                        defaultValue={client && client.familyName}
                        onChange={(value, name) => handleChange(value, name)}
                        validation="required|min:2"
                        isApp
                        enterkeyhint="next"
                        submit={() => inputRefs.current.email.current.next()}
                      />
                    </IonCol>
                  </IonRow>

                  <IonRow className={styles.input}>
                    <IonCol>
                      <Input
                        name="email"
                        label="email"
                        placeholder="email"
                        mode="email"
                        required
                        ref={inputRefs.current.email}
                        value={formData["email"]}
                        defaultValue={client && client.email}
                        onChange={(value, name) => handleChange(value, name)}
                        validation="required|email"
                        isApp
                        enterkeyhint="next"
                        submit={() => inputRefs.current.password.current.next()}
                      />
                    </IonCol>
                    <IonCol>
                      <Input
                        name="phone"
                        label="phone"
                        placeholder="phone"
                        mode="tel"
                        required
                        ref={inputRefs.current.phone}
                        value={formData["phone"]}
                        onChange={(value, name) => handleChange(value, name)}
                        validation="required|phone"
                        isApp
                      />
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <Input
                        name="device"
                        label="Browser used"
                        placeholder="Browser used"
                        required
                        ref={inputRefs.current.device}
                        value={formData["device"]}
                        onChange={(value, name) => handleChange(value, name)}
                        validation="required|min:2"
                        isApp
                        enterkeyhint="go"
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <Input
                        name="os"
                        label="Operating system"
                        placeholder="OS used"
                        required
                        ref={inputRefs.current.os}
                        value={formData["os"]}
                        onChange={(value, name) => handleChange(value, name)}
                        validation="required|min:2"
                        isApp
                        enterkeyhint="go"
                      />
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <TextArea
                        value={formData.message}
                        onChange={(val) =>
                          setFormData({ ...formData, message: val })
                        }
                        label="Feedback details"
                        placeholder="Feedback details"
                        required
                      />
                    </IonCol>
                  </IonRow>
                </div>
                <IonRow>
                  <IonCol>
                    <Button
                      expand="block"
                      shape="round"
                      type="green"
                      disabled={
                        !formData.email ||
                        !formData.given_name ||
                        !formData.family_name ||
                        !formData.message ||
                        !formData.phone ||
                        !formData.os ||
                        !formData.device
                      }
                      style={{
                        height: "5rem",
                        width: "100%",
                        border:
                          !formData.email ||
                          !formData.given_name ||
                          !formData.family_name ||
                          !formData.message ||
                          !formData.phone ||
                          !formData.os ||
                          !formData.device
                            ? "1px solid #e5e5e5"
                            : "1px solid #1F7A60",
                      }}
                      loading={loading}
                      onClick={handleSubmit}
                      title="Submit"
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </CardContainer>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Feedback;
