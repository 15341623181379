import styles from "./listing-images.module.scss";
import {
  IonList,
  IonImg,
  IonModal,
  IonButton,
  IonIcon,
  createGesture,
  IonSpinner,
} from "@ionic/react";
import { useState, useRef, useEffect } from "react";
import DefaultImage from "../../../../../assets/img/no-photo.png";
import { closeOutline } from "ionicons/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

const IMAGE_API = "https://cdn.repliers.io";

const ListingImages = ({ images, imagesRef }) => {
  const [imgsLoaded, setImgsLoaded] = useState(false);
  // const [orientation, setOrientation] = useState(
  //   ScreenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY
  // );
  const [showPhotosEnd, setShowPhotosEnd] = useState(false);
  const [modal, setModal] = useState(undefined);
  const slidesModalRef = useRef();
  const slidesRef = useRef();
  const containerRef = useRef();
  const listRef = useRef();
  const handleError = (e) => (e.target.hidden = true);
  const SLIDES_OPTIONS = {
    spaceBetween: 20,
    slidesPerView: 1.2,
    initialSlide: 0,
    centeredSlides: true,
    loop: true,
  };

  const handleDoubleTap = async (e) => {
    try {
      const swiper = await slidesRef.current.getSwiper();
      if (swiper.zoom.currentScale > 1) {
        swiper.zoom.out();
      } else {
        swiper.zoom.in();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleLoad = async () => {
    try {
      const swiper = await slidesRef.current.getSwiper();
      swiper.slideTo(modal + 1);
    } catch (err) {
      console.log(err);
    }
  };


  const handleDrag = (e) => {
    const gesture = createGesture({
      el: e.target,
      gestureName: "my-swipe",
      direction: "y",
      onMove: (event) => {
        if (event.deltaY > 125 || event.deltaY < -125) {
          e.target.style.opacity = 0.35;
          e.target.style.transform = `scale(0.85) translateY(${event.deltaY}px)`;
        } else if (event.deltaY > 100 || event.deltaY < -100) {
          e.target.style.opacity = 0.5;
          e.target.style.transform = `scale(0.90) translateY(${event.deltaY}px)`;
        } else if (event.deltaY > 50 || event.deltaY < -50) {
          e.target.style.opacity = 0.7;
          e.target.style.transform = `scale(0.95) translateY(${event.deltaY}px)`;
        } else {
          e.target.style.opacity = 0.8;
          e.target.style.transform = `scale(1) translateY(${event.deltaY}px)`;
        }

        if (event.deltaY > 150 || event.deltaY < -150) {
          setModal(undefined);
          return;
        }
      },

      onEnd: (event) => {
        if (event.deltaY < 150 || event.deltaY < -150) {
          e.target.style.transform = "";
          e.target.style.opacity = 1;
        }
      },
    });
    gesture.enable();
  };

  useEffect(() => {
    let isSubscribed = true;
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        loadImg.onload = () => resolve(image);
        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(images.map((img) => loadImage(`${IMAGE_API}/${img}?class=medium`)))
      .then(() => {
        if (isSubscribed) {
          setImgsLoaded(true);
          setTimeout(() => {
            setShowPhotosEnd(true);
          }, 1500);
        }
      })
      .catch((err) => console.log("Failed to load images.", err));

    return () => (isSubscribed = false);
  }, [images]);

  return (
    <div
      className={`${styles.images} ${
        images && images.length > 0 && styles.purpleBackground
      }`}
      tabIndex="-1"
      ref={imagesRef}
      onMouseEnter={() => imagesRef.current.focus()}
      onTouchMove={() => imagesRef.current.focus()}
      onTouchStart={() => imagesRef.current.focus()}
    >
      <IonModal
        className={styles.modal}
        ref={slidesModalRef}
        isOpen={modal !== undefined}
        // onDidPresent={() => {
        //   if (getPlatforms().includes("capacitor")) {
        //     ScreenOrientation.unlock();
        //   }
        // }}
        // onDidDismiss={() => {
        //   setOrientation(ScreenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY);
        //   setModal(undefined);
        //   if (getPlatforms().includes("capacitor")) {
        //     ScreenOrientation.lock(
        //       ScreenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY
        //     );
        //   }
        // }}
        cssClass="modal"
        animated={false}
        backdropDismiss={true}
      >
        {modal && (
          <div className={styles.container} ref={containerRef}>
            <Swiper
              options={SLIDES_OPTIONS}
              className={styles.slides}
              ref={slidesRef}
              onDoubleTap={handleDoubleTap}
              onLoad={handleLoad}
            >
              {!images || images.length === 0 ? (
                <SwiperSlide>
                  <IonImg src={DefaultImage} className={styles.slide} />
                </SwiperSlide>
              ) : (
                images.map((img, i) => (
                  <SwiperSlide key={i} className={styles.slide}>
                    <img
                      key={i}
                      src={`${IMAGE_API}/${img}?class=medium`}
                      onError={handleError}
                      alt="Listing"
                      onClick={(e) => e.stopPropagation()}
                      onLoad={handleDrag}
                    />
                  </SwiperSlide>
                ))
              )}
            </Swiper>
            <div className={styles.closeContainer}>
              <IonButton
                fill="outline"
                shape="round"
                // className={
                //   orientation
                //     ? orientation.includes("portrait")
                //       ? styles.btn
                //       : styles.landscapeBtn
                //     : styles.btn
                // }
                className={styles.btn}
                // onClick={() => {
                //   setOrientation(
                //     ScreenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY
                //   );
                //   if (getPlatforms().includes("capacitor")) {
                //     ScreenOrientation.lock(
                //       ScreenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY
                //     );
                //   }
                //   setModal(undefined);
                // }}
                onClick={() => setModal(undefined)}
              >
                <IonIcon icon={closeOutline} />
                close
              </IonButton>
            </div>
          </div>
        )}
      </IonModal>
      {!imgsLoaded && <IonSpinner name="dots" className={styles.spinner} />}
      <IonList className={styles.list} ref={listRef}>
        {!images || images.length === 0 ? (
          <div className={styles.defaultContainer}>
            <IonImg src={DefaultImage} className={styles.default} />
          </div>
        ) : (
          <>
            {imgsLoaded &&
              images.map((img, i) => (
                <IonImg
                  key={i}
                  src={`${IMAGE_API}/${img}?class=medium`}
                  defaultValue={DefaultImage}
                  onIonImgDidLoad={({ target }) =>
                    (target.style.visibility = "visible")
                  }
                  onIonError={handleError}
                  onClick={() => {
                    // setOrientation(
                    //   ScreenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY
                    // );
                    // if (getPlatforms().includes("capacitor"))
                    //   ScreenOrientation.lock(
                    //     ScreenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY
                    //   );
                    setModal(i + 1);
                  }}
                  className={styles.img}
                  style={{ "--animation-order": i }}
                />
              ))}
            {showPhotosEnd && <div className={styles.end}>Done photos</div>}
          </>
        )}
      </IonList>
    </div>
  );
};

export default ListingImages;
