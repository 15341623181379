import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectIsAgent,
} from "../../../redux/user/user.selectors";
import AgentTourDetailsComponent from "../AgentTourDetails/agent-tour-details.component";
import ClientTourDetailsComponent from "../ClientTourDetails/client-tour-details.component";
import { Redirect, useParams } from "react-router";
import {  IonPage } from "@ionic/react";
import { useRef } from "react";

const TourItemDetails = () => {
  const ref = useRef();
  const params = useParams();
  const currentUser = useSelector(selectCurrentUser);
  const isAgent = useSelector(selectIsAgent);

  if (!currentUser) {
    return <Redirect to="/tabs/tours" />;
  }

  return (
    <IonPage ref={ref}>
        {isAgent ? (
          <AgentTourDetailsComponent
            tourId={params.tourId}
            tourItemId={params.tourItemId}
          />
        ) : (
          <ClientTourDetailsComponent
            tourId={params.tourId}
            tourItemId={params.tourItemId}
            pageRef={ref}
          />
        )}
    </IonPage>
  );
};
export default TourItemDetails;
