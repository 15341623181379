import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonTextarea,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonSpinner,
  IonModal,
} from "@ionic/react";
import CustomModalHeader from "../../../../CustomModalHeader/custom-modal-header.component";
import { fetchClientStart } from "../../../../../../redux/client/client.actions";
import styles from "./confirm-registration.module.scss";
import Welcome from "../../../../CustomModals/Welcome/welcome.component";
import { updateToast } from "../../../../../../redux/ui/ui.actions";
import { signOutStart } from "../../../../../../redux/user/user.actions";
import { ProfileService } from "../../../../../../services/profileService";

const ConfirmRegistration = ({
  client,
  confirmRegistration,
  setConfirmRegistration,
  close,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);
  const [types, setTypes] = useState({
    sale: false,
    lease: false,
    looking: false,
    preConstruction: false,
  });
  const [realtor, setRealtor] = useState(false);
  const text =
    "Terms of Use Agreement: By accessing any of the websites or mobile applications (collectively, hereinafter “website” or “websites”) operated by The Canadian Real Estate Association (CREA), including REALTOR.ca, you, the user, agree to be bound by all of the terms for use and agree these terms constitute a binding contract between the user and CREA. These Terms of Use apply to all users, except to the extent that CREA has developed specific policies for member real estate Boards, Associations and REALTOR® members. Those organizations and persons should also refer to the applicable policies of CREA’s Technology Committee.";

  const [showWelcome, setShowWelcome] = useState(false);

  const handleSubmit = async () => {
    if (!agree) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message:
            "Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy.",
        })
      );
    } else {
      setLoading(true);
      const profile = {
        buyingSelling: types.sale,
        leasingRenting: types.lease,
        justLooking: types.looking,
        preConstruction: types.preConstruction,
        isRealtor: realtor,

      };
      try {
        await ProfileService.createProfile(profile);
        dispatch(fetchClientStart());
        setTimeout(() => {
          setLoading(false);
          setShowWelcome(true);
        }, 1000);
      } catch (err) {
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong! Please try again.",
          })
        );
      }
    }
  };

  return (
    <>
      {showWelcome && (
        <IonModal
          isOpen={showWelcome}
          onDidDismiss={() => setShowWelcome(false)}
        >
          <Welcome
            setConfirmRegistration={setConfirmRegistration}
            setShowModal={setShowWelcome}
          />
        </IonModal>
      )}
      <CustomModalHeader
        title="Register"
        setShowModal={setConfirmRegistration}
        disable={true}
      />
      <IonContent>
        <IonGrid className={styles.signupOptions}>
          <IonRow>
            <IonCol className={styles.title}>Almost there!</IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.subtitle}>
              Submit this form to receive your account confirmation email.
            </IonCol>
          </IonRow>
          <div className={styles.line} />
          <IonRow>
            <IonCol className={styles.label}>Terms and conditions</IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.textareaCol}>
              <IonTextarea
                className={styles.textarea}
                // disabled
                readonly
                rows={6}
                value={text}
              ></IonTextarea>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.agree}>
              <IonItem className={styles.lastOption}>
                <IonCheckbox
                  value="agreeTerms"
                  checked={agree}
                  onIonChange={() => setAgree(!agree)}
                />
                <IonLabel>
                  I agree to the <Link to="/terms">Terms of Use</Link>/
                  <Link to="/privacy">Privacy Policy</Link>*
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <div className={styles.line} />
          <IonRow>
            <IonCol className={styles.label}>I am:</IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.option}>
              <IonItem>
                <IonCheckbox
                  value="agreeTerms"
                  checked={types.sale}
                  onClick={() => setTypes({ ...types, sale: !types.sale })}
                />
                <IonLabel> Buying or selling</IonLabel>
              </IonItem>
              <IonItem>
                <IonCheckbox
                  value="agreeTerms"
                  checked={types.lease}
                  onClick={() => setTypes({ ...types, lease: !types.lease })}
                />
                <IonLabel> Renting or leasing</IonLabel>
              </IonItem>
              <IonItem>
                <IonCheckbox
                  value="agreeTerms"
                  checked={types.preConstruction}
                  onClick={() =>
                    setTypes({
                      ...types,
                      preConstruction: !types.preConstruction,
                    })
                  }
                />
                <IonLabel> Pre-construction</IonLabel>
              </IonItem>
              <IonItem className={styles.lastOption}>
                <IonCheckbox
                  value="agreeTerms"
                  checked={types.looking}
                  onClick={() =>
                    setTypes({ ...types, looking: !types.looking })
                  }
                />
                <IonLabel> Just looking</IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <div className={styles.line} />
          <IonRow>
            <IonCol className={styles.label}>
              Are you a registered real estate agent?
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.option}>
              <IonItem>
                <IonCheckbox
                  value="agreeTerms"
                  checked={!realtor}
                  onClick={() => setRealtor(false)}
                />
                <IonLabel>Nope!</IonLabel>
              </IonItem>
              <IonItem className={styles.lastOption}>
                <IonCheckbox
                  value="agreeTerms"
                  checked={realtor}
                  onClick={() => setRealtor(true)}
                />
                <IonLabel>Yes, I am a realtor</IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <div className={styles.line} />
          <IonRow>
            <IonCol>
              <IonButton
                shape="round"
                className={styles.button}
                onClick={handleSubmit}
              >
                {loading ? (
                  <span className={styles.btntitle}>
                    <IonSpinner name="crescent" className={styles.spinner} />
                    working on it!
                  </span>
                ) : (
                  <span>Submit</span>
                )}
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div
                className={styles.signOut}
                onClick={() => {
                  dispatch(signOutStart());
                  close();
                }}
              >
                Sign out and continue as a guest
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default ConfirmRegistration;
