import { store } from "../redux/store";
import { getAllProjects, fetchFavouriteItems } from "../amplify/graphql.utils";
import { API } from "aws-amplify";

export const getClusterListings = async (map) => {
  const isSchoolsEnabled = store.getState().schools.active;
  const showOnlyWithinSchoolsBoundaries =
    store.getState().schools.filters.showListingsWithin;
  const schools = store.getState().schools.schools;

  const filters = store.getState().filters.value;
  const preFilters = store.getState().filters.pre;

  const extraFilters = store.getState().filters.extra;

  const body = {
    map,
    isSchoolsEnabled,
    showOnlyWithinSchoolsBoundaries,
    schools,
    filters,
    preFilters,
    extraFilters,
  };

    try {
      const response = await API.post("ettieREST", "/listings/cluster", {
        body,
      });
      const listings = response.data.listings;

      return listings;
    } catch (error) {
      return new Error(error.message);
    }
}

export const getMapListings = async (term, token, isHomepage = false) => {
  const isSchoolsEnabled = store.getState().schools.active;
  const showOnlyWithinSchoolsBoundaries =
    store.getState().schools.filters.showListingsWithin;
  const schools = store.getState().schools.schools;

  const filters = store.getState().filters.value;
  const preFilters = store.getState().filters.pre;
  const map = store.getState().map?.ref;

  const extraFilters = store.getState().filters.extra;

  const body = {
    requestType: "mapSearch",
    term,
    isHomepage,
    isSchoolsEnabled,
    showOnlyWithinSchoolsBoundaries,
    schools,
    filters,
    preFilters,
    extraFilters,
    zoom: map ? map.zoom : undefined,
  };

    try {
      return await API.post("ettieREST", "/repliers", {
        body,
      });
    } catch (error) {
      return new Error(error.message);
    }
  } 

export const getSimilarListing = async (mlsNumber) => {
  try {
    const response = await API.get("ettieREST", `/listings/${mlsNumber}/similar`);
    return response.data.similar;
  } catch (error) {
    return new Error(error.message);
  }
};

export const getSearchResults = async (term, activeListingsOnly = false) => {
  try {
    const response = await API.get("ettieREST", "/listings/search", {
      queryStringParameters: { term, activeListingsOnly },
    });

    return response.data.listings;
  } catch (error) {
    return new Error(error.message);
  }
};

export const getMarketData = async (type, area, neighborhood, propertyType) => {
  let lastStatus;
  if (type === "Sale") {
    lastStatus = "Sld";
  } else if (type === "Lease") {
    lastStatus = "Lsd";
  } else {
    lastStatus = "Sld";
  }

  try {
    return await API.post("ettieREST", "/repliers", {
      body: {
        requestType: "marketData",
        lastStatus: lastStatus,
        area: area,
        neighborhood: neighborhood,
        propertyType: propertyType,
      },
    });
  } catch (error) {
    return new Error(error.message);
  }
};

export const getLocations = async () => {
  return await API.post("ettieREST", "/repliers", {
    body: { requestType: "locations" },
  });
};

export const getSearchCount = async ({ filters, extra }) => {
  try {
    return await API.post("ettieREST", "/repliers", {
      body: { requestType: "searchCount", filters, extraFilters: extra },
    });
  } catch (error) {
    return new Error(error.message);
  }
};

export const checkFavouritesUpdates = async () => {
  try {
    const favourites = await fetchFavouriteItems();

    return await API.post("ettieREST", "/repliers", {
      body: { requestType: "updates", favourites },
    });
  } catch (error) {
    console.log(error);
    return new Error(error.message);
  }
};

export const createClient = async (user) => {
  try {
    return await API.post("ettieREST", "/repliers", {
      body: { requestType: "createClient", user },
    });
  } catch (error) {
    return new Error(error.message);
  }
};

export const createSearch = async (clientId, search) => {
  return await API.post("ettieREST", "/repliers", {
    body: { requestType: "createSearch", search, clientId },
  });
};

export const getClientSearchCount = async (clientId) => {
  try {
    return await API.post("ettieREST", "/repliers", {
      body: { requestType: "getClientSearchCount", clientId },
    });
  } catch (error) {
    return new Error(error.message);
  }
};

export const removeSearch = async (searchId) => {
  try {
    await API.post("ettieREST", "/repliers", {
      body: { requestType: "removeSearch", searchId },
    });
  } catch (error) {
    console.log(error);
  }
};
