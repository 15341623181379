import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Card from "../../../components/Card/card.component";
import ListingCard from "../../../components/ListingCard/listing-card.component";
import { selectUINotifications } from "../../../redux/ui/ui.selectors";
import MobileListingCard from "../../../app/components/ListingCard/listing-card.component";
import MobilePreListingCard from "../../../app/components/ListingCard/pre-listing-card.component";
import PreListingCard from "../../../components/ListingCard/pre-listing-card.component";
import { ListingService } from "../../../services/listingService";

const FavoriteItem = ({ item, isPre = false, isApp = false }) => {
  const [latestUpdate, setLatestUpdate] = useState();
  const notification = useSelector(selectUINotifications);

  useEffect(() => {
    let isFetching = true;
    const fetchListing = async () => {
      try {
        const listing = await ListingService.getListingByMlsNumber(item.mlsNumber);
        isFetching && setLatestUpdate(listing);
      } catch (error) {
        console.log(error);
      }
    };
    if (!latestUpdate && !isPre) fetchListing();

    return () => (isFetching = false);
  }, [isPre, item, latestUpdate]);

  const getUpdates = () => {
    if (item) {
      if (notification && notification.favourites) {
        const { favourites } = notification;

        if (Array.isArray(favourites)) {
          const foundItem = favourites.find(
            (fav) => fav.mlsNumber === JSON.parse(item.listing).mlsNumber
          );

          if (foundItem) return foundItem.updates;
        }
        return null;
      }
      return null;
    }
  };

  return isApp ? (
    !isPre ? (
      <MobileListingCard
        listing={latestUpdate ? latestUpdate : JSON.parse(item.listing)}
        updates={getUpdates()}
        expand
      />
    ) : (
      <MobilePreListingCard
        listing={item.project}
        updates={{ isPre: true }}
        route="/favourites"
        isPre
        expand
      />
    )
  ) : (
    <Card animated={true}>
      {!isPre ? (
        <ListingCard
          listing={latestUpdate ? latestUpdate : JSON.parse(item.listing)}
          updates={getUpdates()}
        />
      ) : (
        <PreListingCard listing={item.project} updates={{ isPre: true }} />
      )}
    </Card>
  );
};
export default FavoriteItem;
