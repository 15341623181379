import { useEffect, useState, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSpinner,
  IonModal,
} from "@ionic/react";
import styles from "./sign-in.module.scss";
import FederatedButtons from "../../components/Authentication/components/FederatedButtons/federated-buttons.component";
import Input from "../../../components/Form/Input/input.component";
import {
  clearErrors,
  emailSignInStart,
} from "../../../redux/user/user.actions";
import {
  selectCurrentUser,
  selectUserError,
  selectUserState,
} from "../../../redux/user/user.selectors";
import { updateToast } from "../../../redux/ui/ui.actions";
import ForgotPassword from "../../components/CustomModals/ForgotPassword/forgot-password.component";
import AppHeader from "../../components/Header/header.component";
import CustomAuthHeader from "../../components/Authentication/components/CustomAuthHeader/custom-auth-header.component";
import AppConfirmSignUpModal from "../Signup/component/confirm-sign-up.component";

const AppSignIn = () => {
  const dispatch = useDispatch();


  const ref = useRef();
  const user = useSelector(selectCurrentUser);
  const [loading, setLoading] = useState(false);
  const error = useSelector(selectUserError);
  const state = useSelector(selectUserState);
  const [signinfailure, setSignInFailure] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showForgotPassModal, setShowForgotPassModal] = useState(false);

  const inputRefs = useRef({
    email: createRef(),
    password: createRef(),
  });

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const submitSignIn = () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }
    if (isValid) {
      setLoading(true);
      const { email, password } = formData;

      dispatch(
        emailSignInStart({
          username: email,
          password,
        })
      );
    } else {
      return;
    }
  };

  useEffect(() => {
    if (error) {
      if (error.code === "NotAuthorizedException" && showConfirmModal) {
        setShowConfirmModal(false);
      }
      if (error["code"] === "UserNotConfirmedException") {
        setShowConfirmModal(true);
        dispatch(clearErrors());
      } else {
        setSignInFailure(error);
        if (error.message) {
          const msg =
            error.code === "NotAuthorizedException" ||
            error.code === "UserNotFoundException"
              ? "Invalid email or password."
              : "Something went wrong! Please try again.";
          dispatch(
            updateToast({
              open: true,
              type: "error",
              message: msg,
            })
          );

          // if (msg === "Something went wrong! Please try again.")
          // setTimeout(() => {
          //   history.go(0);
          // }, 1500);
        }
      }
    }

    setLoading(false);
  }, [dispatch, error, showConfirmModal, state]);

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  return user ? (
    <Redirect to="/" />
  ) : (
    <IonPage ref={ref}>
      <AppHeader title="Sign in" hasRightButton={false} backHref="/tabs/home" />
      <IonContent>
        <CustomAuthHeader />
        <IonModal
          presentingElement={ref.current}
          isOpen={showForgotPassModal}
          onDidDismiss={() => {
            dispatch(clearErrors());
            setShowForgotPassModal(false);
          }}>
          <AppHeader
            title="Forgot password"
            hasBackButton={false}
            hasRightButton
            isModal
            onClick={() => setShowForgotPassModal(false)}
          />
          <IonContent>
            <ForgotPassword
              setShowForgotPassModal={setShowForgotPassModal}
              enteredEmail={""}
            />
          </IonContent>
        </IonModal>
        <IonModal
          presentingElement={ref.current}
          isOpen={showConfirmModal}
          onDidDismiss={() => setShowConfirmModal(false)}>
          <AppConfirmSignUpModal
            setShowConfirmModal={setShowConfirmModal}
            username={formData.email}
            password={formData.password}
            signIn
          />
        </IonModal>
        <IonGrid className={styles.signin}>
          {/* <FederatedButtons option="signin" /> */}
          <IonRow>
            <IonCol>
              <div className={styles.option}>or sign in with your email</div>
              <div className={styles.line}></div>
            </IonCol>
          </IonRow>
          <div className={styles.inputs}>
            <IonRow>
              <IonCol>
                <Input
                  name="email"
                  label="email"
                  placeholder="email"
                  mode="email"
                  required
                  ref={inputRefs.current.email}
                  value={formData["email"]}
                  onChange={(value, name) =>
                    handleChange(value.toLowerCase(), name)
                  }
                  validation="required|email"
                  submit={() => inputRefs.current.password.current.next()}
                  isAuth
                  enterkeyhint="next"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Input
                  name="password"
                  label="password"
                  placeholder="password"
                  type="password"
                  required
                  ref={inputRefs.current.password}
                  value={formData["password"]}
                  onChange={(value, name) => handleChange(value, name)}
                  validation="required"
                  submit={submitSignIn}
                  isAuth
                  enterkeyhint="go"
                />
              </IonCol>
            </IonRow>
          </div>
          <IonRow>
            <IonCol
              className={styles.forgotPass}
              onClick={() => setShowForgotPassModal(true)}>
              Forgot password?
            </IonCol>
          </IonRow>
          {error && signinfailure && (
            <IonRow className={styles.error}>
              <IonCol>Invalid email or password</IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol>
              <IonButton
                shape="round"
                className={styles.button}
                onClick={submitSignIn}
                type="submit">
                {loading ? (
                  <span className={styles.title}>
                    <IonSpinner name="crescent" className={styles.spinner} />
                    working on it!
                  </span>
                ) : (
                  <span>Sign in</span>
                )}
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.register}>
              Don’t have an account?
              <Link to="/signup">Create account</Link>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AppSignIn;
