// import { PreConstructionAmenities } from "../../../../../../../../utils/constants";
import styles from "./pre-amenities.module.scss";

const PreAmenities = ({ project }) => {
  const { amenities } = project;

  return (
    <div className={styles.preAmenities}>
      <div className={styles.infos}>
        <div className={styles.details}>
          {amenities.items && amenities.items.length > 0 ? (
            amenities.items.map((item, i) => (
              <div key={i}>{item.amenity.name}</div>
            ))
          ) : (
            <div>Not available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreAmenities;
