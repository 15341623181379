import { IonItem, IonLabel, IonRadio, IonRadioGroup } from "@ionic/react";

const PreRadio = ({ label, value, onChange, items, style, required }) => {
  return (
    <IonItem style={style}>
      <IonLabel>
        {label}
        {required && "*"}
      </IonLabel>
      <IonRadioGroup
        value={value}
        onIonChange={(e) => onChange(e.detail.value)}
      >
        {items.map((item, i) => (
          <IonItem lines="none" key={i}>
            <IonLabel style={{ fontSize: "1.5rem", color: "#363636" }}>
              {item.label}
            </IonLabel>
            <IonRadio slot="end" value={item.value} />
          </IonItem>
        ))}
      </IonRadioGroup>
    </IonItem>
  );
};

export default PreRadio;
