import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./home.module.scss";
import { Helmet } from "react-helmet";
import {
  IonButton,
  IonIcon,
  IonImg,
  IonLabel,
  IonModal,
  IonPage,
  IonRange,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
// import Logo from "../../assets/img/logo.png";
import ToursImage from "../../assets/img/webtour.png";
import SchoolsImage from "../../assets/img/webschools.png";
import app from "../../assets/svg/homepage/app.png";
import signup from "../../assets/svg/homepage/signup.png";
import neighbourhood from "../../assets/svg/homepage/neighbourhood.png";
import tag from "../../assets/svg/homepage/tag.png";
import consultant from "../../assets/svg/homepage/consultant.png";
import saleImage from "../../assets/img/webmap.png";
import leaseImage from "../../assets/img/weblease.png";
import preImage from "../../assets/img/webpreconstruction.png";
import { ReactComponent as GooglePlay } from "../../assets/svg/stores/google.svg";
import { ReactComponent as AppleStore } from "../../assets/svg/stores/apple.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  selectCurrentUser,
} from "../../redux/user/user.selectors";
import { selectCurrentClient } from "../../redux/client/client.selectors";
import {
  selectEttieOffer,
  selectUIAuthModal,
  selectUIModal,
} from "../../redux/ui/ui.selectors";
import CustomAuthContainer from "../../components/Authentication/CustomAuthContainer/custom-auth-container.component";
import {
  closeAuthModal,
  closeModal,
  openAuthModal,
  openModal,
} from "../../redux/ui/ui.actions";
import Modal from "../../components/Modal/modal.component";
import withFilters from "../../HOC/withFilters/with-filters";
import PropertyTypeFilter from "../../components/Filters/components/PropertyTypeFilter/property-type-filter.component";
import PriceFilter from "../../components/Filters/components/PriceFilter/price-filter.component";
import BedsFilter from "../../components/Filters/components/BedsFilter/beds-filter.component";
import { updateFilters } from "../../redux/filters/filters.actions";
import Button from "../../components/Form/Button/button.component";
import moment from "moment";
import {
  arrowForwardOutline,
  chevronDownOutline,
  chevronUpOutline,
  mapOutline,
} from "ionicons/icons";
import CardContainer from "../Tours/component/CardContainer/card-container.component";
import LocationSearch from "../../components/LocationSearch/location-search.component";
import { getHomeItems } from "../../amplify/graphql.utils";
import HomeCard from "../../app/pages/Home/components/home-card.component";
import QuickSearches from "./components/quick-searches.component";
import Footer from "../../components/Footer/footer.component";
import { defaultFilters } from "../../components/Filters/data/filters.data";
import DropdownContainer from "../../components/Form/DropdownContainer/dropdown-container.component";
import useComponentVisible from "../../hooks/useComponentVisible";
import ProfileDropdown from "../../components/ProfileDropdown/profile-dropdown.component";
import { numberWithCommas, calculateCashback } from "../../utils/functions";
import TextAnimation from "react-text-animations";
import LogoWithText from "../../components/Logo/LogoWithText";

const PropertyTypesWithFilters = withFilters(PropertyTypeFilter);
const PriceWithFilters = withFilters(PriceFilter);
const BedsWithFilters = withFilters(BedsFilter);

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(selectCurrentUser);
  const client = useSelector(selectCurrentClient);
  const authModal = useSelector(selectUIAuthModal);
  const modal = useSelector(selectUIModal);
  const [cards, setCards] = useState([]);
  const [type, setType] = useState("sale");
  const { ref, isOpen, setIsOpen } = useComponentVisible(false);
  const [budget, setBudget] = useState(numberWithCommas(1500000));
  const [savings, setSavings] = useState();
  const offer = useSelector(selectEttieOffer);

  useEffect(() => {
    if (user) dispatch(closeAuthModal());
  }, [dispatch, user]);

  useEffect(() => {
    if (
      client &&
      !client.profile &&
      user &&
      !user.signInUserSession.accessToken.payload["cognito:groups"].includes(
        "Agents"
      )
    )
      dispatch(openModal({ current: "confirmRegistration", closable: false }));
  }, [client, dispatch, user]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { cards } = await getHomeItems();

        setCards(cards);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, [user]);

  const handleBtn = (type) => {
    if (type === "sale") {
      dispatch(
        updateFilters({
          ...defaultFilters.value,
          type: "sale",
          status: "A",
          lastStatus: null,
          displayPublic: "Y",
          displayAddressOnInternet: "Y",
          minListDate: null,
          minSoldDate: null,
          maxSoldDate: null,
          resultsPerPage: 2500,
          sortBy: "createdOnDesc",
        })
      );
      history.push("/listings");
    } else if (type === "lease") {
      dispatch(
        updateFilters({
          ...defaultFilters.value,
          type: "lease",
          status: "A",
          lastStatus: null,
          displayPublic: "Y",
          displayAddressOnInternet: "Y",
          minListDate: null,
          minSoldDate: null,
          maxSoldDate: null,
          resultsPerPage: 2500,
          sortBy: "createdOnDesc",
        })
      );
      history.push("/listings");
    } else {
      dispatch(
        updateFilters({
          ...defaultFilters.value,
          type: "pre-construction",
          status: "A",
          lastStatus: null,
          displayPublic: "Y",
          displayAddressOnInternet: "Y",
          minListDate: null,
          minSoldDate: null,
          maxSoldDate: null,
          resultsPerPage: 2500,
          sortBy: "createdOnDesc",
        })
      );
      history.push("/listings");
    }
  };

  const handleTypeChange = ({ detail: { value } }) => {
    switch (value) {
      case "sale":
        dispatch(
          updateFilters({
            ...defaultFilters.value,
            type: "sale",
            status: "A",
            lastStatus: null,
            displayPublic: "Y",
            displayAddressOnInternet: "Y",
            minListDate: null,
            minSoldDate: null,
            maxSoldDate: null,
            resultsPerPage: 2500,
            sortBy: "createdOnDesc",
          })
        );
        setType(value);
        break;
      case "sold":
        user
          ? dispatch(
              updateFilters({
                ...defaultFilters.value,
                type: "sale",
                status: "U",
                lastStatus: "Sld",
                displayPublic: null,
                displayAddressOnInternet: null,
                minListDate: null,
                maxSoldDate: null,
                minSoldDate: moment().subtract(90, "days").format("YYYY-MM-DD"),
                resultsPerPage: 2500,
                sortBy: "createdOnDesc",
              })
            )
          : dispatch(openModal({ current: "join" }));
        user && setType(value);
        break;
      case "de-listed":
        user
          ? dispatch(
              updateFilters({
                ...defaultFilters.value,
                type: "sale",
                status: "U",
                lastStatus: ["Sus", "Exp", "Ter"],
                displayPublic: null,
                displayAddressOnInternet: null,
                minListDate: null,
                maxSoldDate: null,
                minSoldDate: null,
                resultsPerPage: 250,
                sortBy: "updatedOnDesc",
              })
            )
          : dispatch(openModal({ current: "join" }));
        user && setType(value);
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    const getSaving = async () => {
      const result = await calculateCashback(+budget.replace(/,/g, ""), "sale");
      setSavings(result);
    };

    getSaving();
  }, [offer]);

  useEffect(() => {
    const getSaving = async () => {
      const result = await calculateCashback(+budget.replace(/,/g, ""), "sale");
      setSavings(result);
    };

    getSaving();
  }, [budget, offer]);

  const handleBudgetRangeChange = ({ target: { name }, detail: { value } }) => {
    if (name === "budget") {
      setBudget(numberWithCommas(value));
    }
  };

  return (
    <IonPage>
      <Helmet>
        <title>AECORN - Properties in GTA</title>
        <meta
          name="description"
          content="Find real estate listings for sale in GTA. Search MLS real estate listings for homes, condos and properties."
        />
        <meta
          name="keywords"
          content="mls listings, house for sale, mls canada, homes for rent, mls map, mls real estate, homes for sale, condos for sale, homes for sale near me, townhouse for sale, property for sale, apartments for sale, property search, mls online, house prices, duplex for sale, real estate listings, real estate companies, cheap houses for sale, mls house listings, best real estate websites canada, single family homes for sale, mls residential map, detached house for sale, home listings, sold home prices, property listings, sold mls listings, house sale prices"
        />
      </Helmet>
      {modal.isOpen && (
        <Modal
          type={modal.current}
          setType={(type, closable) =>
            dispatch(
              openModal({
                ...modal,
                current: type,
                closable: closable ? closable : modal.closable,
              })
            )
          }
          overflow={modal.overflow}
          closable={modal.closable}
          data={modal.data}
          isProtected={modal.isProtected}
          setData={(data) => dispatch(openModal({ ...modal, data }))}
          close={() => dispatch(closeModal())}
        />
      )}

      {!user && location.pathname === "/" && (
        <IonModal
          isOpen={authModal.isOpen}
          cssClass={styles.modal}
          backdropDismiss={false}>
          <CustomAuthContainer
            type={authModal.current}
            setType={(current) => dispatch(openAuthModal({ current }))}
            closeModal={(current) => dispatch(closeAuthModal())}
          />
        </IonModal>
      )}
      <div className={styles.container}>
        <div className={styles.header}>
          <Link to="/">
            <div className={styles.logo}>
              <LogoWithText />
            </div>
          </Link>

          <div className={styles.navbar}>
            <ul>
              <li>
                <Link to="/listings">map</Link>
              </li>
              <li>
                {user ? (
                  <Link to="/favourites">favourites</Link>
                ) : (
                  <span
                    onClick={() => dispatch(openModal({ current: "join" }))}>
                    favourites
                  </span>
                )}
              </li>
              <li>
                {user ? (
                  <Link to="/searches">saved search</Link>
                ) : (
                  <span
                    onClick={() => dispatch(openModal({ current: "join" }))}>
                    saved search
                  </span>
                )}
              </li>
              <li>
                {user ? (
                  <Link to="/tours">my tours</Link>
                ) : (
                  <span
                    onClick={() => dispatch(openModal({ current: "join" }))}>
                    my tours
                  </span>
                )}
              </li>
              <li>
                <span
                  ref={ref}
                  className={styles.more}
                  onClick={() => setIsOpen(!isOpen)}>
                  <div className={styles.btnContainer}>
                    <span className={styles.btn}>more</span>
                    <IonIcon
                      icon={isOpen ? chevronUpOutline : chevronDownOutline}
                      className={styles.icon}
                    />
                  </div>

                  <DropdownContainer
                    open={isOpen}
                    style={{
                      width: "15rem",
                      backgroundColor: "white",
                      padding: "1rem",
                      boxShadow: "none",
                    }}>
                    <div className={styles.item}>
                      <Link to="/about-us">about us</Link>
                    </div>
                    <div className={styles.item}>
                      <Link to="/market-data">market data</Link>
                    </div>
                    <div className={styles.item}>
                      <Link to="/feedback">feedback</Link>
                    </div>
                    <div className={styles.item}>
                      <Link to="/contact-us">contact</Link>
                    </div>
                  </DropdownContainer>
                </span>
              </li>
            </ul>
            <div className={styles.auth}>
              {!user ? (
                <>
                  <div
                    // fill="clear"
                    className={styles.signIn}
                    onClick={() =>
                      dispatch(openAuthModal({ current: "login" }))
                    }>
                    Sign in
                  </div>
                  <div>
                    <IonButton
                      className={styles.register}
                      fill="clear"
                      onClick={() =>
                        dispatch(openAuthModal({ current: "register" }))
                      }>
                      Register
                    </IonButton>
                  </div>
                </>
              ) : ((
                  <ProfileDropdown
                    givenName={user.attributes["given_name"]}
                    familyName={user.attributes["family_name"]}
                    isHomepage={true}
                  />
                )
              )}
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.title}>
              <span> Let's get</span> started!
            </div>
            <div className={styles.search}>
              <div className={styles.input}>
                <LocationSearch isHomepage disabled={false} />
              </div>
              <div className={styles.filters}>
                <div className={styles.status}>
                  <IonSegment
                    className={styles.segment}
                    id="segment"
                    value={type}
                    onIonChange={handleTypeChange}>
                    <IonSegmentButton
                      className={styles.segmentBtn}
                      value="sale">
                      Sale
                    </IonSegmentButton>
                    <IonSegmentButton
                      className={styles.segmentBtn}
                      value="sold">
                      Sold
                    </IonSegmentButton>
                    <IonSegmentButton
                      className={styles.segmentBtn}
                      value="de-listed">
                      De-listed
                    </IonSegmentButton>
                  </IonSegment>
                </div>
                <div className={styles.extra}>
                  <div className={styles.item}>
                    <PropertyTypesWithFilters isHomepage />
                  </div>
                  <div className={styles.item}>
                    <PriceWithFilters isHomepage />
                  </div>
                  <div className={styles.item}>
                    <BedsWithFilters isHomepage />
                  </div>
                </div>
              </div>
            </div>
            <Link to="/listings">
              <div className={styles.mapBtn}>
                <IonIcon icon={mapOutline} className={styles.mapIcon} />
                Go to map
              </div>
            </Link>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.headersubtitle}>
            <span onClick={() => history.push("/contact-us")}>
              Get access to our exclusive network of service experts by working
              with a AECORN agent
              <IonIcon icon={arrowForwardOutline} className={styles.icon} />
            </span>
          </div>

          <div className={styles.cardsContainer}>
            <div className={styles.content}>
              <div className={styles.note}>
                <div>
                  AECORN is a full-service real estate brokerage that can guide
                  you through <span>the entire process</span>– door to door.
                  That’s why we’ve developed a search platform with specialized
                  features available exclusively through AECORN Realty.
                </div>
              </div>
              <div className={styles.cards}>
                <CardContainer style={{ margin: "1rem", height: "45rem" }}>
                  <div className={styles.content}>
                    <IonImg className={styles.img} src={saleImage} />
                    <div className={styles.title}>Buying and selling</div>
                    <div className={styles.text}>
                      We make property searching easy, whether you’re browsing
                      or ready to make the sale.
                    </div>
                    <div className={styles.btn}>
                      <Button
                        border
                        type="gray"
                        title="Hunt for homes"
                        style={{
                          minWidth: "25rem",
                          height: "5rem",
                          fontSize: "1.8rem",
                          padding: "1.5rem 3rem ",
                          fontWeight: "300",
                        }}
                        onClick={() => handleBtn("sale")}
                      />
                    </div>
                  </div>
                </CardContainer>
                <CardContainer style={{ margin: "1rem", height: "45rem" }}>
                  <div className={styles.content}>
                    <IonImg className={styles.img} src={leaseImage} />
                    <div className={styles.title}>Leasing</div>
                    <div className={styles.text}>
                      Not looking to buy? No problem! Search, save and tour all
                      leasing opportunties with ease!
                    </div>
                    <div className={styles.btn}>
                      <Button
                        border
                        type="gray"
                        title="Find your lease"
                        style={{
                          minWidth: "30rem",
                          height: "5rem",
                          fontSize: "1.8rem",
                          padding: "1.5rem 3rem ",
                          fontWeight: "300",
                        }}
                        onClick={() => handleBtn("lease")}
                      />
                    </div>
                  </div>
                </CardContainer>
                <CardContainer style={{ margin: "1rem", height: "45rem" }}>
                  <div className={styles.content}>
                    <IonImg className={styles.img} src={preImage} />
                    <div className={styles.title}>Pre-construction</div>
                    <div className={styles.text}>
                      Looking for a great investment opportunity? Check out
                      AECORN Realty's exclusive Pre-construction program!
                    </div>
                    <div className={styles.btn}>
                      <Button
                        border
                        type="gray"
                        title={"Search for Precon"}
                        style={{
                          minWidth: "25rem",
                          height: "5rem",
                          fontSize: "1.8rem",
                          padding: "1.5rem 3rem ",
                          fontWeight: "300",
                        }}
                        onClick={() => handleBtn("pre-construction")}
                      />
                    </div>
                  </div>
                </CardContainer>
              </div>
            </div>
          </div>
          <div className={styles.tours}>
            <div className={styles.toursContent}>
              <div className={styles.image}>
                <img
                  alt="AECORN Tours Feature"
                  className={styles.ionImg}
                  src={ToursImage}
                />
              </div>
              <div className={styles.info}>
                <div className={styles.title}>
                  Showing details, directions and tools all updated live.
                </div>
                <div className={styles.text}>
                  AECORN's exclusive tour features allow agents and clients to
                  seamlessly arrange showings, invite guests, and take photos,
                  notes and voice recordings to compare properties.
                </div>
              </div>
            </div>
          </div>
          {/* <div className={styles.homeCards}>
            <div className={styles.homeCardsContent}>
              {cards.length > 0 &&
                cards
                  .sort((a, b) => {
                    if (a.order > b.order) return 1;
                    if (a.order < b.order) return -1;
                    return 0;
                  })
                  .map((c, i) => (
                    <div key={i} className={styles.homeCard}>
                      <HomeCard item={c} />
                    </div>
                  ))}
            </div>
          </div> */}
          {/* <div className={styles.features}>
            <div className={styles.featuresContent}>
              {" "}
              <div className={styles.title}>
                <span>
                Thousands of dollars
                </span>
                 back in your pocket.
              </div>
              <div className={styles.subtitle}>
                With Ettie à la carte, leave commission bargaining in the past
                and experience full control of your transaction. Pay for only
                the services you actually need. The result? Thousands in cash
                back.
              </div>
              <div className={styles.tags}>
                <div className={styles.tag}>
                  <div className={styles.imgContainer}>
                    <img src={signup} alt="Sign up" />
                  </div>
                  <span className={styles.title}>
                    Sign up for free on the Ettie app
                  </span>
                </div>
                <div className={styles.tag}>
                  <div className={styles.imgContainer}>
                    <img src={app} alt="Find properties" />
                  </div>
                  <span className={styles.title}>
                    Go on showings with your Ettie agent
                  </span>
                </div>
                <div className={styles.tag}>
                  <div className={styles.imgContainer}>
                    <img src={neighbourhood} alt="Book" />
                  </div>
                  <span className={styles.title}>Put in an offer</span>
                </div>
                <div className={styles.tag}>
                  <div className={styles.imgContainer}>
                    <img src={tag} alt="Work with an Ettie agent" />
                  </div>
                  <span className={styles.title}>Close your deal</span>
                </div>
                <div className={styles.tag}>
                  <div className={styles.imgContainer}>
                    <img src={consultant} alt="Cashback" />
                  </div>
                  <span className={styles.title}>
                    Receive thousands in cash back!
                  </span>
                </div>
              </div>
              <IonButton
                className={styles.agentBtn}
                fill="outline"
                onClick={() =>
                  dispatch(
                    openModal({
                      current: "requestForm",
                      data: { cashback: true, isHomepage: true },
                    })
                  )
                }
              >
                I want cash back! Tell me more.{" "}
                <IonIcon icon={arrowForwardOutline} />
              </IonButton>

              
            </div>
          </div> */}

          <div className={styles.schools}>
            <div className={styles.schoolsContent}>
              <div className={styles.info}>
                <div className={styles.title}>
                  Get comprehensive school zones and data instantly
                </div>
                <div className={styles.text}>
                  When it comes to finding a new home, AECORN knows how
                  important local schools are. View and search boundaries, set
                  up notifications, and review detailed school ratings, scores
                  and EQAO data.
                </div>
              </div>
              <div className={styles.image}>
                <img
                  alt="AECORN Tours Feature"
                  className={styles.ionImg}
                  src={SchoolsImage}
                />
              </div>
            </div>
          </div>
          <div className={styles.searches}>
            <div className={styles.title}>Popular quick searches</div>
            <QuickSearches />
          </div>
          <Footer />
        </div>
      </div>
    </IonPage>
  );
};

export default Home;
