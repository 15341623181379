import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import RecordingsSection from "../../../../../components/Recordings/component/RecordingsSection/recordings-section.component";
import { selectCurrentClient } from "../../../../../redux/client/client.selectors";
import _ from "lodash";
import styles from "./recordings.module.scss";
import { buildAddress } from "../../../../../utils/functions";

const Recordings = ({
  disabled,
  recordingItems,
  listing,
  handleDelete,
  playing,
  setPlaying,
  tour,
}) => {
  const client = useSelector(selectCurrentClient);
  const [recordings, setRecordings] = useState();

  useEffect(() => {
    const groupRecordings = () =>
      _.mapValues(_.groupBy(recordingItems, (recording) => recording.userId));
    if (recordingItems && client) setRecordings(groupRecordings());
  }, [client, recordingItems]);

  return (
    <div className={styles.recordingDetails}>
      <div className={styles.subtitle}>
        {tour.users.items.find((u) => u.userId === client.id)
          ? `All recordings taken for ${buildAddress(
              listing.address
            )}. by all tour
          attendees.`
          : "Only lead/showing agents can post a photo."}
      </div>

      {tour.users.items.find((u) => u.userId === client.id) && (
        <RecordingsSection
          disabled={disabled}
          handleDelete={handleDelete}
          isCurrentUser={true}
          recordings={recordings ? recordings[client.id] : []}
          memberData={tour.users.items.find((u) => u.userId === client.id)}
          playing={playing}
          setPlaying={setPlaying}
          isApp={true}
          tour={tour}
        />
      )}
      {recordings &&
        Object.keys(recordings).map((key) => {
          if (recordings[key][0].userId === client.id) {
            return null;
          } else {
            return (
              <RecordingsSection
                key={key}
                isCurrentUser={false}
                recordings={recordings[key]}
                memberData={tour.users.items.find(
                  (u) => u.userId === recordings[key][0].userId
                )}
                playing={playing}
                setPlaying={setPlaying}
                isApp={true}
              />
            );
          }
        })}
    </div>
  );
};

export default Recordings;
