import { CurrencyActionTypes } from "./currency.types";

const INITIAL_STATE = {
  rates: {
    AUD: 1,
    CAD: 1,
    CNY: 1,
    EUR: 1,
    GBP: 1,
    INR: 1,
    IRR: 1,
    JPY: 1,
    MXN: 1,
    USD: 1,
  },
};

const mapReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CurrencyActionTypes.FETCH_CURRENCY_START:
      return { ...state };
    case CurrencyActionTypes.FETCH_CURRENCY_SUCCESS:
      return {
        ...state,
        rates: action.payload,
      };
    case CurrencyActionTypes.FETCH_CURRENCY_FAILURE:
      return { ...state };
    default:
      return state;
  }
};

export default mapReducer;
