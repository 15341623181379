import { IonLoading } from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getDesigners } from "../../../../amplify/graphql.utils";
import Card from "../../../../components/Card/card.component";
import { updateToast } from "../../../../redux/ui/ui.actions";
import styles from "./designers.module.scss";
import defaultImage from "../../../../assets/img/no-photo.png";
import Button from "../../../../components/Form/Button/button.component";
import BreadCrumbs from "../../components/breadcrumbs";

const Designers = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [designers, setDesigners] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadBuilder = async () => {
    try {
      const builders = await getDesigners();
      setDesigners(builders);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
      history.replace("/admin");
    } finally {
      setLoading(false);
    }
  };

  props.pageHook(() => {
    loadBuilder();
  });

  useEffect(() => {
    loadBuilder();
  }, []);

  const renderCards = () => {
    return designers.map(({ id, name, logo }) => (
      <Card key={id} onClick={() => history.push(`/admin/designers/${id}`)}>
        <div className={styles.card}>
          <img className={styles.image} src={logo || defaultImage} alt={name} />
          <span className={styles.title}>{name}</span>
        </div>
      </Card>
    ));
  };

  return (
    <div className={styles.container}>
      <BreadCrumbs type="designers" />
      <IonLoading isOpen={loading} />

      {!loading && designers.length > 0 && (
        <div className={styles.add}>
          <Button
            title={"Add new designer"}
            type={"green"}
            border={true}
            onClick={() => history.push("/admin/designers/new")}
          />
        </div>
      )}

      <div className={styles.cards}>
        {!loading ? (
          designers.length ? (
            renderCards()
          ) : (
            <div className={styles.noItem}>
              No designer available
              <Button
                style={{ marginTop: "2rem" }}
                title={"Create"}
                type={"green"}
                onClick={() => history.push("/admin/designers/new")}
              />
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default Designers;
