import { IonIcon } from "@ionic/react";
import { arrowForwardCircleOutline } from "ionicons/icons";
import styles from "./quick-contact.module.scss";

const QuickContact = ({ quickContact: { leadingAgent, showingAgents } }) => {
  return (
    <div className={styles.quickContact}>
      <div>
        <span className={styles.bold}>Leading Agent</span>
        <span>{leadingAgent.user.displayName}</span>
        <span>{leadingAgent.user.phone}</span>
        <span
          className={styles.email}
          onClick={() => window.open(`mailto:${leadingAgent.user.email}`, "_blank")}
        >
          {leadingAgent.user.email} <IonIcon icon={arrowForwardCircleOutline} />
        </span>
      </div>
      {showingAgents.map((showingAgent, index) => {
        return (
          <div key={index}>
            <span className={styles.bold}>Showing Agent</span>
            <span>{showingAgent.user.displayName}</span>
            <span>{showingAgent.user.phone}</span>
            <span
              className={styles.email}
              onClick={() =>
                window.open(`mailto:${showingAgent.user.email}`, "_blank")
              }
            >
              {showingAgent.user.email} <IonIcon icon={arrowForwardCircleOutline} />
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default QuickContact;
