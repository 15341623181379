import { GraphQLQuery } from "@aws-amplify/api";
import { API } from "aws-amplify";
import {
  deleteProfile,
  updateUser,
  deleteUser as deleteUserMutation,
} from "../graphql/mutations";
import { AuthenticationService } from "./authenticationService";
import { getUser, listUsers } from "../graphql/queries";
import { GetUserQuery, ListUsersQuery, UserRole } from "../API";

const getCurrentUser = async () => {
  const cognitoUser = await AuthenticationService.getCognitoUser();
  const response = await API.graphql<GraphQLQuery<GetUserQuery>>({
    query: getUser,
    variables: { id: cognitoUser.username },
  });

  return {
    ...response.data?.getUser,
    isAdmin: response.data?.getUser?.role === "admin",
    isAgent: response.data?.getUser?.role === "agent",
  };
};

const getCurrentUserId = async () => {
  const cognitoUser = await AuthenticationService.getCognitoUser();
  return cognitoUser.username;
};

const updateGivenName = async (name: string) => {
  const updatedUser = await AuthenticationService.updateUserAttribute(
    "given_name",
    name
  );
  await API.graphql({
    query: updateUser,
    variables: { input: { id: updatedUser.username, givenName: name } },
  });
};

const updateFamilyName = async (name: string) => {
  const updatedUser = await AuthenticationService.updateUserAttribute(
    "family_name",
    name
  );
  await API.graphql({
    query: updateUser,
    variables: { input: { id: updatedUser.username, familyName: name } },
  });
};

const updateFullName = async (givenName: string, familyName: string) => {
  const updatedUser = await AuthenticationService.updateUserAttributes({
    given_name: givenName,
    family_name: familyName,
  });
  await API.graphql({
    query: updateUser,
    variables: {
      input: {
        id: updatedUser.username,
        givenName: givenName,
        familyName: familyName,
      },
    },
  });
};

const updateProfileId = async (id: string) => {
  const cognitoUser = await AuthenticationService.getCognitoUser();
  await API.graphql({
    query: updateUser,
    variables: { input: { id: cognitoUser.username, userProfileId: id } },
  });
};

const updateDeviceToken = async (token: string) => {
  const cognitoUser = await AuthenticationService.getCognitoUser();
  await API.graphql({
    query: updateUser,
    variables: { input: { id: cognitoUser.username, deviceToken: token } },
  });
};

const updateTourGeneralNote = async (tourNote: string) => {
  const cognitoUser = await AuthenticationService.getCognitoUser();
  await API.graphql({
    query: updateUser,
    variables: { input: { id: cognitoUser.username, toursNote: tourNote } },
  });
};

const deleteUser = async (password: string) => {
  const user = await AuthenticationService.getCognitoUser();
  await AuthenticationService.login(user.username, password);

  await API.graphql({
    query: deleteUserMutation,
    variables: { input: { id: user.username } },
  });
  await API.graphql({
    query: deleteProfile,
    variables: { input: { id: user.username } },
  });
  await AuthenticationService.deleteAccount();
};

const getAgentUsers = async (searchTerm?: string) => {
  let filter;

  if (searchTerm) {
    filter = {
      role: { eq: UserRole.client },
      and: [
        {
          or: [
            { givenName: { contains: searchTerm } },
            { familyName: { contains: searchTerm } },
            { email: { contains: searchTerm } },
            { name: { contains: searchTerm.toLowerCase() } },
          ],
        },
      ],
    };
  } else {
    filter = { role: { eq: UserRole.agent } };
  }

  const response = await API.graphql<GraphQLQuery<ListUsersQuery>>({
    query: listUsers,
    variables: {
      filter,
    },
  });
  return response.data?.listUsers?.items || [];
};

export const getClientUsers = async (searchTerm?: string) => {
  let filter;

  if (searchTerm) {
    filter = {
      role: { eq: UserRole.client },
      and: [
        {
          or: [
            { givenName: { contains: searchTerm } },
            { familyName: { contains: searchTerm } },
            { email: { contains: searchTerm } },
            { name: { contains: searchTerm.toLowerCase() } },
          ],
        },
      ],
    };
  } else {
    filter = { role: { eq: UserRole.client } };
  }

  const response = await API.graphql<GraphQLQuery<ListUsersQuery>>({
    query: listUsers,
    variables: {
      filter,
    },
  });

  return response.data?.listUsers?.items || [];
};

const getUserById = async (id: string) => {
  const response = await API.graphql<GraphQLQuery<GetUserQuery>>({
    query: getUser,
    variables: { id },
  });
  return response.data?.getUser;
};

export const UserService = {
  getCurrentUser,
  getCurrentUserId,
  updateGivenName,
  updateFamilyName,
  updateFullName,
  updateProfileId,
  updateDeviceToken,
  updateTourGeneralNote,
  deleteUser,
  getAgentUsers,
  getClientUsers,
  getUserById,
};
