export const getAgentTourItem = /* GraphQL */ `query GetTourItem($id: ID!) {
    getTourItem(id: $id) {
      creator
      tourId
      tour {
        title
        creator
        clients
        primaryAgent
        showingAgents
        status
        privateNote
        generalNote
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        id
        users {
            items {
                role
                seen
                id
                userId
                user {
                id
                givenName
                familyName
                name
                displayName
                email
                phone
                __typename
                }
                __typename
            }
            nextToken
            __typename
            }
      }
      clients
      primaryAgent
      showingAgents
      mlsNumber
      status
      startTime
      endTime
      order
      showingNote
      entryInfo
      reviews {
        items {
            id
            rate 
            userId 
            tourItemId
        }
        nextToken
        __typename
      }
      notes {
        items {
            id 
            content 
            userId
            tourItemId
        }
        nextToken
        __typename
      }
      photos {
        items {
            id 
            userId 
            tourItemId 
            path
        }
        nextToken
        __typename
      }
      recordings {
        items {
            id 
            userId 
            tourItemId 
            path
        }
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      tourTourItemsId
      __typename
    }
  }
  `;

export const getClientTourItem = /* GraphQL */ `query GetTourItem($id: ID!) {
  getTourItem(id: $id) {
    creator
    tourId
    tour {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      users {
          items {
              role
              seen
              id
              userId
              user {
              id
              givenName
              familyName
              name
              displayName
              email
              phone
              __typename
              }
              __typename
          }
          nextToken
          __typename
          }
    }
    clients
    primaryAgent
    showingAgents
    mlsNumber
    status
    startTime
    endTime
    order
    showingNote
    reviews {
      items {
          id
          rate 
          userId 
          tourItemId
      }
      nextToken
      __typename
    }
    notes {
      items {
          id 
          content 
          userId
          tourItemId
      }
      nextToken
      __typename
    }
    photos {
      items {
          id 
          userId 
          tourItemId 
          path
      }
      nextToken
      __typename
    }
    recordings {
      items {
          id 
          userId 
          tourItemId 
          path
      }
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    tourTourItemsId
    __typename
  }
}
`;


export const listAgentTourItems = /* GraphQL */ `query ListTourItems(
  $filter: ModelTourItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listTourItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      creator
      tourId
      clients
      primaryAgent
      showingAgents
      mlsNumber
      status
      startTime
      endTime
      order
      showingNote
      entryInfo
      id
      createdAt
      updatedAt
      tourTourItemsId
      reviews {
        items {
            id
            rate 
            userId 
            tourItemId
        }
        nextToken
        __typename
      }
      notes {
        items {
            id 
            content 
            userId
            tourItemId
        }
        nextToken
        __typename
      }
      photos {
        items {
            id 
            userId 
            tourItemId 
            path
        }
        nextToken
        __typename
      }
      recordings {
        items {
            id 
            userId 
            tourItemId 
            path
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
`;

export const listClientTourItems = /* GraphQL */ `query ListTourItems(
  $filter: ModelTourItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listTourItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      creator
      tourId
      clients
      primaryAgent
      showingAgents
      mlsNumber
      status
      startTime
      endTime
      order
      showingNote
      id
      createdAt
      updatedAt
      tourTourItemsId
      reviews {
        items {
            id
            rate 
            userId 
            tourItemId
        }
        nextToken
        __typename
      }
      notes {
        items {
            id 
            content 
            userId
            tourItemId
        }
        nextToken
        __typename
      }
      photos {
        items {
            id 
            userId 
            tourItemId 
            path
        }
        nextToken
        __typename
      }
      recordings {
        items {
            id 
            userId 
            tourItemId 
            path
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
`;