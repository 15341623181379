import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import {
  selectCurrentUser,
  selectUserError,
} from "../../redux/user/user.selectors";
import { Redirect } from "react-router-dom";
import { selectCurrentClient } from "../../redux/client/client.selectors";
import { checkUserSession } from "../../redux/user/user.actions";

const withAuthentication = (Component) => (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const user = useSelector(selectCurrentUser);
  const client = useSelector(selectCurrentClient);
  const error = useSelector(selectUserError);

  useEffect(() => {
    if (user && client) {
      setAuthenticated(true);
      setLoading(false);
    }
    if (error) {
      setAuthenticated(false);
      setLoading(false);
    }
    if (!user && !error) {
      dispatch(checkUserSession());
    }
  }, [dispatch, user, error, client]);

  return loading ? (
    <></>
  ) : authenticated ? (
    <Component {...props} user={user} client={client} />
  ) : (
    <Redirect
      to={{
        pathname: "/",
      }}
    />
  );
};

export default withAuthentication;
