import styles from "./city.module.scss";
import { useSelector } from "react-redux";
import { IonList, IonItem, IonCheckbox, IonLabel, IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import withFilters from "../../../../../HOC/withFilters/with-filters";
import { selectLocationsValue } from "../../../../../redux/locations/locations.selectors";
import withMap from "../../../../../HOC/withMap/with-map";
import { useHistory } from "react-router-dom";

const CityPage = ({
  filters: { area, city, neighborhood },
  updateMultipleFilters,
  updateMapLocations,
}) => {
  const history = useHistory();
  const locations =
    useSelector(selectLocationsValue)?.filter((loc) => loc.name === area) || [];

  const handleSelect = ({ name, location }) => {
    if (city) {
      if (city.includes(name)) {
        const updatedCities = city.filter((c) => c !== name);
        updateMultipleFilters({
          city: updatedCities.length ? updatedCities : null,
        });
      } else {
        const updatedNeighborhoods = neighborhood
          ? neighborhood.filter((n) => {
              const item = locations
                .find((a) => a.name === area)
                .value[0].cities.find((c) => c.name === name)
                .neighborhoods.find((item) => item.name === n);
              if (item) return false;
              return true;
            })
          : null;

        updateMultipleFilters({
          city: [...city, name],
          neighborhood: updatedNeighborhoods
            ? updatedNeighborhoods.length
              ? updatedNeighborhoods
              : null
            : null,
        });
        updateMapLocations({ center: location, city: name });
      }
    } else {
      const updatedNeighborhoods = neighborhood
        ? neighborhood.filter((n) => {
            const neighborhoods = locations
              .find((a) => a.name === area)
              .value[0].cities.find((c) => c.name === name).neighborhoods;
            const index = neighborhoods.findIndex((item) => item.name === n);
            if (index >= 0) return false;
            return true;
          })
        : null;

      updateMultipleFilters({
        city: [name],
        neighborhood: updatedNeighborhoods
          ? updatedNeighborhoods.length
            ? updatedNeighborhoods
            : null
          : null,
      });
      updateMapLocations({ center: location, city: name });
    }
  };

  const isIndeterminate = (name) => {
    const item = locations[0];
    if (city && city.includes(name)) {
      return false;
    }
    if (neighborhood && neighborhood.length) {
      const { neighborhoods } = item.value[0].cities.find(
        (c) => c.name === name
      );
      const total = neighborhoods.filter((n) =>
        neighborhood.includes(n.name)
      ).length;
      return total !== 0;
    }
  };

  const handleExpand = (e, city) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/filters/cities/${city}`, { direction: "forward" });
  };

  if (!locations.length) {
    history.replace("/", { direction: "root" });
    return;
  }
  const cities = locations[0].value[0].cities;

  return (
    <IonList>
      {cities.map((c) => (
        <IonItem key={c.name} lines="full" className={styles.item}>
          <IonCheckbox
            value={c.name}
            className={styles.checkbox}
            slot="start"
            mode="md"
            indeterminate={isIndeterminate(c.name)}
            checked={
              (city && (city === c.name || city.includes(c.name))) ||
              isIndeterminate(c.name)
            }
            onClick={() => handleSelect(c)}
          />
          <IonLabel
            className={`${styles.label} ${
              city &&
              (city === c.name || city.includes(c.name)) &&
              styles.selected
            }`}
          >
            {c.name}
          </IonLabel>
          <span
            onClick={(e) => handleExpand(e, c.name)}
            className={styles.iconContainer}
          >
            <IonIcon className={styles.icon} icon={chevronForwardOutline} />
          </span>
        </IonItem>
      ))}
    </IonList>
  );
};

export default withMap(withFilters(CityPage));
