import { useState, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonGrid, IonRow, IonCol, IonIcon } from "@ionic/react";
import Button from "../../../components/Form/Button/button.component";

import {
  logoFacebook,
  logoInstagram,
  logoLinkedin,
  logoTwitter,
} from "ionicons/icons";
import Footer from "../../../components/Footer/footer.component";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import CardContainer from "../../Tours/component/CardContainer/card-container.component";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { ReactComponent as GooglePlay } from "../../../assets/svg/stores/google.svg";
import { ReactComponent as AppleStore } from "../../../assets/svg/stores/apple.svg";
import styles from "./contact-us.module.scss";
import Input from "../../../components/Form/Input/input.component";
import { contactUs } from "../../../api/ettie";
import { updateToast } from "../../../redux/ui/ui.actions";
import GooglePlayStore from "../../../assets/svg/stores/google.svg";

const ContactUs = () => {
  const dispatch = useDispatch();

  const client = useSelector(selectCurrentClient);
  const [loading, setLoading] = useState(false);

  const inputRefs = useRef({
    email: createRef(),
    given_name: createRef(),
    family_name: createRef(),
    phone: createRef(),
    // message: createRef(),
  });

  const [formData, setFormData] = useState({
    email: "",
    given_name: "",
    family_name: "",
    phone: "",
    message: "",
  });

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleMessageChange = ({ target: { value } }) => {
    setFormData((prev) => ({ ...prev, message: value }));
  };

  const sendEmailHandler = () => {
    let Link = "mailto:info@aecorn.ca?subject=Contact%20us";
    window.open(Link, "_system");
  };

  const handleSubmit = async () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }
    if (isValid) {
      setLoading(true);
      try {
        await contactUs({
          name: `${formData.given_name} ${formData.family_name}`,
          givenName: formData.given_name,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
        });
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: "Message sent successfully.",
          })
        );
      } catch (err) {
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong!",
          })
        );
      }
      setLoading(false);
    } else {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message:
            "An entry is required or has an invalid value. Please correct and try again.",
        })
      );
    }
  };

  return (
    <div className={styles.contactUs}>
      <div className={styles.cards}>
        <CardContainer>
          <IonGrid className={styles.contact}>
            <IonRow>
              <IonCol className={styles.title}>
                Hello <span>{client ? client["givenName"] : "there"}!</span>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={styles.subtitle}>
                We would love to hear from you! Whatever you need, feel free to
                shoot us a message right here, or through your email.
              </IonCol>
            </IonRow>

            <IonRow className={styles.email}>
              <IonCol>Email</IonCol>
              <IonCol
                className={styles.emailAddress}
                onClick={sendEmailHandler}
              >
                info@aecorn.ca
              </IonCol>
            </IonRow>
            <IonRow className={styles.email}>
              <IonCol>Media inquries</IonCol>
              <IonCol
                className={styles.emailAddress}
                onClick={sendEmailHandler}
              >
                info@aecorn.ca
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={styles.owned}>
                <span className={styles.bold}> Operated by</span>
                <div>AECORN Realty Inc.</div>
                <div>120 East Beaver Creek Rd. Suite 200</div>
                <div>Richmond Hill, ON L4B 4V1</div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </CardContainer>

        <CardContainer>
          <IonGrid className={styles.form}>
            <IonRow>
              <IonCol>
                <div className={styles.formlabel}>Talk to us</div>
                <div className={styles.formsubtitle}>
                  Want to connect to a AECORN Realty agent? Questions, comments
                  or suggestions? Send us a message or an email, and we will get
                  back to you within 24 hours.
                </div>
              </IonCol>
            </IonRow>

            <div className={styles.inputs}>
              <IonRow className={styles.input}>
                <IonCol>
                  <Input
                    name="given_name"
                    label="given name"
                    placeholder="given name"
                    required
                    ref={inputRefs.current.given_name}
                    value={formData["given_name"]}
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|min:2"
                    isApp
                    enterkeyhint="next"
                    submit={() => inputRefs.current.family_name.current.next()}
                  />
                </IonCol>
                <IonCol>
                  <Input
                    name="family_name"
                    label="family name"
                    placeholder="family name"
                    required
                    ref={inputRefs.current.family_name}
                    value={formData["family_name"]}
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|min:2"
                    isApp
                    enterkeyhint="next"
                    submit={() => inputRefs.current.email.current.next()}
                  />
                </IonCol>
              </IonRow>

              <IonRow className={styles.input}>
                <IonCol>
                  <Input
                    name="email"
                    label="email"
                    placeholder="email"
                    mode="email"
                    required
                    ref={inputRefs.current.email}
                    value={formData["email"]}
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|email"
                    isApp
                    enterkeyhint="next"
                    submit={() => inputRefs.current.phone.current.next()}
                  />
                </IonCol>
                <IonCol>
                  <Input
                    name="phone"
                    label="phone"
                    placeholder="phone"
                    mode="tel"
                    required
                    ref={inputRefs.current.phone}
                    value={formData["phone"]}
                    onChange={(value, name) => handleChange(value, name)}
                    validation="required|phone"
                    isApp
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <textarea
                    className={styles.textarea}
                    rows={12}
                    name="message"
                    placeholder="Message*"
                    value={formData.message}
                    onChange={handleMessageChange}
                  ></textarea>
                </IonCol>
              </IonRow>
            </div>

            <IonRow>
              <IonCol>
                <Button
                  onClick={handleSubmit}
                  title="Submit"
                  expand="block"
                  shape="round"
                  type="green"
                  style={{
                    height: "5rem",
                    width: "100%",
                    border: Object.values(formData).filter((val) => !val).length
                      ? "1px solid #e5e5e5"
                      : "1px solid #1F7A60",
                  }}
                  loading={loading}
                  disabled={
                    Object.values(formData).filter((val) => !val).length
                  }
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </CardContainer>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
