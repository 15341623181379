import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IonIcon, IonSkeletonText } from "@ionic/react";
import { attachOutline, navigateOutline } from "ionicons/icons";
import styles from "./client-tour-item.module.scss";
import {
  buildAddress,
  handleAppNavigation,
  numberWithCommas,
} from "../../../../../../utils/functions";
import { capitalize } from "lodash";
import { selectCurrentClient } from "../../../../../../redux/client/client.selectors";
import { updateToast } from "../../../../../../redux/ui/ui.actions";
import Rating from "../../../../../../components/Rating/rating.component";
import { API } from "@aws-amplify/api";
import {
  onCreateReview,
  onUpdateReviewById,
} from "../../../../../../graphql/subscriptions";
import { TourItemService } from "../../../../../../services/tourItemService";

const ClientTourItem = ({ item: { tourItem, listing } }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const client = useSelector(selectCurrentClient);
  const [review, setReview] = useState();
  const subscriptionRef = useRef();
  const subscriptionNewRef = useRef();

  useEffect(() => {
    const subscribe = async (r) => {
      subscriptionRef.current?.unsubscribe();
      const subscription = await API.graphql({
        query: onUpdateReviewById,
        variables: { id: r.id },
      }).subscribe({
        next: async ({
          value: {
            data: { onUpdateReviewById: updatedReview },
          },
        }) => {
          setReview(updatedReview);
        },
      });

      if (subscription) {
        subscriptionRef.current = subscription;
      }
    };

    const subscribeNew = async () => {
      subscriptionNewRef.current?.unsubscribe();
      const subscription = await API.graphql({
        query: onCreateReview,
        variables: { userId: client.id },
      }).subscribe({
        next: async ({
          value: {
            data: { onCreateReview: newReview },
          },
        }) => {
          if (newReview.tourItemId === tourItem.id) {
            setReview(newReview);
            subscribe(newReview);
          }
        },
      });

      if (subscription) {
        subscriptionNewRef.current = subscription;
      }
    };
    if (client && tourItem) {
      const r = tourItem.reviews.items.find((r) => r.userId === client.id);
      setReview(r);
      if (r) {
        subscribe(r);
      } else {
        subscribeNew();
      }
    }

    return () => subscriptionRef.current?.unsubscribe();
  }, [client, tourItem]);

  const handleReview = async (rate) => {
    try {
      if (!review) {
        const createdReview = await TourItemService.addReview(tourItem.id, rate);
        setReview(createdReview);
      } else {
        const updatedReview = await TourItemService.updateReview(review.id, rate);
        setReview(updatedReview);
      }

      dispatch(
        updateToast({
          open: true,
          message: "Review submitted successfully.",
          type: "success",
        })
      );
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          message: "Something went wrong!",
          type: "error",
        })
      );
    }
  };

  return (
    <div className={styles.clientTourItem}>
      <span className={styles.order}>{tourItem.order}</span>
      <span>{`${tourItem.startTime} - ${tourItem.endTime}`}</span>
      <span className={styles.map}>
        {
          <IonIcon
            icon={navigateOutline}
            onClick={
              listing
                ? () =>
                    handleAppNavigation({
                      map: listing.map,
                      address: listing.address,
                      byAddress: true,
                    })
                : null
            }
          />
        }
      </span>
      <span
        onClick={() => history.push(`/tours/${tourItem.id}`)}
        style={{ cursor: "pointer" }}
      >
        {listing ? buildAddress(listing.address) : <IonSkeletonText animated />}
      </span>
      <span>
        {listing ? (
          `$${numberWithCommas(listing.listPrice)}`
        ) : (
          <IonSkeletonText animated />
        )}
      </span>

      <span className={`${styles.status} ${styles[tourItem.status]}`}>
        {capitalize(tourItem.status)}
      </span>
      <Rating review={review} handleReview={handleReview} />
      <span className={styles.details}>
        <IonIcon
          icon={attachOutline}
          onClick={() => history.push(`/tours/${tourItem.id}`)}
        />
      </span>
    </div>
  );
};

export default ClientTourItem;
