import { useState, useRef, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Input from "../../Form/Input/input.component";
import Button from "../../Form/Button/button.component";
import { IonIcon } from "@ionic/react";
import { sadOutline } from "ionicons/icons";
import styles from "./delete-account-modal.module.scss";
import { deleteAccountData } from "../../../data/customModal";
import { openModal } from "../../../redux/ui/ui.actions";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { UserService } from "../../../services/userService";

const DeleteAccountModal = ({ setType, close, language }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const inputRefs = useRef({
    password: createRef(),
  });

  const [formData, setFormData] = useState({
    password: "",
  });

  const isFederated = () => {
    const { username } = user;
    if (
      username.startsWith("google") ||
      username.startsWith("facebook") ||
      username.startsWith("signinwithapple")
    )
      return true;
    return false;
  };
  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDelete = async () => {
    const res = inputRefs.current.password.current.validate(true);
    if (res) {
      setLoading(true);
        try {
          await UserService.deleteUser(formData.password);
          dispatch(openModal({ current: "deletedAccount", closable: false }));
        } catch (err) {
          setError("Something went wrong. Please try again.");
          setLoading(false);
        } finally {
          setLoading(false);
        }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <IonIcon icon={sadOutline} />
      </div>
      <div className={styles.header}>{deleteAccountData[language].header}</div>
      <div className={styles.subtitle}>
        <span>
          {isFederated()
            ? deleteAccountData[language].federatedSubtitle
            : deleteAccountData[language].subtitle}
        </span>
      </div>
      <div className={styles.content}>
        <Input
          required
          name="password"
          label={isFederated() ? "" : deleteAccountData[language].input}
          placeholder={isFederated() ? "" : deleteAccountData[language].input}
          type={isFederated() ? "text" : "password"}
          ref={inputRefs.current.password}
          value={formData["password"]}
          onChange={(value, name) => handleChange(value, name)}
          validation={isFederated() ? "required|equal:DELETE" : "required"}
        />
      </div>
      {error && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
      <div className={styles.btns}>
        <Button
          title={deleteAccountData[language].button_1}
          type="green"
          style={{ height: "5rem", width: "24rem" }}
          onClick={close}
        />
        <Button
          title={deleteAccountData[language].button_2}
          type="orange"
          style={{ height: "5rem", width: "24rem" }}
          onClick={handleDelete}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default DeleteAccountModal;
