import { IonSpinner, useIonRouter } from "@ionic/react";
import { Listing } from "../../../models/listings/listing.model";
import styles from "./listing-list-item.module.scss";
import DefaultImage from "../../../assets/img/no-photo.png";
import { useState } from "react";
import ListingStatusTag from "../ListingStatusTag/listing-status-tag.component";
import ListingFavouriteIcons from "../ListingFavouriteIcons/listing-favourite-icons.component";
import { ListingsHelper } from "utils/ListingsHelper";

const ListingListItem = ({ listing }: { listing: Listing }) => {
  const router = useIonRouter();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const image = ListingsHelper.getListingThumbnail(listing);
  const listingPrice = ListingsHelper.getListingFormattedPrice(listing);
  const days = ListingsHelper.getListingFormattedDate(listing);

  const handleNavigateToListing = () => {
    router.push(`/listings/${listing.mlsNumber}`);
  };

  const renderRooms = () => {
    const bedrooms =
      +listing.details.numBedrooms +
      (listing.details.numBedroomsPlus ? +listing.details.numBedroomsPlus : 0);
    const bathrooms =
      +listing.details.numBathrooms +
      (listing.details.numBathroomsPlus
        ? +listing.details.numBathroomsPlus
        : 0);
    const parkings =
      +listing.details.numParkingSpaces +
      (listing.details.numGarageSpaces ? +listing.details.numGarageSpaces : 0);

    return (
      <>
        <span>{bedrooms} Bed</span>
        <span>{bathrooms} Bath</span>
        <span>{parkings} Parking</span>
      </>
    );
  };

  return (
    <div onClick={handleNavigateToListing} className={styles.container}>
      <div className={styles.image}>
        <img
          src={image}
          onLoad={() => setIsImageLoaded(true)}
          onError={(e) => {
            e.currentTarget.src = DefaultImage;
            setIsImageLoaded(true);
          }}
        />
        {!isImageLoaded && <IonSpinner name="crescent" />}
      </div>
      <div className={styles.details}>
        <div className={styles.icons}>
          <ListingFavouriteIcons listing={listing} />
        </div>
        <div className={styles.price}>{listingPrice}</div>
        <div className={styles.address}>{ListingsHelper.getListingAddress(listing.address)}</div>
        <div className={styles.type}>
          {ListingsHelper.getListingPropertyType(listing.details.propertyType)}
        </div>
        <div className={styles.rooms}>{renderRooms()}</div>
        <div className={styles.tag}>
          <ListingStatusTag listing={listing} />
          {days > 0 ? <span>{days} day</span> : <span>Today</span>}
        </div>
        <div className={styles.mlsNumber}>MLS number: {listing.mlsNumber}</div>
      </div>
    </div>
  );
};

export default ListingListItem;
