import { Redirect, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  IonBadge,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { Route } from "react-router-dom";
import {
  ellipsisHorizontalOutline,
  mapOutline,
  starOutline,
} from "ionicons/icons";
import AppHomePage from "../app/pages/Home/home.component";
import Favourites from "../app/pages/Favourites/favourites.component";
import UserAccount from "../app/pages/UserAccount/user-account.component";
import styles from "./styles.module.scss";
import { selectUINotifications } from "../redux/ui/ui.selectors";
import { getPlatforms } from "@ionic/core/components";
import homeOutlineIcon from "../assets/svg/REA103_Icons-01a_HomeEttie.svg";
import homeIcon from "../assets/svg/REA103_Icons-01a_HomeEttieSelected.svg";
import whitetourIcon from "../assets/svg/appTourIcon.svg";
import greentourIcon from "../assets/svg/tourIcon.svg";
import AppSearchesPage from "../app/pages/Searches/searches.component";
import AppSearchPage from "../app/pages/Search/search.component";
import AppSearchDetailsPage from "../app/pages/Searches/pages/search-details.component";
import AppDetailsPage from "../app/pages/Details/details.component";
import { updateTab } from "../redux/ui/ui.actions";
import { useEffect, useRef } from "react";
import AppTermsOfService from "../app/pages/TermsOfService/terms-of-service.component";
import AppPrivacyPolicy from "../app/pages/PrivacyPolicy/privacy-policy.component";
import AppContactUs from "../app/pages/ContactUs/contact-us.component";
import AppFeedback from "../app/pages/Feedback/feedback.component";
import AppAboutUs from "../app/pages/AboutUs/about-us.component";
import AppMarketData from "../app/pages/MarketData/market-data.component";
import AppSchoolDisclaimer from "../app/pages/SchoolDisclaimer/school-disclaimer.component";
import AppHireAgent from "../app/pages/HireAgent/hire-agent.component";
import Tours from "../app/pages/Tours/tours.component";
import TourItem from "../app/pages/Tours/tour-item.component";
import TourItemDetails from "../app/pages/Tours/tour-item-details.component";
import NewTour from "../app/components/NewTour/new-tour.component";

const AppTabs = () => {
  const tabRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const notifications = useSelector(selectUINotifications);

  const path = history.location.pathname;

  const onTabChange = (e) => {
    const tab = e.detail.tab;
    dispatch(updateTab(tab));
  };

  useEffect(() => {
    dispatch(updateTab(tabRef.current?.ionTabContextState.activeTab));
  }, [dispatch]);

  return (
    <IonTabs onIonTabsDidChange={onTabChange} ref={tabRef}>
      <IonRouterOutlet animated id="tabs">
        <Route exact path="/tabs/home" component={AppHomePage} />
        <Route exact path="/tabs/home/:id" component={AppDetailsPage} />
        <Route exact path="/tabs/map" component={AppSearchPage} />
        <Route exact path="/tabs/favourites" component={Favourites} />
        <Route exact path="/tabs/favourites/:id" component={AppDetailsPage} />
        <Route exact path="/tabs/tours" component={Tours} />
        <Route exact path="/tabs/tours/:tourId" render={({match}) => {
          const { tourId } = match.params;
          if (tourId === "new") {
            return <NewTour />
          }
          return <TourItem tourId={tourId} />
        }} />
        <Route
          exact
          path="/tabs/tours/:tourId/:tourItemId"
          component={TourItemDetails}
        />
        <Route
          exact
          path="/tabs/tours/:tourId/:tourItemId/:id"
          component={AppDetailsPage}
        />
        <Route exact path="/tabs/more" component={UserAccount} />
        <Route exact path="/tabs/more/terms" component={AppTermsOfService} />
        <Route exact path="/tabs/more/privacy" component={AppPrivacyPolicy} />
        <Route exact path="/tabs/more/contact-us" component={AppContactUs} />
        <Route exact path="/tabs/more/feedback" component={AppFeedback} />
        <Route exact path="/tabs/more/about-us" component={AppAboutUs} />
        <Route exact path="/tabs/more/market-data" component={AppMarketData} />
        <Route
          exact
          path="/tabs/more/school-terms"
          component={AppSchoolDisclaimer}
        />
        <Route exact path="/tabs/more/hiring-agent" component={AppHireAgent} />
        <Route exact path="/tabs/more/searches" component={AppSearchesPage} />
        <Route
          exact
          path="/tabs/more/searches/:searchId"
          component={AppSearchDetailsPage}
        />
        <Route exact path="/">
          <Redirect to="/tabs/home" />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/tabs/home" >
          <IonIcon
            icon={path === "/tabs/home" ? homeOutlineIcon : homeIcon}
            
          />
          <IonLabel>home</IonLabel>
        </IonTabButton>
        <IonTabButton tab="map" href="/tabs/map" >
          <IonIcon icon={mapOutline}  />
          <IonLabel>map</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="favourites"
          href="/tabs/favourites"
          >
          {notifications.favourites &&
            notifications.favourites.length > 0 &&
            (getPlatforms().includes("ios") ? (
              !getPlatforms().includes("tablet") ? (
                <span className={styles.iosBadge} />
              ) : null
            ) : (
              <IonBadge className={styles.badge} />
            ))}

          <IonIcon icon={starOutline}  />
          <IonLabel>favourites</IonLabel>
        </IonTabButton>

        <IonTabButton tab="tours" href="/tabs/tours" >
          {notifications.tours &&
            notifications.tours.length > 0 &&
            (getPlatforms().includes("ios") ? (
              !getPlatforms().includes("tablet") ? (
                <span className={styles.iosBadge} />
              ) : null
            ) : (
              <IonBadge className={styles.badge} />
            ))}
          <IonIcon
            icon={path.includes("/tabs/tours") ? greentourIcon : whitetourIcon}
            
          />
          <IonLabel>my tours</IonLabel>
        </IonTabButton>
        <IonTabButton tab="more" href="/tabs/more" >
          <IonIcon icon={ellipsisHorizontalOutline}  />
          <IonLabel>more</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default AppTabs;
