import { IonCheckbox } from "@ionic/react";
import DropdownContainer from "../Form/DropdownContainer/dropdown-container.component";
import styles from "./type-filter.module.scss";

const TypeFilter = ({
  open,
  items,
  value,
  onClickHandler,
  dropdown = true,
  multiple = true,
  isHomepage = false,
}) => {
  const render = () => (
    <div className={styles.typesFilter}>
      {items.map((item, index) => (
        <div key={index}>
          <label
            className={styles.checkbox}
            onClick={() => onClickHandler(item.value)}
          >
            <IonCheckbox
              // type="checkbox"
              onClick={() => onClickHandler(item.value)}
              mode="md"
              checked={
                multiple
                  ? value.includes(item.value) ||
                    value.includes(`'${item.value}'`)
                  : value === item.value
              }
              readOnly
            />
            <span className={styles.item}>{item.name}</span>
          </label>
        </div>
      ))}
    </div>
  );

  return dropdown ? (
    <DropdownContainer open={open} style={{ boxShadow: isHomepage && "none" }}>
      {render()}
    </DropdownContainer>
  ) : open ? (
    <div className={styles.menuItem}>{render()}</div>
  ) : null;
};

export default TypeFilter;
