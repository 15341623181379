import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardContainer from "../../pages/Tours/component/CardContainer/card-container.component";
import DetailsHeader from "../TourDetailsHeader/tour-details-header.module";
import EditIcon from "../../assets/svg/REA103_Icons-01a_Edit saved search.svg";
import styles from "./notes.module.scss";
import NotesSection from "./component/NotesSection/notes-section.component";
import { buildAddress } from "../../utils/functions";
import { selectCurrentClient } from "../../redux/client/client.selectors";
import { IonLoading } from "@ionic/react";
import { updateToast } from "../../redux/ui/ui.actions";
import _ from "lodash";
import { TourItemMediaService } from "../../services/tourItemMediaService";
import { TourService } from "../../services/tourService";

const Notes = ({ tourItem, noteItems, listing, isOpen, setIsOpen, tour }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState();
  const client = useSelector(selectCurrentClient);

  useEffect(() => {
    const groupNotes = () =>
      _.mapValues(_.groupBy(noteItems, (note) => note.userId));
    if (noteItems && client) setNotes(groupNotes());
  }, [client, noteItems]);

  const createNote = async (content) => {
    setLoading(true);
    try {
      const note = await TourItemMediaService.addNote(tourItem.id, content);
      const tempNotes = notes;
      tempNotes[note.userId] = [note];
      setNotes(tempNotes);
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Showing note saved.",
        })
      );
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateNote = async ({ content, id }) => {
    setLoading(true);
    try {
      const note = await TourItemMediaService.updateNote(id, content);
      const tempNotes = notes;
      tempNotes[note.userId] = [note];
      setNotes(tempNotes);

      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Showing note saved.",
        })
      );
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
      console.log(err);
    }
    setLoading(false);
  };

  return client && tour && tourItem ? (
    <CardContainer>
      <IonLoading isOpen={loading} />
      <DetailsHeader
        title="All notes"
        isCustomIcon={true}
        icon={EditIcon}
        hasTag={false}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {isOpen && (
        <div className={styles.noteDetails}>
          <div className={styles.subtitle}>
            {TourService.getTourUserByUserId(tour, client.id)
              ? `All notes taken for ${buildAddress(
                  listing.address
                )}. by all tour
            attendees. You may only add to and edit your own notes.`
              : "Only lead/showing agents can create a note item."}
          </div>
          {TourService.getTourUserByUserId(tour, client.id) && (
            <NotesSection
              isCurrentUser={true}
              note={notes[client.id] ? notes[client.id][0] : null}
              create={createNote}
              update={updateNote}
              memberData={tour.users.items.find((u) => u.userId === client.id)}
              tour={tourItem}
            />
          )}

          {notes &&
            Object.keys(notes).map((key) => {
              if (notes[key][0].userId === client.id) {
                return null;
              } else {
                return (
                  <NotesSection
                    key={key}
                    isCurrentUser={false}
                    note={notes[key][0]}
                    memberData={TourService.getTourUserByUserId(tour, notes[key][0].userId)}
                    tour={tourItem}
                  />
                );
              }
            })}
        </div>
      )}
    </CardContainer>
  ) : null;
};

export default Notes;
