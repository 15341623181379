import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLoading,
  IonModal,
  IonRouterLink,
  IonRow,
} from "@ionic/react";
import {
  arrowBackCircleOutline,
  arrowForwardCircleOutline,
  cameraOutline,
  micOutline,
  navigateOutline,
} from "ionicons/icons";
import { useState, useEffect, useRef, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Rating from "../../../components/Rating/rating.component";
import withAuthentication from "../../../HOC/withAuthentication/with-authentication";
import { updateToast } from "../../../redux/ui/ui.actions";
import {
  buildAddress,
  capitalize,
  numberWithCommas,
  handleAppNavigation,
  copyToClipboard,
} from "../../../utils/functions";
import Medias from "../../components/Medias/medias.component";
import TourElement from "../AgentTour/component/TourElement/tour-element.component";
import styles from "./client-tour-details.module.scss";
import editIcon from "../../../assets/svg/REA103_Icons-01a_Edit saved search.svg";
import LoadingFullPage from "../../../components/Loading/loading-full-page.component";
import { API, graphqlOperation } from "aws-amplify";
import {
  onCreateNoteByTourItemId,
  onCreatePhotoByTourItemId,
  onCreateRecordingByTourItemId,
  onDeletePhotoByTourItemId,
  onDeleteRecordingByTourItemId,
  onUpdateNoteByTourItemId,
  onUpdateTourItemById,
} from "../../../graphql/subscriptions";
import AppHeader from "../../components/Header/header.component";
import moment from "moment";
import MediaNav from "../../components/Medias/component/StickyNav/media-nav.component";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import Refresher from "../../components/Refresher/refresher.component";
import NotePlayer from "../../components/NotePlayer/note-player.component";
import { useHistory, useLocation } from "react-router-dom";
import { getPlatforms } from "@ionic/core/components";
import { VoiceRecorder } from "capacitor-voice-recorder";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { TourItemService } from "../../../services/tourItemService";
import { TourService } from "../../../services/tourService";
import { ListingService } from "../../../services/listingService";
import { TourItemMediaService } from "../../../services/tourItemMediaService";
import { ShareStatus } from "../../../API";

const AppClientTourDetails = ({ tourId, tourItemId, pageRef }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const ref = useRef();
  const user = useSelector(selectCurrentUser);
  const [uploading, setUploading] = useState(false);
  const [tour, setTour] = useState();
  const [tourItems, setTourItems] = useState();
  const [tourItem, setTourItem] = useState();
  const [listing, setListing] = useState();
  const [loading, setLoading] = useState(false);
  const [review, setReview] = useState();
  const [showMedia, setShowMedia] = useState(false);
  const [type, setType] = useState("");
  const [isAudioRecording, setIsAudioRecording] = useState(false);

  useEffect(() => {
    const platforms = getPlatforms();
    if (platforms.includes("capacitor")) {
      VoiceRecorder.requestAudioRecordingPermission();
    }
  }, []);

  const refreshMedia = async () => {
    const item = await TourItemService.getTourItemById(tourItemId);
    setTourItem({ ...item });
  };

  const fetchData = useCallback(
    async (inBackground = false) => {
      if (!inBackground) setLoading(true);

      try {
        const tour = await TourService.getTourById(tourId);
        setTour(tour);
        const items = await TourItemService.getTourItemsByTourId(tourId);

        if (!tour || !items || tour.shared !== ShareStatus.shared) {
          history.replace("/tours", { direction: "root" });
          return;
        }
        setTourItems(items);
        const item = await TourItemService.getTourItemById(tourItemId);
        if (!item) {
          history.replace("/tours", { direction: "root" });
          return;
        }
        setTourItem(item);
        const listing = await ListingService.getListingByMlsNumber(item.mlsNumber);
        setListing(listing);
      } catch (err) {
        console.log(err)
        history.replace("/tours", { direction: "root" });
      }
      if (!inBackground) setLoading(false);
    },

    [history, tourId, tourItemId]
  );

  useEffect(() => {
    const subscription = [];

    const subscribeMedia = async () => {
      const promises = [];
      promises.push(
        API.graphql(
          graphqlOperation(onUpdateTourItemById, {
            id: tourItemId,
          })
        ).subscribe({
          next: (res) => {
            fetchData(true);
          },
        }),
        API.graphql(
          graphqlOperation(onCreatePhotoByTourItemId, {
            tourItemId: tourItemId,
          })
        ).subscribe({
          next: (res) => {
            fetchData(true);
          },
        }),
        API.graphql(
          graphqlOperation(onCreateRecordingByTourItemId, {
            tourItemId: tourItemId,
          })
        ).subscribe({
          next: (res) => {
            fetchData(true);
          },
        }),
        API.graphql(
          graphqlOperation(onCreateNoteByTourItemId, { tourItemId: tourItemId })
        ).subscribe({
          next: (res) => {
            fetchData(true);
          },
        }),
        API.graphql(
          graphqlOperation(onDeletePhotoByTourItemId, {
            tourItemId: tourItemId,
          })
        ).subscribe({
          next: (res) => {
            fetchData(true);
          },
        }),
        API.graphql(
          graphqlOperation(onDeleteRecordingByTourItemId, {
            tourItemId: tourItemId,
          })
        ).subscribe({
          next: (res) => {
            fetchData(true);
          },
        }),
        API.graphql(
          graphqlOperation(onUpdateNoteByTourItemId, { tourItemId: tourItemId })
        ).subscribe({
          next: (res) => {
            fetchData(true);
          },
        })
      );
      (await Promise.all(promises)).forEach((p) => subscription.push(p));
    };

    subscribeMedia();
    fetchData();

    return () => {
      if (subscription && subscription.length) {
        subscription.forEach((s) => s.unsubscribe());
      }
    };
  }, [fetchData, tourId, tourItemId]);

  useEffect(() => {
    if (tourItem) {
      ref.current && ref.current.scrollToTop();
      setReview(tourItem.reviews.items.find((r) => r.userId === user.username));
    }
  }, [tourItem, user.username]);

  const nextRoute = () => {
    const nextId = tourItems.find((i) => i.order === tourItem.order + 1).id;
    return `/tabs/tours/${tourId}/${nextId}`;
  };

  const previousRoute = () => {
    const previousId = tourItems.find((i) => i.order === tourItem.order - 1).id;
    return `/tabs/tours/${tourId}/${previousId}`;
  };

  const handleNewPhoto = async () => {
    const takePicture = async () => {
      const image = await Camera.getPhoto({
        quality: 75,
        allowEditing: false,
        source: CameraSource.Camera,
        resultType: CameraResultType.Base64,
        height: 1280,
        width: 1280,
      });
      if (image) {
        try {
          setUploading(true);
          await TourItemMediaService.addPhoto(tourItem.id, image);
          dispatch(
            updateToast({
              open: true,
              message: "Photo uploaded successfully.",
              type: "success",
            })
          );
          refreshMedia();
        } catch (err) {
          dispatch(
            updateToast({
              open: true,
              message: "Something went wrong!",
              type: "error",
            })
          );
        } finally {
          setUploading(false);
        }
      }
    };

    await Camera.checkPermissions().then(async (res) => {
      if (res.camera === "granted") {
        await takePicture();
      }
      if (res.camera === "denied") {
        return;
      }
      if (res.camera === "prompt") {
        await Camera.requestPermissions()
          .then(async (res) => {
            if (res.camera === "granted") {
              await takePicture();
            }
          })
          .catch((err) => {
            return;
          });
      }
    });
  };

  const startRecording = () => {
    if (isAudioRecording) {
      return;
    }

    VoiceRecorder.startRecording().then(() => {
      setIsAudioRecording(true);
    });
  };

  const stopRecording = () => {
    if (!isAudioRecording) {
      return;
    }

    VoiceRecorder.stopRecording().then(async (result) => {
      setIsAudioRecording(false);
      if (result && result.value && result.value.recordDataBase64) {
        const recordData = result.value.recordDataBase64;
        const fileName = new Date().getTime() + ".wav";
        await Filesystem.writeFile({
          path: fileName,
          directory: Directory.Data,
          data: recordData,
        });
        const file = await Filesystem.readFile({
          path: fileName,
          directory: Directory.Data,
        });
        const data = file.data;

        try {
          setUploading(true);
          await TourItemMediaService.addRecording(tourItem.id, data);
          dispatch(
            updateToast({
              open: true,
              message: "Recording uploaded successfully.",
              type: "success",
            })
          );
          refreshMedia(tourItem.id);
        } catch (err) {
          dispatch(
            updateToast({
              open: true,
              message: "Something went wrong!",
              type: "error",
            })
          );
        } finally {
          setUploading(false);
        }
      }
    });
  };

  const handleReview = async (rate) => {
    try {
      if (!review) {
        const newReview = await TourItemService.addReview(tourItem.id, rate);
        setReview(newReview);
      } else {
        const updatedReview = await TourItemService.updateReview(review.id, rate);
        setReview(updatedReview);
      }
      dispatch(
        updateToast({
          open: true,
          message: "Review submitted successfully.",
          type: "success",
        })
      );
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          message: "Something went wrong!",
          type: "error",
        })
      );
    }
  };

  const handleCopyToClipboard = async () => {
    const res = await copyToClipboard(
      `https://aecorn.ca/listings/${listing.mlsNumber}`
    );
    res &&
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Property link has been copied to clipboard.",
        })
      );
  };

  const handleRefresh = async (ref) => {
    await fetchData(true);
    ref.complete();
  };

  return (
    <>
      <AppHeader hasBackButton hasRightButton={false} title="Details" />
      <IonContent
        className={styles.clientTourDetails}
        scrollEvents={true}
        ref={ref}
        forceOverscroll={false}>
        <Refresher onRefresh={handleRefresh} />
        <IonModal
          swipeToClose={true}
          isOpen={showMedia}
          onDidDismiss={() => setShowMedia(false)}
          presentingElement={pageRef.current}>
          <Medias
            disabled={!tour || !tour.status || tour.status === "archived"}
            tourItem={tourItem}
            tour={tour}
            listing={listing}
            type={type}
            setIsOpen={setShowMedia}
            refresh={refreshMedia}
          />
        </IonModal>
        <IonLoading isOpen={uploading} />

        {loading || !tourItem ? (
          <div className={styles.loading}>
            <LoadingFullPage />
          </div>
        ) : (
          <>
            <IonGrid className={styles.details}>
              <IonRow>
                <IonCol className={styles.info}>
                  <div className={styles.rating}>
                    <Rating
                      disabled={
                        !tour || !tour.status || tour.status === "archived"
                      }
                      readOnly={
                        !tour || !tour.status || tour.status === "archived"
                      }
                      review={review}
                      size="lg"
                      handleReview={handleReview}
                    />
                  </div>
                  <div className={styles.address}>
                    {buildAddress(listing.address)}
                  </div>
                  <div
                    className={
                      styles.postalCode
                    }>{`${listing.address.zip}, ${listing.address.city}`}</div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton
                    expand="block"
                    className={styles.directionBtn}
                    onClick={() =>
                      handleAppNavigation({
                        map: listing.map,
                        address: listing.address,
                        byAddress: true,
                      })
                    }>
                    <IonIcon icon={navigateOutline} />
                    Directions
                  </IonButton>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className={styles.agentNote}>
                  <div className={styles.label}>
                    Agent note
                    {tourItem.showingNote && (
                      <NotePlayer isAgent content={tourItem.showingNote} />
                    )}
                  </div>
                  <div className={styles.note}>
                    {tourItem.showingNote ? tourItem.showingNote : "---"}
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className={styles.mediaElements}>
                  <div className={styles.label}>Media</div>
                  <div className={styles.elements}>
                    <TourElement
                      icon={cameraOutline}
                      title="All photos"
                      onClick={() => {
                        setShowMedia(true);
                        setType("photos");
                      }}
                    />
                  </div>
                  <div className={styles.elements}>
                    <TourElement
                      icon={editIcon}
                      title="All notes"
                      onClick={() => {
                        setShowMedia(true);
                        setType("notes");
                      }}
                    />
                  </div>
                  <div className={styles.elements}>
                    <TourElement
                      icon={micOutline}
                      title="All recordings"
                      onClick={() => {
                        setShowMedia(true);
                        setType("recordings");
                      }}
                    />
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className={styles.overview}>
                  <div className={styles.label}>Overview</div>
                  <div className={styles.item}>
                    <span>Price</span>
                    <span className={styles.bold}>
                      ${numberWithCommas(listing.listPrice)}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span>Property type</span>
                    <span className={styles.bold}>
                      {listing.details.propertyType}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span>Beds</span>
                    <span className={styles.bold}>
                      {`${
                        listing.details.numBedrooms
                          ? listing.details.numBedrooms
                          : "-"
                      } ${
                        listing.details.numBedroomsPlus !== "" &&
                        listing.details.numBedroomsPlus !== null
                          ? " + " + listing.details.numBedroomsPlus
                          : ""
                      }`}{" "}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span>Baths</span>
                    <span className={styles.bold}>
                      {listing.details.numBathrooms
                        ? listing.details.numBathrooms
                        : "-"}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span>Parking</span>
                    <span className={styles.bold}>
                      {listing.details.numParkingSpaces
                        ? listing.details.numParkingSpaces
                        : listing.details.numGarageSpaces
                        ? listing.details.numGarageSpaces
                        : "-"}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span>MLS Number</span>
                    <span
                      className={`${styles.bold} ${styles.underline}`}
                      onClick={handleCopyToClipboard}>
                      {listing.mlsNumber}
                      {/* <IonIcon icon={arrowForwardCircleOutline} /> */}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span>All details</span>
                    <span
                      className={`${styles.bold} ${styles.underline}`}
                      onClick={() =>
                        history.push(`/listings/${listing.mlsNumber}`, {
                          direction: "forward",
                          listing,
                          location: location.pathname,
                        })
                      }>
                      View full listing
                      <IonIcon icon={arrowForwardCircleOutline} />
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span>Showing time</span>
                    <span className={styles.bold}>
                      {tourItem.startTime
                        ? moment(tourItem.startTime, "HH:mm").format("h:mm A")
                        : "-"}
                      -
                      {tourItem.endTime
                        ? moment(tourItem.endTime, "HH:mm").format("h:mm A")
                        : "-"}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span>Showing status</span>
                    <span className={styles.bold}>
                      {capitalize(tourItem.status)}
                    </span>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className={styles.overalRating}>
                  <div className={styles.label}>My review</div>
                  <div>
                    <Rating
                      disabled={
                        !tour || !tour.status || tour.status === "archived"
                      }
                      readOnly={
                        !tour || !tour.status || tour.status === "archived"
                      }
                      review={review}
                      size="xlg"
                      handleReview={handleReview}
                    />
                  </div>
                </IonCol>
              </IonRow>

              <IonRow className={styles.navigation}>
                {tourItems.find((i) => i.order === tourItem.order - 1) && (
                  <IonRouterLink
                    routerLink={previousRoute()}
                    className={styles.link}
                    routerDirection="back">
                    <IonCol className={styles.previous}>
                      <IonIcon icon={arrowBackCircleOutline} />
                      <span>previous</span>
                    </IonCol>
                  </IonRouterLink>
                )}
                {tourItems.find((i) => i.order === tourItem.order + 1) && (
                  <IonRouterLink
                    routerLink={nextRoute()}
                    className={styles.link}
                    routerDirection="forward">
                    <IonCol className={styles.next}>
                      <span>next</span>
                      <IonIcon icon={arrowForwardCircleOutline} />
                    </IonCol>
                  </IonRouterLink>
                )}
              </IonRow>
            </IonGrid>
            <MediaNav
              disabled={!tour || !tour.status || tour.status === "archived"}
              tourItem={tourItem}
              handlePhoto={handleNewPhoto}
              handleNote={() => {
                setShowMedia(true);
                setType("notes");
              }}
              isRecording={isAudioRecording}
              startRecording={startRecording}
              stopRecording={stopRecording}
            />
          </>
        )}
      </IonContent>
    </>
  );
};

export default memo(withAuthentication(AppClientTourDetails));
