import { API, GraphQLQuery } from "@aws-amplify/api";
import {
  CreateReviewMutation,
  CreateTourItemInput,
  CreateTourItemMutation,
  GetTourItemQuery,
  ListTourItemsQuery,
  Tour,
  TourItem,
  UpdateReviewMutation,
  UpdateTourItemInput,
  UpdateTourItemMutation,
  UpdateUserTourMutation,
} from "../API";
import {
  createTourItem as createTourItemMutation,
  deleteTourItem as deleteTourItemMutation,
  createReview,
  updateReview as updateReviewMutation,
  updateUserTour
} from "../graphql/mutations";
import { TourItemMediaService } from "./tourItemMediaService";
import { UserService } from "./userService";
import { getAgentTourItem, getClientTourItem, listAgentTourItems, listClientTourItems } from "./queries/tourItem.queries";
import { updateTourItem as updateTourItemMutation, markUserTourAsSeen } from "./mutations/tourItem.mutations";
import { AuthenticationService } from "./authenticationService";

const getTourItemsByTourId = async (tourId: string) => {
  const isUserAgent = await AuthenticationService.isCurrentUserAgent();
  const response = await API.graphql<GraphQLQuery<ListTourItemsQuery>>({
    query: isUserAgent ? listAgentTourItems : listClientTourItems,
    variables: { filter: { tourId: {eq: tourId} } },
  });

  return response.data?.listTourItems?.items as TourItem[];
};

const getTourItemById = async (id: string) => {
  const isUserAgent = await AuthenticationService.isCurrentUserAgent();
  const response = await API.graphql<GraphQLQuery<GetTourItemQuery>>({
    query: isUserAgent ? getAgentTourItem : getClientTourItem,
    variables: { id },
  });

  return response.data?.getTourItem;
};

const createTourItem = async (tourItem: CreateTourItemInput) => {
  const currentUserId = await UserService.getCurrentUserId();
  const response = await API.graphql<GraphQLQuery<CreateTourItemMutation>>({
    query: createTourItemMutation,
    variables: { input: {...tourItem, creator: currentUserId} },
  });
  return response.data?.createTourItem;
};

export const updateTourItem = async (tourItem: UpdateTourItemInput) => {
  const response = await API.graphql<GraphQLQuery<UpdateTourItemMutation>>({
    query: updateTourItemMutation,
    variables: { input: tourItem },
  });

  return response.data?.updateTourItem;
};

export const deleteTourItem = async (tourItem: TourItem) => {
  await TourItemMediaService.deleteAllMedia(tourItem);
  await API.graphql({
    query: deleteTourItemMutation,
    variables: { input: { id: tourItem.id } },
  });

  // re-arrange the order of the remaining tour items
  const tourItems = await getTourItemsByTourId(tourItem.tourId);
  const updatedTourItems = tourItems
    .sort((a, b) => a.order! - b.order!)
    .map((item, index) => ({ id: item.id, order: index + 1 }));

  await Promise.all(updatedTourItems.map((item) => updateTourItem(item)));
};

const addReview = async (tourItemId: string, rate: number) => {
  const response = await API.graphql<GraphQLQuery<CreateReviewMutation>>({query: createReview, variables: {input: {tourItemId, rate}}});
  return response.data?.createReview;
}

const updateReview = async (reviewId: string, rate: number) => {
  const response = await API.graphql<GraphQLQuery<UpdateReviewMutation>>({query: updateReviewMutation, variables: {input: {id: reviewId, rate}}});
  return response.data?.updateReview;
}

const markTourAsSeen = async (tour: Tour) => {
  const currentUserId = await UserService.getCurrentUserId();
  const userTour = tour.users?.items?.find(userTour => userTour?.userId === currentUserId);

  if (userTour) {
    await API.graphql<GraphQLQuery<UpdateUserTourMutation>>({query: markUserTourAsSeen, variables: {input: {id: userTour.id, seen: true}}});
  }
  
};

export const TourItemService = {
  getTourItemsByTourId,
  getTourItemById,
  createTourItem,
  updateTourItem,
  deleteTourItem,
  addReview,
  updateReview,
  markTourAsSeen
};
