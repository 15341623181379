import { useState, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { findProject } from "../../amplify/graphql.utils";
import ErrorPage from "../../components/Errors/error.component";
import Footer from "../../components/Footer/footer.component";
import LoadingFullPage from "../../components/Loading/loading-full-page.component";
import styles from "./pre-construction-project-details.module.scss";
import PreLeftSection from "./Sections/PreLeftSection/pre-left-section.component";
import PreRightSection from "./Sections/PreRightSection/pre-right-section.component";

const PreConstructionDetailsPage = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [project, setProject] = useState();

  const getProjectDetails = useCallback(async () => {
    try {
      setError(null);
      const project = await findProject({ id });
      setProject(project);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  }, [id]);

  useEffect(() => {
    getProjectDetails();
  }, [getProjectDetails, id]);

  return (
    <>
      {!loading && project && !error && (
        <div className={styles.detailPage}>
          <Helmet>
            <title>AECORN {project && `- ${project.name} project`}</title>
          </Helmet>
          <div>
            <PreLeftSection project={project} />
          </div>
          <div className={styles.righSection}>
            <PreRightSection project={project} />
          </div>
          <div className={styles.footer}>
            <Footer />
          </div>
        </div>
      )}
      {loading && (
        <div className={styles.loader}>
          <LoadingFullPage />
        </div>
      )}
      {error && (
        <ErrorPage
          status={error}
          from={location.state ? location.state.from : null}
          onClick={getProjectDetails}
        />
      )}
    </>
  );
};

export default PreConstructionDetailsPage;
