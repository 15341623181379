import { useState, useEffect, useCallback, memo } from "react";
import { useSelector } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import AppHeader from "../../components/Header/header.component";
import MobileEditBar from "../../components/MobileEditBar/mobile-edit-bar.component";
import FavoriteItem from "../../../pages/Favourites/component/favorite-item.component";
import AddFavourite from "../../../pages/Favourites/component/add-favourite.component";
import Card from "../../../components/Card/card.component";
import styles from "./favourites.module.scss";
import {
  getPreFavouriteItems,
  fetchFavouriteItems,
} from "../../../amplify/graphql.utils";
import { API, graphqlOperation } from "aws-amplify";
import {
  onFavouriteUpdateByUserId,
  onPreFavouriteUpdateByUserId,
} from "../../../graphql/subscriptions";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import Refresher from "../../components/Refresher/refresher.component";
import JoinContent from "../../components/CustomModals/Join/join-content.component";
import LoadingFullPage from "../../../components/Loading/loading-full-page.component";
import { useLocation } from "react-router";

const AppFavouritesPage = () => {
  const [loading, setLoading] = useState(true);
  const [sortedFavourites, setSortedFavourites] = useState();
  const [sortedPreFavourits, setSortedPreFavourites] = useState();
  const [sortBy, setSortBy] = useState({
    value: "date",
    direction: "desc",
  });

  const user = useSelector(selectCurrentUser);
  const currentClient = useSelector(selectCurrentClient);
  const location = useLocation();

  useEffect(() => {
    let subscription, preSubscription;

    const subscribe = async () => {
      subscription = await API.graphql(
        graphqlOperation(onFavouriteUpdateByUserId, {
          userId: currentClient.id,
        })
      ).subscribe({ next: () => fetchFavourites(true) });

      preSubscription = await API.graphql(
        graphqlOperation(onPreFavouriteUpdateByUserId, {
          userId: currentClient.id,
        })
      ).subscribe({ next: () => fetchPreFavourites(true) });
    };

    const fetchFavourites = async (inBackground) => {
      if (!inBackground) {
        setLoading(true);
      }

      try {
        const favourites = await fetchFavouriteItems();
        setSortedFavourites(
          favourites.length
            ? favourites.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            : null
        );
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    const fetchPreFavourites = async (inBackground) => {
      if (!inBackground) {
        setLoading(true);
      }
      try {
        const favourites = await getPreFavouriteItems();
        setSortedPreFavourites(
          favourites.length
            ? favourites.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            : null
        );
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    if (currentClient) {
      fetchFavourites();
      // fetchPreFavourites();
      subscribe();
    }

    return () => {
      if (subscription) subscription.unsubscribe();
      if (preSubscription) preSubscription.unsubscribe();
    };
  }, [currentClient]);

  useEffect(() => {
    const sortItems = () => {
      const temp = [...sortedFavourites];
      switch (sortBy.value) {
        case "date":
          temp.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
          break;
        case "status":
          temp
            .sort((a, b) =>
              JSON.parse(a.listing).type > JSON.parse(b.listing).type ? 1 : -1
            )
            .sort((a, b) =>
              JSON.parse(a.listing).lastStatus >
              JSON.parse(b.listing).lastStatus
                ? 1
                : -1
            )
            .reverse();

          break;
        case "price":
          temp.sort((a, b) =>
            +JSON.parse(a.listing).listPrice > +JSON.parse(b.listing).listPrice
              ? 1
              : -1
          );
          break;
        case "beds":
          temp.sort((a, b) =>
            +JSON.parse(a.listing).details.numBedrooms >
            +JSON.parse(b.listing).details.numBedrooms
              ? 1
              : -1
          );
          break;
        case "baths":
          temp.sort((a, b) =>
            +JSON.parse(a.listing).details.numBathrooms >
            +JSON.parse(b.listing).details.numBathrooms
              ? 1
              : -1
          );
          break;
        case "sqft":
          temp.sort((a, b) =>
            +JSON.parse(a.listing).details.sqft >
            +JSON.parse(b.listing).details.sqft
              ? 1
              : -1
          );
          break;
        default:
          return;
      }
      sortBy.direction === "desc"
        ? setSortedFavourites([...temp.reverse()])
        : setSortedFavourites([...temp]);
    };
    if (sortedFavourites) {
      sortItems();
    }
  }, [sortBy]);

  const handleRefresh = async (ref) => {
    fetchFavouriteItems();
    ref.complete();
  };

  useEffect(() => {
    if (!user) setSortedFavourites(null);
  }, [user]);

  const renderPreCards = useCallback(() => {
    if (sortedPreFavourits) {
      return sortedPreFavourits.map((favourite) => (
        <FavoriteItem key={favourite.id} item={favourite} isPre isApp={true} />
      ));
    }
  }, [sortedPreFavourits]);

  const renderCards = useCallback(() => {
    if (sortedFavourites) {
      if (sortedFavourites.length) {
        const cards = sortedFavourites.map((favourite) => (
          <FavoriteItem
            key={`${favourite.id}_${favourite.notification.toString()}`}
            item={favourite}
            isApp={true}
          />
        ));

        return (
          <div>
            {renderPreCards()}
            {cards}
          </div>
        );
      } else {
        return (
          <div className={styles.cardsContainer}>
            <div className={styles.cards}>
              <div className={styles.card}>
                <AddFavourite />
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className={styles.cardsContainer}>
          <div className={styles.cards}>
            <Card add={true}>
              <AddFavourite isApp={true} />
            </Card>
          </div>
        </div>
      );
    }
  }, [renderPreCards, sortedFavourites]);

  return (
    <IonPage>
      <AppHeader
        title={user ? "Favourites" : "Join"}
        hasRightButton={user}
        hasBackButton={
          location.pathname.includes("notifications") ? true : false
        }
      />

      <IonContent className={user && styles.content}>
        {user ? (
          loading ? (
            <LoadingFullPage />
          ) : (
            <>
              <Refresher background="primary" onRefresh={handleRefresh} />
              <MobileEditBar
                sortBy={sortBy}
                setSortBy={setSortBy}
                mode="favourites"
                disabled={!sortedFavourites || !sortedFavourites.length}
              />
              <div className={styles.container}>{renderCards()}</div>
            </>
          )
        ) : (
          <JoinContent />
        )}
      </IonContent>
    </IonPage>
  );
};

export default memo(AppFavouritesPage);
