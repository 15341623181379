import { API } from "aws-amplify";
import { GeneralConstants } from "../utils/constants";
import { Listing } from "../models/listings/listing.model";
import { Locations } from "models/locations/locations.model";

const getListingByMlsNumber = async (mlsNumber: string) => {
  const response = await API.get(
    GeneralConstants.REST_API_NAME,
    `/listings/${mlsNumber}`,
    {}
  );
  return response.data as Listing;
};

const searchListingsAndLocations = async (term: string, activeListingsOnly = false) => {
  const response = await API.get(
    GeneralConstants.REST_API_NAME,
    "/listings/search",
    {
      queryStringParameters: { term, activeListingsOnly },
    }
  );

  const listings = response.data.listings as Listing[];
  const locations = response.data.locations as Locations;

  return { listings, locations };
};


export const ListingService = {
  getListingByMlsNumber,
  searchListingsAndLocations,
};
