import {
  capitalize,
  numberWithCommas,
} from "../../../../../../../../utils/functions";
import styles from "./pre-details.module.scss";

const PROJECT_STATUS = {
  preconstruction: "Pre construction",
  underconstruction: "Under construction",
  completed: "Completed",
};

const PreDetails = ({ project }) => {
  const {
    types,
    units,
    stories,
    minBeds,
    maxBeds,
    phase,
    minSqft,
    maxSqft,
    pricePerSqft,
    maintenanceFees,
    occupancy,
    builder,
    architect,
    interiorDesigner,
  } = project;
  return (
    <div className={styles.preDetails}>
      <div className={styles.infos}>
        <div className={styles.details}>
          <div>
            <span className={styles.bold}>Property type</span>
            <span>
              {types && types.length !== 0
                ? types.map((type, i) => (
                    <span key={i}>
                      {capitalize(type)}
                      {types.length > 1 && i < types.length - 1 ? "," : ""}{" "}
                    </span>
                  ))
                : "-"}
            </span>
          </div>

          <div>
            <span className={styles.bold}>Units/Stories</span>
            <span>
              {units ? units : "-"} units | {stories ? stories : "-"} stories
            </span>
          </div>

          <div>
            <span className={styles.bold}>Bedroom range</span>
            <span>
              {minBeds} {maxBeds !== 0 && `- ${maxBeds}`} bedrooms
            </span>
          </div>

          <div>
            <span className={styles.bold}>Phase</span>
            <span>{phase ? PROJECT_STATUS[phase] : "-"}</span>
          </div>

          <div>
            <span className={styles.bold}>Sqft range</span>
            <span>
              {minSqft !== 0 && `${numberWithCommas(minSqft)} - `}{" "}
              {maxSqft !== 0 ? numberWithCommas(maxSqft) : "-"} sqft
            </span>
          </div>

          <div>
            <span className={styles.bold}>Price per sqft</span>
            <span>
              {pricePerSqft ? `${numberWithCommas(pricePerSqft, true)}` : "-"}{" "}
              per sqft
            </span>
          </div>

          <div>
            <span className={styles.bold}>Maintenance fees</span>
            <span>
              {maintenanceFees
                ? `${numberWithCommas(maintenanceFees, true)} per sqft`
                : "-"}
            </span>
          </div>

          <div>
            <span className={styles.bold}>Estimated completion</span>
            <span>{occupancy ? occupancy : "-"}</span>
          </div>

          <div>
            <span className={styles.bold}>Builder</span>
            <span>{builder ? builder.name : "-"}</span>
          </div>

          <div>
            <span className={styles.bold}>Architect</span>
            <span>{architect ? architect.name : "-"}</span>
          </div>

          <div>
            <span className={styles.bold}>Interior designer</span>
            <span>{interiorDesigner ? interiorDesigner.name : "-"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreDetails;
