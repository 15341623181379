import { useState } from "react";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import AgentDetails from "./component/AgentDetails/agent-details.component";
import TourDetails from "./component/TourDetails/tour-details.component";
import TourAttendees from "./component/TourAttendees/tour-attendees.component";
import AppHeader from "../Header/header.component";
import { useHistory } from "react-router";
import { ToastService } from "../../../services/toastService";
import { TourService } from "../../../services/tourService";

const NewTour = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [draftTour, setDraftTour] = useState({});
  const [step, setStep] = useState("agentDetails");

  const changeStep = (newStep, values) => {
    if (values) {
      setDraftTour({ ...draftTour, ...values });
    }
    setStep(newStep);
  };

  const handleBack = () => {
    switch (step) {
      case "agentDetails":
        history.goBack();
        break;
      case "tourDetails":
        changeStep("agentDetails");
        break;
      case "tourAttendees":
        changeStep("tourDetails");
        break;
    }
  };

  const submit = async (values) => {
    const newTour = { ...draftTour, ...values };

    try {
      setLoading(true);
      const createdTour = await TourService.createTour({
        title: newTour.title,
        date: newTour.date,
        startTime: newTour.startTime,
        endTime: newTour.endTime,
        generalNote: newTour.generalNote,
        meetupLocation: newTour.meetupLocation,
        meetupTime: newTour.meetupTime,
        meetupDescription: newTour.meetupDescription,
        privateNote: newTour.privateNote,
      }, newTour.leadAgent, newTour.showingAgents, newTour.client, newTour.guests);
      ToastService.showToast("Tour created successfully.", ToastService.ToastType.SUCCESS);
      history.replace(`/tabs/tours/${createdTour.id}`);
    } catch(err) {
      console.log(err);
      ToastService.showToast("Error creating tour.", ToastService.ToastType.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const renderCurrentStep = () => {
    switch (step) {
      case "agentDetails":
        return (
          <AgentDetails
            onNext={(values) => changeStep("tourDetails", values)}
            tour={draftTour}
          />
        );
      case "tourDetails":
        return (
          <TourDetails
            onNext={(values) => changeStep("tourAttendees", values)}
            tour={draftTour}
          />
        );
      case "tourAttendees":
        return (
          <TourAttendees
            onNext={submit}
            tour={draftTour}
          />
        );
  }
};

  return (
    <IonPage>
      <AppHeader title="New tour" hasBackButton={false} hasRightButton={false} customBack={handleBack} />
      <IonContent>{renderCurrentStep()}</IonContent>
    </IonPage>
  );
};

export default NewTour;
