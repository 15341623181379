import { useState, useEffect } from "react";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import PreFloorPlans from "./components/pre-floor-plans.component";
import styles from "./pre-floor-plans-and-pricing.module.scss";

const PreFloorPlansAndPricing = ({ project, isApp = false }) => {
  const [units, setUnits] = useState(
    project.projectUnits && project.projectUnits.items.length > 0
      ? project.projectUnits.items
      : []
  );
  const [segment, setSegment] = useState("studio");
  const [studio, setStudio] = useState([]);
  const [oneBed, setOneBed] = useState([]);
  const [twoBeds, setTwoBeds] = useState([]);
  const [threeBeds, setThreeBeds] = useState([]);

  useEffect(() => {
    const updateUnits = () => {
      setUnits(project.projectUnits.items);
    };

    updateUnits();
  }, [project]);

  useEffect(() => {
    const getUnitsByBedroomsNum = () => {
      const std = units.filter((unit) => unit.numBeds == 0);
      setStudio(std);

      const oneBedroom = units.filter((unit) => unit.numBeds == 1);
      setOneBed(oneBedroom);

      const twoBedrooms = units.filter((unit) => unit.numBeds == 2);
      setTwoBeds(twoBedrooms);

      const threeBedrooms = units.filter((unit) => unit.numBeds >= 3);
      setThreeBeds(threeBedrooms);
    };

    getUnitsByBedroomsNum();
  }, [units]);

  return (
    <div className={styles.preFloorPlansAndPricing}>
      <div className={styles.infos}>
        <div className={styles.details}>
          <div className={`${styles.segments} ${styles.row}`}>
            <IonSegment
              onIonChange={(e) => setSegment(e.detail.value)}
              className={styles.segment}
              value={segment}
            >
              <IonSegmentButton value="studio">
                <IonLabel>{isApp ? "Studio" : "Studio"}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="oneBed">
                <IonLabel>{isApp ? "1bed" : "1 Bedroom"}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="twoBeds">
                <IonLabel>{isApp ? "2bed" : "2 Bedroom"}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="threeBeds">
                <IonLabel>{isApp ? "3bed" : "3 Bedroom"}</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>

          <div>
            {segment === "studio" ? (
              studio && studio.length > 0 ? (
                studio.map((item, index) => (
                  <div key={index} className={styles.row} id={item.id}>
                    <PreFloorPlans item={item} isApp={isApp} />
                  </div>
                ))
              ) : (
                <div className={styles.notAvl}> Not available </div>
              )
            ) : segment === "oneBed" ? (
              oneBed && oneBed.length > 0 ? (
                oneBed.map((item, index) => (
                  <div key={index} className={styles.row} id={item.id}>
                    <PreFloorPlans item={item} isApp={isApp} />
                  </div>
                ))
              ) : (
                <div className={styles.notAvl}> Not available </div>
              )
            ) : segment === "twoBeds" ? (
              twoBeds && twoBeds.length > 0 ? (
                twoBeds.map((item, index) => (
                  <div key={index} className={styles.row} id={item.id}>
                    <PreFloorPlans item={item} isApp={isApp} />
                  </div>
                ))
              ) : (
                <div className={styles.notAvl}> Not available </div>
              )
            ) : segment === "threeBeds" ? (
              threeBeds && threeBeds.length > 0 ? (
                threeBeds.map((item, index) => (
                  <div key={index} className={styles.row} id={item.id}>
                    <PreFloorPlans item={item} isApp={isApp} />
                  </div>
                ))
              ) : (
                <div className={styles.notAvl}> Not available </div>
              )
            ) : (
              <div className={styles.notAvl}>Not available</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreFloorPlansAndPricing;
