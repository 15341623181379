import styles from "./tour-attendees.module.scss";
import { TourRoles } from "../../../../../../utils/constants";

const TourAttendees = ({ tourAttendees }) => {
  return (
    <div className={styles.tourAttendees}>
      {tourAttendees.map((item, index) => (
        <div key={index}>
          <span className={styles.bold}>{TourRoles[item.role]}</span>
          <span>{`${item.user.givenName} ${item.user.familyName}`}</span>
        </div>
      ))}
    </div>
  );
};

export default TourAttendees;
