import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DetailPageCard from "./components/DetailPageCard/detail-page-card.component";
import DetailCardHeader from "./components/DetailPageCardHeader/detail-card-header.component";
import DetailPageCarousel from "./components/DetailPageCarousel/detail-page-carousel.component";
import DetailPageButtons from "./components/DetailPageButtons/detail-page-buttons.component";
import DetailPageDescription from "./components/DetailPageDescription/detail-page-description.component";
import DetailPageInformation from "./components/DetailPageInformation/detail-page-information.component";
import noImage from "../../../../assets/img/no-photo.png";
import {
  getImageOrFallback,
  handleAppNavigation,
} from "../../../../utils/functions";
import { openModal } from "../../../../redux/ui/ui.actions";
import styles from "./left-section.module.scss";
import {
  updateActiveListing,
  updateDetailsListing,
  updateSelectedListing,
} from "../../../../redux/map/map.actions";
import { useHistory } from "react-router";

const LeftSection = ({
  currentClient,
  clientLoading,
  user,
  listing,
  schools,
  schoolsLoading,
  language,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  //Display only images that actually load
  const [imagesLoading, setImagesLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState([]);
  useEffect(() => {
    const loadImages = async () => {
      setImagesLoading(true);
      const images = listing.images;

      const loadedImages = [];
      if (images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          const result = await getImageOrFallback(
            `https://cdn.repliers.io/${images[i]}`
          );
          if (result) {
            loadedImages.push(result);
          }
        }
      }

      setLoadedImages(
        loadedImages.length > 0
          ? loadedImages.map((image) => ({ src: image }))
          : [{ src: noImage }]
      );
      setImagesLoading(false);
    };

    loadImages();
  }, [listing]);

  const virtualTourHandler = () => {
    let newWindow;
    listing.details.virtualTourUrl.length > 0 &&
      (newWindow = window.open(
        listing.details.virtualTourUrl,
        "_blank",
        "noopener,noreferrer"
      ));
    if (newWindow) newWindow.opener = null;
  };

  const navigateHandler = () => {
    handleAppNavigation({
      map: listing.map,
      address: listing.address,
      byAddress: true,
    });
  };

  const mapViewHandler = () => {
    dispatch(updateSelectedListing(null));
    dispatch(updateDetailsListing({ listing, view: true }));
    history.push("/listings");
  };

  const offerFormHandler = () => {
    dispatch(
      openModal({
        current: "requestForm",
        overflow: true,
        data: {
          offer: true,
          address: listing.address,
          mlsNumber: listing.mlsNumber,
          status: listing.type,
          active: listing.status === "A" ? true : false,
          price: listing.listPrice,
        },
      })
    );
  };

  return (
    <div className={styles.container}>
      <DetailPageCard>
        <DetailCardHeader
          currentClient={currentClient}
          clientLoading={clientLoading}
          listing={listing}
        />
        <DetailPageCarousel
          images={loadedImages}
          loading={imagesLoading}
          location={listing.map}
          showMap={false}
        />
        <DetailPageButtons
          listing={listing}
          virtualTourHandler={virtualTourHandler}
          hasVirtualTour={
            listing.details.virtualTourUrl.length > 0 ? true : false
          }
          mapViewHandler={mapViewHandler}
          showMap={false}
          navigateHandler={navigateHandler}
          language={language}
        />
        <DetailPageDescription
          listing={listing}
          offerFormHandler={offerFormHandler}
          language={language}
        />
        <DetailPageInformation
          schools={schools}
          schoolsLoading={schoolsLoading}
          listing={listing}
          user={user}
          language={language}
        />
      </DetailPageCard>
    </div>
  );
};

export default LeftSection;
