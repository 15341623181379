import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  PriceDataFormater,
  numberWithCommas,
} from "../../../../../../../../../../../utils/functions";
import styles from "../median-sold-price-with-avg-dom.module.scss";

const MedianSoldPriceWithAvgDOMChart = ({ marketData, isApp }) => {
  const CustomizedTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customTooltip}>
          <div className={styles.date}>{payload[0]["payload"]["date"]}</div>
          <div className={styles.area}>{`${
            payload[0]["name"]
          } : ${numberWithCommas(payload[0].value, true)}`}</div>
          <div
            className={styles.bar}
          >{`${payload[1]["name"]} : ${payload[1].value}`}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      styles={{ position: "inherit" }}
    >
      <>
        <ComposedChart
          width={isApp ? 350 : 700}
          height={350}
          data={marketData}
          syncId="anyId"
          margin={{
            top: 10,
            right: 0,
            bottom: 20,
            left: -15,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey="date"
            scale="band"
            angle={-45}
            padding={{ top: 15 }}
            tickMargin={22}
            stroke="#6e6e6e"
            style={{ fontSize: "1.2rem" }}
          />
          <YAxis
            tickFormatter={PriceDataFormater}
            stroke="#6e6e6e"
            style={{ fontSize: "1.4rem" }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            stroke="#6e6e6e"
            style={{ fontSize: "1.4rem" }}
          />
          <Tooltip content={<CustomizedTooltip />} />
          <Legend
            verticalAlign="top"
            layout="vertical"
            align="center"
            style={{ marginBottom: "8rem" }}
            margin={{
              top: 0,
              right: 0,
              bottom: 20,
              left: 0,
            }}
          />
          <Area
            type="monotone"
            dataKey="Median Sold Price"
            fill="#1aaecc"
            stroke="#007b94"
            fillOpacity={0.15}
          />
          <Bar
            yAxisId="right"
            dataKey="Average DOM"
            barSize={20}
            fill="#6e6e6e"
            // stroke="#6e6e6e"
            fillOpacity={0.7}
          />
        </ComposedChart>

        <div
          style={{
            marginTop: "4rem",
            color: "#6e6e6e",
            fontSize: "1.8rem",
            fontWeight: "600",
          }}
        >
          Average sold price and average days on market in this area *
        </div>

        <ComposedChart
          width={isApp ? 350 : 700}
          height={350}
          data={marketData}
          syncId="anyId"
          margin={{
            top: 10,
            right: 0,
            bottom: 20,
            left: -15,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey="date"
            scale="band"
            angle={-45}
            padding={{ top: 15 }}
            tickMargin={22}
            stroke="#6e6e6e"
            style={{ fontSize: "1.2rem" }}
          />
          <YAxis
            tickFormatter={PriceDataFormater}
            stroke="#6e6e6e"
            style={{ fontSize: "1.4rem" }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            stroke="#6e6e6e"
            style={{ fontSize: "1.4rem" }}
          />
          <Tooltip content={<CustomizedTooltip />} />
          <Legend verticalAlign="top" layout="vertical" />
          <Area
            type="monotone"
            dataKey="Average Sold Price"
            fill="#1aaecc"
            stroke="#007b94"
            fillOpacity={0.15}
          />
          <Bar
            yAxisId="right"
            dataKey="Average DOM"
            barSize={20}
            fill="#6e6e6e"
            // stroke="#6e6e6e"
            fillOpacity={0.7}
          />
        </ComposedChart>
      </>
    </ResponsiveContainer>
  );
};

export default MedianSoldPriceWithAvgDOMChart;
