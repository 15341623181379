import styles from "./details.module.scss";
import { useState, useEffect, useRef, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import LoadingFullPage from "../../../components/Loading/loading-full-page.component";
import ListingImages from "./components/ListingImages/listing-images.component";
import ListingHeader from "./components/ListingHeader/listing-header.component";
import ListingDetails from "./components/ListingDetails/listing-details.component";
import ListingFooter from "./components/ListingFooter/listing-footer.component";
import ErrorPage from "../../../components/Errors/error.component";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { markNotification } from "../../../redux/ui/ui.actions";
import {
  updateActiveListing,
  updateDetailsListing,
} from "../../../redux/map/map.actions";
import { useParams } from "react-router";
import { ListingService } from "../../../services/listingService";
import { FavoritesService } from "../../../services/favoritesService";

const AppDetailsPage = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const imagesRef = useRef();
  const [error, setError] = useState();
  const [scroll, setScroll] = useState(true);
  const isAuthenticated = useSelector(selectCurrentUser);
  const { id } = useParams();

  const [listings, setListings] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchListingData = useCallback(
    async (id) => {
      setError(null);
      const listing = await ListingService.getListingByMlsNumber(id);

      if (listing && listing.status === "U" && !isAuthenticated) {
        setError(401);
        return;
      }
      if (listing && isAuthenticated) {
        dispatch(markNotification(listing.mlsNumber));
        FavoritesService.updateFavoriteListing(listing);
      }

      return listing;
    },
    [dispatch, isAuthenticated]
  );

  useEffect(() => {
    const loadListing = async () => {
      if (!listings[id]) {
        setLoading(true);
        try {
          const listing = await fetchListingData(id);
          setListings((prevListings) => ({...prevListings, [id]: listing}));
        } catch (err) {
          setError(err.response.status);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    loadListing();
  }, [
    id,
    listings,
    fetchListingData
  ]);

  const listing = listings[id];

  useEffect(() => {
    if (error === 401 && isAuthenticated) setError(null);
  }, [isAuthenticated, error]);

  useEffect(() => {
    dispatch(updateActiveListing(null));
    dispatch(updateDetailsListing(null));
  }, [dispatch]);

  const renderContent = () => {
    return (
      <>
        <div
          className={`${styles.headerContainer} ${
            listing.images &&
            listing.images.length > 0 &&
            styles.purpleBackground
          }`}>
          <ListingHeader />
          <ListingImages images={listing.images} imagesRef={imagesRef} />
        </div>
        <ListingDetails
          listing={listing}
          parentRef={ref.current}
          imagesRef={imagesRef}
          scroll={scroll}
          setScroll={setScroll}
        />
        <ListingFooter listing={listing} parentRef={ref.current} />
      </>
    );
  };

  return (
    <IonPage ref={ref}>
      <IonContent
        scrollY={true}
        className={styles.ioncontent}
        forceOverscroll={false}>
        {
          <>
            {error && <ErrorPage status={error}  isApp />}
            {loading && <LoadingFullPage details  />}
            {listing && renderContent()}
          </>
        }
      </IonContent>
    </IonPage>
  );
};
export default memo(AppDetailsPage);
