import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonModal,
  getPlatforms,
} from "@ionic/react";
import {
  rocketOutline,
  statsChartOutline,
  chatbubbleOutline,
  addCircleOutline,
  personOutline,
  exitOutline,
  searchOutline,
  megaphoneOutline,
} from "ionicons/icons";
import { signOutStart } from "../../../redux/user/user.actions";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import AppHeader from "../../components/Header/header.component";
import styles from "./user-account.module.scss";
import Profile from "../../components/Profile/profile.component";
import Invite from "../../components/CustomModals/Invite/invite.component";
import { SocialSharing } from "@ionic-native/social-sharing";
import { useHistory } from "react-router-dom";
import { updateClient } from "../../../amplify/graphql.utils";
import { App } from "@capacitor/app";
import jsonObj from "../../../../package.json";
import { isCapacitor } from "../../../utils/functions";

const UserAccountPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const ref = useRef();
  const history = useHistory();
  const user = useSelector(selectCurrentUser);
  const client = useSelector(selectCurrentClient);
  const [appVersion, setAppVersion] = useState("");
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showInvite, setShowInvite] = useState(false);

  const handleSignOut = async () => {
    if (getPlatforms().includes("capacitor"))
      await updateClient({
        id: client.id,
        updates: { deviceToken: null },
      });
    dispatch(signOutStart());
  };

  useEffect(() => {
    if (location && location.search === "?alerts") {
      if (user) {
        setShowProfileModal(true);
        history.replace("/tabs/more");
      } else {
        history.replace("/tabs/more");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, user]);

  useEffect(() => {
    if (isCapacitor()) {
      App.getInfo().then((info) => {
        setAppVersion(info.version);
      });
    } else {
      setAppVersion(jsonObj.version);
    }
   
  }, [])

  const handleInvite = async () => {
    if (!getPlatforms().includes("capacitor")) {
      setShowInvite(true);
    } else {
      const text = `Join AECORN!`;

      const options = {
        message: text,
        subject: text,
        url: `https://aecorn.ca/`,
        chooserTitle: "Pick an app",
      };

      await SocialSharing.shareWithOptions(options);
    }
  };

  return (
    <IonPage ref={ref}>
      <AppHeader title="More" isProfilePage={true} hasBackButton={false} />
      <IonContent className={styles.userAccount}>
        <IonModal
          isOpen={showProfileModal}
          onDidDismiss={() => setShowProfileModal(false)}
          swipeToClose={true}
          presentingElement={ref.current}>
          {client && (
            <Profile
              setShowModal={setShowProfileModal}
              handleSignOut={handleSignOut}
              client={client}
              user={user}
            />
          )}
        </IonModal>

        <IonModal
          isOpen={showInvite}
          onDidDismiss={() => setShowInvite(false)}
          swipeToClose={true}
          presentingElement={ref.current}>
          <Invite setIsOpen={setShowInvite} />
        </IonModal>

        <div className={styles.mainContent}>
          <div className={styles.greeting}>
            {user ? (
              <span>Hello {client && client.givenName}!</span>
            ) : (
              <span>Hello there!</span>
            )}
            <div>How are you today?</div>
          </div>

          <div className={styles.options}>
            <IonItem>
              {user ? (
                <IonLabel onClick={() => setShowProfileModal(true)}>
                  <IonIcon icon={personOutline} slot="start" /> Account settings
                </IonLabel>
              ) : (
                <IonLabel onClick={() => history.push("/signin")}>
                  <IonIcon icon={personOutline} slot="start" /> Sign in
                </IonLabel>
              )}
            </IonItem>
            <IonItem routerLink="/tabs/more/searches" detail={false}>
              <IonLabel>
                <IonIcon src={searchOutline} slot="start" />
                Saved searches
              </IonLabel>
            </IonItem>
            <IonItem onClick={() => history.push("/tabs/more/about-us")}>
              <IonLabel>
                <IonIcon icon={rocketOutline} slot="start" /> About us
              </IonLabel>
            </IonItem>
            <IonItem onClick={() => history.push("/tabs/more/market-data")}>
              <IonLabel>
                <IonIcon icon={statsChartOutline} slot="start" /> Market data
              </IonLabel>
            </IonItem>
            <IonItem onClick={() => history.push("/tabs/more/feedback")}>
              <IonLabel>
                <IonIcon icon={megaphoneOutline} slot="start" /> feedback
              </IonLabel>
            </IonItem>
            <IonItem onClick={() => history.push("/tabs/more/contact-us")}>
              <IonLabel>
                <IonIcon icon={chatbubbleOutline} slot="start" /> contact
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel onClick={handleInvite}>
                <IonIcon icon={addCircleOutline} slot="start" /> invite others
              </IonLabel>
            </IonItem>
          </div>
          <div className={styles.buttons}>
            {user ? (
              <IonButton
                shape="round"
                className={styles.logout}
                onClick={handleSignOut}>
                <IonIcon icon={exitOutline} />
                Log out
              </IonButton>
            ) : (
              <>
                <IonButton
                  shape="round"
                  className={styles.register}
                  onClick={() => history.push("/signup")}>
                  Register for an account
                </IonButton>
                <div className={styles.login}>
                  Already have an account?
                  <div onClick={() => history.push("/signin")}>Sign in</div>
                </div>
              </>
            )}
          </div>
          <div className={styles.infos}>
            <div>
              <Link to="/more/terms">Terms of Use</Link> |{" "}
              <Link to="/more/privacy">Privacy Policy </Link> | App version:{" "}
              {appVersion}
            </div>
            <div>©2024 AECORN Realty Inc. All rights reserved.</div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default UserAccountPage;
