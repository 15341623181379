import styles from "./school-details.module.scss";
import Card from "../../components/Card/card.component";
import School from "../../app/pages/SchoolDetails/school-details.component";
import Footer from "../../components/Footer/footer.component";

const SchoolDetails = (props) => {
  return (
    <div className={styles.schoolDetails}>
      <div className={styles.container}>
        <Card>
          <School isApp={false} {...props} />
        </Card>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};
export default SchoolDetails;
