import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../Form/Button/button.component";
import Checkbox from "../../Form/Checkbox/checkbox-component";
import { editProfileType } from "../../../data/customModal";
import styles from "./edit-profile-type-modal.module.scss";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import { fetchClientStart } from "../../../redux/client/client.actions";
import { ProfileService } from "../../../services/profileService";

const EditProfileTypeModal = ({ setType, language }) => {
  const dispatch = useDispatch();
  const client = useSelector(selectCurrentClient);
  const {
    buyingSelling,
    leasingRenting,
    justLooking,
    preConstruction,
    isRealtor,
  } = client.profile;
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState({
    sale: buyingSelling,
    lease: leasingRenting,
    looking: justLooking,
    preConstruction: preConstruction,
  });
  const [realtor, setRealtor] = useState(isRealtor);

  const handleSubmit = async () => {
    setLoading(true);
    const profile = {
      id: client.profile.id,
      buyingSelling: types.sale,
      leasingRenting: types.lease,
      justLooking: types.looking,
      preConstruction: types.preConstruction,
      isRealtor: realtor,
    };
    await ProfileService.updateProfile(profile);
    dispatch(fetchClientStart());
    setLoading(false);
    setType("updatedProfileType");
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>{editProfileType[language].header}</div>
      <div className={styles.subtitle}>
        <span>{editProfileType[language].subtitle}</span>
      </div>

      <div className={styles.content}>
        <div className={styles.firstRow}>
          <div className={styles.label}>
            {editProfileType[language].label_1}
          </div>
          <div className={styles.options}>
            <div>
              <Checkbox
                checked={types.sale}
                onClick={() => setTypes({ ...types, sale: !types.sale })}
                label={editProfileType[language].buyORsale}
              />
            </div>
            <div>
              <Checkbox
                checked={types.lease}
                onClick={() => setTypes({ ...types, lease: !types.lease })}
                label={editProfileType[language].leaseORrent}
              />
            </div>
            <div>
              <Checkbox
                checked={types.preConstruction}
                onClick={() =>
                  setTypes({
                    ...types,
                    preConstruction: !types.preConstruction,
                  })
                }
                label={editProfileType[language].preConstruction}
              />
            </div>
            <div style={{ marginTop: "1rem" }}>
              <Checkbox
                checked={types.looking}
                onClick={() => setTypes({ ...types, looking: !types.looking })}
                label={editProfileType[language].looking}
              />
            </div>
          </div>
        </div>
        <div className={styles.firstRow}>
          <div className={styles.label}>
            {editProfileType[language].label_2}
          </div>
          <div className={styles.options}>
            <div>
              <Checkbox
                checked={!realtor}
                onClick={() => setRealtor(false)}
                label={editProfileType[language].notAgent}
              />
            </div>
            <div>
              <Checkbox
                checked={realtor}
                onClick={() => setRealtor(true)}
                label={editProfileType[language].isAgent}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <Button
          title={editProfileType[language].button}
          type="green"
          style={{ height: "5rem", width: "100%", border: "1px solid #1F7A60" }}
          loading={loading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default EditProfileTypeModal;
