import { useState, useRef, createRef } from "react";
import Input from "../../Form/Input/input.component";
import Button from "../../Form/Button/button.component";
import { changePassword } from "../../../data/customModal";
import styles from "./change-password-modal.module.scss";
import { AuthenticationService } from "../../../services/authenticationService";

const ChangePasswordModal = ({ setType, language }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const inputRefs = useRef({
    current_password: createRef(),
    new_password: createRef(),
    confirm_password: createRef(),
  });

  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdatePassword = async () => {
    if (
      inputRefs.current.current_password.current.validate(true) &&
      inputRefs.current.new_password.current.validate(true) &&
      inputRefs.current.confirm_password.current.validate(true)
    ) {
      try {
        setLoading(true);
        await AuthenticationService.updatePassword(
          formData.current_password,
          formData.new_password
        );
        setType("updatedPassword");
      } catch (err) {
        setLoading(false);
        setError("Incorrect current password.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>{changePassword[language].header}</div>
      <div className={styles.content}>
        <div className={styles.firstRow}>
          <div className={styles.subtitle}>
            {changePassword[language].subtitle_1}
          </div>
          <Input
            required
            name="current_password"
            label={changePassword[language].oldPassword}
            placeholder={changePassword[language].oldPassword}
            type="password"
            ref={inputRefs.current.current_password}
            value={formData["current_password"]}
            onChange={(value, name) => handleChange(value, name)}
            validation="required"
          />
        </div>
        <div className={styles.secondRow}>
          <div className={styles.subtitle}>
            {changePassword[language].subtitle_2}
          </div>
          <Input
            required
            name="new_password"
            label={changePassword[language].newPassword}
            placeholder={changePassword[language].newPassword}
            type="password"
            ref={inputRefs.current.new_password}
            value={formData["new_password"]}
            onChange={(value, name) => handleChange(value, name)}
            validation="required|password"
          />
          <Input
            required
            name="confirm_password"
            label={changePassword[language].confirmNewPassword}
            placeholder={changePassword[language].confirmNewPassword}
            type="password"
            ref={inputRefs.current.confirm_password}
            value={formData["confirm_password"]}
            onChange={(value, name) => handleChange(value, name)}
            validation={`required|confirmPassword:${formData["new_password"]}`}
          />
        </div>
      </div>
      {error && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
      <div className={styles.button}>
        <Button
          title={changePassword[language].button}
          type="green"
          style={{
            height: "5rem",
            width: "100%",
            border: "1px solid #1F7A60",
          }}
          onClick={handleUpdatePassword}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ChangePasswordModal;
