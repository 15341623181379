import { IonButton, IonIcon } from "@ionic/react";
import { chatbubblesOutline, mailOutline } from "ionicons/icons";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import DetailsHeader from "../../../../components/TourDetailsHeader/tour-details-header.module";
import styles from "./quick-contact.module.scss";
import withAuthentication from "../../../../HOC/withAuthentication/with-authentication";
import { TourService } from "../../../../services/tourService";

const QuickContact = ({ tour, isOpen, setIsOpen }) => {
  const users = TourService.getTourUsers(tour);

  return (
    <CardContainer>
      <div className={styles.attendees}>
        <DetailsHeader
          title="Quick contact"
          icon={chatbubblesOutline}
          isVisible={true}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />

        {isOpen && (
          <div className={styles.inputs}>
            {users.map((user, index) => (
              <div key={index} className={styles.contact}>
                <div className={styles.title}>{user.title}</div>
                <div className={styles.infos}>
                  <span className={styles.bold}>{user.displayName}</span>
                  <span>{user.phone}</span>
                  <span>{user.email}</span>
                </div>
                <div className={styles.btn}>
                  <IonButton
                    expand="block"
                    className={styles.button}
                    href={`mailto:${user.email}`}
                    target="_blank">
                    <IonIcon icon={mailOutline} />
                  </IonButton>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </CardContainer>
  );
};

export default withAuthentication(QuickContact);
