import { useState, useEffect } from "react";
import Footer from "../../components/Footer/footer.component";
import CardContainer from "./component/CardContainer/card-container.component";
import NewTour from "./component/NewTour/new-tour.component";
import Topbar from "./component/Topbar/topbar.component";
import TourItem from "./component/TourItem/tour-item.component";
import styles from "./tours.module.scss";
import withAuthentication from "../../HOC/withAuthentication/with-authentication";
import NoTourItem from "./component/NoTourItem/no-tour-item.component";
import { isBeforeOrAfter } from "../../utils/functions";
import Pagination from "./component/Pagination/pagination.component";
import { useIonViewDidEnter } from "@ionic/react";
import { TourService } from "../../services/tourService";
import LoadingFullPage from "../../components/Loading/loading-full-page.component";
const PER_PAGE = 5;

const Tours = () => {
  const [loading, setLoading] = useState(true);
  const [tours, setTours] = useState([]);
  const [sortedTours, setSortedTours] = useState({
    upcoming: [],
    past: [],
    archived: [],
  });
  const [page, setPage] = useState(1);
  const [type, setType] = useState("upcoming");

  const [sortBy, setSortBy] = useState({
    value: "date",
    direction: "desc",
  });

  const loadTours = async () => {
    try {
      setLoading(true);
      const items = await TourService.getAgentTours();
      setTours(items);
    } catch (err) {
      setTours([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTours();
  }, []);

  useIonViewDidEnter(() => {
    const loadData = async () => {
      await loadTours();
    };
    loadData();
  }, []);

  const handleReverse = (items, direction) => {
    if (!items || !items.length) return;
    if (direction !== "desc") return items?.reverse();
    return items;
  };

  useEffect(() => {
    const sort = (sorted) => {
      setSortedTours({
        upcoming: handleReverse(
          sorted.filter(
            (t) =>
              !["archived"].includes(t.status) &&
              (!t.date || isBeforeOrAfter(t.date) >= 0)
          ),
          sortBy.direction
        ),
        past: handleReverse(
          sorted.filter((t) => isBeforeOrAfter(t.date) < 0),
          sortBy.direction
        ),
        archived: handleReverse(
          sorted.filter((t) => ["archived"].includes(t.status)),
          sortBy.direction
        ),
      });
    };
    let sorted;
    if (tours) {
      switch (sortBy.value) {
        case "date":
          sorted = tours.sort((a, b) => new Date(b.date) - new Date(a.date));
          sort(sorted);
          break;
        case "status":
          sorted = tours.sort((a, b) => (b.status > a.status ? 1 : -1));
          sort(sorted);
          break;
        case "stops":
          sorted = tours.sort(
            (a, b) => b.tourItems.items.length - a.tourItems.items.length
          );
          sort(sorted);
          break;
        case "title":
          sorted = tours.sort((a, b) =>
            b.title.toLowerCase() > a.title.toLowerCase() ? 1 : -1
          );
          sort(sorted);
          break;
        case "client":
          sorted = tours.sort((a, b) => {
            if (!a.users.items[0] && !b.users.items[0]) return 0;
            if (!a.users.items[0]) return 1;
            if (!b.users.items[0]) return -1;
            const clientA = a.users.items.find((u) => u.role === "client");
            const clientB = b.users.items.find((u) => u.role === "client");

            return clientB.givenName.toLowerCase() >
              clientA.givenName.toLowerCase()
              ? 1
              : -1;
          });
          sort(sorted);
          break;
        case "showing agent":
          sorted = tours.sort((a, b) => {
            const showingAgentA = a.users.items.find(
              (u) => u.role === "showingagent"
            );
            const showingAgentB = b.users.items.find(
              (u) => u.role === "showingagent"
            );
            if (!showingAgentA && !showingAgentB) return 0;
            if (!showingAgentA) return 1;
            if (!showingAgentB) return -1;
            return showingAgentB.givenName.toLowerCase() >
              showingAgentA.givenName.toLowerCase()
              ? 1
              : -1;
          });
          sort(sorted);
          break;
        default:
          sorted = tours.sort((a, b) => new Date(b.date) - new Date(a.date));
          sort(sorted);
      }
    }
  }, [sortBy, tours]);

  useEffect(() => {
    setPage(1);
  }, [type]);

  const getToursByType = () => {
    if (loading) return;
    if (!sortedTours.archived && !sortedTours.past && !sortedTours.upcoming) {
      return <NoTourItem type="upcoming" />;
    } else {
      return sortedTours[type] && sortedTours[type].length > 0 ? (
        <div className={styles.cardsContainer}>
          <CardContainer>
            <div className={styles.header}>
              <div>D/M/Y</div>
              <div>Title</div>
              <div>Client</div>
              <div>Total time</div>
              <div>Showing agent</div>
              <div># of stops</div>
              <div>Status of tour</div>
              <div>Open in tab</div>
              <div>Edit</div>
            </div>
            {sortedTours[type]
              .slice((page - 1) * PER_PAGE, page * PER_PAGE)
              .map((item, index) => (
                <TourItem item={item} index={index} key={index} />
              ))}
          </CardContainer>

          <div className={styles.pagination}>
            <Pagination
              current={page}
              total={sortedTours[type].length}
              perPage={PER_PAGE}
              onChange={(page) => setPage(page)}
            />
          </div>
        </div>
      ) : (
        <NoTourItem type={type} />
      );
    }
  };

  return (
    <div className={styles.tours}>
      {loading ? (
        <LoadingFullPage />
      ) : (
        <>
          <Topbar
            type={type}
            setType={setType}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
          <div>{getToursByType()}</div>
          <CardContainer>
            <NewTour />
          </CardContainer>
          <Footer />
        </>
      )}
    </div>
  );
};

export default withAuthentication(Tours);
