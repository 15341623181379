import {
  createGesture,
  IonIcon,
  IonItem,
  IonSelect,
  IonSelectOption,
  useIonViewDidEnter,
} from "@ionic/react";
import { useRef, useEffect, useCallback, useState } from "react";
import Slide from "react-reveal/Slide";
import ListingCard from "../../app/components/ListingCard/listing-card.component";
import styles from "./mobile-card.module.scss";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import { ReactComponent as Chevron } from "../../assets/svg/chevron-down.svg";
import { v4 } from "uuid";
import PreListingCard from "../../app/components/ListingCard/pre-listing-card.component";

const MobileCard = ({
  listings = null,
  allListings = false,
  header,
  headerType,
  onCollapse,
  children,
}) => {
  const [sortBy, setSortBy] = useState({ value: "date", direction: "desc" });
  const containerRef = useRef();
  const handleRef = useRef();
  const contentRef = useRef();
  const [sortedListings, setSortedListings] = useState([]);
  const [listingsToShow, setListingsToShow] = useState([]);
  const [reloadFav, setReloadFav] = useState();
  const [page, setPage] = useState(1);

  const totalCards = useRef();

  const loadMore = useCallback(() => {
    setPage((page) => page + 1);
    setListingsToShow(sortedListings.slice(0, page * 5));
  }, [sortedListings, page]);

  const scrollListener = useCallback(
    (e) => {
      const { current: element } = contentRef;
      if (element.scrollTop + element.clientHeight + 20 > element.scrollHeight)
        loadMore();
    },
    [loadMore, contentRef]
  );

  useEffect(() => {
    const ref = contentRef.current;
    if (ref) contentRef.current.addEventListener("scroll", scrollListener);
    if (ref)
      contentRef.current.addEventListener("touchover", () => console.log(""));

    return () => {
      if (ref) ref.removeEventListener("scroll", scrollListener);
    };
  }, [contentRef, scrollListener]);

  useEffect(() => {
    if (sortedListings) {
      if (!sortedListings.length) {
        setListingsToShow([]);
      } else {
        totalCards.current = sortedListings.length;
        setListingsToShow(sortedListings.slice(0, 5));
      }

      //if (contentRef.current) contentRef.current.scrollTo(0, 0);
    }
  }, [sortedListings]);

  useEffect(() => {
    if (listings && listings.length) {
      const temp = [...listings];
      switch (sortBy.value) {
        case "date":
          temp.sort((a, b) =>
            a.status === "A"
              ? a.listDate > b.listDate
                ? 1
                : -1
              : a.soldDate > b.soldDate
              ? 1
              : -1
          );
          setSortedListings(
            sortBy.direction === "desc" ? temp.reverse() : temp
          );
          break;
        case "price":
          temp.sort((a, b) =>
            a.status === "A"
              ? +a.listPrice > +b.listPrice
                ? 1
                : -1
              : +a.soldPrice > +b.soldPrice
              ? 1
              : -1
          );
          setSortedListings(
            sortBy.direction === "desc" ? temp.reverse() : temp
          );
          break;
        case "beds":
          temp.sort((a, b) =>
            +a.details.numBedrooms > +b.details.numBedrooms ? 1 : -1
          );
          setSortedListings(
            sortBy.direction === "desc" ? temp.reverse() : temp
          );
          break;
        case "baths":
          temp.sort((a, b) =>
            +a.details.numBathrooms > +b.details.numBathrooms ? 1 : -1
          );
          setSortedListings(
            sortBy.direction === "desc" ? temp.reverse() : temp
          );
          break;
        case "sqft":
          temp.sort((a, b) => (+a.details.sqft > +b.details.sqft ? 1 : -1));
          setSortedListings(
            sortBy.direction === "desc" ? temp.reverse() : temp
          );
          break;
        default:
          setSortedListings(listings);
      }
    } else {
      setSortedListings([]);
    }

    return () => {
      setSortedListings([]);
    };
  }, [listings, sortBy.direction, sortBy.value]);

  useIonViewDidEnter(() => {
    setReloadFav(v4());
  });

  useEffect(() => {
    let h = handleRef.current;
    let c = containerRef.current;
    let content = contentRef.current;

    let gesture;

    if (listings.length === 1) {
      gesture = createGesture({
        el: c,
        gestureName: "my-swipe",
        direction: "y",
        onMove: (event) => {
          if (event.deltaY < 0) return;
          c.style.transform = `translateY(${event.deltaY}px)`;
          if (event.deltaY > 100) {
            c.style.transition = ".3s ease-out";
            c.style.transform = `translateY(100%)`;
            onCollapse();
            return;
          }
        },

        onEnd: (event) => {
          c.style.transition = ".5s ease-out";
          if (event.deltaY < 100) {
            c.style.transform = "";
          }
        },
      });
    } else if (allListings) {
      gesture = createGesture({
        el: h,
        gestureName: "my-swipe",
        direction: "y",
        onMove: (event) => {
          if (event.deltaY < 0) return;

          c.style.transform = `translateY(${event.deltaY}px)`;
          if (event.deltaY > 50 && event.currentY > 150) {
            c.style.transition = ".5s ease-in-out";
            c.style.transform = `translateY(100%)`;
            onCollapse();
            return;
          }
        },

        onEnd: (event) => {
          c.style.transition = ".5s ease-out";
          if (event.deltaY < 50) {
            c.style.transform = "";
          }
        },
      });
    } else {
      gesture = createGesture({
        el: h,
        gestureName: "my-swipe",
        direction: "y",
        onMove: (event) => {
          if (event.deltaY < -300) return;
          if (event.deltaY > 150) {
            c.dataset.open = "false";
            c.style.transition = ".3s ease-in-out";
            c.style.transform = `translateY(100%)`;
            onCollapse();
            return;
          }
          if (event.deltaY < 0 && c.dataset.open !== "true") {
            //c.style.height = `calc(100% - 32rem - ${event.deltaY}px)`;
            c.style.height = `calc(35rem - ${event.deltaY}px)`;
            //c.style.transform = `translateY(${event.deltaY}px)`;
          } else if (c.dataset.open !== "true" || event.deltaY > 0) {
            c.style.transform = `translateY(${event.deltaY}px)`;
          }
        },

        onEnd: (event) => {
          c.style.transition = ".5s ease-out";
          if (event.deltaY < -30 && c.dataset.open !== "true") {
            //c.style.transform = `translateY(-300px)`;
            c.style.height = `80%`;
            c.dataset.open = "true";
          } else if (event.deltaY > 0 && c.dataset.open !== "true") {
            c.style.transform = "";
            c.dataset.open = "false";
            c.style.transition = ".3s ease-in-out";
            c.style.transform = `translateY(100%)`;
            c.dataset.open = "false";
            onCollapse();
            return;
          } else if (event.deltaY > 0) {
            c.style.height = "35rem";
            c.dataset.open = "false";
          } else if (c.dataset.open !== "true") {
            c.style.height = "35rem";
            c.dataset.open = "false";
          }
        },
      });
    }

    gesture.enable(true);
  }, [allListings, listings.length, onCollapse]);

  const handleClick = () => {
    //let h = handleRef.current;
    let c = containerRef.current;
    let content = contentRef.current;
    if (listings.length === 1) {
      c.style.transition = ".3s ease-out";
      c.style.transform = `translateY(100%)`;
      onCollapse();
    } else if (allListings) {
      c.style.transition = ".5s ease-in-out";
      c.style.transform = `translateY(100%)`;
      onCollapse();
    } else {
      if (c.dataset.open === "true") {
        c.style.transform = "";
        c.style.height = "35rem";
        c.dataset.open = "false";
      } else {
        c.style.height = `80%`;
        c.dataset.open = "true";
        content.focus();
      }
    }
  };

  const renderListings = () => {
    if (listingsToShow && listingsToShow.length) {
      return listingsToShow.map((listing) => (
        <div
          className={styles.item}
          key={listing.mlsNumber ? listing.mlsNumber : listing.id}>
          {listing.mlsNumber ? (
            <ListingCard listing={listing} reloadFav={reloadFav} />
          ) : (
            <PreListingCard listing={listing} />
          )}
        </div>
      ));
    }
    return null;
  };

  return listings.length === 1 ? (
    <div className={styles.singleListing} ref={containerRef}>
      <Slide bottom exit={true} opposite={true} duration={500}>
        <div className={styles.card}>
          <span className={styles.singleHeader}>1 listing</span>
          <div className={styles.handle} ref={handleRef} onClick={handleClick}>
            <Chevron className={styles.chevron} />
          </div>
          <div className={styles.content} ref={contentRef}>
            {listings ? renderListings() : children}
          </div>
        </div>
      </Slide>
    </div>
  ) : allListings ? (
    <div
      className={`${styles.wrapper} ${allListings && styles.allListings}`}
      ref={containerRef}>
      <Slide bottom exit={true} opposite={true} duration={500}>
        <div className={styles.card}>
          <div ref={handleRef} onClick={handleClick}>
            <div className={styles.handle}>
              <span className={styles.bar} />
            </div>
            <div
              className={`${styles.header} ${
                headerType && styles[headerType]
              }`}>
              <span className={styles.title}>{header}</span>

              <IonItem
                className={styles.sortContainer}
                lines="none"
                onClick={(e) => e.stopPropagation()}>
                <span className={styles.title}>Sort by</span>
                <span className={styles.value}>{sortBy.value}</span>
                <IonSelect
                  className={styles.select}
                  value={sortBy.value}
                  cancelText="Cancel"
                  okText="Ok"
                  onIonChange={(e) => {
                    setSortBy({ ...sortBy, value: e.detail.value });
                  }}
                  interface="alert"
                  interfaceOptions={{ cssClass: "alert" }}>
                  <IonSelectOption className={styles.option} value="date">
                    Date
                  </IonSelectOption>
                  <IonSelectOption value="price">Price</IonSelectOption>
                  <IonSelectOption value="beds">Beds</IonSelectOption>
                  <IonSelectOption value="baths">Baths</IonSelectOption>
                  <IonSelectOption value="sqft">Square feet</IonSelectOption>
                </IonSelect>
                <IonIcon
                  className={styles.icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSortBy({
                      ...sortBy,
                      direction: sortBy.direction === "desc" ? "asc" : "desc",
                    });
                  }}
                  src={
                    sortBy.direction === "desc"
                      ? chevronDownOutline
                      : chevronUpOutline
                  }
                />
              </IonItem>
            </div>
          </div>

          <div
            className={allListings ? styles.allListingsContent : styles.content}
            ref={contentRef}>
            {listings ? renderListings() : children}
          </div>
        </div>
      </Slide>
    </div>
  ) : (
    <div
      className={`${styles.wrapper} ${allListings && styles.allListings}`}
      ref={containerRef}>
      <Slide bottom exit={true} opposite={true} duration={500}>
        <div className={styles.card}>
          <div ref={handleRef} onClick={handleClick}>
            <div className={styles.handle}>
              <span className={styles.bar} />
            </div>
            <div
              className={`${styles.header} ${
                headerType && styles[headerType]
              }`}>
              <span className={styles.title}>{header}</span>
            </div>
          </div>

          <div
            className={allListings ? styles.allListingsContent : styles.content}
            ref={contentRef}>
            {listings ? renderListings() : children}
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default MobileCard;
