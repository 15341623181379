import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IonInput, IonItem, IonLabel } from "@ionic/react";
import styles from "./builders.module.scss";
import Button from "../../../../components/Form/Button/button.component";
import { validURL } from "../../utils";
import { updateToast } from "../../../../redux/ui/ui.actions";
import { addBuilder } from "../../../../amplify/graphql.utils";
import BreadCrumbs from "../../components/breadcrumbs";

const NewBuilder = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState({ name: "", url: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await addBuilder({ name: data.name, url: data.url });
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Builder created successfully!",
        })
      );
      history.replace(`/admin/builders`);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!data.name) {
      setError("Name is required.");
    } else if (data.url && !validURL(data.url)) {
      setError("Invalid URL");
    } else setError("");
  }, [data.name, data.url]);

  const validateForm = () => {
    if (!data.name) return true;
    if (data.url && !validURL(data.url)) return true;
  };

  return (
    <div className={styles.container}>
      <BreadCrumbs type="builders" sub="New Builder" />
      <h2>Create a new builder</h2>
      <div className={styles.form}>
        <IonItem>
          <IonLabel position="fixed">Name</IonLabel>
          <IonInput
            placeholder="Builder name"
            clearInput
            required
            value={data.name}
            onIonChange={(e) => setData({ ...data, name: e.detail.value })}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="fixed">Logo URL</IonLabel>
          <IonInput
            placeholder="Builder logo"
            clearInput
            pattern="url"
            value={data.url}
            onIonChange={(e) => setData({ ...data, url: e.detail.value })}
          />
        </IonItem>
        <div className={styles.error}>{error}</div>
        <div className={styles.submit}>
          <Button
            title={"Create"}
            type={"green"}
            loading={loading}
            disabled={validateForm()}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default NewBuilder;
