import { IonCol, IonGrid, IonRow, IonIcon } from "@ionic/react";
import IsVisibleTag from "../../../../../../../components/IsVisibleTag/is-visible-tag.component";
import styles from "./quick-contact.module.scss";
import {
  callOutline,
  chatboxEllipsesOutline,
  mailOutline,
} from "ionicons/icons";
import _ from "lodash";
import { TourRoles } from "../../../../../../../utils/constants";

const QuickContact = ({ contacts }) => {
  const handleEmailAll = () => {
    const emails = contacts.map((c) => c.user.email).join(",");
    if (emails.length > 0) {
      window.open(`mailto:${emails}`, "_blank");
    }
  };

  return (
    <IonGrid className={styles.quickContact}>
      <IonRow className={styles.tag}>
        <IonCol>
          <IsVisibleTag isVisible={true} expand="full" />
        </IonCol>
      </IonRow>
      <IonRow className={styles.contactEveryone}>
        <IonCol className={styles.email} onClick={handleEmailAll}>
          <div>
            <IonIcon icon={mailOutline} />
          </div>
          <div>Email everyone</div>
        </IonCol>
      </IonRow>
      {contacts &&
        contacts.length > 0 &&
        _.uniqBy(contacts, (c) => [c.id, c.role].join()).map(
          (contact, index) => (
            <IonRow className={styles.contact} key={index}>
              <IonCol>
                <div>
                  <span
                    className={styles.name}
                  >{`${contact.user.givenName} ${contact.user.familyName}`}</span>
                  <span className={styles.role}>{TourRoles[contact.role]}</span>
                </div>
                <div className={styles.phone}>{contact.user.phone}</div>
                <div className={styles.email}>{contact.user.email}</div>
                <div className={styles.icons}>
                  <a
                    href={contact.user.email ? `mailto:${contact.user.email}` : ""}
                    target="_blank"
                    rel="noreferrer"
                    className={`${styles.mailIcon} ${
                      !contact.user.email && styles.disabled
                    }`}
                  >
                   <IonIcon icon={mailOutline} />
                  </a>
                  <a
                    href={contact.user.phone ? `sms:${contact.user.phone}` : "#"}
                    target="_blank"
                    rel="noreferrer"
                    className={`${styles.smsIcon} ${
                      !contact.user.phone && styles.disabled
                    }`}
                  >
                    <IonIcon icon={chatboxEllipsesOutline} />
                  </a>
                  <a
                    href={contact.user.phone ? `tel:${contact.user.phone}` : ""}
                    target="_blank"
                    rel="noreferrer"
                    className={`${styles.callIcon} ${
                      !contact.user.phone && styles.disabled
                    }`}
                  >
                    <IonIcon icon={callOutline} />
                  </a>
                </div>
              </IonCol>
            </IonRow>
          )
        )}
    </IonGrid>
  );
};

export default QuickContact;
