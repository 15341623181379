import FavoriteIcon from "../../../../../../components/ListingCard/components/FavoriteIcon/favorite-icon.component";
import withMap from "../../../../../../HOC/withMap/with-map";
import styles from "./pre-con-card-header.module.scss";

const PreConCardHeader = ({ project }) => {
  const { name, city } = project;

  return (
    <div className={styles.header}>
      <div>
        <div className={styles.street}>{name}</div>
        <div className={styles.address}>
          <>
            <span className={`${styles.path}`}>{city}</span> &gt;{" "}
            <span>{name}</span>
          </>
        </div>
      </div>
      <div className={styles.img}>
        <FavoriteIcon
          listing={project}
          iconStyle={{ height: "4.2rem", width: "4.2rem" }}
          favIconStyle={{ height: "4.2rem", width: "4rem" }}
          notifIconStyle={{ display: "none" }}
          isPreconstruction
        />
      </div>
    </div>
  );
};

export default withMap(PreConCardHeader);
