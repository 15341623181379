import { useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./confirm-registration-modal.module.scss";
import Button from "../../Form/Button/button.component";
import Checkbox from "../../Form/Checkbox/checkbox-component";
import { fetchClientStart } from "../../../redux/client/client.actions";
import { confirmRegistration } from "../../../data/customModal";
import { updateToast } from "../../../redux/ui/ui.actions";
import { signOutStart } from "../../../redux/user/user.actions";
import { ProfileService } from "../../../services/profileService";

const TERMS =
  "Terms of Use Agreement\nBy accessing any of the websites or mobile applications (collectively, hereinafter “website” or “websites”) operated by The Canadian Real Estate Association (CREA), including aecorn.ca, you, the user, agree to be bound by all of the terms for use and agree these terms constitute a binding contract between the user and CREA. These Terms of Use apply to all users, except to the extent that CREA has developed specific policies for member real estate Boards, Associations and REALTOR® members. Those organizations and persons should also refer to the applicable policies of CREA’s Technology Committee.";

const ConfirmRegistration = ({ setType, language, close }) => {
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);
  const [types, setTypes] = useState({
    sale: false,
    lease: false,
    looking: false,
    preConstruction: false,
  });
  const [realtor, setRealtor] = useState(false);

  const handleSignOut = () => {
    dispatch(signOutStart());
  };

  const handleSubmit = async () => {
    if (!agree) {
      setShowError(true);
    } else {
      showError && setShowError(false);
      setLoading(true);
      const profile = {
        buyingSelling: types.sale,
        leasingRenting: types.lease,
        justLooking: types.looking,
        preConstruction: types.preConstruction,
        isRealtor: realtor,
      };
      try {
        await ProfileService.createProfile(profile);
        dispatch(fetchClientStart());
        setTimeout(() => {
          setType("welcome", true);
        }, 1000);
      } catch (err) {
        console.log(err)
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong! Please try again.",
          })
        );
      }

      setLoading(false);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.subtitle}>
          {confirmRegistration[language].header}
        </span>
        <span className={styles.note}>
          {confirmRegistration[language].subtitle}
        </span>
      </div>
      <div className={styles.content}>
        <div className={styles.terms}>
          <div className={styles.label}>
            {confirmRegistration[language].label_1}
          </div>
          <textarea
            readOnly={true}
            draggable={false}
            className={styles.textarea}
            value={TERMS}
            wrap="on"
          />
        </div>
        <div className={styles.options}>
          <div className={styles.option}>
            <Checkbox
              label={confirmRegistration[language].agreement}
              value="agreeTerms"
              checked={agree}
              onClick={() => setAgree(!agree)}
            />
          </div>
          <div className={styles.option}>
            <div className={styles.label}>
              {confirmRegistration[language].label_2}
            </div>
            <div className={styles.checkbox}>
              <Checkbox
                label={confirmRegistration[language].buyORsale}
                value="agreeTerms"
                checked={types.sale}
                onClick={() => setTypes({ ...types, sale: !types.sale })}
              />
              <Checkbox
                label={confirmRegistration[language].leaseORrent}
                value="agreeTerms"
                checked={types.lease}
                onClick={() => setTypes({ ...types, lease: !types.lease })}
              />
            </div>
            <div className={styles.checkbox}>
              <Checkbox
                label={confirmRegistration[language].preConstruction}
                value="agreeTerms"
                checked={types.preConstruction}
                onClick={() =>
                  setTypes({
                    ...types,
                    preConstruction: !types.preConstruction,
                  })
                }
              />
              <Checkbox
                label={confirmRegistration[language].looking}
                value="agreeTerms"
                checked={types.looking}
                onClick={() => setTypes({ ...types, looking: !types.looking })}
              />
            </div>
          </div>
          <div className={styles.option}>
            <div className={styles.label}>
              {confirmRegistration[language].label_3}
            </div>
            <div className={styles.checkbox}>
              <Checkbox
                label={confirmRegistration[language].notAgent}
                value="agreeTerms"
                checked={!realtor}
                onClick={() => setRealtor(false)}
              />
              <Checkbox
                label={confirmRegistration[language].isAgent}
                value="agreeTerms"
                checked={realtor}
                onClick={() => setRealtor(true)}
              />
            </div>
          </div>
        </div>
      </div>
      {showError && (
        <div className={styles.error}>
          {confirmRegistration[language].errorMsg}
        </div>
      )}
      <div className={styles.button}>
        <Button
          title={confirmRegistration[language].button}
          type="green"
          style={{ width: "100%", height: "5rem", border: "1px solid #1F7A60" }}
          loading={loading}
          onClick={handleSubmit}
        />
        <Button
          title={confirmRegistration[language].cancel}
          type="clear"
          style={{
            color: "#363636",
            width: "max-content",
            margin: "1rem auto 0",
          }}
          onClick={handleSignOut}
        />
      </div>
    </div>
  );
};

export default ConfirmRegistration;
