import { useState, useEffect } from "react";
import { IonIcon, IonButton, IonLoading } from "@ionic/react";
import styles from "./tour-attendees.module.scss";
import {
  closeCircleOutline,
  personAddOutline,
  personOutline,
} from "ionicons/icons";
import IsVisibleTag from "../../../../../../../components/IsVisibleTag/is-visible-tag.component";
import SearchableSelect from "../../../../../../components/Form/SearchableSelect/searchable-select.component";
import { TourService } from "../../../../../../../services/tourService";
import { UserService } from "../../../../../../../services/userService";
import { TourRole } from "../../../../../../../API";

const TourAttendees = ({ tour, update, selfRef }) => {
  const { shared } = tour;

  const [client, setClient] = useState(TourService.getTourClient(tour));
  const [guests, setGuests] = useState(TourService.getTourGuests(tour));
  const [leadAgent, setLeadAgent] = useState(
    TourService.getTourPrimaryAgent(tour)
  );
  const [showingAgents, setShowingAgents] = useState(
    TourService.getTourShowingAgents(tour)
  );
  const [agents, setAgents] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAllClinets = async () => {
      try {
        const clients = await UserService.getClientUsers();
        setClientsList(clients);
      } catch (err) {
        console.log(err);
      }
    };

    const fetchAgents = async () => {
      try {
        const agents = await UserService.getAgentUsers();
        setAgents(agents);
      } catch (err) {
        console.log(err);
      }
    };

    fetchAllClinets();
    fetchAgents();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const users = [
      { id: client.id, role: TourRole.client },
      { id: leadAgent.id, role: TourRole.primaryAgent },
      ...guests.map((g) => ({ id: g.id, role: TourRole.guest })),
      ...showingAgents.map((a) => ({ id: a.id, role: TourRole.showingAgent })),
    ];
    await update(users);
    setLoading(false);
  };

  const handleClientsChange = async (val) => {
    const clients = await UserService.getClientUsers(val);
    setClientsList(clients);
  };

  const handleAgentsChange = async (val) => {
    const agents = await UserService.getAgentUsers(val);
    setAgents(agents);
  };

  return (
    <div className={styles.tourAttendees}>
      <IonLoading isOpen={loading} />
      <IsVisibleTag isVisible={true} expand="full" />
      <div className={styles.inputs}>
        <SearchableSelect
          title="Select the main client"
          label={client ? `Client: ${client.displayName}` : "Select client"}
          type="radio"
          parentRef={selfRef}
          required={true}
          icon={client ? personOutline : personAddOutline}
          items={clientsList && clientsList.filter((c) => !c.deactivated)}
          selected={client}
          setSelected={setClient}
          onSave={(client) => setClient(client)}
          onChange={handleClientsChange}
          canRemove={guests.length === 0}
          onRemove={() => {
            setClient(null);
          }}
        />

        {client && (
          <SearchableSelect
            title="Select additional guests"
            type="checkbox"
            parentRef={selfRef}
            items={clientsList
              .filter((c) => !c.deactivated)
              .filter((c) => c.id !== client.id)}
            selected={guests}
            setSelected={setGuests}
            onChange={handleClientsChange}
            label={
              guests.length
                ? `${guests.length} guests selected`
                : "Add additional guests"
            }
            icon={personAddOutline}
          />
        )}

        {guests.length > 0 && (
          <div className={styles.guests}>
            {guests
              .filter((g) => g !== null)
              .map((g, i) => (
                <div className={styles.chip} key={i}>
                  {g.displayName}
                  <IonIcon
                    className={styles.icon}
                    icon={closeCircleOutline}
                    onClick={() =>
                      setGuests(guests.filter((guest) => guest.id !== g.id))
                    }
                  />
                </div>
              ))}
          </div>
        )}

        <SearchableSelect
          title="Lead Agent"
          label={
            leadAgent
              ? `Lead Agent: ${leadAgent.displayName}`
              : "Select lead agent"
          }
          type="radio"
          parentRef={selfRef}
          required={true}
          icon={leadAgent ? personOutline : personAddOutline}
          items={agents.filter((c) => !c.deactivated)}
          selected={leadAgent}
          setSelected={setLeadAgent}
          onSave={(agent) => setLeadAgent(agent)}
          onChange={handleAgentsChange}
          canRemove={showingAgents.length === 0}
          onRemove={() => {
            setLeadAgent(null);
          }}
        />

        <SearchableSelect
          title="Showing Agents"
          label={
            showingAgents.length
              ? `${showingAgents.length} showing agents selected`
              : "Select showing agents"
          }
          type="checkbox"
          parentRef={selfRef}
          required={true}
          icon={showingAgents.length ? personOutline : personAddOutline}
          items={agents.filter((c) => !c.deactivated)}
          selected={showingAgents}
          setSelected={setShowingAgents}
          onSave={(agents) => setShowingAgents(agents)}
          onChange={handleAgentsChange}
        />
      </div>
      <IonButton
        shape="round"
        expand="block"
        className={styles.button}
        disabled={
          !leadAgent ||
          !client ||
          !showingAgents.length ||
          !tour ||
          !tour.status ||
          tour.status === "archived"
        }
        onClick={handleSubmit}>
        Save changes
      </IonButton>
      {shared === "unshared" && (
        <div className={styles.note}>
          The tour will not be available to the client until you share it.
        </div>
      )}
    </div>
  );
};

export default TourAttendees;
