import { useState, useEffect } from "react";
import { IonIcon, IonButton } from "@ionic/react";
import IsVisibleTag from "../../../../../components/IsVisibleTag/is-visible-tag.component";
import styles from "./tour-attendees.module.scss";
import {
  closeCircleOutline,
  personAddOutline,
  personOutline,
} from "ionicons/icons";
import SearchableSelect from "../../../Form/SearchableSelect/searchable-select.component";
import { UserService } from "../../../../../services/userService";

const TourAttendees = ({ tour, onNext }) => {
  const [client, setClient] = useState();
  const [guests, setGuests] = useState([]);
  const [showingAgents, setShowingAgents] = useState([]);
  const [agents, setAgents] = useState([]);
  const [clientsList, setClientsList] = useState([]);

  useEffect(() => {
    const fetchAllClinets = async () => {
      const clientUsers = await UserService.getClientUsers();
      setClientsList(clientUsers);
    };

    const fetchAgents = async () => {
      try {
        const agentUsers = await UserService.getAgentUsers();
        setAgents(agentUsers);
      } catch (err) {
        console.log(err);
      }
    };

    fetchAllClinets();
    fetchAgents();
  }, []);

  const handleAgentsChange = async (val) => {
    const agentUsers = await UserService.getAgentUsers(val);
    setAgents(agentUsers);
  };

  const handleCleintsChange = async (val) => {
    const clientUsers = await UserService.getClientUsers(val);
    setClientsList(clientUsers);
  };

  const submit = () => {
    onNext({ client, guests, showingAgents });
  };

  return (
    <div className={styles.tourAttendees}>
      <div className={styles.subtitle}>
        Enter your tour information – you can always edit it later. Note that
        some content will only be visible by you, the agent. Once you create a
        tour, you can add and edit showings.
      </div>

      <div className={styles.label}>
        <div className={styles.title}>Tour attendees</div>
        <IsVisibleTag isVisible={true} />
      </div>

      <div className={styles.inputs}>
        <SearchableSelect
          title="Select the main client"
          type="radio"
          items={clientsList && clientsList.filter((c) => !c.deactivated)}
          onChange={handleCleintsChange}
          selected={client}
          setSelected={setClient}
          label={client ? `Client: ${client.displayName}` : "Select client"}
          onSave={(client) => setClient(client)}
          icon={client ? personOutline : personAddOutline}
        />
        {client && (
          <SearchableSelect
            title="Select additional guests"
            type="checkbox"
            items={
              clientsList &&
              clientsList
                .filter((c) => !c.deactivated)
                .filter((c) => c.id !== client.id)
            }
            selected={guests}
            setSelected={setGuests}
            onChange={handleCleintsChange}
            label="Add additional guests"
            icon={personAddOutline}
          />
        )}

        {guests.length > 0 && (
          <div className={styles.guests}>
            {guests
              .filter((g) => g !== null)
              .map((g, i) => (
                <div className={styles.chip} key={i}>
                  {g.displayName}
                  <IonIcon
                    className={styles.icon}
                    icon={closeCircleOutline}
                    onClick={() =>
                      setGuests(guests.filter((guest) => guest.id !== g.id))
                    }
                  />
                </div>
              ))}
          </div>
        )}

        <SearchableSelect
          title="Select showing agents"
          type="checkbox"
          required={true}
          items={agents.filter((a) => !a.deactivated)}
          selected={showingAgents}
          setSelected={setShowingAgents}
          onChange={handleAgentsChange}
          label={
            showingAgents.length
              ? `${showingAgents.length} showing agents selected`
              : "Add showing agents"
          }
          icon={personAddOutline}
        />
      </div>

      <IonButton
        shape="round"
        expand="block"
        className={styles.button}
        onClick={submit}
        disabled={!tour.leadAgent || !client || showingAgents.length === 0}
      >
        Complete tour setup
      </IonButton>
    </div>
  );
};

export default TourAttendees;
