import { useState, useEffect, useCallback } from "react";
import styles from "../filters-menu.module.scss";
import withFilters from "../../../HOC/withFilters/with-filters";
import Input from "../../Form/Input/input.component";
import useComponentVisible from "../../../hooks/useComponentVisible";
import DropdownContainer from "../../Form/DropdownContainer/dropdown-container.component";
import { IonIcon } from "@ionic/react";
import { warningOutline } from "ionicons/icons";
import SearchResult from "../../LocationSearch/components/search-result.component";
import { debounce } from "lodash";
import { getBuilders } from "../../../amplify/graphql.utils";

const BuilderFilterItem = ({ filters: { builder }, updateSingleFilter }) => {
  const [term, setTerm] = useState("");
  const [developer, setDeveloper] = useState(builder);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    ref: refInput,
    isOpen: isOpenInput,
    setIsOpen: setIsOpenInput,
  } = useComponentVisible(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce((term) => fetchResults(term), 500),
    []
  );

  const handleTermChange = (value, name) => {
    setTerm(value);
  };

  const fetchResults = async (term) => {
    setLoading(true);

    try {
      const response = await getBuilders(term);
      setResults(response);
    } catch (err) {
      setResults(null);
    }

    setLoading(false);
  };
  useEffect(() => {
    if (term.length && !developer) {
      setLoading(true);
      debouncedFetch(term);
    }
  }, [term, debouncedFetch, developer]);
  useEffect(() => {
    if (term && !loading && !developer) setIsOpenInput(true);
  }, [term, loading, setIsOpenInput, developer]);

  return (
    <div className={styles.item}>
      <div className={styles.keywords}>
        <div className={styles.label}>Developer</div>
        <div className={styles.input} style={{ marginLeft: "1rem" }}>
          <div ref={refInput} className={styles.searchContainer}>
            <Input
              label="Developer/Builder name"
              placeholder="Developer/Builder name"
              mode="search"
              value={term}
              search
              clear={() => {
                setTerm("");
                setDeveloper(null);
                updateSingleFilter({ builder: null });
              }}
              onChange={handleTermChange}
              loading={loading}
              onClick={() => {
                if (term && results) setIsOpenInput(true);
              }}
              disabled={developer}
            />
            {isOpenInput && (
              <DropdownContainer
                open={term && !loading}
                style={{
                  maxHeight: "22rem",
                }}
              >
                {!results && (
                  <div className={styles.noResult}>
                    <IonIcon icon={warningOutline} className={styles.icon} />
                    No developer found. Try revising your search.
                  </div>
                )}
                {results &&
                  results.map((developer) => (
                    <SearchResult
                      key={developer.id}
                      item={developer}
                      term={term}
                      type="developer"
                      onClick={() => {
                        setDeveloper(developer);
                        updateSingleFilter({ builder: developer.id });
                        setTerm(developer.name);
                        setIsOpenInput(false);
                      }}
                    />
                  ))}
              </DropdownContainer>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFilters(BuilderFilterItem);
