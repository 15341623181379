import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import styles from "./agent-details.module.scss";
import IsVisibleTag from "../../../../../components/IsVisibleTag/is-visible-tag.component";
import { selectCurrentClient } from "../../../../../redux/client/client.selectors";
import { UserService } from "../../../../../services/userService";

const AgentDetails = ({ tour, onNext }) => {
  const agent = useSelector(selectCurrentClient);
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const [agentsModalIsOpen, setAgentsModalIsOpen] = useState(false);
  const [agents, setAgents] = useState();
  const [leadAgent, setLeadAgent] = useState();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchAgents = async () => {
      const agentUsers = await UserService.getAgentUsers();
      setAgents(agentUsers);
    };
    fetchAgents();
  }, [agent]);

  const handleNoteChange = ({ target: { value } }) => {
    setNote(value);
  };

  useEffect(() => {
    if (tour) {
      setTitle(tour.title);
      setNote(tour.privateNote ? tour.privateNote : "");
      setLeadAgent(tour.leadAgent);
      const div = document.getElementById("new_tour");
      div?.scrollIntoView();
    }
  }, [tour]);

  const openAgentsModal = () => {
    setAgentsModalIsOpen(true);
  };

  const submit = () => {
    onNext({ title, privateNote: note, leadAgent });
  };

  return (
    <>
      <IonModal isOpen={agentsModalIsOpen} onDidDismiss={() => setAgentsModalIsOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setAgentsModalIsOpen(false)}>
                Close
              </IonButton>
            </IonButtons>
            <IonTitle>Primary Agent</IonTitle>
            <IonButtons slot="end">
              <IonButton strong onClick={() => setAgentsModalIsOpen(false)}>
                Done
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonSearchbar placeholder="Search for an agent..." onIonInput={e => setSearchText(e.detail.value)}></IonSearchbar>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            {agents &&
              agents.filter(agent => {
                if (!searchText) {
                  return agent;
                } else if (
                  agent.displayName.toLowerCase().includes(searchText.toLowerCase() || agent.email.toLowerCase().includes(searchText.toLowerCase()))
                ) {
                  return agent;
                }
              }).map((agent) => (
                <IonItem
                  key={agent.id}
                  button
                  onClick={() => {
                    setLeadAgent(agent);
                    setAgentsModalIsOpen(false);
                  }}>
                  <IonLabel>{agent.displayName}</IonLabel>
                </IonItem>
              ))}
          </IonList>
        </IonContent>
      </IonModal>
      <div className={styles.agentDetails}>
        <div className={styles.subtitle}>
          Enter your tour information – you can always edit it later. Note that
          some content will only be visible by you, the agent. Once you create a
          tour, you can add and edit showings.
        </div>

        <div className={styles.label}>
          <div className={styles.title}>Agent details</div>
          <IsVisibleTag isVisible={true} />
        </div>
        <div className={styles.inputs}>
          <IonItem>
            <IonInput
              required
              placeholder="Tour title"
              value={title}
              onIonChange={(event) => setTitle(event.detail.value)}
            />
          </IonItem>

          <div className={styles.container}>
            <IonItem button onClick={openAgentsModal}>
              <IonLabel>Lead Agent</IonLabel>
              <IonLabel>
                {leadAgent ? leadAgent.displayName : "Select lead agent"}
              </IonLabel>
            </IonItem>
          </div>
          <br />
          <div className={styles.label}>
            <div className={styles.title}>Private notes</div>
            <IsVisibleTag isVisible={false} />
          </div>
          <textarea
            className={styles.textarea}
            name="agent_note"
            placeholder="Private agent tour notes"
            value={note}
            onChange={handleNoteChange}></textarea>
        </div>

        <IonButton
          shape="round"
          expand="block"
          className={styles.button}
          onClick={submit}
          disabled={!title || !leadAgent}>
          Save and continue
        </IonButton>
        <div className={styles.whitespace}></div>
      </div>
    </>
  );
};

export default AgentDetails;
