import { useDispatch } from "react-redux";
import { ReactComponent as HomeIcon } from "../../../assets/svg/listing.svg";
import { ReactComponent as FavoriteIcon } from "../../../assets/svg/star.svg";
import { ReactComponent as SearchIcon } from "../../../assets/svg/search.svg";
import { ReactComponent as WatchedIcon } from "../../../assets/svg/eye.svg";
import Button from "../../Form/Button/button.component";
import { join } from "../../../data/customModal";
import styles from "./join-modal.module.scss";
import { openAuthModal } from "../../../redux/ui/ui.actions";

const JoinModal = ({ close, language }) => {
  const dispatch = useDispatch();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.title}>{join[language].header}</span>
        <span className={styles.subtitle}>{join[language].subtitle}</span>
        <span className={styles.note}>{join[language].note}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.item}>
          <HomeIcon className={styles.icon} />
          <span className={styles.label}>{join[language].home}</span>
        </span>
        <span className={styles.item}>
          <FavoriteIcon
            className={styles.icon}
            style={{ strokeWidth: ".15rem" }}
          />
          <span
            className={styles.label}
            dangerouslySetInnerHTML={{ __html: join[language].favorite }}
          ></span>
        </span>
        <span className={styles.item}>
          <SearchIcon className={styles.icon} style={{ width: "3.1rem" }} />
          <span
            className={styles.label}
            dangerouslySetInnerHTML={{ __html: join[language].search }}
          ></span>
        </span>
        <span className={styles.item}>
          <WatchedIcon className={styles.icon} />
          <span
            className={styles.label}
            dangerouslySetInnerHTML={{ __html: join[language].watch }}
          ></span>
        </span>
      </div>
      <div className={styles.button}>
        <Button
          title={join[language].button}
          type="green"
          style={{ width: "100%", border: "1px solid #1F7A60", height: "5rem" }}
          onClick={() => {
            close();
            dispatch(openAuthModal({ current: "register" }));
          }}
        />
      </div>
      <div className={styles.subButton}>
        {join[language].subButtonLabel}
        <span
          onClick={() => {
            close();
            dispatch(openAuthModal({ current: "login" }));
          }}
        >
          {join[language].subButton}
        </span>
      </div>
    </div>
  );
};

export default JoinModal;
