import { useSelector } from "react-redux";
import { selectLocationsValue } from "../../../redux/locations/locations.selectors";
import Select from "../../Form/Select/select.component";
import TreeSelect from "../../TreeSelect/tree-select.component";
import withFilters from "../../../HOC/withFilters/with-filters";
import withMap from "../../../HOC/withMap/with-map";
import styles from "../filters-menu.module.scss";

const LocationFilterItem = ({
  buttonStyle,
  filters,
  updateMultipleFilters,
  updateMapLocations,
  tabName,
  tab,
  setTab,
  disabled,
  mapRef,
}) => {
  const locations = useSelector(selectLocationsValue) ?? [];

  return (
    <div className={styles.item}>
      <Select
        buttonStyle={buttonStyle}
        title="Neighbourhoods"
        open={tab === tabName}
        onClick={() => setTab(tabName)}
        dropdown={false}
        disabled={disabled}
      />
      <TreeSelect
        selected={{
          area: filters.area,
          city: filters.city,
          neighborhood: filters.neighborhood,
        }}
        locations={locations ?? []}
        update={updateMultipleFilters}
        updateLocations={updateMapLocations}
        open={tab === tabName}
        onClick={() => setTab(tabName)}
        mapRef={mapRef}
      />
    </div>
  );
};

export default withMap(withFilters(LocationFilterItem));
