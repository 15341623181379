import styles from "./comparable-listings.module.scss";
import ListingCard from "../../../../../../components/ListingCard/listing-card.component";
import AppBlurCard from "./component/blur-card.component";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

const ComparableListings = ({
  title,
  listings,
  isActive = false,
  user,
  signInHandler,
  signUpHandler,
}) => {
  const renderSingleComparable = () => {
    return (
      <div className={styles.single}>
        {isActive ? (
          <ListingCard isActiveComparables={isActive} listing={listings[0]} />
        ) : user ? (
          <ListingCard isActiveComparables={isActive} listing={listings[0]} />
        ) : (
          <AppBlurCard
            listing={listings[0]}
            signInHandler={signInHandler}
            signUpHandler={signUpHandler}
          />
        )}
      </div>
    );
  };

  return (
    <div className={styles.comparables}>
      <div className={styles.title}>{title}</div>
      {listings ? (
        <Swiper
          spaceBetween={0}
          slidesPerView={1.1}
          centeredSlides={false}
          loop
          initialSlide={0}>
          {listings.map((listing) => (
            <SwiperSlide key={listing.mlsNumber}>
              {isActive ? (
                <ListingCard isActiveComparables={isActive} listing={listing} />
              ) : user ? (
                <ListingCard isActiveComparables={isActive} listing={listing} />
              ) : (
                <AppBlurCard
                  listing={listing}
                  signInHandler={signInHandler}
                  signUpHandler={signUpHandler}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : null}
    </div>
  );
};

export default ComparableListings;
