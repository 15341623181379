import { useState, useEffect, useCallback, useRef, createRef } from "react";
import {
  IonCheckbox,
  IonDatetime,
  IonDatetimeButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonRange,
} from "@ionic/react";
import { addOutline } from "ionicons/icons";
import Button from "../../Form/Button/button.component";
import Input from "../../Form/Input/input.component";
import RadioButton from "../../Form/RadioButton/radio-button.component";
import styles from "./request-form-modal.module.scss";
import withClient from "../../../HOC/withClient/with-client";
import { sendRequestToAgent } from "../../../api/ettie";
import {
  buildAddress,
  numberWithCommas,
  calculateCashback,
} from "../../../utils/functions";
import moment from "moment";
import TextAnimation from "react-text-animations";

const RequestFormModal = ({ currentClient, setType, data, language }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [text, setText] = useState("");
  const [value, setValue] = useState("");
  const [showTime, setShowTime] = useState(false);
  const [addDate, setAddDate] = useState([]);
  const [budget, setBudget] = useState(numberWithCommas(1500000));
  const [offer, setOffer] = useState({
    initialValue: true,
    discuss: false,
    value: data.price ? "$" + data.price : "",
    date: null,
  });
  let address, mlsNumber, status, active;

  if (!data.tourBooking && !data.cashback) {
    address = data.address;
    mlsNumber = data.mlsNumber;
    status = data.status;
    active = data.active;
  }

  const createMsg = useCallback(() => {
    if (value.includes("information")) {
      setShowTime(false);
      setAddDate([]);
    }
    let msg = value.includes("information")
      ? active
        ? "I'd like to get more information on "
        : "I'd like to find listings similar to "
      : `I'd like to book ${
          value.includes("virtual") ? "a virtual" : "an in-person"
        } appointment to view `;
    if (address.unitNumber) msg += address.unitNumber + " ";
    if (address.streetName) msg += address.streetName + " ";
    if (address.streetSuffix) msg += address.streetSuffix + ", ";
    if (address.city) msg += address.city;
    msg += ` (${mlsNumber}).`;
    return msg;
  }, [address, mlsNumber, value, active]);

  useEffect(() => {
    if (value) setText(createMsg());
  }, [createMsg, value]);

  const inputRefs = useRef({
    given_name: createRef(),
    family_name: createRef(),
    email: createRef(),
    phone_number: createRef(),
  });

  const [formData, setFormData] = useState({
    given_name: "",
    family_name: "",
    email: "",
    phone_number: "",
  });

  useEffect(() => {
    if (currentClient) {
      setFormData({
        email: currentClient.email,
        given_name: currentClient.givenName,
        family_name: currentClient.familyName,
        phone_number: currentClient.phone ? currentClient.phone.substr(2) : "",
      });
    }
  }, [currentClient]);

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }

    if (isValid) {
      setLoading(true);
      setError("");
      const res = await sendRequestToAgent({
        name: `${formData.given_name} ${formData.family_name}`,
        givenName: formData.given_name,
        mlsNumber,
        address: buildAddress(address),
        status,
        email: formData.email,
        phone: formData.phone_number,
        dates: addDate.map((d) => {
          return {
            date: moment(d).format("YYYY-MM-DD"),
            from: moment(d).format("hh:mm a"),
            to: moment(d).add(1, "hour").format("hh:mm a"),
          };
        }),
        inPerson: value.includes("in-person"),
        virtual: value.includes("virtual"),
        information: value.includes("information"),
        message: text,
      });
      if (res.success) {
        setType("requestSent");
      } else {
        setLoading(false);
        setError("Something went wrong! Please try again.");
      }
    }
  };

  const handleTourSubmit = async () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }

    if (isValid) {
      setLoading(true);
      setError("");
      const res = await sendRequestToAgent({
        name: `${formData.given_name} ${formData.family_name}`,
        givenName: formData.given_name,
        email: formData.email,
        phone: formData.phone_number,
        tourBooking: true,
        message: text,
      });
      if (res.success) {
        setType("requestSent");
      } else {
        setLoading(false);
        setError("Something went wrong! Please try again.");
      }
    }
  };

  const handleOfferSubmit = async () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }

    if (isValid) {
      setLoading(true);
      setError("");
      const res = await sendRequestToAgent({
        name: `${formData.given_name} ${formData.family_name}`,
        givenName: formData.given_name,
        email: formData.email,
        phone: formData.phone_number,
        mlsNumber,
        address: buildAddress(address),
        status,
        offer: {
          ...offer,
          date: moment(new Date(offer.date)).format("YYYY-MM-DD"),
        },
      });
      if (res.success) {
        setType("requestSent");
      } else {
        setLoading(false);
        setError("Something went wrong! Please try again.");
      }
    }
  };

  const handleCashbackSubmit = async () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }

    if (isValid) {
      setLoading(true);
      setError("");
      const res = await sendRequestToAgent({
        name: `${formData.given_name} ${formData.family_name}`,
        givenName: formData.given_name,
        email: formData.email,
        phone: formData.phone_number,
        message: text,
        info: data.info,
        cashback: true,
      });
      if (res.success) {
        setType("requestSent");
      } else {
        setLoading(false);
        setError("Something went wrong! Please try again.");
      }
    }
  };

  const radioButtonHandler = (label) => {
    setValue(label);
    if (!label.includes("information")) {
      setShowTime(true);
      if (!addDate.length) {
        setAddDate([moment().toISOString()]);
      }
    }
  };

  const handleMessage = (event) => {
    setText(event.target.value);
  };

  const addDateHandler = () => {
    if (!addDate.length) {
      setAddDate([moment().toISOString()]);
    } else {
      setAddDate((addDate) => [...addDate, moment().toISOString()]);
    }
  };

  const updateDateHandler = (index, value) => {
    const dates = [...addDate];
    dates[index] = value;
    setAddDate(dates);
  };

  const deleteDateHandler = (index) => {
    const dates = addDate.filter((d) => d.index !== index);
    setAddDate(dates);
  };

  const handleOfferChange = (value) => {
    let cleanedValue = 0;
    if (value.includes("$")) value = value.split("$").pop();

    if (!isNaN(+value.replace(/,/g, "")))
      cleanedValue = +value.replace(/,/g, "");

    if (cleanedValue <= 0) cleanedValue = "";

    setOffer({
      ...offer,
      value: offer.initialValue
        ? "$" + numberWithCommas(cleanedValue)
        : cleanedValue,
      initialValue: offer.initialValue && false,
    });
  };

  const handleOfferBlur = () => {
    setOffer({
      ...offer,
      value: offer.value.toString().includes("$")
        ? numberWithCommas(offer.value)
        : "$" + numberWithCommas(offer.value),
    });
  };

  const handleBudgetInputChange = (value, name) => {
    if (name === "budget") {
      let cleanedValue = 0;
      if (!isNaN(+value.replace(/,/g, "")))
        cleanedValue = +value.replace(/,/g, "");
      if (cleanedValue > 20000000) cleanedValue = 20000000;
      if (cleanedValue < 0) cleanedValue = 0;
      setBudget(numberWithCommas(cleanedValue));
    }
  };

  const handleBudgetRangeChange = ({ target: { name }, detail: { value } }) => {
    if (name === "budget") {
      setBudget(numberWithCommas(value));
    }
  };

  return data.tourBooking ? (
    <div className={styles.requestForm}>
      <div className={styles.title}>Want to book a tour?</div>
      <div className={styles.subtitle}>
        We're excited to work with you! Submit this form and we will be in touch
        soon!
      </div>
      <div className={styles.sectionTitle}>Contact information</div>
      <div className={styles.inputRow}>
        <Input
          required
          name="given_name"
          label="First name"
          placeholder="First name"
          type="text"
          ref={inputRefs.current.given_name}
          value={formData["given_name"]}
          defaultValue={currentClient && currentClient.givenName}
          onChange={(value, name) => handleChange(value, name)}
          validation="required|min:2"
        />
        <Input
          required
          name="family_name"
          label="Last name"
          placeholder="Last name"
          type="text"
          ref={inputRefs.current.family_name}
          value={formData["family_name"]}
          defaultValue={currentClient && currentClient.familyName}
          onChange={(value, name) => handleChange(value, name)}
          validation="required|min:2"
        />
      </div>
      <div className={styles.inputRow}>
        <Input
          required
          name="phone_number"
          label="Phone"
          placeholder="Phone"
          // type="phone"
          mode="tel"
          ref={inputRefs.current.phone_number}
          value={formData["phone_number"]}
          // defaultValue={
          //   currentClient && currentClient.phone ? currentClient.phone : ""
          // }
          onChange={(value, name) => handleChange(value, name)}
          validation="required|phone"
        />
        <Input
          required
          name="email"
          label="Email"
          placeholder="Email"
          // type="email"
          mode="email"
          ref={inputRefs.current.email}
          value={formData["email"]}
          defaultValue={currentClient && currentClient.email}
          onChange={(value, name) => handleChange(value.toLowerCase(), name)}
          validation="required|email"
        />
      </div>

      <span className={styles.textarealabel}>Message</span>
      <textarea
        className={styles.textarea}
        placeholder="I’d like to start a showing tour of some properties I'm interested in."
        name="textarea"
        value={text}
        onChange={handleMessage}></textarea>
      {error && <div className={styles.error}>{error}</div>}

      <Button
        onClick={handleTourSubmit}
        title="Submit"
        type="green"
        loading={loading}
        style={
          !value || (!value.includes("information") && !addDate.length)
            ? {
                marginTop: "2rem",
                height: "5rem",
                width: "100%",
                border: "1px solid #1f7a60",
              }
            : {
                marginTop: "2rem",
                height: "5rem",
                width: "100%",
                border: "1px solid #1f7a60",
              }
        }
      />
      <div className={styles.declaration}>
        By submitting this form, I understand AECORN will share my information
        with registered real estate professionals.
      </div>
    </div>
  ) : data.offer ? (
    <div className={styles.requestForm}>
      <div className={styles.title}>
        {status.toLowerCase() === "sale" ? "Sale" : "Lease"} offer form
      </div>
      <div className={styles.address}>{buildAddress(address)}</div>
      <div className={styles.subtitle}>
        Submit this form and a AECORN agent will contact you in the next 24
        hours to assist you in putting in this potential offer.
      </div>
      <div className={styles.sectionTitle}>Contact information</div>
      <div className={styles.inputRow}>
        <Input
          required
          name="given_name"
          label="First name"
          placeholder="First name"
          type="text"
          ref={inputRefs.current.given_name}
          value={formData["given_name"]}
          defaultValue={currentClient && currentClient.givenName}
          onChange={(value, name) => handleChange(value, name)}
          validation="required|min:2"
        />
        <Input
          required
          name="family_name"
          label="Last name"
          placeholder="Last name"
          type="text"
          ref={inputRefs.current.family_name}
          value={formData["family_name"]}
          defaultValue={currentClient && currentClient.familyName}
          onChange={(value, name) => handleChange(value, name)}
          validation="required|min:2"
        />
      </div>
      <div className={styles.inputRow}>
        <Input
          required
          name="phone_number"
          label="Phone"
          placeholder="Phone"
          // type="phone"
          mode="tel"
          ref={inputRefs.current.phone_number}
          value={formData["phone_number"]}
          // defaultValue={
          //   currentClient && currentClient.phone ? currentClient : ""
          // }
          onChange={(value, name) => handleChange(value, name)}
          validation="required|phone"
        />
        <Input
          required
          name="email"
          label="Email"
          placeholder="Email"
          // type="email"
          mode="email"
          ref={inputRefs.current.email}
          value={formData["email"]}
          defaultValue={currentClient && currentClient.email}
          onChange={(value, name) => handleChange(value.toLowerCase(), name)}
          validation="required|email"
        />
      </div>

      <div className={styles.sectionTitle}>Offer details</div>

      <div className={styles.inputs}>
        <div className={styles.row}>
          <span className={styles.inputLabel}>Preliminary offer</span>
          <Input
            required
            name="offer_value"
            type="text"
            mode="numeric"
            defaultValue={offer.value}
            value={offer.value}
            onFocus={() => setOffer({ ...offer, value: "" })}
            onChange={(value, name) => handleOfferChange(value)}
            onBlur={handleOfferBlur}
            disabled={offer.discuss}
          />
        </div>
        {status.toLowerCase() === "sale" && (
          <IonItem lines="none" className={styles.checkboxContainer}>
            <IonCheckbox
              checked={offer.discuss}
              className={styles.checkbox}
              onIonChange={({ detail: { checked } }) =>
                setOffer({ ...offer, discuss: checked })
              }
            />
            <IonLabel className={styles.label}>
              Discuss how much I should offer with a AECORN agent
            </IonLabel>
          </IonItem>
        )}
        <div className={styles.row}>
          <IonItem>
            <IonLabel>
              {status.toLowerCase() === "sale"
                ? "Preferred closing date"
                : "Lease start date"}
            </IonLabel>
            <IonDatetimeButton datetime="date"></IonDatetimeButton>
            <IonModal keepContentsMounted>
              <IonDatetime
                id="date"
                value={offer.date}
                presentation="date"
                showDefaultButtons
                className={styles.offerDate}
                disabled={offer.disableDate}
                min={moment().format("YYYY-MM-DD")}
                max={new Date().getFullYear() + 1}
                displayFormat="MMM DD YYYY"
                placeholder={
                  status.toLowerCase() === "sale"
                    ? "Preferred closing date"
                    : "Lease start date"
                }
                onIonChange={({ detail: { value } }) =>
                  setOffer({ ...offer, date: value })
                }
              />
            </IonModal>
          </IonItem>

          <IonItem lines="none" className={styles.checkboxContainer}>
            <IonLabel className={`${styles.label} ion-text-wrap`}>
              Not sure yet
            </IonLabel>
            <IonCheckbox
              checked={offer.disableDate}
              className={styles.checkbox}
              onIonChange={({ detail: { checked } }) =>
                setOffer({ ...offer, disableDate: checked })
              }
              slot="start"
            />
          </IonItem>
        </div>
      </div>

      {error && <div className={styles.error}>{error}</div>}

      <Button
        onClick={handleOfferSubmit}
        title="Submit"
        type="green"
        loading={loading}
        style={
          !value || (!value.includes("information") && !addDate.length)
            ? {
                marginTop: "2rem",
                height: "5rem",
                width: "100%",
                border: "1px solid #1f7a60",
              }
            : {
                marginTop: "2rem",
                height: "5rem",
                width: "100%",
                border: "1px solid #1f7a60",
              }
        }
      />
      <div className={styles.declaration}>
        By submitting this form, I understand AECORN will share my information
        with registered real estate professionals.
      </div>
    </div>
  ) : data.cashback ? (
    <div className={styles.requestForm}>
      {data.isHomepage ? (
        <div className={styles.title}>Keep an eye on your mailbox!</div>
      ) : (
        <div className={styles.title}>We’ll be in touch!</div>
      )}

      {data.isHomepage ? (
        <div className={styles.subtitle}>
          We’ll be in touch. More information on AECORN à la carte, coming your
          way.
        </div>
      ) : data.isPrecon ? (
        <div className={styles.subtitle}>
          What are you going to spend your cashback on?
        </div>
      ) : (
        <div className={styles.subtitle}>
          What are you going to spend your <span>${data.cashbackAmount}</span>{" "}
          cashback on?
        </div>
      )}

      {/* <div className={styles.subtitle}>
        {data.isSale
          ? `Ettie’s cashback system enables you to avoid paying the standard 2.5%
        commission, and instead pay for only the services you actually use.`
          : `Ettie's cashback system makes the entire lease commission available to the buyer. Pay for only the services you actually use and receive cashback for the rest!`}
        Once you submit your information, we'll put you in touch with an Ettie
        agent as soon as possible!
      </div> */}
      <div className={styles.sectionTitle}>Contact information</div>
      <div className={styles.inputRow}>
        <Input
          required
          name="given_name"
          label="First name"
          placeholder="First name"
          type="text"
          ref={inputRefs.current.given_name}
          value={formData["given_name"]}
          defaultValue={currentClient && currentClient.givenName}
          onChange={(value, name) => handleChange(value, name)}
          validation="required|min:2"
        />
        <Input
          required
          name="family_name"
          label="Last name"
          placeholder="Last name"
          type="text"
          ref={inputRefs.current.family_name}
          value={formData["family_name"]}
          defaultValue={currentClient && currentClient.familyName}
          onChange={(value, name) => handleChange(value, name)}
          validation="required|min:2"
        />
      </div>
      <div className={styles.inputRow}>
        <Input
          required
          name="phone_number"
          label="Phone"
          placeholder="Phone"
          // type="phone"
          mode="tel"
          ref={inputRefs.current.phone_number}
          value={formData["phone_number"]}
          // defaultValue={
          //   currentClient && currentClient.phone ? currentClient : ""
          // }
          onChange={(value, name) => handleChange(value, name)}
          validation="required|phone"
        />
        <Input
          required
          name="email"
          label="Email"
          placeholder="Email"
          // type="email"
          mode="email"
          ref={inputRefs.current.email}
          value={formData["email"]}
          defaultValue={currentClient && currentClient.email}
          onChange={(value, name) => handleChange(value.toLowerCase(), name)}
          validation="required|email"
        />
      </div>
      <span className={styles.textarealabel}>Message</span>
      <textarea
        className={styles.textarea}
        placeholder="I'm ready to start saving thousands in commission!"
        name="textarea"
        value={text}
        onChange={handleMessage}></textarea>

      {data.isHomepage && (
        <div>
          <div>
            <div className={styles.budgetRow}>
              <span>Budget:</span>

              <Input
                name="budget"
                mode="numeric"
                defaultValue={budget}
                value={budget}
                prefix="$"
                onChange={handleBudgetInputChange}
                style={{ width: "25rem" }}
              />
            </div>
            <IonRange
              name="budget"
              className={styles.range}
              min="400000"
              max="5000000"
              step={50000}
              value={+budget.replace(/,/g, "")}
              onIonChange={handleBudgetRangeChange}
            />
          </div>
          <div className={styles.savingRow}>
            <span className={styles.savingAmount}>
              ${calculateCashback(+budget.replace(/,/g, ""), "sale")}
            </span>
            <TextAnimation.Slide
              target="for"
              text={[
                " for your next vacation",
                " for that new car",
                " for your kids college fund",
              ]}
              cname="textAnimation"
              id="textAnimation__slide"
              animation={{
                duration: 2000,
                delay: 4000,
                timingFunction: "ease-out",
              }}
              loop={true}>
              in savings for
            </TextAnimation.Slide>
          </div>
        </div>
      )}

      {error && <div className={styles.error}>{error}</div>}

      <Button
        onClick={handleCashbackSubmit}
        title="Submit"
        type="green"
        loading={loading}
        style={
          !value || (!value.includes("information") && !addDate.length)
            ? {
                marginTop: "2rem",
                height: "5rem",
                width: "100%",
                border: "1px solid #E5E5E5",
              }
            : {
                marginTop: "2rem",
                height: "5rem",
                width: "100%",
                border: "1px solid #1f7a60",
              }
        }
      />
      <div className={styles.declaration}>
        By submitting this form, I understand AECORN will share my information
        with registered real estate professionals.
      </div>
    </div>
  ) : (
    <div className={styles.requestForm}>
      <div className={styles.title}>Request form</div>
      <div className={styles.subtitle}>
        Complete this form to contact the agent or schedule a viewing.
      </div>
      <div className={styles.sectionTitle}>Contact information</div>
      <div className={styles.inputRow}>
        <Input
          required
          name="given_name"
          label="First name"
          placeholder="First name"
          type="text"
          ref={inputRefs.current.given_name}
          value={formData["given_name"]}
          defaultValue={currentClient && currentClient.givenName}
          onChange={(value, name) => handleChange(value, name)}
          validation="required|min:2"
        />
        <Input
          required
          name="family_name"
          label="Last name"
          placeholder="Last name"
          type="text"
          ref={inputRefs.current.family_name}
          value={formData["family_name"]}
          defaultValue={currentClient && currentClient.familyName}
          onChange={(value, name) => handleChange(value, name)}
          validation="required|min:2"
        />
      </div>
      <div className={styles.inputRow}>
        <Input
          required
          name="phone_number"
          label="Phone"
          placeholder="Phone"
          // type="phone"
          mode="tel"
          ref={inputRefs.current.phone_number}
          value={formData["phone_number"]}
          // defaultValue={
          //   currentClient && currentClient.phone ? currentClient.phone : ""
          // }
          onChange={(value, name) => handleChange(value, name)}
          validation="required|phone"
        />
        <Input
          required
          name="email"
          label="Email"
          placeholder="Email"
          // type="email"
          mode="email"
          ref={inputRefs.current.email}
          value={formData["email"]}
          defaultValue={currentClient && currentClient.email}
          onChange={(value, name) => handleChange(value.toLowerCase(), name)}
          validation="required|email"
        />
      </div>
      <div className={styles.sectionTitle}>I want to:*</div>
      <div className={styles.options}>
        {active && (
          <>
            <RadioButton
              label="request in-person viewing"
              onClick={radioButtonHandler}
              value={value}
            />
            <RadioButton
              label="request virtual viewing"
              onClick={radioButtonHandler}
              value={value}
            />
          </>
        )}
        <RadioButton
          label="request information"
          onClick={radioButtonHandler}
          value={value}
        />
      </div>
      {value && (
        <>
          <span className={styles.textarealabel}>Message</span>
          <textarea
            className={styles.textarea}
            placeholder="I’d like to book an appointment to ..."
            name="textarea"
            value={text}
            onChange={handleMessage}></textarea>
        </>
      )}
      {showTime && (
        <>
          <div className={styles.dateTitle}>
            Dates and times I am available:*
          </div>
          {addDate.map((date, i) => (
            <IonItem key={i} lines="none">
              <IonDatetimeButton datetime={`datetime-${i}`} />
              <IonModal keepContentsMounted>
                <IonDatetime
                  id={`datetime-${i}`}
                  onIonChange={(event) =>
                    updateDateHandler(i, event.detail.value)
                  }
                  presentation="date-time"
                  value={date}
                  showDefaultButtons
                  min={moment().toISOString()}
                />
              </IonModal>
            </IonItem>
          ))}
          <div className={styles.addTime} onClick={addDateHandler}>
            <div className={styles.addIcon}>
              <IonIcon icon={addOutline} />
            </div>
            <div>Add {addDate.length ? "another" : "a"} time slot</div>
          </div>
        </>
      )}
      {error && <div className={styles.error}>{error}</div>}

      <Button
        onClick={handleSubmit}
        disabled={!value || (!value.includes("information") && !addDate.length)}
        title="Submit"
        type="green"
        loading={loading}
        style={
          !value || (!value.includes("information") && !addDate.length)
            ? {
                marginTop: "2rem",
                height: "5rem",
                width: "100%",
                border: "1px solid #E5E5E5",
              }
            : {
                marginTop: "2rem",
                height: "5rem",
                width: "100%",
                border: "1px solid #1f7a60",
              }
        }
      />
      <div className={styles.declaration}>
        By submitting this form, I understand AECORN will share my information
        with registered real estate professionals.
      </div>
    </div>
  );
};

export default withClient(RequestFormModal);
