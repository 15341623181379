import { useState, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import TourOptionsSegment from "../../components/TourOptionsSegment/tour-options-segment.component";
import styles from "./agent-tours.module.scss";
import TourCard from "./component/TourCard/tour-card.component";
import { IonLoading } from "@ionic/react";
import {
  clearCurrentTour,
  fetchToursStart,
} from "../../../redux/tour/tour.actions";
import MobileEditBar from "../../components/MobileEditBar/mobile-edit-bar.component";
import { isBeforeOrAfter } from "../../../utils/functions";
import Refresher from "../../components/Refresher/refresher.component";
import { TourService } from "../../../services/tourService";

const AppAgentTours = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState("upcoming");
  const [sortBy, setSortBy] = useState({
    value: "date",
    direction: "desc",
  });
  const [tours, setTours] = useState([]);
  const [sortedTours, setSortedTours] = useState();
  const [loading, setLoading] = useState(false);

  const getTours = async (inBackground) => {
    !inBackground && setLoading(true);
    try {
      const fetchedTours = await TourService.getAgentTours();
      setTours(fetchedTours);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
    !inBackground && setLoading(false);
  };

  useEffect(() => {
    getTours();
  }, []);
  useEffect(() => {
    dispatch(clearCurrentTour());
    dispatch(fetchToursStart());
  }, [dispatch]);

  useEffect(() => {
    let sorted;
    if (tours.length) {
      switch (sortBy.value) {
        case "date":
          sorted = tours.sort((a, b) => new Date(b.date) - new Date(a.date));
          setSortedTours(
            sortBy.direction === "desc" ? [...sorted] : [...sorted.reverse()]
          );
          break;
        case "status":
          sorted = tours.sort((a, b) => b.status - a.status);
          setSortedTours(
            sortBy.direction === "desc" ? [...sorted] : [...sorted.reverse()]
          );
          break;
        case "stops":
          sorted = tours.sort(
            (a, b) => b.tourItems.items.length - a.tourItems.items.length
          );
          setSortedTours(
            sortBy.direction === "desc" ? [...sorted] : [...sorted.reverse()]
          );
          break;
        case "title":
          sorted = tours.sort((a, b) => {
            if (a.title < b.title) {
              return -1;
            }
            if (a.title > b.title) {
              return 1;
            }
            return 0;
          });
          setSortedTours(
            sortBy.direction === "desc" ? [...sorted] : [...sorted.reverse()]
          );
          break;
        case "client":
          sorted = tours.sort((a, b) => {
            if (!a.users.items[0] && !b.users.items[0]) return 0;
            if (!a.users.items[0]) return 1;
            if (!b.users.items[0]) return -1;
            if (a.users.items[0].name < b.users.items[0].name) {
              return -1;
            }
            if (a.users.items[0].name > b.users.items[0].name) {
              return 1;
            }
            return 0;
          });
          setSortedTours(
            sortBy.direction === "desc" ? [...sorted] : [...sorted.reverse()]
          );
          break;
        case "agent":
          sorted = tours.sort((a, b) => {
            const showingAgentA = a.users.items.find(
              (u) => u.role === "showingagent"
            );
            const showingAgentB = b.users.items.find(
              (u) => u.role === "showingagent"
            );
            if (!showingAgentA && !showingAgentB) return 0;
            if (!showingAgentA) return 1;
            if (!showingAgentB) return -1;
            return showingAgentB.givenName.toLowerCase() >
              showingAgentA.givenName.toLowerCase()
              ? 1
              : -1;
          });
          setSortedTours(
            sortBy.direction === "desc" ? sorted : sorted.reverse()
          );
          break;
        default:
          setSortedTours(
            tours.sort((a, b) => new Date(b.date) - new Date(a.date))
          );
      }
    }
  }, [sortBy, tours]);

  const getToursByType = () => {
    if (loading) return;
    if (sortedTours && sortedTours.length === 0) {
      return (
        <div className={styles.card}>
          <h3>You have no active tours right now.</h3>
          <p>Get a tour started, and it will appear here.</p>
        </div>
      );
    } else if (sortedTours && sortedTours.length > 0) {
      if (tab === "upcoming") {
        const results = sortedTours.filter(
          (t) =>
            !["archived"].includes(t.status) &&
            (!t.date || isBeforeOrAfter(t.date) >= 0)
        );
        return results.length > 0 ? (
          results.map((item, i) => <TourCard key={i} item={item} />)
        ) : (
          <div className={styles.card}>
            <p>
              You have no active tours right now. When you get a tour started,
              it will appear here.
            </p>
          </div>
        );
      } else if (tab === "past") {
        const results = sortedTours.filter((t) => isBeforeOrAfter(t.date) < 0);
        return results.length > 0 ? (
          results.map((item, i) => <TourCard key={i} item={item} />)
        ) : (
          <div className={styles.card}>
            <p>
              You have no past tours right now. Only past tours will appear
              here.
            </p>
          </div>
        );
      } else if (tab === "archived") {
        const results = sortedTours.filter((t) =>
          ["archived"].includes(t.status)
        );
        return results.length > 0 ? (
          <>
            <div className={styles.archiveSubtitle}>
              Archived tours are view-only. They can no longer be edited by
              client or agent.
            </div>
            {results.map((item, i) => (
              <TourCard key={i} item={item} />
            ))}
          </>
        ) : (
          <div className={styles.card}>
            <p>
              You have no archived tours right now. Once you have archived a
              tour, it will appear here.
            </p>
          </div>
        );
      }
    }
  };

  const handleRefresh = async (ref) => {
    await getTours(true);
    ref.complete();
  };

  return (
    <div className={styles.appAgentTours}>
      <IonLoading isOpen={loading} />
      <Refresher onRefresh={handleRefresh} background="primary" />
      <div className={styles.body}>
        <TourOptionsSegment tab={tab} setTab={setTab} />
        <MobileEditBar
          sortBy={sortBy}
          setSortBy={setSortBy}
          mode="tours"
          disabled={!sortedTours || !sortedTours.length}
        />
        <div className={styles.cards}>{getToursByType()}</div>
      </div>
    </div>
  );
};

export default memo(AppAgentTours);
