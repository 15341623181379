import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styles from "./projects.module.scss";
import Button from "../../../../components/Form/Button/button.component";
import { DEFAULT_PROJECT_STATE, validateNewProjectForm } from "../../utils";
import { v4 as uuidv4 } from "uuid";
import { updateToast } from "../../../../redux/ui/ui.actions";
import {
  findProject,
  getAllAmenities,
  modifyProject,
  searchForArchitects,
  searchForBuilders,
  searchForDesigners,
} from "../../../../amplify/graphql.utils";
import PreInput from "../../components/input";
import PreSearch from "../../components/search";
import PreMultiple from "../../components/multiple";
import PreRadio from "../../components/radio";
import PreCheckbox from "../../components/checkbox";
import PreMedia from "../../components/media";
import { TYPES } from "./data";
import { IonSpinner } from "@ionic/react";
import BreadCrumbs from "../../components/breadcrumbs";

const Project = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [project, setProject] = useState(DEFAULT_PROJECT_STATE);
  const [availableAmenities, setAvailableAmenities] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [builders, setBuilders] = useState([]);
  const [designers, setDesigners] = useState([]);
  const [architects, setArchitects] = useState([]);
  const [dropdown, setDropdown] = useState("");

  const handleSubmit = async () => {
    if (!validateForm()) {
      document.getElementById("errors")?.scrollIntoView();
    } else {
      try {
        setLoading(true);
        await modifyProject({ project, selectedAmenities });
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: "Project updated successfully!",
          })
        );
        history.replace(`/admin/projects`);
      } catch (err) {
        // console.log(err);
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong!",
          })
        );
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const getProject = async () => {
      try {
        const foundProject = await findProject({ id });
        // console.log("Found Project");
        // console.log(foundProject);
        const amenities = await getAllAmenities();
        const mappedAmenities = amenities.map((record) => {
          return { label: record.name, value: record.id, item: record };
        });

        const currentAmenities = foundProject.amenities.items.map((record) => {
          return { value: record.amenityID, item: { id: record.amenityID } };
        });

        setAvailableAmenities(mappedAmenities);
        setSelectedAmenities(foundProject.amenities.items);

        const { types } = foundProject;
        const projectTypes = {
          condo: types.includes("condo"),
          townhouse: types.includes("townhouse"),
          single: types.includes("single"),
        };

        setProject({
          ...project,
          id: foundProject.id,
          isSoldOut: foundProject.isSoldOut,
          name: foundProject.name,
          thumbnail: foundProject.logo,
          occupancy: foundProject.occupancy,
          address: {
            streetNumber: foundProject.streetNumber,
            streetName: foundProject.streetName,
            city: foundProject.city,
            province: foundProject.province,
            latitude: foundProject.latitude,
            longitude: foundProject.longitude,
          },
          price: {
            start: foundProject.startPrice,
            end: foundProject.endPrice,
            avg: foundProject.pricePerSqft,
            parking: foundProject.parkingPrice,
            locker: foundProject.lockerPrice,
            maintenance: foundProject.maintenanceFees,
          },
          beds: {
            min: foundProject.minBeds,
            max: foundProject.maxBeds,
          },
          sqft: {
            min: foundProject.minSqft,
            max: foundProject.maxSqft,
          },
          description: foundProject.description,
          incentives: JSON.parse(foundProject.incentives),
          deposit: JSON.parse(foundProject.depositStructure),
          amenities: currentAmenities,
          types: projectTypes,
          media: JSON.parse(foundProject.media),
          phase: foundProject.phase,
          availability: foundProject.availability,
          units: foundProject.units,
          stories: foundProject.stories,
          builder: foundProject.builderId,
          architectId: foundProject.architectId,
          architect: foundProject.architect,
          interiorDesignerId: foundProject.interiorDesignerId,
          interiorDesigner: foundProject.interiorDesigner,
        });

        foundProject.builder && setBuilders([foundProject.builder]);
        foundProject.interiorDesigner &&
          setDesigners([foundProject.interiorDesigner]);
        foundProject.architect && setArchitects([foundProject.architect]);
      } catch (err) {
        history.replace("/admin");
      }
    };
    setProject(DEFAULT_PROJECT_STATE);
    getProject();
  }, [id]);

  const validateForm = () => {
    const errs = validateNewProjectForm(project);
    setErrors(errs);
    return errs.length === 0;
  };

  const handleBuilderSearch = async ({ detail: { value } }) => {
    try {
      if (value) {
        const builders = await searchForBuilders({ name: value });
        setBuilders(builders);
      } else {
        setBuilders([]);
      }
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };
  const handleArchitectSearch = async ({ detail: { value } }) => {
    try {
      if (value) {
        const archtiects = await searchForArchitects({ name: value });
        setArchitects(archtiects);
      } else {
        setArchitects([]);
      }
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };
  const handleDesignerSearch = async ({ detail: { value } }) => {
    try {
      if (value) {
        const designers = await searchForDesigners({ name: value });
        setDesigners(designers);
      } else {
        setDesigners([]);
      }
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };

  return (
    <div className={styles.container}>
      <BreadCrumbs type="projects" sub={project.name} />
      <h2>Update project</h2>
      {project.id ? (
        <div className={styles.form}>
          <PreRadio
            required
            label="Sold out?"
            value={project.isSoldOut}
            onChange={(val) => setProject({ ...project, isSoldOut: val })}
            style={{ gridColumn: "1/-1" }}
            items={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
          <PreInput
            value={project.name}
            required
            label={"Name"}
            placeholder={"Project name"}
            onChange={(val) => setProject({ ...project, name: val })}
          />
          <PreInput
            value={project.thumbnail}
            label={"Thumbnail URL"}
            placeholder={"Project thumbnail"}
            pattern="url"
            onChange={(val) => setProject({ ...project, thumbnail: val })}
          />

          <div
            className={styles.field}
            style={{ flexDirection: project.builder ? "row" : "column" }}
          >
            <PreSearch
              required
              label="Project builder"
              selected={builders[0]?.name}
              placeholder="Project builder"
              showSearchBar={!project.builder}
              showResults={dropdown === "builder" && builders.length}
              results={builders}
              onRemove={() => {
                setProject({ ...project, builder: null });
                setBuilders([]);
              }}
              onChange={handleBuilderSearch}
              onFocus={() => setDropdown("builder")}
              onBlur={() => setDropdown("")}
              onSelect={(val) => setProject({ ...project, builder: val })}
            />
          </div>
          <div
            className={styles.field}
            style={{ flexDirection: project.architect ? "row" : "column" }}
          >
            <PreSearch
              label="Project architect"
              selected={architects[0]?.name}
              placeholder="Project architect"
              showSearchBar={!project.architect}
              showResults={dropdown === "architect" && architects.length}
              results={architects}
              onRemove={() => {
                setProject({ ...project, architect: null, architectId: null });
                setArchitects([]);
              }}
              onChange={handleArchitectSearch}
              onFocus={() => setDropdown("architect")}
              onBlur={() => setDropdown("")}
              onSelect={(val) => setProject({ ...project, architect: val })}
            />
          </div>
          <div
            className={styles.field}
            style={{ flexDirection: project.designer ? "row" : "column" }}
          >
            <PreSearch
              label="Project interior designer"
              selected={designers[0]?.name}
              placeholder="Project interior designer"
              showSearchBar={!project.interiorDesigner}
              showResults={dropdown === "designer" && designers.length}
              results={designers}
              onRemove={() => {
                setProject({
                  ...project,
                  interiorDesigner: null,
                  interiorDesignerId: null,
                });
                setDesigners([]);
              }}
              onChange={handleDesignerSearch}
              onFocus={() => setDropdown("designer")}
              onBlur={() => setDropdown("")}
              onSelect={(val) =>
                setProject({ ...project, interiorDesigner: val })
              }
            />
          </div>
          <br />
          <PreInput
            value={project.occupancy}
            label={"Occupancy"}
            placeholder={"Project occupancy"}
            onChange={(val) => setProject({ ...project, occupancy: val })}
          />
          <PreInput
            value={project.units}
            label={"Units"}
            type="number"
            placeholder={"Project units"}
            onChange={(val) => setProject({ ...project, units: val })}
          />
          <PreInput
            value={project.stories}
            label={"Stories"}
            type="number"
            placeholder={"Project stories"}
            onChange={(val) => setProject({ ...project, stories: val })}
          />
          <PreInput
            value={project.address.streetNumber}
            label={"Street number"}
            required
            placeholder={"Street number"}
            onChange={(val) =>
              setProject({
                ...project,
                address: { ...project.address, streetNumber: val },
              })
            }
          />
          <PreInput
            value={project.address.streetName}
            label={"Street name"}
            required
            placeholder={"Street name"}
            onChange={(val) =>
              setProject({
                ...project,
                address: { ...project.address, streetName: val },
              })
            }
          />
          <PreInput
            value={project.address.city}
            label={"City"}
            required
            placeholder={"City"}
            onChange={(val) =>
              setProject({
                ...project,
                address: { ...project.address, city: val },
              })
            }
          />
          <PreInput
            value={project.address.province}
            label={"Province"}
            required
            placeholder={"Province"}
            onChange={(val) =>
              setProject({
                ...project,
                address: { ...project.address, province: val },
              })
            }
          />
          <PreInput
            value={project.address.latitude}
            label={"Latitude"}
            required
            placeholder={"Latitude"}
            onChange={(val) =>
              setProject({
                ...project,
                address: { ...project.address, latitude: val },
              })
            }
          />
          <PreInput
            value={project.address.longitude}
            label={"Longitude"}
            required
            placeholder={"Longitude"}
            onChange={(val) =>
              setProject({
                ...project,
                address: { ...project.address, longitude: val },
              })
            }
          />
          <PreInput
            value={project.price.start}
            label={"Starting price"}
            required
            type="number"
            placeholder={"Starting price"}
            onChange={(val) =>
              setProject({
                ...project,
                price: { ...project.price, start: val },
              })
            }
          />
          <PreInput
            value={project.price.end}
            label={"Ending price"}
            type="number"
            placeholder={"Ending price"}
            onChange={(val) =>
              setProject({
                ...project,
                price: { ...project.price, end: val },
              })
            }
          />
          <PreInput
            value={project.price.avg}
            label={"Price per sqft"}
            type="number"
            placeholder={"Price per sqft"}
            onChange={(val) =>
              setProject({
                ...project,
                price: { ...project.price, avg: val },
              })
            }
          />
          <PreInput
            value={project.price.parking}
            label={"Parking price"}
            type="number"
            placeholder={"Parking price"}
            onChange={(val) =>
              setProject({
                ...project,
                price: { ...project.price, parking: val },
              })
            }
          />
          <PreInput
            value={project.price.locker}
            label={"Locker price"}
            type="number"
            placeholder={"Locker price"}
            onChange={(val) =>
              setProject({
                ...project,
                price: { ...project.price, locker: val },
              })
            }
          />
          <PreInput
            value={project.price.maintenance}
            label={"Maintenance fee per sqft"}
            type="number"
            placeholder={"Maintenance fee per sqft"}
            onChange={(val) =>
              setProject({
                ...project,
                price: { ...project.price, maintenance: val },
              })
            }
          />
          <PreInput
            value={project.beds.min}
            label={"Min beds"}
            type="number"
            placeholder={"Min beds"}
            onChange={(val) =>
              setProject({
                ...project,
                beds: { ...project.beds, min: val },
              })
            }
          />
          <PreInput
            value={project.beds.max}
            label={"Max beds"}
            type="number"
            placeholder={"Max beds"}
            onChange={(val) =>
              setProject({
                ...project,
                beds: { ...project.beds, max: val },
              })
            }
          />
          <PreInput
            value={project.sqft.min}
            label={"Min sqft"}
            type="number"
            placeholder={"Min sqft"}
            onChange={(val) =>
              setProject({
                ...project,
                sqft: { ...project.sqft, min: val },
              })
            }
          />
          <PreInput
            value={project.sqft.max}
            label={"Max sqft"}
            type="number"
            placeholder={"Max sqft"}
            onChange={(val) =>
              setProject({
                ...project,
                sqft: { ...project.sqft, max: val },
              })
            }
          />
          <br />
          <PreInput
            value={project.description}
            label={"Description"}
            placeholder={"Description"}
            autoGrow
            style={{ gridColumn: "1/-1" }}
            onChange={(val) =>
              setProject({
                ...project,
                description: val,
              })
            }
          />
          <PreMultiple
            label="Incentives"
            styles={{ addIcon: styles.addIcon }}
            items={project.incentives}
            onAdd={() =>
              setProject({
                ...project,
                incentives: [
                  ...project.incentives,
                  { id: uuidv4(), value: "" },
                ],
              })
            }
            onChange={({ index, value }) => {
              let incentives = [...project.incentives];
              incentives[index].value = value;
              setProject({ ...project, incentives });
            }}
            onRemove={(id) => {
              setProject({
                ...project,
                incentives: project.incentives.filter((item) => item.id !== id),
              });
            }}
          />

          <PreMultiple
            label="Deposit structure"
            styles={{ addIcon: styles.addIcon }}
            items={project.deposit}
            onAdd={() =>
              setProject({
                ...project,
                deposit: [...project.deposit, { id: uuidv4(), value: "" }],
              })
            }
            onChange={({ index, value }) => {
              let deposit = [...project.deposit];
              deposit[index].value = value;
              setProject({ ...project, deposit });
            }}
            onRemove={(id) => {
              setProject({
                ...project,
                deposit: project.deposit.filter((item) => item.id !== id),
              });
            }}
          />

          <PreRadio
            required
            label="Phase"
            value={project.phase}
            onChange={(val) => setProject({ ...project, phase: val })}
            style={{ gridColumn: "1/-1" }}
            items={[
              { label: "Pre-construction", value: "preconstruction" },
              { label: "Under construction", value: "underconstruction" },
              { label: "Completed", value: "completed" },
            ]}
          />
          <PreRadio
            required
            label="Availability"
            value={project.availability}
            onChange={(val) => setProject({ ...project, availability: val })}
            style={{ gridColumn: "1/-1" }}
            items={[
              { label: "Available", value: "available" },
              { label: "Coming soon", value: "comingsoon" },
            ]}
          />
          <PreCheckbox
            label="Property Types"
            styles={{
              list: { gridColumn: "1/-1" },
              header: { fontSize: "1.5rem" },
              label: { fontSize: "1.4rem", color: "#363636" },
              checkboxes: styles.checkboxes,
            }}
            items={TYPES}
            checked={project.types}
            onChange={(item) =>
              setProject({
                ...project,
                types: { ...project.types, [item.value]: item.checked },
              })
            }
          />
          <PreCheckbox
            label="Amenities"
            styles={{
              list: { gridColumn: "1/-1" },
              header: { fontSize: "1.5rem" },
              label: { fontSize: "1.4rem", color: "#363636" },
              checkboxes: styles.checkboxes,
            }}
            items={availableAmenities}
            checked={project.amenities}
            isAmenity
            onChange={(item) => {
              const { checked } = item;
              if (checked) {
                setProject({
                  ...project,
                  amenities: [
                    ...project.amenities,
                    availableAmenities.find(
                      (record) => record.value === item.value
                    ),
                  ],
                });
              } else {
                setProject({
                  ...project,
                  amenities: project.amenities.filter(
                    (record) => record.value !== item.value
                  ),
                });
              }
            }}
          />
          <PreMedia
            label="Media"
            styles={{ addIcon: styles.addIcon, item: { minWidth: "20rem" } }}
            items={project.media}
            onAdd={() =>
              setProject({
                ...project,
                media: [
                  ...project.media,
                  { id: uuidv4(), url: "", type: "image" },
                ],
              })
            }
            onMediaChange={({ index, value }) => {
              let media = [...project.media];
              media[index].value = value;
              setProject({ ...project, media });
            }}
            onTypeChange={({ index, value }) => {
              let media = [...project.media];
              media[index].type = value;
              setProject({ ...project, media });
            }}
            onRemove={(id) =>
              setProject({
                ...project,
                media: project.media.filter((item) => item.id !== id),
              })
            }
          />

          <div id="errors" className={styles.error}>
            {errors.map((error, i) => (
              <div key={i}>- {error}</div>
            ))}
          </div>
          <div className={styles.submit}>
            <Button
              title={"Update"}
              type={"green"}
              loading={loading}
              onClick={handleSubmit}
            />
          </div>
        </div>
      ) : (
        <div className={styles.loading}>
          <IonSpinner className={styles.spinner} name="dots" />
        </div>
      )}
    </div>
  );
};

export default Project;
