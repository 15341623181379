import UserActionTypes from "./user.types";

export const clearErrors = () => ({
  type: UserActionTypes.CLEAR_ERRORS,
});

export const federatedSignInStart = (provider) => ({
  type: UserActionTypes.FEDERATED_SIGN_IN_START,
  payload: provider,
});

export const emailSignInStart = (emailAndPassword) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAndPassword,
});

export const signInSuccess = (payload) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: payload,
});

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error,
});

export const getGuestStart = () => ({
  type: UserActionTypes.GET_GUEST_START,
});

export const getGuestSuccess = (guest) => ({
  type: UserActionTypes.GET_GUEST_SUCCESS,
  payload: guest,
});

export const getGuestFailure = (error) => ({
  type: UserActionTypes.GET_GUEST_FAILURE,
  payload: error,
});

export const checkUserSession = (bypassCache = false) => ({
  type: UserActionTypes.CHECK_USER_SESSION,
  payload: bypassCache,
});

export const signOutStart = (showRegister) => ({
  type: UserActionTypes.SIGN_OUT_START,
  payload: showRegister,
});

export const signOutSuccess = (showRegister) => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
  payload: showRegister,
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error,
});

export const signUpStart = (userCredentials) => ({
  type: UserActionTypes.SIGN_UP_START,
  payload: userCredentials,
});

export const signUpSuccess = ({ user, additionalData }) => ({
  type: UserActionTypes.SIGN_UP_SUCCESS,
  payload: { user, additionalData },
});

export const signUpFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_FAILURE,
  payload: error,
});

export const confirmSignUpStart = (userData) => ({
  type: UserActionTypes.CONFIRM_SIGN_UP_START,
  payload: userData,
});

export const confrimSignUpSuccess = ({ user, additionalData }) => ({
  type: UserActionTypes.CONFIRM_SIGN_UP_SUCCESS,
  payload: { user, additionalData },
});

export const confirmSignUpFailure = (error) => ({
  type: UserActionTypes.CONFIRM_SIGN_UP_FAILURE,
  payload: error,
});

export const updateUser = (user) => ({
  type: UserActionTypes.UPDATE_USER,
  payload: user,
});
