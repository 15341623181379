import React, { useEffect, useState, createContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonToast,
  IonModal,
  IonIcon,
  IonAlert,
  IonLoading,
} from "@ionic/react";
import { App as CapApp } from "@capacitor/app";
import { Market } from "@ionic-native/market";
import "./App.scss";
import { API } from "aws-amplify";
import { LastLocationProvider } from "react-router-last-location";
import "@ionic/react/css/core.css";
import { fetchLocationsStart } from "./redux/locations/locations.actions";
import { selectLocationsValue } from "./redux/locations/locations.selectors";
import Layout from "./pages/Layout/layout.component";
import Home from "./pages/Home/home.component";
import FavouriteListings from "./pages/Favourites/favorite-listings.component";
import SavedSearches from "./pages/SavedSearch/saved-searches-listings.component";
import Logout from "./components/Authentication/Logout/logout-component";
import UserAccount from "./pages/User/user-account.component";
import DetailPage from "./pages/Detail/detail.component";
import { fetchCurrencyStart } from "./redux/currency/currency.actions";
import {
  isAndroid,
  isApp as checkPlatform,
  isCapacitor,
} from "./utils/functions";
import styles from "./App.module.scss";
import { selectCurrentUser, selectIsAgent } from "./redux/user/user.selectors";
import { selectFederatedLoading, selectUIToast } from "./redux/ui/ui.selectors";
import Tours from "./pages/Tours/tours.component";
import Tour from "./pages/Tour/tour.component";
import TourDetails from "./pages/TourDetails/tour-details.component";
import ClientTours from "./pages/ClientTours/client-tours.component";
import ClientTourDetails from "./pages/ClientTourDetails/client-tour-details";
import {
  updateToast,
  updateChangedFavourite,
  fetchOfferStart,
  fetchRateStart,
} from "./redux/ui/ui.actions";
import { GraphQLSubscription } from "@aws-amplify/api";
import ToursRoute from "./routes/ToursRoute";
import AppUrlListener from "./utils/appUrlListener";
import { selectCurrentClient } from "./redux/client/client.selectors";
import ConfirmRegistration from "./app/components/Authentication/CustomAuthContainer/components/ConfirmRegistration/confirm-registration.component";
import AppTermsOfService from "./app//pages/TermsOfService/terms-of-service.component";
import AppPrivacyPolicy from "./app/pages/PrivacyPolicy/privacy-policy.component";
import TermsOfService from "./pages/Terms/TermsofService/terms-of-service.component";
import PrivacyPolicy from "./pages/Terms/PrivacyPolicy/privacy-policy.component";
import { clearClient } from "./redux/client/client.actions";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { StatusBar } from "@ionic-native/status-bar";
import AppSignIn from "./app/pages/Signin/sign-in.component";
import AppSignUp from "./app/pages/Signup/sign-up.component";
import ContactUs from "./pages/MeetEttie/ContactUs/contact-us.component";
import Feedback from "./pages/MeetEttie/Feedback/feedback.component";
import AboutUs from "./pages/MeetEttie/AboutUs/about-us.components";
import { App as CapacitorApp } from "@capacitor/app";
import AppTabs from "./routes/Tabs";
import AppSchoolDetails from "./app/pages/SchoolDetails/school-details.component";
import SchoolDetails from "./pages/SchoolDetails/school-details.component";
import { getPlatforms } from "@ionic/core/components";
import { browsersOutline } from "ionicons/icons";
import { Helmet } from "react-helmet";
import MarketData from "./pages/MeetEttie/MarketData/market-data.component";
import { storeIds, storeUrls } from "./utils/constants";
import {
  onFavouriteUpdateByUserId,
  onPreFavouriteUpdateByUserId,
} from "./graphql/subscriptions";
import SchoolDisclaimer from "./pages/SchoolDisclaimer/school-disclaimer.component";
import AppSchoolDisclaimer from "./app/pages/SchoolDisclaimer/school-disclaimer.component";
import PreConstructionDetailsPage from "./pages/PreConstructionProjectDetails/pre-construction-project-details.component";
import Admin from "./pages/Admin/admin";
import AdminRoute from "./routes/AdminRoute";
import NewBuilder from "./pages/Admin/subpages/Builders/new-builder";
import Builder from "./pages/Admin/subpages/Builders/builder";
import Builders from "./pages/Admin/subpages/Builders/builders";
import Architects from "./pages/Admin/subpages/Architects/architects";
import Architect from "./pages/Admin/subpages/Architects/architect";
import NewArchitect from "./pages/Admin/subpages/Architects/new-architect";
import Designers from "./pages/Admin/subpages/Designers/designers";
import Designer from "./pages/Admin/subpages/Designers/designer";
import NewDesigner from "./pages/Admin/subpages/Designers/new-designer";
import NewProject from "./pages/Admin/subpages/Projects/new-project";
import Units from "./pages/Admin/subpages/Units/units.component";
import NewUnits from "./pages/Admin/subpages/Units/new-unit.component";
import Unit from "./pages/Admin/subpages/Units/unit.component";
import Projects from "./pages/Admin/subpages/Projects/projects";
import Project from "./pages/Admin/subpages/Projects/project";
import Amenities from "./pages/Admin/subpages/Amenities/amenities";
import AppPreConstructionDetails from "./app/pages/PreConstructionProjectDetails/pre-construction-project-details.component";
import { OnUpdateFavouriteSubscription } from "./API";
import AppDetailsPage from "./app/pages/Details/details.component";
import AppSearchesDetailPage from "./app/pages/Searches/pages/search-details.component";
import AppFavouritesPage from "./app/pages/Favourites/favourites.component";
import AppTourItemPage from "./app/pages/Tours/tour-item.component";
import LogoIconOnly from "./components/Logo/LogoIconOnly";
import { AppUpdate } from "@capawesome/capacitor-app-update";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import Join from "./app/components/CustomModals/Join/join.component";
import { UserService } from "./services/userService";
import { IonReactRouter } from "@ionic/react-router";
import Search from "./pages/web/Search/search.page";
import { checkUserSession } from "redux/user/user.actions";
import { Hub } from "aws-amplify";

export const PlatformContext = createContext(false);

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [isApp, setIsApp] = useState(false);
  const [showConfirmRegistration, setShowConfirmRegistration] = useState(false);
  const [showDownloadApp, setShowDownloadApp] = useState({
    show: false,
    os: "",
  });
  const handleUpdate = async () => {
    await Market.open(
      getPlatforms().includes("ios") ? storeIds.apple : storeIds.android
    );
  };
  const [update, setUpdate] = useState({
    isAvailable: false,
    type: "",
    value: {
      title: "",
      msg: "",
      btn: "",
      onClick: handleUpdate,
      forced: false,
    },
  });
  const locations = useSelector(selectLocationsValue);
  const user = useSelector(selectCurrentUser);
  const isAgent = useSelector(selectIsAgent);
  const client = useSelector(selectCurrentClient);
  const toast = useSelector(selectUIToast);
  const federatedLoading = useSelector(selectFederatedLoading);
  const history = useHistory();

  const closeToast = () => {
    dispatch(
      updateToast({
        open: false,
        type: undefined,
        message: "",
        header: undefined,
        position: undefined,
      })
    );
  };

  const resizeListener = useCallback(() => {
    if (window.innerWidth < 960) setIsApp(true);
    if (window.innerWidth >= 960 && !getPlatforms().includes("capacitor")) {
      setIsApp(false);
    }
  }, [isApp]);

  useEffect(() => {
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        dispatch(checkUserSession());
      }
      if (payload.event === "signOut") {
        dispatch(clearClient());
      }
    });
  });

  useEffect(() => {
    dispatch(checkUserSession());
  }, []);

  useEffect(() => {
    if (!locations) dispatch(fetchLocationsStart());
  }, [dispatch, locations]);

  useEffect(() => {
    const checkConnection = async () => {
      setTimeout(async () => {
        await checkAppUpdates();
      }, 1000);
    };

    const getCurrentAppVersion = async () => {
      const result = await AppUpdate.getAppUpdateInfo();
      if (Capacitor.getPlatform() === "android") {
        return result.currentVersionCode;
      } else {
        return result.currentVersionName;
      }
    };

    const getAvailableAppVersion = async () => {
      const result = await AppUpdate.getAppUpdateInfo();
      if (Capacitor.getPlatform() === "android") {
        return result.availableVersionCode;
      } else {
        return result.availableVersionName;
      }
    };

    const openAppStore = async () => {
      await AppUpdate.openAppStore();
    };

    const compareVersions = (
      currentVersion: string,
      minStableVersion: string
    ) => {
      const currentParts = currentVersion.split(".").map(Number);
      const minParts = minStableVersion.split(".").map(Number);

      for (let i = 0; i < minParts.length; i++) {
        if (currentParts[i] > minParts[i]) return true;
        if (currentParts[i] < minParts[i]) return false;
      }

      return true;
    };

    const checkAppUpdates = async () => {
      if (getPlatforms().includes("capacitor")) {
        try {
          const { minStableVersion } = await API.get(
            "ettieREST",
            "/checkupdates",
            {}
          );

          const currentVersion = await getCurrentAppVersion();

          const updatedNeeded = !compareVersions(
            currentVersion,
            minStableVersion
          );

          if (updatedNeeded) {
            setUpdate({
              isAvailable: true,
              type: "update",
              value: {
                title: "New Update Available",
                msg: "A new version of AECORN is available. Please update to continue using the app.",
                btn: "Update",
                onClick: openAppStore,
                forced: true,
              },
            });
          }
        } catch (err) {
          console.log("Error checking for updates", err);
        }
      }
    };

    CapApp.addListener("appStateChange", (state) => {
      if (state.isActive) {
        checkAppUpdates();
      }
    });
    checkAppUpdates();
    dispatch(fetchCurrencyStart());
    dispatch(fetchRateStart());
    // dispatch(fetchOfferStart());
  }, []);

  useEffect(() => {
    if (checkPlatform()) {
      setIsApp(true);
    }

    if (isCapacitor()) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY);
      if (isAndroid()) {
        StatusBar.overlaysWebView(false);
        StatusBar.show();
      }
    }
  }, [user]);

  useEffect(() => {
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, [resizeListener]);

  useEffect(() => {
    if (isApp && client && !client.profile && client.role === "client") {
      setShowConfirmRegistration(true);
    }
  }, [client, isApp, user]);

  useEffect(() => {
    if (!user) {
      dispatch(clearClient());
    }
  }, [dispatch, user]);

  useEffect(() => {
    CapacitorApp.addListener("backButton", (e) => {
      const confirmRegistrationModal = document.getElementById(
        "confirmRegistration"
      );

      if (confirmRegistrationModal) {
        setShowConfirmRegistration(true);
      }

      if (
        window.location.pathname === "/home" ||
        window.location.pathname === "/listings"
      )
        CapacitorApp.exitApp();
    });
  }, []);

  useEffect(() => {
    if (getPlatforms().includes("mobileweb")) {
      const os = getPlatforms().includes("ios")
        ? "ios"
        : getPlatforms().includes("android")
        ? "android"
        : null;

      if (os === "ios" || os === "android") {
        setShowDownloadApp({
          show: true,
          os: os,
        });
      }
    }
  }, []);

  const handleOpenApp = async () => {
    await AppUpdate.openAppStore();
  };

  ///CHECK FOR FAVOURITE UPDATES
  useEffect(() => {
    let subscription: { unsubscribe: () => any };
    const subscribe = async () => {
      subscription = API.graphql<
        GraphQLSubscription<OnUpdateFavouriteSubscription>
      >({
        query: onFavouriteUpdateByUserId,
        variables: { userId: client.id },
      }).subscribe({
        next: ({ value }: { value: any }) => {
          const item = value.data.onFavouriteUpdateByUserId;
          dispatch(updateChangedFavourite(item.mlsNumber));
        },
      });
    };
    if (client) {
      subscribe();
    }

    return () => {
      subscription && subscription.unsubscribe();
    };
  }, [client, dispatch]);

  const registerDeviceForNotifications = async () => {
    if (!getPlatforms().includes("capacitor")) return;
    try {
      PushNotifications.removeAllListeners();
      const permissionStatus = await PushNotifications.requestPermissions();
      if (permissionStatus.receive === "granted") {
        await PushNotifications.register();

        PushNotifications.addListener("registration", async (token) => {
          console.log("Device registered with token:", token.value);
          localStorage.setItem("deviceToken", token.value);
        });
        PushNotifications.addListener("registrationError", (error) => {
          console.error("Registration error:", error);
        });
      }
    } catch (error) {
      console.error("Error registering device:", error);
    }
  };

  useEffect(() => {
    registerDeviceForNotifications();
  }, []);

  useEffect(() => {
    if (!getPlatforms().includes("capacitor")) return;

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async (notification) => {
        const data = notification.notification.data;
        if (data.id) {
          history.push(`/notifications/${data.category}/${data.id}`);
        } else if (data.category) {
          history.push(`/notifications/${data.category}`);
        }
      }
    );
  }, [history]);

  useEffect(() => {
    const deviceToken = localStorage.getItem("deviceToken");
    if (user && deviceToken) {
      UserService.updateDeviceToken(deviceToken);
    }
  }, [user]);

  return (
    <IonApp className={isApp ? styles.app : styles.web}>
      <IonReactRouter>
        <Helmet>
          <title>AECORN - Properties in GTA</title>
          <meta
            name="description"
            content="Find real estate listings for sale in GTA. Search MLS real estate listings for homes, condos and properties."
          />
          <meta
            name="keywords"
            content="mls listings, house for sale, mls canada, homes for rent, mls map, mls real estate, homes for sale, condos for sale, homes for sale near me, townhouse for sale, property for sale, apartments for sale, property search, mls online, house prices, duplex for sale, real estate listings, real estate companies, cheap houses for sale, mls house listings, best real estate websites canada, single family homes for sale, mls residential map, detached house for sale, home listings, sold home prices, property listings, sold mls listings, house sale prices"
          />
        </Helmet>
        <IonAlert
          isOpen={update.isAvailable}
          header={update.value.title}
          message={update.value.msg}
          backdropDismiss={false}
          onDidDismiss={() =>
            setUpdate({
              isAvailable: false,
              type: "",
              value: {
                btn: "",
                title: "",
                forced: false,
                msg: "",
                onClick: handleUpdate,
              },
            })
          }
          buttons={
            update.value.forced
              ? [{ text: update.value.btn, handler: update.value.onClick }]
              : [
                  { text: update.value.btn, handler: update.value.onClick },
                  { text: "Close", role: "cancel" },
                ]
          }
        />
        <AppUrlListener />
        <IonModal
          id="confirmRegistration"
          isOpen={showConfirmRegistration}
          onDidDismiss={() => setShowConfirmRegistration(false)}>
          <ConfirmRegistration
            client={client}
            confirmRegistration={showConfirmRegistration}
            setConfirmRegistration={setShowConfirmRegistration}
            close={() => setShowConfirmRegistration(false)}
          />
        </IonModal>
        <IonToast isOpen={true} cssClass="connection" />
        <IonToast
          position={toast.position ? toast.position : "top"}
          cssClass={styles[toast.type]}
          isOpen={toast.open}
          onDidDismiss={closeToast}
          header={toast.header}
          message={toast.message}
          buttons={[
            {
              side: "end",
              icon: toast.act && "chevron-forward",
              handler: toast.act && toast.act,
            },
            {
              side: "end",
              icon: "close",
              handler: closeToast,
            },
          ]}
          duration={4000}
        />

        {isApp ? (
          <>
            <IonLoading isOpen={federatedLoading} message={"Please wait..."} />
            {showDownloadApp.show && (
              <>
                <div className={styles.bottomCard}>
                  <div className={styles.header}>See AECORN in...</div>
                  <div className={styles.content}>
                    <div className={styles.item}>
                      <div className={styles.logo}>
                        <span className={styles.appLogo}>
                          <LogoIconOnly />
                        </span>
                        <span className={styles.title}>AECORN</span>
                      </div>

                      <span
                        className={`${styles.label} ${styles.appLabel}`}
                        onClick={() => handleOpenApp()}>
                        Download AECORN
                      </span>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.logo}>
                        <span className={styles.browserLogo}>
                          <IonIcon
                            icon={browsersOutline}
                            className={styles.icon}
                          />
                        </span>
                        <span className={styles.title}>Browser</span>
                      </div>
                      <span
                        className={`${styles.label} ${styles.browserLabel}`}
                        onClick={() =>
                          setShowDownloadApp({
                            ...showDownloadApp,
                            show: false,
                          })
                        }>
                        Continue
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.backdrop} />
              </>
            )}
            <IonRouterOutlet id="main" animated>
              <Route path="/join" component={Join} />
              <Route path="/signin" component={AppSignIn} />
              <Route path="/signup" component={AppSignUp} />
              <Route path="/terms" component={AppTermsOfService} />
              <Route path="/privacy" component={AppPrivacyPolicy} />
              <Route path="/schools/:id" component={AppSchoolDetails} />
              <Route
                path="/notifications/listings/:id"
                component={AppDetailsPage}
              />
              <Route
                path="/notifications/searches/:searchId"
                component={AppSearchesDetailPage}
              />
              <Route
                path="/notifications/favourites"
                component={AppFavouritesPage}
              />
              <Route
                path="/notifications/tours/:tourId"
                component={AppTourItemPage}
              />
              <Route path="/listings/:id" component={AppDetailsPage} />
              <Route path="/tabs" component={AppTabs} />
              <Route
                path="/favourites"
                render={() => <Redirect to="/tabs/favourites" />}
                exact
              />
              <Route
                path="/tours"
                render={() => <Redirect to="/tabs/tours" />}
                exact
              />
              <Route
                path="/more"
                render={() => <Redirect to="/tabs/more" />}
                exact
              />
              <Route
                path="/"
                render={() => <Redirect to="/tabs/home" />}
                exact
              />
              <Redirect to="/" />
            </IonRouterOutlet>
          </>
        ) : (
          <LastLocationProvider>
            <IonRouterOutlet animated={false}>
              <Route exact path="/" render={() => <Home />} />
              <Route
                exact
                path="/listings"
                render={() => (
                  <Search />
                )}
              />

              <Route
                exact
                path="/user"
                render={() => (
                  <Layout>
                    <UserAccount />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/favourites"
                render={() => (
                  <Layout>
                    <FavouriteListings />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/searches"
                render={() => (
                  <Layout>
                    <SavedSearches />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/tours"
                render={() => (
                  <Layout>
                    <ToursRoute
                      user={user}
                      agentComponent={<Tours />}
                      clientComponent={<ClientTours />}
                    />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/tours/:tourId"
                render={(props) => (
                  <Layout>
                    <ToursRoute
                      user={user}
                      agentComponent={<Tour {...props} />}
                      clientComponent={<ClientTourDetails {...props} />}
                    />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/tours/details/:tourItemId"
                render={(props) => (
                  <Layout>
                    <TourDetails {...props} />
                  </Layout>
                )}
              />
              <Route
                path="/schools/:id"
                render={(props) => (
                  <Layout>
                    <SchoolDetails {...props} />
                  </Layout>
                )}
                exact
              />

              <Route
                exact
                path="/listings/:mlsNumber"
                render={(props) => (
                  <Layout>
                    <DetailPage {...props} />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/projects/:id"
                render={(props) => (
                  <Layout>
                    <PreConstructionDetailsPage {...props} />
                  </Layout>
                )}
              />

              <Route exact path="/signout" render={() => <Logout />} />

              <Route
                exact
                path="/terms"
                render={(props) => (
                  <Layout>
                    <TermsOfService />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/privacy"
                render={(props) => (
                  <Layout>
                    <PrivacyPolicy />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/contact-us"
                render={(props) => (
                  <Layout>
                    <ContactUs />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/feedback"
                render={(props) => (
                  <Layout>
                    <Feedback />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/school-terms"
                render={(props) => (
                  <Layout>
                    <SchoolDisclaimer />
                  </Layout>
                )}
              />

              <Route
                exact
                path={["/about-us", "/hiring-agent"]}
                render={(props) => (
                  <Layout>
                    <AboutUs />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/market-data"
                render={(props) => (
                  <Layout>
                    <MarketData />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/admin"
                render={(props) => (
                  <Layout>
                    <AdminRoute
                      user={user}
                      isWeb
                      component={<Admin {...props} />}
                    />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/admin/projects"
                render={(props) => (
                  <Layout>
                    <AdminRoute
                      user={user}
                      isWeb
                      component={<Projects {...props} />}
                    />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/admin/projects/:id"
                render={(props) => (
                  <Layout>
                    <AdminRoute
                      user={user}
                      isWeb
                      component={<Project {...props} />}
                    />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/admin/projects/new"
                render={() => (
                  <Layout>
                    <AdminRoute user={user} isWeb component={<NewProject />} />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/admin/builders"
                render={(props) => (
                  <Layout>
                    <AdminRoute
                      user={user}
                      isWeb
                      component={<Builders {...props} />}
                    />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/admin/builders/:id"
                render={(props) => (
                  <Layout>
                    <AdminRoute
                      user={user}
                      isWeb
                      component={<Builder {...props} />}
                    />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/admin/builders/new"
                render={(props) => (
                  <Layout>
                    <AdminRoute user={user} isWeb component={<NewBuilder />} />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/admin/architects"
                render={(props) => (
                  <Layout>
                    <AdminRoute
                      user={user}
                      isWeb
                      component={<Architects {...props} />}
                    />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/admin/architects/:id"
                render={(props) => (
                  <Layout>
                    <AdminRoute
                      user={user}
                      isWeb
                      component={<Architect {...props} />}
                    />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/admin/architects/new"
                render={(props) => (
                  <Layout>
                    <AdminRoute
                      user={user}
                      isWeb
                      component={<NewArchitect />}
                    />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/admin/designers"
                render={(props) => (
                  <Layout>
                    <AdminRoute
                      user={user}
                      isWeb
                      component={<Designers {...props} />}
                    />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/admin/designers/:id"
                render={(props) => (
                  <Layout>
                    <AdminRoute
                      user={user}
                      isWeb
                      component={<Designer {...props} />}
                    />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/admin/designers/new"
                render={(props) => (
                  <Layout>
                    <AdminRoute user={user} isWeb component={<NewDesigner />} />
                  </Layout>
                )}
              />

              <Route
                exact
                path="/admin/units"
                render={(props) => (
                  <Layout>
                    <AdminRoute
                      user={user}
                      isWeb
                      component={<Units {...props} />}
                    />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/admin/units/:id"
                render={(props) => (
                  <Layout>
                    <AdminRoute
                      user={user}
                      isWeb
                      component={<Unit {...props} />}
                    />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/admin/units/new"
                render={(props) => (
                  <Layout>
                    <AdminRoute user={user} isWeb component={<NewUnits />} />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/admin/amenities"
                render={() => (
                  <Layout>
                    <AdminRoute user={user} isWeb component={<Amenities />} />
                  </Layout>
                )}
              />
              <Redirect to="/" />
            </IonRouterOutlet>
          </LastLocationProvider>
        )}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
