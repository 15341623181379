import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Topbar from "../Tours/component/Topbar/topbar.component";
import styles from "./client-tours.module.scss";
import TourScheduleContainer from "./component/TourScheduleContainer/tour-schedule-container.component";
import GeneralNotes from "./component/GeneralNotes/general-notes.component";
import withAuthentication from "../../HOC/withAuthentication/with-authentication";
import withClient from "../../HOC/withClient/with-client";
import NoTours from "../../assets/img/noTours.png";
// import { ReactComponent as Message } from "../../assets/svg/no-tours.svg";
import Card from "../../components/Card/card.component";
import Button from "../../components/Form/Button/button.component";
import { openModal } from "../../redux/ui/ui.actions";
import { isBeforeOrAfter } from "../../utils/functions";
import Footer from "../../components/Footer/footer.component";
import { selectUINotifications } from "../../redux/ui/ui.selectors";
import LoadingFullPage from "../../components/Loading/loading-full-page.component";
import { TourService } from "../../services/tourService";

const ClientTours = ({ currentClient, pageHook }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectUINotifications);
  const [showGuide, setShowGuide] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tours, setTours] = useState({ upcoming: [], past: [], archived: [] });
  const [type, setType] = useState("upcoming");

  const fetchTours = async () => {
    try {
      setLoading(true);
      const tours = await TourService.getClientTours();

      setTours({
        upcoming: tours.filter(
          (t) =>
            !["archived"].includes(t.status) &&
            (!t.date || isBeforeOrAfter(t.date) >= 0)
        ),

        past: tours.filter((t) => isBeforeOrAfter(t.date) < 0),
        archived: tours.filter((t) => ["archived"].includes(t.status)),
      });
    } catch (error) {
      console.error("Error fetching tours: ", error);
    } finally {
      setLoading(false);
    }
  };

  pageHook(() => {
    fetchTours();
  });

  useEffect(() => {
    fetchTours();
  }, []);

  useEffect(() => {
    if (
      tours.archived.length === 0 &&
      tours.past.length === 0 &&
      tours.upcoming.length === 0
    ) {
      setShowGuide(true);
    } else {
      setShowGuide(false);
    }
  }, [tours]);

  const handleBookTour = () => {
    dispatch(
      openModal({
        isOpen: true,
        current: "requestForm",
        data: { tourBooking: true },
      })
    );
  };

  const getToursByType = () => {
    const items =
      tours[type].length > 0 ? (
        tours[type].map((item, index) => (
          <TourScheduleContainer
            key={index}
            tour={item}
            notSeen={notifications?.tours.find(
              (record) => record.id === item.id
            )}
            clientTour={notifications?.tours.find(
              (record) => record.id === item.id
            )}
          />
        ))
      ) : (
        <div className={styles.note}>
          {type === "upcoming"
            ? "You have no upcoming tours right now. You can ask your agent to get a tour started, and it will appear here."
            : type === "past"
            ? "You have no past tours right now. Only past tours will appear here."
            : "Archived tours are view-only. They can no longer be edited by client or agent. You have no archived tours right now. Only tours that your lead agent has archived will appear here."}
        </div>
      );

    return type === "archived" && items.length > 0 ? (
      <>
        <span className={styles.note}>
          Archived tours are view-only. They can no longer be edited by client
          or agent.
        </span>
        {items}
      </>
    ) : (
      items
    );
  };

  return (
    <div className={styles.clientTours}>
      {loading ? (
        <LoadingFullPage />
      ) : showGuide ? (
        <div className={styles.cardContainer}>
          <Card>
            <div className={styles.message}>
              <img src={NoTours} className={styles.img} />
              <div className={styles.title}>Oh hello!</div>
              <div className={styles.text}>
                As a registered user, you can work together seamlessly with a
                AECORN agent to build custom showing tours. You and each tour
                guest can access AECORN's collaborative showing tools to take
                photos, recordings and notes at each location, as well as rate
                and compare for review later.
              </div>
              {currentClient && !currentClient.tourRequested && (
                <Button
                  type="green"
                  border
                  title="Let's get a tour started!"
                  style={{ width: "25rem", height: "5rem", fontWeight: "500" }}
                  onClick={handleBookTour}
                />
              )}
            </div>
          </Card>
        </div>
      ) : (
        <>
          <Topbar isAgent={false} type={type} setType={setType} />
          <div>
            {getToursByType()}
            {currentClient && (
              <GeneralNotes title="My general notes" client={currentClient} />
            )}
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default withAuthentication(withClient(ClientTours));
