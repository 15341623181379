/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onFavouriteUpdateByUserId = /* GraphQL */ `subscription OnFavouriteUpdateByUserId($userId: String!) {
  onFavouriteUpdateByUserId(userId: $userId) {
    userId
    repliersID
    mlsNumber
    listing
    listingUpdate
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnFavouriteUpdateByUserIdSubscriptionVariables,
  APITypes.OnFavouriteUpdateByUserIdSubscription
>;
export const onPreFavouriteUpdateByUserId = /* GraphQL */ `subscription OnPreFavouriteUpdateByUserId($userId: String!) {
  onPreFavouriteUpdateByUserId(userId: $userId) {
    userId
    projectId
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnPreFavouriteUpdateByUserIdSubscriptionVariables,
  APITypes.OnPreFavouriteUpdateByUserIdSubscription
>;
export const onCreateTourByAgents = /* GraphQL */ `subscription OnCreateTourByAgents(
  $agentId: ID
  $creator: ID
  $showingAgentId: ID
) {
  onCreateTourByAgents(
    agentId: $agentId
    creator: $creator
    showingAgentId: $showingAgentId
  ) {
    title
    creator
    users {
      nextToken
      __typename
    }
    clients
    primaryAgent
    showingAgents
    status
    privateNote
    generalNote
    tourItems {
      nextToken
      __typename
    }
    meetupLocation
    meetupDescription
    meetupTime
    startTime
    endTime
    date
    shared
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTourByAgentsSubscriptionVariables,
  APITypes.OnCreateTourByAgentsSubscription
>;
export const onUpdateTourByTourId = /* GraphQL */ `subscription OnUpdateTourByTourId($id: ID!) {
  onUpdateTourByTourId(id: $id) {
    title
    creator
    users {
      nextToken
      __typename
    }
    clients
    primaryAgent
    showingAgents
    status
    privateNote
    generalNote
    tourItems {
      nextToken
      __typename
    }
    meetupLocation
    meetupDescription
    meetupTime
    startTime
    endTime
    date
    shared
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTourByTourIdSubscriptionVariables,
  APITypes.OnUpdateTourByTourIdSubscription
>;
export const onUpdateTourItemById = /* GraphQL */ `subscription OnUpdateTourItemById($id: ID!) {
  onUpdateTourItemById(id: $id) {
    creator
    tourId
    tour {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    clients
    primaryAgent
    showingAgents
    mlsNumber
    status
    startTime
    endTime
    order
    showingNote
    entryInfo
    reviews {
      nextToken
      __typename
    }
    notes {
      nextToken
      __typename
    }
    photos {
      nextToken
      __typename
    }
    recordings {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    tourTourItemsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTourItemByIdSubscriptionVariables,
  APITypes.OnUpdateTourItemByIdSubscription
>;
export const onUpdateTourItemByTourId = /* GraphQL */ `subscription OnUpdateTourItemByTourId($tourId: ID!) {
  onUpdateTourItemByTourId(tourId: $tourId) {
    creator
    tourId
    tour {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    clients
    primaryAgent
    showingAgents
    mlsNumber
    status
    startTime
    endTime
    order
    showingNote
    entryInfo
    reviews {
      nextToken
      __typename
    }
    notes {
      nextToken
      __typename
    }
    photos {
      nextToken
      __typename
    }
    recordings {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    tourTourItemsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTourItemByTourIdSubscriptionVariables,
  APITypes.OnUpdateTourItemByTourIdSubscription
>;
export const onCreateTourItemByTourId = /* GraphQL */ `subscription OnCreateTourItemByTourId($tourId: ID!) {
  onCreateTourItemByTourId(tourId: $tourId) {
    creator
    tourId
    tour {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    clients
    primaryAgent
    showingAgents
    mlsNumber
    status
    startTime
    endTime
    order
    showingNote
    entryInfo
    reviews {
      nextToken
      __typename
    }
    notes {
      nextToken
      __typename
    }
    photos {
      nextToken
      __typename
    }
    recordings {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    tourTourItemsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTourItemByTourIdSubscriptionVariables,
  APITypes.OnCreateTourItemByTourIdSubscription
>;
export const onCreatePhotoByTourItemId = /* GraphQL */ `subscription OnCreatePhotoByTourItemId($tourItemId: ID!) {
  onCreatePhotoByTourItemId(tourItemId: $tourItemId) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemPhotosId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePhotoByTourItemIdSubscriptionVariables,
  APITypes.OnCreatePhotoByTourItemIdSubscription
>;
export const onCreateRecordingByTourItemId = /* GraphQL */ `subscription OnCreateRecordingByTourItemId($tourItemId: ID!) {
  onCreateRecordingByTourItemId(tourItemId: $tourItemId) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemRecordingsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRecordingByTourItemIdSubscriptionVariables,
  APITypes.OnCreateRecordingByTourItemIdSubscription
>;
export const onCreateNoteByTourItemId = /* GraphQL */ `subscription OnCreateNoteByTourItemId($tourItemId: ID!) {
  onCreateNoteByTourItemId(tourItemId: $tourItemId) {
    userId
    content
    tourItemId
    id
    createdAt
    updatedAt
    tourItemNotesId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNoteByTourItemIdSubscriptionVariables,
  APITypes.OnCreateNoteByTourItemIdSubscription
>;
export const onDeletePhotoByTourItemId = /* GraphQL */ `subscription OnDeletePhotoByTourItemId($tourItemId: ID!) {
  onDeletePhotoByTourItemId(tourItemId: $tourItemId) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemPhotosId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePhotoByTourItemIdSubscriptionVariables,
  APITypes.OnDeletePhotoByTourItemIdSubscription
>;
export const onDeleteRecordingByTourItemId = /* GraphQL */ `subscription OnDeleteRecordingByTourItemId($tourItemId: ID!) {
  onDeleteRecordingByTourItemId(tourItemId: $tourItemId) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemRecordingsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRecordingByTourItemIdSubscriptionVariables,
  APITypes.OnDeleteRecordingByTourItemIdSubscription
>;
export const onUpdateNoteByTourItemId = /* GraphQL */ `subscription OnUpdateNoteByTourItemId($tourItemId: ID!) {
  onUpdateNoteByTourItemId(tourItemId: $tourItemId) {
    userId
    content
    tourItemId
    id
    createdAt
    updatedAt
    tourItemNotesId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNoteByTourItemIdSubscriptionVariables,
  APITypes.OnUpdateNoteByTourItemIdSubscription
>;
export const onCreateReviewByTourItemId = /* GraphQL */ `subscription OnCreateReviewByTourItemId($tourItemId: ID!) {
  onCreateReviewByTourItemId(tourItemId: $tourItemId) {
    userId
    tourItemId
    rate
    id
    createdAt
    updatedAt
    tourItemReviewsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReviewByTourItemIdSubscriptionVariables,
  APITypes.OnCreateReviewByTourItemIdSubscription
>;
export const onUpdateReviewById = /* GraphQL */ `subscription OnUpdateReviewById($id: ID!) {
  onUpdateReviewById(id: $id) {
    userId
    tourItemId
    rate
    id
    createdAt
    updatedAt
    tourItemReviewsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReviewByIdSubscriptionVariables,
  APITypes.OnUpdateReviewByIdSubscription
>;
export const onCreateFavourite = /* GraphQL */ `subscription OnCreateFavourite(
  $filter: ModelSubscriptionFavouriteFilterInput
  $userId: String
) {
  onCreateFavourite(filter: $filter, userId: $userId) {
    userId
    repliersID
    mlsNumber
    listing
    listingUpdate
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFavouriteSubscriptionVariables,
  APITypes.OnCreateFavouriteSubscription
>;
export const onUpdateFavourite = /* GraphQL */ `subscription OnUpdateFavourite(
  $filter: ModelSubscriptionFavouriteFilterInput
  $userId: String
) {
  onUpdateFavourite(filter: $filter, userId: $userId) {
    userId
    repliersID
    mlsNumber
    listing
    listingUpdate
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFavouriteSubscriptionVariables,
  APITypes.OnUpdateFavouriteSubscription
>;
export const onDeleteFavourite = /* GraphQL */ `subscription OnDeleteFavourite(
  $filter: ModelSubscriptionFavouriteFilterInput
  $userId: String
) {
  onDeleteFavourite(filter: $filter, userId: $userId) {
    userId
    repliersID
    mlsNumber
    listing
    listingUpdate
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFavouriteSubscriptionVariables,
  APITypes.OnDeleteFavouriteSubscription
>;
export const onCreatePreFavourite = /* GraphQL */ `subscription OnCreatePreFavourite(
  $filter: ModelSubscriptionPreFavouriteFilterInput
  $userId: String
) {
  onCreatePreFavourite(filter: $filter, userId: $userId) {
    userId
    projectId
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePreFavouriteSubscriptionVariables,
  APITypes.OnCreatePreFavouriteSubscription
>;
export const onUpdatePreFavourite = /* GraphQL */ `subscription OnUpdatePreFavourite(
  $filter: ModelSubscriptionPreFavouriteFilterInput
  $userId: String
) {
  onUpdatePreFavourite(filter: $filter, userId: $userId) {
    userId
    projectId
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePreFavouriteSubscriptionVariables,
  APITypes.OnUpdatePreFavouriteSubscription
>;
export const onDeletePreFavourite = /* GraphQL */ `subscription OnDeletePreFavourite(
  $filter: ModelSubscriptionPreFavouriteFilterInput
  $userId: String
) {
  onDeletePreFavourite(filter: $filter, userId: $userId) {
    userId
    projectId
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePreFavouriteSubscriptionVariables,
  APITypes.OnDeletePreFavouriteSubscription
>;
export const onCreateSearch = /* GraphQL */ `subscription OnCreateSearch(
  $filter: ModelSubscriptionSearchFilterInput
  $userId: String
) {
  onCreateSearch(filter: $filter, userId: $userId) {
    name
    userId
    repliersID
    value
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSearchSubscriptionVariables,
  APITypes.OnCreateSearchSubscription
>;
export const onUpdateSearch = /* GraphQL */ `subscription OnUpdateSearch(
  $filter: ModelSubscriptionSearchFilterInput
  $userId: String
) {
  onUpdateSearch(filter: $filter, userId: $userId) {
    name
    userId
    repliersID
    value
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSearchSubscriptionVariables,
  APITypes.OnUpdateSearchSubscription
>;
export const onDeleteSearch = /* GraphQL */ `subscription OnDeleteSearch(
  $filter: ModelSubscriptionSearchFilterInput
  $userId: String
) {
  onDeleteSearch(filter: $filter, userId: $userId) {
    name
    userId
    repliersID
    value
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSearchSubscriptionVariables,
  APITypes.OnDeleteSearchSubscription
>;
export const onCreateReview = /* GraphQL */ `subscription OnCreateReview(
  $filter: ModelSubscriptionReviewFilterInput
  $userId: String
) {
  onCreateReview(filter: $filter, userId: $userId) {
    userId
    tourItemId
    rate
    id
    createdAt
    updatedAt
    tourItemReviewsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReviewSubscriptionVariables,
  APITypes.OnCreateReviewSubscription
>;
export const onUpdateReview = /* GraphQL */ `subscription OnUpdateReview(
  $filter: ModelSubscriptionReviewFilterInput
  $userId: String
) {
  onUpdateReview(filter: $filter, userId: $userId) {
    userId
    tourItemId
    rate
    id
    createdAt
    updatedAt
    tourItemReviewsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReviewSubscriptionVariables,
  APITypes.OnUpdateReviewSubscription
>;
export const onDeleteReview = /* GraphQL */ `subscription OnDeleteReview(
  $filter: ModelSubscriptionReviewFilterInput
  $userId: String
) {
  onDeleteReview(filter: $filter, userId: $userId) {
    userId
    tourItemId
    rate
    id
    createdAt
    updatedAt
    tourItemReviewsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReviewSubscriptionVariables,
  APITypes.OnDeleteReviewSubscription
>;
export const onCreateNote = /* GraphQL */ `subscription OnCreateNote(
  $filter: ModelSubscriptionNoteFilterInput
  $userId: String
) {
  onCreateNote(filter: $filter, userId: $userId) {
    userId
    content
    tourItemId
    id
    createdAt
    updatedAt
    tourItemNotesId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNoteSubscriptionVariables,
  APITypes.OnCreateNoteSubscription
>;
export const onUpdateNote = /* GraphQL */ `subscription OnUpdateNote(
  $filter: ModelSubscriptionNoteFilterInput
  $userId: String
) {
  onUpdateNote(filter: $filter, userId: $userId) {
    userId
    content
    tourItemId
    id
    createdAt
    updatedAt
    tourItemNotesId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNoteSubscriptionVariables,
  APITypes.OnUpdateNoteSubscription
>;
export const onDeleteNote = /* GraphQL */ `subscription OnDeleteNote(
  $filter: ModelSubscriptionNoteFilterInput
  $userId: String
) {
  onDeleteNote(filter: $filter, userId: $userId) {
    userId
    content
    tourItemId
    id
    createdAt
    updatedAt
    tourItemNotesId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNoteSubscriptionVariables,
  APITypes.OnDeleteNoteSubscription
>;
export const onCreatePhoto = /* GraphQL */ `subscription OnCreatePhoto(
  $filter: ModelSubscriptionPhotoFilterInput
  $userId: String
) {
  onCreatePhoto(filter: $filter, userId: $userId) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemPhotosId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePhotoSubscriptionVariables,
  APITypes.OnCreatePhotoSubscription
>;
export const onUpdatePhoto = /* GraphQL */ `subscription OnUpdatePhoto(
  $filter: ModelSubscriptionPhotoFilterInput
  $userId: String
) {
  onUpdatePhoto(filter: $filter, userId: $userId) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemPhotosId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePhotoSubscriptionVariables,
  APITypes.OnUpdatePhotoSubscription
>;
export const onDeletePhoto = /* GraphQL */ `subscription OnDeletePhoto(
  $filter: ModelSubscriptionPhotoFilterInput
  $userId: String
) {
  onDeletePhoto(filter: $filter, userId: $userId) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemPhotosId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePhotoSubscriptionVariables,
  APITypes.OnDeletePhotoSubscription
>;
export const onCreateRecording = /* GraphQL */ `subscription OnCreateRecording(
  $filter: ModelSubscriptionRecordingFilterInput
  $userId: String
) {
  onCreateRecording(filter: $filter, userId: $userId) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemRecordingsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRecordingSubscriptionVariables,
  APITypes.OnCreateRecordingSubscription
>;
export const onUpdateRecording = /* GraphQL */ `subscription OnUpdateRecording(
  $filter: ModelSubscriptionRecordingFilterInput
  $userId: String
) {
  onUpdateRecording(filter: $filter, userId: $userId) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemRecordingsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRecordingSubscriptionVariables,
  APITypes.OnUpdateRecordingSubscription
>;
export const onDeleteRecording = /* GraphQL */ `subscription OnDeleteRecording(
  $filter: ModelSubscriptionRecordingFilterInput
  $userId: String
) {
  onDeleteRecording(filter: $filter, userId: $userId) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemRecordingsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRecordingSubscriptionVariables,
  APITypes.OnDeleteRecordingSubscription
>;
export const onCreateProjectAmenities = /* GraphQL */ `subscription OnCreateProjectAmenities(
  $filter: ModelSubscriptionProjectAmenitiesFilterInput
) {
  onCreateProjectAmenities(filter: $filter) {
    id
    projectID
    amenityID
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    amenity {
      name
      description
      id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProjectAmenitiesSubscriptionVariables,
  APITypes.OnCreateProjectAmenitiesSubscription
>;
export const onUpdateProjectAmenities = /* GraphQL */ `subscription OnUpdateProjectAmenities(
  $filter: ModelSubscriptionProjectAmenitiesFilterInput
) {
  onUpdateProjectAmenities(filter: $filter) {
    id
    projectID
    amenityID
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    amenity {
      name
      description
      id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProjectAmenitiesSubscriptionVariables,
  APITypes.OnUpdateProjectAmenitiesSubscription
>;
export const onDeleteProjectAmenities = /* GraphQL */ `subscription OnDeleteProjectAmenities(
  $filter: ModelSubscriptionProjectAmenitiesFilterInput
) {
  onDeleteProjectAmenities(filter: $filter) {
    id
    projectID
    amenityID
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    amenity {
      name
      description
      id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProjectAmenitiesSubscriptionVariables,
  APITypes.OnDeleteProjectAmenitiesSubscription
>;
