import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styles from "./pre-con-information.module.scss";
import {
  openModal,
  closeModal,
  openAuthModal,
} from "../../../../../../redux/ui/ui.actions";
import { selectCurrentClient } from "../../../../../../redux/client/client.selectors";
import { selectCurrencyRates } from "../../../../../../redux/currency/currency.selectors";
import _ from "lodash";
import DetailPageDropdown from "../../../../../Detail/Sections/LeftSection/components/DetailPageDropdown/detail-page-dropdown.component";
import PreOverview from "./components/PreOverview/pre-overview.component";
import PreDepositStructure from "./components/PreDepositStructure/pre-deposit-structure.component";
import PreDetails from "./components/PreDetails/pre-details.component";
import PreAmenities from "./components/PreAmenities/pre-amenities.component";
import PreFeaturesAndFinishes from "./components/PreFeaturesAndFinishes/pre-features-and-finishes.component";
import PreFloorPlansAndPricing from "./components/PreFloorPlansAndPricing/pre-floor-plans-and-pricing.component";
import PrePricingAndFees from "./components/PrePricingAndFees/pre-pricing-and-fees.component";
import Calculators from "../../../../../Detail/Sections/LeftSection/components/DetailPageInformation/components/Calculators/calculators.component";

const PreConInformation = ({ project, user }) => {
  const client = useSelector(selectCurrentClient);
  const rates = useSelector(selectCurrencyRates);
  const dispatch = useDispatch();
  const location = useLocation();
  const [tabs, setTabs] = useState({
    overview: true,
    depositeStructure: false,
    details: false,
    amenities: false,
    featuresAndFinishes: false,
    floorplansAndPricing: false,
    pricingAndFees: false,
    calculators: false,
  });

  const [feet, setFeet] = useState(
    client && client.measurement === "metric" ? false : true
  );

  let currency;
  if (client && rates) {
    if (client.currency !== "cad") currency = client.currency.toUpperCase();
  }

  // const signInHandler = () => {
  //   dispatch(openAuthModal({ current: "login" }));
  // };
  // const signUpHandler = () => {
  //   dispatch(openAuthModal({ current: "register" }));
  // };

  useEffect(() => {
    user && dispatch(closeModal());
  }, [user, dispatch]);

  const toggleTabs = (tabName) => {
    setTabs({ ...tabs, [tabName]: !tabs[tabName] });
  };

  const {
    overview,
    depositeStructure,
    details,
    amenities,
    featuresAndFinishes,
    floorplansAndPricing,
    pricingAndFees,
    calculators,
  } = tabs;

  return (
    <div className={styles.container}>
      <div className={styles.infoTitle}>Property information</div>
      <DetailPageDropdown
        title="Overview"
        open={overview}
        onClick={() => toggleTabs("overview")}
      />
      {overview && <PreOverview project={project} />}

      <DetailPageDropdown
        title="Deposit structure"
        open={depositeStructure}
        onClick={() => toggleTabs("depositeStructure")}
      />
      {depositeStructure && <PreDepositStructure project={project} />}

      <DetailPageDropdown
        title="Details"
        open={details}
        onClick={() => toggleTabs("details")}
      />
      {details && <PreDetails project={project} />}

      <DetailPageDropdown
        title="Amenities"
        open={amenities}
        onClick={() => toggleTabs("amenities")}
      />
      {amenities && <PreAmenities project={project} />}

      {/* <DetailPageDropdown
        title="Features and finishes"
        open={featuresAndFinishes}
        onClick={() => toggleTabs("featuresAndFinishes")}
      />
      {featuresAndFinishes && <PreFeaturesAndFinishes />} */}

      <DetailPageDropdown
        title="Floor plans and pricing"
        open={floorplansAndPricing}
        onClick={() => toggleTabs("floorplansAndPricing")}
      />
      {floorplansAndPricing && <PreFloorPlansAndPricing project={project} />}

      <DetailPageDropdown
        title="Pricing and fees"
        open={pricingAndFees}
        onClick={() => toggleTabs("pricingAndFees")}
      />
      {pricingAndFees && <PrePricingAndFees project={project} />}

      <DetailPageDropdown
        title="Calculators"
        open={calculators}
        onClick={() => toggleTabs("calculators")}
      />
      {calculators && <Calculators listing={project} isPreCon={true} />}
    </div>
  );
};

export default PreConInformation;
