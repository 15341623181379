import { takeLatest, call, put, all } from "redux-saga/effects";
import { SchoolsActionTypes } from "./schools.types";
import {
  fetchSchoolsSuccess,
  fetchSchoolsFailure,
  toggleFilterListings,
} from "./schools.actions";
import { SchoolsService } from "services/schoolsService";

export function* fetchSchoolsAsync({ payload }) {
  try {
    const schools = yield call(SchoolsService.getSchools, payload);
    yield put(fetchSchoolsSuccess(schools));
  } catch (error) {
    yield put(fetchSchoolsFailure(error.message));
  }
}

export function* resetSelectedSchools() {
  yield put(toggleFilterListings());
}

export function* fetchSchoolsStart() {
  yield takeLatest(SchoolsActionTypes.FETCH_SCHOOLS_START, fetchSchoolsAsync);
}

export function* onUpdateSchoolsFilters() {
  yield takeLatest(
    SchoolsActionTypes.UPDATE_SCHOOLS_FILTERS,
    resetSelectedSchools
  );
}

export function* schoolsSagas() {
  yield all([call(fetchSchoolsStart), call(onUpdateSchoolsFilters)]);
}
