export const MapActionTypes = {
  UPDATE_LOADING: "UPDATE_LOADING",
  UPDATE_LISTINGS: "UPDATE_LISTINGS",
  CLEAR_LISTINGS: "CLEAR_LISTINGS",
  UPDATE_SELECTED_CLUSTER: "UPDATE_SELECTED_CLUSTER",
  UPDATE_SELECTED_LISTINGS: "UPDATE_SELECTED_LISTINGS",
  UPDATE_ACTIVE_LISTING: "UPDATE_ACTIVE_LISTING",
  UPDATE_DETAILS_LISTING: "UPDATE_DETAILS_LISTING",
  UPDATE_SELECTED_LOCATION: "UPDATE_SELECTED_LOCATION",
  UPDATE_MAP_REF: "UPDATE_MAP_REF",
  UPDATE_LOCATIONS: "UPDATE_LOCATIONS",
  UPDATE_BOUNDARIES: "UPDATE_BOUNDARIES",
  TOGGLE_FAVOURITES: "TOGGLE_FAVOURITES",
};
