import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectIsAgent,
} from "../../../redux/user/user.selectors";
import AgentTourComponent from "../AgentTour/agent-tour.component";
import ClientTourComponent from "../ClientTour/client-tour.component";
import { Redirect } from "react-router";
import { IonContent, IonPage } from "@ionic/react";
import AppHeader from "../../components/Header/header.component";

const TourItem = ({ tourId }) => {
  const currentUser = useSelector(selectCurrentUser);
  const isAgent = useSelector(selectIsAgent);


  if (!currentUser) {
    return <Redirect to="/tabs/tours" />;
  }

  return (
    <IonPage>
      <AppHeader
        title={isAgent ? "Tour Summary" : "Tour Details"}
        hasRightButton={false}
        backHref={"/tours"}
      />
      <IonContent>
        {isAgent ? (
          <AgentTourComponent tourId={tourId} />
        ) : (
          <ClientTourComponent tourId={tourId} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default TourItem;
