import { IonIcon, IonRouterLink, IonSpinner } from "@ionic/react";
import { ellipsisHorizontalCircleOutline } from "ionicons/icons";
import { useState, useEffect } from "react";
import {
  calcCrow,
  isInsidePolygon,
} from "../../../../../../../../utils/functions";
import styles from "./schools.module.scss";

const Schools = ({ schools, listing, loading }) => {

  const [sortedSchools, setSortedSchools] = useState({
    elementary: { title: "Elementary Schools", items: [] },
    middle: { title: "Middle Schools", items: [] },
    high: { title: "High Schools", items: [] },
  });

  useEffect(() => {
    const calculateDistance = (school) => {
      return calcCrow(
        school.latitude,
        school.longitude,
        listing.map.latitude,
        listing.map.longitude
      ).toFixed(2);
    };
    const renderInfo = (school) => {
      let str = `${school.gradeFrom === -1 ? "JK-" : `${school.gradeFrom}-`}${
        school.gradeEnd
      } / `;

      if (school.isCatholic) str += " Catholic /";
      const distance = calcCrow(
        school.latitude,
        school.longitude,
        listing.map.latitude,
        listing.map.longitude
      );
      str = str + ` ${distance.toFixed(2)} km`;

      return str;
    };

    const checkBoundary = (school) => {
      try {
        const boundary = JSON.parse(JSON.parse(school.boundaryArray)).Points;

        let points = [];
        for (let i = 0; i < boundary.length; i += 2) {
          points.push(boundary.slice(i, i + 2));
        }
        const isInside = isInsidePolygon(
          [parseFloat(listing.map.latitude), parseFloat(listing.map.longitude)],
          points
        );

        return isInside ? 1 : 0;
      } catch (err) {
        return -1;
      }
    };
    if (schools) {
      schools.forEach((school) => {
        school.info = renderInfo(school);
        school.isInBoundary = checkBoundary(school);
        school.distance = calculateDistance(school);
      });

      setSortedSchools({
        elementary: {
          title: "Elementary School",
          items: schools
            .filter((s) => s.isElementary && s.isInBoundary)
            .sort((a, b) => a.isInBoundary - b.isInBoundary)
            .sort((a, b) => a.distance - b.distance)
            .sort((a, b) => b.ettieOverallAvg - a.ettieOverallAvg),
        },
        middle: {
          title: "Middle Schools",
          items: schools
            .filter((s) => s.isMiddle && s.isInBoundary)
            .sort((a, b) => a.isInBoundary - b.isInBoundary)
            .sort((a, b) => a.distance - b.distance)
            .sort((a, b) => b.ettieOverallAvg - a.ettieOverallAvg),
        },
        high: {
          title: "High Schools",
          items: schools
            .filter((s) => s.isHigh && s.isInBoundary)
            .sort((a, b) => a.isInBoundary - b.isInBoundary)
            .sort((a, b) => a.distance - b.distance)
            .sort((a, b) => b.ettieOverallAvg - a.ettieOverallAvg),
        },
      });
    }
  }, [listing.map.latitude, listing.map.longitude, schools]);

  const renderScore = (school) => {
    if (
      !school.ettieOverallAvg ||
      school.ettieOverallAvg === 0 ||
      school.ettieOverallAvg === -1
    )
      return (
        <span className={styles.score} style={{ fontSize: "2.4rem" }}>
          N/A
        </span>
      );

    return (
      <>
        <span className={styles.score}>
          {(school.ettieOverallAvg / 10).toFixed(1)}
        </span>
        <span className={styles.outOf}>out of 10</span>
      </>
    );
  };

  const renderPrograms = (school) => {
    let str = "";
    if (school.isFrenchImmersion) str += "French Immersion, ";
    if (school.isExtendedFrench) str += "Extended French, ";
    if (school.isIB) str += "International Baccalaureate, ";
    if (school.isAP) str += "Advanced Placement, ";
    if (school.isGifted) str += "Gifted, ";
    if (school.isSport) str += "Sports, ";
    if (school.isArts) str += "Arts, ";

    if (str.length) str = str.substring(0, str.length - 2);
    if (str.length)
      return (
        <>
          <span className={styles.title}>Special Programs</span>
          <span className={styles.text}>{str}</span>
        </>
      );
    return;
  };

  return (
    <div className={styles.container}>
      {(!schools || !schools.length) && (
        <div className={styles.noSchool}>
          {loading ? (
            <IonSpinner name="dots" className={styles.spinner} />
          ) : (
            <>
              The school in this area has not provided defined school zone
              boundaries. Please confirm which school zone this home falls
              within before making any purchasing decisions.
            </>
          )}
        </div>
      )}
      {Object.keys(sortedSchools).map((grade, i) => {
        if (sortedSchools[grade].items?.length > 0) {
          return (
            <div className={styles.schools} key={i}>
              <div className={styles.header}>
                <div className={styles.title}>{sortedSchools[grade].title}</div>
                <div className={styles.note}>
                  Residents likely attend{" "}
                  <IonRouterLink
                    className={styles.link}
                    routerLink={`/schools/${sortedSchools[grade].items[0].id}`}>
                    <span style={{ textDecoration: "underline" }}>
                      {sortedSchools[grade].items[0].name}
                    </span>
                  </IonRouterLink>
                  . Please confirm the school for this property before making a
                  purchasing decision.
                </div>
              </div>
              <div className={styles.items}>
                {sortedSchools[grade].items.map((school, i) => (
                  <div key={i} className={styles.item}>
                    <div className={styles.left}>
                      <IonRouterLink
                        className={styles.link}
                        routerLink={`/schools/${school.id}`}>
                        <div className={styles.name}>
                          <span>{school.name}</span>
                          <IonIcon icon={ellipsisHorizontalCircleOutline} />
                        </div>
                      </IonRouterLink>
                      <div className={styles.info}>{school.info}</div>
                      <div className={styles.programs}>
                        {renderPrograms(school)}
                      </div>
                    </div>
                    <div className={styles.right}>
                      <div className={styles.rate}>
                        <div className={styles.rate}>{renderScore(school)}</div>
                      </div>
                      <div className={styles.boundary}>
                        {school.isInBoundary === 1
                          ? "Within boundaries"
                          : school.isInBoundary === 0
                          ? "Not within boundaries"
                          : "Boundaries not available"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        } else return null;
      })}
    </div>
  );
};
export default Schools;
