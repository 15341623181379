import { IonCol, IonGrid, IonRow, IonLabel } from "@ionic/react";
import styles from "./tour-attendees.module.scss";
import { TourService } from "../../../../../../../services/tourService";
import { TourRole } from "../../../../../../../API";

const TourAttendees = ({ tour }) => {
  const users = TourService.getTourUsers(tour);

  const renderClient = () => {
    const client = users.find((m) => m.role === TourRole.client);
    if (client)
      return (
        <IonLabel>
          {client.givenName} {client.familyName}
        </IonLabel>
      );
    return "---";
  };

  const renderGuests = () => {
    const guests = users.filter((m) => m.role === TourRole.guest);
    if (!guests || guests.length === 0) return;
    return guests.map((g, i) => (
      <IonRow className={styles.attendees} key={i}>
        <IonCol>Additional guest</IonCol>
        <IonCol className={`${styles.bold}`}>
          <IonLabel>{`${g.givenName} ${g.familyName}`}</IonLabel>
        </IonCol>
      </IonRow>
    ));
  };

  return (
    <IonGrid className={styles.tourAttendees}>
      <IonRow className={styles.attendees}>
        <IonCol>Lead agent</IonCol>
        <IonCol className={`${styles.bold} ${styles.link}`}>
          <IonLabel>
            {users.find((m) => m.role === TourRole.primaryagent)?.displayName}
          </IonLabel>
        </IonCol>
      </IonRow>
      {users
        .filter((m) => m.role === TourRole.showingagent)
        .map((agent, i) => (
          <IonRow key={i} className={styles.attendees}>
            <IonCol>Showing agent</IonCol>
            <IonCol className={`${styles.bold} ${styles.link}`}>
              <IonLabel>{agent.displayName}</IonLabel>
            </IonCol>
          </IonRow>
        ))}

      <IonRow className={styles.attendees}>
        <IonCol>Client</IonCol>
        <IonCol className={`${styles.bold}`}>{renderClient()}</IonCol>
      </IonRow>
      {renderGuests()}
    </IonGrid>
  );
};

export default TourAttendees;
