import { MapActionTypes } from "./map.types";

const INITIAL_STATE = {
  ref: null,
  clusters: [],
  listings: null,
  selectedCluster: null,
  selectedListings: [],
  selectedLocation: null,
  activeListing: null,
  detailsListing: null,
  favouritesOnly: false,
  locations: {
    center: null,
    coordinates: null,
  },
  boundary: null,
  loading: false,
  error: undefined,
};

const mapReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MapActionTypes.UPDATE_LOADING:
      return { ...state, loading: action.payload };
    case MapActionTypes.UPDATE_LISTINGS:
      return { ...state, listings: action.payload };
    case MapActionTypes.CLEAR_LISTINGS:
      return { ...state, listings: null };
    case MapActionTypes.UPDATE_SELECTED_CLUSTER:
      return { ...state, selectedCluster: action.payload };
    case MapActionTypes.UPDATE_SELECTED_LISTINGS:
      return { ...state, selectedListings: action.payload };
    case MapActionTypes.UPDATE_SELECTED_LOCATION:
      return { ...state, selectedLocation: action.payload };
    case MapActionTypes.UPDATE_DETAILS_LISTING:
      return { ...state, detailsListing: action.payload };
    case MapActionTypes.UPDATE_ACTIVE_LISTING:
      return { ...state, activeListing: action.payload };
    case MapActionTypes.UPDATE_MAP_REF:
      return { ...state, ref: action.payload };
    case MapActionTypes.UPDATE_LOCATIONS:
      return { ...state, locations: action.payload };
    case MapActionTypes.TOGGLE_FAVOURITES:
      if (action.payload) return { ...state, favouritesOnly: action.payload };
      return { ...state, favouritesOnly: !state.favouritesOnly };
    case MapActionTypes.UPDATE_BOUNDARIES:
      return { ...state, boundary: action.payload };
    default:
      return state;
  }
};

export default mapReducer;
