import { useState, useRef, createRef, useEffect } from "react";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonIcon,
  IonAlert,
  IonLoading,
} from "@ionic/react";
import IsVisibleTag from "../../../../../../../components/IsVisibleTag/is-visible-tag.component";
import styles from "./agent-details.module.scss";
import { closeOutline } from "ionicons/icons";
import { TourStatus } from "../../../../../../../API";
import { TourService } from "../../../../../../../services/tourService";

const AgentDetails = ({ tour, update, archive, cancel }) => {
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");
  const [showArchiveAlert, setShowArchiveAlert] = useState(false);
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const inputRefs = useRef({
    tour_title: createRef(),
  });

  const handleNoteChange = ({ target: { value } }) => {
    setNote(value);
  };

  const handleSave = async () => {
    await update({privateNote: note})
  };

  const handleArchive = async () => {
    setShowArchiveAlert(false);
    setLoading(true);
    await archive();
    setLoading(false);
  };

  const handleCancel = async (informAttendees) => {
    setShowCancelAlert(false);
    setLoading(true);
    await cancel(informAttendees);
    setLoading(false);
  };

  useEffect(() => {
      setNote(tour.privateNote ? tour.privateNote : "");
  }, [tour]);

  return (
    <IonGrid className={styles.agentDetails}>
      <IonLoading isOpen={loading} />
      <IonAlert
        isOpen={showArchiveAlert}
        backdropDismiss={true}
        onDidDismiss={() => setShowArchiveAlert(false)}
        header="Archive tour"
        message="Are you sure? By archiving a tour, tour attendants will no longer be able to update its content."
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => setShowArchiveAlert(false),
          },
          {
            text: "Archive",
            role: "submit",
            handler: () => handleArchive(),
          },
        ]}
      />
      <IonAlert
        isOpen={showCancelAlert}
        backdropDismiss={true}
        onDidDismiss={() => setShowCancelAlert(false)}
        header="Cancel tour"
        subHeader="Do you want to inform attendees that this tour has been cancelled?"
        buttons={[
          {
            text: "Keep tour active",
            role: "cancel",
            handler: () => setShowCancelAlert(false),
            cssClass: styles.btn,
          },
          {
            text: "Cancel the tour only",
            cssClass: styles.btn,
            handler: () => handleCancel(false),
          },
          {
            text: "Cancel and update attendees",
            cssClass: styles.btn,
            handler: () => handleCancel(true),
          },
        ]}
      />
      <IonRow>
        <IonCol>
          <IsVisibleTag isVisible={false} expand="full" />
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <textarea
            className={styles.textarea}
            name="agent_note"
            placeholder="Private agent tour notes"
            ref={inputRefs.current.agent_note}
            value={note}
            onChange={handleNoteChange}
          ></textarea>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <IonButton
            shape="round"
            expand="block"
            className={styles.button}
            disabled={!tour || !tour.status || tour.status === "archived"}
            onClick={handleSave}
          >
            Save changes
          </IonButton>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol className={styles.note}>
          These fields will not be shared with the client.
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <IonButton
            fill="clear"
            shape="round"
            expand="block"
            className={styles.archiveButton}
            onClick={() => setShowArchiveAlert(true)}
            disabled={tour.status === "archived"}
          >
            {tour.status !== "archived" ? (
              <>
                <IonIcon icon={closeOutline} />
                Archive tour
              </>
            ) : (
              "This tour is archived"
            )}
          </IonButton>
        </IonCol>
        {![TourStatus.archived, TourStatus.cancelled, TourStatus.completed].includes(tour.status) && (
          <IonCol>
            <IonButton
              fill="clear"
              shape="round"
              expand="block"
              className={styles.cancelButton}
              disabled={!TourService.isCurrentUserTourPrimaryAgent(tour)}
              onClick={() => setShowCancelAlert(true)}
            >
              <IonIcon icon={closeOutline} />
              Cancel tour
            </IonButton>
          </IonCol>
        )}
      </IonRow>
    </IonGrid>
  );
};

export default AgentDetails;
