import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Form/Button/button.component";
import { ReactComponent as BackIcon } from "../../assets/svg/chevronBack.svg";
import { ReactComponent as ClearIcon } from "../../assets/svg/close.svg";
import styles from "./filters-menu.module.scss";
import PriceFilterItem from "./components/price-filter-item.component";
import withFilters from "../../HOC/withFilters/with-filters";
import LocationFilterItem from "./components/location-filter-item.component";
import { resetFilters } from "../../redux/filters/filters.actions";
import BedsFilterItem from "./components/beds-filter-item.component";
import BathsFilterItem from "./components/baths-filter-item.component";
import SQFTFilterItem from "./components/sqft-filter-item.component";
import PurposeFilterItem from "./components/purpose-filter-item.component";
import DateFilterItem from "./components/date-filter-item.component";
import StatusFilterItem from "./components/status-filter-item.component";
import PropertyTypeFilterItem from "./components/property-type-filter-item.component";
import ParkingsFilterItem from "./components/parkings-filter-item.component";
import LotWidthFilterItem from "./components/lot-width-filter-item.component";
import LotDepthFilterItem from "./components/lot-depth-filter-item.component";
import LockerFilterItem from "./components/locker-filter-item.component";
import BasementFilterItem from "./components/basement-filter-item.component";
import MaintenanceFilterItem from "./components/maintenance-filter-item.component";
import KeywordsFilterItem from "./components/keywords-filter-item.component";
import { updateLocations } from "../../redux/map/map.actions";
import { isActive, renderLocationTag, renderTag } from "./utils";
import { toggleFilters } from "../../redux/ui/ui.actions";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { selectIsFiltersOpen } from "../../redux/ui/ui.selectors";
import { capitalize } from "../../utils/functions";
import { selectIsPreConstruction } from "../../redux/filters/filters.selectors";
import BuilderFilterItem from "./components/builder-filter-item.component";
import ProjectFilterItem from "./components/project-filter-item.component";
import SQFTPriceFilterItem from "./components/sqft-price-filter-item.component";
import { selectLocationsValue } from "../../redux/locations/locations.selectors";
import { useDetectClickOutside } from "react-detect-click-outside";

const BUTTON_STYLE = {
  borderRadius: 0,
  border: 0,
  height: "5rem",
};

const FiltersMenu = ({
  activeFilters,
  filters,
  extraFilters,
  updateSingleFilter,
  isMobile,
}) => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState();
  const isFiltersOpen = useSelector(selectIsFiltersOpen);

  const clickOutsideRef = useDetectClickOutside({
    onTriggered: () => {
      if (isFiltersOpen) {
        dispatch(toggleFilters());
      }
    },
    disableTouch: true,
  });
  const isPreConstruction = useSelector(selectIsPreConstruction);
  const locations = useSelector(selectLocationsValue) || [];

  const onTabHandler = (tabName) => {
    if (tab === tabName) {
      setTab(null);
    } else {
      setTab(tabName);
    }
  };

  const checkIsActive = (type) =>
    isActive(type, activeFilters, extraFilters, tab);

  const renderFilterTag = (type) =>
    capitalize(renderTag(type, filters, extraFilters, tab));

  const submit = () => dispatch(toggleFilters());

  const handleTypeChange = ({ detail: { value } }) => {
    updateSingleFilter({
      type: value,
      lastStatus:
        filters.status === "A" ? null : value === "sale" ? "Sld" : "Lsd",
      minPrice: "0",
      maxPrice: "max",
    });
    setTab(null);
  };

  return (
    <div className={styles.filtersContainer} id="filters" ref={clickOutsideRef}>
      <div className={styles.header}>More filters</div>
      <div className={styles.segments}>
        <IonSegment
          id="segment"
          value={filters.type}
          onIonChange={handleTypeChange}
          className={styles.segment}
          mode="ios">
          <IonSegmentButton value="sale">
            <IonLabel>{filters.status === "U" ? "Sold" : "For Sale"}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="lease">
            <IonLabel>
              {filters.status === "U" ? "Leased" : "For Lease"}
            </IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="pre-construction">
            <IonLabel>Pre-const</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </div>
      <div className={styles.main}>
        {isPreConstruction && (
          <>
            <div
              className={`${styles.filters} ${
                checkIsActive("builder") && styles.activeFilter
              }`}>
              <BuilderFilterItem
                tabName="builder"
                tab={tab}
                setTab={onTabHandler}
              />
            </div>
            <div
              className={`${styles.filters} ${
                checkIsActive("project") && styles.activeFilter
              }`}>
              <ProjectFilterItem
                tabName="project"
                tab={tab}
                setTab={onTabHandler}
              />
            </div>
          </>
        )}
        {!isPreConstruction && locations && (
          <div
            className={`${styles.filters} ${
              checkIsActive("location") && styles.activeFilter
            }`}>
            <LocationFilterItem
              buttonStyle={BUTTON_STYLE}
              tabName="location"
              tab={tab}
              setTab={onTabHandler}
              disabled={false}
            />
            <div className={styles.active} onClick={() => setTab("location")}>
              {tab !== "location" &&
                renderLocationTag({
                  neighborhood: filters.neighborhood,
                  city: filters.city,
                  area: filters.area,
                })}
            </div>
          </div>
        )}

        <div
          className={`${styles.filters} ${
            checkIsActive("type") && styles.activeFilter
          }`}>
          <PropertyTypeFilterItem
            buttonStyle={BUTTON_STYLE}
            tabName="type"
            tab={tab}
            setTab={onTabHandler}
            disabled={false}
            isPreConstruction={isPreConstruction}
          />
          <div className={styles.active} onClick={() => setTab("type")}>
            {renderFilterTag("type")}
          </div>
        </div>
        <div
          className={`${styles.filters} ${
            checkIsActive("price") && styles.activeFilter
          }`}>
          <PriceFilterItem
            buttonStyle={BUTTON_STYLE}
            tabName="price"
            tab={tab}
            setTab={onTabHandler}
            disabled={false}
          />
          <div className={styles.active} onClick={() => setTab("price")}>
            {renderFilterTag("price")}
          </div>
        </div>

        <div
          className={`${styles.filters} ${
            checkIsActive("beds") && styles.activeFilter
          }`}>
          <BedsFilterItem
            buttonStyle={BUTTON_STYLE}
            tabName="beds"
            tab={tab}
            setTab={onTabHandler}
            disabled={false}
          />
          <div className={styles.active} onClick={() => setTab("beds")}>
            {renderFilterTag("beds")}
          </div>
        </div>
        {isPreConstruction && (
          <>
            <div
              className={`${styles.filters} ${
                checkIsActive("price") && styles.activeFilter
              }`}>
              <SQFTPriceFilterItem
                buttonStyle={BUTTON_STYLE}
                tabName="sqftPrice"
                tab={tab}
                setTab={onTabHandler}
                disabled={false}
              />
              <div
                className={styles.active}
                onClick={() => setTab("sqftPrice")}>
                {renderFilterTag("sqftPrice")}
              </div>
            </div>
            <div
              className={`${styles.filters} ${
                checkIsActive("sqft") && styles.activeFilter
              }`}>
              <SQFTFilterItem
                buttonStyle={BUTTON_STYLE}
                tabName="sqft"
                tab={tab}
                setTab={onTabHandler}
                disabled={false}
              />
              <div className={styles.active} onClick={() => setTab("sqft")}>
                {renderFilterTag("sqft")}
              </div>
            </div>
          </>
        )}
        {!isPreConstruction && (
          <>
            {" "}
            <div
              className={`${styles.filters} ${
                checkIsActive("baths") && styles.activeFilter
              }`}>
              <BathsFilterItem
                buttonStyle={BUTTON_STYLE}
                tabName="baths"
                tab={tab}
                setTab={onTabHandler}
                disabled={false}
              />
              <div className={styles.active} onClick={() => setTab("baths")}>
                {renderFilterTag("baths")}
              </div>
            </div>
            <div
              className={`${styles.filters} ${
                checkIsActive("parkings") && styles.activeFilter
              }`}>
              <ParkingsFilterItem
                buttonStyle={BUTTON_STYLE}
                tabName="parkings"
                tab={tab}
                setTab={onTabHandler}
                disabled={false}
              />
              <div className={styles.active} onClick={() => setTab("parkings")}>
                {renderFilterTag("parkings")}
              </div>
            </div>
            <div
              className={`${styles.filters} ${
                checkIsActive("sqft") && styles.activeFilter
              }`}>
              <SQFTFilterItem
                buttonStyle={BUTTON_STYLE}
                tabName="sqft"
                tab={tab}
                setTab={onTabHandler}
                disabled={false}
              />
              <div className={styles.active} onClick={() => setTab("sqft")}>
                {renderFilterTag("sqft")}
              </div>
            </div>
            <div
              className={`${styles.filters} ${
                checkIsActive("purpose") && styles.activeFilter
              }`}>
              <PurposeFilterItem
                buttonStyle={BUTTON_STYLE}
                tabName="purpose"
                tab={tab}
                setTab={onTabHandler}
                disabled={false}
              />
              <div className={styles.active} onClick={() => setTab("purpose")}>
                {renderFilterTag("purpose")}
              </div>
            </div>
            <div
              className={`${styles.filters} ${
                checkIsActive("status") && styles.activeFilter
              }`}>
              <StatusFilterItem
                buttonStyle={BUTTON_STYLE}
                tabName="status"
                tab={tab}
                setTab={onTabHandler}
                disabled={false}
              />
              <div className={styles.active} onClick={() => setTab("status")}>
                {renderFilterTag("status")}
              </div>
            </div>
            <div
              className={`${styles.filters} ${
                checkIsActive("date") && styles.activeFilter
              }`}>
              <DateFilterItem
                buttonStyle={BUTTON_STYLE}
                tabName="date"
                tab={tab}
                setTab={onTabHandler}
                disabled={false}
              />
              <div className={styles.active} onClick={() => setTab("date")}>
                {renderFilterTag("date")}
              </div>
            </div>
            <div
              className={`${styles.filters} ${
                checkIsActive("lotWidth") && styles.activeFilter
              }`}>
              <LotWidthFilterItem
                buttonStyle={BUTTON_STYLE}
                tabName="lotWidth"
                tab={tab}
                setTab={onTabHandler}
                disabled={false}
              />
              <div className={styles.active} onClick={() => setTab("lotWidth")}>
                {renderFilterTag("lotWidth")}
              </div>
            </div>
            <div
              className={`${styles.filters} ${
                checkIsActive("lotDepth") && styles.activeFilter
              }`}>
              <LotDepthFilterItem
                buttonStyle={BUTTON_STYLE}
                tabName="lotDepth"
                tab={tab}
                setTab={onTabHandler}
                disabled={false}
              />
              <div className={styles.active} onClick={() => setTab("lotDepth")}>
                {renderFilterTag("lotDepth")}
              </div>
            </div>
            <div
              className={`${styles.filters} ${
                checkIsActive("locker") && styles.activeFilter
              }`}>
              <LockerFilterItem
                buttonStyle={BUTTON_STYLE}
                tabName="locker"
                tab={tab}
                setTab={onTabHandler}
                disabled={false}
              />
              <div className={styles.active} onClick={() => setTab("locker")}>
                {renderFilterTag("locker")}
              </div>
            </div>
            <div
              className={`${styles.filters} ${
                checkIsActive("basement") && styles.activeFilter
              }`}>
              <BasementFilterItem
                buttonStyle={BUTTON_STYLE}
                tabName="basement"
                tab={tab}
                setTab={onTabHandler}
                disabled={false}
              />
              <div className={styles.active} onClick={() => setTab("basement")}>
                {renderFilterTag("basement")}
              </div>
            </div>
            <div
              className={`${styles.filters} ${
                checkIsActive("maintenance") && styles.activeFilter
              }`}>
              <MaintenanceFilterItem
                buttonStyle={BUTTON_STYLE}
                tabName="maintenance"
                tab={tab}
                setTab={onTabHandler}
                disabled={false}
              />
              <div
                className={styles.active}
                onClick={() => setTab("maintenance")}>
                {renderFilterTag("maintenance")}
              </div>
            </div>
          </>
        )}

        <div
          className={`${styles.filters} ${
            checkIsActive("maintenance") && styles.activeFilter
          }`}>
          <KeywordsFilterItem
            buttonStyle={BUTTON_STYLE}
            tabName="keywords"
            tab={tab}
            setTab={onTabHandler}
            disabled={false}
          />
          <div className={styles.active} onClick={() => setTab("keywords")}>
            {renderFilterTag("keywords")}
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          title="reset"
          type="clear"
          style={{
            border: "none",
            width: "auto",
            color: "#707070",
            fontWeight: "500",
          }}
          onClick={() => {
            dispatch(resetFilters());
            dispatch(updateLocations({ center: null, coordinates: null }));
          }}>
          <ClearIcon />
        </Button>
        <Button
          title="done"
          type="green"
          style={{ width: "9rem", fontWeight: "500" }}
          onClick={submit}>
          <BackIcon />
        </Button>
      </div>
    </div>
  );
};

export default withFilters(FiltersMenu);
