import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectIsAgent,
} from "../../../redux/user/user.selectors";
import { IonContent, IonPage } from "@ionic/react";
import AppHeader from "../../components/Header/header.component";
import JoinContent from "../../components/CustomModals/Join/join-content.component";
import AgentToursComponent from "../AgentTours/agent-tours.component";
import ClientToursComponent from "../ClientTours/client-tours.component";
import { useHistory } from "react-router";

const Tours = () => {
  const history = useHistory();
  const currentUser = useSelector(selectCurrentUser);
  const isAgent = useSelector(selectIsAgent);

  if (!currentUser) {
    return (
      <IonPage>
        <AppHeader title="Join" hasRightButton={false} hasBackButton={false} />
        <IonContent>
          <JoinContent />
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <AppHeader
        title="My tours"
        isTourPage={isAgent ? true : false}
        hasRightButton={isAgent ? true : false}
        hasBackButton={false}
        onClick={() => history.push("/tabs/tours/new")}
        backHref={"/tours"}
      />
      <IonContent class="ion-padding">
        {isAgent ? <AgentToursComponent /> : <ClientToursComponent />}
      </IonContent>
    </IonPage>
  );
};

export default Tours;
