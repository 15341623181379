import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styles from "./projects.module.scss";
import Button from "../../../../components/Form/Button/button.component";
import { DEFAULT_PROJECT_STATE, validateNewProjectForm } from "../../utils";
import { updateToast } from "../../../../redux/ui/ui.actions";
import {
  addProject,
  getAllAmenities,
  searchForArchitects,
  searchForBuilders,
  searchForDesigners,
} from "../../../../amplify/graphql.utils";

import PreInput from "../../components/input";
import PreSearch from "../../components/search";
import PreRadio from "../../components/radio";
import PreCheckbox from "../../components/checkbox";
import PreMedia from "../../components/media";
import PreMultiple from "../../components/multiple";
import { TYPES } from "./data";
import BreadCrumbs from "../../components/breadcrumbs";

const NewProject = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState(DEFAULT_PROJECT_STATE);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [builders, setBuilders] = useState([]);
  const [designers, setDesigners] = useState([]);
  const [architects, setArchitects] = useState([]);
  const [availableAmenities, setAvailableAmenities] = useState([]);
  const [dropdown, setDropdown] = useState("");

  useEffect(() => {
    const fetchAmenities = async () => {
      const amenities = await getAllAmenities();
      const mappedAmenities = amenities.map((record) => {
        return { label: record.name, value: record.id, item: record };
      });
      setAvailableAmenities(mappedAmenities);
    };

    fetchAmenities();
  }, []);

  const handleSubmit = async () => {
    if (!validateForm()) {
      document.getElementById("errors")?.scrollIntoView();
    } else {
      try {
        setLoading(true);
        await addProject(data);
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: "Project created successfully!",
          })
        );
        history.replace(`/admin/projects`);
      } catch (err) {
        console.log(err);
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong!",
          })
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const validateForm = () => {
    const errs = validateNewProjectForm(data);
    setErrors(errs);
    return errs.length === 0;
  };

  const handleBuilderSearch = async ({ detail: { value } }) => {
    try {
      if (value) {
        const builders = await searchForBuilders({ name: value });
        setBuilders(builders);
      } else {
        setBuilders([]);
      }
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };
  const handleArchitectSearch = async ({ detail: { value } }) => {
    try {
      if (value) {
        const archtiects = await searchForArchitects({ name: value });
        setArchitects(archtiects);
      } else {
        setArchitects([]);
      }
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };
  const handleDesignerSearch = async ({ detail: { value } }) => {
    try {
      if (value) {
        const designers = await searchForDesigners({ name: value });
        setDesigners(designers);
      } else {
        setDesigners([]);
      }
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };

  return (
    <div className={styles.container}>
      <BreadCrumbs type="projects" sub="New Project" />
      <h2>Create a new project</h2>
      <div className={styles.form}>
        <PreInput
          value={data.name}
          required
          label={"Name"}
          placeholder={"Project name"}
          onChange={(val) => setData({ ...data, name: val })}
        />
        <PreInput
          value={data.thumbnail}
          label={"Thumbnail URL"}
          placeholder={"Project thumbnail"}
          pattern="url"
          onChange={(val) => setData({ ...data, thumbnail: val })}
        />

        <div
          className={styles.field}
          style={{ flexDirection: data.builder ? "row" : "column" }}
        >
          <PreSearch
            required
            label="Project builder"
            placeholder="Project builder"
            showSearchBar={!data.builder}
            showResults={dropdown === "builder" && builders.length}
            results={builders}
            onRemove={() => {
              setData({ ...data, builder: null });
              setBuilders([]);
            }}
            onChange={handleBuilderSearch}
            onFocus={() => setDropdown("builder")}
            onBlur={() => setDropdown("")}
            onSelect={(val) => setData({ ...data, builder: val })}
            selected={data.builder?.name}
          />
        </div>
        <div
          className={styles.field}
          style={{ flexDirection: data.architect ? "row" : "column" }}
        >
          <PreSearch
            label="Project architect"
            placeholder="Project architect"
            showSearchBar={!data.architect}
            showResults={dropdown === "architect" && architects.length}
            results={architects}
            onRemove={() => {
              setData({ ...data, architect: null });
              setArchitects([]);
            }}
            onChange={handleArchitectSearch}
            onFocus={() => setDropdown("architec")}
            onBlur={() => setDropdown("")}
            onSelect={(val) => setData({ ...data, architect: val })}
          />
        </div>
        <div
          className={styles.field}
          style={{ flexDirection: data.designer ? "row" : "column" }}
        >
          <PreSearch
            label="Project interior designer"
            placeholder="Project interior designer"
            showSearchBar={!data.designer}
            showResults={dropdown === "designer" && designers.length}
            results={designers}
            onRemove={() => {
              setData({ ...data, designer: null });
              setDesigners([]);
            }}
            onChange={handleDesignerSearch}
            onFocus={() => setDropdown("designer")}
            onBlur={() => setDropdown("")}
            onSelect={(val) => setData({ ...data, designer: val })}
          />
        </div>
        <br />
        <PreInput
          value={data.occupancy}
          label={"Occupancy"}
          placeholder={"Project occupancy"}
          onChange={(val) => setData({ ...data, occupancy: val })}
        />
        <PreInput
          value={data.units}
          label={"Units"}
          type="number"
          placeholder={"Project units"}
          onChange={(val) => setData({ ...data, units: val })}
        />
        <PreInput
          value={data.stories}
          label={"Stories"}
          type="number"
          placeholder={"Project stories"}
          onChange={(val) => setData({ ...data, stories: val })}
        />
        <PreInput
          value={data.address.streetNumber}
          label={"Street number"}
          required
          placeholder={"Street number"}
          onChange={(val) =>
            setData({
              ...data,
              address: { ...data.address, streetNumber: val },
            })
          }
        />
        <PreInput
          value={data.address.streetName}
          label={"Street name"}
          required
          placeholder={"Street name"}
          onChange={(val) =>
            setData({
              ...data,
              address: { ...data.address, streetName: val },
            })
          }
        />
        <PreInput
          value={data.address.city}
          label={"City"}
          required
          placeholder={"City"}
          onChange={(val) =>
            setData({
              ...data,
              address: { ...data.address, city: val },
            })
          }
        />
        <PreInput
          value={data.address.province}
          label={"Province"}
          required
          placeholder={"Province"}
          onChange={(val) =>
            setData({
              ...data,
              address: { ...data.address, province: val },
            })
          }
        />
        <PreInput
          value={data.address.latitude}
          label={"Latitude"}
          required
          placeholder={"Latitude"}
          onChange={(val) =>
            setData({
              ...data,
              address: { ...data.address, latitude: val },
            })
          }
        />
        <PreInput
          value={data.address.longitude}
          label={"Longitude"}
          required
          placeholder={"Longitude"}
          onChange={(val) =>
            setData({
              ...data,
              address: { ...data.address, longitude: val },
            })
          }
        />
        <PreInput
          value={data.price.start}
          label={"Starting price"}
          required
          type="number"
          placeholder={"Starting price"}
          onChange={(val) =>
            setData({
              ...data,
              price: { ...data.price, start: val },
            })
          }
        />
        <PreInput
          value={data.price.end}
          label={"Ending price"}
          type="number"
          placeholder={"Ending price"}
          onChange={(val) =>
            setData({
              ...data,
              price: { ...data.price, end: val },
            })
          }
        />
        <PreInput
          value={data.price.avg}
          label={"Price per sqft"}
          type="number"
          placeholder={"Price per sqft"}
          onChange={(val) =>
            setData({
              ...data,
              price: { ...data.price, avg: val },
            })
          }
        />
        <PreInput
          value={data.price.parking}
          label={"Parking price"}
          type="number"
          placeholder={"Parking price"}
          onChange={(val) =>
            setData({
              ...data,
              price: { ...data.price, parking: val },
            })
          }
        />
        <PreInput
          value={data.price.locker}
          label={"Locker price"}
          type="number"
          placeholder={"Locker price"}
          onChange={(val) =>
            setData({
              ...data,
              price: { ...data.price, locker: val },
            })
          }
        />
        <PreInput
          value={data.price.maintenance}
          label={"Maintenance fee per sqft"}
          type="number"
          placeholder={"Maintenance fee per sqft"}
          onChange={(val) =>
            setData({
              ...data,
              price: { ...data.price, maintenance: val },
            })
          }
        />
        <PreInput
          value={data.beds.min}
          label={"Min beds"}
          type="number"
          placeholder={"Min beds"}
          onChange={(val) =>
            setData({
              ...data,
              beds: { ...data.beds, min: val },
            })
          }
        />
        <PreInput
          value={data.beds.max}
          label={"Max beds"}
          type="number"
          placeholder={"Max beds"}
          onChange={(val) =>
            setData({
              ...data,
              beds: { ...data.beds, max: val },
            })
          }
        />
        <PreInput
          value={data.sqft.min}
          label={"Min sqft"}
          type="number"
          placeholder={"Min sqft"}
          onChange={(val) =>
            setData({
              ...data,
              sqft: { ...data.sqft, min: val },
            })
          }
        />
        <PreInput
          value={data.sqft.max}
          label={"Max sqft"}
          type="number"
          placeholder={"Max sqft"}
          onChange={(val) =>
            setData({
              ...data,
              sqft: { ...data.sqft, max: val },
            })
          }
        />
        <br />
        <PreInput
          value={data.description}
          label={"Description"}
          placeholder={"Description"}
          autoGrow
          style={{ gridColumn: "1/-1" }}
          onChange={(val) =>
            setData({
              ...data,
              description: val,
            })
          }
        />
        <PreMultiple
          label="Incentives"
          styles={{ addIcon: styles.addIcon }}
          items={data.incentives}
          onAdd={() =>
            setData({
              ...data,
              incentives: [...data.incentives, { id: uuidv4(), value: "" }],
            })
          }
          onChange={({ index, value }) => {
            let incentives = [...data.incentives];
            incentives[index].value = value;
            setData({ ...data, incentives });
          }}
          onRemove={(id) => {
            setData({
              ...data,
              incentives: data.incentives.filter((item) => item.id !== id),
            });
          }}
        />

        <PreMultiple
          label="Deposit structure"
          styles={{ addIcon: styles.addIcon }}
          items={data.deposit}
          onAdd={() =>
            setData({
              ...data,
              deposit: [...data.deposit, { id: uuidv4(), value: "" }],
            })
          }
          onChange={({ index, value }) => {
            let deposit = [...data.deposit];
            deposit[index].value = value;
            setData({ ...data, deposit });
          }}
          onRemove={(id) => {
            setData({
              ...data,
              deposit: data.deposit.filter((item) => item.id !== id),
            });
          }}
        />

        <PreRadio
          required
          label="Phase"
          value={data.phase}
          onChange={(val) => setData({ ...data, phase: val })}
          style={{ gridColumn: "1/-1" }}
          items={[
            { label: "Pre-construction", value: "preconstruction" },
            { label: "Under construction", value: "underconstruction" },
            { label: "Completed", value: "completed" },
          ]}
        />
        <PreRadio
          required
          label="Availability"
          value={data.availability}
          onChange={(val) => setData({ ...data, availability: val })}
          style={{ gridColumn: "1/-1" }}
          items={[
            { label: "Available", value: "available" },
            { label: "Coming soon", value: "comingsoon" },
          ]}
        />
        <PreCheckbox
          label="Property Types"
          styles={{
            list: { gridColumn: "1/-1" },
            header: { fontSize: "1.5rem" },
            label: { fontSize: "1.4rem", color: "#363636" },
            checkboxes: styles.checkboxes,
          }}
          items={TYPES}
          checked={data.types}
          onChange={(item) =>
            setData({
              ...data,
              types: { ...data.types, [item.value]: item.checked },
            })
          }
        />
        <PreCheckbox
          label="Amenities"
          styles={{
            list: { gridColumn: "1/-1" },
            header: { fontSize: "1.5rem" },
            label: { fontSize: "1.4rem", color: "#363636" },
            checkboxes: styles.checkboxes,
          }}
          items={availableAmenities}
          checked={data.amenities}
          isAmenity
          onChange={(item) => {
            const { checked } = item;
            if (checked) {
              setData({
                ...data,
                amenities: [
                  ...data.amenities,
                  availableAmenities.find(
                    (record) => record.value === item.value
                  ),
                ],
              });
            } else {
              setData({
                ...data,
                amenities: data.amenities.filter(
                  (record) => record.value !== item.value
                ),
              });
            }
          }}
        />
        <PreMedia
          label="Media"
          styles={{ addIcon: styles.addIcon, item: { minWidth: "20rem" } }}
          items={data.media}
          onAdd={() =>
            setData({
              ...data,
              media: [...data.media, { id: uuidv4(), url: "", type: "image" }],
            })
          }
          onMediaChange={({ index, value }) => {
            let media = [...data.media];
            media[index].value = value;
            setData({ ...data, media });
          }}
          onTypeChange={({ index, value }) => {
            let media = [...data.media];
            media[index].type = value;
            setData({ ...data, media });
          }}
          onRemove={(id) =>
            setData({
              ...data,
              media: data.media.filter((item) => item.id !== id),
            })
          }
        />

        <div id="errors" className={styles.error}>
          {errors.map((error, i) => (
            <div key={i}>- {error}</div>
          ))}
        </div>
        <div className={styles.submit}>
          <Button
            title={"Create"}
            type={"green"}
            loading={loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default NewProject;
