import { API } from "aws-amplify";
import { store } from "../redux/store";
import { GeneralConstants } from "../utils/constants";
import { ListingsSortOptions } from "../enums/Listings/ListingsSortOptions.enum";

const getListings = async (page = 1, sortBy: ListingsSortOptions, sortOrder: "asc" | "desc") => {
  const body: any = buildRequestBody();
  body.page = page;
  body.filters.sortBy = `${sortBy}${sortOrder === "asc" ? "Asc" : "Desc"}`;

  try {
    const response = await API.post(
      GeneralConstants.REST_API_NAME,
      "/listings",
      {
        body,
      }
    );

    return {
      listings: response.data.listings || [],
      count: response.data.count || 0,
      page: response.data.page || 1,
    };
  } catch (error) {
    return {
      listings: [],
      count: 0,
      page: 1,
    };
  }
};

const getClusters = async () => {
  const body = buildRequestBody();

  if (!body.filters.map || !body.zoom) {
    return [];
  }

  try {
    const response = await API.post(
      GeneralConstants.REST_API_NAME,
      "/listings/clusters",
      {
        body,
      }
    );
    const clusters = response.data.aggregates.map.clusters;
    return clusters;
  } catch (error) {
    return [];
  }
};

const getClusterListings = async (map: number[][]) => {
  const body: any = buildRequestBody();
  body.page = 1;
  body.filters.map = map;

  try {
    const response = await API.post(
      GeneralConstants.REST_API_NAME,
      "/listings",
      {
        body,
      }
    );

    return response.data.listings || [];
  } catch (error) {
    return [];
  }
};

const buildRequestBody = () => {
  const isSchoolsEnabled = store.getState().schools.active;
  const showOnlyWithinSchoolsBoundaries =
    store.getState().schools.filters.showListingsWithin;
  const schools = store.getState().schools.schools;

  const filters = store.getState().filters.value;
  const preFilters = store.getState().filters.pre;
  const map = store.getState().map?.ref;

  const extraFilters = store.getState().filters.extra;

  return {
    isSchoolsEnabled,
    showOnlyWithinSchoolsBoundaries,
    schools,
    filters,
    preFilters,
    extraFilters,
    zoom: map ? map.zoom : undefined,
  };
};

export const MapService = {
  getListings,
  getClusters,
  getClusterListings,
};
