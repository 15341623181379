import { IonBadge, IonIcon } from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { useDispatch } from "react-redux";
import chevronDownOutline from "../../assets/svg/REA103_Icons-01a_ArrowDown-2pt-black.svg";
import { markNotification } from "../../redux/ui/ui.actions";
import styles from "./tour-details-header.module.scss";
import { TourItemService } from "../../services/tourItemService";

const DetailsHeader = ({
  clientTour,
  title,
  isCustomIcon = false,
  icon,
  isOpen,
  setIsOpen,
  isTourSchedule = false,
  scheduleLabel,
  hasTag = true,
  isVisible,
  disabled = false,
  invert = false,
  status,
  notSeen,
}) => {
  const dispatch = useDispatch();
  const onOpen = async () => {
    setIsOpen(!isOpen);
    if (!isOpen && notSeen && clientTour) {
      await TourItemService.markTourAsSeen(clientTour);
      dispatch(markNotification(clientTour.id));
    }
  };
  return (
    <div className={styles.header} onClick={onOpen}>
      <div className={styles.title}>
        <IonIcon
          icon={icon}
          className={`${isCustomIcon ? styles.customIcon : styles.icon}`}
        />{" "}
        <span>{title}</span>
        {isTourSchedule && (
          <span className={styles.tourSchedule}>{scheduleLabel}</span>
        )}
        {isTourSchedule && (
          <span className={`${styles.tourStatus} ${styles[status]}`}>
            {status}
          </span>
        )}
        {notSeen && <IonBadge className={styles.badge}>NEW</IonBadge>}
      </div>
      {hasTag && (
        <div
          className={`${styles.tag} ${
            isVisible ? styles.visible : styles.invisible
          }`}
        >
          {isVisible ? (
            <>
              <IonIcon icon={eyeOutline} className={styles.icon} />
              <span>visible to client</span>
            </>
          ) : (
            <>
              <IonIcon icon={eyeOffOutline} className={styles.icon} />
              <span>not visible to client</span>
            </>
          )}
        </div>
      )}
      <div>
        <IonIcon
          className={`${styles.chevronicon} ${
            isOpen ? styles.iconReversed : ""
          } ${invert ? styles.invertedIcon : ""} ${disabled &&
            styles.disabledIcon}`}
          src={chevronDownOutline}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
    </div>
  );
};

export default DetailsHeader;
