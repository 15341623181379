import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";
import CardContainer from "../../../../Tours/component/CardContainer/card-container.component";
import Button from "../../../../../components/Form/Button/button.component";
import styles from "./summary.module.scss";

const Summary = () => {
  const clickHandler = () => {
    window.open("https://aecorn.ca/learn-more/", "_blank");
  };

  return (
    <CardContainer>
      <IonGrid className={styles.generalInformation}>
        <IonRow>
          <IonCol>
            <div className={styles.title}>Who is AECORN Realty?</div>
            <div className={styles.subtitle}>
              AECORN Realty is a full-service real estate brokerage who can
              guide you through the entire process – door to door.
            </div>
            <div className={styles.info}>
              <div>
                We pride ourselves on being at the forefront of real estate
                technology and practices, which is why we have developed our own
                search platform. AECORN Realty is creating exclusive tools and
                services available nowhere else. We are continusouly updating
                and improving our platform and have big plans for the future.
              </div>
              <div>
                The AECORN Realty brokerage operates under a revolutionary,
                customer-focused methodology, offering clients a unique cashback
                program that can easily save them thousands when compared to the
                traditional commission system.
              </div>
            </div>
            <div>
              <Button
                onClick={clickHandler}
                title=" Learn more about AECORN Realty"
                expand="block"
                shape="round"
                type="green"
                style={{
                  height: "5rem",
                  width: "100%",
                  border: "1px solid #1F7A60",
                }}
              />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </CardContainer>
  );
};

export default Summary;
