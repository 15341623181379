import { IonLabel, IonIcon, IonSearchbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import DropdownContainer from "../../../components/Form/DropdownContainer/dropdown-container.component";
import styles from "./styles.module.scss";

const PreSearch = ({
  required = false,
  showSearchBar,
  label,
  selected,
  placeholder,
  onSelect,
  onRemove,
  onChange,
  onFocus,
  onBlur,
  results,
  showResults,
}) => {
  return (
    <>
      <IonLabel position="stack">
        {label}
        {required && "*"}
      </IonLabel>
      {!showSearchBar ? (
        <div className={styles.value}>
          <span className={styles.label}>{selected || label}</span>
          <IonIcon icon={closeOutline} onClick={onRemove} />
        </div>
      ) : (
        <>
          <IonSearchbar
            placeholder={placeholder}
            debounce={500}
            onIonChange={onChange}
            onIonFocus={onFocus}
            onIonBlur={onBlur}
            autocomplete="no"
            // showCancelButton="never"
            showClearButton="never"
          />

          <DropdownContainer open={showResults}>
            {results.map((item) => (
              <div
                className={styles.item}
                key={item.id}
                onClick={() => onSelect(item)}
              >
                {item.name}
              </div>
            ))}
          </DropdownContainer>
        </>
      )}
    </>
  );
};

export default PreSearch;
