import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  updateTourAttendees,
  fetchTours,
  updateTour,
} from "../../amplify/graphql.utils";

import { addTour } from "../../amplify/graphql.utils";
import {
  addClientsToTourFailure,
  addClientsToTourSuccess,
  addTourFailure,
  addTourSuccess,
  fetchToursFailure,
  fetchToursSuccess,
  updateTourFailure,
  updateTourSuccess,
} from "./tour.actions";
import TourActionTypes from "./tour.types";

export function* fetchToursAsync() {
  try {
    const tours = yield fetchTours();
    yield put(fetchToursSuccess(tours));
  } catch (error) {
    yield put(fetchToursFailure("Something went wrong. Please try again."));
  }
}

export function* addTourAsync({ payload }) {
  try {
    const tour = yield addTour(payload);
    yield put(addTourSuccess(tour));
  } catch (error) {
    yield put(addTourFailure("Something went wrong. Please try again."));
  }
}

export function* updateTourAsync({ payload }) {
  try {
    const tour = yield updateTour(payload);
    yield put(updateTourSuccess(tour));
  } catch (error) {
    yield put(updateTourFailure("Something went wrong. Please try again."));
  }
}

export function* addClientsToTourAsync({ payload }) {
  try {
    const { tourId, members } = payload;
    yield updateTourAttendees(members, tourId);
    yield put(addClientsToTourSuccess());
  } catch (error) {
    yield put(
      addClientsToTourFailure("Something went wrong. Please try again.")
    );
  }
}

export function* onFetchToursStart() {
  yield takeLatest(TourActionTypes.FETCH_TOURS_START, fetchToursAsync);
}

export function* onAddTourStart() {
  yield takeLatest(TourActionTypes.ADD_TOUR_START, addTourAsync);
}

export function* onUpdateTourStart() {
  yield takeLatest(TourActionTypes.UPDATE_TOUR_START, updateTourAsync);
}

export function* onAddClientsToTourStart() {
  yield takeLatest(TourActionTypes.ADD_CLIENTS_START, addClientsToTourAsync);
}

export function* tourSagas() {
  yield all([
    call(onFetchToursStart),
    call(onAddTourStart),
    call(onUpdateTourStart),
    call(onAddClientsToTourStart),
  ]);
}
