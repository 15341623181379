import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Storage } from "aws-amplify";
import styles from "./recordings-section.module.scss";
import Recording from "../Recording/recording.component";
import { openModal } from "../../../../redux/ui/ui.actions";
import { IonLoading, useIonViewWillEnter } from "@ionic/react";
import { TourRoles } from "../../../../utils/constants";

const RecordingsSection = ({
  disabled,
  isCurrentUser,
  recordings,
  memberData,
  handleDelete,
  playing,
  setPlaying,
  tour,
  isApp,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [s3Recordings, setS3recordings] = useState([]);

  useIonViewWillEnter(() => {
    setDeleteMode(false);
  });

  const handleNothingAdded = () =>
    dispatch(openModal({ current: "nothingAdded" }));

  useEffect(() => {
    const fetchRecordings = async () => {
      setLoading(true);
      const urls = await Promise.all(
        recordings.map(async (r) => {
          const recording = await Storage.get(r.path, {
            download: false,
            level: "public",
            expires: 21600,
          });

          return { recording: r, src: recording, id: r.id, path: r.path };
        })
      );
      setS3recordings(urls);
      setLoading(false);
    };
    if (recordings && recordings.length > 0) fetchRecordings();
  }, [recordings]);

  return (
    <div className={styles.recordingsSection}>
      <IonLoading isOpen={loading} />
      {isCurrentUser ? (
        <div className={styles.currentUser}>
          <div className={styles.title}>My recordings</div>
          {isApp && recordings && s3Recordings.length > 0 && !disabled && (
            <div
              className={
                !tour || !tour.status || tour.status === "archived"
                  ? styles.disableDeleteMode
                  : styles.deleteMode
              }
              onClick={() =>
                !tour || !tour.status || tour.status === "archived"
                  ? null
                  : setDeleteMode(!deleteMode)
              }
            >
              {deleteMode ? "Cancel" : "Delete recordings"}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.others}>
          <span>{`${memberData.user.givenName} ${memberData.user.familyName}`}</span>
          <span className={styles.role}>{TourRoles[memberData.role]}</span>
        </div>
      )}
      <div className={styles.recordings}>
        {recordings && s3Recordings.length > 0 ? (
          s3Recordings.map((recording, index) => (
            <span key={`${memberData.role}${index}${recording.path}`}>
              <audio id={recording.id}>
                <source src={recording.src} type="audio/wav" />
              </audio>
              <Recording   
                src={recording.src}
                id={recording.id}
                recording={recording.recording}
                label={`Recording ${index + 1}`}
                owner={memberData.user.givenName + " " + memberData.user.familyName}
                playing={playing}
                setPlaying={setPlaying}
                deleteMode={deleteMode}
                onDelete={handleDelete}
              />
            </span>
          ))
        ) : (
          <div className={styles.noRecording} onClick={handleNothingAdded}>
            Nothing added
          </div>
        )}
      </div>
    </div>
  );
};

export default RecordingsSection;
