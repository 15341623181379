import ReactDOM from "react-dom";
import { Capacitor } from "@capacitor/core";
import { setupIonicReact } from "@ionic/react";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./redux/store";
import rootSaga from "./redux/root-saga";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import { Browser } from "@capacitor/browser";
import './scss/index.scss';

let awsConfig; 

switch (process.env.REACT_APP_ENV) {
  case 'release':
    awsConfig = require('./aws-exports-release').default;
    break;
  case 'development':
  default:
    awsConfig = require('./aws-exports-dev').default;
    break;
}

Amplify.configure(awsConfig);


Store.sagaMiddleware.run(rootSaga);


setupIonicReact({ swipeBackEnabled: false });

if (Capacitor.isNativePlatform()) {
  document.addEventListener(
    "deviceready",
    () => {
      //@ts-ignore
      window.open = (url) => Browser.open({ url });
    },
    false
  );
}

ReactDOM.render(
  <Provider store={Store.store}>
    <PersistGate loading={true} persistor={Store.persistor}>
        <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.unregister();

reportWebVitals();
