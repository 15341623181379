import { useState } from "react";
import styles from "./new-tour.module.scss";
import AgentDetails from "./component/agent-details.component";
import TourDetails from "./component/tour-details.component";
import TourAttendees from "./component/tour-attendees.component";
import { useIonLoading, useIonToast } from "@ionic/react";
import { TourService } from "../../../../services/tourService";
import { useHistory } from "react-router";

const NewTour = () => {
  const history = useHistory();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentToast] = useIonToast();
  const [draftTour, setDraftTour] = useState({});
  const [step, setStep] = useState("agentDetails");

  const updateDraftTour = (values) => {
    if (values) {
      setDraftTour({ ...draftTour, ...values });
    }
  };

  const goToAgentDetails = (values) => {
    updateDraftTour(values);
    setStep("agentDetails");
  };

  const goToTourDetails = (values) => {
    updateDraftTour(values);
    setStep("tourDetails");
  };

  const goToTourAttendees = (values) => {
    updateDraftTour(values);
    setStep("tourAttendees");
  };

  const submit = async (values) => {
    const draftValues = { ...draftTour, ...values };

    await presentLoading({
      message: "Please wait...",
      spinner: "crescent",
    });
    try {
      const tour = await TourService.createTour({
        title: draftValues.title,
        privateNote: draftValues.privateNote,
        generalNote: draftValues.generalNote,
        date: draftValues.date,
        meetupDescription: draftValues.meetupDescription,
        meetupLocation: draftValues.meetupLocation,
        meetupTime: draftValues.meetupTime,
        startTime: draftValues.startTime,
        endTime: draftValues.endTime,
      }, draftValues.leadAgent, draftValues.showingAgents, draftValues.client, draftValues.guests);

      await presentToast({
        message: "Tour created successfully",
        duration: 3000,
        color: "success",
      });

      history.push(`/tours/${tour.id}`);
    } catch (error) {
      console.log(error);
      await presentToast({
        message: "Something went wrong. Please try again.",
        duration: 3000,
        color: "danger",
      });
    } finally {
      dismissLoading();
    }
  };

  const reset = () => {
    setDraftTour({});
    setStep("agentDetails");
  };

  return (
    <div className={styles.newTour} id="new_tour">
      <div className={styles.title}>Create a new tour</div>
      <div className={styles.subtitle}>
        Enter your tour information – you can always edit it later. Note that
        some content will only be visible by you, the agent. Once you create a
        tour, you can add and edit showings.
      </div>
      {step === "agentDetails" && (
        <AgentDetails tour={draftTour} next={goToTourDetails} />
      )}
      {step === "tourDetails" && (
        <TourDetails
          tour={draftTour}
          back={goToAgentDetails}
          next={goToTourAttendees}
        />
      )}

      {step === "tourAttendees" && (
        <TourAttendees
          tour={draftTour}
          back={goToTourDetails}
          next={submit}
          reset={reset}
        />
      )}
    </div>
  );
};

export default NewTour;
