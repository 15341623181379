import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { addUnit, searchForProjects } from "../../../../amplify/graphql.utils";
import { DEFAULT_UNIT_STATE, validateNewUnitForm } from "../../utils";
import { updateToast } from "../../../../redux/ui/ui.actions";
import Button from "../../../../components/Form/Button/button.component";
import PreInput from "../../components/input";
import PreSearch from "../../components/search";
import PreMedia from "../../components/media";
import PreRadio from "../../components/radio";
import styles from "./units.module.scss";
import BreadCrumbs from "../../components/breadcrumbs";

const NewUnits = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState(DEFAULT_UNIT_STATE);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropdown, setDropdown] = useState("");
  const [projects, setProjects] = useState([]);

  const handleProjectSearch = async ({ detail: { value } }) => {
    try {
      if (value) {
        const projects = await searchForProjects({ name: value });
        setProjects(projects);
      } else {
        setProjects([]);
      }
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    }
  };

  const validateForm = () => {
    const errs = validateNewUnitForm(data);
    setErrors(errs);
    return errs.length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      document.getElementById("errors")?.scrollIntoView();
    } else {
      try {
        setLoading(true);
        await addUnit(data);
        dispatch(
          updateToast({
            open: true,
            type: "success",
            message: "Unit created successfully!",
          })
        );
        history.replace(`/admin/units`);
      } catch (err) {
        console.log(err);
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong!",
          })
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.container}>
      <BreadCrumbs type="units" sub="New Unit" />
      <h2>Create a new unit</h2>
      <div className={styles.form}>
        <div
          className={styles.field}
          style={{ flexDirection: data.builder ? "row" : "column" }}
        >
          <PreSearch
            required
            label="Project"
            placeholder="Project"
            showSearchBar={!data.project}
            showResults={dropdown === "project" && projects.length}
            results={projects}
            onRemove={() => {
              setData({ ...data, project: null });
              setProjects([]);
            }}
            onChange={handleProjectSearch}
            onFocus={() => setDropdown("project")}
            onBlur={() => setDropdown("")}
            onSelect={(val) => setData({ ...data, project: val })}
            selected={data.project?.name}
          />
        </div>
        <PreInput
          value={data.beds}
          required
          label={"Bedroom(s)"}
          placeholder={"number of bedrooms"}
          onChange={(val) => setData({ ...data, beds: val })}
        />

        <PreInput
          value={data.baths}
          required
          label={"Bathroom(s)"}
          placeholder={"number of bathrooms"}
          onChange={(val) => setData({ ...data, baths: val })}
        />

        <PreInput
          value={data.parkings}
          label={"Parking(s)"}
          placeholder={"number of parkings"}
          onChange={(val) => setData({ ...data, parkings: val })}
        />
        <PreInput
          value={data.lockers}
          label={"Lockers(s)"}
          placeholder={"number of lockers"}
          onChange={(val) => setData({ ...data, lockers: val })}
        />

        <PreInput
          value={data.sqft}
          label={"Sq.ft."}
          placeholder={"Sq.ft."}
          onChange={(val) => setData({ ...data, sqft: val })}
        />

        <PreInput
          value={data.price}
          label={"Price"}
          placeholder={"Price"}
          onChange={(val) => setData({ ...data, price: val })}
        />

        <PreInput
          value={data.pricePerSqft}
          label={"Price per sq.ft."}
          placeholder={"Price per sq.ft."}
          onChange={(val) => setData({ ...data, pricePerSqft: val })}
        />

        <PreInput
          value={data.available}
          label={"Available"}
          placeholder={"available units"}
          onChange={(val) => setData({ ...data, available: val })}
        />

        <PreInput
          value={data.sold}
          label={"Sold"}
          placeholder={"sold units"}
          onChange={(val) => setData({ ...data, sold: val })}
        />

        <PreRadio
          label="Type"
          value={data.type}
          required
          onChange={(val) => setData({ ...data, type: val })}
          style={{ gridColumn: "1/-1" }}
          items={[
            { label: "Condo", value: "condo" },
            { label: "Townhouse", value: "townhouse" },
            { label: "Single Family Residence", value: "single" },
          ]}
        />

        <PreMedia
          label="Media"
          styles={{ addIcon: styles.addIcon, item: { minWidth: "20rem" } }}
          items={data.media}
          onAdd={() =>
            setData({
              ...data,
              media: [...data.media, { id: uuidv4(), url: "", type: "image" }],
            })
          }
          onMediaChange={({ index, value }) => {
            let media = [...data.media];
            media[index].value = value;
            setData({ ...data, media });
          }}
          onTypeChange={({ index, value }) => {
            let media = [...data.media];
            media[index].type = value;
            setData({ ...data, media });
          }}
          onRemove={(id) =>
            setData({
              ...data,
              media: data.media.filter((item) => item.id !== id),
            })
          }
        />

        <div id="errors" className={styles.error}>
          {errors.map((error, i) => (
            <div key={i}>- {error}</div>
          ))}
        </div>

        <div className={styles.submit}>
          <Button
            title={"Create"}
            type={"green"}
            loading={loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default NewUnits;
