import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  IonIcon,
  IonButton,
  IonLoading,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonList,
  useIonAlert,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import {
  addOutline,
  arrowForwardCircleOutline,
  calendarNumberOutline,
  caretBackOutline,
  caretForwardOutline,
  closeOutline,
  eyeOffOutline,
  starOutline,
  warningOutline,
} from "ionicons/icons";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import styles from "./add-showing.module.scss";
import Input from "../../../../components/Form/Input/input.component";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import Select from "../../../../components/Form/Select/select.component";
import DropdownContainer from "../../../../components/Form/DropdownContainer/dropdown-container.component";
import { ranges } from "../../../Tours/component/NewTour/component/utils";
import debounce from "lodash/debounce";
import SearchResult from "../../../../components/LocationSearch/components/search-result.component";
import {
  buildAddress,
  capitalize,
  getHighlightedLength,
  numberWithCommas,
} from "../../../../utils/functions";
import Map from "./components/map.component";
import MapListingCard from "../../../../components/Map/components/MapListingCard/map-listing.card.component";
import Button from "../../../../components/Form/Button/button.component";
import { updateToast } from "../../../../redux/ui/ui.actions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "@ionic/react/css/ionic-swiper.css";
import { ListingService } from "../../../../services/listingService";
import { FavoritesService } from "../../../../services/favoritesService";
import { ShowingsService } from "../../../../services/showingsService";
import { TourService } from "../../../../services/tourService";

const regex = /^[a-zA-Z]\d+/;

const AddShowing = ({ tour, updateTourItems, nextListingOrder }) => {
  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();
  const [listing, setListing] = useState();
  const [term, setTerm] = useState("");
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [status, setStatus] = useState();
  const [entryInfo, setEntryInfo] = useState("");
  const [note, setNote] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [toggleFavourites, setToggleFavourites] = useState(false);
  const [toggleBookedShowings, setToggleBookedShowings] = useState(false);
  const [toggleMultiple, setToggleMultiple] = useState(false);
  const [favLoading, setFavLoading] = useState(false);
  const [bookingsLoading, setBookingsLoading] = useState(false);
  const [favourites, setFavourites] = useState([]);
  const [bookedShowings, setBookedShowings] = useState([]);
  const [slideOpts, setSlideOpts] = useState({
    spaceBetween:
      window.innerWidth > 20 ? 2.1 : window.innerWidth > 1250 ? 0 : 20,
    slidesPerView:
      window.innerWidth > 1500 ? 2.1 : window.innerWidth > 1250 ? 1.65 : 3.1,
    initialSlide: 0,
    centeredSlides: true,
    loop: true,
  });
  const slidesRef = useRef();
  const {
    ref: refInput,
    isOpen: isOpenInput,
    setIsOpen: setIsOpenInput,
  } = useComponentVisible(false);

  const {
    ref: refFrom,
    isOpen: isOpenFrom,
    setIsOpen: setIsOpenFrom,
  } = useComponentVisible(false);

  const {
    ref: refTo,
    isOpen: isOpenTo,
    setIsOpen: setIsOpenTo,
  } = useComponentVisible(false);

  const {
    ref: refStatus,
    isOpen: isOpenStatus,
    setIsOpen: setIsOpenStatus,
  } = useComponentVisible(false);

  const handleTermChange = (value, name) => {
    setTerm(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce((term) => fetchResults(term), 500),
    []
  );

  const fetchResults = async (term) => {
    try {
      setLoading(true);
      const {listings} = await ListingService.searchListingsAndLocations(term, true);
      setResults(listings);
    } catch (err) {
      setResults([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (term.length && !toggleMultiple) {
      setLoading(true);
      debouncedFetch(term);
    }
  }, [term, debouncedFetch, toggleMultiple]);

  useEffect(() => {
    if (term && !loading) setIsOpenInput(true);
  }, [term, loading, setIsOpenInput]);

  useEffect(() => {
    const fetchFavourites = async () => {
      try {
        setFavLoading(true);
        const favs = await FavoritesService.getFavorites();
        setFavourites(favs);
      } catch (err) {
        dispatch(
          updateToast({
            open: true,
            message: "Something went wrong!",
            type: "error",
          })
        );
      } finally {
        setFavLoading(false);
      }
    };

    const fetchBookedShowings = async () => {
      try {
        setBookingsLoading(true);
        const bookings = await ShowingsService.getShowingsByDate(tour.date);
        setBookedShowings(bookings);
      } catch (err) {
        dispatch(
          updateToast({
            open: true,
            message: "Something went wrong!",
            type: "error",
          })
        );
      } finally {
        setBookingsLoading(false);
      }
    };

    fetchFavourites();
    fetchBookedShowings();
  }, [dispatch, tour]);

  const reset = () => {
    setStartTime(null);
    setEndTime(null);
    setListing(null);
    setTerm("");
    setStatus(null);
    setEntryInfo("");
    setNote();
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);

    try {
      if (toggleMultiple) {
        let order = nextListingOrder;
        const showings = [];
        for (let item of listing) {
          const showing = await TourService.addShowingToTour(tour, {
            mlsNumber: item.mlsNumber,
            status: status,
            entryInfo: entryInfo,
            showingNote: note,
            startTime: startTime,
            endTime: endTime,
            order: order,
          });
          showings.push(showing);
          order++;
        }
        updateTourItems(showings);
        reset();
      } else {
        const showing = await TourService.addShowingToTour(tour, {
          mlsNumber: listing.mlsNumber,
          status: status,
          entryInfo: entryInfo,
          showingNote: note,
          startTime: startTime,
          endTime: endTime,
          order: nextListingOrder,
        });
        updateTourItems([showing]);
        reset();
      }
    } catch (err) {
      console.log(err);
    }
    setSubmitLoading(false);
  };

  const compareListings = (a, b) => {
    const aMatch = getHighlightedLength(
      `${a.address.city} - ${buildAddress(a.address)}`,
      term
    );

    const bMatch = getHighlightedLength(
      `${b.address.city} - ${buildAddress(b.address)}`,
      term
    );

    if (aMatch > bMatch) return -1;
    if (aMatch === bMatch) return 0;
    return 1;
  };

  const handlePrevSlide = async () => {
    const swiper = await slidesRef.current.getSwiper();
    if (swiper) await swiper.slidePrev();
  };

  const handleNextSlide = async () => {
    const swiper = await slidesRef.current.getSwiper();
    if (swiper) await swiper.slideNext();
  };

  useEffect(() => {
    const listener = window.addEventListener("resize", () => {
      if (window.innerWidth > 1500) {
        setSlideOpts({
          ...slideOpts,
          slidesPerView: 2.1,
          spaceBetween: 20,
        });
      } else if (window.innerWidth > 1225) {
        setSlideOpts({
          ...slideOpts,
          slidesPerView: 1.65,
          spaceBetween: 0,
        });
      } else {
        setSlideOpts({
          ...slideOpts,
          slidesPerView: 3.1,
          spaceBetween: 20,
        });
      }
    });

    return () => window.removeEventListener("resize", listener);
  }, [slideOpts]);

  const handleMultiple = () => {
    setTerm("");
    setListing(null);
    setEntryInfo("");
    setStatus(null);
    setNote();
    setStartTime(null);
    setEndTime(null);
    setToggleMultiple(!toggleMultiple);
  };

  const addMultipleListings = async () => {
    try {
      setLoading(true);
      const mlsNumbers = term.replaceAll(" ", "").split(",");
      const listings = [];
      let error = false;
      for (let mlsNumber of mlsNumbers) {
        if (regex.test(mlsNumber)) {
          let listing = await ListingService.getListingByMlsNumber(mlsNumber);
          listings.push(listing);
        } else {
          error = true;
          dispatch(
            updateToast({
              open: true,
              message: "Invalid MLS Number.",
              type: "error",
            })
          );
          break;
        }
      }
      if (listings.length > 0 && !error) setListing(listings);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          message: "Something went wrong!",
          type: "error",
        })
      );
    } finally {
      setTerm("");
      setLoading(false);
    }
  };

  const addFromFavourites = async (mls) => {
    reset();
    const listing = await ListingService.getListingByMlsNumber(mls);
    if (listing) {
      setListing(listing);
      setTerm(mls);
    }
    setToggleFavourites(false);
  };

  const addBookedShowing = (showing) => () => {
    reset();
    setStartTime(showing.startTime);
    setEndTime(showing.endTime);
    setListing(showing.listing);
    setStatus("confirmed");
    setTerm(showing.listing.mlsNumber);

    const instructionItems = showing.instructions.split("\n");
    const codeText = instructionItems.find((item) => item.includes("Code:"));
    if (codeText) {
      const code = codeText.split(":")[1].trim();
      setEntryInfo(code);
    }

    setNote(showing.instructions);
    setToggleBookedShowings(false);

    presentAlert({
      header: "Verify showing details",
      message:
        "Please verify the showing details and entry information. Make sure to remove any sensitive information from the client notes.",
      buttons: [
        {
          text: "OK",
          role: "confirm",
        },
      ],
    });
  };

  const renderBookedShowings = () => {
    return bookedShowings.map((showing) => {
      const listing = showing.listing;
      const isAlreadyAdded = tour.tourItems.items.find(
        (item) => item.mlsNumber === listing.mlsNumber
      );
      const instructionItems = showing.instructions.split("\n");

      return (
        <IonCard key={showing.id}>
          <IonCardHeader>
            <IonCardTitle>{buildAddress(listing.address)}</IonCardTitle>
            <IonCardSubtitle>MLS#: {listing.mlsNumber}</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem lines="none" color="light">
                <IonLabel>Start Time</IonLabel>
                <IonLabel>{showing.startTime}</IonLabel>
              </IonItem>
              <IonItem lines="none" color="light">
                <IonLabel>End Time</IonLabel>
                <IonLabel>{showing.endTime}</IonLabel>
              </IonItem>
              <IonItem lines="none" color="light">
                <IonLabel style={{ fontWeight: "bold" }}>
                  Instructions:
                </IonLabel>
              </IonItem>
              <IonGrid>
                {instructionItems.map((item, index) => (
                  <IonRow key={index}>
                    {instructionItems
                      .slice(index, index + 2)
                      .map((subItem, subIndex) => (
                        <IonCol size="6" key={subIndex}>
                          <IonLabel>{subItem}</IonLabel>
                        </IonCol>
                      ))}
                  </IonRow>
                ))}
              </IonGrid>
            </IonList>
            <IonButton
              className={styles.button}
              shape="round"
              expand="full"
              disabled={isAlreadyAdded}
              onClick={addBookedShowing(showing)}>
              <IonIcon icon={addOutline} />
              Add
            </IonButton>
          </IonCardContent>
        </IonCard>
      );
    });
  };

  return (
    <CardContainer>
      <IonLoading isOpen={submitLoading} />
      <div className={styles.showing} id="add-showing">
        <div className={styles.header}>
          <div className={styles.title}>
            <IonIcon icon={addOutline} />{" "}
            <span>{!toggleMultiple ? "Add a showing" : "Add multiple"}</span>
            <span className={styles.multiple} onClick={handleMultiple}>
              {toggleMultiple ? "Add Single" : "Add Multiple"}
            </span>
          </div>
          <div className={styles.tag}>
            <IonIcon icon={eyeOffOutline} className={styles.icon} />
            <span>not visible to client</span>
          </div>
        </div>
        <div className={styles.inputs}>
          <div ref={refInput} className={styles.container}>
            {listing ? (
              <div className={styles.selected}>
                <span className={styles.label}>
                  {toggleMultiple ? (
                    `${listing.map((item) => `${item.mlsNumber}`)}`
                  ) : (
                    <>
                      Listing MLS #: <b>{listing.mlsNumber}</b>
                    </>
                  )}
                </span>
                <IonIcon
                  icon={closeOutline}
                  className={styles.icon}
                  onClick={() => {
                    setListing(null);
                    setTerm("");
                  }}
                />
              </div>
            ) : (
              <div className={styles.buttons}>
                <Input
                  style={{
                    flex: 1,
                    fontSize: toggleMultiple ? "1.2rem" : "1.4rem",
                  }}
                  label={
                    toggleMultiple
                      ? "MLS Numbers (Separate with comma)"
                      : "location or MLS #"
                  }
                  placeholder={
                    toggleMultiple
                      ? "Separate MLS numbers with comma"
                      : "location or MLS #"
                  }
                  mode="search"
                  value={term}
                  search
                  clear={() => {
                    setTerm("");
                    setListing(null);
                  }}
                  onChange={handleTermChange}
                  loading={loading}
                  onClick={() => {
                    if (term && results) setIsOpenInput(true);
                  }}
                  disabled={tour.status === "archived"}
                />
                {toggleMultiple && (
                  <Button
                    title="Add"
                    type="green"
                    shape="block"
                    border
                    onClick={addMultipleListings}
                  />
                )}
              </div>
            )}

            {isOpenInput && !listing && !toggleMultiple && (
              <DropdownContainer
                open={term && !loading}
                style={{ maxHeight: "22rem" }}>
                {!results && (
                  <div className={styles.noResult}>
                    <IonIcon icon={warningOutline} className={styles.icon} />
                    No listing found. Try revising your search.
                  </div>
                )}
                {results &&
                  results.sort(compareListings).map((listing) => (
                    <SearchResult
                      key={listing.mlsNumber}
                      item={listing}
                      term={term}
                      type="listing"
                      onClick={() => {
                        setToggleFavourites(false);
                        setToggleBookedShowings(false);
                        setListing(listing);
                        setTerm(listing.mlsNumber);
                        setIsOpenInput(false);
                      }}
                    />
                  ))}
              </DropdownContainer>
            )}
          </div>
          {!toggleMultiple && (
            <>
              <div className={styles.favourites}>
                <IonItem
                  className={`${styles.item} ${styles.favourite}`}
                  detail
                  detailIcon={
                    toggleBookedShowings ? closeOutline : calendarNumberOutline
                  }
                  lines="none"
                  onClick={() =>
                    setToggleBookedShowings(
                      (toggleBookedShowings) => !toggleBookedShowings
                    )
                  }>
                  <IonLabel>
                    {toggleBookedShowings
                      ? "Close booked showings"
                      : "Or choose from your booked showings"}
                  </IonLabel>
                </IonItem>
                {toggleBookedShowings && (
                  <>
                    {bookingsLoading ? (
                      <IonSkeletonText
                        animated
                        style={{ height: "8rem", padding: "0 1.5rem" }}
                      />
                    ) : bookedShowings && bookedShowings.length > 0 ? (
                      <div className={styles.bookedShowings}>
                        {renderBookedShowings()}
                      </div>
                    ) : (
                      <div className={styles.noFav}>
                        You have no booked showings for the current tour's date.
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={styles.favourites}>
                <IonItem
                  className={`${styles.item} ${styles.favourite}`}
                  detail
                  detailIcon={toggleFavourites ? closeOutline : starOutline}
                  lines="none"
                  onClick={() =>
                    setToggleFavourites((toggleFavourites) => !toggleFavourites)
                  }>
                  <IonLabel>
                    {toggleFavourites
                      ? "Close favourites"
                      : "Or choose from your favourites"}
                  </IonLabel>
                </IonItem>
                {toggleFavourites && (
                  <>
                    {favLoading ? (
                      <IonSkeletonText
                        animated
                        style={{ height: "8rem", padding: "0 1.5rem" }}
                      />
                    ) : favourites && favourites.length > 0 ? (
                      <div className={styles.slidesContainer}>
                        <IonIcon
                          icon={caretBackOutline}
                          className={styles.slidesIcon}
                          onClick={handlePrevSlide}
                        />
                        <Swiper options={slideOpts} ref={slidesRef}>
                          {favourites.map((fav) => (
                            <SwiperSlide key={fav.id}>
                              <MapListingCard
                                listing={JSON.parse(fav.listing)}
                                webAddShowing
                                addShowing
                                handleAddShowing={() =>
                                  addFromFavourites(fav.mlsNumber)
                                }
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                        <IonIcon
                          icon={caretForwardOutline}
                          className={styles.slidesIcon}
                          onClick={handleNextSlide}
                        />
                      </div>
                    ) : (
                      <div className={styles.noFav}>
                        Search the listings or review your saved searches to
                        find new favourites.
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          {listing && (
            <div className={styles.times}>
              <div ref={refFrom} className={styles.container}>
                <Select
                  label="Start time"
                  title={startTime ? startTime : "Start time"}
                  style={{ height: "4rem", width: "100%" }}
                  buttonStyle={{ height: "100%" }}
                  open={isOpenFrom}
                  onClick={() => setIsOpenFrom(!isOpenFrom)}
                />
                <DropdownContainer
                  open={isOpenFrom}
                  style={{
                    position: "absolute",
                    top: "auto",
                    left: "auto",
                    // width: "20rem",
                    height: "15rem",
                  }}>
                  {ranges.map((range, index) => (
                    <div
                      key={index}
                      className={styles.range}
                      onClick={() => {
                        setStartTime(
                          moment(range, "HH:mm:ss a").format("HH:mm")
                        );
                        setIsOpenFrom(false);
                      }}>
                      {range}
                    </div>
                  ))}
                </DropdownContainer>
              </div>
              <div className={styles.hyphen}>-</div>
              <div ref={refTo} className={styles.container}>
                <Select
                  label="End time"
                  title={endTime ? endTime : "End time"}
                  style={{ height: "4rem", width: "100%" }}
                  buttonStyle={{ height: "100%" }}
                  open={isOpenTo}
                  onClick={() => setIsOpenTo(!isOpenTo)}
                />
                <DropdownContainer
                  open={isOpenTo}
                  style={{
                    position: "absolute",
                    top: "auto",
                    left: "auto",
                    // width: "20rem",
                    height: "15rem",
                  }}>
                  {ranges.map((range, index) => (
                    <div
                      key={index}
                      className={styles.range}
                      onClick={() => {
                        setEndTime(moment(range, "HH:mm:ss a").format("HH:mm"));
                        setIsOpenTo(false);
                      }}>
                      {range}
                    </div>
                  ))}
                </DropdownContainer>
              </div>
            </div>
          )}
          {listing && (
            <div ref={refStatus} className={styles.container}>
              <Select
                label="Showing status"
                title={status ? capitalize(status) : `Showing status`}
                style={{ height: "4rem", width: "100%" }}
                buttonStyle={{ height: "100%" }}
                open={isOpenStatus}
                onClick={() => setIsOpenStatus(!isOpenStatus)}
              />
              <DropdownContainer
                open={isOpenStatus}
                style={{
                  position: "absolute",
                  top: "auto",
                  left: "auto",
                  // width: "20rem",
                  height: "15rem",
                }}>
                <div>
                  <div
                    onClick={() => {
                      setStatus("requested");
                      setIsOpenStatus(false);
                    }}
                    className={styles.range}>
                    Requested
                  </div>
                  <div
                    onClick={() => {
                      setStatus("confirmed");
                      setIsOpenStatus(false);
                    }}
                    className={styles.range}>
                    Confirmed
                  </div>
                  <div
                    onClick={() => {
                      setStatus("completed");
                      setIsOpenStatus(false);
                    }}
                    className={styles.range}>
                    Completed
                  </div>
                  <div
                    onClick={() => {
                      setStatus("skipped");
                      setIsOpenStatus(false);
                    }}
                    className={styles.range}>
                    Skipped
                  </div>
                  <div
                    onClick={() => {
                      setStatus("cancelled");
                      setIsOpenStatus(false);
                    }}
                    className={styles.range}>
                    Cancelled
                  </div>
                  <div
                    onClick={() => {
                      setStatus("rejected");
                      setIsOpenStatus(false);
                    }}
                    className={styles.range}>
                    Rejected
                  </div>
                </div>
              </DropdownContainer>
            </div>
          )}
          {listing && (
            <div className={styles.entryContainer}>
              <Input
                name="entryInfo"
                label="Lockbox and entry info"
                // showLabel={false}
                placeholder="Lockbox and entry info"
                value={entryInfo}
                onChange={(value, name) => setEntryInfo(value)}
              />
            </div>
          )}
          {listing && (
            <div className={styles.container}>
              <div className={styles.textareaLabel}>
                Showing notes for client
              </div>
              <textarea
                className={styles.textarea}
                name="note"
                placeholder="Showing notes. eg. Year built, offer date, etc."
                value={note}
                onChange={(event) => {
                  setNote(event.target.value);
                }}></textarea>
            </div>
          )}
        </div>
        {listing && !toggleMultiple && (
          <div className={`${styles.map} ${listing && styles.mapTransparent}`}>
            <Map location={listing.map} />
          </div>
        )}
        {listing && (
          <div className={styles.details}>
            {!toggleMultiple && (
              <>
                <div>
                  <span>Address: </span>
                  <span className={styles.bold}>
                    {buildAddress(listing.address)}
                  </span>
                </div>
                <div>
                  <span>MLS #: </span>
                  <span className={styles.bold}>
                    {" "}
                    {listing.mlsNumber}{" "}
                    <IonIcon
                      icon={arrowForwardCircleOutline}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(`/listings/${listing.mlsNumber}`, "_blank")
                      }
                    />{" "}
                  </span>
                </div>
                <div>
                  <span>Price: </span>
                  <span className={styles.bold}>
                    ${numberWithCommas(listing.listPrice)}
                  </span>
                </div>
                <div>
                  <span>Beds: </span>
                  <span className={styles.bold}>
                    {listing.details.numBedrooms}
                  </span>{" "}
                  <span> | </span>
                  <span>Baths: </span>
                  <span className={styles.bold}>
                    {listing.details.numBathrooms}
                  </span>{" "}
                  <span> | </span>
                  <span>Parking: </span>
                  <span className={styles.bold}>
                    {" "}
                    {listing.details.numParkingSpaces !== "0.0"
                      ? listing.details.numParkingSpaces
                      : listing.details.numGarageSpaces !== "0.0"
                      ? listing.details.numGarageSpaces
                      : "-"}
                  </span>
                </div>
                <div>
                  <span>Property type: </span>
                  <span className={styles.bold}>
                    {listing.details.propertyType}
                  </span>
                </div>
                <div>
                  <span>Listing brokerage: </span>
                  <span className={styles.bold}>
                    {listing.office.brokerageName}
                  </span>
                </div>
                <div>
                  <span>Listing agent: </span>
                  <span className={styles.bold}>
                    {listing.agents.flat(", ")}
                  </span>
                </div>
              </>
            )}

            <IonButton
              className={styles.button}
              shape="round"
              expand="block"
              disabled={!startTime || !endTime || !status}
              onClick={handleSubmit}>
              <IonIcon icon={addOutline} />
              {toggleMultiple ? "Add showings to tour" : "Add showing to tour"}
            </IonButton>
          </div>
        )}
      </div>
    </CardContainer>
  );
};

export default AddShowing;
