import awsExports from "../aws-exports";
import { ListingsSortOptions } from "../enums/Listings/ListingsSortOptions.enum";
import {SortOption} from "../types/shared/SortOption.type";

export const GeneralConstants = {
  REST_API_NAME: "ettieREST",
  S3_BUCKET_NAME: awsExports.aws_user_files_s3_bucket,
  S3_BUCKET_REGION: awsExports.aws_user_files_s3_bucket_region,
}

export const TourStatus = [
  { title: "Draft" },
  { title: "Pending" },
  { title: "Ready" },
  { title: "Completed" },
  { title: "Cancelled" },
];

export const APP_BUNDLE_ID = "com.aecoinnovationlab.aecorealty";
export const APP_SCHEME = "aecorealty://aecorn.ca/";

export const storeUrls = {
  apple: "https://apps.apple.com/ca/app/aecorn/id1578020676",
  android:
    "https://play.google.com/store/apps/details?id=com.aecoinnovationlab.aecorealty&hl=en_CA&gl=US",
};

export const storeIds = {
  apple: "1578020676",
  android: "com.aecoinnovationlab.aecorealty",
};

export const PreConstructionAmenities = {
  pool: "Pool",
  gym: "Gym",
  petWash: "Pet Wash",
  dogPark: "Dog Park",
  concierge: "Concierge",
  partyRoom: "Party Room",
  studyRoom: "Study Room",
  gameRoom: "Game Room",
  flexRoom: "Flex Room",
};

export const TourRoles = {
  primaryagent: "Primary Agent",
  showingagent: "Showing Agent",
  client: "Client",
  guest: "Additional Guest",
};

export const ListingsSortItems: SortOption[] = [
  {
    label: "Date",
    value: ListingsSortOptions.CREATED_ON,
  },
  {
    label: "Price",
    value: ListingsSortOptions.LIST_PRICE,
  },
  {
    label: "Beds",
    value: ListingsSortOptions.BEDS,
  },
  {
    label: "Baths",
    value: ListingsSortOptions.BATHS,
  },
  {
    label: "Sqft",
    value: ListingsSortOptions.SQFT,
  },
];