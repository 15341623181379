import { takeLatest, call, put, all } from "redux-saga/effects";
import { UIActionTypes } from "./ui.types";
import { fetchOfferFailure, fetchOfferSuccess, fetchRateSuccess, fetchRateFailure } from "./ui.actions";
import { API } from "aws-amplify";

const getOffer = async () => {
  try {
    const res = await API.get("ettieREST", "/offer", "");

    return {
      sale: +res.offer.M.sale.N,
      lease: +res.offer.M.lease.N,
      leasePercentage: +res.offer.M.leasePercentage.N,
      salePercentage: +res.offer.M.salePercentage.N,
    };
  } catch (err) {
    throw Error("Something went wrong!");
  }
};

const getRate = async () => {
  try {
    const res = await API.get("ettieREST", "/rate", {});
    return +res.rate;
  } catch (err) {
    throw Error("Something went wrong!");
  }
};

export function* fetchOfferAsync() {
  try {
    const rates = yield call(getOffer);
    yield put(fetchOfferSuccess(rates));
  } catch (error) {
    yield put(fetchOfferFailure("Something went wrong!"));
  }
}

export function* fetchRateAsync() {
  try {
    const rate = yield call(getRate);
    yield put(fetchRateSuccess(rate));
  } catch (error) {
    yield put(fetchRateFailure("Something went wrong!"));
  }
}


export function* fetchOfferStart() {
  yield takeLatest(UIActionTypes.FETCH_OFFER_START, fetchOfferAsync);
}

export function* fetchRateStart() {
  yield takeLatest(UIActionTypes.FETCH_RATE_START, fetchRateAsync);
}

export function* uiSagas() {
  yield all([call(fetchOfferStart), call(fetchRateStart)]);
}
