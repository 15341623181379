import { useState } from "react";
import { useSelector } from "react-redux";
import { updateClient } from "../../../amplify/graphql.utils";
import useComponentVisible from "../../../hooks/useComponentVisible";
import Button from "../../Form/Button/button.component";
import DropdownContainer from "../../Form/DropdownContainer/dropdown-container.component";
import Select from "../../Form/Select/select.component";
import { changeUnits } from "../../../data/customModal";
import styles from "./edit-units-modal.module.scss";

import { capitalize } from "lodash";
import { selectCurrentClient } from "../../../redux/client/client.selectors";

const EditUnitsModal = ({ setType, language }) => {
  const client = useSelector(selectCurrentClient);
  const [loading, setLoading] = useState(false);
  let measures = ["Imperial", "Metric"];
  const { isOpen, setIsOpen } = useComponentVisible(false);
  const [measure, setMeasure] = useState(capitalize(client.measurement));

  const updateHandler = async () => {
    setLoading(true);
    await updateClient({
      id: client.id,
      updates: { measurement: measure.toLowerCase() },
    });
    setLoading(false);
    setType("updatedUnits");
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>{changeUnits[language].header}</div>
      <div className={styles.subtitle}>{changeUnits[language].subtitle}</div>

      <div className={styles.content}>
        <div className={styles.label}>{changeUnits[language].units}</div>
        <div>
          <Select
            label=""
            title={measure}
            open={isOpen}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
            style={{ width: "12rem" }}
          />
          <div className={styles.dropdown}>
            <DropdownContainer open={isOpen}>
              <div>
                {measures.map((measure, index) => (
                  <div
                    key={index}
                    className={styles.currency}
                    onClick={() => {
                      setIsOpen(false);
                      setMeasure(measure);
                    }}
                  >
                    {measure}
                  </div>
                ))}
              </div>
            </DropdownContainer>
          </div>
        </div>
      </div>
      <Button
        title={changeUnits[language].button}
        type="green"
        style={{
          height: "5rem",
          width: "40rem",
          margin: "0 auto",
          border: "1px solid #1F7A60",
        }}
        onClick={updateHandler}
        loading={loading}
      />
    </div>
  );
};

export default EditUnitsModal;
