import { graphqlOperation, API, Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import awsExports from "../aws-exports-dev";
import {
  getAgent,
  getClient,
  getTour,
  listAgents,
  listClients,
  listSearches,
  getTourItem,
  getSearch,
  getFavouriteByMlsNumber,
  listFavourites,
  listFeatureds as listFeaturedItems,
  listHomeCards as listHomeCardItems,
  getBuilder,
  listBuilders,
  listArchitects,
  getArchitect,
  listInteriorDesigners,
  getInteriorDesigner,
  listProjects,
  listAmenities,
  getPreFavouriteByProjectId,
  getProject,
  listPreFavourites,
  listProjectUnits,
  getProjectUnit,
  listProjectAmenities,
  listTours,
  getUser,
  listUsers,
  listTourItems,
  getUserTourByUserAndTour,
  listUserTours,
  getShowingsByDate,
} from "../graphql/queries";
import _, { at } from "lodash";
import {
  createAmenity,
  deleteAmenity,
  updateClient as update,
  updateAgent,
  createBuilder,
  updateFavourite,
  createSearch,
  deleteSearch,
  updateSearch,
  createTour,
  createProjectAmenities,
  updateTour as updateTourItem,
  updateTourItem as updateTourListing,
  createTourItem,
  deleteTourItem,
  createNote,
  updateNote,
  createReview,
  updateReview,
  createPhoto,
  deletePhoto,
  createRecording,
  deleteRecording,
  createProfile,
  updateProfile,
  updateBuilder,
  createArchitect,
  updateArchitect,
  createInteriorDesigner,
  updateInteriorDesigner,
  createProject,
  createPreFavourite,
  deletePreFavourite,
  updateProject,
  createProjectUnit,
  updateProjectUnit,
  updateAmenity,
  deleteProjectAmenities,
  updateUser,
  createUserTour,
  deleteUserTour,
  updateNotification,
  deleteNote,
  deleteReview,
  updateUserTour,
} from "../graphql/mutations";
import { store } from "../redux/store";
import { fetchClientStart } from "../redux/client/client.actions";
import {
  createSearch as createRepliersSearch,
  removeSearch as removeRepliersSearch,
} from "../api/repliers";
import gql from "graphql-tag";
import moment from "moment";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import { ShareStatus } from "../models";

export const getHomeItems = async () => {
  try {
    const isAuthenticated = store.getState().user.currentUser;
    const {data: {listFeatureds}} = await API.graphql({query: listFeaturedItems, authMode: isAuthenticated ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM});
    const {data: {listHomeCards}} = await API.graphql({query: listHomeCardItems, authMode: isAuthenticated ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM});
    return {cards: listHomeCards.items, featureds: listFeatureds.items};
  } catch (err) {
    return { cards: [], featureds: [] };
  }
};

export const updateClient = async ({ id, updates }) => {
  try {
    const {
      data: { updateUser: updatedUser },
    } = await API.graphql(
      graphqlOperation(updateUser, { input: { id, ...updates } })
    );

    if (
      updates.email ||
      updates.phone ||
      updates.givenName ||
      updates.familyName
    ) {
      const {
        data: { listTours: userTours },
      } = await API.graphql({ query: listTours });

      const tours = userTours.items;

      for (let i = 0; i < tours.length; i++) {
        const users = tours[i].users.items;
        for (let j = 0; j < users.length; j++) {
          if (users[j].id === id) {
            await API.graphql(
              graphqlOperation(updateUserTour, {
                input: {
                  id: users[j].id,
                  email: updatedUser.email,
                  phone: updatedUser.phone,
                  givenName: updatedUser.givenName,
                  familyName: updatedUser.familyName,
                  name: `${updatedUser.givenName} ${updatedUser.familyName}`.toLowerCase(),
                  displayName: `${updatedUser.givenName} ${updatedUser.familyName}`,
                },
              })
            );
          }
        }
      }
    }

    store.dispatch(fetchClientStart());
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateUserNotification = async (updates) => {
  try {
    await API.graphql({
      query: updateNotification,
      variables: { input: updates },
    });
    store.dispatch(fetchClientStart());
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getSearchItem = async (id) => {
  const { data } = await API.graphql(graphqlOperation(getSearch, { id }));

  return data.getSearch;
};

export const getSearchItems = async () => {
  const { data } = await API.graphql(graphqlOperation(listSearches));

  return data.listSearches.items;
};

export const getPreFavouriteItems = async () => {
  const { data } = await API.graphql(graphqlOperation(listPreFavourites));

  return data.listPreFavourites.items;
};

export const getFavouriteItem = async (mlsNumber, isPreConstruction) => {
  if (isPreConstruction) {
    const { data } = await API.graphql(
      graphqlOperation(getPreFavouriteByProjectId, { projectId: mlsNumber })
    );
    return data.getPreFavouriteByProjectId.items[0];
  } else {
    const { data } = await API.graphql(
      graphqlOperation(getFavouriteByMlsNumber, { mlsNumber })
    );

    return data.getFavouriteByMlsNumber.items[0];
  }
};

export const updateFavouriteListing = async (listing) => {
  const favourite = await getFavouriteItem(listing.mlsNumber);
  if (favourite) {
    const { data } = await API.graphql(
      graphqlOperation(updateFavourite, {
        input: {
          id: favourite.id,
          listing: JSON.stringify(listing),
          notification:
            listing.status === "U" && favourite.notification
              ? false
              : favourite.notification,
        },
      })
    );

    return data;
  }
  return;
};

export const updateNotifications = async (updates) => {
  await API.graphql(graphqlOperation(updateNotifications, { input: updates }));
};

export const addSearch = async (clientId, clientRepliersId, search) => {
  const repliersID = await createRepliersSearch(
    clientRepliersId,
    search.filters
  );

  const { data } = await API.graphql(
    graphqlOperation(createSearch, {
      input: {
        name: search.searchName,
        value: JSON.stringify({
          filters: search.filters,
          extra: search.extraFilters,
          locations: search.mapLocations,
          drawMode: search.drawMode.active,
          schoolMode: search.schoolMode.active,
          schoolName:
            search.schoolMode.active && search.schoolMode.schools.length
              ? search.schoolMode.schools.length > 1
                ? "Multiple"
                : search.schoolMode.schools[0].name
              : undefined,
          schoolId:
            search.schoolMode.active && search.schoolMode.schools.length
              ? search.schoolMode.schools.length > 1
                ? undefined
                : search.schoolMode.schools[0].id
              : undefined,
          schools: search.schoolMode.active
            ? search.schoolMode.schools?.map((s) => {
                return { id: s.id, name: s.name };
              })
            : null,
        }),
        repliersID,
        notification: search.alert,
      },
    })
  );

  return data;
};

export const updateSavedSearch = async ({ id, notification, name }) => {
  const { data } = await API.graphql(
    graphqlOperation(updateSearch, {
      input: { id, name, notification },
    })
  );

  return data;
};

export const removeSearch = async (search) => {
  await removeRepliersSearch(search.repliersID);

  const { data } = await API.graphql(
    graphqlOperation(deleteSearch, {
      input: { id: search.id },
    })
  );

  return data;
};

export const fetchTours = async () => {
  let token;
  let data = [];

  try {
    const {
      data: { listTours: tours },
    } = await API.graphql({
      query: listTours,
      variables: { nextToken: token },
    });

    data = [...tours.items];
    token = tours.nextToken;

    while (token) {
      const {
        data: { listTours: tours },
      } = await API.graphql({
        query: listTours,
        variables: { nextToken: token },
      });

      data = [...data, ...tours.items];
      token = tours.nextToken;
    }
    return data;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const getSingleTour = async (tourId) => {
  const { data } = await API.graphql(graphqlOperation(getTour, { id: tourId }));
  return data.getTour;
};

export const getSingleTourItem = async (tourItemId) => {
  const { data } = await API.graphql(
    graphqlOperation(getTourItem, { id: tourItemId })
  );
  return data.getTourItem;
};

export const fetchTourItems = async (tourId) => {
  const { data } = await API.graphql({
    query: listTourItems,
    variables: { filter: { tourId: { eq: tourId } } },
  });
  return data.listTourItems.items;
};

export const addTour = async (tour) => {
  const creator = store.getState().client.currentClient.id;
  try {
    const { data: createTourData } = await API.graphql(
      graphqlOperation(createTour, {
        input: {
          creator,
          status: "draft",
          shared: "unshared",
          title: tour.title,
          privateNote: tour.privateNote,
          agents: [tour.agent.id],
        },
      })
    );

    const newTour = createTourData.createTour;

    await API.graphql({
      query: createUserTour,
      variables: {
        input: {
          tourId: newTour.id,
          userId: tour.agent.userAgentId,
          name: tour.agent.name,
          displayName: tour.agent.displayName,
          givenName: tour.agent.givenName,
          familyName: tour.agent.familyName,
          email: tour.agent.email,
          phone: tour.agent.phone,
          role: "primaryagent",
          seen: true,
        },
      },
    });

    const { data: tourData } = await API.graphql({
      query: getTour,
      variables: { id: newTour.id },
    });

    return tourData.getTour;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const updateTour = async (updates) => {
  try {
    const { data } = await API.graphql({
      query: updateTourItem,
      variables: { input: updates },
    });
    return data.updateTour;
  } catch (err) {
    throw Error(err);
  }
};

export const clearTourAttendees = async (attendees, tourId) => {
  try {
    await updateTour({ id: tourId, agents: [], clients: [] });

    attendees.forEach(async (attendee) => {
      await API.graphql({
        query: deleteUserTour,
        variables: { input: { id: attendee.id } },
      });
    });
  } catch (err) {
    throw Error(err);
  }
};

export const updateTourAttendees = async (attendees, tourId) => {
  const user = store.getState().client.currentClient;
  try {
    const currentTourUsers = await API.graphql({
      query: listUserTours,
      variables: { filter: { tourId: { eq: tourId } } },
    });
    const currentAttendees = currentTourUsers.data.listUserTours.items;
    const tourUsers = attendees.map((a) => a.userId);
    const agents = attendees.filter(
      (a) => a.role === "primaryagent" || a.role === "showingagent"
    );
    const clients = attendees.filter(
      (a) => a.role === "client" || a.role === "guest"
    );

    await updateTour({
      id: tourId,
      agents: agents.map((a) => a.userId),
      clients: clients.map((c) => c.userId),
    });

    const filteredAttendees = [];

    for (let i = 0; i < currentAttendees.length; i++) {
      const attendee = currentAttendees[i];
      if (
        !attendees.find(
          (a) => a.userId === attendee.userId && a.role === attendee.role
        )
      ) {
        console.log("delete user tour", attendee.role);
        await API.graphql({
          query: deleteUserTour,
          variables: { input: { id: attendee.id } },
        });
      } else {
        filteredAttendees.push(attendee);
      }
    }

    for (let i = 0; i < attendees.length; i++) {
      const attendee = attendees[i];
      const userTourExists = filteredAttendees.find(
        (a) => a.userId === attendee.userId && a.role === attendee.role
      );
      if (userTourExists) {
        console.log("update user tour", attendee.role);
        await API.graphql({
          query: updateUserTour,
          variables: {
            input: {
              id: userTourExists.id,
              tourUsers: tourUsers,
              ...attendee,
            },
          },
        });
      } else {
        console.log("create user tour", attendee.role);
        await API.graphql({
          query: createUserTour,
          variables: {
            input: {
              tourId: tourId,
              tourUsers: tourUsers,
              seen: attendee.userId === user.id,
              ...attendee,
            },
          },
        });
      }
    }

    const { data } = await API.graphql({
      query: getTour,
      variables: { id: tourId },
    });
    return data.getTour;
  } catch (err) {
    console.log(err);
    throw Error(err);
  }
};

export const addListingToTour = async ({
  tourId,
  mlsNumber,
  status,
  entryInfo,
  showingNote,
  startTime,
  endTime,
  order,
}) => {
  const creator = store.getState().client.currentClient.id;
  const { data } = await API.graphql(
    graphqlOperation(createTourItem, {
      input: {
        creator,
        tourId,
        status,
        entryInfo,
        showingNote,
        mlsNumber,
        startTime,
        endTime,
        order,
      },
    })
  );

  return data.createTourItem;
};

export const updateTourListingOrder = async ({ id, order }) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(updateTourListing, { input: { id, order } })
    );

    return data.updateTourItem;
  } catch (err) {
    console.log(err);
  }
};

export const updateTourListingItem = async (updates) => {
  console.log(updates);
  const { data } = await API.graphql(
    graphqlOperation(updateTourListing, { input: { ...updates } })
  );

  return data.updateTourItem;
};

export const removeTourListingItem = async (tourItem) => {
  // Remove all photos and recordings from blob storage

  for (let i = 0; i < tourItem.photos.items.length; i++) {
    const photo = tourItem.photos.items[i];
    await Storage.remove(photo.path);
  }

  for (let i = 0; i < tourItem.recordings.items.length; i++) {
    const recording = tourItem.recordings.items[i];
    await Storage.remove(recording.path);
  }

  await API.graphql(
    graphqlOperation(deleteTourItem, { input: { id: tourItem.id } })
  );
};

export const getAgents = async (search) => {
  let filter = {
    and: [{ role: { eq: "agent" } }, { or: [] }],
  };
  let allAgents = [];
  let token;
  if (search) {
    filter.and[1].or.push(
      { givenName: { contains: search } },
      { familyName: { contains: search } },
      { name: { contains: String(search).toLowerCase() } },
      { email: { contains: search } }
    );
  }

  try {
    const { data } = await API.graphql({
      query: listUsers,
      variables: { filter: search ? filter : { role: { eq: "agent" } } },
    });

    allAgents = [...data.listUsers.items];
    token = data.listUsers.nextToken;
    while (token) {
      const { data } = await API.graphql({
        query: listUsers,
        variables: { filter: search ? filter : null, nextToken: token },
      });
      allAgents = [...allAgents, ...data.listUsers.items];
      token = data.listUsers.nextToken;
    }
    return allAgents;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const getClients = async (search) => {
  let filter = {
    and: [{ role: { eq: "client" } }, { or: [] }],
  };
  let allClients = [];
  let token;
  if (search) {
    filter.and[1].or.push(
      { givenName: { contains: search } },
      { familyName: { contains: search } },
      { name: { contains: String(search).toLowerCase() } },
      { email: { contains: search } }
    );
  }

  try {
    const { data } = await API.graphql({
      query: listUsers,
      variables: { filter: search ? filter : { role: { eq: "client" } } },
    });

    allClients = [...data.listUsers.items];
    token = data.listUsers.nextToken;
    while (token) {
      const { data } = await API.graphql({
        query: listUsers,
        variables: { filter: search ? filter : null, nextToken: token },
      });
      allClients = [...allClients, ...data.listUsers.items];
      token = data.listUsers.nextToken;
    }
    return allClients;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const getTourClients = async ({ clients }) => {
  if (!clients || clients.length === 0) return;
  let filter = {
    or: [],
  };

  clients.forEach((c) => filter.or.push({ id: { eq: c } }));

  const { data } = await API.graphql(
    graphqlOperation(listClients, { filter: filter })
  );

  return data.listClients.items;
};

export const getSummaryForTour = async (id) => {
  const { data } = await API.graphql({ query: getTour, variables: { id } });
  return data.getTour;
};

export const createShowingNote = async ({ content, tourItemId }) => {
  const { data } = await API.graphql(
    graphqlOperation(createNote, { input: { content, tourItemId } })
  );
  return data.createNote;
};

export const updateShowingNote = async (updates) => {
  const { data } = await API.graphql(
    graphqlOperation(updateNote, { input: { ...updates } })
  );
  return data.updateNote;
};

export const fetchClientTours = async () => {
  const filter = {
    shared: {
      eq: ShareStatus.SHARED,
    },
  };
  const { data } = await API.graphql({
    query: listTours,
    variables: { filter },
  });

  return data.listTours;
};

export const submitReview = async ({ tourItemId, rate }) => {
  const { data } = await API.graphql(
    graphqlOperation(createReview, { input: { tourItemId, rate } })
  );

  return data.createReview;
};

export const updateClientReview = async ({ id, tourItemId, rate }) => {
  const { data } = await API.graphql(
    graphqlOperation(updateReview, { input: { id, tourItemId, rate } })
  );

  return data.updateReview;
};

export const uploadPhoto = async ({ file, tourItemId }) => {
  const addImageToDB = async (image) => {
    await API.graphql(graphqlOperation(createPhoto, { input: image }));
  };
  const name = uuidv4();

  const buf = Buffer.from(file.base64String, "base64");

  try {
    await Storage.put(name, buf, { contentType: "image/jpeg" })
      .then(async (res) => {
        const image = {
          tourItemId,
          path: res.key,
          file: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: "public/" + name,
          },
        };

        await addImageToDB(image);
      })
      .catch((err) => console.log(err));
  } catch (err) {
    console.log(err);
  }
};

export const removePhoto = async ({ id, path }) => {
  await Storage.remove(path).then(async (result) => {
    await API.graphql(graphqlOperation(deletePhoto, { input: { id } }));
  });
};

export const uploadRecording = async ({ data, tourItemId }) => {
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: "audio/wav" });

  console.log(blob.size, blob.type);

  const addRecordingToDB = async (recording) => {
    await API.graphql(graphqlOperation(createRecording, { input: recording }));
  };
  const name = uuidv4();

  try {
    await Storage.put(name, blob, { contentType: "audio/wav" })
      .then(async (res) => {
        const recording = {
          tourItemId,
          path: res.key,
          file: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: "public/" + name,
          },
        };

        await addRecordingToDB(recording);
      })
      .catch((err) => console.log(err));
  } catch (err) {
    console.log(err);
  }
};

export const removeRecording = async ({ id, path }) => {
  await Storage.remove(path).then(async (result) => {
    await API.graphql(graphqlOperation(deleteRecording, { input: { id } }));
  });
};

// Pre-construction functions
export const findProject = async ({ id }) => {
  const guest = store.getState().user.guest;
  const isAuthenticated = store.getState().user.currentUser;

  if (!isAuthenticated) {
    const {
      data: { getProject: project },
    } = await guest.query({
      query: gql(getProject),
      variables: { id },
    });

    return project;
  } else {
    const {
      data: { getProject: project },
    } = await API.graphql({
      query: getProject,
      authMode: isAuthenticated
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: { id },
    });

    return project;
  }
};

export const getBuilders = async () => {
  const {
    data: { listBuilders: builders },
  } = await API.graphql(graphqlOperation(listBuilders));

  return builders.items;
};

export const findBuilder = async ({ id }) => {
  const {
    data: { getBuilder: builder },
  } = await API.graphql(
    graphqlOperation(getBuilder, {
      id,
    })
  );

  return builder;
};

export const addBuilder = async ({ name, url }) => {
  const {
    data: { createBuilder: builder },
  } = await API.graphql(
    graphqlOperation(createBuilder, {
      input: { name, logo: url },
    })
  );

  return builder;
};

export const modifyBuilder = async ({ id, name, url }) => {
  const {
    data: { updateBuilder: builder },
  } = await API.graphql(
    graphqlOperation(updateBuilder, {
      input: { id, name, logo: url },
    })
  );

  return builder;
};

export const getArchitects = async () => {
  const {
    data: { listArchitects: architects },
  } = await API.graphql(graphqlOperation(listArchitects));

  return architects.items;
};

export const findArchitect = async ({ id }) => {
  const {
    data: { getArchitect: architect },
  } = await API.graphql(
    graphqlOperation(getArchitect, {
      id,
    })
  );

  return architect;
};

export const addArchitect = async ({ name, url }) => {
  const {
    data: { createArchitect: architect },
  } = await API.graphql(
    graphqlOperation(createArchitect, {
      input: { name, logo: url },
    })
  );

  return architect;
};

export const modifyArchitect = async ({ id, name, url }) => {
  const {
    data: { updateArchitect: architect },
  } = await API.graphql(
    graphqlOperation(updateArchitect, {
      input: { id, name, logo: url },
    })
  );

  return architect;
};

export const getDesigners = async () => {
  const {
    data: { listInteriorDesigners: designers },
  } = await API.graphql(graphqlOperation(listInteriorDesigners));

  return designers.items;
};

export const findDesigner = async ({ id }) => {
  const {
    data: { getInteriorDesigner: designer },
  } = await API.graphql(
    graphqlOperation(getInteriorDesigner, {
      id,
    })
  );

  return designer;
};

export const addDesigner = async ({ name, url }) => {
  const {
    data: { createInteriorDesigner: designer },
  } = await API.graphql(
    graphqlOperation(createInteriorDesigner, {
      input: { name, logo: url },
    })
  );

  return designer;
};

export const modifyDesigner = async ({ id, name, url }) => {
  const {
    data: { updateInteriorDesigner: designer },
  } = await API.graphql(
    graphqlOperation(updateInteriorDesigner, {
      input: { id, name, logo: url },
    })
  );

  return designer;
};

export const searchForProjects = async ({ name }) => {
  const filter = {
    name: {
      contains: name,
    },
  };
  const {
    data: { listProjects: projects },
  } = await API.graphql(graphqlOperation(listProjects, { filter }));

  return projects.items;
};
export const searchForBuilders = async ({ name }) => {
  const filter = {
    name: {
      contains: name,
    },
  };
  const {
    data: { listBuilders: builders },
  } = await API.graphql(graphqlOperation(listBuilders, { filter }));

  return builders.items;
};

export const searchForArchitects = async ({ name }) => {
  const filter = {
    name: {
      contains: name,
    },
  };
  const {
    data: { listArchitects: architects },
  } = await API.graphql(graphqlOperation(listArchitects, { filter }));

  return architects.items;
};

export const searchForDesigners = async ({ name }) => {
  const filter = {
    name: {
      contains: name,
    },
  };
  const {
    data: { listInteriorDesigners: designers },
  } = await API.graphql(graphqlOperation(listInteriorDesigners, { filter }));

  return designers.items;
};

export const findUnit = async ({ id }) => {
  const {
    data: { getProjectUnit: unit },
  } = await API.graphql(
    graphqlOperation(getProjectUnit, {
      id,
    })
  );

  return unit;
};

export const getUnits = async () => {
  const {
    data: { listProjectUnits: units },
  } = await API.graphql(graphqlOperation(listProjectUnits));

  return units.items;
};
export const addUnit = async ({
  project,
  beds,
  baths,
  parkings,
  lockers,
  price,
  sqft,
  pricePerSqft,
  available,
  sold,
  type,
  media,
}) => {
  const {
    data: { createProjectUnit: unit },
  } = await API.graphql(
    graphqlOperation(createProjectUnit, {
      input: {
        projectId: project.id,
        numBeds: beds,
        numBaths: baths,
        numParkings: parkings,
        numLockers: lockers,
        sqft: sqft,
        price: price,
        pricePerSqft: pricePerSqft,
        type,
        available,
        sold,
        media: JSON.stringify(media),
      },
    })
  );

  return unit;
};

export const modifyUnit = async ({
  id,
  project,
  beds,
  baths,
  parkings,
  lockers,
  price,
  sqft,
  pricePerSqft,
  available,
  sold,
  type,
  media,
}) => {
  const {
    data: { updateProjectUnit: unit },
  } = await API.graphql(
    graphqlOperation(updateProjectUnit, {
      input: {
        id,
        projectId: project.id,
        numBeds: beds,
        numBaths: baths,
        numParkings: parkings,
        numLockers: lockers,
        sqft: sqft,
        price: price,
        pricePerSqft: pricePerSqft,
        type,
        available,
        sold,
        media: JSON.stringify(media),
      },
    })
  );

  return unit;
};

export const addProject = async ({
  name,
  address: { streetNumber, streetName, city, province, latitude, longitude },
  builder,
  architect,
  designer,
  occupancy,
  units,
  stories,
  price: { start, end, avg, parking, locker, maintenance },
  beds: { min: minBeds, max: maxBeds },
  sqft: { min: minSqft, max: maxSqft },
  phase,
  availability,
  types,
  description,
  incentives,
  deposit,
  media,
  amenities,
  thumbnail,
}) => {
  let filteredTypes = _.pickBy(types, (val, key) => {
    return val;
  });

  filteredTypes = Object.keys(filteredTypes);

  const selectedAmenities = amenities.map((record) => {
    return { id: record.item.id };
  });

  const {
    data: { createProject: project },
  } = await API.graphql(
    graphqlOperation(createProject, {
      input: {
        isSoldOut: false,
        name,
        streetNumber,
        streetName,
        city,
        province,
        latitude,
        longitude,
        builderId: builder?.id,
        architectId: architect?.id,
        interiorDesignerId: designer?.id,
        occupancy,
        units,
        stories,
        startPrice: start,
        endPrice: end,
        pricePerSqft: avg | 0,
        maintenanceFees: maintenance,
        parkingPrice: parking,
        lockerPrice: locker,
        minBeds,
        maxBeds,
        minSqft,
        maxSqft,
        phase,
        availability,
        types: filteredTypes,
        description,
        depositStructure: JSON.stringify(deposit),
        incentives: JSON.stringify(incentives),
        media: JSON.stringify(media),
        logo: thumbnail,
        lastUpdatedOn: moment().format("YYYY-MM-DD"),
      },
    })
  );

  for (let record of selectedAmenities) {
    await API.graphql(
      graphqlOperation(createProjectAmenities, {
        input: { projectID: project.id, amenityID: record.id },
      })
    );
  }

  return project;
};

export const modifyProject = async ({
  project: {
    id,
    name,
    address: { streetNumber, streetName, city, province, latitude, longitude },
    builder,
    architect,
    interiorDesigner,
    occupancy,
    units,
    stories,
    price: { start, end, avg, parking, locker, maintenance },
    beds: { min: minBeds, max: maxBeds },
    sqft: { min: minSqft, max: maxSqft },
    phase,
    availability,
    types,
    description,
    incentives,
    deposit,
    media,
    amenities,
    thumbnail,
    isSoldOut,
  },
  selectedAmenities,
}) => {
  let filteredTypes = _.pickBy(types, (val, key) => {
    return val;
  });

  filteredTypes = Object.keys(filteredTypes);

  for (let record of selectedAmenities) {
    await API.graphql(
      graphqlOperation(deleteProjectAmenities, {
        input: { id: record.id },
      })
    );
  }

  const newAmenities = amenities.map((record) => {
    return { id: record.item.id };
  });

  const {
    data: { updateProject: project },
  } = await API.graphql(
    graphqlOperation(updateProject, {
      input: {
        id,
        isSoldOut,
        name,
        streetNumber,
        streetName,
        city,
        province,
        latitude,
        longitude,
        builderId: builder?.id,
        architectId: architect?.id,
        interiorDesignerId: interiorDesigner?.id,
        occupancy,
        units,
        stories,
        startPrice: start,
        endPrice: end,
        pricePerSqft: avg | 0,
        maintenanceFees: maintenance,
        parkingPrice: parking,
        lockerPrice: locker,
        minBeds,
        maxBeds,
        minSqft,
        maxSqft,
        phase,
        availability,
        types: filteredTypes,
        description,
        depositStructure: JSON.stringify(deposit),
        incentives: JSON.stringify(incentives),
        media: JSON.stringify(media),
        logo: thumbnail,
        lastUpdatedOn: moment().format("YYYY-MM-DD"),
      },
    })
  );

  for (let record of newAmenities) {
    await API.graphql(
      graphqlOperation(createProjectAmenities, {
        input: { projectID: project.id, amenityID: record.id },
      })
    );
  }

  return project;
};

export const getAdminProjects = async () => {
  const {
    data: { listProjects: projects },
  } = await API.graphql(graphqlOperation(listProjects));

  return projects.items;
};

export const getAllProjects = async () => {
  const selectedFilters = store.getState().filters.value;
  const isAuthenticated = store.getState().user.currentUser;
  const guest = store.getState().user.guest;

  const boundary = selectedFilters.map[0];
  const {
    minPrice,
    maxPrice,
    minPricePerSqft,
    maxPricePerSqft,
    minBeds,
    maxBeds,
    minSqft,
    maxSqft,
    propertyType: types,
    keywords,
    status,
    preAvailability,
    builder,
    projectName,
  } = selectedFilters;

  const filter = {
    and: [
      { longitude: { gt: boundary[0][0] } },
      { longitude: { lt: boundary[1][0] } },
      { latitude: { gt: boundary[2][1] } },
      { latitude: { lt: boundary[0][1] } },
    ],
  };

  if (types) {
    const lookForCondo = types.find((type) => type === "condo");
    const lookForSingle = types.find((type) => type === "single");
    const lookForTownhouse = types.find((type) => type === "townhouse");
    filter.and.push({
      and: [
        {
          or: [
            lookForCondo && { types: { contains: "condo" } },
            lookForSingle && { types: { contains: "single" } },
            lookForTownhouse && { types: { contains: "townhouse" } },
          ].filter((item) => item !== undefined),
        },
      ],
    });
  }

  if (preAvailability) {
    filter.and.push({
      and: [
        {
          availability: {
            eq: preAvailability,
          },
        },
      ],
    });
  }

  filter.and.push({
    and: [
      {
        isSoldOut: {
          eq: status === "U",
        },
      },
    ],
  });

  filter.and.push({
    and: [
      {
        and: [
          {
            or: [
              { startPrice: { ge: parseFloat(minPrice | 0) } },
              {
                endPrice: { ge: parseFloat(minPrice | 0) },
              },
            ].filter((item) => item),
          },
          maxPrice &&
            maxPrice !== "max" && {
              or: [
                { startPrice: { le: parseFloat(maxPrice) } },
                {
                  endPrice: { le: parseFloat(maxPrice) },
                },
              ].filter((item) => item),
            },
        ].filter((item) => item),
      },
    ],
  });

  filter.and.push({
    and: [
      {
        and: [
          { pricePerSqft: { ge: parseFloat(minPricePerSqft | 0) } },
          maxPricePerSqft &&
            maxPricePerSqft !== "max" && {
              pricePerSqft: { le: parseFloat(maxPricePerSqft | 0) },
            },
        ].filter((item) => item),
      },
    ],
  });

  if (builder) {
    filter.and.push({
      and: [
        {
          builderId: {
            eq: builder,
          },
        },
      ],
    });
  }

  if (projectName) {
    filter.and.push({
      and: [
        {
          name: {
            contains: projectName,
          },
        },
      ],
    });
  }

  filter.and.push({
    and: [
      {
        and: [
          {
            or: [
              { minBeds: { ge: parseFloat(minBeds | 0) } },
              {
                maxBeds: { ge: parseFloat(minBeds | 0) },
              },
            ].filter((item) => item),
          },
          maxBeds && {
            or: [
              { minBeds: { le: parseFloat(maxBeds) } },
              {
                maxBeds: { le: parseFloat(maxBeds) },
              },
            ].filter((item) => item),
          },
        ].filter((item) => item),
      },
    ],
  });

  filter.and.push({
    and: [
      {
        and: [
          {
            or: [
              { minSqft: { ge: parseFloat(minSqft | 0) } },
              {
                maxSqft: { ge: parseFloat(minSqft | 0) },
              },
            ].filter((item) => item),
          },
          maxSqft && {
            or: [
              { minSqft: { le: parseFloat(maxSqft) } },
              {
                maxSqft: { le: parseFloat(maxSqft) },
              },
            ].filter((item) => item),
          },
        ].filter((item) => item),
      },
    ],
  });

  const listOfKeywords = [];
  if (keywords) {
    keywords.split(",").forEach((keyword) => listOfKeywords.push(keyword));
  }

  if (listOfKeywords.length > 0) {
    const keywordsFilter = {
      and: [
        {
          or: [],
        },
      ],
    };

    listOfKeywords.forEach((keyword) =>
      keywordsFilter.and[0].or.push({ description: { contains: keyword } })
    );

    filter.and.push(keywordsFilter);
  }

  if (!isAuthenticated) {
    const {
      data: { listProjects: projects },
    } = await guest.query({
      query: gql(listProjects),
      variables: { filter: filter, limit: 2000 },
    });

    return projects.items;
  } else {
    const {
      data: { listProjects: projects },
    } = await API.graphql({
      query: listProjects,
      authMode: isAuthenticated
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: {
        filter: filter,
        limit: 2000,
      },
    });

    return projects.items;
  }
};

export const getAllAmenities = async () => {
  const isAuthenticated = store.getState().user.currentUser;
  const guest = store.getState().user.guest;

  if (!isAuthenticated) {
    const {
      data: { listAmenities: amenities },
    } = await guest.query({
      query: gql(listAmenities),
    });
    return amenities.items;
  } else {
    const {
      data: { listAmenities: amenities },
    } = await API.graphql({
      query: listAmenities,
      authMode: isAuthenticated
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.AWS_IAM,
    });

    return amenities.items;
  }
};

export const addAmenity = async ({ name, description }) => {
  const {
    data: { createAmenity: amenity },
  } = await API.graphql(
    graphqlOperation(createAmenity, {
      input: { name, description },
    })
  );

  return amenity;
};

export const removeAmenity = async (id) => {
  await API.graphql(
    graphqlOperation(deleteAmenity, {
      input: { id },
    })
  );
};

export const updateAmenities = async ({ id, name, description }) => {
  const {
    data: { updateAmenity: amenity },
  } = await API.graphql(
    graphqlOperation(updateAmenity, {
      input: { id, name, description },
    })
  );

  return amenity;
};

export const fetchFavouriteItems = async () => {
  let data = [];
  let token;
  try {
    const {
      data: { listFavourites: favourites },
    } = await API.graphql(graphqlOperation(listFavourites));

    data = [...favourites.items];
    token = favourites.nextToken;

    while (token) {
      const {
        data: { listFavourites: favourites },
      } = await API.graphql(
        graphqlOperation(listFavourites, {
          nextToken: token,
        })
      );

      data = [...data, ...favourites.items];
      token = favourites.nextToken;
    }

    return data;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const fetchAllClientItems = async (search) => {
  let filter = {
    and: [{ role: { eq: "client" } }, { or: [] }],
  };
  let allClients = [];
  let token;
  if (search) {
    filter.and[1].or.push(
      { givenName: { contains: search } },
      { familyName: { contains: search } },
      { name: { contains: String(search).toLowerCase() } },
      { email: { contains: search } }
    );
  }

  try {
    const { data } = await API.graphql({
      query: listUsers,
      variables: { filter: search ? filter : { role: { eq: "client" } } },
    });

    allClients = [...data.listUsers.items];
    token = data.listUsers.nextToken;
    while (token) {
      const { data } = await API.graphql({
        query: listUsers,
        variables: { filter: search ? filter : null, nextToken: token },
      });
      allClients = [...allClients, ...data.listUsers.items];
      token = data.listUsers.nextToken;
    }
    return allClients;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};
