import { GraphQLQuery } from '@aws-amplify/api';
import { API } from "aws-amplify";
import { CreateProfileInput, CreateProfileMutation, UpdateProfileInput } from "../API";
import { createProfile as createProfileMutation, updateProfile as updateProfileMutation } from "../graphql/mutations";
import { AuthenticationService } from "./authenticationService";
import { UserService } from "./userService";

const createProfile = async (profile: CreateProfileInput) => {
    const cognitoUser = await AuthenticationService.getCognitoUser();
    const response = await API.graphql<GraphQLQuery<CreateProfileMutation>>({query: createProfileMutation, variables: {input: {...profile, id: cognitoUser.username, userId: cognitoUser.username}}});
    await UserService.updateProfileId(response.data!.createProfile!.id);
    return response.data!.createProfile!;
};

const updateProfile = async (profile: UpdateProfileInput) => {
    return await API.graphql({query: updateProfileMutation, variables: {input: profile}});
};

export const ProfileService = {
    createProfile,
    updateProfile
};