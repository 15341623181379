import * as APITypes from "../../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const markUserTourAsSeen = /* GraphQL */ `mutation UpdateUserTour(
  $input: UpdateUserTourInput!
  $condition: ModelUserTourConditionInput
) {
  updateUserTour(input: $input, condition: $condition) {
    creator
    userId
    tourId
    role
    seen
    id
    createdAt
    updatedAt
    userToursId
    tourUsersId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserTourMutationVariables,
  APITypes.UpdateUserTourMutation
>;

export const updateTourItem = /* GraphQL */ `mutation UpdateTourItem(
    $input: UpdateTourItemInput!
    $condition: ModelTourItemConditionInput
  ) {
    updateTourItem(input: $input, condition: $condition) {
      creator
      tourId
      clients
      primaryAgent
      showingAgents
      mlsNumber
      status
      startTime
      endTime
      order
      showingNote
      entryInfo
      id
      createdAt
      updatedAt
      tourTourItemsId
      reviews {
        items {
            id
            rate 
            userId 
            tourItemId
        }
        nextToken
        __typename
      }
      notes {
        items {
            id 
            content 
            userId
            tourItemId
        }
        nextToken
        __typename
      }
      photos {
        items {
            id 
            userId 
            tourItemId 
            path
        }
        nextToken
        __typename
      }
      recordings {
        items {
            id 
            userId 
            tourItemId 
            path
        }
        nextToken
        __typename
      }
      __typename
    }
  }
  ` as GeneratedMutation<
  APITypes.UpdateTourItemMutationVariables,
  APITypes.UpdateTourItemMutation
>;
