import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { App } from "@capacitor/app";
import { federatedSignInStart } from "../redux/user/user.actions";
import { useIonRouter } from "@ionic/react";

const AppUrlListener = () => {
  const dispatch = useDispatch();
  const router = useIonRouter();
  const location = useLocation();

  useEffect(() => {
    let listener;

    async function addListener() {
      listener = await App.addListener("appUrlOpen", (data) => {
        console.log("App opened with data: ", data);
        // const slug = data.url.split("/?")[1];
        const slug = data.url.split(".ca").pop();
        if (slug) {
          if (slug.startsWith("/?") || slug.startsWith("/oauth")) {
            const code = slug.split("/?")[1];
            document.location.href = `/?${code}`;
          } else {
            if (slug === "/") {
              router.push("/home");
            } else {
              console.log("slug", slug);
              router.push(slug);
            }
          }

          if (Capacitor.isNativePlatform()) {
            Browser.close();
          }
        }
      });
    }

    addListener();

    return () => {
      if (listener) {
        listener.remove();
      }
    };
  }, [router]);

  useEffect(() => {
    if (location?.search?.includes("Already+found+an+entry+for+user"))
      dispatch(
        federatedSignInStart(
          location.search.includes("google")
            ? "Google"
            : location.search.includes("facebook")
            ? "Facebook"
            : "SignInWithApple"
        )
      );
  }, [dispatch, location]);
  return null;
};
export default AppUrlListener;
