import { IonIcon } from "@ionic/react";
import { starOutline } from "ionicons/icons";
import { ReactComponent as FavoriteIcon } from "../../../assets/svg/star.svg";
import { Link } from "react-router-dom";
import styles from "./add-favourite.module.scss";

const AddFavourite = ({ isApp = false }) => {
  return (
    <div>
      <div className={isApp ? styles.appFavorite : styles.favorite}>
        {isApp ? (
          <IonIcon icon={starOutline} className={styles.icon} />
        ) : (
          <FavoriteIcon />
        )}
      </div>
      <div className={styles.title}>Add to favourites</div>
      <div className={styles.content}>
        Search the <Link to={"/favourites/listings"}>listings</Link> or review
        your <Link to="/favourites/searches">saved searches</Link> to find new
        favourites.
      </div>
    </div>
  );
};

export default AddFavourite;
