import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonPopover,
} from "@ionic/react";
import LogoWithText from "../../Logo/LogoWithText";
import styles from "./header.module.scss";
import { carOutline, chevronDown, personOutline } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { selectFiltersValue } from "../../../redux/filters/filters.selectors";
import { updateFilters } from "../../../redux/filters/filters.actions";
import { useEffect, useState } from "react";
import CustomAuthContainer from "components/Authentication/CustomAuthContainer/custom-auth-container.component";
import { signOutStart } from "redux/user/user.actions";
import { Link } from "react-router-dom";

const Header = ({ type }: { type?: "search" }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const { type: searchType } = useSelector(selectFiltersValue);
  const [authModal, setAuthModal] = useState<{
    isOpen: boolean;
    type: "login" | "register";
  }>({ isOpen: false, type: "login" });

  const updateSearchType = (value: string) => {
    dispatch(updateFilters({ type: value }));
  };

  const openLoginModal = () => {
    setAuthModal({ isOpen: true, type: "login" });
  };

  const openRegisterModal = () => {
    setAuthModal({ isOpen: true, type: "register" });
  };

  const handleLogout = () => {
    dispatch(signOutStart());
  };

  useEffect(() => {
    if (currentUser) {
      setAuthModal({ isOpen: false, type: "login" });
    }
  }, [currentUser]);

  const renderLeftSideCustomContent = () => {
    if (type === "search") {
      return (
        <>
          <IonButton
            size="small"
            id="filter-trigger"
            color="dark"
            className="aecorn-button">
            {searchType === "sale" ? "For Sale" : "For Lease"}
            <IonIcon slot="end" icon={chevronDown} />
          </IonButton>
          <IonPopover
            dismissOnSelect
            trigger="filter-trigger"
            triggerAction="click"
            showBackdrop={false}>
            <IonContent className={styles.popoverContent}>
              <IonList>
                <IonItem
                  button
                  lines="none"
                  onClick={() => updateSearchType("sale")}>
                  <IonLabel>For Sale</IonLabel>
                </IonItem>
                <IonItem
                  button
                  lines="none"
                  onClick={() => updateSearchType("lease")}>
                  <IonLabel>For Lease</IonLabel>
                </IonItem>
              </IonList>
            </IonContent>
          </IonPopover>
        </>
      );
    }
    return null;
  };

  return (
    <div className={styles.container}>
      <IonModal
        isOpen={authModal.isOpen}
        className={styles.modal}
        backdropDismiss={false}>
        <CustomAuthContainer
          type={authModal.type}
          setType={(type: "login" | "register") =>
            setAuthModal({ isOpen: true, type })
          }
          closeModal={() => setAuthModal({ isOpen: false, type: "login" })}
        />
      </IonModal>
      <div className={styles.content}>
        <div>
          <Link to="/">
            <LogoWithText dark={true} />
          </Link>
          {renderLeftSideCustomContent()}
        </div>
        <div>
          <IonButton
            size="small"
            shape="round"
            routerLink="/tours"
            className="aecorn-button"
            color="dark">
            <IonIcon slot="start" icon={carOutline} />
            Take a Tour
          </IonButton>
          <IonButton
            className={styles.profileDropdown}
            id="profile-trigger"
            color="dark"
            fill="clear">
            <IonIcon icon={personOutline} />
            <IonIcon icon={chevronDown} />
          </IonButton>
          <IonPopover
            trigger="profile-trigger"
            triggerAction="click"
            dismissOnSelect
            showBackdrop={false}>
            <IonContent className={styles.popoverContent}>
              <IonList>
                {currentUser ? (
                  <>
                    <IonItem lines="none" routerLink="/user">
                      Profile
                    </IonItem>
                    <IonItemDivider />
                    <IonItem lines="none" routerLink="/tours">
                      My tours
                    </IonItem>
                    <IonItem lines="none" routerLink="/favourites">
                      My favourites
                    </IonItem>
                    <IonItem lines="none" routerLink="/searches">
                      Saved searches
                    </IonItem>
                    <IonItemDivider />
                    <IonItem lines="none" button onClick={handleLogout}>
                      Log out
                    </IonItem>
                  </>
                ) : (
                  <>
                    <IonItem lines="none" button onClick={openLoginModal}>
                      Log in
                    </IonItem>
                    <IonItem lines="none" button onClick={openRegisterModal}>
                      Register
                    </IonItem>
                  </>
                )}
              </IonList>
            </IonContent>
          </IonPopover>
        </div>
      </div>
    </div>
  );
};

export default Header;
