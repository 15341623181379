import { useState, useEffect, useCallback, useRef, createRef } from "react";
import { sendRequestToAgent } from "../../../api/ettie";
import withClient from "../../../HOC/withClient/with-client";
import Button from "../../Form/Button/button.component";
import Input from "../../Form/Input/input.component";
import styles from "./pre-request-form-modal.module.scss";

const PreRequestFormModal = ({ currentClient, setType, data, language }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [text, setText] = useState("");
  const [value, setValue] = useState("");
  const [addDate, setAddDate] = useState([]);

  useEffect(() => {
    if (value) setText();
  }, [value]);

  const inputRefs = useRef({
    given_name: createRef(),
    family_name: createRef(),
    email: createRef(),
    phone_number: createRef(),
  });

  const [formData, setFormData] = useState({
    given_name: "",
    family_name: "",
    email: "",
    phone_number: "",
  });

  useEffect(() => {
    if (currentClient) {
      setFormData({
        email: currentClient.email,
        given_name: currentClient.givenName,
        family_name: currentClient.familyName,
        phone_number: currentClient.phone ? currentClient.phone.substr(2) : "",
      });
    }
  }, [currentClient]);

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleMessage = (event) => {
    setText(event.target.value);
  };

  const handlePreConRequest = async () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }

    if (isValid) {
      setLoading(true);
      setError("");

      const res = await sendRequestToAgent({
        name: `${formData.given_name} ${formData.family_name}`,
        givenName: formData.given_name,
        email: formData.email,
        phone: formData.phone_number,
        message: text,
        info: data.info,
        preCon: true,
      });
      if (res.success) {
        setType("requestSent");
      } else {
        setLoading(false);
        setError("Something went wrong! Please try again.");
      }
    }
  };

  return (
    <div className={styles.preRequestForm}>
      <div className={styles.title}>
        {data.isUnit
          ? "Want more info on this unit?"
          : "Want more info on pre-con projects?"}
      </div>
      <div className={styles.subtitle}>
        We're excited to work with you! Submit this form and we will be in touch
        soon!
      </div>

      <div className={styles.sectionTitle}>Contact information</div>
      <div className={styles.inputRow}>
        <Input
          required
          name="given_name"
          label="First name"
          placeholder="First name"
          type="text"
          ref={inputRefs.current.given_name}
          value={formData["given_name"]}
          defaultValue={currentClient && currentClient.givenName}
          onChange={(value, name) => handleChange(value, name)}
          validation="required|min:2"
        />
        <Input
          required
          name="family_name"
          label="Last name"
          placeholder="Last name"
          type="text"
          ref={inputRefs.current.family_name}
          value={formData["family_name"]}
          defaultValue={currentClient && currentClient.familyName}
          onChange={(value, name) => handleChange(value, name)}
          validation="required|min:2"
        />
      </div>
      <div className={styles.inputRow}>
        <Input
          required
          name="phone_number"
          label="Phone"
          placeholder="Phone"
          // type="phone"
          mode="tel"
          ref={inputRefs.current.phone_number}
          value={formData["phone_number"]}
          // defaultValue={
          //   currentClient && currentClient.phone ? currentClient.phone : ""
          // }
          onChange={(value, name) => handleChange(value, name)}
          validation="required|phone"
        />
        <Input
          required
          name="email"
          label="Email"
          placeholder="Email"
          // type="email"
          mode="email"
          ref={inputRefs.current.email}
          value={formData["email"]}
          defaultValue={currentClient && currentClient.email}
          onChange={(value, name) => handleChange(value.toLowerCase(), name)}
          validation="required|email"
        />
      </div>

      <span className={styles.textarealabel}>Message</span>
      <textarea
        className={styles.textarea}
        placeholder={
          data.isUnit
            ? "I’d like to get more information on this unit."
            : "I’d like to get more information on pre-construction projects."
        }
        name="textarea"
        value={text}
        onChange={handleMessage}
      ></textarea>
      {error && <div className={styles.error}>{error}</div>}

      <Button
        onClick={handlePreConRequest}
        title="Submit"
        type="green"
        loading={loading}
        style={
          !value || (!value.includes("information") && !addDate.length)
            ? {
                marginTop: "2rem",
                height: "5rem",
                width: "100%",
                border: "1px solid #1f7a60",
              }
            : {
                marginTop: "2rem",
                height: "5rem",
                width: "100%",
                border: "1px solid #1f7a60",
              }
        }
      />
      <div className={styles.declaration}>
        By submitting this form, I understand AECORN will share my information
        with registered real estate professionals.
      </div>
    </div>
  );
};

export default withClient(PreRequestFormModal);
