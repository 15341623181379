import { IonPage, IonContent, IonCol, IonGrid, IonRow } from "@ionic/react";
import AppHeader from "../../components/Header/header.component";
import styles from "./school-disclaimer.module.scss";

const AppSchoolDisclaimer = ({ modal = false, close }) => {
  const sendEmailHandler = async () => {
    try {
      let Link = "mailto:info@aecorn.ca";
      window.open(Link, "_system");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <IonPage>
      <AppHeader
        title=""
        hasRightButton={modal}
        isModal={modal}
        onClick={close}
      />
      <IonContent>
        <IonGrid className={styles.schools}>
          <IonRow>
            <IonCol>
              <div className={styles.title}>
                Fraser Institute Rating Discontinued
              </div>
              <div className={styles.subtitle}>
                November 10, 2020 Admin Real Estate
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className={styles.body}>
              <div>Hi AECORN users,</div>
              <p>
                AECORN uses data from{" "}
                <a
                  href="https://www.eqao.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  EQAO
                </a>{" "}
                for school ratings. The EQAO rating is a widely recognized
                rating system for public schools in Ontario.
              </p>
              <p>
                Our platform considers all of the recent 5 years of ranking
                based on EQAO scores and compiles them into an overall AECORN
                Realty rating based on the scale of 10.
                <div>
                  If you have any further questions, please reach out to us at{" "}
                  <span className={styles.email} onClick={sendEmailHandler}>
                    info@aecorn.ca
                  </span>
                  .
                </div>
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AppSchoolDisclaimer;
