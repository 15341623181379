import ClientActionTypes from "./client.types";

export const fetchClientStart = (user) => ({
  type: ClientActionTypes.FETCH_CLIENT_START,
  payload: user,
});

export const fetchClientSuccess = (client) => ({
  type: ClientActionTypes.FETCH_CLIENT_SUCCESS,
  payload: client,
});

export const fetchClientFailure = (error) => ({
  type: ClientActionTypes.FETCH_CLIENT_FAILURE,
  payload: error,
});

export const clearClient = () => ({
  type: ClientActionTypes.CLEAR_CLIENT,
});

export const addSearchStart = (search) => ({
  type: ClientActionTypes.ADD_SEARCH_START,
  payload: search,
});

export const addSearchSuccess = () => ({
  type: ClientActionTypes.ADD_SEARCH_SUCCESS,
});

export const addSearchFailure = (error) => ({
  type: ClientActionTypes.ADD_SEARCH_SUCCESS,
  payload: error,
});

export const removeSearchStart = (search) => ({
  type: ClientActionTypes.REMOVE_SEARCH_START,
  payload: search,
});

export const removeSearchSuccess = () => ({
  type: ClientActionTypes.REMOVE_SEARCH_SUCCESS,
});

export const removeSearchFailure = (error) => ({
  type: ClientActionTypes.REMOVE_SEARCH_SUCCESS,
  payload: error,
});

export const updateSearchStart = (search) => ({
  type: ClientActionTypes.UPDATE_SEARCH_START,
  payload: search,
});

export const updateSearchSuccess = () => ({
  type: ClientActionTypes.UPDATE_SEARCH_SUCCESS,
});

export const updateSearchFailure = (error) => ({
  type: ClientActionTypes.UPDATE_SEARCH_FAILURE,
  payload: error,
});
