import { IonLoading } from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAdminProjects } from "../../../../amplify/graphql.utils";
import Card from "../../../../components/Card/card.component";
import { updateToast } from "../../../../redux/ui/ui.actions";
import styles from "./projects.module.scss";
import defaultImage from "../../../../assets/img/no-photo.png";
import Button from "../../../../components/Form/Button/button.component";
import BreadCrumbs from "../../components/breadcrumbs";

const Projects = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadProjects = async () => {
    try {
      const projects = await getAdminProjects();
      setProjects(projects);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
      history.replace("/admin");
    } finally {
      setLoading(false);
    }
  };

  props.pageHook(() => {
    loadProjects();
  });

  useEffect(() => {
    loadProjects();
  }, []);

  const renderCards = () => {
    return projects.map(({ id, name, logo }) => (
      <Card key={id} onClick={() => history.push(`/admin/projects/${id}`)}>
        <div className={styles.card}>
          <img className={styles.image} src={logo || defaultImage} alt={name} />
          <span className={styles.title}>{name}</span>
        </div>
      </Card>
    ));
  };

  return (
    <div className={styles.container}>
      <IonLoading isOpen={loading} />
      <BreadCrumbs type="projects" />

      {!loading && projects.length > 0 && (
        <div className={styles.add}>
          <Button
            type={"green"}
            border={true}
            title={"Add new project"}
            onClick={() => history.push("/admin/projects/new")}
          />
        </div>
      )}

      <div className={styles.cards}>
        {!loading ? (
          projects.length ? (
            renderCards()
          ) : (
            <div className={styles.noItem}>
              No project available
              <Button
                style={{ marginTop: "2rem" }}
                title={"Create"}
                type={"green"}
                onClick={() => history.push("/admin/projects/new")}
              />
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default Projects;
