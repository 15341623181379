import { API } from "aws-amplify";
export const getMLSEstimate = async (mlsNumber) => {
  const res = await API.get("ettieREST", `/estimate/${mlsNumber}`);
  return res;
};

export const getHomeCards = async () => {
  const res = await API.post("ettieREST", "/listhomecards", { body: {} });
  return res;
};

export const getHomeFeatureds = async () => {
  const res = await API.post("ettieREST", "/featured", { body: {} });
  return res;
};

export const sendFeedback = async (request) => {
  const data = {
    body: request,
  };
  const res = await API.post("ettieREST", "/feedback", data);
  return res;
};

export const contactUs = async (request) => {
  const data = {
    body: request,
  };
  const res = await API.post("ettieREST", "/contact/general", data);
  return res;
};

export const sendRequestToAgent = async (request) => {
  const data = {
    body: request,
  };
  const res = await API.post("ettieREST", "/contact/agent", data);
  return res;
};

export const shareListing = async (request) => {
  const data = {
    body: request,
  };

  const res = await API.post("ettieREST", "/contact/share", data);
  return res;
};