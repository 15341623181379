import { API } from "aws-amplify";
import {
  CreateTourInput,
  CreateTourMutation,
  CreateUserTourInput,
  CreateUserTourMutation,
  ListToursQuery,
  ShareStatus,
  TourStatus,
  Tour,
  TourRole,
  UpdateTourInput,
  UpdateUserTourInput,
  UpdateUserTourMutation,
  User,
  CreateTourItemInput,
  GetShowingsByDateQuery,
} from "../API";
import {
  createTour as createTourMutation,
  createUserTour as createUserTourMutation,
  deleteUserTour,
  updateUserTour,
} from "../graphql/mutations";
import { GraphQLQuery } from "@aws-amplify/api";
import { UserService } from "./userService";
import { GeneralConstants } from "../utils/constants";
import {
  getClientTour,
  getAgentTour,
  listTours,
} from "./queries/tours.queries";
import { updateTour as updateTourMutation } from "./mutations/tours.mutations";
import { GetTourQuery, UpdateTourMutation } from "./types/tours.types";
import { AuthenticationService } from "./authenticationService";
import { TourItemService } from "./tourItemService";
import { getShowingsByDate } from "../graphql/queries";
import { ListingService } from "./listingService";
import { BookedShowing } from "../models/listings/BookedShowing.model";

const getTourById = async (id: string) => {
  const isCurrentUserAgent = await AuthenticationService.isCurrentUserAgent();
  const response = await API.graphql<GraphQLQuery<GetTourQuery>>({
    query: isCurrentUserAgent ? getAgentTour : getClientTour,
    variables: { id },
  });
  return response.data?.getTour;
};

const getTours = async (filter?: { [key: string]: any }) => {
  let nextToken: string | null | undefined;

  const tours: Tour[] = [];

  do {
    const response = await API.graphql<GraphQLQuery<ListToursQuery>>({
      query: listTours,
      variables: { nextToken, ...(filter && { filter }) },
    });

    if (response.data?.listTours) {
      tours.push(...(response.data.listTours.items as Tour[]));
      nextToken = response.data.listTours.nextToken;
    }
  } while (nextToken);

  return tours;
};

const getAgentTours = async () => {
  return await getTours();
};

const getClientTours = async () => {
  return await getTours({ shared: { eq: ShareStatus.shared } });
};

const createTour = async (
  tour: CreateTourInput,
  leadAgent: User,
  showingAgents: User[],
  client: User,
  guests?: User[]
) => {
  const currentUser = await UserService.getCurrentUser();
  const input: CreateTourInput = {
    ...tour,
    creator: currentUser?.id,
    primaryAgent: leadAgent.id,
    showingAgents: showingAgents.map((agent) => agent.id) || [],
    clients: [client.id, ...(guests || []).map((guest) => guest.id)],
    status: TourStatus.pending,
    shared: ShareStatus.unshared,
  };
  const response = await API.graphql<GraphQLQuery<CreateTourMutation>>({
    query: createTourMutation,
    variables: { input },
  });
  const createdTour = response.data?.createTour;

  if (createdTour) {
    await createUserTours(
      createdTour.id,
      leadAgent,
      showingAgents,
      client,
      guests
    );
  }

  return createdTour;
};

const updateTour = async (tour: UpdateTourInput) => {
  const response = await API.graphql<GraphQLQuery<UpdateTourMutation>>({
    query: updateTourMutation,
    variables: { input: tour },
  });
  return response.data?.updateTour;
};

const archiveTour = async (id: string) => {
  return await updateTour({ id, status: TourStatus.archived });
};

const cancelTour = async (id: string, notifyAttendees = false) => {
  if (notifyAttendees) {
    try {
      const authToken = await AuthenticationService.getToken();

      await API.post(GeneralConstants.REST_API_NAME, `/tours/${id}/cancel`, {
        headers: {
          AuthToken: authToken,
        },
      });
    } catch (error) {
      throw new Error("Failed to notify attendees");
    }
  }

  return await updateTour({ id, status: TourStatus.cancelled });
};

const updateTourUsers = async (
  tourId: string,
  users: { id: string; role: TourRole }[]
) => {
  const currentUserId = await UserService.getCurrentUserId();
  const tour = await getTourById(tourId);

  // Iterate through existing users and remove any that are not in the new list and add any that are not in the existing list and update any that have a different role
  const existingUsers = tour?.users?.items || [];

  const usersToRemove = existingUsers.filter(
    (existingUser) => !users.find((user) => user.id === existingUser?.userId)
  );
  const usersToAdd = users.filter(
    (user) =>
      !existingUsers.find((existingUser) => existingUser?.userId === user.id)
  );
  const usersToUpdate = users.filter((user) => {
    if (
      !existingUsers.find((existingUser) => existingUser?.userId === user.id)
    ) {
      return false;
    }

    const existingEntity = existingUsers.find(
      (existingUser) =>
        existingUser?.userId === user.id && existingUser?.role === user.role
    );

    return !existingEntity;
  });

  // Remove users
  await Promise.all(
    usersToRemove.map((user) => {
      if (user?.id) {
        return removeUserTourById(user.id);
      }
      return Promise.resolve();
    })
  );

  // Add users
  await Promise.all(
    usersToAdd.map((user) => {
      return createUserTour({
        creator: currentUserId,
        userId: user.id,
        tourId,
        role: user.role,
        seen: false,
      });
    })
  );

  await Promise.all(
    usersToUpdate.map((user) => {
      const existingUser = existingUsers.find(
        (existingUser) => existingUser?.userId === user.id
      );
      if (existingUser) {
        return updateTourUser({
          id: existingUser.id,
          role: user.role,
        });
      }
    })
  );

  // Update tour agents and clients
  const updatedTour = await updateTour({
    id: tourId,
    primaryAgent: users.find((user) => user.role === TourRole.primaryagent)?.id,
    showingAgents: users
      .filter((user) => user.role === TourRole.showingagent)
      .map((user) => user.id),
    clients: users
      .filter(
        (user) => user.role === TourRole.client || user.role === TourRole.guest
      )
      .map((user) => user.id),
  });

  // Update tour items
  const tourItems = tour?.tourItems?.items || [];
  await Promise.all(
    tourItems.map((item) => {
      if (item) {
        return TourItemService.updateTourItem({
          id: item.id,
          primaryAgent: updatedTour?.primaryAgent,
          showingAgents: updatedTour?.showingAgents,
          clients: updatedTour?.clients,
        });
      }
    })
  );

  return await getTourById(tourId);
};

const getToursUpdates = async () => {
  const currentUserId = await UserService.getCurrentUserId();
  const isUserAgent = await AuthenticationService.isCurrentUserAgent();
  const userTours = isUserAgent
    ? await getAgentTours()
    : await getClientTours();

  const updates = userTours.filter((tour) =>
    tour.users?.items.some(
      (user) => user?.userId === currentUserId && !user?.seen
    )
  );

  return updates;
};

const addShowingToTour = async (tour: Tour, showing: CreateTourItemInput) => {
  return await TourItemService.createTourItem({
    ...showing,
    tourId: tour.id,
    primaryAgent: tour.primaryAgent,
    showingAgents: tour.showingAgents,
    clients: tour.clients,
  });
};

const shareTour = async (tourId: string) => {
  const tour = await getTourById(tourId);
  if (tour) {
    const clients = tour.users.items
      .filter(
        (user) =>
          user &&
          (user.role === TourRole.client || user.role === TourRole.guest)
      )
      .map((user) => user!.user);

    if (clients.length === 0) {
      throw new Error("Tour has no clients to share with");
    }

    const authToken = await AuthenticationService.getToken();

    await API.post(GeneralConstants.REST_API_NAME, `/tours/${tourId}/share`, {
      headers: {
        AuthToken: authToken,
      },
    });

    const response = await API.graphql<GraphQLQuery<UpdateTourMutation>>({
      query: updateTourMutation,
      variables: { input: { id: tourId, shared: ShareStatus.shared } },
    });

    return response.data?.updateTour;
  } else {
    throw new Error("Tour not found");
  }
};

// Utility functions
const getTourPrimaryAgent = (tour: Tour) => {
  return tour.users?.items.find((user) => user?.role === TourRole.primaryagent)
    ?.user;
};

const getTourShowingAgents = (tour: Tour) => {
  return tour.users?.items
    .filter((user) => user?.role === TourRole.showingagent)
    .map((user) => user?.user);
};

const getTourClient = (tour: Tour) => {
  return tour.users?.items.find((user) => user?.role === TourRole.client)?.user;
};

const getTourGuests = (tour: Tour) => {
  return tour.users?.items
    .filter((user) => user?.role === TourRole.guest)
    .map((user) => user?.user);
};

const getTourUserByUserId = (tour: Tour, userId: string) => {
  return tour.users?.items.find((user) => user?.userId === userId);
};

const getTourUsers = (tour: Tour) => {
  const tourUsers = tour.users?.items || [];

  const TourRoleMap = {
    [TourRole.primaryagent]: "Lead Agent",
    [TourRole.showingagent]: "Showing Agent",
    [TourRole.client]: "Client",
    [TourRole.guest]: "Guest",
  };

  return tourUsers
    .map((user) => ({
      ...user?.user,
      role: user?.role,
      title: user?.role ? TourRoleMap[user.role] : "Unknown",
    }))
    .sort((a, b) => {
      if (a.role === TourRole.primaryagent) {
        return -1;
      } else if (b.role === TourRole.primaryagent) {
        return 1;
      }

      if (a.role === TourRole.showingagent) {
        return -1;
      } else if (b.role === TourRole.showingagent) {
        return 1;
      }

      if (a.role === TourRole.client) {
        return -1;
      } else if (b.role === TourRole.client) {
        return 1;
      }
      return 0;
    });
};

// Private functions

const createUserTours = async (
  tourId: string,
  leadAgent: User,
  showingAgents: User[],
  client: User,
  guests?: User[]
) => {
  const currentUserId = await UserService.getCurrentUserId();

  const userTourPromises = [
    createUserTour({
      creator: currentUserId,
      userId: leadAgent.id,
      tourId,
      role: TourRole.primaryagent,
      seen: currentUserId === leadAgent.id,
    }),
    ...showingAgents.map((agent) =>
      createUserTour({
        creator: currentUserId,
        userId: agent.id,
        tourId,
        role: TourRole.showingagent,
        seen: agent.id === currentUserId,
      })
    ),
    createUserTour({
      creator: currentUserId,
      userId: client.id,
      tourId,
      role: TourRole.client,
      seen: false,
    }),
  ];

  if (guests) {
    userTourPromises.push(
      ...guests.map((guest) =>
        createUserTour({
          creator: currentUserId,
          userId: guest.id,
          tourId,
          role: TourRole.guest,
          seen: false,
        })
      )
    );
  }

  return Promise.all(userTourPromises);
};

const createUserTour = async (userTour: CreateUserTourInput) => {
  const response = await API.graphql<GraphQLQuery<CreateUserTourMutation>>({
    query: createUserTourMutation,
    variables: { input: userTour },
  });
  return response.data?.createUserTour;
};

const updateTourUser = async (userTour: UpdateUserTourInput) => {
  const response = await API.graphql<GraphQLQuery<UpdateUserTourMutation>>({
    query: updateUserTour,
    variables: { input: userTour },
  });
  return response.data?.updateUserTour;
};

const removeUserTourById = async (id: string) => {
  await API.graphql({ query: deleteUserTour, variables: { input: { id } } });
};

const getBookedShowingsByDate = async (date: string) => {
  const response = await API.graphql<GraphQLQuery<GetShowingsByDateQuery>>({
    query: getShowingsByDate,
    variables: { date },
  });

  const showings: BookedShowing[] = <BookedShowing[]>(
    (response.data?.getShowingsByDate?.items || [])
  );

  for (const showing of showings) {
    if (showing && showing.mlsNumber) {
      const listing = await ListingService.getListingByMlsNumber(
        showing.mlsNumber
      );
      showing.listing = listing;
    }
  }

  return showings;
};

const isCurrentUserTourPrimaryAgent = async (tour: Tour) => {
  const currentUserId = await UserService.getCurrentUserId();
  return tour.primaryAgent === currentUserId;
}

export const TourService = {
  getTourById,
  getAgentTours,
  getClientTours,
  createTour,
  updateTour,
  updateTourUsers,
  getTourUserByUserId,
  getTourUsers,
  getTourPrimaryAgent,
  getTourShowingAgents,
  getTourClient,
  getTourGuests,
  getToursUpdates,
  archiveTour,
  cancelTour,
  shareTour,
  addShowingToTour,
  getBookedShowingsByDate,
  isCurrentUserTourPrimaryAgent
};
