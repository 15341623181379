import {
  IonItem,
  IonLabel,
  IonIcon,
  IonInput,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { addOutline, removeOutline } from "ionicons/icons";

const PreMedia = ({
  label,
  styles,
  onAdd,
  items,
  onMediaChange,
  onTypeChange,
  onRemove,
}) => {
  return (
    <>
      <IonItem lines="none">
        <IonLabel position="stack">{label}</IonLabel>
        <IonIcon icon={addOutline} className={styles.addIcon} onClick={onAdd} />
      </IonItem>
      <div>
        {items.map((item, i) => (
          <IonItem key={i}>
            <IonLabel>{i + 1}) </IonLabel>
            <IonInput
              value={item.value}
              clearInput
              type="text"
              onIonChange={(e) =>
                onMediaChange({ index: i, value: e.detail.value })
              }
            />
            <IonItem lines="none" style={styles.item}>
              <IonLabel>Type</IonLabel>
              <IonSelect
                value={item.type}
                onIonChange={(e) =>
                  onTypeChange({ index: i, value: e.detail.value })
                }
              >
                <IonSelectOption value="image">Image</IonSelectOption>
                <IonSelectOption value="video">Video</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonIcon
              icon={removeOutline}
              className={styles.addIcon}
              onClick={() => onRemove(item.id)}
            />
          </IonItem>
        ))}
      </div>
    </>
  );
};

export default PreMedia;
