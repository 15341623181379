import { Listing } from "models/listings/listing.model";
import styles from "./listing-price-difference.module.scss";
import { IonIcon } from "@ionic/react";
import { arrowDownOutline, arrowUpOutline } from "ionicons/icons";
import { ListingsHelper } from "utils/ListingsHelper";

const ListingPriceDifference = ({ listing }: { listing: Listing }) => {
  const priceDifference = +listing.soldPrice - +listing.listPrice;
  const formattedPriceDifference =
    ListingsHelper.getListingFormattedPriceDifference(listing);

  if (priceDifference === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <IonIcon
        className={priceDifference > 0 ? styles.up : styles.down}
        icon={priceDifference > 0 ? arrowUpOutline : arrowDownOutline}
      />

      <span
        className={`${styles.priceValue} ${
          priceDifference > 0 ? styles.up : styles.down
        }`}>
        {formattedPriceDifference}
      </span>

      <span className={styles.priceText}>
        {priceDifference > 0 ? "above asking" : "below asking"}
      </span>
    </div>
  );
};

export default ListingPriceDifference;
