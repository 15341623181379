import React, { useEffect, useState } from "react";
import moment from "moment";
import MedianSoldPriceWithAvgDOMChart from "./MedianSoldPriceWithAvgDOMChart/median-sold-price-with-avg-dom-chart.component";
import styles from "./median-sold-price-with-avg-dom.module.scss";

const MedianSoldPriceWithAvgDOM = ({
  segment,
  area,
  neighborhood,
  propertyType,
  marketData,
  isApp,
}) => {
  const [fiveYmarketData, setFiveYmarketData] = useState();
  const [tenYmarketData, setTenYmarketData] = useState();
  const [fifteenYmarketData, setFifteenYmarketData] = useState();

  useEffect(() => {
    const currentDate = moment();
    const pastFiveYears = moment(currentDate)
      .subtract(59, "M")
      .format("YYYY-MM");
    const pastTenYears = moment(currentDate)
      .subtract(119, "M")
      .format("YYYY-MM");
    const pastFifteenYears = moment(currentDate)
      .subtract(179, "M")
      .format("YYYY-MM");

    let fiveYindex =
      marketData &&
      marketData
        .map(function(e) {
          return e.date;
        })
        .indexOf(pastFiveYears);

    let tenYindex =
      marketData &&
      marketData
        .map(function(e) {
          return e.date;
        })
        .indexOf(pastTenYears);

    let fifteenYindex =
      marketData &&
      marketData
        .map(function(e) {
          return e.date;
        })
        .indexOf(pastFifteenYears);

    let fiveYData = marketData.slice(0, fiveYindex + 1);
    fiveYData = fiveYData.reverse();
    setFiveYmarketData(fiveYData);

    let tenYData = marketData.slice(0, tenYindex + 1);
    tenYData = tenYData.reverse();
    setTenYmarketData(tenYData);

    let fifteenYData = marketData.slice(0, fifteenYindex + 1);
    fifteenYData = fifteenYData.reverse();
    setFifteenYmarketData(fifteenYData);
  }, []);

  return (
    <div className={styles.soldwithDom}>
      <div className={styles.title}>
        Median sold price and average days on market in this area *
      </div>

      <div className={styles.chart}>
        {marketData && segment === "5" && fiveYmarketData ? (
          <MedianSoldPriceWithAvgDOMChart
            marketData={fiveYmarketData}
            isApp={isApp}
          />
        ) : segment === "10" && tenYmarketData ? (
          <MedianSoldPriceWithAvgDOMChart
            marketData={tenYmarketData}
            isApp={isApp}
          />
        ) : (
          segment === "15" &&
          fifteenYmarketData && (
            <MedianSoldPriceWithAvgDOMChart
              marketData={fifteenYmarketData}
              isApp={isApp}
            />
          )
        )}
      </div>

      <div className={styles.info}>
        {area} / {neighborhood} / {propertyType}
      </div>
    </div>
  );
};

export default MedianSoldPriceWithAvgDOM;
