/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createBuilder = /* GraphQL */ `mutation CreateBuilder(
  $input: CreateBuilderInput!
  $condition: ModelBuilderConditionInput
) {
  createBuilder(input: $input, condition: $condition) {
    id
    name
    projects {
      nextToken
      __typename
    }
    logo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBuilderMutationVariables,
  APITypes.CreateBuilderMutation
>;
export const updateBuilder = /* GraphQL */ `mutation UpdateBuilder(
  $input: UpdateBuilderInput!
  $condition: ModelBuilderConditionInput
) {
  updateBuilder(input: $input, condition: $condition) {
    id
    name
    projects {
      nextToken
      __typename
    }
    logo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBuilderMutationVariables,
  APITypes.UpdateBuilderMutation
>;
export const deleteBuilder = /* GraphQL */ `mutation DeleteBuilder(
  $input: DeleteBuilderInput!
  $condition: ModelBuilderConditionInput
) {
  deleteBuilder(input: $input, condition: $condition) {
    id
    name
    projects {
      nextToken
      __typename
    }
    logo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBuilderMutationVariables,
  APITypes.DeleteBuilderMutation
>;
export const createArchitect = /* GraphQL */ `mutation CreateArchitect(
  $input: CreateArchitectInput!
  $condition: ModelArchitectConditionInput
) {
  createArchitect(input: $input, condition: $condition) {
    name
    projects {
      nextToken
      __typename
    }
    logo
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateArchitectMutationVariables,
  APITypes.CreateArchitectMutation
>;
export const updateArchitect = /* GraphQL */ `mutation UpdateArchitect(
  $input: UpdateArchitectInput!
  $condition: ModelArchitectConditionInput
) {
  updateArchitect(input: $input, condition: $condition) {
    name
    projects {
      nextToken
      __typename
    }
    logo
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateArchitectMutationVariables,
  APITypes.UpdateArchitectMutation
>;
export const deleteArchitect = /* GraphQL */ `mutation DeleteArchitect(
  $input: DeleteArchitectInput!
  $condition: ModelArchitectConditionInput
) {
  deleteArchitect(input: $input, condition: $condition) {
    name
    projects {
      nextToken
      __typename
    }
    logo
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteArchitectMutationVariables,
  APITypes.DeleteArchitectMutation
>;
export const createInteriorDesigner = /* GraphQL */ `mutation CreateInteriorDesigner(
  $input: CreateInteriorDesignerInput!
  $condition: ModelInteriorDesignerConditionInput
) {
  createInteriorDesigner(input: $input, condition: $condition) {
    name
    projects {
      nextToken
      __typename
    }
    logo
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInteriorDesignerMutationVariables,
  APITypes.CreateInteriorDesignerMutation
>;
export const updateInteriorDesigner = /* GraphQL */ `mutation UpdateInteriorDesigner(
  $input: UpdateInteriorDesignerInput!
  $condition: ModelInteriorDesignerConditionInput
) {
  updateInteriorDesigner(input: $input, condition: $condition) {
    name
    projects {
      nextToken
      __typename
    }
    logo
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInteriorDesignerMutationVariables,
  APITypes.UpdateInteriorDesignerMutation
>;
export const deleteInteriorDesigner = /* GraphQL */ `mutation DeleteInteriorDesigner(
  $input: DeleteInteriorDesignerInput!
  $condition: ModelInteriorDesignerConditionInput
) {
  deleteInteriorDesigner(input: $input, condition: $condition) {
    name
    projects {
      nextToken
      __typename
    }
    logo
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInteriorDesignerMutationVariables,
  APITypes.DeleteInteriorDesignerMutation
>;
export const createProject = /* GraphQL */ `mutation CreateProject(
  $input: CreateProjectInput!
  $condition: ModelProjectConditionInput
) {
  createProject(input: $input, condition: $condition) {
    name
    streetNumber
    streetName
    city
    province
    latitude
    longitude
    builderId
    builder {
      id
      name
      logo
      createdAt
      updatedAt
      __typename
    }
    architectId
    architect {
      name
      logo
      id
      createdAt
      updatedAt
      __typename
    }
    interiorDesignerId
    interiorDesigner {
      name
      logo
      id
      createdAt
      updatedAt
      __typename
    }
    occupancy
    units
    stories
    startPrice
    endPrice
    minBeds
    maxBeds
    minSqft
    maxSqft
    pricePerSqft
    maintenanceFees
    parkingPrice
    lockerPrice
    phase
    availability
    types
    description
    incentives
    amenities {
      nextToken
      __typename
    }
    depositStructure
    logo
    media
    projectUnits {
      nextToken
      __typename
    }
    lastUpdatedOn
    isSoldOut
    commission
    id
    createdAt
    updatedAt
    builderProjectsId
    architectProjectsId
    interiorDesignerProjectsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectMutationVariables,
  APITypes.CreateProjectMutation
>;
export const updateProject = /* GraphQL */ `mutation UpdateProject(
  $input: UpdateProjectInput!
  $condition: ModelProjectConditionInput
) {
  updateProject(input: $input, condition: $condition) {
    name
    streetNumber
    streetName
    city
    province
    latitude
    longitude
    builderId
    builder {
      id
      name
      logo
      createdAt
      updatedAt
      __typename
    }
    architectId
    architect {
      name
      logo
      id
      createdAt
      updatedAt
      __typename
    }
    interiorDesignerId
    interiorDesigner {
      name
      logo
      id
      createdAt
      updatedAt
      __typename
    }
    occupancy
    units
    stories
    startPrice
    endPrice
    minBeds
    maxBeds
    minSqft
    maxSqft
    pricePerSqft
    maintenanceFees
    parkingPrice
    lockerPrice
    phase
    availability
    types
    description
    incentives
    amenities {
      nextToken
      __typename
    }
    depositStructure
    logo
    media
    projectUnits {
      nextToken
      __typename
    }
    lastUpdatedOn
    isSoldOut
    commission
    id
    createdAt
    updatedAt
    builderProjectsId
    architectProjectsId
    interiorDesignerProjectsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectMutationVariables,
  APITypes.UpdateProjectMutation
>;
export const deleteProject = /* GraphQL */ `mutation DeleteProject(
  $input: DeleteProjectInput!
  $condition: ModelProjectConditionInput
) {
  deleteProject(input: $input, condition: $condition) {
    name
    streetNumber
    streetName
    city
    province
    latitude
    longitude
    builderId
    builder {
      id
      name
      logo
      createdAt
      updatedAt
      __typename
    }
    architectId
    architect {
      name
      logo
      id
      createdAt
      updatedAt
      __typename
    }
    interiorDesignerId
    interiorDesigner {
      name
      logo
      id
      createdAt
      updatedAt
      __typename
    }
    occupancy
    units
    stories
    startPrice
    endPrice
    minBeds
    maxBeds
    minSqft
    maxSqft
    pricePerSqft
    maintenanceFees
    parkingPrice
    lockerPrice
    phase
    availability
    types
    description
    incentives
    amenities {
      nextToken
      __typename
    }
    depositStructure
    logo
    media
    projectUnits {
      nextToken
      __typename
    }
    lastUpdatedOn
    isSoldOut
    commission
    id
    createdAt
    updatedAt
    builderProjectsId
    architectProjectsId
    interiorDesignerProjectsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectMutationVariables,
  APITypes.DeleteProjectMutation
>;
export const createProjectUnit = /* GraphQL */ `mutation CreateProjectUnit(
  $input: CreateProjectUnitInput!
  $condition: ModelProjectUnitConditionInput
) {
  createProjectUnit(input: $input, condition: $condition) {
    numBeds
    numBaths
    numParkings
    numLockers
    sqft
    price
    pricePerSqft
    projectId
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    type
    available
    sold
    media
    id
    createdAt
    updatedAt
    projectProjectUnitsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectUnitMutationVariables,
  APITypes.CreateProjectUnitMutation
>;
export const updateProjectUnit = /* GraphQL */ `mutation UpdateProjectUnit(
  $input: UpdateProjectUnitInput!
  $condition: ModelProjectUnitConditionInput
) {
  updateProjectUnit(input: $input, condition: $condition) {
    numBeds
    numBaths
    numParkings
    numLockers
    sqft
    price
    pricePerSqft
    projectId
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    type
    available
    sold
    media
    id
    createdAt
    updatedAt
    projectProjectUnitsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectUnitMutationVariables,
  APITypes.UpdateProjectUnitMutation
>;
export const deleteProjectUnit = /* GraphQL */ `mutation DeleteProjectUnit(
  $input: DeleteProjectUnitInput!
  $condition: ModelProjectUnitConditionInput
) {
  deleteProjectUnit(input: $input, condition: $condition) {
    numBeds
    numBaths
    numParkings
    numLockers
    sqft
    price
    pricePerSqft
    projectId
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    type
    available
    sold
    media
    id
    createdAt
    updatedAt
    projectProjectUnitsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectUnitMutationVariables,
  APITypes.DeleteProjectUnitMutation
>;
export const createAmenity = /* GraphQL */ `mutation CreateAmenity(
  $input: CreateAmenityInput!
  $condition: ModelAmenityConditionInput
) {
  createAmenity(input: $input, condition: $condition) {
    name
    description
    projects {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAmenityMutationVariables,
  APITypes.CreateAmenityMutation
>;
export const updateAmenity = /* GraphQL */ `mutation UpdateAmenity(
  $input: UpdateAmenityInput!
  $condition: ModelAmenityConditionInput
) {
  updateAmenity(input: $input, condition: $condition) {
    name
    description
    projects {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAmenityMutationVariables,
  APITypes.UpdateAmenityMutation
>;
export const deleteAmenity = /* GraphQL */ `mutation DeleteAmenity(
  $input: DeleteAmenityInput!
  $condition: ModelAmenityConditionInput
) {
  deleteAmenity(input: $input, condition: $condition) {
    name
    description
    projects {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAmenityMutationVariables,
  APITypes.DeleteAmenityMutation
>;
export const createFeatured = /* GraphQL */ `mutation CreateFeatured(
  $input: CreateFeaturedInput!
  $condition: ModelFeaturedConditionInput
) {
  createFeatured(input: $input, condition: $condition) {
    title
    subtitle
    filters
    isNearby
    isFavourite
    isSchool
    order
    status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFeaturedMutationVariables,
  APITypes.CreateFeaturedMutation
>;
export const updateFeatured = /* GraphQL */ `mutation UpdateFeatured(
  $input: UpdateFeaturedInput!
  $condition: ModelFeaturedConditionInput
) {
  updateFeatured(input: $input, condition: $condition) {
    title
    subtitle
    filters
    isNearby
    isFavourite
    isSchool
    order
    status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFeaturedMutationVariables,
  APITypes.UpdateFeaturedMutation
>;
export const deleteFeatured = /* GraphQL */ `mutation DeleteFeatured(
  $input: DeleteFeaturedInput!
  $condition: ModelFeaturedConditionInput
) {
  deleteFeatured(input: $input, condition: $condition) {
    title
    subtitle
    filters
    isNearby
    isFavourite
    isSchool
    order
    status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFeaturedMutationVariables,
  APITypes.DeleteFeaturedMutation
>;
export const createHomeCard = /* GraphQL */ `mutation CreateHomeCard(
  $input: CreateHomeCardInput!
  $condition: ModelHomeCardConditionInput
) {
  createHomeCard(input: $input, condition: $condition) {
    title
    text
    image
    actionLabel
    actionLink
    inAppAction
    order
    status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHomeCardMutationVariables,
  APITypes.CreateHomeCardMutation
>;
export const updateHomeCard = /* GraphQL */ `mutation UpdateHomeCard(
  $input: UpdateHomeCardInput!
  $condition: ModelHomeCardConditionInput
) {
  updateHomeCard(input: $input, condition: $condition) {
    title
    text
    image
    actionLabel
    actionLink
    inAppAction
    order
    status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHomeCardMutationVariables,
  APITypes.UpdateHomeCardMutation
>;
export const deleteHomeCard = /* GraphQL */ `mutation DeleteHomeCard(
  $input: DeleteHomeCardInput!
  $condition: ModelHomeCardConditionInput
) {
  deleteHomeCard(input: $input, condition: $condition) {
    title
    text
    image
    actionLabel
    actionLink
    inAppAction
    order
    status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHomeCardMutationVariables,
  APITypes.DeleteHomeCardMutation
>;
export const createProfile = /* GraphQL */ `mutation CreateProfile(
  $input: CreateProfileInput!
  $condition: ModelProfileConditionInput
) {
  createProfile(input: $input, condition: $condition) {
    userId
    buyingSelling
    leasingRenting
    justLooking
    preConstruction
    isRealtor
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProfileMutationVariables,
  APITypes.CreateProfileMutation
>;
export const updateProfile = /* GraphQL */ `mutation UpdateProfile(
  $input: UpdateProfileInput!
  $condition: ModelProfileConditionInput
) {
  updateProfile(input: $input, condition: $condition) {
    userId
    buyingSelling
    leasingRenting
    justLooking
    preConstruction
    isRealtor
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProfileMutationVariables,
  APITypes.UpdateProfileMutation
>;
export const deleteProfile = /* GraphQL */ `mutation DeleteProfile(
  $input: DeleteProfileInput!
  $condition: ModelProfileConditionInput
) {
  deleteProfile(input: $input, condition: $condition) {
    userId
    buyingSelling
    leasingRenting
    justLooking
    preConstruction
    isRealtor
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProfileMutationVariables,
  APITypes.DeleteProfileMutation
>;
export const createFavourite = /* GraphQL */ `mutation CreateFavourite(
  $input: CreateFavouriteInput!
  $condition: ModelFavouriteConditionInput
) {
  createFavourite(input: $input, condition: $condition) {
    userId
    repliersID
    mlsNumber
    listing
    listingUpdate
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFavouriteMutationVariables,
  APITypes.CreateFavouriteMutation
>;
export const updateFavourite = /* GraphQL */ `mutation UpdateFavourite(
  $input: UpdateFavouriteInput!
  $condition: ModelFavouriteConditionInput
) {
  updateFavourite(input: $input, condition: $condition) {
    userId
    repliersID
    mlsNumber
    listing
    listingUpdate
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFavouriteMutationVariables,
  APITypes.UpdateFavouriteMutation
>;
export const deleteFavourite = /* GraphQL */ `mutation DeleteFavourite(
  $input: DeleteFavouriteInput!
  $condition: ModelFavouriteConditionInput
) {
  deleteFavourite(input: $input, condition: $condition) {
    userId
    repliersID
    mlsNumber
    listing
    listingUpdate
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFavouriteMutationVariables,
  APITypes.DeleteFavouriteMutation
>;
export const createPreFavourite = /* GraphQL */ `mutation CreatePreFavourite(
  $input: CreatePreFavouriteInput!
  $condition: ModelPreFavouriteConditionInput
) {
  createPreFavourite(input: $input, condition: $condition) {
    userId
    projectId
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePreFavouriteMutationVariables,
  APITypes.CreatePreFavouriteMutation
>;
export const updatePreFavourite = /* GraphQL */ `mutation UpdatePreFavourite(
  $input: UpdatePreFavouriteInput!
  $condition: ModelPreFavouriteConditionInput
) {
  updatePreFavourite(input: $input, condition: $condition) {
    userId
    projectId
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePreFavouriteMutationVariables,
  APITypes.UpdatePreFavouriteMutation
>;
export const deletePreFavourite = /* GraphQL */ `mutation DeletePreFavourite(
  $input: DeletePreFavouriteInput!
  $condition: ModelPreFavouriteConditionInput
) {
  deletePreFavourite(input: $input, condition: $condition) {
    userId
    projectId
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePreFavouriteMutationVariables,
  APITypes.DeletePreFavouriteMutation
>;
export const createSearch = /* GraphQL */ `mutation CreateSearch(
  $input: CreateSearchInput!
  $condition: ModelSearchConditionInput
) {
  createSearch(input: $input, condition: $condition) {
    name
    userId
    repliersID
    value
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSearchMutationVariables,
  APITypes.CreateSearchMutation
>;
export const updateSearch = /* GraphQL */ `mutation UpdateSearch(
  $input: UpdateSearchInput!
  $condition: ModelSearchConditionInput
) {
  updateSearch(input: $input, condition: $condition) {
    name
    userId
    repliersID
    value
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSearchMutationVariables,
  APITypes.UpdateSearchMutation
>;
export const deleteSearch = /* GraphQL */ `mutation DeleteSearch(
  $input: DeleteSearchInput!
  $condition: ModelSearchConditionInput
) {
  deleteSearch(input: $input, condition: $condition) {
    name
    userId
    repliersID
    value
    notification
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSearchMutationVariables,
  APITypes.DeleteSearchMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $input: CreateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  createNotification(input: $input, condition: $condition) {
    userId
    schedule
    type
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateNotification = /* GraphQL */ `mutation UpdateNotification(
  $input: UpdateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  updateNotification(input: $input, condition: $condition) {
    userId
    schedule
    type
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationMutationVariables,
  APITypes.UpdateNotificationMutation
>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification(
  $input: DeleteNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  deleteNotification(input: $input, condition: $condition) {
    userId
    schedule
    type
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationMutationVariables,
  APITypes.DeleteNotificationMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    repliersID
    givenName
    familyName
    name
    displayName
    email
    phone
    profilePicture
    role
    agent {
      userId
      website
      licenseNumber
      areas
      availability
      id
      createdAt
      updatedAt
      brokerageAgentsId
      __typename
    }
    client {
      userId
      id
      createdAt
      updatedAt
      agentClientsId
      clientAgentId
      __typename
    }
    subscribed
    currency
    measurement
    notifications {
      userId
      schedule
      type
      id
      createdAt
      updatedAt
      __typename
    }
    deviceToken
    profile {
      userId
      buyingSelling
      leasingRenting
      justLooking
      preConstruction
      isRealtor
      id
      createdAt
      updatedAt
      __typename
    }
    requestedTour
    tours {
      nextToken
      __typename
    }
    toursNote
    deactivated
    id
    createdAt
    updatedAt
    userAgentId
    userClientId
    userNotificationsId
    userProfileId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    repliersID
    givenName
    familyName
    name
    displayName
    email
    phone
    profilePicture
    role
    agent {
      userId
      website
      licenseNumber
      areas
      availability
      id
      createdAt
      updatedAt
      brokerageAgentsId
      __typename
    }
    client {
      userId
      id
      createdAt
      updatedAt
      agentClientsId
      clientAgentId
      __typename
    }
    subscribed
    currency
    measurement
    notifications {
      userId
      schedule
      type
      id
      createdAt
      updatedAt
      __typename
    }
    deviceToken
    profile {
      userId
      buyingSelling
      leasingRenting
      justLooking
      preConstruction
      isRealtor
      id
      createdAt
      updatedAt
      __typename
    }
    requestedTour
    tours {
      nextToken
      __typename
    }
    toursNote
    deactivated
    id
    createdAt
    updatedAt
    userAgentId
    userClientId
    userNotificationsId
    userProfileId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    repliersID
    givenName
    familyName
    name
    displayName
    email
    phone
    profilePicture
    role
    agent {
      userId
      website
      licenseNumber
      areas
      availability
      id
      createdAt
      updatedAt
      brokerageAgentsId
      __typename
    }
    client {
      userId
      id
      createdAt
      updatedAt
      agentClientsId
      clientAgentId
      __typename
    }
    subscribed
    currency
    measurement
    notifications {
      userId
      schedule
      type
      id
      createdAt
      updatedAt
      __typename
    }
    deviceToken
    profile {
      userId
      buyingSelling
      leasingRenting
      justLooking
      preConstruction
      isRealtor
      id
      createdAt
      updatedAt
      __typename
    }
    requestedTour
    tours {
      nextToken
      __typename
    }
    toursNote
    deactivated
    id
    createdAt
    updatedAt
    userAgentId
    userClientId
    userNotificationsId
    userProfileId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createAgent = /* GraphQL */ `mutation CreateAgent(
  $input: CreateAgentInput!
  $condition: ModelAgentConditionInput
) {
  createAgent(input: $input, condition: $condition) {
    userId
    website
    licenseNumber
    areas
    availability
    brokerage {
      name
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    clients {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    brokerageAgentsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAgentMutationVariables,
  APITypes.CreateAgentMutation
>;
export const updateAgent = /* GraphQL */ `mutation UpdateAgent(
  $input: UpdateAgentInput!
  $condition: ModelAgentConditionInput
) {
  updateAgent(input: $input, condition: $condition) {
    userId
    website
    licenseNumber
    areas
    availability
    brokerage {
      name
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    clients {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    brokerageAgentsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAgentMutationVariables,
  APITypes.UpdateAgentMutation
>;
export const deleteAgent = /* GraphQL */ `mutation DeleteAgent(
  $input: DeleteAgentInput!
  $condition: ModelAgentConditionInput
) {
  deleteAgent(input: $input, condition: $condition) {
    userId
    website
    licenseNumber
    areas
    availability
    brokerage {
      name
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    clients {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    brokerageAgentsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAgentMutationVariables,
  APITypes.DeleteAgentMutation
>;
export const createClient = /* GraphQL */ `mutation CreateClient(
  $input: CreateClientInput!
  $condition: ModelClientConditionInput
) {
  createClient(input: $input, condition: $condition) {
    userId
    agent {
      userId
      website
      licenseNumber
      areas
      availability
      id
      createdAt
      updatedAt
      brokerageAgentsId
      __typename
    }
    id
    createdAt
    updatedAt
    agentClientsId
    clientAgentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClientMutationVariables,
  APITypes.CreateClientMutation
>;
export const updateClient = /* GraphQL */ `mutation UpdateClient(
  $input: UpdateClientInput!
  $condition: ModelClientConditionInput
) {
  updateClient(input: $input, condition: $condition) {
    userId
    agent {
      userId
      website
      licenseNumber
      areas
      availability
      id
      createdAt
      updatedAt
      brokerageAgentsId
      __typename
    }
    id
    createdAt
    updatedAt
    agentClientsId
    clientAgentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClientMutationVariables,
  APITypes.UpdateClientMutation
>;
export const deleteClient = /* GraphQL */ `mutation DeleteClient(
  $input: DeleteClientInput!
  $condition: ModelClientConditionInput
) {
  deleteClient(input: $input, condition: $condition) {
    userId
    agent {
      userId
      website
      licenseNumber
      areas
      availability
      id
      createdAt
      updatedAt
      brokerageAgentsId
      __typename
    }
    id
    createdAt
    updatedAt
    agentClientsId
    clientAgentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClientMutationVariables,
  APITypes.DeleteClientMutation
>;
export const createBrokerage = /* GraphQL */ `mutation CreateBrokerage(
  $input: CreateBrokerageInput!
  $condition: ModelBrokerageConditionInput
) {
  createBrokerage(input: $input, condition: $condition) {
    name
    agents {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBrokerageMutationVariables,
  APITypes.CreateBrokerageMutation
>;
export const updateBrokerage = /* GraphQL */ `mutation UpdateBrokerage(
  $input: UpdateBrokerageInput!
  $condition: ModelBrokerageConditionInput
) {
  updateBrokerage(input: $input, condition: $condition) {
    name
    agents {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBrokerageMutationVariables,
  APITypes.UpdateBrokerageMutation
>;
export const deleteBrokerage = /* GraphQL */ `mutation DeleteBrokerage(
  $input: DeleteBrokerageInput!
  $condition: ModelBrokerageConditionInput
) {
  deleteBrokerage(input: $input, condition: $condition) {
    name
    agents {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBrokerageMutationVariables,
  APITypes.DeleteBrokerageMutation
>;
export const createUserTour = /* GraphQL */ `mutation CreateUserTour(
  $input: CreateUserTourInput!
  $condition: ModelUserTourConditionInput
) {
  createUserTour(input: $input, condition: $condition) {
    creator
    userId
    tourId
    user {
      repliersID
      givenName
      familyName
      name
      displayName
      email
      phone
      profilePicture
      role
      subscribed
      currency
      measurement
      deviceToken
      requestedTour
      toursNote
      deactivated
      id
      createdAt
      updatedAt
      userAgentId
      userClientId
      userNotificationsId
      userProfileId
      __typename
    }
    tour {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    role
    seen
    id
    createdAt
    updatedAt
    userToursId
    tourUsersId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserTourMutationVariables,
  APITypes.CreateUserTourMutation
>;
export const updateUserTour = /* GraphQL */ `mutation UpdateUserTour(
  $input: UpdateUserTourInput!
  $condition: ModelUserTourConditionInput
) {
  updateUserTour(input: $input, condition: $condition) {
    creator
    userId
    tourId
    user {
      repliersID
      givenName
      familyName
      name
      displayName
      email
      phone
      profilePicture
      role
      subscribed
      currency
      measurement
      deviceToken
      requestedTour
      toursNote
      deactivated
      id
      createdAt
      updatedAt
      userAgentId
      userClientId
      userNotificationsId
      userProfileId
      __typename
    }
    tour {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    role
    seen
    id
    createdAt
    updatedAt
    userToursId
    tourUsersId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserTourMutationVariables,
  APITypes.UpdateUserTourMutation
>;
export const deleteUserTour = /* GraphQL */ `mutation DeleteUserTour(
  $input: DeleteUserTourInput!
  $condition: ModelUserTourConditionInput
) {
  deleteUserTour(input: $input, condition: $condition) {
    creator
    userId
    tourId
    user {
      repliersID
      givenName
      familyName
      name
      displayName
      email
      phone
      profilePicture
      role
      subscribed
      currency
      measurement
      deviceToken
      requestedTour
      toursNote
      deactivated
      id
      createdAt
      updatedAt
      userAgentId
      userClientId
      userNotificationsId
      userProfileId
      __typename
    }
    tour {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    role
    seen
    id
    createdAt
    updatedAt
    userToursId
    tourUsersId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserTourMutationVariables,
  APITypes.DeleteUserTourMutation
>;
export const createTour = /* GraphQL */ `mutation CreateTour(
  $input: CreateTourInput!
  $condition: ModelTourConditionInput
) {
  createTour(input: $input, condition: $condition) {
    title
    creator
    users {
      nextToken
      __typename
    }
    clients
    primaryAgent
    showingAgents
    status
    privateNote
    generalNote
    tourItems {
      nextToken
      __typename
    }
    meetupLocation
    meetupDescription
    meetupTime
    startTime
    endTime
    date
    shared
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTourMutationVariables,
  APITypes.CreateTourMutation
>;
export const updateTour = /* GraphQL */ `mutation UpdateTour(
  $input: UpdateTourInput!
  $condition: ModelTourConditionInput
) {
  updateTour(input: $input, condition: $condition) {
    title
    creator
    users {
      nextToken
      __typename
    }
    clients
    primaryAgent
    showingAgents
    status
    privateNote
    generalNote
    tourItems {
      nextToken
      __typename
    }
    meetupLocation
    meetupDescription
    meetupTime
    startTime
    endTime
    date
    shared
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTourMutationVariables,
  APITypes.UpdateTourMutation
>;
export const deleteTour = /* GraphQL */ `mutation DeleteTour(
  $input: DeleteTourInput!
  $condition: ModelTourConditionInput
) {
  deleteTour(input: $input, condition: $condition) {
    title
    creator
    users {
      nextToken
      __typename
    }
    clients
    primaryAgent
    showingAgents
    status
    privateNote
    generalNote
    tourItems {
      nextToken
      __typename
    }
    meetupLocation
    meetupDescription
    meetupTime
    startTime
    endTime
    date
    shared
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTourMutationVariables,
  APITypes.DeleteTourMutation
>;
export const createTourItem = /* GraphQL */ `mutation CreateTourItem(
  $input: CreateTourItemInput!
  $condition: ModelTourItemConditionInput
) {
  createTourItem(input: $input, condition: $condition) {
    creator
    tourId
    tour {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    clients
    primaryAgent
    showingAgents
    mlsNumber
    status
    startTime
    endTime
    order
    showingNote
    entryInfo
    reviews {
      nextToken
      __typename
    }
    notes {
      nextToken
      __typename
    }
    photos {
      nextToken
      __typename
    }
    recordings {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    tourTourItemsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTourItemMutationVariables,
  APITypes.CreateTourItemMutation
>;
export const updateTourItem = /* GraphQL */ `mutation UpdateTourItem(
  $input: UpdateTourItemInput!
  $condition: ModelTourItemConditionInput
) {
  updateTourItem(input: $input, condition: $condition) {
    creator
    tourId
    tour {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    clients
    primaryAgent
    showingAgents
    mlsNumber
    status
    startTime
    endTime
    order
    showingNote
    entryInfo
    reviews {
      nextToken
      __typename
    }
    notes {
      nextToken
      __typename
    }
    photos {
      nextToken
      __typename
    }
    recordings {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    tourTourItemsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTourItemMutationVariables,
  APITypes.UpdateTourItemMutation
>;
export const deleteTourItem = /* GraphQL */ `mutation DeleteTourItem(
  $input: DeleteTourItemInput!
  $condition: ModelTourItemConditionInput
) {
  deleteTourItem(input: $input, condition: $condition) {
    creator
    tourId
    tour {
      title
      creator
      clients
      primaryAgent
      showingAgents
      status
      privateNote
      generalNote
      meetupLocation
      meetupDescription
      meetupTime
      startTime
      endTime
      date
      shared
      id
      createdAt
      updatedAt
      __typename
    }
    clients
    primaryAgent
    showingAgents
    mlsNumber
    status
    startTime
    endTime
    order
    showingNote
    entryInfo
    reviews {
      nextToken
      __typename
    }
    notes {
      nextToken
      __typename
    }
    photos {
      nextToken
      __typename
    }
    recordings {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    tourTourItemsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTourItemMutationVariables,
  APITypes.DeleteTourItemMutation
>;
export const createShowing = /* GraphQL */ `mutation CreateShowing(
  $input: CreateShowingInput!
  $condition: ModelShowingConditionInput
) {
  createShowing(input: $input, condition: $condition) {
    userId
    mlsNumber
    date
    startTime
    endTime
    instructions
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateShowingMutationVariables,
  APITypes.CreateShowingMutation
>;
export const updateShowing = /* GraphQL */ `mutation UpdateShowing(
  $input: UpdateShowingInput!
  $condition: ModelShowingConditionInput
) {
  updateShowing(input: $input, condition: $condition) {
    userId
    mlsNumber
    date
    startTime
    endTime
    instructions
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateShowingMutationVariables,
  APITypes.UpdateShowingMutation
>;
export const deleteShowing = /* GraphQL */ `mutation DeleteShowing(
  $input: DeleteShowingInput!
  $condition: ModelShowingConditionInput
) {
  deleteShowing(input: $input, condition: $condition) {
    userId
    mlsNumber
    date
    startTime
    endTime
    instructions
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteShowingMutationVariables,
  APITypes.DeleteShowingMutation
>;
export const createReview = /* GraphQL */ `mutation CreateReview(
  $input: CreateReviewInput!
  $condition: ModelReviewConditionInput
) {
  createReview(input: $input, condition: $condition) {
    userId
    tourItemId
    rate
    id
    createdAt
    updatedAt
    tourItemReviewsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMutationVariables,
  APITypes.CreateReviewMutation
>;
export const updateReview = /* GraphQL */ `mutation UpdateReview(
  $input: UpdateReviewInput!
  $condition: ModelReviewConditionInput
) {
  updateReview(input: $input, condition: $condition) {
    userId
    tourItemId
    rate
    id
    createdAt
    updatedAt
    tourItemReviewsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewMutationVariables,
  APITypes.UpdateReviewMutation
>;
export const deleteReview = /* GraphQL */ `mutation DeleteReview(
  $input: DeleteReviewInput!
  $condition: ModelReviewConditionInput
) {
  deleteReview(input: $input, condition: $condition) {
    userId
    tourItemId
    rate
    id
    createdAt
    updatedAt
    tourItemReviewsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewMutationVariables,
  APITypes.DeleteReviewMutation
>;
export const createNote = /* GraphQL */ `mutation CreateNote(
  $input: CreateNoteInput!
  $condition: ModelNoteConditionInput
) {
  createNote(input: $input, condition: $condition) {
    userId
    content
    tourItemId
    id
    createdAt
    updatedAt
    tourItemNotesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNoteMutationVariables,
  APITypes.CreateNoteMutation
>;
export const updateNote = /* GraphQL */ `mutation UpdateNote(
  $input: UpdateNoteInput!
  $condition: ModelNoteConditionInput
) {
  updateNote(input: $input, condition: $condition) {
    userId
    content
    tourItemId
    id
    createdAt
    updatedAt
    tourItemNotesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNoteMutationVariables,
  APITypes.UpdateNoteMutation
>;
export const deleteNote = /* GraphQL */ `mutation DeleteNote(
  $input: DeleteNoteInput!
  $condition: ModelNoteConditionInput
) {
  deleteNote(input: $input, condition: $condition) {
    userId
    content
    tourItemId
    id
    createdAt
    updatedAt
    tourItemNotesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNoteMutationVariables,
  APITypes.DeleteNoteMutation
>;
export const createPhoto = /* GraphQL */ `mutation CreatePhoto(
  $input: CreatePhotoInput!
  $condition: ModelPhotoConditionInput
) {
  createPhoto(input: $input, condition: $condition) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemPhotosId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePhotoMutationVariables,
  APITypes.CreatePhotoMutation
>;
export const updatePhoto = /* GraphQL */ `mutation UpdatePhoto(
  $input: UpdatePhotoInput!
  $condition: ModelPhotoConditionInput
) {
  updatePhoto(input: $input, condition: $condition) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemPhotosId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePhotoMutationVariables,
  APITypes.UpdatePhotoMutation
>;
export const deletePhoto = /* GraphQL */ `mutation DeletePhoto(
  $input: DeletePhotoInput!
  $condition: ModelPhotoConditionInput
) {
  deletePhoto(input: $input, condition: $condition) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemPhotosId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePhotoMutationVariables,
  APITypes.DeletePhotoMutation
>;
export const createRecording = /* GraphQL */ `mutation CreateRecording(
  $input: CreateRecordingInput!
  $condition: ModelRecordingConditionInput
) {
  createRecording(input: $input, condition: $condition) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemRecordingsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRecordingMutationVariables,
  APITypes.CreateRecordingMutation
>;
export const updateRecording = /* GraphQL */ `mutation UpdateRecording(
  $input: UpdateRecordingInput!
  $condition: ModelRecordingConditionInput
) {
  updateRecording(input: $input, condition: $condition) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemRecordingsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRecordingMutationVariables,
  APITypes.UpdateRecordingMutation
>;
export const deleteRecording = /* GraphQL */ `mutation DeleteRecording(
  $input: DeleteRecordingInput!
  $condition: ModelRecordingConditionInput
) {
  deleteRecording(input: $input, condition: $condition) {
    userId
    file {
      bucket
      region
      key
      __typename
    }
    path
    createdAt
    tourItemId
    id
    updatedAt
    tourItemRecordingsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRecordingMutationVariables,
  APITypes.DeleteRecordingMutation
>;
export const createSchool = /* GraphQL */ `mutation CreateSchool(
  $input: CreateSchoolInput!
  $condition: ModelSchoolConditionInput
) {
  createSchool(input: $input, condition: $condition) {
    objVer
    name
    isCatholic
    isPublic
    isUseSchool
    gradeFrom
    gradeEnd
    isElementary
    isMiddle
    isHigh
    isEnglish
    isFrenchImmersion
    isExtendedFrench
    isAP
    isArts
    isGifted
    isIB
    isSport
    g3Avg
    g3WeightedAvg
    g6Avg
    g6WeightedAvg
    g9Avg
    g9APMWeightedAvg
    g9ACMWeightedAvg
    g10Avg
    ettieOverallAvg
    g3Rank
    g3RankTotal
    g6Rank
    g6RankTotal
    g9Rank
    g9RankTotal
    g9ACRank
    g9ACTotalRank
    g9APRank
    g9APTotalRank
    g10Rank
    g10RankTotal
    EQAOScore
    yearMark
    province
    nation
    url
    schoolBoardId
    schoolBoard {
      objVer
      name
      url
      boardType
      province
      nation
      address
      city
      postalCode
      phone
      fax
      id
      createdAt
      updatedAt
      __typename
    }
    ratings {
      nextToken
      __typename
    }
    address
    city
    postalCode
    phone
    fax
    latitude
    longitude
    comments
    boundaryArray
    schoolsAssocationArray
    lastReviewDate
    id
    createdAt
    updatedAt
    schoolBoardSchoolsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSchoolMutationVariables,
  APITypes.CreateSchoolMutation
>;
export const updateSchool = /* GraphQL */ `mutation UpdateSchool(
  $input: UpdateSchoolInput!
  $condition: ModelSchoolConditionInput
) {
  updateSchool(input: $input, condition: $condition) {
    objVer
    name
    isCatholic
    isPublic
    isUseSchool
    gradeFrom
    gradeEnd
    isElementary
    isMiddle
    isHigh
    isEnglish
    isFrenchImmersion
    isExtendedFrench
    isAP
    isArts
    isGifted
    isIB
    isSport
    g3Avg
    g3WeightedAvg
    g6Avg
    g6WeightedAvg
    g9Avg
    g9APMWeightedAvg
    g9ACMWeightedAvg
    g10Avg
    ettieOverallAvg
    g3Rank
    g3RankTotal
    g6Rank
    g6RankTotal
    g9Rank
    g9RankTotal
    g9ACRank
    g9ACTotalRank
    g9APRank
    g9APTotalRank
    g10Rank
    g10RankTotal
    EQAOScore
    yearMark
    province
    nation
    url
    schoolBoardId
    schoolBoard {
      objVer
      name
      url
      boardType
      province
      nation
      address
      city
      postalCode
      phone
      fax
      id
      createdAt
      updatedAt
      __typename
    }
    ratings {
      nextToken
      __typename
    }
    address
    city
    postalCode
    phone
    fax
    latitude
    longitude
    comments
    boundaryArray
    schoolsAssocationArray
    lastReviewDate
    id
    createdAt
    updatedAt
    schoolBoardSchoolsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSchoolMutationVariables,
  APITypes.UpdateSchoolMutation
>;
export const deleteSchool = /* GraphQL */ `mutation DeleteSchool(
  $input: DeleteSchoolInput!
  $condition: ModelSchoolConditionInput
) {
  deleteSchool(input: $input, condition: $condition) {
    objVer
    name
    isCatholic
    isPublic
    isUseSchool
    gradeFrom
    gradeEnd
    isElementary
    isMiddle
    isHigh
    isEnglish
    isFrenchImmersion
    isExtendedFrench
    isAP
    isArts
    isGifted
    isIB
    isSport
    g3Avg
    g3WeightedAvg
    g6Avg
    g6WeightedAvg
    g9Avg
    g9APMWeightedAvg
    g9ACMWeightedAvg
    g10Avg
    ettieOverallAvg
    g3Rank
    g3RankTotal
    g6Rank
    g6RankTotal
    g9Rank
    g9RankTotal
    g9ACRank
    g9ACTotalRank
    g9APRank
    g9APTotalRank
    g10Rank
    g10RankTotal
    EQAOScore
    yearMark
    province
    nation
    url
    schoolBoardId
    schoolBoard {
      objVer
      name
      url
      boardType
      province
      nation
      address
      city
      postalCode
      phone
      fax
      id
      createdAt
      updatedAt
      __typename
    }
    ratings {
      nextToken
      __typename
    }
    address
    city
    postalCode
    phone
    fax
    latitude
    longitude
    comments
    boundaryArray
    schoolsAssocationArray
    lastReviewDate
    id
    createdAt
    updatedAt
    schoolBoardSchoolsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSchoolMutationVariables,
  APITypes.DeleteSchoolMutation
>;
export const createSchoolBoard = /* GraphQL */ `mutation CreateSchoolBoard(
  $input: CreateSchoolBoardInput!
  $condition: ModelSchoolBoardConditionInput
) {
  createSchoolBoard(input: $input, condition: $condition) {
    objVer
    name
    url
    boardType
    province
    nation
    address
    city
    postalCode
    phone
    fax
    schools {
      nextToken
      __typename
    }
    scores {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSchoolBoardMutationVariables,
  APITypes.CreateSchoolBoardMutation
>;
export const updateSchoolBoard = /* GraphQL */ `mutation UpdateSchoolBoard(
  $input: UpdateSchoolBoardInput!
  $condition: ModelSchoolBoardConditionInput
) {
  updateSchoolBoard(input: $input, condition: $condition) {
    objVer
    name
    url
    boardType
    province
    nation
    address
    city
    postalCode
    phone
    fax
    schools {
      nextToken
      __typename
    }
    scores {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSchoolBoardMutationVariables,
  APITypes.UpdateSchoolBoardMutation
>;
export const deleteSchoolBoard = /* GraphQL */ `mutation DeleteSchoolBoard(
  $input: DeleteSchoolBoardInput!
  $condition: ModelSchoolBoardConditionInput
) {
  deleteSchoolBoard(input: $input, condition: $condition) {
    objVer
    name
    url
    boardType
    province
    nation
    address
    city
    postalCode
    phone
    fax
    schools {
      nextToken
      __typename
    }
    scores {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSchoolBoardMutationVariables,
  APITypes.DeleteSchoolBoardMutation
>;
export const createSchoolBoardScore = /* GraphQL */ `mutation CreateSchoolBoardScore(
  $input: CreateSchoolBoardScoreInput!
  $condition: ModelSchoolBoardScoreConditionInput
) {
  createSchoolBoardScore(input: $input, condition: $condition) {
    schoolBoardId
    schoolBoard {
      objVer
      name
      url
      boardType
      province
      nation
      address
      city
      postalCode
      phone
      fax
      id
      createdAt
      updatedAt
      __typename
    }
    yearMark
    year
    totalAvg
    g3STU
    g6STU
    g9ACSTU
    g9APSTU
    g3TS
    g6TS
    g9TS
    g10OSSLTFSTUA
    g10OSSLTFSTUP
    g10OSSLTFSUCCESS
    g3AR
    g3AW
    g3AM
    g6AR
    g6AW
    g6AM
    g9APM
    g9ACM
    g3Last5Avg
    g6Last5Avg
    g9Last5Avg
    g3FindSchoolRating
    g6FindSchoolRating
    g9FindSchoolRating
    id
    createdAt
    updatedAt
    schoolBoardScoresId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSchoolBoardScoreMutationVariables,
  APITypes.CreateSchoolBoardScoreMutation
>;
export const updateSchoolBoardScore = /* GraphQL */ `mutation UpdateSchoolBoardScore(
  $input: UpdateSchoolBoardScoreInput!
  $condition: ModelSchoolBoardScoreConditionInput
) {
  updateSchoolBoardScore(input: $input, condition: $condition) {
    schoolBoardId
    schoolBoard {
      objVer
      name
      url
      boardType
      province
      nation
      address
      city
      postalCode
      phone
      fax
      id
      createdAt
      updatedAt
      __typename
    }
    yearMark
    year
    totalAvg
    g3STU
    g6STU
    g9ACSTU
    g9APSTU
    g3TS
    g6TS
    g9TS
    g10OSSLTFSTUA
    g10OSSLTFSTUP
    g10OSSLTFSUCCESS
    g3AR
    g3AW
    g3AM
    g6AR
    g6AW
    g6AM
    g9APM
    g9ACM
    g3Last5Avg
    g6Last5Avg
    g9Last5Avg
    g3FindSchoolRating
    g6FindSchoolRating
    g9FindSchoolRating
    id
    createdAt
    updatedAt
    schoolBoardScoresId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSchoolBoardScoreMutationVariables,
  APITypes.UpdateSchoolBoardScoreMutation
>;
export const deleteSchoolBoardScore = /* GraphQL */ `mutation DeleteSchoolBoardScore(
  $input: DeleteSchoolBoardScoreInput!
  $condition: ModelSchoolBoardScoreConditionInput
) {
  deleteSchoolBoardScore(input: $input, condition: $condition) {
    schoolBoardId
    schoolBoard {
      objVer
      name
      url
      boardType
      province
      nation
      address
      city
      postalCode
      phone
      fax
      id
      createdAt
      updatedAt
      __typename
    }
    yearMark
    year
    totalAvg
    g3STU
    g6STU
    g9ACSTU
    g9APSTU
    g3TS
    g6TS
    g9TS
    g10OSSLTFSTUA
    g10OSSLTFSTUP
    g10OSSLTFSUCCESS
    g3AR
    g3AW
    g3AM
    g6AR
    g6AW
    g6AM
    g9APM
    g9ACM
    g3Last5Avg
    g6Last5Avg
    g9Last5Avg
    g3FindSchoolRating
    g6FindSchoolRating
    g9FindSchoolRating
    id
    createdAt
    updatedAt
    schoolBoardScoresId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSchoolBoardScoreMutationVariables,
  APITypes.DeleteSchoolBoardScoreMutation
>;
export const createSchoolRating = /* GraphQL */ `mutation CreateSchoolRating(
  $input: CreateSchoolRatingInput!
  $condition: ModelSchoolRatingConditionInput
) {
  createSchoolRating(input: $input, condition: $condition) {
    objVer
    schoolId
    school {
      objVer
      name
      isCatholic
      isPublic
      isUseSchool
      gradeFrom
      gradeEnd
      isElementary
      isMiddle
      isHigh
      isEnglish
      isFrenchImmersion
      isExtendedFrench
      isAP
      isArts
      isGifted
      isIB
      isSport
      g3Avg
      g3WeightedAvg
      g6Avg
      g6WeightedAvg
      g9Avg
      g9APMWeightedAvg
      g9ACMWeightedAvg
      g10Avg
      ettieOverallAvg
      g3Rank
      g3RankTotal
      g6Rank
      g6RankTotal
      g9Rank
      g9RankTotal
      g9ACRank
      g9ACTotalRank
      g9APRank
      g9APTotalRank
      g10Rank
      g10RankTotal
      EQAOScore
      yearMark
      province
      nation
      url
      schoolBoardId
      address
      city
      postalCode
      phone
      fax
      latitude
      longitude
      comments
      boundaryArray
      schoolsAssocationArray
      lastReviewDate
      id
      createdAt
      updatedAt
      schoolBoardSchoolsId
      __typename
    }
    yearMark
    year
    G3STU
    G3AR
    G3AW
    G3AM
    G3Rank
    G3Total
    G3FindSchoolRating
    G6STU
    G6AR
    G6AW
    G6AM
    G6Rank
    G6Total
    G6FindSchoolRating
    G9ACSTU
    G9ACM
    G9ACRank
    G9ACTotal
    G9APSTU
    G9APM
    G9APRank
    G9APTotal
    G9Rank
    G9Total
    G9FindSchoolRating
    G10OSSLTFSTUA
    G10OSSLTFSTUP
    G10OSSLTFSUCCESS
    G3Last5Avg
    G6Last5Avg
    G9Last5Avg
    id
    createdAt
    updatedAt
    schoolRatingsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSchoolRatingMutationVariables,
  APITypes.CreateSchoolRatingMutation
>;
export const updateSchoolRating = /* GraphQL */ `mutation UpdateSchoolRating(
  $input: UpdateSchoolRatingInput!
  $condition: ModelSchoolRatingConditionInput
) {
  updateSchoolRating(input: $input, condition: $condition) {
    objVer
    schoolId
    school {
      objVer
      name
      isCatholic
      isPublic
      isUseSchool
      gradeFrom
      gradeEnd
      isElementary
      isMiddle
      isHigh
      isEnglish
      isFrenchImmersion
      isExtendedFrench
      isAP
      isArts
      isGifted
      isIB
      isSport
      g3Avg
      g3WeightedAvg
      g6Avg
      g6WeightedAvg
      g9Avg
      g9APMWeightedAvg
      g9ACMWeightedAvg
      g10Avg
      ettieOverallAvg
      g3Rank
      g3RankTotal
      g6Rank
      g6RankTotal
      g9Rank
      g9RankTotal
      g9ACRank
      g9ACTotalRank
      g9APRank
      g9APTotalRank
      g10Rank
      g10RankTotal
      EQAOScore
      yearMark
      province
      nation
      url
      schoolBoardId
      address
      city
      postalCode
      phone
      fax
      latitude
      longitude
      comments
      boundaryArray
      schoolsAssocationArray
      lastReviewDate
      id
      createdAt
      updatedAt
      schoolBoardSchoolsId
      __typename
    }
    yearMark
    year
    G3STU
    G3AR
    G3AW
    G3AM
    G3Rank
    G3Total
    G3FindSchoolRating
    G6STU
    G6AR
    G6AW
    G6AM
    G6Rank
    G6Total
    G6FindSchoolRating
    G9ACSTU
    G9ACM
    G9ACRank
    G9ACTotal
    G9APSTU
    G9APM
    G9APRank
    G9APTotal
    G9Rank
    G9Total
    G9FindSchoolRating
    G10OSSLTFSTUA
    G10OSSLTFSTUP
    G10OSSLTFSUCCESS
    G3Last5Avg
    G6Last5Avg
    G9Last5Avg
    id
    createdAt
    updatedAt
    schoolRatingsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSchoolRatingMutationVariables,
  APITypes.UpdateSchoolRatingMutation
>;
export const deleteSchoolRating = /* GraphQL */ `mutation DeleteSchoolRating(
  $input: DeleteSchoolRatingInput!
  $condition: ModelSchoolRatingConditionInput
) {
  deleteSchoolRating(input: $input, condition: $condition) {
    objVer
    schoolId
    school {
      objVer
      name
      isCatholic
      isPublic
      isUseSchool
      gradeFrom
      gradeEnd
      isElementary
      isMiddle
      isHigh
      isEnglish
      isFrenchImmersion
      isExtendedFrench
      isAP
      isArts
      isGifted
      isIB
      isSport
      g3Avg
      g3WeightedAvg
      g6Avg
      g6WeightedAvg
      g9Avg
      g9APMWeightedAvg
      g9ACMWeightedAvg
      g10Avg
      ettieOverallAvg
      g3Rank
      g3RankTotal
      g6Rank
      g6RankTotal
      g9Rank
      g9RankTotal
      g9ACRank
      g9ACTotalRank
      g9APRank
      g9APTotalRank
      g10Rank
      g10RankTotal
      EQAOScore
      yearMark
      province
      nation
      url
      schoolBoardId
      address
      city
      postalCode
      phone
      fax
      latitude
      longitude
      comments
      boundaryArray
      schoolsAssocationArray
      lastReviewDate
      id
      createdAt
      updatedAt
      schoolBoardSchoolsId
      __typename
    }
    yearMark
    year
    G3STU
    G3AR
    G3AW
    G3AM
    G3Rank
    G3Total
    G3FindSchoolRating
    G6STU
    G6AR
    G6AW
    G6AM
    G6Rank
    G6Total
    G6FindSchoolRating
    G9ACSTU
    G9ACM
    G9ACRank
    G9ACTotal
    G9APSTU
    G9APM
    G9APRank
    G9APTotal
    G9Rank
    G9Total
    G9FindSchoolRating
    G10OSSLTFSTUA
    G10OSSLTFSTUP
    G10OSSLTFSUCCESS
    G3Last5Avg
    G6Last5Avg
    G9Last5Avg
    id
    createdAt
    updatedAt
    schoolRatingsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSchoolRatingMutationVariables,
  APITypes.DeleteSchoolRatingMutation
>;
export const createProjectAmenities = /* GraphQL */ `mutation CreateProjectAmenities(
  $input: CreateProjectAmenitiesInput!
  $condition: ModelProjectAmenitiesConditionInput
) {
  createProjectAmenities(input: $input, condition: $condition) {
    id
    projectID
    amenityID
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    amenity {
      name
      description
      id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectAmenitiesMutationVariables,
  APITypes.CreateProjectAmenitiesMutation
>;
export const updateProjectAmenities = /* GraphQL */ `mutation UpdateProjectAmenities(
  $input: UpdateProjectAmenitiesInput!
  $condition: ModelProjectAmenitiesConditionInput
) {
  updateProjectAmenities(input: $input, condition: $condition) {
    id
    projectID
    amenityID
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    amenity {
      name
      description
      id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectAmenitiesMutationVariables,
  APITypes.UpdateProjectAmenitiesMutation
>;
export const deleteProjectAmenities = /* GraphQL */ `mutation DeleteProjectAmenities(
  $input: DeleteProjectAmenitiesInput!
  $condition: ModelProjectAmenitiesConditionInput
) {
  deleteProjectAmenities(input: $input, condition: $condition) {
    id
    projectID
    amenityID
    project {
      name
      streetNumber
      streetName
      city
      province
      latitude
      longitude
      builderId
      architectId
      interiorDesignerId
      occupancy
      units
      stories
      startPrice
      endPrice
      minBeds
      maxBeds
      minSqft
      maxSqft
      pricePerSqft
      maintenanceFees
      parkingPrice
      lockerPrice
      phase
      availability
      types
      description
      incentives
      depositStructure
      logo
      media
      lastUpdatedOn
      isSoldOut
      commission
      id
      createdAt
      updatedAt
      builderProjectsId
      architectProjectsId
      interiorDesignerProjectsId
      __typename
    }
    amenity {
      name
      description
      id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectAmenitiesMutationVariables,
  APITypes.DeleteProjectAmenitiesMutation
>;
