import {
  IonContent,
  IonIcon,
  IonLabel,
  IonItem,
  IonList,
  IonPage,
  IonSkeletonText,
  IonThumbnail,
  IonSpinner,
} from "@ionic/react";
import Header from "../../../components/web/Header/header.component";
import Map from "../../../components/Map/map.component";
import styles from "./search.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  selectExtraFilters,
  selectFiltersValue,
} from "../../../redux/filters/filters.selectors";
import { selectMapLocations } from "../../../redux/map/map.selectors";
import { updateFilters } from "../../../redux/filters/filters.actions";
import { selectSchools } from "../../../redux/schools/schools.selectors";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import googleMapReact from "google-map-react";
import { updateMapRef } from "../../../redux/map/map.actions";
import { Listing } from "../../../models/listings/listing.model";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import ListingListItem from "../../../components/shared/ListingListItem/listing-list-item.component";
import { MapService } from "../../../services/mapService";
import SortButton from "../../../components/web/SortButton/sort-button.component";
import { ListingsSortItems } from "../../../utils/constants";
import { SortOptionValue } from "../../../types/shared/SortOption.type";
import { ListingsSortOptions } from "../../../enums/Listings/ListingsSortOptions.enum";
import SearchFilters from "../../../components/web/SearchFilters/search-filters.component";
import ListingCard from "components/shared/ListingCard/listing-card.component";

const Search = () => {
  const dispatch = useDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [mapReady, setMapReady] = useState(false);
  const [sortBy, setSortBy] = useState<ListingsSortOptions>(
    ListingsSortOptions.CREATED_ON
  );
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [clusters, setClusters] = useState<any[]>([]);
  const [selectedCluster, setSelectedCluster] = useState<any>();
  const [clustersLoading, setClustersLoading] = useState(true);
  const [listingsLoading, setListingsLoading] = useState(true);
  const [listings, setListings] = useState<Listing[]>([]);
  const [selectedListings, setSelectedListings] = useState<Listing[]>([]);
  const [totalListingsPages, setTotalListingPages] = useState(1);
  const [listingsCollapsed, setListingsCollapsed] = useState(false);
  const [view, setView] = useState<"map" | "list">("map");
  const filters = useSelector(selectFiltersValue);
  const extraFilters = useSelector(selectExtraFilters);
  const mapLocations = useSelector(selectMapLocations);
  const schools = useSelector(selectSchools);

  const updateMapFilter = (update: { map: any[][][] }) => {
    if (update.map.flat(2).includes(undefined)) {
      return;
    }
    dispatch(updateFilters(update));
  };

  const handleMapReady = (mapRef: MutableRefObject<googleMapReact>) => {
    dispatch(updateMapRef(mapRef));
    setMapReady(true);
  };

  const handleSort = (value: SortOptionValue) => {
    setSortBy(value as ListingsSortOptions);
  };

  const handleChangeSortOrder = () => {
    setSortOrder((order) => (order === "asc" ? "desc" : "asc"));
  };

  const handleListingsListScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current!;

    const nextPage = listings.length / 10 + 1;
    if (
      scrollTop + clientHeight >= scrollHeight - 100 &&
      nextPage <= totalListingsPages
    ) {
      fetchListings(nextPage);
    }
  };

  const handleSelectCluster = async (cluster: any) => {
    try {
      setSelectedListings([]);
      setSelectedCluster(cluster);
      const clusterListings = await MapService.getClusterListings(cluster.map);
      setSelectedListings(clusterListings);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectListings = (listings: Listing[]) => {
    setSelectedCluster(null);
    setSelectedListings(listings);
  };

  const handleDeselectClusterAndListings = () => {
    setSelectedCluster(undefined);
    setSelectedListings([]);
  };

  const handleUpdateView = (view: "map" | "list") => {
    setListingsCollapsed(false);
    setView(view);
  };

  const fetchClusters = async () => {
    try {
      setClustersLoading(true);
      const clusters = await MapService.getClusters();
      setClusters(clusters);
    } catch (error) {
      console.error(error);
    } finally {
      setClustersLoading(false);
    }
  };

  const fetchListings = async (page: number) => {
    try {
      setListingsLoading(true);
      const response = await MapService.getListings(page, sortBy, sortOrder);
      setListings((prevListings) => [...prevListings, ...response.listings]);
      const totalPages = Math.ceil(response.count / 10);
      setTotalListingPages(totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setListingsLoading(false);
    }
  };

  useEffect(() => {
    if (mapReady) {
      fetchClusters();
    }
  }, [filters, extraFilters, schools.filters.showListingsWithin, mapReady]);

  useEffect(() => {
    if (mapReady) {
      setTotalListingPages(1);
      setListings([]);
      fetchListings(1);
    }
  }, [clusters]);

  useEffect(() => {
    if (mapReady) {
      setListings([]);
      fetchListings(1);
    }
  }, [sortBy, sortOrder]);

  return (
    <IonPage>
      <IonContent className={styles.content}>
        <div className={styles.container}>
          <Header type="search" />
          <SearchFilters view={view} updateView={handleUpdateView} />
          <div
            className={`${styles.listingsMap} ${
              listingsCollapsed && styles.listingsCollapsed
            }`}>
            <div
              ref={scrollRef}
              onScroll={handleListingsListScroll}
              className={`${styles.listings} ${
                view === "list" && styles.fullSizeListings
              }`}>
              <div className={styles.listingsHeader}>
                <h3>Listings</h3>
                <SortButton
                  value={sortBy}
                  order={sortOrder}
                  items={ListingsSortItems}
                  onSelect={handleSort}
                  onChangeOrder={handleChangeSortOrder}
                />
                <div className={styles.collapseExpandButton}>
                  <button
                    onClick={() =>
                      setListingsCollapsed((collapsed) => !collapsed)
                    }>
                    <IonIcon
                      icon={
                        listingsCollapsed
                          ? chevronForwardOutline
                          : chevronBackOutline
                      }
                    />
                  </button>
                </div>
              </div>

              <div className={styles.listingsList}>
                {listingsLoading && !listings.length ? (
                  <div className={styles.loading}>
                    <IonList>
                      {Array.from({ length: 10 }).map((_, i) => (
                        <IonItem key={i} lines="none">
                          <IonThumbnail slot="start">
                            <IonSkeletonText animated />
                          </IonThumbnail>
                          <IonLabel>
                            <h3>
                              <IonSkeletonText
                                animated={true}
                                style={{ width: "80%" }}></IonSkeletonText>
                            </h3>
                            <p>
                              <IonSkeletonText
                                animated={true}
                                style={{ width: "60%" }}></IonSkeletonText>
                            </p>
                            <p>
                              <IonSkeletonText
                                animated={true}
                                style={{ width: "30%" }}></IonSkeletonText>
                            </p>
                          </IonLabel>
                        </IonItem>
                      ))}
                    </IonList>
                  </div>
                ) : listings.length > 0 ? (
                  listings.map((listing, i) =>
                    view === "list" ? (
                      <ListingCard key={i} listing={listing} />
                    ) : (
                      <ListingListItem key={i} listing={listing} />
                    )
                  )
                ) : (
                  <div className={styles.noListings}>
                    Uh oh! There are no matching results for your current
                    search. Try zooming out or adjusting your filters.
                  </div>
                )}
                {listingsLoading && listings.length > 0 && (
                  <IonSpinner name="bubbles" />
                )}
              </div>
            </div>
            <div className={styles.map}>
              {mapReady && !clustersLoading && !clusters.length && (
                <div className={styles.noClusters}>
                  No listings found in the current map area.
                </div>
              )}
              <Map
                filters={filters}
                extraFilters={extraFilters}
                mapLocations={mapLocations}
                updateBoundaryFilter={updateMapFilter}
                clusters={clusters}
                selectedCluster={selectedCluster}
                onSelectCluster={handleSelectCluster}
                selectedListings={selectedListings}
                onSelectListings={handleSelectListings}
                onDeselectClusterAndListings={handleDeselectClusterAndListings}
                schools={schools}
                view={view}
                setView={setView}
                onMapReady={handleMapReady}
              />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Search;
