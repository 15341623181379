import styles from "./listing-header.module.scss";
import { IonBackButton, IonIcon } from "@ionic/react";
import { arrowBackOutline, closeOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import { selectSelectedTab } from "../../../../../redux/ui/ui.selectors";
import { useHistory } from "react-router";

const ListingHeader = () => {
  const history = useHistory();
  const selectedTab = useSelector(selectSelectedTab);

  const handleClose = () => {
    history.replace(`/tabs/${selectedTab}`, { direction: "back" });
  };

  return (
    <div className={styles.header}>
      <IonBackButton
        text=""
        defaultHref="/tabs/map"
        className={`${styles.btn} ${styles.icon}`}
        icon={arrowBackOutline}></IonBackButton>
      {/* <span
        onClick={handleClose}
        className={`${styles.closeBtn} ${styles.btn}`}>
        <IonIcon icon={closeOutline} className={styles.icon} />
      </span> */}
    </div>
  );
};

export default ListingHeader;
