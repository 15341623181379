import { IonIcon } from "@ionic/react";
import LocationIcon from "../../../../../../assets/svg/location-pin.svg";
import SqftIcon from "../../../../../../assets/svg/REA103_Icons-01a_SquareFeet.svg";
import {
  capitalize,
  numberWithCommas,
} from "../../../../../../utils/functions";
import styles from "./pre-con-description.module.scss";
import {
  bedOutline,
  businessOutline,
  flag,
  homeOutline,
  newspaperOutline,
} from "ionicons/icons";

const PROJECT_STATUS = {
  preconstruction: "Pre construction",
  underconstruction: "Under construction",
  completed: "Completed",
};

const PreConDescription = ({ project }) => {
  if (!project) return <div>Not available</div>;

  const {
    province,
    city,
    streetName,
    streetNumber,
    builder,
    types,
    phase,
    units,
    stories,
    minBeds,
    maxBeds,
    minSqft,
    maxSqft,
    pricePerSqft,
    description,
  } = project;

  return (
    <div className={styles.descriptionContainer}>
      <div className={styles.title}>Description</div>
      <div className={styles.row}>
        <div>
          <IonIcon src={LocationIcon} className={styles.icon} />
          <span>{`${streetNumber} ${streetName}, ${city}, ${province}`}</span>
        </div>
        <div>
          <IonIcon icon={flag} className={styles.icon} />
          <span>{builder ? builder.name : "-"}</span>
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <IonIcon icon={homeOutline} className={styles.icon} />
          <span>
            {types && types.length > 0
              ? types.map((type, i) => <span key={i}>{capitalize(type)}</span>)
              : "-"}
          </span>
        </div>
        <div>
          <IonIcon icon={newspaperOutline} className={styles.icon} />
          <span>{phase ? PROJECT_STATUS[phase] : "-"}</span>
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <IonIcon icon={businessOutline} className={styles.icon} />
          <span>
            {units ? units : "-"} Units | {stories ? stories : "-"} Stories
          </span>
        </div>
        <div>
          <IonIcon icon={bedOutline} className={styles.icon} />
          <span>
            {minBeds} {maxBeds !== 0 && `- ${maxBeds}`} Bedrooms
          </span>
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <IonIcon src={SqftIcon} className={styles.icon} />
          <span>
            {minSqft !== 0 && `${numberWithCommas(minSqft)} - `}{" "}
            {maxSqft !== 0 ? numberWithCommas(maxSqft) : "-"} sqft
          </span>
        </div>
        <div>
          <IonIcon src={SqftIcon} className={styles.icon} />
          <span>
            {pricePerSqft !== 0 ? `$${numberWithCommas(pricePerSqft)}` : "-"}{" "}
            per SqFt
          </span>
        </div>
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default PreConDescription;
