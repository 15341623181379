import { Photo } from "@capacitor/camera";
import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

const upload = async (
  object: File | Blob | Buffer,
  contentType: string
): Promise<{ key: string; fileName: string }> => {
  const name = uuidv4();
  const result = await Storage.put(name, object, { contentType });
  return { key: result.key, fileName: name };
};

const addPhoto = async (file: Photo) => {
  if (!file.base64String) {
    throw new Error("Invalid file");
  }

  const buf = Buffer.from(file.base64String, "base64");
  
  return await upload(buf, "image/jpeg");
};

const addRecording = async (data: string) => {
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "audio/wav" });

  return await upload(blob, "audio/wav");
};

const deleteItem = async (key: string) => {
  await Storage.remove(key);
};

export const StorageService = {
  addPhoto,
  addRecording,
  deleteItem,
};
