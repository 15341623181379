export const rightSection = {
  en: {
    login: "Sign in",
    register: "Register",
    share: "Share this listing",
    contact: "Contact or request viewing",
    request: "Request a viewing",
    offer: "Make an offer",
  },
};

export const detailPageHeader = {
  en: {
    favourites: "Return to favourites",
    listing: "Back",
    search: "Return to listings",
    open: "Open page in new tab",
  },
};

export const detailPageButtons = {
  en: {
    virtualTour: "Virtual tour",
    mapView: "View on map",
    navigate: "Navigate",
    gallery: "Image view",
  },
};

export const detailPageDescription = {
  en: {
    description: "Description",
    bed: "bed",
    bath: "bath",
    parking: "parking",
    offer: "Make an offer",
  },
};

export const detailPageInformation = {
  en: {
    title: "Property information",
    essentials: "Essentials",
    details: "Details",
    rooms: "Specifications",
    priceHistory: "Listing history",
    schools: "Schools",
    marketData: "Market Data",
    calculators: "Calculators",
    noPriceHistory: "No listing history available",
  },
};

export const overview = {
  en: {
    details: "Details",
    mlsNumber: "MLS® Number",
    streetaddress: "Street address",
    postalCode: "Postal code",
    price: "List price",
    status: "Status",
    listed: "Listed",
    unitNumber: "Unit number",
    type: "Property type",
    taxes: "Taxes",
    purpose: "Purpose",
    condoType: "Property type",
    area: "Area/Community",
    mainIntersection: "Main intersection",
    building: "Building",
    age: "Building age",
    propertyStyle: "Property style",
    exposure: "Exposure",
    lotSize: "Lot size",
    lotFrontage: "Lot frontage",
    lotDepth: "Lot depth",
    sqft: "Sqft",
    parking: "Parking",
    garageType: "Garage type",
    garage: "Garage parking",
    openParking: "Open parking",
    totalParking: "Total parking spaces",
    pets: "Pets",
    pool: "Swimming pool",
    amenities: "Amenities",
    sewers: "Sewers",
    interior: "Interior",
    bedrooms: "Bedrooms",
    bathrooms: "Bathrooms",
    balcony: "Balcony",
    basement: "Basement",
    locker: "Locker",
    utilities: "Utilities",
    fees: "Maintenance/Condo Fees",
    air: "Air Conditioning",
    heat: "Heat",
    waterIncluded: "Water included",
    hydroIncluded: "Hydro included",
    heatIncluded: "Heat included",
    brokerage: "Brokerage",
    possessionDate: "Possession date",
  },
};

export const rooms = {
  en: {
    name: "Name",
    size: "Size",
    feature: "Feature",
  },
};

export const priceHistory = {
  en: {
    dateStart: "Listing date",
    dateEnd: "Sold/Leased Date",
    price: "Original Price",
    soldPrice: "Sold/Leased Price:",
    status: "Status",
    id: "MLS#",
    msg: {
      please: "Please",
      register: "register",
      or: "or",
      login: "sign in",
      rest: "to view this information",
    },
  },
};
