import { useSelector } from "react-redux";
import moment from "moment";
import millify from "millify";
import numeral from "numeral";
import { IonIcon } from "@ionic/react";
import {
  arrowDownOutline,
  arrowUpOutline,
  checkmarkOutline,
} from "ionicons/icons";
import styles from "./right-navbar.module.scss";
import NewStatus from "./components/NewStatus/new-status.component";
import SoldStatus from "./components/soldStatus/sold-status.component";
import { selectCurrentClient } from "../../../../../../redux/client/client.selectors";
import { selectCurrencyRates } from "../../../../../../redux/currency/currency.selectors";
import {
  diffDate,
  getPricesPercentageDiff,
  getTagTitle,
  renderEstimate,
} from "../../../../../../utils/functions";

const RightNavbar = ({
  listing,
  estimate,
  shareListingHandler,
  requestFormHandler,
  offerFormHandler,
  language,
}) => {
  const client = useSelector(selectCurrentClient);
  const rates = useSelector(selectCurrencyRates);
  const {
    listDate,
    soldDate,
    type,
    status,
    lastStatus,
    listPrice,
    soldPrice,
    updatedOn,
  } = listing;

  let currency;
  if (client && rates) {
    if (client.currency !== "cad") currency = client.currency.toUpperCase();
  }

  const renderPriceDiff = () => {
    const renderListPrice = () => (
      <span className={styles.listPrice}>
        List Price: ${millify(listPrice, { precision: 2 })}
      </span>
    );
    let diff = soldPrice - listPrice;
    if (currency) diff = (+diff / rates.CAD) * rates[currency];

    return (
      <div className={styles.priceContainer}>
        <span
          className={
            diff > 0
              ? styles.increased
              : diff < 0
              ? styles.decreased
              : styles.asking
          }>
          <IonIcon
            icon={
              diff > 0
                ? arrowUpOutline
                : diff < 0
                ? arrowDownOutline
                : checkmarkOutline
            }
          />
          {diff === 0 ? (
            <span className={styles.text}>
              {type.toLowerCase() === "sale" ? "sold" : "leased"} at asking
            </span>
          ) : (
            <span className={styles.value}>
              {millify(diff, { precision: 2 })}{" "}
              {diff > 0 ? "above asking" : "below asking"}
            </span>
          )}
        </span>
        {diff !== 0 && renderListPrice()}
      </div>
    );
  };

  const renderEstimateWithDiff = () => {
    if (!estimate) return;
    const estimateValue = renderEstimate(currency, estimate, rates);
    const price = listing.soldPrice > 0 ? listing.soldPrice : listing.listPrice;

    const difference = getPricesPercentageDiff(price, estimate);

    return (
      <div className={styles.estimate}>
        <span className={styles.estimateValue}>
          Estimated value: {estimateValue}
        </span>
        <span className={styles.estimateDiff}>
          {difference > 0 ? (
            <span className={styles.increased}>
              <IonIcon icon={arrowUpOutline} />
              <span className={styles.diffText}>{difference}%</span>
            </span>
          ) : (
            <span className={styles.decreased}>
              <IonIcon icon={arrowDownOutline} />
              <span className={styles.diffText}>{difference}%</span>
            </span>
          )}
        </span>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {status === "A" ? (
        <NewStatus
          shareListingHandler={shareListingHandler}
          requestFormHandler={requestFormHandler}
          offerFormHandler={offerFormHandler}
          language={language}
          type={type}
          lastStatus={lastStatus}
          renderEstimate={renderEstimateWithDiff}
          listDate={diffDate(status, listDate, soldDate).label}
          listPrice={
            currency
              ? `${currency} ${millify(
                  (+listPrice / rates.CAD) * rates[currency]
                )}`
              : numeral(listPrice).format("$1,000,000")
          }
          estimate={estimate}
        />
      ) : (
        <SoldStatus
          listDate={moment.utc(soldDate ? soldDate : updatedOn)}
          soldPrice={
            currency
              ? `${currency} ${millify(
                  (+soldPrice !== 0 ? +soldPrice : +listPrice / rates.CAD) *
                    rates[currency]
                )}`
              : numeral(+soldPrice !== 0 ? soldPrice : listPrice).format(
                  "$1,000,000"
                )
          }
          lastStatus={lastStatus}
          status={getTagTitle(lastStatus, type)}
          diffDate={
            diffDate(status, moment.utc(listDate), moment.utc(soldDate))
              .soldNumber
          }
          renderPriceDiff={renderPriceDiff}
          requestFormHandler={requestFormHandler}
          language={language}
        />
      )}
    </div>
  );
};

export default RightNavbar;
