import { IonIcon } from "@ionic/react";
import { Location } from "../../../models/locations/locations.model";
import styles from "./location-search-result.module.scss";
import { businessOutline, peopleCircle } from "ionicons/icons";

const LocationSearchResult = ({
  location,
  type,
  onClick,
}: {
  location: Location;
  type: "city" | "neighborhood";
  onClick: (location: Location) => void;
}) => {
  return (
    <div className={styles.container} onClick={() => onClick(location)}>
      <div className={styles.info}>
        <IonIcon
          icon={type === "city" ? businessOutline : peopleCircle}
        />
        <p>{location.name}</p>
      </div>
      <span className={styles.type}>
        {type === "city" ? "City" : "Neighborhood"}
      </span>
    </div>
  );
};

export default LocationSearchResult;
