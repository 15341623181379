import { useState, useEffect, useRef, useCallback } from "react";
import Card from "../../components/Card/card.component";
import AddFavourite from "./component/add-favourite.component";
import EditBar from "../../components/EditBar/edit-bar.component";
import withAuthentication from "../../HOC/withAuthentication/with-authentication";
import styles from "./favorite-listings.module.scss";
import withClient from "../../HOC/withClient/with-client";
import Footer from "../../components/Footer/footer.component";
import FavoriteItem from "./component/favorite-item.component";
import { API } from "@aws-amplify/api";
import {
  onFavouriteUpdateByUserId,
  onPreFavouriteUpdateByUserId,
} from "../../graphql/subscriptions";
import {
  getPreFavouriteItems,
  fetchFavouriteItems,
} from "../../amplify/graphql.utils";
import LoadingFullPage from "../../components/Loading/loading-full-page.component";

const FavoriteListings = ({ currentClient }) => {
  const scrollRef = useRef();
  const [loading, setLoading] = useState(true);
  const [sortedPreFavourits, setSortedPreFavourites] = useState();
  const [sortedFavourites, setSortedFavourites] = useState();
  const [sortBy, setSortBy] = useState({
    value: "date",
    direction: "desc",
  });

  useEffect(() => {
    let subscription, preSubscription;

    const subscribe = async () => {
      subscription = API.graphql({
        query: onFavouriteUpdateByUserId,
        variables: { userId: currentClient.id },
      }).subscribe({
        next: () => fetchFavourites(),
      });

      preSubscription = API.graphql({
        query: onPreFavouriteUpdateByUserId,
        variables: { userId: currentClient.id },
      }).subscribe({
        next: () => fetchFavourites(),
      });
    };

    const fetchFavourites = async () => {
      setLoading(true);
      try {
        const favourites = await fetchFavouriteItems();
        setSortedFavourites(
          favourites.length
            ? favourites.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            : null
        );
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    const fetchPreFavourites = async () => {
      try {
        const favourites = await getPreFavouriteItems();
        setSortedPreFavourites(
          favourites.length
            ? favourites.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            : null
        );
      } catch (err) {
        console.log(err);
      }
    };

    if (currentClient) {
      fetchFavourites();
      fetchPreFavourites();
      subscribe();
    }

    return () => {
      if (subscription) subscription.unsubscribe();
      if (preSubscription) preSubscription.unsubscribe();
    };
  }, [currentClient]);

  useEffect(() => {
    const sortItems = () => {
      const temp = [...sortedFavourites];
      switch (sortBy.value) {
        case "date":
          temp.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
          break;
        case "status":
          temp
            .sort((a, b) =>
              JSON.parse(a.listing).type > JSON.parse(b.listing).type ? 1 : -1
            )
            .sort((a, b) =>
              JSON.parse(a.listing).lastStatus >
              JSON.parse(b.listing).lastStatus
                ? 1
                : -1
            )
            .reverse();

          break;
        case "price":
          temp.sort((a, b) =>
            +JSON.parse(a.listing).listPrice > +JSON.parse(b.listing).listPrice
              ? 1
              : -1
          );
          break;
        case "beds":
          temp.sort((a, b) =>
            +JSON.parse(a.listing).details.numBedrooms >
            +JSON.parse(b.listing).details.numBedrooms
              ? 1
              : -1
          );
          break;
        case "baths":
          temp.sort((a, b) =>
            +JSON.parse(a.listing).details.numBathrooms >
            +JSON.parse(b.listing).details.numBathrooms
              ? 1
              : -1
          );
          break;
        case "sqft":
          temp.sort((a, b) =>
            +JSON.parse(a.listing).details.sqft >
            +JSON.parse(b.listing).details.sqft
              ? 1
              : -1
          );
          break;
        default:
          return;
      }
      sortBy.direction === "desc"
        ? setSortedFavourites(temp.reverse())
        : setSortedFavourites(temp);
    };

    if (sortedFavourites) {
      sortItems();
    }
  }, [sortBy]);

  const renderPreCards = useCallback(() => {
    if (sortedPreFavourits) {
      return sortedPreFavourits.map((favourite) => (
        <FavoriteItem key={favourite.id} item={favourite} isPre />
      ));
    }
  }, [sortedPreFavourits]);

  const renderCards = useCallback(() => {
    if (loading) {
      return <LoadingFullPage />;
    }

    if (sortedFavourites) {
      if (sortedFavourites.length) {
        const cards = sortedFavourites.map((favourite) => (
          <FavoriteItem key={favourite.id} item={favourite} />
        ));

        return (
          <div className={styles.cardsContainer}>
            <div className={styles.cards}>
              {renderPreCards()}
              {cards}
              <Card add={true}>
                <AddFavourite />
              </Card>
            </div>

            <div className={styles.footer}>
              <Footer />
            </div>
          </div>
        );
      } else {
        return (
          <div className={styles.cardsContainer}>
            <div className={styles.cards}>
              {renderPreCards()}
              <Card add={true}>
                <AddFavourite />
              </Card>
            </div>
            <div className={styles.footer}>
              <Footer />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className={styles.cardsContainer}>
          <div className={styles.cards}>
            {renderPreCards()}
            <Card add={true}>
              <AddFavourite />
            </Card>
          </div>
          <div className={styles.footer}>
            <Footer />
          </div>
        </div>
      );
    }
  }, [loading, renderPreCards, sortedFavourites]);

  return (
    <div className={styles.container}>
      <div className={styles.listings} ref={scrollRef}>
        <div className={styles.edit}>
          <EditBar
            sortBy={sortBy}
            setSortBy={setSortBy}
            mode="favourites"
            disabled={!sortedFavourites || !sortedFavourites.length}
          />
        </div>
        {renderCards()}
      </div>
    </div>
  );
};

export default withClient(withAuthentication(FavoriteListings));
