import { all, call, take } from "redux-saga/effects";
import { filterSagas } from "./filters/filters.sagas";
import { mapSagas } from "./map/map.sagas";
import { locationsSagas } from "./locations/locations.sagas";
import { userSagas } from "./user/user.sagas";
import { REHYDRATE } from "redux-persist/lib/constants";
import { clientSagas } from "./client/client.sagas";
import { currencySagas } from "./currency/currency.sagas";
import { schoolsSagas } from "./schools/schools.sagas";
import { tourSagas } from "./tour/tour.sagas";
import { uiSagas } from "./ui/ui.sagas";

export default function* rootSaga() {
  yield take(REHYDRATE);
  yield all([
    call(filterSagas),
    call(mapSagas),
    call(locationsSagas),
    call(userSagas),
    call(clientSagas),
    call(tourSagas),
    call(currencySagas),
    call(schoolsSagas),
    call(uiSagas),
  ]);
}
