import { INITIAL_STATE } from "../../../../redux/filters/filters.reducer";

export const defaultFilters = INITIAL_STATE;

// export const defaultFilters = {
//   value: {
//     area: null,
//     city: null,
//     neighborhood: null,
//     type: "sale",
//     class: ["residential", "condo"],
//     status: "A",
//     lastStatus: null,
//     style: null,
//     propertyType: null,
//     date: null,
//     minListDate: null,
//     maxListDate: null,
//     minSoldDate: null,
//     maxSoldDate: null,
//     listDate: null,
//     displayPublic: "Y",
//     displayAddressOnInternet: "Y",
//     map: null,
//     minPrice: "0",
//     maxPrice: "max",
//     minBeds: null,
//     maxBeds: null,
//     minBaths: null,
//     maxBaths: null,
//     minParkingSpaces: null,
//     minSqft: null,
//     maxSqft: null,
//     sortBy: "createdOnDesc",
//     keywords: null,
//     hasImages: null,
//     resultsPerPage: 2500,
//     pageNum: 1,
//   },
//   areas: {
//     area: null,
//     cities: [],
//     neighbourhoods: [],
//     location: null,
//   },
//   extra: {
//     lotWidth: { min: null, max: null },
//     lotDepth: { min: null, max: null },
//     locker: null,
//     basement: {
//       finished: false,
//       walkOut: false,
//       sepEntrance: false,
//       any: true,
//     },
//     maintenance: null,
//   },
// };

export const price = {
  sale: [
    0,
    25000,
    50000,
    75000,
    100000,
    125000,
    150000,
    175000,
    200000,
    225000,
    250000,
    275000,
    300000,
    325000,
    350000,
    375000,
    400000,
    425000,
    450000,
    475000,
    500000,
    550000,
    600000,
    650000,
    700000,
    750000,
    800000,
    850000,
    900000,
    950000,
    1000000,
    1100000,
    1200000,
    1300000,
    1400000,
    1500000,
    1600000,
    1700000,
    1800000,
    1900000,
    2000000,
    2500000,
    3000000,
    3500000,
    4000000,
    4500000,
    5000000,
    5500000,
    6000000,
    6500000,
    7000000,
    7500000,
    10000000,
    15000000,
    20000000,
    "max",
  ],
  lease: [
    0,
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    800,
    900,
    1000,
    1200,
    1400,
    1600,
    1800,
    2000,
    2500,
    3000,
    3500,
    4000,
    4500,
    5000,
    6000,
    7000,
    8000,
    9000,
    10000,
    15000,
    "max",
  ],
};

export const areas = ["toronto", "peel", "york", "durham", "halton"];

export const saleStatus = [
  { label: "sale", checked: true },
  { label: "sold", checked: false },
  { label: "delisted (sale)", checked: false },
];

export const leaseStatus = [
  { label: "lease", checked: true },
  { label: "leased", checked: false },
  { label: "delisted (lease)", checked: false },
];

export const dates = [
  { label: "Any time", value: null },
  { label: "Last 1 day", value: 1 },
  { label: "Last 3 days", value: 3 },
  { label: "Last 7 days", value: 7 },
  { label: "Last 30 days", value: 30 },
  { label: "Last 90 days", value: 90 },
  { label: "Last 180 days", value: 180 },
  { label: "Last 365 days", value: 365 },
  { label: "Year 2024", value: "y2024" },
  { label: "Year 2023", value: "y2023" },
  { label: "Year 2022", value: "y2022" },
  { label: "Year 2021", value: "y2021" },
  { label: "Year 2020", value: "y2020" },
  { label: "Year 2019", value: "y2019" },
  { label: "Year 2018", value: "y2018" },
  { label: "Year 2017", value: "y2017" },
  { label: "Year 2016", value: "y2016" },
  { label: "Year 2015", value: "y2015" },
  { label: "Year 2013", value: "y2013" },
  { label: "Year 2012", value: "y2012" },
  { label: "Year 2011", value: "y2011" },
  { label: "Year 2010", value: "y2010" },
  { label: "Year 2009", value: "y2009" },
  { label: "Year 2008", value: "y2008" },
  { label: "Year 2007", value: "y2007" },
  { label: "Year 2006", value: "y2006" },
  { label: "Year 2005", value: "y2005" },
  { label: "Year 2004", value: "y2004" },
  { label: "Year 2003", value: "y2003" },
  { label: "Year 2002", value: "y2002" },
  { label: "Year 2001", value: "y2001" },
  { label: "Year 2000", value: "y2000" },
];

export const propertyClass = [
  { label: "residential", value: "residential", checked: true },
  { label: "commercial", value: "commercial", checked: false },
];

export const lockerOptions = [
  { label: "any", value: true, checked: true },
  { label: "yes", value: true, checked: false },
  { label: "no", value: false, checked: false },
];

export const beds = [
  { label: "any", value: "any", checked: true },
  { label: "1", value: "1", checked: false },
  { label: "1+", value: "1+", checked: false },
  { label: "2", value: "2", checked: false },
  { label: "2+", value: "2+", checked: false },
  { label: "3", value: "3", checked: false },
  { label: "3+", value: "3+", checked: false },
  { label: "4", value: "4", checked: false },
  { label: "4+", value: "4+", checked: false },
];

export const maintenanceRange = [
  "any",
  100,
  200,
  300,
  400,
  500,
  750,
  1000,
  2000,
];
export const bedsRange = [0, 1, 2, 3, 4, 5, "max"];
export const bathsRange = [0, 1, 2, 3, 4, "max"];
export const parkingsRange = ["any", 1, 2, 3, 4];
export const sqftRange = [
  0,
  100,
  250,
  500,
  750,
  1000,
  1500,
  2000,
  3000,
  4000,
  "max",
];
export const lotWidthRange = [
  0,
  10,
  20,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  "max",
];

export const lotDepthRange = [0, 25, 50, 100, 150, 200, 300, 400, 500, "max"];

export const VALID_FILTERS = [
  "area",
  "city",
  "keywords",
  "maxBeds",
  "maxPrice",
  "minBeds",
  "minPrice",
  "minSqft",
  "neighborhood",
  "propertyType",
  "type",
];
export const VALID_PROPERTY_TYPES = [
  "Detached",
  "Semi-detached",
  "Condo Apt",
  "Condo townhouse",
  "Att/Row/Twnhouse",
  "Multiplex",
  "Link",
];

export const residentialTypes = [
  { name: "Detached", value: "Detached", isChecked: false },
  { name: "Semi-detached", value: "Semi-detached", isChecked: false },
  { name: "Condo apartment", value: "Condo Apt", isChecked: false },
  { name: "Condo townhouse", value: "Condo townhouse", isChecked: false },
  { name: "Freehold townhouse", value: "Att/Row/Twnhouse", isChecked: false },
  { name: "Multiplex", value: "Multiplex", isChecked: false },
  { name: "Link", value: "Link", isChecked: false },
];

export const commercialTypes = [
  { name: "Retail", value: "Commercial/Retail", isChecked: false },
  { name: "Detached", value: "Detached", isChecked: false },
  { name: "Office", value: "Office", isChecked: false },
  { name: "Store", value: "Store W/Apt/Office", isChecked: false },
  { name: "Farm", value: "Farm", isChecked: false },
  { name: "Industrial", value: "Industrial", isChecked: false },
  { name: "Investment", value: "Investment", isChecked: false },
  { name: "Business", value: "Sale Of Business", isChecked: false },
];

export const allTypes = [
  { name: "Condo townhouse", value: "Condo townhouse", isChecked: false },
  { name: "Condo apartment", value: "Condo Apt", isChecked: false },
  { name: "Freehold townhouse", value: "Att/Row/Twnhouse", isChecked: false },
  { name: "Detached", value: "Detached", isChecked: false },
  { name: "Semi-detached", value: "Semi-detached", isChecked: false },
  { name: "Link", value: "Link", isChecked: false },
  { name: "Multiplex", value: "Multiplex", isChecked: false },
  { name: "Retail", value: "Commercial/Retail", isChecked: false },
  { name: "Detached", value: "Detached", isChecked: false },
  { name: "Office", value: "Office", isChecked: false },
  { name: "Store", value: "Store W/Apt/Office", isChecked: false },
  { name: "Farm", value: "Farm", isChecked: false },
  { name: "Industrial", value: "Industrial", isChecked: false },
  { name: "Investment", value: "Investment", isChecked: false },
  { name: "Business", value: "Sale Of Business", isChecked: false },
];

export const basementTypes = [
  { name: "Any", value: "any" },
  { name: "Finished", value: "finished" },
  { name: "Separate Entrance", value: "sepEntrance" },
  { name: "Walk-out", value: "walkOut" },
  { name: "None", value: "none" },
];
