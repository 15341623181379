import {
  IonCheckbox,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
} from "@ionic/react";

const PreCheckbox = ({
  styles,
  label,
  items,
  checked,
  onChange,
  isAmenity = false,
}) => {
  const isChecked = (r) => {
    if (checked.find((item) => item.value === r.value)) {
      return true;
    }
    return false;
  };
  return (
    <IonList style={styles.list}>
      <IonListHeader style={styles.header}>{label}</IonListHeader>
      <div className={styles.checkboxes}>
        {items.map((item, i) => (
          <IonItem key={i}>
            <IonLabel style={styles.label}>{item.label}</IonLabel>
            <IonCheckbox
              value={item.value}
              checked={isAmenity ? isChecked(item) : checked[item.value]}
              onIonChange={(e) => onChange(e.detail)}
            />
          </IonItem>
        ))}
      </div>
    </IonList>
  );
};

export default PreCheckbox;
