const ActiveListingsDateFilterOptions = [
  { label: "Last 1 day", value: 1 },
  { label: "Last 7 days", value: 7 },
  { label: "Last 30 days", value: 30 },
  { label: "Last 90 days", value: 90 },
  { label: "Last 180 days", value: 180 },
  { label: "Last 365 days", value: 365 },
  { label: "Any time", value: 0 },
  { label: "More than 7 days", value: -7 },
  { label: "More than 15 days", value: -15 },
  { label: "More than 30 days", value: -30 },
  { label: "More than 90 days", value: -90 },
];

const UnavailableListingsDateFilterOptions = [
  { label: "Last 1 day", value: 1 },
  { label: "Last 7 days", value: 7 },
  { label: "Last 30 days", value: 30 },
  { label: "Last 90 days", value: 90 },
  { label: "Last 180 days", value: 180 },
  { label: "Last 365 days", value: 365 },
  { label: "Any time", value: 0 },
];

const currentYear = new Date().getFullYear();
for (let year = currentYear; year >= 2003; year--) {
  UnavailableListingsDateFilterOptions.push({
    label: year.toString(),
    value: year,
  });
}

const ResidentialPropertyTypes = [
  { label: "Freehold townhouse", value: "Att/Row/Twnhouse" },
  { label: "Detached", value: "Detached" },
  { label: "Semi-detached", value: "Semi-detached" },
  { label: "Link", value: "Link" },
  { label: "Multiplex", value: "Multiplex" },
];
const CondoPropertyTypes = [
  { label: "Condo townhouse", value: "Condo townhouse" },
  { label: "Condo apartment", value: "Condo Apt" },
];
const CommercialPropertyTypes = [
  { label: "Retail", value: "Commercial/Retail" },
  { label: "Office", value: "Office" },
  { label: "Store", value: "Store W/Apt/Office" },
  { label: "Farm", value: "Farm" },
  { label: "Industrial", value: "Industrial" },
  { label: "Investment", value: "Investment" },
  { label: "Business", value: "Sale Of Business" },
];


export const ListingsConstants = {
  ActiveListingsDateFilterOptions,
  UnavailableListingsDateFilterOptions,
  ResidentialPropertyTypes,
  CondoPropertyTypes,
  CommercialPropertyTypes,
};