import { GraphQLQuery } from "@aws-amplify/api";
import { API } from "aws-amplify";
import {
  CreateNoteMutation,
  CreatePhotoMutation,
  CreateRecordingMutation,
  Note,
  Photo,
  Recording,
  Tour,
  TourItem,
  UpdateNoteMutation,
} from "../API";
import { Photo as CapacitorPhoto } from "@capacitor/camera";
import {
  createNote,
  updateNote as updateNoteMutation,
  deleteNote as deleteNoteMutation,
  deletePhoto as deletePhotoMutation,
  deleteRecording as deleteRecordingMutation,
  createPhoto,
  createRecording,
} from "../graphql/mutations";
import { StorageService } from "./storageService";
import { GeneralConstants } from "../utils/constants";

const buildFileObject = (
  fileName: string,
  key: string,
  additionalProperties?: { [key: string]: string }
) => {
  return {
    ...additionalProperties,
    path: key,
    file: {
      bucket: GeneralConstants.S3_BUCKET_NAME,
      region: GeneralConstants.S3_BUCKET_REGION,
      key: "public/" + fileName,
    },
  };
};

const addNote = async (tourItemId: string, content: string) => {
  const response = await API.graphql<GraphQLQuery<CreateNoteMutation>>({
    query: createNote,
    variables: { input: { tourItemId, content } },
  });

  return response.data?.createNote;
};

const updateNote = async (noteId: string, content: string) => {
  const response = await API.graphql<GraphQLQuery<UpdateNoteMutation>>({
    query: updateNoteMutation,
    variables: { input: { id: noteId, content } },
  });
  return response.data?.updateNote;
};

const addPhoto = async (tourItemId: string, file: CapacitorPhoto) => {
  const { key, fileName } = await StorageService.addPhoto(file);
  const image = buildFileObject(fileName, key, { tourItemId });

  const response = await API.graphql<GraphQLQuery<CreatePhotoMutation>>({
    query: createPhoto,
    variables: { input: image },
  });
  return response.data?.createPhoto;
};

const addRecording = async (tourItemId: string, data: string) => {
  const { key, fileName } = await StorageService.addRecording(data);
  const recording = buildFileObject(fileName, key, { tourItemId });

  const response = await API.graphql<GraphQLQuery<CreateRecordingMutation>>({
    query: createRecording,
    variables: { input: recording },
  });

  return response.data?.createRecording;
};

const deleteAllMedia = async (tourItem: TourItem) => {
  const promises: Promise<void>[] = [];

  tourItem.notes?.items.forEach((note) => {
    if (note) {
      promises.push(deleteNote(note));
    }
  });

  tourItem.photos?.items.forEach((photo) => {
    if (photo) {
      promises.push(deletePhoto(photo));
    }
  });

  tourItem.recordings?.items.forEach((recording) => {
    if (recording) {
      promises.push(deleteRecording(recording));
    }
  });

  await Promise.all(promises);
};

const deleteNote = async (note: Note) => {
  await API.graphql({
    query: deleteNoteMutation,
    variables: { input: { id: note.id } },
  });
};

const deletePhoto = async (photo: Photo) => {
  if (photo.path) {
    await StorageService.deleteItem(photo.path);
  }
  await API.graphql({
    query: deletePhotoMutation,
    variables: { input: { id: photo.id } },
  });
};

const deleteRecording = async (recording: Recording) => {
  if (recording.path) {
    await StorageService.deleteItem(recording.path);
  }
  API.graphql({
    query: deleteRecordingMutation,
    variables: { input: { id: recording.id } },
  });
};

const getMediaOwnerDetailsByTour = async (
  tour: Tour,
  media: Note | Photo | Recording
) => {
  const ownerUserId = media.userId;
  const owner = tour.users?.items?.find((user) => user?.userId === ownerUserId);
  return owner;
};

export const TourItemMediaService = {
  addNote,
  updateNote,
  addPhoto,
  addRecording,
  deleteAllMedia,
  deleteNote,
  deletePhoto,
  deleteRecording,
  getMediaOwnerDetailsByTour,
};
