import { takeLatest, call, put, all } from "redux-saga/effects";
import { store } from "../store";
import { FiltersActionTypes } from "./filters.types";
import { clearListings } from "../map/map.actions";
import { updateMultipleFilters } from "./filters.actions";

export function* clear({ payload: val }) {
  const filters = store.getState().filters.value;
  if (
    (filters.type.toLowerCase() === "pre-construction" &&
      val.toLowerCase() !== "pre-construction") ||
    val.toLowerCase() === "pre-construction"
  ) {
    yield put(updateMultipleFilters({ propertyType: [], status: "A" }));
  }
  if (val.toLowerCase() !== filters.type.toLowerCase()) {
    yield put(clearListings());
  }

  if (filters.status === "A" || val.toLowerCase() === "pre-construction") {
    yield put(updateMultipleFilters({ type: val.toLowerCase() }));
  } else {
    yield put(
      updateMultipleFilters({
        type: val,
        lastStatus: val === "sale" ? "Sld" : "Lsd",
      })
    );
  }
}
export function* updateType() {
  yield takeLatest(FiltersActionTypes.UPDATE_TYPE, clear);
}
export function* filterSagas() {
  yield all([call(updateType)]);
}
