import { useState, useRef, memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IonPage, IonContent, IonModal } from "@ionic/react";
import Map from "../../../components/Map/map.component";
import {
  selectSchools,
  selectSchoolsFilters,
} from "../../../redux/schools/schools.selectors";
import withMap from "../../../HOC/withMap/with-map";
import withFilters from "../../../HOC/withFilters/with-filters";
import styles from "./search.module.scss";
import { fetchSchoolsStart } from "../../../redux/schools/schools.actions";
import AppSchoolFilterModal from "../../components/CustomModals/SchoolFilter/school-filter.component";
import SearchModal from "../../../components/Map/components/MapControls/components/SearchModal/search-modal.component";
import SaveSearchModal from "../../../components/Map/components/MapControls/components/SaveSearchModal/save-search-modal.component";
import {
  selectMapBoundaries,
  selectMapFavourites,
  selectMapRef,
} from "../../../redux/map/map.selectors";
import { selectDrawMode } from "../../../redux/ui/ui.selectors";
import {
  selectClientError,
  selectClientLoading,
  selectCurrentClient,
} from "../../../redux/client/client.selectors";
import { useHistory } from "react-router-dom";
import { updateFilters } from "../../../redux/filters/filters.actions";
import pcGif from "../../../assets/img/crane.png";
import {
  toggleFavourites,
  updateSelectedListings,
} from "../../../redux/map/map.actions";
import FiltersModal from "../Filters/filters.component";
import { MapService } from "services/mapService";

const AppSearchPage = ({
  mapLoading,
  selectedListings,
  mapError,
  areas,
  mapSelectedLocation,
  updateMapRef,
  updateMapSelectedLocation,
  mapLocations,
  updateMapLocations,
  filters,
  extraFilters,
}) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const history = useHistory();
  const [mapReady, setMapReady] = useState(false);
  const [clusters, setClusters] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState();
  const [view, setView] = useState("map");
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(false);

  const updateView = () => {
    if (view === "map" && mapSelectedLocation) {
      updateMapSelectedLocation(null);
      setView("list");
    } else {
      setView(view === "map" ? "list" : "map");
    }
  };
  const mapRef = useSelector(selectMapRef);
  const drawMode = useSelector(selectDrawMode);
  const currentClient = useSelector(selectCurrentClient);
  const clientLoading = useSelector(selectClientLoading);
  const clientError = useSelector(selectClientError);
  const favouritesMode = useSelector(selectMapFavourites);
  const schools = useSelector(selectSchools);
  const schoolsFilters = useSelector(selectSchoolsFilters);

  const { active } = schools;
  const boundaries = useSelector(selectMapBoundaries);

  useEffect(() => {
    if (mapReady) {
      fetchClusters();
    }
  }, [filters, extraFilters, schools.filters.showListingsWithin, mapReady]);

  useEffect(() => {
    if (active && mapRef && mapRef.zoom > 11)
      dispatch(fetchSchoolsStart({ boundary: boundaries[0] }));
  }, [active, dispatch, boundaries, mapRef, schoolsFilters]);

  const handleModals = (type) => {
    if (!currentClient && type === "savedSearch") {
      history.push("/join", { direction: "none" });
      return;
    }
    setModal(type);
  };

  useEffect(() => {
    setShowModal(modal ? true : false);
  }, [modal]);

  const handleMapReady = (map) => {
    updateMapRef(map);
    setMapReady(true);
  };

  const fetchClusters = async () => {
    try {
      const clusters = await MapService.getClusters();
      setClusters(clusters);
    } catch (error) {
      console.error(error);
    }
  };

  const updateMapFilter = (update) => {
    if (update.map.flat(2).includes(undefined)) {
      return;
    }
    dispatch(updateFilters(update));
  };

  const handleSelectCluster = async (cluster) => {
    try {
      dispatch(updateSelectedListings([]));
      setSelectedCluster(cluster);
      const clusterListings = await MapService.getClusterListings(cluster.map);
      dispatch(updateSelectedListings(clusterListings));
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectListings = async (listings) => {
    dispatch(updateSelectedListings(listings));
  };

  const handleDeselectClusterAndListings = () => {
    setSelectedCluster(null);
    dispatch(updateSelectedListings([]));
  };

  return (
    <IonPage ref={ref}>
      <IonContent scrollY={false}>
        <IonModal
          id="modal"
          isOpen={showModal}
          onDidDismiss={() => setShowModal(false)}
          backdropDismiss={false}>
          {modal === "filters" && (
            <FiltersModal
              mapRef={mapRef}
              close={() => setModal(null)}
              openSearchModal={() => setModal("savedSearch")}
            />
          )}
          {modal === "search" && (
            <SearchModal mapRef={mapRef} close={() => setModal(null)} />
          )}
          {modal === "schools" && (
            <AppSchoolFilterModal close={() => setModal(null)} />
          )}
          {modal === "savedSearch" && (
            <SaveSearchModal
              close={() => setModal(null)}
              drawMode={drawMode}
              schoolMode={{
                active: schools.filterListings.active,
                schools: schools.filterListings.schools,
              }}
              filters={filters}
              extraFilters={extraFilters}
              client={currentClient}
              clientError={clientError}
              clientLoading={clientLoading}
            />
          )}
        </IonModal>
        <div className={styles.mapContainer}>
          {/* {filters.type === "pre-construction" && (
            <div
              className={styles.comingSoon}
              onClick={() => dispatch(updateFilters({ type: "sale" }))}
            >
              <img
                className={styles.animation}
                src={pcGif}
                alt="Coming soon!"
              />
              <h2 className={styles.header}>Coming soon!</h2>
              <span className={styles.content}>
                We are very excited to be building our pre-construction program
                and search portal!
              </span>
              <span className={styles.content}>
                By registering for AECORN you will be first to hear when it’s
                ready to launch!
              </span>

              <IonButton className={styles.backBtn} onClick={() => dispatch(updateFilters({ type: "sale" }))}>
                Go Back!
              </IonButton>
            </div>
          )} */}
          <Map
            isApp={true}
            filters={filters}
            extraFilters={extraFilters}
            mapLocations={mapLocations}
            updateBoundaryFilter={updateMapFilter}
            clusters={clusters}
            selectedCluster={selectedCluster}
            onSelectCluster={handleSelectCluster}
            selectedListings={selectedListings}
            onSelectListings={handleSelectListings}
            onDeselectClusterAndListings={handleDeselectClusterAndListings}
            areas={areas}
            loading={mapLoading}
            error={mapError}
            selectedLocation={mapSelectedLocation}
            updateSelectedLocation={updateMapSelectedLocation}
            updateMapLocations={updateMapLocations}
            onMapReady={handleMapReady}
            // hide={filters.type === "pre-construction"}
            schools={schools}
            type="mobile"
            view={view}
            setView={updateView}
            handleModals={handleModals}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default memo(withMap(withFilters(AppSearchPage)));
