import { IonIcon, IonSpinner } from "@ionic/react";
import { Listing } from "../../../models/listings/listing.model";
import styles from "./listing-favourite-icons.module.scss";
import favouriteIcon from "../../../assets/svg/favourite.svg";
import notificationIcon from "../../../assets/svg/notifications.svg";
import { useEffect, useState } from "react";
import { Favourite } from "../../../API";
import { FavoritesService } from "../../../services/favoritesService";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../redux/user/user.selectors";

const ListingFavouriteIcons = ({ listing, size = "small" }: { listing: Listing, size?: "small" | "large" }) => {
  const isAuthenticating = useSelector(selectCurrentUser);
  const [loading, setLoading] = useState(true);
  const [favourite, setFavourite] = useState<Favourite | null>(null);

  useEffect(() => {
    const fetchFavourite = async () => {
      if (!isAuthenticating) {
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        const favouriteItem = await FavoritesService.getFavoriteByMlsNumber(
          listing.mlsNumber
        );
        setFavourite(favouriteItem);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchFavourite();
  }, []);

  const handleFavourite = async (e: any) => {
    e.stopPropagation();
    if (!isAuthenticating) {
      return;
    }
    try {
      setLoading(true);
      if (favourite) {
        await FavoritesService.removeFavorite(favourite);
        setFavourite(null);
      } else {
        const newFavourite = await FavoritesService.createFavorite(listing);
        setFavourite(newFavourite);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleNotification = async (e: any) => {
    e.stopPropagation();
    if (!isAuthenticating) {
      return;
    }
    try {
      setLoading(true);
      if (favourite) {
        const updatedFavourite =
          await FavoritesService.toggleFavoriteNotification(favourite);
        setFavourite(updatedFavourite);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`${styles.container} ${styles[size]}`}>
      {loading ? (
        <IonSpinner name="dots" />
      ) : (
        <>
          <IonIcon
            className={
              favourite ? styles.enabledFavourite : styles.disabledFavourite
            }
            icon={favouriteIcon}
            onClick={handleFavourite}
          />

          <IonIcon
            className={
              favourite
                ? favourite.notification
                  ? styles.enabledNotification
                  : styles.disabledNotification
                : styles.disabled
            }
            icon={notificationIcon}
            onClick={handleNotification}
          />
        </>
      )}
    </div>
  );
};

export default ListingFavouriteIcons;
