import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import NotesSection from "../../../../../components/Notes/component/NotesSection/notes-section.component";
import { buildAddress } from "../../../../../utils/functions";
import { selectCurrentClient } from "../../../../../redux/client/client.selectors";
import { updateToast } from "../../../../../redux/ui/ui.actions";
import styles from "./notes.module.scss";
import { TourItemMediaService } from "../../../../../services/tourItemMediaService";

const Notes = ({ tourItem, noteItems, listing, refresh, disabled, tour }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState();
  const client = useSelector(selectCurrentClient);

  useEffect(() => {
    const groupNotes = () =>
      _.mapValues(_.groupBy(noteItems, (note) => note.userId));
    if (noteItems && client) setNotes(groupNotes());
  }, [client, noteItems]);

  const createNote = async (content) => {
    try {
      setLoading(true);
      await TourItemMediaService.addNote(tourItem.id, content);
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Showing note saved.",
        })
      );
      refresh();
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const updateNote = async ({ content, id }) => {
    try {
      setLoading(true);
      await TourItemMediaService.updateNote({
        id,
        content,
      });
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Showing note saved.",
        })
      );
      refresh();
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.noteDetails}>
      <div className={styles.subtitle}>
        {tour.users.items.find((u) => u.userId === client.id)
          ? `All notes taken for ${buildAddress(listing.address)}. by all tour
          attendees. You may only add to and edit your own notes.`
          : "Only lead/showing agents can create a note item."}
      </div>

      {tour.users.items.find((u) => u.userId === client.id) && (
        <NotesSection
          disabled={disabled}
          isCurrentUser={true}
          note={noteItems.find((note) => note.userId === client.id)}
          create={createNote}
          update={updateNote}
          memberData={tour.users.items.find((u) => u.userId === client.id)}
          tour={tour}
        />
      )}

      {notes &&
        Object.keys(notes).map((key) => {
          if (notes[key][0].userId === client.id) {
            return null;
          } else {
            return (
              <NotesSection
                key={key}
                isCurrentUser={false}
                note={notes[key][0]}
                memberData={tour.users.items.find(
                  (u) => u.userId === notes[key][0].userId
                )}
                tour={tour}
              />
            );
          }
        })}
    </div>
  );
};

export default Notes;
