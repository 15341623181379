import { useState, useEffect } from "react";
import {
  IonRange,
  IonIcon,
  IonPopover,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
} from "@ionic/react";
import Input from "../../../../../../../../components/Form/Input/input.component";
import styles from "./calculators.module.scss";
import { numberWithCommas } from "../../../../../../../../utils/functions";
import { helpCircleOutline } from "ionicons/icons";
import Toggle from "../../../../../../../../components/Form/Toggle/toggle-component";
import {
  taxLimit1,
  taxLimit2,
  taxLimit3,
  taxLimit4,
  text1,
  text2,
  text3,
} from "./data";
import { useSelector } from "react-redux";
import { selectMortgageRate } from "../../../../../../../../redux/ui/ui.selectors";

const Calculators = ({ listing, isApp = false, isPreCon = false }) => {
  const defaultMortgateRate = useSelector(selectMortgageRate);

  const [popover, setPopover] = useState({
    isOpen: false,
    event: undefined,
    content: "",
  });
  const [mortgage, setMortgage] = useState({
    price: isPreCon
      ? numberWithCommas(listing.startPrice)
      : numberWithCommas(listing.listPrice),
    term: 25,
    rate: defaultMortgateRate ? defaultMortgateRate.toString() : "5",
    downPayment: isPreCon
      ? numberWithCommas(listing.startPrice / 5)
      : numberWithCommas(listing.listPrice / 5),
    downPaymentPercentage: 20,
    monthlyPayment: 0,
  });

  const [tax, setTax] = useState({
    provincial: 0,
    municipal: 0,
    firstTimeBuyer: false,
  });

  const handleMortgageInputChange = (value, name) => {
    if (name === "price") {
      let cleanedValue = 0;
      if (!isNaN(+value.replace(/,/g, "")))
        cleanedValue = +value.replace(/,/g, "");
      if (cleanedValue > 20000000) cleanedValue = 20000000;
      if (cleanedValue < 0) cleanedValue = 0;
      setMortgage({ ...mortgage, price: numberWithCommas(cleanedValue) });
    }

    if (name === "term") {
      let cleanedValue = 0;
      if (!isNaN(+value)) cleanedValue = +value;

      if (cleanedValue > 30) cleanedValue = 30;
      if (cleanedValue < 0) cleanedValue = 0;
      setMortgage({ ...mortgage, term: cleanedValue });
    }

    if (name === "rate") {
      let cleanedValue = "0";
      if (!isNaN(+value.replace(/./g, ""))) cleanedValue = value;
      if (cleanedValue > 10) cleanedValue = "10";
      if (cleanedValue < 0) cleanedValue = "0";

      setMortgage({ ...mortgage, rate: cleanedValue });
    }

    if (name === "downPayment") {
      let cleanedValue = 0;
      if (!isNaN(+value.replace(/,/g, "")))
        cleanedValue = +value.replace(/,/g, "");
      if (cleanedValue > 20000000) cleanedValue = 20000000;
      if (cleanedValue < 0) cleanedValue = 0;

      setMortgage({
        ...mortgage,
        downPayment: numberWithCommas(cleanedValue),
        downPaymentPercentage:
          (cleanedValue / +mortgage.price.replace(/,/g, "")) * 100,
      });
    }

    if (name === "downPaymentPercentage") {
      const cleanedValue = parseFloat(value);

      if (isNaN(cleanedValue)) {
        setMortgage({
          ...mortgage,
          downPaymentPercentage: 0,
          downPayment: 0,
        });
      }
      setMortgage({
        ...mortgage,
        downPaymentPercentage: cleanedValue,
        downPayment: numberWithCommas(
          (Math.round(
            (+mortgage.price.replace(/,/g, "") * cleanedValue) / 100
          ) *
            10) /
            10
        ),
      });
    }
  };

  const handleAppTerm = ({ detail: { value } }) => {
    setMortgage({ ...mortgage, term: value });
  };

  const handleMortgageRangeChange = ({
    target: { name },
    detail: { value },
  }) => {
    if (name === "price") {
      setMortgage({ ...mortgage, price: numberWithCommas(value) });
    }
    if (name === "term") {
      setMortgage({ ...mortgage, term: value });
    }
    if (name === "rate") {
      setMortgage({ ...mortgage, rate: Math.round(value * 10) / 10 });
    }
    if (name === "downPayment") {
      setMortgage({
        ...mortgage,
        downPaymentPercentage: value,
        downPayment: numberWithCommas(
          (Math.round((+mortgage.price.replace(/,/g, "") * value) / 100) * 10) /
            10
        ),
      });
    }
  };

  useEffect(() => {
    const calculateMonthlyPayment = (price, rate, term, downPayment) => {
      const loan = +price.replace(/,/g, "") - +downPayment.replace(/,/g, "");
      rate /= 1200;
      term *= 12;
      const payment =
        (loan * (rate * Math.pow(1 + rate, term))) /
        (Math.pow(1 + rate, term) - 1);
      setMortgage({
        ...mortgage,
        monthlyPayment: isNaN(payment) ? mortgage.monthlyPayment : payment,
      });
    };

    calculateMonthlyPayment(
      mortgage.price,
      mortgage.rate,
      mortgage.term,
      mortgage.downPayment
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mortgage.price, mortgage.rate, mortgage.term, mortgage.downPayment]);

  useEffect(() => {
    setTax({
      ...tax,
      provincial: numberWithCommas(
        calculateProvincialTax(+mortgage.price.replace(/,/g, ""))
      ),
      municipal: isPreCon
        ? listing.city === "Toronto"
          ? numberWithCommas(
              calculateProvincialTax(+mortgage.price.replace(/,/g, ""))
            )
          : 0
        : listing.address.city === "Toronto"
        ? numberWithCommas(
            calculateProvincialTax(+mortgage.price.replace(/,/g, ""))
          )
        : 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mortgage.price]);

  const calculateProvincialTax = (price) => {
    switch (true) {
      case price > 2000000:
        return (
          taxLimit1 +
          taxLimit2 +
          taxLimit3 +
          taxLimit4 +
          ((price - 2000000) * 2.5) / 100
        );
      case price > 400000 && price <= 2000000:
        return taxLimit1 + taxLimit2 + taxLimit3 + ((price - 400000) * 2) / 100;
      case 250000 < price && price <= 400000:
        return taxLimit1 + taxLimit2 + ((price - 250000) * 1.5) / 100;
      case 55000 < price && price <= 250000:
        return taxLimit1 + ((price - 55000) * 1) / 100;
      case price <= 55000:
        return (price * 0.5) / 100;
      default:
        return 0;
    }
  };

  const calculateRebate = () => {
    let total = 0;
    if (+mortgage.price.replace(/,/g, "") <= 368000) {
      total = +tax.provincial.replace(/,/g, "");
    } else if (+tax.provincial.replace(/,/g, "") > 4000) {
      total = 4000;
    } else {
      total = +tax.provincial.replace(/,/g, "");
    }
    if (tax.municipal !== 0) {
      if (+tax.municipal.replace(/,/g, "") > 4475) {
        total = total + 4475;
      } else {
        total = total + +tax.municipal.replace(/,/g, "");
      }
    }

    return total;
  };

  const calculateTransferTax = () => {
    if (tax.provincial === 0) return 0;
    let total = +tax.provincial.replace(/,/g, "");
    if (tax.municipal !== 0) total = total + +tax.municipal.replace(/,/g, "");

    if (tax.firstTimeBuyer) {
      total = total - calculateRebate();
    }
    return total;
  };

  return isApp ? (
    <div className={styles.appContainer}>
      <div className={styles.mortgage}>
        <div className={styles.header}>Mortgage calculator</div>
        <div className={styles.calculator}>
          <div className={styles.item}>
            <div className={styles.inputContainer}>
              <span className={styles.label}>Home price</span>
              <Input
                name="price"
                defaultValue={mortgage.price}
                value={mortgage.price}
                mode="numeric"
                prefix="$"
                onChange={handleMortgageInputChange}
                style={{ width: "100%" }}
                type="tel"
              />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.inputContainer}>
              <span className={styles.label}>Term</span>
              <IonItem lines="none" className={styles.selectItem}>
                <IonSelect
                  className={styles.select}
                  value={mortgage.term}
                  placeholder="Term"
                  onIonChange={handleAppTerm}
                >
                  <IonSelectOption value={5}>5 years</IonSelectOption>
                  <IonSelectOption value={10}>10 years</IonSelectOption>
                  <IonSelectOption value={15}>15 years</IonSelectOption>
                  <IonSelectOption value={20}>20 years</IonSelectOption>
                  <IonSelectOption value={25}>25 years</IonSelectOption>
                  <IonSelectOption value={30}>30 years</IonSelectOption>
                </IonSelect>
              </IonItem>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.inputContainer}>
              <span className={styles.label}>Rate</span>
              <Input
                name="rate"
                defaultValue={mortgage.rate}
                value={mortgage.rate}
                mode="decimal"
                onChange={handleMortgageInputChange}
                style={{ width: "100%" }}
                postfix="%"
                type="number"
              />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.inputContainer}>
              <span className={styles.label}>Down payment</span>
              <div className={styles.inputs}>
                <span className={styles.amount}>
                  <Input
                    name="downPayment"
                    defaultValue={mortgage.downPayment}
                    mode="numeric"
                    value={
                      isNaN(+mortgage.downPayment.replace(/,/g, ""))
                        ? 0
                        : mortgage.downPayment
                    }
                    onChange={handleMortgageInputChange}
                    style={{ width: "100%" }}
                    prefix="$"
                    type="tel"
                  />
                </span>
                <span className={styles.percentage}>
                  <Input
                    name="downPaymentPercentage"
                    defaultValue={mortgage.downPayment}
                    mode="decimal"
                    value={mortgage.downPaymentPercentage}
                    onChange={handleMortgageInputChange}
                    style={{ width: "100%" }}
                    postfix="%"
                    type="number"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.payment}>
          <span className={styles.label}>Mortage payment:</span>
          <span className={styles.amount}>
            {!isFinite(mortgage.monthlyPayment)
              ? "$0*"
              : `$${numberWithCommas(
                  (Math.round(mortgage.monthlyPayment) * 10) / 10
                )}*`}
          </span>
        </div>
        <div className={styles.transfer}>
          <IonPopover
            cssClass={isApp ? styles.appPopover : styles.popover}
            showBackdrop={false}
            isOpen={popover.isOpen}
            event={popover.event}
            onDidDismiss={() =>
              setPopover({ isOpen: false, event: undefined, content: "" })
            }
          >
            <div className={styles.content}>{popover.content}</div>
          </IonPopover>
          <div className={styles.header}>Land transfer tax</div>
          <div className={styles.item}>
            <div className={styles.inputContainer}>
              <div className={styles.label}>Home price</div>
              <Input
                name="price"
                mode="numeric"
                defaultValue={mortgage.price}
                value={mortgage.price}
                prefix="$"
                onChange={handleMortgageInputChange}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.inputContainer}>
              <div className={styles.label}>
                Province of purchase:
                <span className={styles.subLabel}>
                  {isPreCon ? listing.province : listing.address.state}
                </span>
              </div>
              <div className={styles.input}>
                <span className={styles.amount}>
                  <span style={{ fontWeight: 400 }}>$</span>{" "}
                  {tax.provincial ? tax.provincial : 0}
                </span>

                <IonIcon
                  icon={helpCircleOutline}
                  className={styles.icon}
                  onClick={(e) => {
                    e.persist();
                    setPopover({ isOpen: true, event: e, content: text1 });
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.inputContainer}>
              <div className={styles.label}>
                Municipality of purchase:
                <span className={styles.subLabel}>
                  {isPreCon ? listing.city : listing.address.city}
                </span>
              </div>
              <div className={styles.input}>
                <IonIcon
                  icon={helpCircleOutline}
                  className={styles.icon}
                  onClick={(e) => {
                    e.persist();
                    setPopover({ isOpen: true, event: e, content: text2 });
                  }}
                />
                <span className={styles.amount}>
                  <span style={{ fontWeight: 400 }}>$</span>{" "}
                  {tax.municipal ? tax.municipal : 0}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.inputContainer}>
              <div className={styles.label}>First time home buyer?</div>
              <div className={styles.input}>
                <span className={styles.toggle}>
                  <Toggle
                    color="green"
                    active={tax.firstTimeBuyer}
                    onToggle={() =>
                      setTax({ ...tax, firstTimeBuyer: !tax.firstTimeBuyer })
                    }
                    isYesNo={true}
                  />
                </span>
                <IonIcon
                  icon={helpCircleOutline}
                  className={styles.icon}
                  style={{ zIndex: "1000" }}
                  onClick={(e) => {
                    e.persist();
                    setPopover({ isOpen: true, event: e, content: text3 });
                  }}
                />
                <div
                  style={{
                    marginLeft: "1rem",
                    fontSize: "1.5rem",
                    color: tax.firstTimeBuyer ? "#1f7a60" : "#707070",
                  }}
                >
                  {tax.firstTimeBuyer
                    ? `$${numberWithCommas(calculateRebate())} rebate`
                    : "$0 rebate"}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.payment}>
            <span className={styles.label}>Total land transfer tax:</span>
            <span className={styles.amount}>
              {!isFinite(calculateTransferTax()) ||
              isNaN(calculateTransferTax())
                ? "$0*"
                : `$${numberWithCommas(
                    (Math.round(calculateTransferTax()) * 10) / 10
                  )}*`}
            </span>
          </div>
        </div>
        <div className={styles.disclaimer}>
          *Calculation formula is created by AECORN. This is for estimation
          purposes only.
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.mortgage}>
        <div className={styles.header}>Mortgage calculator</div>
        <div className={styles.calculator}>
          <div className={styles.item}>
            <div className={styles.rangeContainer}>
              <span className={styles.label}>Home price</span>
              <IonRange
                name="price"
                className={styles.range}
                min="0"
                max="20000000"
                value={+mortgage.price.replace(/,/g, "")}
                onIonChange={handleMortgageRangeChange}
              />
            </div>

            <div className={styles.inputContainer}>
              <Input
                name="price"
                mode="numeric"
                defaultValue={mortgage.price}
                value={mortgage.price}
                prefix="$"
                onChange={handleMortgageInputChange}
                style={{ width: "25rem" }}
              />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.rangeContainer}>
              <span className={styles.label}>Term</span>
              <IonRange
                name="term"
                className={styles.range}
                min="0"
                max="30"
                step="5"
                value={+mortgage.term}
                onIonChange={handleMortgageRangeChange}
              />
            </div>

            <div className={styles.inputContainer}>
              <Input
                name="term"
                mode="numeric"
                defaultValue={mortgage.term}
                value={mortgage.term}
                onChange={handleMortgageInputChange}
                style={{ width: "25rem" }}
                postfix="years"
              />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.rangeContainer}>
              <span className={styles.label}>Rate</span>
              <IonRange
                name="rate"
                className={styles.range}
                min="0"
                max="10"
                step="0.1"
                value={+mortgage.rate}
                onIonChange={handleMortgageRangeChange}
              />
            </div>

            <div className={styles.inputContainer}>
              <Input
                name="rate"
                mode="decimal"
                defaultValue={mortgage.rate}
                value={mortgage.rate}
                onChange={handleMortgageInputChange}
                style={{ width: "25rem" }}
                postfix="%"
              />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.rangeContainer}>
              <span className={styles.label}>Down payment</span>
              <IonRange
                name="downPayment"
                className={styles.range}
                min="0"
                max="100"
                step="1"
                value={mortgage.downPaymentPercentage}
                onIonChange={handleMortgageRangeChange}
              />
            </div>

            <div className={styles.inputContainer}>
              <Input
                name="downPayment"
                mode="numeric"
                defaultValue={mortgage.downPayment}
                value={
                  isNaN(+mortgage.downPayment.replace(/,/g, ""))
                    ? 0
                    : mortgage.downPayment
                }
                onChange={handleMortgageInputChange}
                style={{ width: "16rem", marginRight: "1rem" }}
                prefix="$"
              />
              <Input
                name="downPaymentPercentage"
                mode="decimal"
                defaultValue={mortgage.downPayment}
                value={mortgage.downPaymentPercentage}
                onChange={handleMortgageInputChange}
                style={{ width: "8rem" }}
                postfix="%"
              />
            </div>
          </div>
          <div className={styles.payment}>
            <span className={styles.label}>Mortage payment:</span>
            <span className={styles.amount}>
              {!isFinite(mortgage.monthlyPayment)
                ? "$0*"
                : `$${numberWithCommas(
                    (Math.round(mortgage.monthlyPayment) * 10) / 10
                  )}*`}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.transfer}>
        <IonPopover
          cssClass={styles.popover}
          showBackdrop={false}
          isOpen={popover.isOpen}
          event={popover.event}
          onDidDismiss={() =>
            setPopover({ isOpen: false, event: undefined, content: "" })
          }
        >
          <div className={styles.content}>{popover.content}</div>
        </IonPopover>
        <div className={styles.header}>Land transfer tax</div>
        <div className={styles.item}>
          <div className={styles.inputContainer}>
            <div className={styles.label}>Home price</div>
            <Input
              name="price"
              mode="numeric"
              defaultValue={mortgage.price}
              value={mortgage.price}
              prefix="$"
              onChange={handleMortgageInputChange}
              style={{ width: "25rem" }}
            />
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.inputContainer}>
            <div className={styles.label}>
              Province of purchase:
              <span className={styles.subLabel}>
                {isPreCon ? listing.province : listing.address.state}
              </span>
            </div>
            <div className={styles.input}>
              <IonIcon
                icon={helpCircleOutline}
                className={styles.icon}
                onClick={(e) => {
                  e.persist();
                  setPopover({ isOpen: true, event: e, content: text1 });
                }}
              />
              <Input
                name="provincialTax"
                mode="numeric"
                defaultValue={tax.provincial}
                value={tax.provincial}
                prefix="$"
                style={{
                  width: "25rem",
                  backgroundColor: "#F2F2F2",
                  color: "#363636",
                }}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.inputContainer}>
            <div className={styles.label}>
              Municipality of purchase:
              <span className={styles.subLabel}>
                {isPreCon ? listing.city : listing.address.city}
              </span>
            </div>
            <div className={styles.input}>
              <IonIcon
                icon={helpCircleOutline}
                className={styles.icon}
                onClick={(e) => {
                  e.persist();
                  setPopover({ isOpen: true, event: e, content: text2 });
                }}
              />
              <Input
                name="municipitalTax"
                mode="numeric"
                defaultValue={tax.municipal}
                value={tax.municipal}
                prefix="$"
                style={{
                  width: "25rem",
                  backgroundColor: "#F2F2F2",
                  color: "#363636",
                }}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.inputContainer}>
            <div className={styles.label}>
              First time home buyer?
              <span className={styles.toggle}>
                <Toggle
                  color="gray"
                  active={tax.firstTimeBuyer}
                  onToggle={() =>
                    setTax({ ...tax, firstTimeBuyer: !tax.firstTimeBuyer })
                  }
                  isYesNo={true}
                />
              </span>
            </div>
            <div className={styles.input}>
              <IonIcon
                icon={helpCircleOutline}
                className={styles.icon}
                style={{ zIndex: "1000" }}
                onClick={(e) => {
                  e.persist();
                  setPopover({ isOpen: true, event: e, content: text3 });
                }}
              />
              <Input
                name="municipitalTax"
                mode="numeric"
                defaultValue="0"
                value={
                  tax.firstTimeBuyer
                    ? `${numberWithCommas(calculateRebate())} rebate`
                    : "0 rebate"
                }
                prefix="$"
                style={{
                  width: "25rem",
                  backgroundColor: tax.firstTimeBuyer ? "#dff7f0" : "#f4f4f7",
                  color: tax.firstTimeBuyer ? "#1f7a60" : "#707070",
                }}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className={styles.payment}>
          <span className={styles.label}>Total land transfer tax:</span>
          <span className={styles.amount}>
            {!isFinite(calculateTransferTax()) || isNaN(calculateTransferTax())
              ? "$0*"
              : `$${numberWithCommas(
                  (Math.round(calculateTransferTax()) * 10) / 10
                )}*`}
          </span>
        </div>
      </div>
      <div className={styles.disclaimer}>
        *Calculation formula is created by AECORN. This is for estimation
        purposes only.
      </div>
    </div>
  );
};
export default Calculators;
