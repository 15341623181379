import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FavoriteIcon from "../../../../../../components/ListingCard/components/FavoriteIcon/favorite-icon.component";
import withMap from "../../../../../../HOC/withMap/with-map";
import { updateMultipleFilters } from "../../../../../../redux/filters/filters.actions";
import { selectLocationsCitiesAndNeighbourhoods } from "../../../../../../redux/locations/locations.selectors";
import styles from "./detail-card-header.module.scss";

const DetailCardHeader = ({ listing, updateMapLocations }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { address } = listing;
  const locations = useSelector(selectLocationsCitiesAndNeighbourhoods);

  const isLocationAvailable = (name) =>
    ["Toronto", "Peel", "York", "Durham", "Halton"].includes(name) ||
    locations?.find((loc) => loc.name === name);

  const handlePathClick = (type) => {
    const location = locations?.find(
      (loc) =>
        loc.type === "Neighbourhood" && loc.name === address?.neighborhood
    );

    const filter = {};
    switch (type) {
      case "area":
        filter.area = [address.area];
        break;
      case "city":
        filter.area = [address.area];
        filter.city = [address.city];
        break;
      case "neighborhood":
        filter.area = [address.area];
        filter.city = null;
        filter.neighborhood = [address.neighborhood];
        updateMapLocations({
          center: location.location,
          coordinates: location.coords,
          neighborhood: location.name,
          city: address.city,
        });
        break;
      default:
        return;
    }

    dispatch(updateMultipleFilters(filter));
    history.push("/listings");
  };

  return (
    <div className={styles.header}>
      <div>
        <div className={styles.street}>
          {address.unitNumber && address.unitNumber + " - "}
          {address.streetNumber +
            " " +
            address.streetName +
            " " +
            address.streetSuffix}
          {address.streetDirection && " " + address.streetDirection + " "}
        </div>
        <div className={styles.address}>
          <>
            {address.area !== "Toronto" && (
              <>
                <span
                  className={`${styles.path} ${
                    isLocationAvailable(address.area) && styles.activePath
                  }`}
                  onClick={() =>
                    isLocationAvailable(address.area) && handlePathClick("area")
                  }
                >
                  {address.area}
                </span>{" "}
                &gt;{" "}
              </>
            )}
            <span
              className={`${styles.path} ${
                isLocationAvailable(address.city) && styles.activePath
              }`}
              onClick={() =>
                isLocationAvailable(address.city) && handlePathClick("city")
              }
            >
              {address.city}
            </span>{" "}
            &gt;{" "}
            <span
              className={`${styles.path} ${
                isLocationAvailable(address.neighborhood) && styles.activePath
              }`}
              onClick={() =>
                isLocationAvailable(address.neighborhood) &&
                handlePathClick("neighborhood")
              }
            >
              {address.neighborhood}
            </span>{" "}
            &gt;{" "}
            <span>
              {address.streetNumber +
                " " +
                address.streetName +
                " " +
                address.streetSuffix}
              {address.streetDirection && " " + address.streetDirection + " "}
            </span>
          </>
        </div>
      </div>
      <div className={styles.img}>
        <FavoriteIcon
          listing={listing}
          iconStyle={{ height: "4.2rem", width: "4.2rem" }}
          favIconStyle={{ height: "4.2rem", width: "4rem" }}
          notifIconStyle={{ height: "5.2rem", width: "4.4rem" }}
        />
      </div>
    </div>
  );
};

export default withMap(DetailCardHeader);
