import { fill } from "lodash";
import {
  VictoryLine,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryVoronoiContainer,
  VictoryScatter,
  VictoryAxis,
} from "victory";
import styles from "./chart.module.scss";

const DoubleLineChart = ({
  label,
  data,
  domain = 4,
  showY = true,
  style,
  scale,
}) => {
  return (
    <div className={styles.bar} style={style?.container}>
      <div className={styles.container}>
        {label && <div className={styles.label}>{label}</div>}
        <div className={styles.chartContainer} style={style?.chart}>
          <VictoryChart
            animate={{
              duration: 1000,
              onLoad: { duration: 1000 },
            }}
            domain={{ y: domain ? [0, domain] : null }}
            scale={{ y: scale ? scale : "linear" }}
            containerComponent={
              <VictoryVoronoiContainer
                mouseFollowTooltips
                voronoiDimension="x"
                disable={!showY}
                labels={({ datum }) =>
                  `${datum.childName.includes(0) ? "  School " : "Board "} ${
                    datum.x
                  } score: ${datum.y}  `
                }
                style={{
                  touchAction: "auto",
                  userSelect: "auto",
                }}
                voronoiBlacklist={["line1", "line2"]}
              />
            }
          >
            <VictoryAxis label="Years" style={{ axisLabel: { padding: 30 } }} />
            <VictoryAxis dependentAxis style={{ axisLabel: { padding: 30 } }} />
            <VictoryLine
              data={data.school.filter((item) => item.y > 0)}
              style={{ data: { stroke: "#363636", strokeWidth: 2 } }}
            />
            <VictoryLine
              data={data.board.filter((item) => item.y > 0)}
              style={{ data: { stroke: "#9c9c9c", strokeWidth: 2 } }}
            />
            <VictoryScatter
              name="line1"
              data={data.school.filter((item) => item.y > 0)}
              style={{
                data: { fill: "#363636" },
                labels: { fontWeight: 600, offset: 100 },
              }}
              size={5}
            />
            <VictoryScatter
              name="line2"
              data={data.board.filter((item) => item.y > 0)}
              style={{
                data: { fill: "#9c9c9c" },
                labels: { fontWeight: 600, offset: 100 },
              }}
              size={5}
            />
          </VictoryChart>
        </div>
      </div>
    </div>
  );
};

export default DoubleLineChart;
