import { useState, useEffect } from "react";
import { IonGrid, IonRow, IonCol, IonButton, IonIcon } from "@ionic/react";
import CardContainer from "../../../../../pages/Tours/component/CardContainer/card-container.component";
import styles from "./tour-card.module.scss";
import { attachOutline, navigateOutline } from "ionicons/icons";
import {
  buildAddress,
  capitalize,
  handleAppNavigation,
  numberWithCommas,
} from "../../../../../utils/functions";
import withClient from "../../../../../HOC/withClient/with-client";
import Rating from "../../../../../components/Rating/rating.component";
import { API } from "@aws-amplify/api";
import {
  onCreateReview,
  onUpdateReviewById,
} from "../../../../../graphql/subscriptions";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../redux/user/user.selectors";

const TourCard = ({ currentClient, tourItem, listing }) => {
  const history = useHistory();
  const user = useSelector(selectCurrentUser);
  const [review, setReview] = useState(
    tourItem?.reviews?.items.find((r) => r?.userId === currentClient?.userId)
  );

  useEffect(() => {
    let updateSubscription, createSubscription;
    const subscribeUpdate = async () => {
      updateSubscription = await API.graphql({
        query: onUpdateReviewById,
        variables: { id: review.id },
      }).subscribe({
        next: async ({
          value: {
            data: { onUpdateReviewById },
          },
        }) => {
          setReview(onUpdateReviewById);
        },
      });
    };

    const subscribeCreate = async () => {
      createSubscription = await API.graphql({
        query: onCreateReview,
        variables: { userId: user.username },
      }).subscribe({
        next: async ({
          value: {
            data: { onCreateReview },
          },
        }) => {
          setReview(onCreateReview);
        },
      });
    };
    if (review && tourItem) subscribeUpdate();
    if (tourItem) subscribeCreate();

    return () => {
      if (createSubscription) createSubscription.unsubscribe();
      if (updateSubscription) updateSubscription.unsubscribe();
    };
  }, [review, tourItem]);

  return (
    <>
      <CardContainer>
        <IonGrid className={styles.tourcard}>
          <IonRow className={`${styles.row} ${styles.firstRow}`}>
            <IonCol className={styles.index}>{tourItem.order}</IonCol>
            <IonCol className={styles.address}>
              <div
                onClick={() =>
                  history.push(`/tabs/tours/${tourItem.tourId}/${tourItem.id}`)
                }>
                {buildAddress(listing.address)}
              </div>
              <div
                className={
                  styles.postalCode
                }>{`${listing.address.zip}, ${listing.address.city}`}</div>
            </IonCol>
          </IonRow>
          <IonRow className={`${styles.row} ${styles.details}`}>
            <IonCol>Time slot</IonCol>
            <IonCol size="8" className={styles.bold}>
              {tourItem.startTime
                ? moment(tourItem.startTime, "HH:mm").format("h:mm A")
                : "-"}
              -
              {tourItem.endTime
                ? moment(tourItem.endTime, "HH:mm").format("h:mm A")
                : "-"}
            </IonCol>
          </IonRow>
          <IonRow className={`${styles.row} ${styles.details}`}>
            <IonCol>Price</IonCol>
            <IonCol className={styles.bold}>
              ${numberWithCommas(listing.listPrice)}
            </IonCol>
          </IonRow>
          <IonRow className={`${styles.row} ${styles.details}`}>
            <IonCol>Showing status</IonCol>
            <IonCol className={styles.bold}>
              {capitalize(tourItem.status)}
            </IonCol>
          </IonRow>
          <IonRow className={`${styles.row} ${styles.details}`}>
            <IonCol>My review</IonCol>
            <IonCol className={`${styles.bold} ${styles.reviewColumn}`}>
              <Rating review={review} size="lg" disabled readOnly />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                className={styles.detailsBtn}
                routerDirection="forward"
                routerLink={`/tabs/tours/${tourItem.tourId}/${tourItem.id}`}>
                <IonIcon icon={attachOutline} />
                Details
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                className={styles.directionBtn}
                onClick={() =>
                  handleAppNavigation({
                    map: listing.map,
                    address: listing.address,
                    byAddress: true,
                  })
                }>
                <IonIcon icon={navigateOutline} />
                Directions
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CardContainer>
    </>
  );
};

export default withClient(TourCard);
