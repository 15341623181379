export enum ListingLastStatus {
    Suspended = 'Sus',
    Expired = 'Exp',
    Sold = 'Sld',
    Terminated = 'Ter',
    DealFellThrough = 'Dft',
    Leased = 'Lsd',
    SoldConditionally = 'Sc',
    SoldConditionallyWithEscapeClause = 'Sce',
    LeasedConditionally = 'Lsc',
    PriceChange = 'Pc',
    Extension = 'Ext',
    New = 'New',
    ComingSoon = 'Cs',
}