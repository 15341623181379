import Select from "../../Form/Select/select.component";
import withFilters from "../../../HOC/withFilters/with-filters";
import styles from "../filters-menu.module.scss";
import TypeFilter from "../../TypeFilter/type-filter.component";
import {
  residentialTypes,
  commercialTypes,
  preTypes,
} from "../../Filters/data/filters.data";

const PropertyTypeFilterItem = ({
  buttonStyle,
  filters: { propertyType, class: classFilter },
  updateSingleFilter,
  tabName,
  tab,
  setTab,
  disabled,
  isPreConstruction,
}) => {
  const options = isPreConstruction
    ? preTypes
    : classFilter.includes("residential")
    ? residentialTypes
    : commercialTypes;

  const onChangeHandler = (item) => {
    if (item === "Any type") {
      updateSingleFilter({
        propertyType: null,
      });
    } else {
      if (!propertyType) {
        updateSingleFilter({
          propertyType: propertyType ? [...propertyType, item] : [item],
        });
      } else {
        const index = propertyType.indexOf(item);
        if (index > -1) {
          const updatedTypes = propertyType.filter((type) => type !== item);
          if (updatedTypes.length) {
            updateSingleFilter({
              propertyType: propertyType.filter((type) => type !== item),
            });
          } else {
            updateSingleFilter({
              propertyType: null,
            });
          }
        } else {
          updateSingleFilter({
            propertyType: propertyType ? [...propertyType, item] : [item],
          });
        }
      }
    }
  };

  return (
    <div className={styles.item}>
      <Select
        buttonStyle={buttonStyle}
        title="Property Type"
        open={tab === tabName}
        onClick={() => setTab(tabName)}
        dropdown={false}
        disabled={disabled}
      />
      <TypeFilter
        open={tab === tabName}
        items={options}
        value={propertyType ? propertyType : ["Any type"]}
        onClickHandler={onChangeHandler}
        dropdown={false}
      />
    </div>
  );
};

export default withFilters(PropertyTypeFilterItem);
