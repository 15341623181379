import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonButton,
  IonIcon,
  IonModal,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { bedOutline, lockClosedOutline } from "ionicons/icons";
import BathIcon from "../../../../../../../../../assets/svg/REA103_Icons-01a_Bath.svg";
import SqftIcon from "../../../../../../../../../assets/svg/REA103_Icons-01a_SquareFeet.svg";
import ParkingIcon from "../../../../../../../../../assets/svg/REA103_Icons-01a_Parking.svg";
import { numberWithCommas } from "../../../../../../../../../utils/functions";
import NoImages from "../../../../../../../../../assets/img/no-photo.png";
import { openModal } from "../../../../../../../../../redux/ui/ui.actions";
import numeral from "numeral";
import styles from "./pre-floor-plans.module.scss";
import PreRequestForm from "../../../../../../../../../app/components/CustomModals/PreRequestForm/pre-request-form.component";
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

const PreFloorDetails = ({ item, isApp }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const mediaObj = JSON.parse(item.media);
  const imagesObj = mediaObj.filter((obj) => {
    return obj.type === "image";
  });

  const handleError = (e) => (e.target.hidden = true);

  const data =
    "Unit: Beds: " +
    item.numBeds +
    " Baths: " +
    item.numBaths +
    " Parking: " +
    item.numParkings +
    " Locker: " +
    item.numLockers +
    " Price/Sqft: " +
    numeral(item.pricePerSqft).format("$1,000,000") +
    " Sqft: " +
    numeral(item.sqft).format("1,000,000") +
    " for price: " +
    numeral(item.price).format("$1,000,000") +
    " Project: " +
    item.project.name +
    " at " +
    item.project.streetNumber +
    " " +
    item.project.streetName +
    ", " +
    item.project.city +
    ", starting from " +
    numeral(item.project.startPrice).format("$1,000,000");

  const preRequestFormHandler = () => {
    dispatch(
      openModal({
        current: "preRequestForm",
        data: { isUnit: true, info: data },
      })
    );
  };

  return (
    <>
      {showModal && (
        <PreRequestForm
          showModal={showModal}
          setShowModal={setShowModal}
          info={data}
          isUnit={true}
          // parentRef={parentRef}
        />
      )}

      <IonModal
        className={styles.modal}
        isOpen={modal}
        cssClass="fullScreenModal"
        animated={false}
        backdropDismiss={false}
      >
        {modal && (
          <div className={styles.container}>
            <img
              className={isApp ? styles.appImg : styles.webImg}
              src={imagesObj[0].value}
              onError={handleError}
              alt="Listing"
              onClick={(e) => e.stopPropagation()}
            />

            <div className={styles.closeContainer}>
              <IonButton
                // fill="outline"
                shape="round"
                className={styles.btn}
                onClick={() => setModal(false)}
              >
                <IonIcon icon={closeOutline} />
                close
              </IonButton>
            </div>
          </div>
        )}
      </IonModal>

      <div
        className={
          isApp ? styles.PreFloorDetailsApp : styles.PreFloorDetailsWeb
        }
      >
        {isApp && (
          <div className={styles.price}>
            {item.price
              ? numberWithCommas(item.price, true)
              : "Pricing coming soon"}
          </div>
        )}

        <div className={styles.left}>
          {imagesObj.length === 0 ? (
            <div className={styles.noPlan}>
              <img className={styles.noImg} src={NoImages} />
            </div>
          ) : (
            <div className={styles.plan}>
              <img
                className={styles.img}
                src={imagesObj[0].value}
                onClick={() => {
                  setModal(true);
                }}
              />
            </div>
          )}
        </div>

        <div className={styles.right}>
          <div className={styles.firstRow}>
            {!isApp && (
              <div className={styles.price}>
                {item.price
                  ? numberWithCommas(item.price, true)
                  : "Pricing coming soon"}
              </div>
            )}

            {!isApp && (
              <div>
                <IonButton
                  className={styles.btn}
                  onClick={preRequestFormHandler}
                >
                  Secure this unit
                </IonButton>
              </div>
            )}
          </div>

          <div className={styles.secondRow}>
            <div className={styles.details}>
              <div>
                <IonIcon icon={bedOutline} className={styles.icon} />
                {item.numBeds} bed
              </div>

              <div>
                <IonIcon src={BathIcon} className={styles.icon} />
                {item.numBaths ? `${item.numBaths} bath` : "-"}
              </div>

              <div>
                <IonIcon src={ParkingIcon} className={styles.icon} />
                {item.numParkings ? `${item.numParkings} parking` : "-"}
              </div>

              <div>
                <IonIcon
                  src={lockClosedOutline}
                  className={`${styles.icon} ${styles.locker}`}
                />
                {item.numLockers ? `${item.numLockers} locker` : "-"}
              </div>

              <div>
                <IonIcon src={SqftIcon} className={styles.icon} />
                {item.pricePerSqft
                  ? `${numberWithCommas(item.pricePerSqft, true)}/sqft`
                  : "-"}
              </div>

              <div>
                <IonIcon src={SqftIcon} className={styles.icon} />
                {item.sqft ? `${numberWithCommas(item.sqft)} sqft` : "-"}
              </div>
            </div>

            {isApp && (
              <div>
                <IonButton
                  className={styles.btn}
                  onClick={() => setShowModal(true)}
                >
                  Secure this unit
                </IonButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PreFloorDetails;
