import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/react";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import AppHeader from "../../components/Header/header.component";
import AgentsPhoto from "../../../assets/img/About - agents@4x.png";
import BrokeragePhoto from "../../../assets/img/About - brokerage@4x.png";
import SupportPhoto from "../../../assets/img/About - support@4x.png";
import styles from "./about-us.module.scss";
import { handleLinkClick } from "../../../utils/functions";

const AppAboutUs = () => {
  const sendEmailHandler = async () => {
    try {
      let Link = "mailto:info@aecorn.ca?subject=Hiring%20agents";
      window.open(Link, "_system");
    } catch (error) {
      try {
        InAppBrowser.create(
          `mailto:info@aecorn.ca?Subject=Hiring%20agents`,
          "_blank",
          {
            hideurlbar: true,
            hidenavigationbutton: true,
            toolbar: "yes",
            toolbarcolor: "#363636",
            closebuttoncolor: "#f2f2f2",
            closebuttoncaption: "Back",
            toolbarposition: "top",
          }
        );
      } catch (error) {
        console.log("Something went wrong!");
      }
    }
  };

  return (
    <IonPage>
      <AppHeader title="About us" hasRightButton={false} />

      <IonContent>
        <IonGrid className={styles.aboutus}>
          <IonRow>
            <IonCol className={styles.generalInformation}>
              <div className={styles.title}>Who is AECORN Realty?</div>
              <div className={styles.info}>
                <div>
                  AECORN Realty is a full-service real estate brokerage who can
                  guide you through the entire process – door to door.
                </div>
                <div>
                  We pride ourselves on being at the forefront of real estate
                  technology and practices, which is why we have developed our
                  own search platform. AECORN Realty is creating exclusive tools
                  and services available nowhere else. We are continusouly
                  updating and improving our platform and have big plans for the
                  future.
                </div>
                The AECORN Realty brokerage operates under a revolutionary,
                customer-focused methodology, offering clients a unique cashback
                program that can easily save them thousands when compared to the
                traditional commission system.
              </div>
              <div>
                <IonButton
                  expand="block"
                  shape="round"
                  className={styles.button}
                  onClick={() =>
                    handleLinkClick("https://aecorn.ca/learn-more/")
                  }
                >
                  Learn more about AECORN Realty
                </IonButton>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className={styles.team}>
              <div className={styles.title}>The AECORN Realty team</div>
              <div>
                <img
                  alt="AECORN team"
                  src={AgentsPhoto}
                  className={styles.img}
                />
              </div>
              <div className={styles.info}>
                <span className={styles.label}>Agents</span> <br />
                AECORN Realty team of hyper-localized, specialized real estate
                sales representatives are ready to help you achieve your buying,
                selling, leasing or investing goals. Our realtors have lived
                and/or worked in the areas they show for years — so you can
                trust they know the neighbourhood like the back of their hand.
                Get all details about all the ins and outs, best hidden gems,
                local festivities and more! If you would like to be connected
                with a hyper-localized agent, please send us a message and we’ll
                be happy to get you matched with an agent within 24 hours!
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className={styles.team}>
              <div>
                <img src={BrokeragePhoto} className={styles.img} />
              </div>
              <div className={styles.info}>
                <span className={styles.label}>Brokerage team</span> <br />
                Our brokerage is lead by a team of well-established
                professionals who aim to provide the best value-for-service in
                the real estate industry. Whether you’re a client looking for a
                new home or hoping to join our team at AECORN Realty, we’re
                happy to talk about your goals and discuss the best ways for you
                to achieve them.
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className={styles.team}>
              <div>
                <img src={SupportPhoto} className={styles.img} />
              </div>
              <div className={styles.info}>
                <span className={styles.label}>
                  Business, marketing & technology
                </span>{" "}
                <br />
                This team of developers, UX designers, business leaders and
                digital marketers build the analytics and structure of the
                AECORN Realty web platform, app and realty services to create
                the most effective user experience possible.
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className={styles.career}>
              <div className={styles.title}>Careers</div>
              <div className={styles.subtitle}>Hiring agents</div>
              <div className={styles.info}>
                AECORN Realty is eagerly looking for both new and experienced
                agents to join the team and be an integral part of our
                innovative real estate platform. Please send your inquiries and
                resume to{" "}
                <span onClick={sendEmailHandler} className={styles.underline}>
                  info@aecorn.ca.
                </span>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className={styles.faq}>
              <div className={styles.title}>FAQ</div>

              <div className={styles.question}>
                <div className={styles.label}>
                  Is AECORN a brokerage or an app?
                </div>
                Both! AECORN Realty is a brokerage that has developed their own
                web search platform.
              </div>

              <div className={styles.question}>
                <div className={styles.label}>
                  Why do I need to register to see sold prices?
                </div>
                To legally view sold property data in some geographical areas,
                you must agree to certain terms of use. Your user ID is the
                email with which you agreed to these terms.
              </div>

              <div className={styles.question}>
                <div className={styles.label}>
                  What is the EQAO school rating?
                </div>
                Many users still expect to see Fraser Institute school ratings.
                But due to recent copyright changes by the Fraser Institute,
                AECORN Realty now uses the EQAO rating system, which ranks out
                of 4. Learn more at{" "}
                <a
                  href="https://www.eqao.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  eqao.com.
                </a>
              </div>

              <div className={styles.question}>
                <div className={styles.label}>
                  How much do your services cost?
                </div>{" "}
                All our services are tailored to you individually. To find out
                what will work for you, just submit a request and a AECORN
                Realty agent will contact you with all the details.
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AppAboutUs;
