import { useHistory } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";
import Button from "../../Form/Button/button.component";
import { nothingAdded } from "../../../data/customModal";
import styles from "./nothing-added-modal.module.scss";

const NothingAddedModal = ({ close, language }) => {
  const history = useHistory();
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <IonIcon icon={alertCircleOutline} />
      </div>

      <div className={styles.info}>{nothingAdded[language].info}</div>
      <div className={styles.btn}>
        <Button
          title={nothingAdded[language].btn}
          type="green"
          style={{
            width: "47rem",
            height: "5rem",
            border: "1px solid #1F7A60",
          }}
          onClick={close}
        />
      </div>
    </div>
  );
};

export default NothingAddedModal;
