import { useDispatch, useSelector } from "react-redux";
import DetailPageButton from "./DetailPageButton/detail-page-button.component";
import VirtualTour from "../../../../../../assets/svg/REA103_Icons-01a_VirtualTour.svg";
import MapView from "../../../../../../assets/svg/map-details.svg";
import ImageView from "../../../../../../assets/svg/image-view.svg";
import { detailPageButtons } from "../../../../../../data/detailsPage";
import NavigateIcon from "../../../../../../assets/svg/navigate-outline.svg";
import { calculateCashback } from "../../../../../../utils/functions";
import { openModal } from "../../../../../../redux/ui/ui.actions";
import styles from "./detail-page-buttons.module.scss";
import { IonButton } from "@ionic/react";
import { selectEttieOffer } from "../../../../../../redux/ui/ui.selectors";
import numeral from "numeral";

const DetailPageButtons = ({
  listing,
  showMap,
  virtualTourHandler,
  mapViewHandler,
  hasVirtualTour,
  navigateHandler,
  language,
}) => {
  const dispatch = useDispatch();
  const offer = useSelector(selectEttieOffer);
  const getCashbackAmount = () => {
    if (listing.type.toLowerCase() === "sale") {
      return calculateCashback(+listing.listPrice, "sale");
    } else {
      return calculateCashback(+listing.listPrice, "lease");
    }
  };

  const info =
    "MLS #: " +
    listing.mlsNumber +
    " Address: " +
    listing.address.streetNumber +
    " " +
    listing.address.streetName +
    " " +
    listing.address.streetSuffix +
    " Price: " +
    numeral(listing.listPrice).format("$1,000,000");

  const cashbackFormHandler = () => {
    dispatch(
      openModal({
        current: "requestForm",
        overflow: true,
        data: {
          cashback: true,
          info: info,
          cashbackAmount: getCashbackAmount(),
          isSale: listing.type.toLowerCase() === "sale",
        },
      })
    );
  };

  const getCashbackTitle = () => {
    if (listing.class === "CommercialProperty")
      return "Contact us for AECORN cashback offers!";
    if (listing.type.toLowerCase() === "sale") {
      return `$${calculateCashback(+listing.listPrice, "sale")} cashback!`;
    } else {
      return `$${calculateCashback(+listing.listPrice, "lease")} cashback!`;
    }
  };

  return (
    <div>
      <div className={styles.btns}>
        <DetailPageButton
          icon={VirtualTour}
          label={detailPageButtons[language].virtualTour}
          imgheight="42px"
          imgwidth="30.72px"
          onClick={virtualTourHandler}
          disable={!hasVirtualTour}
          isImage={false}
        />
        <DetailPageButton
          icon={showMap ? ImageView : MapView}
          label={
            showMap
              ? detailPageButtons[language].gallery
              : detailPageButtons[language].mapView
          }
          imgheight="37.69px"
          imgwidth="42.13px"
          onClick={mapViewHandler}
          isImage={false}
        />
        <DetailPageButton
          icon={NavigateIcon}
          label={detailPageButtons[language].navigate}
          imgheight="37.69px"
          imgwidth="42.13px"
          onClick={navigateHandler}
          isImage={false}
        />
      </div>

      {/* <div>
        {listing.status === "A" && offer && (
          <IonButton
            className={styles.cashbackBtn}
            onClick={cashbackFormHandler}
          >
            {listing.class !== "CommercialProperty" && "Get up to"}
            <span>{getCashbackTitle()}</span>
          </IonButton>
        )}
      </div> */}
    </div>
  );
};

export default DetailPageButtons;
