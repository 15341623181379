import { useState, useRef, createRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "../../../../../components/Form/Button/button.component";
import Input from "../../../../../components/Form/Input/input.component";
import { IonIcon, IonButton } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import styles from "./agent-details.module.scss";
import IsVisibleTag from "../../../../../components/IsVisibleTag/is-visible-tag.component";
import useComponentVisible from "../../../../../hooks/useComponentVisible";
import Select from "../../../../../components/Form/Select/select.component";
import DropdownContainer from "../../../../../components/Form/DropdownContainer/dropdown-container.component";
import { selectCurrentClient } from "../../../../../redux/client/client.selectors";
import { UserService } from "../../../../../services/userService";

const AgentDetails = ({ tour, next }) => {
  const inputRefs = useRef({
    tour_title: createRef(),
  });
  const agent = useSelector(selectCurrentClient);
  const [agents, setAgents] = useState([]);
  const [leadAgent, setLeadAgent] = useState();
  const {
    ref: refAgent,
    isOpen: isOpenAgent,
    setIsOpen: setIsOpenAgent,
  } = useComponentVisible(false);

  const [title, setTitle] = useState('');
  const [note, setNote] = useState('');

  useEffect(() => {
    const fetchAgents = async () => {
      const agents = await UserService.getAgentUsers();
      setAgents(agents);
    };
    fetchAgents();
  }, [agent]);

  const handleNoteChange = ({ target: { value } }) => {
    setNote(value);
  };

  const handleClear = () => {
    setTitle("");
    setNote("");
  };

  const handleClick = async () => {
    next({
      title,
      privateNote: note,
      leadAgent: leadAgent,
    });
  };

  useEffect(() => {
    if (tour) {
      setTitle(tour.title);
      setNote(tour.privateNote ? tour.privateNote : "");
      setLeadAgent(tour.leadAgent);
      const div = document.getElementById("new_tour");
      div?.scrollIntoView();
    }
  }, [tour]);

  return (
    <div className={styles.agentDetails}>
      <div className={styles.details}>
        <div className={styles.label}>Agent details</div>
        <IsVisibleTag isVisible={false} />
      </div>

      <Input
        name="tour_title"
        label="Tour title"
        placeholder="Tour title"
        required
        ref={inputRefs.current.tour_title}
        value={title}
        onChange={(value, name) => setTitle(value)}
        validation="required"
      />
      <div ref={refAgent} className={styles.container}>
        <Select
          label=""
          title={
            leadAgent
              ? `${leadAgent.givenName} ${leadAgent.familyName}`
              : "Lead agent"
          }
          style={{ height: "4rem", width: "100%" }}
          buttonStyle={{ height: "100%" }}
          open={isOpenAgent}
          onClick={() => setIsOpenAgent(!isOpenAgent)}
        />

        <DropdownContainer
          open={isOpenAgent}
          style={{
            position: "absolute",
            top: "auto",
            left: "auto",
            maxHeight: "15rem",
          }}>
          {agents &&
            agents.map((agent) => (
              <div
                key={agent.id}
                className={styles.item}
                onClick={() => {
                  setLeadAgent(agent);
                  setIsOpenAgent(false);
                }}>{`${agent.givenName} ${agent.familyName}`}</div>
            ))}
        </DropdownContainer>
      </div>
      <textarea
        className={styles.textarea}
        name="agent_note"
        placeholder="Private agent tour notes"
        ref={inputRefs.current.agent_note}
        value={note}
        onChange={handleNoteChange}></textarea>

      <Button
        title="Save and continue"
        type="green"
        style={{
          border:
            title && leadAgent ? "1px solid #1F7A60" : "1px solid #e5e5e5",
          width: "100%",
          height: "5rem",
        }}
        onClick={handleClick}
        disabled={!title || !leadAgent}
      />
      <IonButton fill="clear" className={styles.close} onClick={handleClear}>
        <IonIcon icon={closeOutline} className={styles.icon} />
        Clear
      </IonButton>
    </div>
  );
};

export default AgentDetails;
