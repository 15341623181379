import {
  IonPage,
  IonContent,
  IonIcon,
  IonButton,
  IonLabel,
  IonModal,
  IonRange,
} from "@ionic/react";
import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./home.module.scss";
import {
  key,
  personOutline,
  pricetag,
  arrowForwardOutline,
} from "ionicons/icons";
import chevron from "../../../assets/svg/homepage/chevronGreen.svg";
import soldIcon from "../../../assets/svg/homepage/sold.svg";
import preconIcon from "../../../assets/svg/homepage/precon.svg";
import {
  selectCurrentUser,
} from "../../../redux/user/user.selectors";
import { updateMultipleFilters } from "../../../redux/filters/filters.actions";
import moment from "moment";
import Featured from "./components/featured.component";
import app from "../../../assets/svg/homepage/app.png";
import signup from "../../../assets/svg/homepage/signup.png";
import neighbourhood from "../../../assets/svg/homepage/neighbourhood.png";
import tag from "../../../assets/svg/homepage/tag.png";
import consultant from "../../../assets/svg/homepage/consultant.png";
import HomeCard from "./components/home-card.component";
import { isApp, isCapacitor } from "../../../utils/functions";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { getHomeItems, updateClient } from "../../../amplify/graphql.utils";
import { Link, useHistory } from "react-router-dom";
import ContactAgentForm from "./components/ContactAgentForm/contact-agent-form.component";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import LocationSearch from "../../../components/LocationSearch/location-search.component";
import RequestForm from "../../components/CustomModals/RequestForm/request-form-component";
import {
  buildAddress,
  numberWithCommas,
  calculateCashback,
} from "../../../utils/functions";
import TextAnimation from "react-text-animations";
import { selectEttieOffer } from "../../../redux/ui/ui.selectors";
import { updateToast } from "../../../redux/ui/ui.actions";
import LogoWithText from "../../../components/Logo/LogoWithText";

const AppHomePage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [featureds, setFeatureds] = useState([]);
  const [cards, setCards] = useState([]);
  const history = useHistory();
  const contentRef = useRef();
  const sectionsRef = useRef();
  const user = useSelector(selectCurrentUser);
  const client = useSelector(selectCurrentClient);
  const [showForm, setShowForm] = useState(false);
  const [budget, setBudget] = useState(numberWithCommas(1500000));
  const [savings, setSavings] = useState();
  const offer = useSelector(selectEttieOffer);

  const handleBtn = (type) => {
    switch (type) {
      case "sale":
        dispatch(
          updateMultipleFilters({
            type: "sale",
            status: "A",
            lastStatus: null,
            displayPublic: "Y",
            displayAddressOnInternet: "Y",
            minListDate: null,
            minSoldDate: null,
            maxSoldDate: null,
          })
        );
        history.replace("/listings", { direction: "forward" });
        break;
      case "sold":
        dispatch(
          updateMultipleFilters({
            type: "sale",
            status: "U",
            lastStatus: "Sld",
            displayPublic: null,
            displayAddressOnInternet: null,
            minListDate: null,
            maxSoldDate: null,
            minSoldDate: moment().subtract(90, "days").format("YYYY-MM-DD"),
          })
        );
        history.replace("/listings", { direction: "forward" });
        break;
      case "lease":
        dispatch(
          updateMultipleFilters({
            type: "lease",
            status: "A",
            lastStatus: null,
            displayPublic: "Y",
            displayAddressOnInternet: "Y",
            minListDate: null,
            minSoldDate: null,
            maxSoldDate: null,
          })
        );
        history.replace("/listings", { direction: "forward" });
        break;
      case "pre-construction":
        dispatch(
          updateMultipleFilters({
            type: "pre-construction",
          })
        );
        history.push("/listings", { direction: "forward" });
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const { cards, featureds } = await getHomeItems();
        setFeatureds(featureds);
        setCards(cards);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    getData();
  }, [user]);

  const handleScroll = async () => {
    await contentRef.current.scrollToPoint(
      0,
      sectionsRef.current.offsetTop,
      1000
    );
  };

  const handleLink = (link) => {
    if (isApp()) {
      InAppBrowser.create(link, "_blank", {
        hideurlbar: true,
        hidenavigationbutton: true,
        toolbar: "yes",
        toolbarcolor: "#110d1b",
        closebuttoncolor: "#f4f4f7",
        closebuttoncaption: "Back",
        toolbarposition: "top",
      });
    } else {
      window.open(link, "_blank");
    }
  };

  useEffect(() => {
    const getSaving = async () => {
      const result = await calculateCashback(+budget.replace(/,/g, ""), "sale");
      setSavings(result);
    };

    getSaving();
  }, [offer]);

  useEffect(() => {
    const getSaving = async () => {
      const result = await calculateCashback(+budget.replace(/,/g, ""), "sale");
      setSavings(result);
    };

    getSaving();
  }, [budget, offer]);

  const handleBudgetRangeChange = ({ target: { name }, detail: { value } }) => {
    if (name === "budget") {
      setBudget(numberWithCommas(value));
    }
  };

  return (
    <IonPage className={styles.page}>
      <IonContent className={styles.home} scrollEvents ref={contentRef}>
        {/* <IonModal isOpen={showForm} onDidDismiss={() => setShowForm(false)}>
          <ContactAgentForm setShowModal={setShowForm} />
        </IonModal> */}

        <RequestForm
          isCashback
          // isSale={listing.type.toLowerCase() === "sale"}
          showModal={showForm}
          setShowModal={setShowForm}
          isHomepage={true}
          // listing={listing}
          // parentRef={containerRef}
        />

        <div className={styles.top}>
          <div className={styles.header}>
            <div className={styles.logo}>
              <LogoWithText />
            </div>
            {client && user ? (
              <span
                className={styles.welcome}
                onClick={() =>
                  history.push("/tabs/more", { direction: "forward" })
                }>
                Hi {client.givenName}!
              </span>
            ) : (
              <button
                className={`${styles.btn} ${styles.transparent}`}
                onClick={() =>
                  history.push("/signin", { direction: "forward" })
                }>
                <IonIcon icon={personOutline} />
                Sign in
              </button>
            )}
          </div>
          <div className={styles.content}>
            <div className={styles.title}>
              <span>Let's get </span>started!
            </div>
            {/* <div className={styles.subtitle}>
              What are you looking for today?
            </div> */}

            <div className={styles.search}>
              <LocationSearch isHomepage isApp={true} disabled={false} />
            </div>
            {/* <div className={styles.btns}>
              <button
                className={`${styles.btn} ${styles.white}`}
                onClick={() => handleBtn("sale")}>
                <IonIcon icon={pricetag} style={{ strokeWidth: "3rem" }} />
                <div style={{ display: "grid" }}>Sale</div>
              </button>
              <button
                className={`${styles.btn} ${styles.white}`}
                onClick={() => handleBtn("sold")}
              >
                <IonIcon icon={soldIcon} />
                <div style={{ display: "grid" }}>Sold</div>
              </button>
              <button
                className={`${styles.btn} ${styles.white}`}
                onClick={() => handleBtn("lease")}
              >
                <IonIcon icon={key} style={{ strokeWidth: "3rem" }} />
                <div style={{ display: "grid" }}>Lease</div>
              </button>
              <button
                className={`${styles.btn} ${styles.white}`}
                onClick={() => handleBtn("pre-construction")}>
                <IonIcon icon={preconIcon} />
                <div style={{ display: "grid" }}>Pre-con</div>
              </button>
            </div> */}

            <div className={styles.scrollBtnContainer}>
              {!loading ? (
                <div className={styles.scroll} onClick={handleScroll}>
                  <IonIcon className={styles.chevron} icon={chevron} />
                </div>
              ) : (
                <div className={styles.scrollContainer} />
              )}
            </div>
          </div>
        </div>
        {!loading && (
          <>
            <div className={styles.sections} ref={sectionsRef}>
              {featureds.length > 0 &&
                featureds
                  .sort((a, b) => {
                    if (a.order > b.order) return 1;
                    if (a.order < b.order) return -1;
                    return 0;
                  })
                  .map((featured, i) => <Featured item={featured} key={i} />)}
            </div>

            <div className={styles.tags}>
              <div className={styles.tag}>
                <img src={signup} alt="Sign up" />
                <span className={styles.title}>
                  Sign up for free on the AECORN app
                </span>
              </div>
              <div className={styles.tag}>
                <img src={app} alt="Find properties" />
                <span className={styles.title}>
                  Go on showings with your AECORN agent
                </span>
              </div>
              <div className={styles.tag}>
                <img src={neighbourhood} alt="Book" />
                <span className={styles.title}>Put in an offer</span>
              </div>
              <div className={styles.tag}>
                <img src={tag} alt="Work with agent" />
                <span className={styles.title}>Close your deal</span>
              </div>

              {/* <IonButton
                className={styles.agentBtn}
                fill="outline"
                onClick={() => setShowForm(true)}
              >
                I want cash back! Tell me more.
                <IonIcon icon={arrowForwardOutline} />
              </IonButton> */}
            </div>
            <div className={styles.note}>
              <div className={styles.title}>
                AECORN is a full-service brokerage that can guide you through
                the entire process – door to door.
              </div>
              <div className={styles.subtitle}>
                That’s why we’ve developed a search platform with specialized
                features available exclusively through AECORN.
              </div>
              <IonButton
                className={styles.clearBtn}
                fill="outline"
                onClick={() => history.push("/about-us")}>
                <IonLabel>What makes AECORN different?</IonLabel>
              </IonButton>
            </div>
            {/* <div className={styles.cards}>
              {cards.length > 0 &&
                cards
                  .sort((a, b) => {
                    if (a.order > b.order) return 1;
                    if (a.order < b.order) return -1;
                    return 0;
                  })
                  .map((c, i) => <HomeCard key={i} item={c} />)}
            </div> */}
            <div className={styles.disclaimer}>
              Disclaimer: *estimated savings using the à la carte model. Contact
              AECORN at info@aecorn.ca to discuss a personalized quote.
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default AppHomePage;
