import { useState, useEffect } from "react";
import moment from "moment";
import {
  IonItem,
  IonDatetime,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonDatetimeButton,
  IonModal,
} from "@ionic/react";
import Input from "../../../../../components/Form/Input/input.component";
import IsVisibleTag from "../../../../../components/IsVisibleTag/is-visible-tag.component";
import styles from "./tour-details.module.scss";
import { closeOutline } from "ionicons/icons";

const TourDetails = ({ onNext, tour }) => {
  const [note, setNote] = useState("");
  const [date, setDate] = useState();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [meetup, setMeetup] = useState({
    address: "",
    time: undefined,
    description: "",
  });

  useEffect(() => {
    if (tour) {
      setMeetup({
        address: tour.meetupLocation ? tour.meetupLocation : "",
        time: tour.meetupTime ? tour.meetupTime : undefined,
        description: tour.meetupDescription ? tour.meetupDescription : "",
      });
      setNote(tour.generalNote ? tour.generalNote : "");
      setStartTime(tour.startTime ? tour.startTime : undefined);
      setEndTime(tour.endTime ? tour.endTime : undefined);
      setDate(tour.date ? tour.date : undefined);
      const div = document.getElementById("new_tour");
      div?.scrollIntoView();
    }
  }, [tour]);

  const handleDelete = (type) => {
    switch (type) {
      case "date":
        setDate("");
        break;
      case "startTime":
        setStartTime("");
        break;
      case "endTime":
        setEndTime("");
        break;
      case "address":
        setMeetup({ ...meetup, address: "" });
        break;
      case "time":
        setMeetup({ ...meetup, time: undefined });
        break;
      case "description":
        setMeetup({ ...meetup, description: "" });
        break;
      default:
        return;
    }
  };

  const submit = () => {
    onNext({
      date,
      startTime,
      endTime,
      meetupLocation: meetup.address,
      meetupTime: meetup.time,
      meetupDescription: meetup.description,
      generalNote: note,
    });
  };

  return (
    <div className={styles.tourDetails}>
      <div className={styles.subtitle}>
        Enter your tour information – you can always edit it later. Note that
        some content will only be visible by you, the agent. Once you create a
        tour, you can add and edit showings.
      </div>

      <div className={styles.label}>
        <div className={styles.title}>Tour details</div>
        <IsVisibleTag isVisible={true} />
      </div>

      <IonGrid className={styles.inputs}>
        <IonRow className={styles.row}>
          <IonCol>
          <IonItem className={styles.item} lines="none">
              <IonLabel>Date</IonLabel>
              <IonDatetimeButton datetime="tour-date">
                {!date && <div slot="date-target">Select</div>}
              </IonDatetimeButton>
              <IonModal keepContentsMounted>
                <IonDatetime
                  id="tour-date"
                  showDefaultButtons
                  placeholder="Tour date"
                  displayFormat="MMM DD YYYY"
                  presentation="date"
                  min={new Date().toISOString()}
                  value={date}
                  onIonChange={(e) =>
                    setDate(moment(e.detail.value).format("YYYY-MM-DD"))
                  }
                  className={styles.picker}></IonDatetime>
              </IonModal>
            </IonItem>
          </IonCol>
          <IonCol size="1">
            <IonIcon icon={closeOutline} onClick={() => handleDelete("date")} />
          </IonCol>
        </IonRow>
        <IonRow className={styles.row}>
          <IonCol>
            <IonItem className={styles.item} lines="none">
              <IonLabel>Start Time</IonLabel>
              <IonDatetimeButton datetime="start-time">
                {!startTime && <div slot="time-target">Select</div>}
              </IonDatetimeButton>
              <IonModal keepContentsMounted>
                <IonDatetime
                  id="start-time"
                  showDefaultButtons
                  placeholder="Tour start time"
                  displayFormat="h:mm A"
                  minuteValues="0,15,30,45"
                  presentation="time"
                  value={startTime}
                  onIonChange={(e) =>
                    setStartTime(moment(e.detail.value).format("HH:mm"))
                  }
                  className={styles.picker}></IonDatetime>
              </IonModal>
            </IonItem>
          </IonCol>
          <IonCol size="1">
            <IonIcon
              icon={closeOutline}
              onClick={() => handleDelete("startTime")}
            />
          </IonCol>
        </IonRow>
        <IonRow className={styles.row}>
          <IonCol>
            <IonItem className={styles.item} lines="none">
              <IonLabel>End Time</IonLabel>
              <IonDatetimeButton datetime="end-time">
                {!endTime && <div slot="time-target">Select</div>}
              </IonDatetimeButton>
              <IonModal keepContentsMounted>
                <IonDatetime
                  id="end-time"
                  showDefaultButtons
                  presentation="time"
                  placeholder="Tour end time"
                  displayFormat="h:mm A"
                  minuteValues="0,15,30,45"
                  value={endTime}
                  onIonChange={(e) =>
                    setEndTime(moment(e.detail.value).format("HH:mm"))
                  }
                  className={styles.picker}></IonDatetime>
              </IonModal>
            </IonItem>
          </IonCol>
          <IonCol size="1">
            <IonIcon
              icon={closeOutline}
              onClick={() => handleDelete("endTime")}
            />
          </IonCol>
        </IonRow>
        <IonRow className={styles.row}>
          <IonCol>
            <Input
              name="address"
              label="Meetup address"
              showLabel={false}
              placeholder="Meetup address"
              style={{ width: "100%", boxShadow: "none" }}
              value={meetup.address}
              onChange={(value, name) =>
                setMeetup({ ...meetup, address: value })
              }
            />
          </IonCol>
          <IonCol size="1">
            <IonIcon
              icon={closeOutline}
              onClick={() => handleDelete("address")}
            />
          </IonCol>
        </IonRow>
        <IonRow className={styles.row}>
          <IonCol>
            <IonItem className={styles.item} lines="none">
              <IonLabel>Meetup Time</IonLabel>
              <IonDatetimeButton datetime="meetup-time">
                {!meetup.time && <div slot="time-target">Select</div>}
              </IonDatetimeButton>
              <IonModal keepContentsMounted>
                <IonDatetime
                  id="meetup-time"
                  showDefaultButtons
                  presentation="time"
                  placeholder="Meetup time"
                  displayFormat="h:mm A"
                  minuteValues="0,15,30,45"
                  value={meetup.time}
                  onIonChange={(e) =>
                    setMeetup({
                      ...meetup,
                      time: moment(e.detail.value).format("HH:mm"),
                    })
                  }
                  className={styles.picker}></IonDatetime>
              </IonModal>
            </IonItem>
          </IonCol>
          <IonCol size="1">
            <IonIcon icon={closeOutline} onClick={() => handleDelete("time")} />
          </IonCol>
        </IonRow>
        <IonRow className={styles.row}>
          <IonCol>
            <Input
              showLabel={false}
              name="description"
              label="Meetup description"
              placeholder="Meetup description"
              value={meetup.description}
              style={{ width: "100%" }}
              onChange={(value, name) =>
                setMeetup({ ...meetup, description: value })
              }
            />
          </IonCol>
          <IonCol size="1">
            <IonIcon
              icon={closeOutline}
              onClick={() => handleDelete("description")}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <textarea
              className={styles.textarea}
              name="note"
              placeholder="General notes for client"
              value={note}
              onChange={(event) => {
                setNote(event.target.value);
              }}></textarea>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonButton
        shape="round"
        expand="block"
        className={styles.button}
        onClick={submit}>
        Save and continue
      </IonButton>
    </div>
  );
};

export default TourDetails;
