import { useState, useEffect, memo } from "react";
import {
  IonIcon,
  IonSkeletonText,
  IonLabel,
  IonButton,
  IonItem,
  IonPopover,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  arrowForwardCircleOutline,
  settingsOutline,
  chevronDownOutline,
  navigateOutline,
} from "ionicons/icons";
import styles from "./tour-property.module.scss";
import {
  buildAddress,
  getTagTitle,
  handleAppNavigation,
  numberWithCommas,
} from "../../../../utils/functions";
import { ListingService } from "../../../../services/listingService";
import { TourItemService } from "../../../../services/tourItemService";

const TourProperty = ({
  item,
  order,
  onUpdate,
  app = false,
  setListings,
  addAddress,
}) => {
  const history = useHistory();
  const [listing, setListing] = useState();
  const [loading, setLoading] = useState(false);
  const [popover, setPopover] = useState({ show: false, event: undefined });
  const fadedOut = ["skipped", "completed", "cancelled", "rejected"].includes(
    item.status
  );

  useEffect(() => {
    let isCancelled = false;
    const fetchListing = async () => {
      !isCancelled && setLoading(true);
      try {
        const property = await ListingService.getListingByMlsNumber(item.mlsNumber);

        setListings((ls) => [...ls, property]);
        !isCancelled && setListing(property);
        !isCancelled &&
          addAddress({
            address: property.address,
            mlsNumber: property.mlsNumber,
          });
      } catch (err) {
        console.log(err);
      } finally {
        !isCancelled && setLoading(false);
      }
    };
    fetchListing();

    return () => {
      isCancelled = true;
    };
  }, []);

  const handleStatus = async (value) => {
    setLoading(true);
    if (value !== item.status) {
      try {
        const tourItem = await TourItemService.updateTourItem({id: item.id, status: value});
        onUpdate(tourItem);
      } catch (err) {
        console.log(err);
      }
    }
    setPopover({ show: false, event: undefined });
    setLoading(false);
  };

  const handleShowListing = (e) => {
    e.stopPropagation();
    history.push(`/listings/${listing.mlsNumber}`);
  };

  return app ? (
    <div
      className={styles.appItem}
      key={item.index}
      onClick={() => history.push(`/tabs/tours/${item.tourId}/${item.id}`)}>
      <div className={styles.order}>{order}</div>
      <div className={styles.direction}>
        <IonIcon
          icon={navigateOutline}
          onClick={(e) => {
            e.stopPropagation();
            handleAppNavigation({
              map: listing.map,
              address: listing.address,
              byAddress: true,
            });
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className={`${styles.info} ${fadedOut && styles.fadedOut}`}>
        <div className={styles.address}>
          {listing && !loading && buildAddress(listing.address)}
          {loading && <IonSkeletonText animated />}
        </div>
        {(item.status || item.startTime || item.endTime) && (
          <div className={styles.container}>
            <span className={styles.status}>{item.status}</span>
            <span className={styles.time}>
              {`${item.startTime ? item.startTime : ""}${
                item.startTime || item.endTime ? " - " : ""
              }${item.endTime ? item.endTime : ""}`}
            </span>
          </div>
        )}

        <div className={styles.price}>
          List price:{" "}
          {listing && !loading && listing.listPrice
            ? numberWithCommas(listing.listPrice, true)
            : "-"}
        </div>
        <div className={styles.price}>
          <span>
            {listing && listing.type === "Sale"
              ? "Sold price: "
              : "Leased price: "}
            {listing &&
            !loading &&
            listing.soldPrice &&
            +listing.soldPrice !== 0
              ? numberWithCommas(listing.soldPrice, true)
              : "-"}
          </span>
          <span className={styles.tag}>
            {listing &&
              !loading &&
              getTagTitle(listing.lastStatus, listing.type)}
          </span>
        </div>
        {listing && !loading && (
          <div className={styles.listing} onClick={handleShowListing}>
            {`MLS: ${listing.mlsNumber}`}{" "}
            <IonIcon icon={arrowForwardCircleOutline} />
          </div>
        )}
      </div>
    </div>
  ) : (
    <div
      className={`${styles.item} ${fadedOut && styles.fadedOut}`}
      key={item.index}>
      <div className={styles.col} style={{ justifyContent: "start" }}>
        {order}
      </div>
      <div className={styles.col}>{`${item.startTime ? item.startTime : ""} - ${
        item.endTime ? item.endTime : ""
      }`}</div>
      <div className={`${styles.col} ${styles.mls}`}>
        <span
          onClick={() =>
            window.open(`/listings/${listing.mlsNumber}`, "_blank")
          }>
          {item.mlsNumber}{" "}
        </span>
        <IonIcon
          icon={arrowForwardCircleOutline}
          style={{ cursor: "pointer" }}
          onClick={() =>
            window.open(`/listings/${listing.mlsNumber}`, "_blank")
          }
        />
      </div>
      <div className={`${styles.col} ${styles.direction}`}>
        <IonIcon
          icon={navigateOutline}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleAppNavigation({
              map: listing.map,
              address: listing.address,
              byAddress: true,
            });
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div
        className={styles.col}
        onClick={() => history.push(`/tours/details/${item.id}`)}
        style={{ cursor: "pointer" }}>
        {listing && !loading && buildAddress(listing.address)}
        {loading && <IonSkeletonText animated />}
      </div>
      <div className={styles.col}>
        {listing && !loading && (
          <div className={styles.price}>
            <div>List price: {numberWithCommas(listing.listPrice, true)}</div>
            <div>
              {listing && listing.type === "Sale"
                ? "Sold price: "
                : "Leased price: "}
              {listing && listing.soldPrice && +listing.soldPrice !== 0
                ? numberWithCommas(listing.soldPrice, true)
                : "-"}
            </div>
            <div>
              {listing &&
                !loading &&
                getTagTitle(listing.lastStatus, listing.type)}
            </div>
          </div>
        )}
        {loading && <IonSkeletonText animated />}
      </div>

      <IonPopover
        cssClass={styles.popover}
        event={popover.event}
        isOpen={popover.show}
        onDidDismiss={() => setPopover({ show: false, event: undefined })}>
        <div className={styles.popoverContainer}>
          <IonItem
            className={styles.statusItem}
            onClick={() => handleStatus("requested")}>
            <IonButton
              fill="clear"
              className={`${styles.status} ${styles.requested}`}>
              <IonLabel>Requested</IonLabel>
            </IonButton>
          </IonItem>
          <IonItem
            className={styles.statusItem}
            onClick={() => handleStatus("confirmed")}>
            <IonButton
              fill="clear"
              className={`${styles.status} ${styles.confirmed}`}>
              <IonLabel>Confirmed</IonLabel>
            </IonButton>
          </IonItem>
          <IonItem
            className={styles.statusItem}
            onClick={() => handleStatus("skipped")}>
            <IonButton
              fill="clear"
              className={`${styles.status} ${styles.skipped}`}>
              <IonLabel>Skipped</IonLabel>
            </IonButton>
          </IonItem>
          <IonItem
            className={styles.statusItem}
            onClick={() => handleStatus("cancelled")}>
            <IonButton
              fill="clear"
              className={`${styles.status} ${styles.cancelled}`}>
              <IonLabel>Cancelled</IonLabel>
            </IonButton>
          </IonItem>
          <IonItem
            className={styles.statusItem}
            onClick={() => handleStatus("rejected")}>
            <IonButton
              fill="clear"
              className={`${styles.status} ${styles.rejected}`}>
              <IonLabel>Rejected</IonLabel>
            </IonButton>
          </IonItem>
        </div>
      </IonPopover>
      <IonButton
        className={`${styles.status} ${styles[item.status]} ${
          !item.status && styles.greyBtn
        }`}
        fill="clear"
        onClick={(e) => {
          e.persist();
          setPopover({ show: true, event: e });
        }}>
        <IonLabel>{item.status ? item.status : "Status"}</IonLabel>
        <IonIcon icon={chevronDownOutline} />
      </IonButton>

      <div className={styles.col}>
        <IonIcon
          className={styles.settingIcon}
          icon={settingsOutline}
          onClick={() => history.push(`/tours/details/${item.id}`)}
        />
      </div>
    </div>
  );
};

export default memo(TourProperty);
