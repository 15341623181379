import * as APITypes from '../../API';
import { UpdateTourMutation } from '../types/tours.types';
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};


export const createUserTour = /* GraphQL */ `mutation CreateUserTour(
    $input: CreateUserTourInput!
    $condition: ModelUserTourConditionInput
  ) {
    createUserTour(input: $input, condition: $condition) {
      id
      userId
      tourId
      user {
        id
        repliersID
        givenName
        familyName
        name
        displayName
        email
        phone
        profilePicture
        role
        agent {
          id
          userId
          website
          licenseNumber
          areas
          availability
          createdAt
          updatedAt
          brokerageAgentsId
          __typename
        }
        client {
          id
          userId
          createdAt
          updatedAt
          agentClientsId
          clientAgentId
          __typename
        }
        tours {
          nextToken
          __typename
        }
        subscribed
        currency
        measurement
        notifications {
          id
          userId
          schedule
          type
          createdAt
          updatedAt
          __typename
        }
        deviceToken
        profile {
          id
          userId
          buyingSelling
          leasingRenting
          justLooking
          preConstruction
          isRealtor
          createdAt
          updatedAt
          __typename
        }
        requestedTour
        toursNote
        deactivated
        createdAt
        updatedAt
        userAgentId
        userClientId
        userNotificationsId
        userProfileId
        __typename
      }
      tour {
        id
        title
        creator
        primaryAgentId
        showingAgetsIds
        clientId
        guestsIds
        users {
          nextToken
          __typename
        }
        status
        privateNote
        generalNote
        tourItems {
          nextToken
          __typename
        }
        meetupLocation
        meetupDescription
        meetupTime
        startTime
        endTime
        date
        shared
        createdAt
        updatedAt
        __typename
      }
      role
      seen
      createdAt
      updatedAt
      __typename
    }
  }
  ` as GeneratedMutation<
    APITypes.CreateUserTourMutationVariables,
    APITypes.CreateUserTourMutation
  >;


  export const updateTour = /* GraphQL */ `mutation UpdateTour(
    $input: UpdateTourInput!
    $condition: ModelTourConditionInput
  ) {
    updateTour(input: $input, condition: $condition) {
    title
    creator
    users {
      items {
        role
        seen
        id
        userId
        user {
          id
          givenName
          familyName
          name
          displayName
          email
          phone
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    clients
    primaryAgent
    showingAgents
    status
    privateNote
    generalNote
    tourItems {
      items {
        id
        order 
        startTime
        endTime
        mlsNumber
        status
        __typename
      }
      nextToken
      __typename
    }
    meetupLocation
    meetupDescription
    meetupTime
    startTime
    endTime
    date
    shared
    id
    createdAt
    updatedAt
    __typename
  }
  }
  ` as GeneratedMutation<
    APITypes.UpdateTourMutationVariables,
    UpdateTourMutation
  >;