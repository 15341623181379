import { takeLatest, put, all, call } from "redux-saga/effects";
import { store } from "../store";
import {
  addSearch,
  removeSearch,
  updateSavedSearch,
} from "../../amplify/graphql.utils";
import {
  fetchClientFailure,
  fetchClientSuccess,
  addSearchFailure,
  addSearchSuccess,
  removeSearchSuccess,
  removeSearchFailure,
  updateSearchFailure,
  updateSearchSuccess,
  clearClient,
} from "./client.actions";
import ClientActionTypes from "./client.types";
import { checkFavouritesUpdates } from "../../api/repliers";
import { resetNotification, updateNotification } from "../ui/ui.actions";
import { resetFilters } from "../filters/filters.actions";
import { signOut } from "../../amplify/auth.utils";
import { signOutFailure, signOutSuccess } from "../user/user.actions";
import { UserService } from "../../services/userService";
import { TourService } from "../../services/tourService";

export function* checkUpdates() {
  const favouritesUpdates = yield checkFavouritesUpdates();
  const toursUpdates = yield TourService.getToursUpdates();

  yield put(
    updateNotification({
      type: "favourites",
      value: { favourites: favouritesUpdates, tours: toursUpdates },
    })
  );
}

export function* fetchClientAsync() {
  try {
    const client = yield UserService.getCurrentUser();
    if (!client) {
      yield put(fetchClientFailure("Something went wrong!"));
    } else {
      yield put(fetchClientSuccess(client));
    }
  } catch (error) {
    yield put(fetchClientFailure(error));
  }
}


export function* addSearchAsync({ payload }) {
  try {
    const client = store.getState().client.currentClient;
    yield addSearch(client.id, client.repliersID, payload);
    yield put(addSearchSuccess());
  } catch (error) {
    yield put(addSearchFailure(error));
  }
}

export function* removeSearchAsync({ payload }) {
  try {
    yield removeSearch(payload);
    yield put(removeSearchSuccess());
  } catch (error) {
    yield put(removeSearchFailure(error));
  }
}

export function* updateSearchAsync({ payload }) {
  try {
    yield updateSavedSearch(payload);
    yield put(updateSearchSuccess());
  } catch (error) {
    yield put(updateSearchFailure(error));
  }
}

export function* signOutAfterFailure() {
  try {
    yield put(resetFilters());
    yield put(clearClient());
    yield put(resetNotification());
    yield signOut();
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

export function* onFetchClientStart() {
  yield takeLatest(ClientActionTypes.FETCH_CLIENT_START, fetchClientAsync);
}

export function* onAddSearchStart() {
  yield takeLatest(ClientActionTypes.ADD_SEARCH_START, addSearchAsync);
}

export function* onRemoveSearchStart() {
  yield takeLatest(ClientActionTypes.REMOVE_SEARCH_START, removeSearchAsync);
}

export function* onUpdateSearchStart() {
  yield takeLatest(ClientActionTypes.UPDATE_SEARCH_START, updateSearchAsync);
}

export function* onAddSearchSuccess() {
  yield takeLatest(ClientActionTypes.ADD_SEARCH_SUCCESS, fetchClientAsync);
}

export function* onRemoveSearchSuccess() {
  yield takeLatest(ClientActionTypes.REMOVE_SEARCH_SUCCESS, fetchClientAsync);
}

export function* onUpdateSearchSuccess() {
  yield takeLatest(ClientActionTypes.UPDATE_SEARCH_SUCCESS, fetchClientAsync);
}

export function* onFetchClientSuccess() {
  yield takeLatest(ClientActionTypes.FETCH_CLIENT_SUCCESS, checkUpdates);
}

export function* onFetchClientFailure() {
  yield takeLatest(ClientActionTypes.FETCH_CLIENT_FAILURE, signOutAfterFailure);
}

export function* clientSagas() {
  yield all([
    call(onFetchClientStart),
    call(onFetchClientSuccess),
    call(onAddSearchStart),
    call(onAddSearchSuccess),
    call(onRemoveSearchStart),
    call(onRemoveSearchSuccess),
    call(onUpdateSearchStart),
    call(onUpdateSearchSuccess),
    call(onFetchClientFailure),
  ]);
}
