import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import Button from "../../components/Form/Button/button.component";
import styles from "./admin.module.scss";
import { useHistory } from "react-router-dom";

const Admin = (props) => {
  const history = useHistory();
  return (
    <div className={styles.container}>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Projects</IonCardTitle>
        </IonCardHeader>
        <IonCardContent className={[styles.bg, styles.content].join(" ")}>
          <Button
            type={"green"}
            title={"View all"}
            onClick={() => history.push("/admin/projects")}
          />
          <Button
            type={"green"}
            title={"Add"}
            onClick={() => history.push("/admin/projects/new")}
          />
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Units</IonCardTitle>
        </IonCardHeader>
        <IonCardContent className={[styles.bg, styles.content].join(" ")}>
          <Button
            type={"green"}
            title={"View all"}
            onClick={() => history.push("/admin/units")}
          />
          <Button
            type={"green"}
            title={"Add"}
            onClick={() => history.push("/admin/units/new")}
          />
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Builders</IonCardTitle>
        </IonCardHeader>
        <IonCardContent className={[styles.bg, styles.content].join(" ")}>
          <Button
            type={"green"}
            title={"View all"}
            onClick={() => history.push("/admin/builders")}
          />
          <Button
            type={"green"}
            title={"Add"}
            onClick={() => history.push("/admin/builders/new")}
          />
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Architects</IonCardTitle>
        </IonCardHeader>
        <IonCardContent className={[styles.bg, styles.content].join(" ")}>
          <Button
            type={"green"}
            title={"View all"}
            onClick={() => history.push("/admin/architects")}
          />
          <Button
            type={"green"}
            title={"Add"}
            onClick={() => history.push("/admin/architects/new")}
          />
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Interior Designers</IonCardTitle>
        </IonCardHeader>
        <IonCardContent className={[styles.bg, styles.content].join(" ")}>
          <Button
            type={"green"}
            title={"View all"}
            onClick={() => history.push("/admin/designers")}
          />
          <Button
            type={"green"}
            title={"Add"}
            onClick={() => history.push("/admin/designers/new")}
          />
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Amenities</IonCardTitle>
        </IonCardHeader>
        <IonCardContent className={[styles.bg, styles.content].join(" ")}>
          <Button
            type={"green"}
            title={"Add/Remove"}
            onClick={() => history.push("/admin/amenities")}
          />
        </IonCardContent>
      </IonCard>
    </div>
  );
};
export default Admin;
