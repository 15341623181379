import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { numberWithCommas, renderCurrencySign } from "../../utils/functions";
import styles from "./pre-listing-card.module.scss";
import { IonIcon } from "@ionic/react";
import { ReactComponent as LocationIcon } from "../../assets/svg/location-pin.svg";
import { bed } from "ionicons/icons";
import millify from "millify";
import ListingImage from "../ListingImage/listing-image.component";
import withClient from "../../HOC/withClient/with-client";
import FavoriteIcon from "./components/FavoriteIcon/favorite-icon.component";
import { updateActiveListing } from "../../redux/map/map.actions";
import { selectCurrencyRates } from "../../redux/currency/currency.selectors";
import ListingTag from "./components/ListingTag/listing-tag.component";

export const PHASES = {
  preconstruction: "Pre-construction",
  underconstruction: "Under construction",
  completed: "Completed",
};

const PreListingCard = ({
  listing,
  map,
  currentClient,
  updates,
  ...rest
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const rates = useSelector(selectCurrencyRates);

  let currency;
  if (currentClient && rates) {
    if (currentClient.currency !== "cad")
      currency = currentClient.currency.toUpperCase();
  }

  const {
    id,
    name,
    builder,
    phase,
    province,
    city,
    streetName,
    streetNumber,
    startPrice,
    endPrice,
    logo,
    minBeds,
    maxBeds,
    minSqft,
    maxSqft,
  } = listing;

  const buildAddress = () => {
    let fullAddress = "";
    if (streetNumber) fullAddress += `${streetNumber} - `;
    if (streetName) fullAddress += `${streetName} `;

    return fullAddress.trim();
  };

  const renderPrice = () => {
    if (startPrice)
      return currency
        ? `${renderCurrencySign(currency, false)}${millify(
            (+startPrice / rates.CAD) * rates[currency]
          )}`
        : `${renderCurrencySign(currency, false)}${numberWithCommas(
            startPrice
          )}`;
  };

  const renderPhase = () => {
    if (!phase) return;
    return PHASES[phase];
  };

  const clickHandler = (e) => {
    e.stopPropagation();

    history.push(`/projects/${id}`, {
      from:
        location.state && location.state.from
          ? [...location.state.from, location.pathname]
          : [location.pathname],
    });
  };

  const handleMouseEnter = () => {
    dispatch(updateActiveListing(id));
  };

  const handleMouseLeave = () => {
    dispatch(updateActiveListing(null));
  };

  return (
    <div
      className={`${map ? styles.map : styles.listingCard}`}
      {...rest}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {updates && <ListingTag updates={updates} />}
      <ListingImage
        img={logo ? logo : "default"}
        onClick={clickHandler}
        isPre
      />
      <div className={styles.details}>
        <span className={styles.titleContainer}>
          <div className={styles.title} onClick={clickHandler}>
            <div className={styles.projectTitle}>{name}</div>
          </div>

          <div className={styles.icons}>
            <FavoriteIcon
              map={map}
              listing={listing}
              isActiveComparables={false}
              isPreconstruction={true}
            />
          </div>
        </span>
        <span className={styles.priceContainer}>
          <span className={styles.price}>
            {startPrice === 0 ? "Coming soon" : `Starting at ${renderPrice()}`}
          </span>
        </span>
        <span className={styles.addressContainer} onClick={clickHandler}>
          {!map && (
            <span className={styles.icon}>
              <LocationIcon />
            </span>
          )}

          <span className={styles.address}>{buildAddress()}</span>
        </span>
        <span className={styles.builderContainer} onClick={clickHandler}>
          <span className={styles.builder}>Project by {builder?.name}</span>
        </span>
        <span className={styles.phaseContainer} onClick={clickHandler}>
          <span className={styles.phase}>{renderPhase()}</span>
        </span>

        <span className={styles.rooms} onClick={clickHandler}>
          {!map && <IonIcon icon={bed} className={styles.bedIcon} />}
          <span className={styles.beds}>
            {minBeds} {maxBeds !== 0 && `- ${maxBeds}`} bed
          </span>
          <span className={styles.sqft}>
            {minSqft !== 0 && `${numberWithCommas(minSqft)} - `}{" "}
            {maxSqft !== 0 ? numberWithCommas(maxSqft) : "-"} sqft
          </span>
        </span>
      </div>
    </div>
  );
};

export default memo(withClient(PreListingCard));
