import { useState } from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { arrowForwardCircleOutline, navigateOutline } from "ionicons/icons";
import styles from "./tour-details.module.scss";
import { getCurrentLocation } from "../../../../../../components/Map/map-utils";
import {
  buildAddress,
  handleAppAddressNavigation,
} from "../../../../../../utils/functions";

const TourDetails = ({ tourDetails, tourItems }) => {
  const [mapLoading, setMapLoading] = useState(false);

  const handleMapView = async () => {
    setMapLoading(true);
    if (tourItems?.length) {
      const currentLocation = await getCurrentLocation();
      let locations = "";
      if (currentLocation)
        locations += `${currentLocation.latitude},${currentLocation.longitude}/`;

      if (tourDetails.meetupLocation)
        locations += `${tourDetails.meetupLocation}/`;
      tourItems.forEach((item) => {
        const l = tourItems.find((i) => i.mlsNumber === item.mlsNumber);
        if (
          !["skipped", "cancelled", "completed", "rejected"].includes(l.status)
        )
          locations += `${buildAddress(item.listing.address, true)}/`;
      });

      window.open(
        `https://www.google.com/maps/dir/${locations.slice(
          0,
          locations.length
        )}&dirflg=d,t`,
        "_blank"
      );
    }
    setMapLoading(false);
  };
  return (
    <div className={styles.tourDetails}>
      <div className={styles.time}>
        <span>
          <span className={styles.bold}>Tour Date</span>
          <span>{tourDetails.date}</span>
        </span>
        <span>
          <span className={styles.bold}>Tour time</span>
          <span>{`${tourDetails.startTime ? tourDetails.startTime : "-"} - ${
            tourDetails.endTime ? tourDetails.endTime : "-"
          }`}</span>
        </span>
      </div>
      <div>
        <span className={styles.bold}>Meetup address</span>
        <span
          className={styles.address}
          onClick={() => handleAppAddressNavigation(tourDetails.meetupLocation)}
        >
          {tourDetails.meetupLocation}
          {tourDetails.meetupLocation && (
            <IonIcon icon={arrowForwardCircleOutline} />
          )}
        </span>
      </div>
      <div>
        <span className={styles.bold}>Meetup time</span>
        <span>{tourDetails.meetupTime}</span>
      </div>
      <div>
        <span className={styles.bold}>Meetup description </span>
        <span>{tourDetails.meetupDescription}</span>
      </div>
      <div>
        <span className={styles.bold}>Tour notes from agent </span>
        <span>{tourDetails.generalNote}</span>
      </div>
      <IonButton
        className={`${styles.button} ${styles.directionsBtn}`}
        disabled={!tourItems?.length}
        onClick={handleMapView}
      >
        <IonIcon icon={navigateOutline} />
        Full directions
      </IonButton>
    </div>
  );
};

export default TourDetails;
