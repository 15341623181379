import { useState, useEffect, useCallback, useRef } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import numeral from "numeral";
import LeftSection from "./Sections/LeftSection/left-section.component";
import RightSection from "./Sections/RightSection/right-section.component";
import styles from "./detail.module.scss";
import { getSimilarListing } from "../../api/repliers";
import LoadingFullPage from "../../components/Loading/loading-full-page.component";
import Footer from "../../components/Footer/footer.component";
import ComparableSection from "./Sections/ComparableSection/comparables-section.component";
import ErrorPage from "../../components/Errors/error.component";
import TabletRightSection from "./Sections/TabletRightSection/tablet-right-section.component";
import { getBoundingCoords } from "../../utils/functions";
import { getMLSEstimate } from "../../api/ettie";
import {
  selectClientLoading,
  selectCurrentClient,
} from "../../redux/client/client.selectors";
import {
  updateActiveListing,
  updateDetailsListing,
} from "../../redux/map/map.actions";
import { ListingService } from "../../services/listingService";
import { FavoritesService } from "../../services/favoritesService";
import { SchoolsService } from "services/schoolsService";

const DetailPage = ({ language = "en", ...rest }) => {
  const {
    match: {
      params: { mlsNumber },
    },
  } = rest;

  const dispatch = useDispatch();
  const scrollRef = useRef();
  const location = useLocation();
  const user = useSelector(selectCurrentUser);
  const currentClient = useSelector(selectCurrentClient);
  const clientLoading = useSelector(selectClientLoading);
  const [property, setProperty] = useState();
  const [estimate, setEstimate] = useState();
  const [schools, setSchools] = useState([]);
  const [schoolsLoading, setSchoolsLoading] = useState(false);
  const [similarListing, setSimilarListing] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    dispatch(updateActiveListing(null));
    dispatch(updateDetailsListing(null));
  }, [dispatch]);

  const getFavourite = async () => {
    if (!currentClient) return false;
    const favourite = await FavoritesService.getFavoriteByMlsNumber(mlsNumber);
    return favourite;
  };

  const getPropertyDetails = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const listing = await ListingService.getListingByMlsNumber(mlsNumber);
      if (listing.status === "A") {
        const estimate = await getMLSEstimate(mlsNumber);
        setEstimate(estimate);
     }
     
      try {
        const similarActiveListing = await getSimilarListing(mlsNumber);
        similarActiveListing
          ? setSimilarListing(similarActiveListing)
          : setSimilarListing([]);
      } catch (err) {
        console.log(err);
      }
      setProperty(listing);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.status);
    }
  }, [mlsNumber]);

  useEffect(() => {
    const updateFavourite = async () => await FavoritesService.updateFavoriteListing(property);
    if (user && property) updateFavourite();
    if (error === 401 && user) setError(null);

    if (!error && !user && property && property.status === "U" && !loading) {
      setError(401);
    }
  }, [user, error, property, loading]);

  useEffect(() => {
    if (mlsNumber) getPropertyDetails();
  }, [mlsNumber, getPropertyDetails]);

  useEffect(() => {
    if (scrollRef.current) scrollRef.current.scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef.current]);

  const buildDescription = () => {
    const createText = () =>
      property.details.numBedrooms +
      " bed " +
      property.details.propertyType +
      " at " +
      property.address.unitNumber +
      " - " +
      property.address.streetNumber +
      " " +
      property.address.streetName +
      " " +
      property.address.streetSuffix +
      ", " +
      property.address.area +
      " for " +
      numeral(property.listPrice).format("$1,000,000");

    return property.status === "A"
      ? createText()
      : currentClient
      ? createText()
      : "";
  };

  useEffect(() => {
    const getSchools = async () => {
      setSchoolsLoading(true);
      const { latitude, longitude } = property.map;

      const { topLeft, topRight, bottomRight, bottomLeft } = getBoundingCoords(
        parseFloat(latitude),
        parseFloat(longitude),
        8000
      );

      const schools = await SchoolsService.findNearbySchools({
        boundary: [
          [topLeft.lng, topLeft.lat],
          [topRight.lng, topRight.lat],
          [bottomRight.lng, bottomRight.lat],
          [bottomLeft.lng, bottomLeft.lat],
        ],
      });

      setSchools(schools);
      setSchoolsLoading(false);
    };

    if (property) getSchools();
  }, [property]);

  return (
    <>
      {!loading && property && mlsNumber.toLowerCase() === property.mlsNumber.toLowerCase() && !error && (
        <div className={styles.detailPage}>
          <Helmet>
            <title>AECORN - Property for {property.type.toLowerCase()}</title>
            <meta
              name="description"
              content={buildDescription()}
              data-react-helmet="true"
            />
          </Helmet>
          <div>
            <LeftSection
              currentClient={currentClient}
              clientLoading={clientLoading}
              user={user}
              listing={property}
              schools={schools}
              schoolsLoading={schoolsLoading}
              language={language}
            />
          </div>
          <div className={styles.righSection}>
            <RightSection
              user={user}
              estimate={estimate}
              property={property}
              language={language}
              isFavourite={getFavourite()}
            />
          </div>
          <div className={styles.comparables}>
            <ComparableSection
              user={user}
              soldComparables={property.comparables}
              activeComparables={similarListing}
            />
          </div>
          <div className={styles.footer}>
            <Footer />
          </div>
          <div className={styles.tabletRightSection}>
            <TabletRightSection
              listing={property}
              estimate={estimate}
              language={language}
              isFavourite={getFavourite()}
            />
          </div>
        </div>
      )}
      {loading && (
        <div className={styles.loader}>
          <LoadingFullPage />
        </div>
      )}
      {error && (
        <ErrorPage
          status={error}
          hideHeader={true}
          from={location.state ? location.state.from : null}
          onClick={getPropertyDetails}
        />
      )}
    </>
  );
};

export default DetailPage;
