import {
  CheckboxCustomEvent,
  IonButton,
  IonCheckbox,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRange,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import styles from "./search-filters.module.scss";
import { ListingsClass } from "enums/Listings/ListingClass.enum";
import {
  chevronDownOutline,
  chevronUpOutline,
  gridOutline,
  mapOutline,
  optionsOutline,
  searchOutline,
} from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { selectFiltersValue } from "redux/filters/filters.selectors";
import { updateFilters } from "redux/filters/filters.actions";
import { ListingsHelper } from "utils/ListingsHelper";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { AuthenticationService } from "services/authenticationService";
import { ListingService } from "services/listingService";
import { Listing } from "models/listings/listing.model";
import { Locations, Location } from "models/locations/locations.model";
import LocationSearchResult from "components/shared/LocationSearchResult/location-search-result.component";
import ListingListItem from "components/shared/ListingListItem/listing-list-item.component";
import { selectMapRef } from "redux/map/map.selectors";

const SearchFilters = ({
  view,
  updateView,
}: {
  view: "map" | "list";
  updateView: (view: "map" | "list") => void;
}) => {
  const isAuthenticated = AuthenticationService.isAuthenticated();

  const dispatch = useDispatch();
  const filters = useSelector(selectFiltersValue);
  const mapRef = useSelector(selectMapRef);
  const searchResultsPopoverRef = useRef<HTMLIonPopoverElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<{
    listings: Listing[];
    locations: Locations;
  }>({
    listings: [],
    locations: {
      cities: [],
      neighborhoods: [],
    },
  });
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [lastStatus, setLastStatus] = useState<"sale" | "sold" | "delisted">(
    "sale"
  );
  const [date, setDate] = useState<number>(0);

  useEffect(() => {
    setLastStatus("sale");
    dispatch(
      updateFilters({
        minListDate: null,
        maxListDate: null,
        minSoldDate: null,
        maxSoldDate: null,
        minUpdatedOn: null,
        maxUpdatedOn: null,
        status: "A",
        lastStatus: null,
      })
    );
  }, [filters.type]);

  useEffect(() => {
    if (!isAuthenticated && lastStatus !== "sale") {
      setLastStatus("sale");
      dispatch(
        updateFilters({
          minListDate: null,
          maxListDate: null,
          minSoldDate: null,
          maxSoldDate: null,
          minUpdatedOn: null,
          maxUpdatedOn: null,
          status: "A",
          lastStatus: null,
        })
      );
    }
  }, [isAuthenticated]);

  const handleUpdateListingsClass = (e: CustomEvent) => {
    dispatch(updateFilters({ class: e.detail.value }));
  };

  const handlePriceRangeChange = (e: CustomEvent) => {
    const minPrice = ListingsHelper.convertPercentageToPrice(
      e.detail.value.lower,
      filters.type
    );
    const maxPrice = ListingsHelper.convertPercentageToPrice(
      e.detail.value.upper,
      filters.type
    );

    dispatch(updateFilters({ minPrice, maxPrice }));
  };

  const handleMinPriceChange = (e: CustomEvent) => {
    const newValue = e.detail.value;
    const maxPrice = filters.maxPrice;

    if (newValue > maxPrice) {
      dispatch(updateFilters({ minPrice: maxPrice, maxPrice: newValue }));
    } else {
      dispatch(updateFilters({ minPrice: newValue }));
    }
  };

  const handleMaxPriceChange = (e: CustomEvent) => {
    const newValue = e.detail.value;
    const minPrice = filters.minPrice;

    if (newValue < minPrice) {
      dispatch(updateFilters({ minPrice: newValue, maxPrice: minPrice }));
    } else {
      dispatch(updateFilters({ maxPrice: newValue }));
    }
  };

  const handleBedsRangeChange = (e: CustomEvent) => {
    const minBeds = e.detail.value;

    dispatch(updateFilters({ minBeds: minBeds === 0 ? null : minBeds }));
  };

  const handleLastStatusChange = (e: CustomEvent) => {
    const value = e.detail.value as "sale" | "sold" | "delisted";
    setLastStatus(value);

    const thirtyDaysAgo = moment().subtract(29, "days").format("YYYY-MM-DD");

    switch (value) {
      case "sale":
        dispatch(
          updateFilters({
            status: "A",
            lastStatus: null,
            minSoldDate: null,
            maxSoldDate: null,
            minUpdatedOn: null,
            maxUpdatedOn: null,
          })
        );
        break;
      case "sold":
        dispatch(
          updateFilters({
            status: "U",
            lastStatus: filters.type === "sale" ? ["Sld"] : ["Lsd"],
            minListDate: null,
            maxListDate: null,
            minUpdatedOn: null,
            maxUpdatedOn: null,
            minSoldDate: filters.minSoldDate
              ? filters.minSoldDate
              : thirtyDaysAgo,
            maxSoldDate: filters.maxSoldDate ? filters.maxSoldDate : null,
          })
        );
        if (!filters.minSoldDate) {
          setDate(30);
        }
        break;
      case "delisted":
        dispatch(
          updateFilters({
            status: "U",
            lastStatus: ["Sus", "Exp", "Ter", "Dft"],
            minUpdatedOn: filters.minUpdatedOn
              ? filters.minUpdatedOn
              : thirtyDaysAgo,
            maxUpdatedOn: filters.maxUpdatedOn ? filters.maxUpdatedOn : null,
            minListDate: null,
            maxListDate: null,
            minSoldDate: null,
            maxSoldDate: null,
          })
        );
        if (!filters.minUpdatedOn) {
          setDate(30);
        }
        break;
    }
  };

  const handleUpdateDate = (e: CustomEvent) => {
    const value = e.detail.value as number;
    setDate(value);

    if (value === 0) {
      dispatch(
        updateFilters({
          minListDate: null,
          maxListDate: null,
          minSoldDate: null,
          maxSoldDate: null,
          minUpdatedOn: null,
          maxUpdatedOn: null,
        })
      );
      return;
    }

    switch (lastStatus) {
      case "sale": {
        const date = moment()
          .subtract(Math.abs(value), "days")
          .add(1, "days")
          .format("YYYY-MM-DD");
        dispatch(
          updateFilters({
            minListDate: value > 0 ? date : null,
            maxListDate: value < 0 ? date : null,
            minSoldDate: null,
            maxSoldDate: null,
            minUpdatedOn: null,
            maxUpdatedOn: null,
          })
        );
        break;
      }
      case "sold":
      case "delisted": {
        if (value.toString().length === 4) {
          const startOfSelectedYear = moment()
            .set("year", value)
            .startOf("year")
            .format("YYYY-MM-DD");
          const endOfSelectedYear = moment()
            .set("year", value)
            .endOf("year")
            .format("YYYY-MM-DD");

          dispatch(
            updateFilters({
              minSoldDate: lastStatus === "sold" ? startOfSelectedYear : null,
              maxSoldDate: lastStatus === "sold" ? endOfSelectedYear : null,
              minListDate: null,
              maxListDate: null,
              minUpdatedOn:
                lastStatus === "delisted" ? startOfSelectedYear : null,
              maxUpdatedOn:
                lastStatus === "delisted" ? endOfSelectedYear : null,
            })
          );
        } else {
          const date = moment()
            .subtract(Math.abs(value), "days")
            .add(1, "days")
            .format("YYYY-MM-DD");
          dispatch(
            updateFilters({
              minSoldDate: lastStatus === "sold" ? date : null,
              maxSoldDate: null,
              minListDate: null,
              maxListDate: null,
              minUpdatedOn: null,
              maxUpdatedOn: lastStatus === "delisted" ? date : null,
            })
          );
        }
        break;
      }
    }
  };

  const handlePropertyTypeChange = (e: CheckboxCustomEvent) => {
    const value = e.detail.value as string;
    const checked = e.detail.checked;
    if (value === "any") {
      dispatch(updateFilters({ propertyType: [] }));
    } else {
      const currentValue: string[] = filters.propertyType;
      const newValue = checked
        ? [...currentValue, value]
        : currentValue.filter((option) => option !== value);

      const availableTypes = ListingsHelper.getListingPropertyTypesByClass();

      dispatch(
        updateFilters({
          propertyType:
            newValue.length === availableTypes.length ? [] : newValue,
        })
      );
    }
  };

  const handleSearchChange = async (e: CustomEvent) => {
    const term = e.detail.value;
    setSearchTerm(term);

    if (!term) {
      setSearchResults({
        listings: [],
        locations: {
          cities: [],
          neighborhoods: [],
        },
      });
      setShowSearchResults(false);
    } else {
      try {
        const { listings, locations } =
          term.length >= 3
            ? await ListingService.searchListingsAndLocations(term)
            : {
                listings: [],
                locations: {
                  cities: [],
                  neighborhoods: [],
                },
              };

        setSearchResults({
          listings,
          locations,
        });
      } catch (err) {
        setSearchResults({
          listings: [],
          locations: {
            cities: [],
            neighborhoods: [],
          },
        });
      }
    }
  };

  const handleOpenSearchResultsPopover = (e: any) => {
    searchResultsPopoverRef.current!.event = e;
    setShowSearchResults(true);
  };

  const handleLocationClick = (location: Location) => {
    mapRef?.setCenter({
      lat: location.location.lat,
      lng: location.location.lng,
    });
    setShowSearchResults(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <IonItem lines="none" className="aecorn-search-input">
          <IonInput
            value={searchTerm}
            debounce={500}
            onIonInput={handleSearchChange}
            onClick={handleOpenSearchResultsPopover}
            clearInput
            placeholder="Search by location or MLS#"
            className="aecorn-search-input">
            <IonIcon slot="start" icon={searchOutline} />
          </IonInput>
        </IonItem>
        <IonPopover
          ref={searchResultsPopoverRef}
          keyboardClose={false}
          className={styles.searchResultsPopover}
          isOpen={
            searchTerm.length > 0 &&
            showSearchResults &&
            (searchResults.listings.length > 0 ||
              searchResults.locations.cities.length > 0 ||
              searchResults.locations.neighborhoods.length > 0)
          }
          backdropDismiss={true}
          showBackdrop={false}>
          <div className={styles.searchResultsPopoverContent}>
            {(searchResults.locations.cities.length > 0 ||
              searchResults.locations.neighborhoods.length > 0) && (
              <>
                <div className={styles.searchResultsHeader}>Locations</div>
                <div className={styles.searchResultsList}>
                  {searchResults.locations.cities.map((location, index) => (
                    <LocationSearchResult
                      key={index}
                      location={location}
                      type="city"
                      onClick={handleLocationClick}
                    />
                  ))}
                </div>
                <div className={styles.searchResultsList}>
                  {searchResults.locations.neighborhoods.map(
                    (location, index) => (
                      <LocationSearchResult
                        key={index}
                        location={location}
                        type="neighborhood"
                        onClick={handleLocationClick}
                      />
                    )
                  )}
                </div>
              </>
            )}
            {searchResults.listings.length > 0 && (
              <>
                <div className={styles.searchResultsHeader}>Listings</div>
                <div className={styles.searchResultsList}>
                  {searchResults.listings.map((listing, index) => (
                    <ListingListItem key={index} listing={listing} />
                  ))}
                </div>
              </>
            )}
          </div>
        </IonPopover>
      </div>
      <div className={styles.filter}>
        <IonItem lines="none" className="aecorn-select">
          <IonSelect
            aria-label="Select purpose"
            placeholder="Select purpose"
            interface="popover"
            justify="space-between"
            toggleIcon={chevronDownOutline}
            expandedIcon={chevronUpOutline}
            multiple
            interfaceOptions={{
              cssClass: styles.popover,
            }}
            value={filters.class}
            onIonChange={handleUpdateListingsClass}>
            {Object.entries(ListingsClass).map(([key, value]) => (
              <IonSelectOption key={key} value={value}>
                {key}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </div>
      <div className={styles.filter}>
        <IonItem
          id="price-popover"
          button
          lines="none"
          className="aecorn-select">
          <IonLabel>{ListingsHelper.getListingPriceLabel()}</IonLabel>
          <IonIcon slot="end" icon={chevronDownOutline} />
        </IonItem>
        <IonPopover
          trigger="price-popover"
          triggerAction="click"
          showBackdrop={false}
          size="cover">
          <IonContent className="ion-padding">
            <div className={styles.popoverContent}>
              <div className={styles.item}>
                <IonLabel>Price Range</IonLabel>
                <IonRange
                  value={{
                    lower: ListingsHelper.convertPriceToPercentage(
                      filters.minPrice,
                      filters.type,
                      "lower"
                    ),
                    upper: ListingsHelper.convertPriceToPercentage(
                      filters.maxPrice,
                      filters.type,
                      "upper"
                    ),
                  }}
                  onIonChange={handlePriceRangeChange}
                  dualKnobs></IonRange>
              </div>
              <div className={styles.inputs}>
                <IonInput
                  min={0}
                  value={filters.minPrice}
                  onIonChange={handleMinPriceChange}
                  type="number"
                  className="aecorn-input">
                  <div slot="start">{filters.minPrice ? "$" : "Any"}</div>
                  <div className="aecorn-input-label" slot="label">
                    Min
                  </div>
                </IonInput>
                <IonInput
                  min={0}
                  value={filters.maxPrice}
                  onIonChange={handleMaxPriceChange}
                  type="number"
                  className="aecorn-input">
                  <div slot="start">{filters.maxPrice ? "$" : "Any"}</div>
                  <div className="aecorn-input-label" slot="label">
                    Max
                  </div>
                </IonInput>
              </div>
            </div>
          </IonContent>
        </IonPopover>
      </div>
      <div className={styles.filter}>
        <IonItem
          id="beds-popover"
          button
          lines="none"
          className="aecorn-select">
          <IonLabel>{ListingsHelper.getListingNumberOfBedsLabel()}</IonLabel>
          <IonIcon slot="end" icon={chevronDownOutline} />
        </IonItem>
        <IonPopover
          trigger="beds-popover"
          triggerAction="click"
          showBackdrop={false}
          size="cover">
          <IonContent className="ion-padding">
            <div className={styles.popoverContent}>
              <div className={styles.item}>
                <div className={styles.label}>
                  <IonLabel>Bedrooms</IonLabel>
                  <IonLabel className={styles.value}>
                    {filters.minBeds ? `${filters.minBeds}+` : "Any"}
                  </IonLabel>
                </div>
                <IonRange
                  min={0}
                  max={5}
                  snaps
                  value={filters.minBeds}
                  onIonChange={handleBedsRangeChange}></IonRange>
              </div>
            </div>
          </IonContent>
        </IonPopover>
      </div>
      <div className={styles.filter}>
        <IonSegment
          mode="ios"
          className="aecorn-segment"
          onIonChange={handleLastStatusChange}
          value={lastStatus}>
          <IonSegmentButton value="sale">
            <IonLabel>{filters.type === "sale" ? "Sale" : "Lease"}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="sold" disabled={!isAuthenticated}>
            <IonLabel>{filters.type === "sale" ? "Sold" : "Leased"}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="delisted" disabled={!isAuthenticated}>
            <IonLabel>De-listed</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </div>
      <IonItem lines="none" className="aecorn-select">
        <IonSelect
          aria-label={
            lastStatus === "sale"
              ? "Listing date"
              : lastStatus === "sold"
              ? "Sold date"
              : "De-list date"
          }
          placeholder={
            lastStatus === "sale"
              ? "Listing date"
              : lastStatus === "sold"
              ? "Sold date"
              : "De-list date"
          }
          interface="popover"
          justify="space-between"
          toggleIcon={chevronDownOutline}
          expandedIcon={chevronUpOutline}
          interfaceOptions={{
            cssClass: styles.popover,
          }}
          value={date}
          onIonChange={handleUpdateDate}>
          {ListingsHelper.getListingDateFilterOptions().map((option, index) => (
            <IonSelectOption key={index} value={option.value}>
              {option.label}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
      <IonItem id="types-popover" lines="none" button className="aecorn-select">
        <IonLabel>{ListingsHelper.getListingPropertyTypeLabel()}</IonLabel>
        <IonIcon slot="end" icon={chevronDownOutline} />
      </IonItem>
      <IonPopover
        trigger="types-popover"
        triggerAction="click"
        showBackdrop={false}
        size="cover">
        <IonList>
          <IonItem>
            <IonCheckbox
              checked={!filters.propertyType.length}
              value="any"
              disabled={!filters.propertyType.length}
              onIonChange={handlePropertyTypeChange}>
              Any
            </IonCheckbox>
          </IonItem>
          {ListingsHelper.getListingPropertyTypesByClass().map(
            (option, index) => (
              <IonItem key={index}>
                <IonCheckbox
                  onIonChange={handlePropertyTypeChange}
                  checked={filters.propertyType.includes(option.value)}
                  value={option.value}>
                  {option.label}
                </IonCheckbox>
              </IonItem>
            )
          )}
        </IonList>
      </IonPopover>
      <div className={styles.filter}>
        <IonButton fill="clear" className="aecorn-button aecorn-button-clear">
          <IonIcon slot="start" icon={optionsOutline} />
          More filters
        </IonButton>
        <div className={styles.viewButtons}>
          <div
            onClick={() => updateView("map")}
            className={`${styles.viewButton} ${
              view === "map" && styles.activeViewButton
            }`}>
            <IonIcon icon={mapOutline} />
          </div>
          <div
            onClick={() => updateView("list")}
            className={`${styles.viewButton} ${
              view === "list" && styles.activeViewButton
            }`}>
            <IonIcon icon={gridOutline} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchFilters;
