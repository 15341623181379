import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  IonGrid,
  IonContent,
  IonRow,
  IonCol,
  IonIcon,
  getPlatforms,
} from "@ionic/react";
import CustomModalHeader from "../../CustomModalHeader/custom-modal-header.component";
import {
  chatboxEllipsesOutline,
  linkOutline,
  logoFacebook,
  logoInstagram,
  logoLinkedin,
  logoTwitter,
  logoWhatsapp,
  mailOutline,
} from "ionicons/icons";
import styles from "./invite.module.scss";
import { Clipboard } from "@ionic-native/clipboard";
import { SocialSharing } from "@ionic-native/social-sharing";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import AECORNLogo from "../../../../assets/svg/logo/aecorn_logo_small.svg";
import { AndroidPermissions } from "@ionic-native/android-permissions";
import { SMS } from "@ionic-native/sms";

const Invite = ({ setIsOpen }) => {
  const [isCopied, setIsCopied] = useState(false);
  const url = useLocation().pathname;

  const text = `Join AECORN!\nhttps://aecorn.ca${url}`;
  const emailText = `Join AECORN!%0D%0Ahttps://aecorn.ca${url}%0D%0A`;

  const copyToClipboard = async () => {
    try {
      await Clipboard.copy(text);
      setIsCopied(true);
    } catch (err) {
      navigator.clipboard.writeText(`https://aecorn.ca${url}`);
      setIsCopied(true);
    }
  };

  const shareViaWeb = (platform) => {
    switch (platform) {
      case "facebook":
        InAppBrowser.create(
          `https://www.facebook.com/sharer/sharer.php?u=https://aecorn.ca${url}`,
          "_blank",
          {
            hideurlbar: true,
            hidenavigationbutton: true,
            toolbar: "yes",
            toolbarcolor: "#110d1b",
            closebuttoncolor: "#f4f4f7",
            closebuttoncaption: "Back",
            toolbarposition: "top",
          }
        );
        break;
      case "twitter":
        InAppBrowser.create(
          `https://twitter.com/intent/tweet?text=${encodeURI(
            "Join AECORN!"
          )}%0A${encodeURI(text)}%0A${encodeURI(`https://aecorn.ca${url}`)}`,
          "_blank",
          {
            hideurlbar: true,
            hidenavigationbutton: true,
            toolbar: "yes",
            toolbarcolor: "#110d1b",
            closebuttoncolor: "#f4f4f7",
            closebuttoncaption: "Back",
            toolbarposition: "top",
          }
        );
        break;
      case "linkedin":
        InAppBrowser.create(
          `https://www.linkedin.com/sharing/share-offsite/?url=https://aecorn.ca${url}`,
          "_blank",
          {
            hideurlbar: true,
            hidenavigationbutton: true,
            toolbar: "yes",
            toolbarcolor: "#110d1b",
            closebuttoncolor: "#f4f4f7",
            closebuttoncaption: "Back",
            toolbarposition: "top",
          }
        );
        break;
      case "email":
        InAppBrowser.create(
          `mailto:yourfriend@email.com?Subject=Join%AECO!&body=${emailText}`,
          "_blank",
          {
            hideurlbar: true,
            hidenavigationbutton: true,
            toolbar: "yes",
            toolbarcolor: "#110d1b",
            closebuttoncolor: "#f4f4f7",
            closebuttoncaption: "Back",
            toolbarposition: "top",
          }
        );
        break;
      default:
        return;
    }
  };

  const shareHandler = async (platform) => {
    try {
      switch (platform) {
        case "email":
          await SocialSharing.shareViaEmail(text, "Join AECORN!");
          break;
        case "sms":
          if (getPlatforms().includes("android")) {
            AndroidPermissions.checkPermission(
              AndroidPermissions.PERMISSION.SEND_SMS
            ).then(
              (res) => {
                if (res.hasPermission) {
                  SMS.send("", text);
                } else {
                  AndroidPermissions.requestPermission(
                    AndroidPermissions.PERMISSION.SEND_SMS
                  )
                    .then((res) => SMS.send("", text))
                    .catch((err) => console.log(err));
                }
              },
              (err) =>
                AndroidPermissions.requestPermissions([
                  AndroidPermissions.PERMISSION.SEND_SMS,
                ])
            );
          } else {
            SMS.hasPermission()
              .then((res) => {
                SMS.send("", text);
              })
              .catch((err) => console.log(err));
          }
          break;
        case "facebook":
          await SocialSharing.shareViaFacebook(text, `https://aecorn.ca${url}`);
          break;
        case "twitter":
          await SocialSharing.shareViaTwitter(text, `https://aecorn.ca${url}`);
          break;
        case "linkedin":
          await SocialSharing.shareVia(
            "linkedin",
            text,
            `https://aecorn.ca${url}`
          );
          break;
        case "instagram":
          await SocialSharing.shareViaInstagram(text, AECORNLogo);
          break;
        case "whatsapp":
          await SocialSharing.shareViaWhatsApp(
            text,
            AECORNLogo,
            `https://aecorn.ca${url}`
          );
          break;
        default:
          return;
      }
    } catch (err) {
      try {
        await SocialSharing.shareWithOptions({
          message: text,
          subject: "Join AECORN!",
          url: `https://aecorn.ca${url}`,
          chooserTitle: "Join AECORN!",
        });
      } catch (err) {
        shareViaWeb(platform);
      }
    }
  };

  return (
    <>
      <CustomModalHeader
        title="Invite others!"
        disable={true}
        setShowModal={setIsOpen}
        hasBackbtn={true}
      />
      <IonContent>
        <IonGrid className={styles.invite}>
          <IonRow>
            <IonCol className={styles.subtitle}>
              Enjoying AECORN? Fantastic! Invite friends, family or your agent
              to take advantage of AECORN's great features.
            </IonCol>
          </IonRow>

          <IonRow className={styles.shareButton}>
            <IonCol
              className={styles.button}
              onClick={isCopied ? null : copyToClipboard}
              style={{
                backgroundColor: isCopied && "#dff7f0",
              }}>
              <div>
                <IonIcon icon={linkOutline} className={styles.linkIcon} />
              </div>
              <div>{isCopied ? "Copied" : "Copy link"}</div>
            </IonCol>
            <IonCol
              className={styles.button}
              onClick={() => shareHandler("email")}>
              <div>
                <IonIcon icon={mailOutline} className={styles.mailIcon} />
              </div>
              <div>Email</div>
            </IonCol>
          </IonRow>
          {getPlatforms().includes("capacitor") && (
            <IonRow className={styles.row}>
              <IonCol onClick={() => shareHandler("sms")}>
                <IonIcon
                  icon={chatboxEllipsesOutline}
                  className={styles.icon}
                />
                <span>Text</span>
              </IonCol>
            </IonRow>
          )}
          <IonRow className={styles.row}>
            <IonCol onClick={() => shareHandler("facebook")}>
              <IonIcon icon={logoFacebook} className={styles.icon} />
              <span>Facebook</span>
            </IonCol>
          </IonRow>
          {/* <IonRow className={styles.row}>
            <IonCol onClick={() => shareHandler("instagram")}>
              <IonIcon icon={logoInstagram} className={styles.icon} />
              <span>Instagram</span>
            </IonCol>
          </IonRow> */}
          <IonRow className={styles.row}>
            <IonCol onClick={() => shareHandler("linkedin")}>
              <IonIcon icon={logoLinkedin} className={styles.icon} />
              <span>LinkedIn</span>
            </IonCol>
          </IonRow>
          <IonRow className={styles.row}>
            <IonCol onClick={() => shareHandler("twitter")}>
              <IonIcon icon={logoTwitter} className={styles.icon} />
              <span>Twitter</span>
            </IonCol>
          </IonRow>
          <IonRow className={`${styles.row} ${styles.lastRow}`}>
            <IonCol onClick={() => shareHandler("whatsapp")}>
              <IonIcon icon={logoWhatsapp} className={styles.icon} />
              <span>Whatsapp</span>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default Invite;
