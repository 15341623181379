import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import numeral from "numeral";
import { overview } from "../../../../../../../../data/detailsPage";
import { selectCurrentClient } from "../../../../../../../../redux/client/client.selectors";
import { selectCurrencyRates } from "../../../../../../../../redux/currency/currency.selectors";
import styles from "./essentials.module.scss";
import {
  copyToClipboard,
  getLastStatusLabel,
  diffDate,
} from "../../../../../../../../utils/functions";
import { updateToast } from "../../../../../../../../redux/ui/ui.actions";

const Essentials = ({ listing, language }) => {
  const dispatch = useDispatch();
  const client = useSelector(selectCurrentClient);
  const rates = useSelector(selectCurrencyRates);
  const price = ["Lsd,Sld"].includes(listing.lastStatus)
    ? listing.soldPrice
    : listing.listPrice;
  let currency;
  if (client && rates) {
    if (client.currency !== "cad") currency = client.currency.toUpperCase();
  }

  const handleCopyToClipboard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const res = await copyToClipboard(listing.mlsNumber);
    res &&
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Property MLS number has been copied to clipboard.",
        })
      );
  };

  return (
    <div className={styles.essentials}>
      <div className={styles.infos}>
        <div>
          <span className={styles.bold}>{overview[language].mlsNumber}</span>
          <span
            className={`${styles.underline} ${styles.pointer}`}
            onClick={handleCopyToClipboard}
          >
            {listing.mlsNumber}
          </span>
        </div>
        <div>
          <span className={styles.bold}>{overview[language].listed}</span>
          <span>
            {
              diffDate(
                listing.status,
                listing.listDate,
                listing.soldDate,
                false
              ).label
            }
          </span>
        </div>
        <div>
          <span className={styles.bold}>Date Listed</span>
          <span>{`${moment.utc(listing.listDate).format("MMM Do YYYY")}`}</span>
        </div>
        {listing.status !== "A" && (
          <div>
            <span className={styles.bold}>
              {getLastStatusLabel(listing.lastStatus)}
            </span>
            <span>
              {` ${moment
                .utc(
                  listing.status === "U" && listing.soldDate
                    ? listing.soldDate
                    : listing.updatedOn
                )
                .format("MMM Do YYYY")}`}
            </span>
          </div>
        )}
        <div>
          <span className={styles.bold}>{overview[language].type}</span>
          <span>{listing.details.propertyType}</span>
        </div>
        <div>
          <span className={styles.bold}>{overview[language].taxes}</span>
          <span>{`${numeral(listing.taxes.annualAmount).format("$1,000,000")}/${
            listing.taxes.assessmentYear
          }`}</span>
        </div>
        <div>
          <span className={styles.bold}>{overview[language].sqft}</span>
          <span>{listing.details.sqft ? listing.details.sqft : "-"}</span>
        </div>
        {listing.class !== "CondoProperty" && (
          <div>
            <span className={styles.bold}>
              {overview[language].lotFrontage}
            </span>
            <span>
              {listing.lot.width
                ? listing.lot.width + " " + listing.lot.measurement
                : "-"}
            </span>
          </div>
        )}
        {listing.class !== "CondoProperty" && (
          <div>
            <span className={styles.bold}>{overview[language].lotDepth}</span>
            <span>
              {listing.lot.depth
                ? listing.lot.depth + " " + listing.lot.measurement
                : "-"}
            </span>
          </div>
        )}
        {listing.class !== "CondoProperty" && (
          <div>
            <span className={styles.bold}>{overview[language].basement}</span>
            <span>
              {listing.details.basement1
                ? listing.details.basement2
                  ? `${listing.details.basement1}, ${listing.details.basement2}`
                  : listing.details.basement1
                : "-"}
            </span>
          </div>
        )}
        {listing.class === "CondoProperty" && (
          <div>
            <span className={styles.bold}>{overview[language].exposure}</span>
            <span>{listing.condominium.exposure}</span>
          </div>
        )}
        {listing.class === "CondoProperty" && (
          <div>
            <span className={styles.bold}>{overview[language].balcony}</span>
            <span>
              {listing.details.balcony ? listing.details.balcony : "-"}
            </span>
          </div>
        )}
        {listing.class === "CondoProperty" && (
          <div>
            <span className={styles.bold}>{overview[language].locker}</span>
            <span>
              {listing.condominium.locker
                ? listing.condominium.lockerNumber
                  ? `${listing.condominium.locker}, ${listing.condominium.lockerNumber}`
                  : listing.condominium.locker
                : "-"}
            </span>
          </div>
        )}
        {listing.class === "CondoProperty" && (
          <div>
            <span className={styles.bold}>{overview[language].fees}</span>
            <span>
              {listing.condominium.fees.maintenance
                ? numeral(listing.condominium.fees.maintenance).format(
                    "$1,000,000"
                  )
                : "-"}
            </span>
          </div>
        )}
        <div>
          <span className={styles.bold}>{overview[language].brokerage}</span>
          <span className={styles.brokeragename}>
            {listing.office.brokerageName ? listing.office.brokerageName : "-"}
          </span>
        </div>
        <div>
          <span className={styles.bold}>
            {overview[language].possessionDate}
          </span>
          <span>
            {listing.timestamps.possessionDate
              ? moment
                  .utc(listing.timestamps.possessionDate)
                  .format("MMM Do YYYY")
              : "-"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Essentials;
