import { IonIcon } from "@ionic/react";
import { useDispatch } from "react-redux";
import { arrowForwardCircleOutline } from "ionicons/icons";
import Button from "../../../../components/Form/Button/button.component";
import { copyToClipboard, numberWithCommas } from "../../../../utils/functions";
import CardContainer from "../../../Tours/component/CardContainer/card-container.component";
import { openModal, updateToast } from "../../../../redux/ui/ui.actions";
import Rating from "../../../../components/Rating/rating.component";
import styles from "./overview.module.scss";

const Overview = ({ listing, reviews, remove, tour }) => {
  const dispatch = useDispatch();

  const handleCopyToClipboard = async () => {
    const res = await copyToClipboard(listing.mlsNumber);
    res &&
      dispatch(
        updateToast({
          open: true,
          type: "success",
          message: "Property MLS number has been copied to clipboard.",
        })
      );
  };

  return (
    <CardContainer>
      <div className={styles.overview}>
        <div className={styles.title}>Overview</div>
        <div className={styles.details}>
          <div className={styles.detail}>
            <span>Price</span>
            <span className={styles.bold}>
              ${numberWithCommas(listing.listPrice)}
            </span>
          </div>
          <div className={styles.detail}>
            <span>Property type</span>
            <span className={styles.bold}>{listing.details.propertyType}</span>
          </div>
          <div className={styles.detail}>
            <span>Beds</span>
            <span className={styles.bold}>
              {" "}
              {`${
                listing.details.numBedrooms ? listing.details.numBedrooms : "-"
              } ${
                listing.details.numBedroomsPlus !== "" &&
                listing.details.numBedroomsPlus !== null
                  ? " + " + listing.details.numBedroomsPlus
                  : ""
              }`}{" "}
            </span>
          </div>
          <div className={styles.detail}>
            <span>Baths</span>
            <span className={styles.bold}>{listing.details.numBathrooms}</span>
          </div>
          <div className={styles.detail}>
            <span>Parking</span>
            <span className={styles.bold}>
              {listing.details.numParkingSpaces === "0.0"
                ? listing.details.numGarageSpaces === "0.0"
                  ? "-"
                  : listing.details.numGarageSpaces
                : listing.details.numParkingSpaces}
            </span>
          </div>
          <div className={styles.detail}>
            <span>MLS Number</span>
            <span
              className={`${styles.bold} ${styles.underline}`}
              onClick={handleCopyToClipboard}>
              <span>{listing.mlsNumber}</span>
            </span>
          </div>
          <div className={styles.detail}>
            <span>All details</span>
            <span className={styles.bold}>
              <span
                className={styles.underline}
                onClick={() =>
                  window.open(`/listings/${listing.mlsNumber}`, "_blank")
                }>
                View full listing
              </span>
              <IonIcon
                icon={arrowForwardCircleOutline}
                className={styles.icon}
              />
            </span>
          </div>
          {reviews &&
            reviews.length > 0 &&
            reviews.map((r, i) => (
              <div className={styles.detail} key={i}>
                <span>{`${r.name}'s Review`}</span>
                <span>{r.rate ? <Rating review={r} readOnly /> : "TBD"}</span>
              </div>
            ))}

          <Button
            title="Remove from tour"
            type="orange"
            style={{
              border: "1px solid #C24100",
              width: "100%",
              height: "5rem",
              margin: "2rem 0 4rem",
            }}
            onClick={() =>
              dispatch(
                openModal({ current: "deleteShowing", data: { remove } })
              )
            }
            disabled={!tour || !tour.status || tour.status === "archived"}
          />
        </div>
      </div>
    </CardContainer>
  );
};

export default Overview;
