import { useState } from "react";
import styles from "../filters-menu.module.scss";
import withFilters from "../../../HOC/withFilters/with-filters";

import { IonInput } from "@ionic/react";

const ProjectFilterItem = ({
  filters: { projectName },
  updateSingleFilter,
}) => {
  const [value, setValue] = useState(projectName);

  const handleChange = ({ detail: { value: input } }) => {
    setValue(input);
    if (input && input.length > 0) {
      updateSingleFilter({ projectName: input });
    } else {
      updateSingleFilter({ projectName: null });
    }
  };

  return (
    <div className={styles.item}>
      <div className={styles.keywords}>
        <div className={styles.label}>Project Name</div>
        <div className={styles.input} style={{ marginLeft: "1rem" }}>
          <IonInput
            debounce={1000}
            value={value}
            className={styles.keywordInput}
            clearInput={true}
            onIonChange={handleChange}
            placeholder="Project name"
            onIonFocus={() => window.scrollTo(0, document.body.scrollHeight)}
          />
        </div>
      </div>
    </div>
  );
};

export default withFilters(ProjectFilterItem);
