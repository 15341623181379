import { useState, useRef, useEffect, useCallback } from "react";
import { createGesture } from "@ionic/core/components";
import { useSelector } from "react-redux";
import FavoriteIcon from "../../../../../components/ListingCard/components/FavoriteIcon/favorite-icon.component";
import withClient from "../../../../../HOC/withClient/with-client";
import { selectCurrencyRates } from "../../../../../redux/currency/currency.selectors";
import {
  diffDate,
  getPricesPercentageDiff,
  getTagTitle,
  isActiveTag,
  renderCurrencySign,
  renderEstimate,
  renderPrice,
} from "../../../../../utils/functions";
import styles from "./listing-details.module.scss";
import ListingContent from "./components/ListingContent/listing-content.component";
import { IonIcon } from "@ionic/react";
import {
  arrowDownOutline,
  arrowUpOutline,
  checkmarkOutline,
} from "ionicons/icons";
import millify from "millify";
import moment from "moment";
import { getMLSEstimate } from "../../../../../api/ettie";

const ListingDetails = ({
  listing,
  similarListing,
  currentClient,
  isActiveComparables,
  parentRef,
  imagesRef,
  schools,
}) => {
  const {
    mlsNumber,
    status,
    lastStatus,
    type,
    listDate,
    soldDate,
    updatedOn,
    soldPrice,
    listPrice,
  } = listing;
  const [expanded, setExpanded] = useState(false);
  const [estimate, setEstimate] = useState();
  const containerRef = useRef();
  const handleRef = useRef();
  const contentRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);

  const fetchEstimate = useCallback(async (mlsNumber) => {
    try {
      const response = await getMLSEstimate(mlsNumber);
      return response;
    } catch (err) {
      console.log(err);
      return;
    }
  }, []);

  useEffect(() => {
    if (listing.status === "A") {
      fetchEstimate(listing.mlsNumber).then((data) => {
        if (data) {
          setEstimate(data);
        }
      });
    }
  }, [fetchEstimate, listing]);

  useEffect(() => {
    if (handleRef.current) {
      setHeaderHeight(handleRef.current.offsetHeight);
    }
  }, [handleRef.current?.offsetHeight, expanded]);

  const rates = useSelector(selectCurrencyRates);

  let currency;
  if (currentClient && rates) {
    if (currentClient.currency !== "cad")
      currency = currentClient.currency.toUpperCase();
  }

  let comparableStatus;
  isActiveComparables && (comparableStatus = "A");

  useEffect(() => {
    let h = handleRef.current;
    let c = containerRef.current;
    let content = contentRef.current;
    let images = imagesRef.current;
    let gesture;

    gesture = createGesture({
      el: h,
      gestureName: "my-swipe",
      direction: "y",
      onMove: (event) => {
        images.style.overflow = "hidden";

        if (event.deltaY < -content.clientHeight) return;

        if (event.deltaY > 100) {
          c.style.transform = "";
          c.dataset.open = "false";
          setExpanded(false);
          images.style.overflow = "scroll";
          images.focus();
          return;
        }
        c.style.transform = `translateY(${event.deltaY}px)`;
      },

      onEnd: (event) => {
        c.style.transition = "0.5s ease-out";
        if (event.deltaY < -40 && c.dataset.open !== "true") {
          c.style.transform = `translateY(-${content.clientHeight}px)`;
          c.dataset.open = "true";
          imagesRef.current.blur();
          setExpanded(true);
          c.focus();
        } else {
          c.style.transform = "";
          c.dataset.open = "false";
          setExpanded(false);
          images.style.overflow = "scroll";
          images.focus();
          return;
        }
      },
    });

    gesture.enable(true);

    return () => gesture && gesture.destroy();
  }, [expanded, imagesRef]);

  const handleClick = () => {
    let h = handleRef.current;
    let c = containerRef.current;
    let content = contentRef.current;
    if (h.dataset.open === "true") {
      setExpanded(false);
      c.style.transform = "";
      h.dataset.open = "false";
      content.style.height = "100%";
      if (imagesRef.current) imagesRef.current.style.overflow = "scroll";
    } else {
      setExpanded(true);
      h.dataset.open = "true";

      c.style.transform = `translateY(-${content.offsetHeight}px)`;
      c.style.transition = "0.5s ease-in-out";
      if (imagesRef.current) imagesRef.current.style.overflow = "hidden";
    }
  };

  const renderEstimateWithDiff = () => {
    const estimateValue = renderEstimate(currency, estimate, rates);
    const price = listing.soldPrice > 0 ? listing.soldPrice : listing.listPrice;

    const difference = getPricesPercentageDiff(price, estimate);

    return (
      <div>
        <span>Estimated value: {estimateValue}</span>
        <span>
          {difference > 0 ? (
            <span className={styles.increased}>
              <IonIcon icon={arrowUpOutline} />
              <span className={styles.diffText}>{difference}%</span>
            </span>
          ) : (
            <span className={styles.decreased}>
              <IonIcon icon={arrowDownOutline} />
              <span className={styles.diffText}>{difference}%</span>
            </span>
          )}
        </span>
      </div>
    );
  };

  const renderPriceDiff = () => {
    const renderListPrice = () => (
      <span className={styles.listPrice}>
        List Price: {renderCurrencySign(currency)}
        {millify(
          currency ? (+listPrice / rates.CAD) * rates[currency] : listPrice,
          { precision: 2 }
        )}
      </span>
    );
    let diff = soldPrice - listPrice;
    if (currency) diff = (+diff / rates.CAD) * rates[currency];

    return (
      <div>
        <span
          className={
            diff > 0
              ? styles.increased
              : diff < 0
              ? styles.decreased
              : styles.asking
          }>
          <IonIcon
            icon={
              diff > 0
                ? arrowUpOutline
                : diff < 0
                ? arrowDownOutline
                : checkmarkOutline
            }
          />
          <span className={styles.value}>
            {diff > 0 || diff < 0 ? (
              <span className={styles.value}>
                {renderCurrencySign(currency)}
                {millify(diff, { precision: 2 })} {diff > 0 ? "above" : "below"}{" "}
                asking
              </span>
            ) : (
              <span className={styles.text}>
                {type.toLowerCase() === "sale" ? "sold" : "leased"} at asking
              </span>
            )}
          </span>
        </span>
        {diff !== 0 && renderListPrice()}
      </div>
    );
  };

  return (
    <div
      id="details"
      className={styles.container}
      ref={containerRef}
      onMouseEnter={() => imagesRef.current.blur()}
      onTouchMove={() => imagesRef.current.blur()}
      onTouchStart={() => {
        imagesRef.current.blur();
        imagesRef.current.style.overflow = "hidden";
      }}
      style={{
        top: `calc(100% - (${headerHeight}px) - 7rem)`,
      }}>
      <div className={styles.header} ref={handleRef} onClick={handleClick}>
        <div className={styles.handleContainer}>
          <span className={styles.handle} />
        </div>
        {!expanded && (
          <div className={styles.tags}>
            <span className={styles[isActiveTag(lastStatus)]}>
              {["sale", "lease"].includes(
                getTagTitle(lastStatus, type).toLowerCase()
              )
                ? `for ${getTagTitle(lastStatus, type)}`
                : getTagTitle(lastStatus, type)}
            </span>
            {status === "A" ? (
              <span className={styles.date}>
                {diffDate(status, listDate, soldDate).label}
              </span>
            ) : (
              diffDate(status, listDate, soldDate).onMarket && (
                <span className={styles.onMarket}>
                  <span style={{ fontWeight: 600 }}>
                    {
                      diffDate(
                        status,
                        moment.utc(listDate),
                        soldDate ? moment.utc(soldDate) : moment.utc(updatedOn)
                      ).soldDate
                    }
                  </span>
                </span>
              )
            )}
          </div>
        )}

        <div className={styles.priceIconsContainer}>
          <div className={styles.price}>
            <span className={styles.current}>
              {renderPrice(currency, soldPrice, rates, listPrice)}
            </span>
            {status === "A" && (
              <div className={styles.estimate}>
                {estimate && renderEstimateWithDiff()}
              </div>
            )}

            {(lastStatus === "Sld" || lastStatus === "Lsd") && (
              <span className={styles.diff}>{renderPriceDiff()}</span>
            )}
          </div>
          <div
            className={styles.icons}
            onTouchEnd={() => {
              if (!expanded) imagesRef.current.style.overflow = "scroll";
            }}>
            <FavoriteIcon
              isApp={true}
              listing={listing}
              isActiveComparables={isActiveComparables}
            />
          </div>
        </div>
      </div>
      <div className={styles.content} ref={contentRef} id="content">
        <ListingContent
          listing={listing}
          similarListing={similarListing}
          client={currentClient}
          containerRef={parentRef}
          schools={schools}
        />
      </div>
    </div>
  );
};

export default withClient(ListingDetails);
