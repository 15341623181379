import Card from "../../../Card/card.component";
import ListingCard from "../../../ListingCard/listing-card.component";
import PreListingCard from "../../../ListingCard/pre-listing-card.component";
import styles from "./map-listing-card.module.scss";

const MapListingCard = ({
  listing,
  isMobile,
  addShowing,
  webAddShowing,
  handleAddShowing,
  isPre = false,
}) => (
  <div className={styles.cards}>
    <Card
      style={{
        padding: "1.5rem",
        width: isMobile ? "100%" : "35rem",
      }}
      mapCard={true}
    >
      {isPre ? (
        <PreListingCard listing={listing} map={true} />
      ) : (
        <ListingCard
          listing={listing}
          map={true}
          addShowing={addShowing}
          webAddShowing={webAddShowing}
          handleAddShowing={handleAddShowing}
        />
      )}
    </Card>
  </div>
);

export default MapListingCard;
