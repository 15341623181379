import React from "react";
import moment from "moment";
import { numberWithCommas } from "../../../../../../../../../../utils/functions";
import styles from "./market-summary.module.scss";

const MarketSummary = ({ currentDate, marketData }) => {
  return (
    <div className={styles.summaryData}>
      <div className={styles.price}>
        <div className={styles.title}>Median Sold Price</div>

        <div>
          <div>{currentDate}</div>
          <div>{numberWithCommas(marketData.medSoldPriceLastMonth, true)}</div>
        </div>
      </div>

      <div className={styles.listings}>
        <div className={styles.title}>Number of New Listings</div>

        <div>
          <div>{currentDate}</div>
          <div style={{ textAlign: "center" }}>{marketData.cntNewListings}</div>
        </div>
      </div>

      <div className={styles.value}>
        <div className={styles.title}>Value Change</div>

        <div className={styles.values}>
          <div>
            <span>1 Year</span>
            <span>{marketData.oneYearValue}</span>
          </div>

          <div>
            <span>5 Year</span>

            <span>{marketData.fiveYearValue}</span>
          </div>

          <div>
            <span>10 Year</span>
            <span>{marketData.tenYearValue}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketSummary;
