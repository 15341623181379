import styles from "./neighbourhood.module.scss";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IonList, IonItem, IonCheckbox, IonLabel } from "@ionic/react";
import withFilters from "../../../../../HOC/withFilters/with-filters";
import { selectLocationsValue } from "../../../../../redux/locations/locations.selectors";
import withMap from "../../../../../HOC/withMap/with-map";
import { useHistory } from "react-router-dom";

const NeighbourhoodPage = ({
  filters: { area, city, neighborhood },
  updateMultipleFilters,
  updateMapLocations,
}) => {
  const history = useHistory();
  const selectedCity = useParams().city;
  const locations =
    useSelector(selectLocationsValue)?.filter((loc) => loc.name === area) || [];
  let neighbourhoods;
  if (locations.length) {
    const cities = locations[0].value[0].cities;
    neighbourhoods = cities.find((c) => c.name === selectedCity).neighborhoods;
  }

  const handleSelect = ({ name, coordinates, location }) => {
    const updatedCities = city
      ? city.includes(selectedCity)
        ? city.filter((c) => c !== selectedCity)
        : city
      : null;
    if (neighborhood) {
      if (neighborhood.includes(name)) {
        const updatedNeighborhood = neighborhood.filter((n) => n !== name);
        updateMultipleFilters({
          neighborhood: updatedNeighborhood.length ? updatedNeighborhood : null,
        });
        updateMapLocations({ coordinates, neighborhood: name });
      } else {
        updateMultipleFilters({
          neighborhood: [...neighborhood, name],
          city: updatedCities
            ? updatedCities.length
              ? updatedCities
              : null
            : null,
        });

        updateMapLocations({
          center: location,
          coordinates,
          neighborhood: name,
          city: selectedCity,
        });
      }
    } else {
      updateMultipleFilters({
        neighborhood: [name],
        city: updatedCities
          ? updatedCities.length
            ? updatedCities
            : null
          : null,
      });
      updateMapLocations({
        center: location,
        coordinates,
        neighborhood: name,
        city: selectedCity,
      });
      //updateLocations({ reset: true });
    }
  };

  if (!locations.length || !neighbourhoods) {
    history.replace("/listings", { direction: "root" });
    return;
  }

  return (
    <IonList>
      {neighbourhoods.map((n) => (
        <IonItem key={n.name} lines="full">
          <IonCheckbox
            value={n.name}
            className={styles.checkbox}
            slot="start"
            mode="md"
            checked={
              neighborhood &&
              (neighborhood === n.name || neighborhood.includes(n.name))
            }
            onClick={() => handleSelect(n)}
          />
          <IonLabel
            className={`${styles.label} ${
              neighborhood &&
              (neighborhood === n.name || neighborhood.includes(n.name)) &&
              styles.selected
            }`}
          >
            {n.name}
          </IonLabel>
        </IonItem>
      ))}
    </IonList>
  );
};

export default withMap(withFilters(NeighbourhoodPage));
