import { IonInput, IonItem } from "@ionic/react";

const PreInput = ({
  value,
  label,
  required = false,
  placeholder,
  pattern,
  type = "text",
  onChange,
  style,
  autoGrow = false,
}) => {
  return (
    <IonItem style={style}>
      <div style={{ color: "#1f7a60", marginRight: "10px" }}>
        {label}
        {required && "*"}
      </div>
      <IonInput
        placeholder={placeholder}
        clearInput
        required
        pattern={pattern}
        value={value}
        type={type}
        autoGrow={autoGrow}
        onIonChange={(e) => onChange(e.detail.value)}
      />
    </IonItem>
  );
};

export default PreInput;
