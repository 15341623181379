import { useEffect, useState, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonSpinner,
  IonModal,
} from "@ionic/react";
import styles from "./sign-up.module.scss";
import { clearErrors, signUpStart } from "../../../redux/user/user.actions";
import {
  selectCurrentUser,
  selectUserError,
  selectUserState,
} from "../../../redux/user/user.selectors";
import CustomAuthHeader from "../../components/Authentication/components/CustomAuthHeader/custom-auth-header.component";
import Input from "../../../components/Form/Input/input.component";
import FederatedButtons from "../../components/Authentication/components/FederatedButtons/federated-buttons.component";
import AppConfirmSignUpModal from "./component/confirm-sign-up.component";
import AppHeader from "../../components/Header/header.component";

const AppSignUp = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const error = useSelector(selectUserError);
  const state = useSelector(selectUserState);
  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const inputRefs = useRef({
    email: createRef(),
    given_name: createRef(),
    family_name: createRef(),
    password: createRef(),
    confirm_password: createRef(),
  });

  const [formData, setFormData] = useState({
    email: "",
    given_name: "",
    family_name: "",
    password: "",
    confirm_password: "",
    subscribed: true,
  });

  const handleChange = (value, name) => {
    inputRefs.current[name].current.validate();
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const submitSignUp = () => {
    let isValid = true;
    for (const field of Object.values(inputRefs.current)) {
      const validationResult = field.current.validate(true);
      if (!validationResult) isValid = false;
    }
    if (isValid) {
      setLoading(true);
      const { email, given_name, family_name, password, subscribed } = formData;

      dispatch(
        signUpStart({
          username: email,
          given_name,
          family_name,
          password,
          subscribed,
        })
      );
    } else {
      return;
    }
  };

  useEffect(() => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{8,}$/;
    if (formData.password !== "" && !re.test(formData.password)) {
      setPasswordError(
        "Must be minimum 8 characters, contain a mix of upper and lower case letters, at least 1 number, and 1 special character."
      );
    } else if (formData.password !== "" && re.test(formData.password)) {
      setPasswordError("");
    }

    if (formData.confirm_password === "" && formData.password === "") {
      setPasswordError("");
    } else if (
      formData.password !== "" &&
      formData.confirm_password !== "" &&
      re.test(formData.password)
    ) {
      if (formData.password !== formData.confirm_password) {
        setPasswordError("Passwords don't match");
      } else {
        setPasswordError("");
      }
    }
  }, [formData.password, formData.confirm_password]);

  useEffect(() => {
    if (state === "signedUp") {
      setShowConfirmModal(true);
    }
    if (state === "signedIn") {
      setShowConfirmModal(false);
    }
    setLoading(false);
  }, [error, state]);

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  return user ? (
    <Redirect to="/home" />
  ) : (
    <IonPage ref={ref}>
      <AppHeader title="Sign up" hasRightButton={false} backHref="/home" />
      <IonContent>
        <IonModal
          presentingElement={ref.current}
          isOpen={showConfirmModal}
          onDidDismiss={() => setShowConfirmModal(false)}>
          <AppConfirmSignUpModal
            setShowConfirmModal={setShowConfirmModal}
            username={formData.email}
            password={formData.password}
          />
        </IonModal>

        <CustomAuthHeader />
        <IonGrid className={styles.signup}>
          {/* <FederatedButtons option="signup" /> */}
          <div className={styles.inputs}>
            <IonRow>
              <IonCol>
                <Input
                  name="given_name"
                  label="given name"
                  placeholder="given name"
                  required
                  ref={inputRefs.current.given_name}
                  value={formData["given_name"]}
                  onChange={(value, name) => handleChange(value, name)}
                  validation="required|min:2|regex:^[a-zA-Z\s]*$"
                  isAuth
                  enterkeyhint="next"
                  submit={() => inputRefs.current.family_name.current.next()}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Input
                  name="family_name"
                  label="family name"
                  placeholder="family name"
                  required
                  ref={inputRefs.current.family_name}
                  value={formData["family_name"]}
                  onChange={(value, name) => handleChange(value, name)}
                  validation="required|min:2"
                  isAuth
                  enterkeyhint="next"
                  submit={() => inputRefs.current.email.current.next()}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Input
                  name="email"
                  label="email"
                  placeholder="email"
                  mode="email"
                  required
                  ref={inputRefs.current.email}
                  value={formData["email"]}
                  onChange={(value, name) =>
                    handleChange(value.toLowerCase(), name)
                  }
                  validation="required|email"
                  isAuth
                  enterkeyhint="next"
                  submit={() => inputRefs.current.password.current.next()}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Input
                  name="password"
                  label="password"
                  placeholder="password"
                  required
                  type="password"
                  ref={inputRefs.current.password}
                  value={formData["password"]}
                  onChange={(value, name) => handleChange(value, name)}
                  // validation={"password"}
                  isAuth
                  enterkeyhint="next"
                  submit={() =>
                    inputRefs.current.confirm_password.current.next()
                  }
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Input
                  name="confirm_password"
                  label="confirm password"
                  placeholder="confirm password"
                  required
                  type="password"
                  ref={inputRefs.current.confirm_password}
                  value={formData["confirm_password"]}
                  onChange={(value, name) => handleChange(value, name)}
                  // validation={`confirmPassword:${formData.password}`}
                  submit={submitSignUp}
                  isAuth
                  enterkeyhint="go"
                />
              </IonCol>
            </IonRow>
          </div>
          {error && (
            <IonRow className={styles.error}>
              <IonCol>{error.message}</IonCol>
            </IonRow>
          )}
          {passwordError && (
            <IonRow className={styles.error}>
              <IonCol>{passwordError}</IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol className={styles.consent} size="12">
              <IonItem>
                <IonCheckbox
                  checked={formData.subscribed}
                  onIonChange={(e) =>
                    setFormData({
                      ...formData,
                      subscribed: !formData.subscribed,
                    })
                  }
                />
                <IonLabel>
                  I consent to receive communications by email
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                shape="round"
                className={styles.button}
                onClick={submitSignUp}>
                {loading ? (
                  <span className={styles.title}>
                    <IonSpinner name="crescent" className={styles.spinner} />
                    working on it!
                  </span>
                ) : (
                  <span> Next!</span>
                )}
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.login}>
              Already have an account?
              <Link to="/signin">Sign in</Link>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className={styles.agree}>
              By creating an account, you are agreeing to our{" "}
              <Link to="/terms">Terms of Service</Link> and{" "}
              <Link to="/privacy">Privacy Policy</Link>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AppSignUp;
