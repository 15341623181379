import { createSelector } from "reselect";

const selectFilters = (state) => state.filters;

const getType = (_, type) => type;

export const selectFiltersValue = createSelector(
  [selectFilters],
  (filters) => filters.value
);

export const selectExtraFilters = createSelector(
  [selectFilters],
  (filters) => filters.extra
);

export const selectFiltersByType = createSelector(
  [getType, selectFiltersValue],
  (filters, type) => filters[type]
);

export const selectIsPreConstruction = createSelector(
  [selectFilters],
  (filters) => filters.value.type.toLowerCase() === "pre-construction"
);
