import { IonLoading } from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Card from "../../../../components/Card/card.component";
import { updateToast } from "../../../../redux/ui/ui.actions";
import styles from "./units.module.scss";
import defaultImage from "../../../../assets/img/preconstruction/bg.jpg";
import Button from "../../../../components/Form/Button/button.component";
import BreadCrumbs from "../../components/breadcrumbs";
import { getUnits } from "../../../../amplify/graphql.utils";
import _ from "lodash";

const PROPERTY_TYPES = {
  condo: "Condo",
  townhouse: "Townhouse",
  single: "Single Family Residence",
};

const Units = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [units, setUnits] = useState();
  const [loading, setLoading] = useState(true);

  const loadUnits = async () => {
    try {
      const units = await getUnits();
      setUnits(_.groupBy(units, "projectId"));
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
      history.replace("/admin");
    } finally {
      setLoading(false);
    }
  };

  props.pageHook(() => {
    loadUnits();
  });

  useEffect(() => {
    loadUnits();
  }, []);

  const renderCards = () => {
    return Object.keys(units).map((key) => {
      const unitsForProject = units[key];
      const project = unitsForProject[0]?.project?.name;
      if (!project) return null;
      return (
        <div className={styles.project} key={key}>
          <div className={styles.title}>
            Units for <b>{project}</b>
          </div>
          <div className={styles.cards}>
            {unitsForProject.map(({ id, numBeds, numBaths, sqft, type }) => (
              <Card key={id} onClick={() => history.push(`/admin/units/${id}`)}>
                <div className={styles.card}>
                  <img className={styles.image} src={defaultImage} alt="Unit" />
                  <span className={styles.title}>{PROPERTY_TYPES[type]}</span>
                  <span className={styles.details}>
                    <span className={styles.item}>
                      Bedrooms: {numBeds ? numBeds : "-"}
                    </span>
                    <span className={styles.item}>
                      Bathrooms: {numBaths ? numBaths : "-"}
                    </span>
                    <span className={styles.item}>
                      SQFT: {sqft ? sqft : "-"}
                    </span>
                  </span>
                </div>
              </Card>
            ))}
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.container}>
      <BreadCrumbs type="units" />
      <IonLoading isOpen={loading} />
      <div>
        {!loading ? (
          units ? (
            renderCards()
          ) : (
            <div className={styles.noItem}>
              No units available
              <Button
                style={{ marginTop: "2rem" }}
                title={"Create"}
                type={"green"}
                onClick={() => history.push("/admin/units/new")}
              />
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default Units;
