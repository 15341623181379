import { useEffect, useState, useRef } from "react";
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonRange,
  IonSkeletonText,
  IonToolbar,
  useIonViewWillLeave,
} from "@ionic/react";
import {
  pauseOutline,
  play as playIcon,
  stopOutline,
  trashOutline,
} from "ionicons/icons";
import styles from "./recording.module.scss";
import { CSSTransition } from "react-transition-group";
import slideTransition from "../../../../components/Modal/transitions/player.module.scss";
import webSlideTransition from "../../../../components/Modal/transitions/playerWeb.module.scss";

const Recording = ({
  recording,
  src,
  id,
  label,
  owner,
  playing,
  setPlaying,
  deleteMode,
  onDelete,
}) => {
  const recordingRef = useRef();
  const [duration, setDuration] = useState("");
  const isTouched = useRef(false);
  const [progress, setProgress] = useState({
    rangeTime: undefined,
    text: undefined,
    leftText: undefined,
    range: undefined,
  });
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const recording = new Audio(src);
    recording.addEventListener(
      "loadedmetadata",
      function () {
        let durationInSecond = parseInt(recording.duration);
        if (durationInSecond > 0 && durationInSecond < 10) {
          setDuration("00:0" + durationInSecond);
        } else if (durationInSecond >= 10 && durationInSecond < 60) {
          setDuration("00:" + durationInSecond);
        } else if (durationInSecond > 60) {
          let minutes = parseInt(durationInSecond / 60);
          let seconds = durationInSecond % 60;
          if (minutes >= 0 && minutes < 10) {
            minutes = "0" + minutes;
          }
          if (seconds >= 0 && seconds < 10) {
            seconds = "0" + seconds;
          }
          setDuration(minutes + ":" + seconds);
        }
        setIsLoading(false);
      },
      false
    );
  }, [src]);

  const pauseClickHandler = (e) => {
    try {
      e.stopPropagation();
      let audio = document.getElementById(`${id}`);
      recordingRef.current = audio;

      if (playing && playing.id === id && playing.status === "playing") {
        setPlaying({ id, status: "paused" });
        recordingRef.current.pause();
      } else {
        setPlaying({ id, status: "playing" });
        recordingRef.current.play();
      }

      recordingRef.current.onended = function () {
        setPlaying(undefined);
        recordingRef.current = undefined;
      };

      recordingRef.current.addEventListener("timeupdate", () => {
        if (!isTouched.current && recordingRef.current) {
          setProgress({
            rangeTime: Number(
              recordingRef.current.currentTime
                .toFixed(2)
                .toString()
                .substring(0, 5)
            ),
            text: sToTime(recordingRef.current.currentTime),
            leftText: sToTime(
              recordingRef.current.duration - recordingRef.current.currentTime
            ),
            range: recordingRef.current.currentTime,
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const padZero = (v) => (v < 10 ? "0" + v : v);

  const sToTime = (t) => {
    return (
      padZero(parseInt(String((t / 60) % 60))) +
      ":" +
      padZero(parseInt(String(t % 60)))
    );
  };

  const stopClickHandler = () => {
    try {
      let audio = document.getElementById(`${id}`);
      setPlaying({ id, status: "stopped" });
      audio.currentTime = 0;
      audio.pause();
      audio.currentTime = 0;
    } catch (err) {
      console.log(err);
    }
  };

  const onRangeChange = ({ detail: { value } }) => {
    if (isTouched.current) {
      setProgress({ ...progress, range: value });
      if (recordingRef.current) recordingRef.current.currentTime = value;
    }
  };

  const handleTouchStart = () => (isTouched.current = true);

  const handleTouchEnd = () => (isTouched.current = false);

  useEffect(() => {
    if (playing && playing.id !== id) {
      if (recordingRef.current) {
        recordingRef.current.currentTime = 0;
        recordingRef.current.pause();
        recordingRef.current = undefined;
      }
    }
    if (!playing && recordingRef.current) {
      recordingRef.current.currentTime = 0;
      recordingRef.current.pause();
      recordingRef.current = undefined;
    }
  }, [id, playing]);

  useEffect(() => {
    if (deleteMode) {
      setPlaying(undefined);
      if (recordingRef.current) recordingRef.current = undefined;
    }
  }, [deleteMode, setPlaying]);

  useIonViewWillLeave(() => {
    if (recordingRef.current) {
      setPlaying(undefined);
      recordingRef.current.pause();
      recordingRef.current = undefined;
    }
  });

  return (
    <>
      <CSSTransition
        in={playing && playing.id === id && recordingRef.current ? true : false}
        timeout={400}
        classNames={
          window.innerWidth < 960 ? slideTransition : webSlideTransition
        }
        unmountOnExit
      >
        <IonToolbar className={styles.player}>
          <IonItem lines="none">
            <IonLabel>
              <span className={styles.title}>
                <h2>{label}</h2>
              </span>
              <p>{owner}</p>
            </IonLabel>
          </IonItem>

          <IonItem lines="none" className={styles.rangeContainer}>
            <IonRange
              min={0}
              max={recordingRef.current ? recordingRef.current.duration : 1}
              disabled={true}
              value={progress.range}
            >
              <IonLabel slot="start" position="stacked">
                {progress.text}
              </IonLabel>
              <IonLabel slot="end" position="stacked">
                - {progress.leftText}
              </IonLabel>
            </IonRange>
          </IonItem>
        </IonToolbar>
      </CSSTransition>

      {!loading ? (
        <div
          className={`${styles.recording} ${
            playing && playing.id === id && styles.purpleBackground
          }`}
        >
          {!deleteMode ? (
            <div className={styles.playerButtons}>
              <span
                className={
                  playing && playing.id === id && playing.status === "playing"
                    ? styles.pauseIcon
                    : styles.playIcon
                }
                onClick={pauseClickHandler}
              >
                <IonIcon
                  icon={
                    playing && playing.id === id && playing.status === "playing"
                      ? pauseOutline
                      : playIcon
                  }
                />
              </span>
              {playing && playing.id === id ? (
                <span
                  className={styles.stopIcon}
                  onClick={() => {
                    stopClickHandler();
                    setPlaying(undefined);
                  }}
                >
                  <IonIcon icon={stopOutline} />
                </span>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <span
              className={styles.deleteIcon}
              onClick={() => onDelete(recording)}
            >
              <IonIcon icon={trashOutline} />
            </span>
          )}

          <span
            className={`${styles.name} ${
              playing && playing.id === id && styles.playingName
            }`}
          >
            {label}
          </span>

          <span
            className={`${styles.duration} ${
              playing && playing.id === id && styles.playingDuration
            }`}
          >
            {duration}
          </span>
        </div>
      ) : (
        <div className={styles.recording}>
          <IonSkeletonText animated style={{ flex: 1 }} />
        </div>
      )}
    </>
  );
};

export default Recording;
