import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IonInput, IonItem, IonLabel, IonLoading } from "@ionic/react";
import BreadCrumbs from "../../components/breadcrumbs";
import { updateToast } from "../../../../redux/ui/ui.actions";
import styles from "./amenities.module.scss";
import {
  addAmenity,
  getAllAmenities,
  removeAmenity,
  updateAmenities,
} from "../../../../amplify/graphql.utils";
import Button from "../../../../components/Form/Button/button.component";

const Amenities = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [amenities, setAmenities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateMode, setUpdateMode] = useState();
  const [data, setData] = useState({ name: "", description: "" });
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchAmenities = async () => {
      try {
        const items = await getAllAmenities();
        setAmenities(items);
      } catch (err) {
        dispatch(
          updateToast({
            open: true,
            type: "error",
            message: "Something went wrong!",
          })
        );
        history.replace("/admin");
      } finally {
        setLoading(false);
      }
    };

    fetchAmenities();
  }, [dispatch, history]);

  const renderAmenities = () => {
    return amenities.map((amenity) => (
      <div className={styles.item} key={amenity.id}>
        <div className={styles.col1}>
          <span className={styles.name}>
            {updateMode?.id === amenity.id ? (
              <IonInput
                placeholder="Amenity name*"
                clearInput
                required
                value={updateMode?.name}
                onIonChange={(e) =>
                  setUpdateMode({ ...updateMode, name: e.detail.value })
                }
                style={{ backgroundColor: "white", borderRadius: "6px" }}
              />
            ) : (
              amenity.name
            )}
          </span>
          <span className={styles.description}>
            {updateMode?.id === amenity.id ? (
              <IonInput
                placeholder="Amenity description"
                clearInput
                value={updateMode?.description}
                onIonChange={(e) =>
                  setUpdateMode({ ...updateMode, description: e.detail.value })
                }
                style={{ backgroundColor: "white", borderRadius: "6px" }}
              />
            ) : (
              <>
                <b>Description:</b>{" "}
                {amenity.description ? amenity.description : "---"}
              </>
            )}
          </span>
        </div>
        <div className={styles.col2}>
          <Button
            title={updateMode?.id === amenity.id ? "Confirm" : "Update"}
            disabled={
              updateMode?.id === amenity.id && !updateMode.name ? true : false
            }
            type="green"
            onClick={() => {
              if (updateMode && updateMode.id === amenity.id) {
                handleUpdate();
              } else {
                setUpdateMode(amenity);
              }
            }}
            style={{ marginRight: "2rem" }}
          />
          <Button
            title={updateMode?.id === amenity.id ? "Cancel" : "Remove"}
            type="orange"
            onClick={() => {
              if (updateMode?.id === amenity.id) {
                setUpdateMode(null);
              } else {
                handleRemove(amenity.id);
              }
            }}
          />
        </div>
      </div>
    ));
  };

  const validateForm = () => {
    return !data.name;
  };

  const handleSubmit = async () => {
    if (amenities.find((amenity) => amenity.name === data.name)) {
      setError("An amenity with this name already exists.");
      return;
    }
    try {
      setError("");
      setLoading(true);
      const amenity = await addAmenity(data);
      setAmenities([...amenities, amenity]);
      setData({ name: "", description: "" });
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = async (id) => {
    try {
      setLoading(true);
      await removeAmenity(id);
      setAmenities(amenities.filter((amenity) => amenity.id !== id));
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const updatedAmenity = await updateAmenities(updateMode);

      const filteredAmenities = amenities.filter(
        (amenity) => amenity.id !== updatedAmenity.id
      );

      setAmenities([...filteredAmenities, updatedAmenity]);
    } catch (err) {
      dispatch(
        updateToast({
          open: true,
          type: "error",
          message: "Something went wrong!",
        })
      );
    } finally {
      setUpdateMode(null);
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <BreadCrumbs type="amenities" />
      <IonLoading isOpen={loading} />
      <div className={styles.list}>{renderAmenities()}</div>
      <div className={styles.form}>
        <IonItem>
          <IonLabel position="fixed">Name*</IonLabel>
          <IonInput
            placeholder="Amenity name"
            clearInput
            required
            value={data.name}
            onIonChange={(e) => setData({ ...data, name: e.detail.value })}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="fixed">Description</IonLabel>
          <IonInput
            placeholder="Amenity description"
            clearInput
            value={data.description}
            onIonChange={(e) =>
              setData({ ...data, description: e.detail.value })
            }
          />
        </IonItem>
        <div className={styles.error}>{error}</div>
        <div className={styles.submit}>
          <Button
            title={"Create"}
            type={"green"}
            loading={loading}
            disabled={validateForm()}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default Amenities;
