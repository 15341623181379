import { store } from "../redux/store";
import { updateToast } from "../redux/ui/ui.actions";

enum ToastType {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

const showToast = (message: string, type: ToastType) => {
  store.dispatch(
    updateToast({
      open: true,
      message,
      type,
    })
  );
};

export const ToastService = {
  showToast,
  ToastType,
};
