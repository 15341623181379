import { UIActionTypes } from "./ui.types";

export const openAuthModal = (modal) => ({
  type: UIActionTypes.OPEN_AUTH_MODAL,
  payload: modal,
});

export const closeAuthModal = () => ({
  type: UIActionTypes.CLOSE_AUTH_MODAL,
});
export const openModal = (modal) => ({
  type: UIActionTypes.OPEN_MODAL,
  payload: modal,
});

export const closeModal = () => ({
  type: UIActionTypes.CLOSE_MODAL,
});

export const updateBadge = (badge) => ({
  type: UIActionTypes.UPDATE_BADGE,
  payload: badge,
});

export const updateNotification = (notification) => ({
  type: UIActionTypes.UPDATE_NOTIFICATION,
  payload: notification,
});

export const markNotification = (id) => ({
  type: UIActionTypes.MARK_NOTIFICATION,
  payload: id,
});

export const resetNotification = () => ({
  type: UIActionTypes.RESET_NOTIFICATION,
});

export const collapseMenu = () => ({
  type: UIActionTypes.COLLAPSE_MENU,
});

export const updateMenuTab = (tab) => ({
  type: UIActionTypes.UPDATE_MENU_TAB,
  payload: tab,
});

export const toggleFilters = (tab) => ({
  type: UIActionTypes.TOGGLE_FILTERS,
  payload: tab,
});

export const updateDrawMode = (status) => ({
  type: UIActionTypes.UPDATE_DRAW_MODE,
  payload: status,
});

export const updateToast = (toast) => ({
  type: UIActionTypes.UPDATE_TOAST,
  payload: toast,
});

export const updateChangedFavourite = (id) => ({
  type: UIActionTypes.UPDATE_CHANGED_FAVOURITE,
  payload: id,
});

export const fetchOfferStart = () => ({
  type: UIActionTypes.FETCH_OFFER_START,
});

export const fetchOfferSuccess = (offer) => ({
  type: UIActionTypes.FETCH_OFFER_SUCCESS,
  payload: offer,
});

export const fetchOfferFailure = (error) => ({
  type: UIActionTypes.FETCH_OFFER_FAILURE,
  payload: error,
});

export const fetchRateStart = () => ({
  type: UIActionTypes.FETCH_RATE_START,
});

export const fetchRateSuccess = (rate) => ({
  type: UIActionTypes.FETCH_RATE_SUCCESS,
  payload: rate,
});

export const fetchRateFailure = (error) => ({
  type: UIActionTypes.FETCH_RATE_FAILURE,
  payload: error,
});

export const federatedLoadingStart = () => ({
  type: UIActionTypes.FEDERATED_LOADING_START,
});

export const federatedLoadingEnd = () => ({
  type: UIActionTypes.FEDERATED_LOADING_END,
});

export const updateTab = (tab) => ({
  type: UIActionTypes.UPDATE_SELECTED_TAB,
  payload: tab,
});