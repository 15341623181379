import withFilters from "../../../../../HOC/withFilters/with-filters";
import styles from "./status-filter.module.scss";
import {
  IonList,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
} from "@ionic/react";
import moment from "moment";
import { useHistory } from "react-router-dom";

const StatusFilter = ({
  filters: { type, status, lastStatus },
  updateMultipleFilters,
  isAuthenticated,
}) => {
  const history = useHistory();
  const checkValue = () => {
    if (status === "A") return "active";
    if (type === "sale" && lastStatus === "Sld") return "inactive";
    if (type === "lease" && lastStatus === "Lsd") return "inactive";
    return "de-listed";
  };

  const handleOnChange = ({ detail: { value } }) => {
    if (!isAuthenticated && (value === "inactive" || value === "de-listed")) {
      history.push("/join", { direction: "none" });
    } else {
      updateMultipleFilters({
        status: value === "active" ? "A" : "U",
        lastStatus:
          value === "active"
            ? null
            : value === "inactive"
            ? type === "sale"
              ? "Sld"
              : "Lsd"
            : ["Sus", "Exp", "Ter"],
        displayPublic: value === "active" ? "Y" : null,
        displayAddressOnInternet: value === "active" ? "Y" : null,
        minListDate: null,
        maxSoldDate:
          value === "active" || value === "de-listed"
            ? null
            : moment(new Date()).format("YYYY-MM-DD"),
        minSoldDate:
          value === "active" || value === "de-listed"
            ? null
            : moment()
                .subtract(90, "days")
                .format("YYYY-MM-DD"),
        resultsPerPage: value === "de-listed" ? 250 : 2500,
        sortBy: value === "de-listed" ? "updatedOnDesc" : "createdOnDesc",
      });
    }
  };

  return (
    <IonList>
      <IonRadioGroup value={checkValue()} onIonChange={handleOnChange}>
        <IonItem lines="none">
          <IonLabel
            className={`${styles.label} ${checkValue() === "active" &&
              styles.selected}`}
          >
            Active
          </IonLabel>
          <IonRadio slot="end" value="active" className={styles.radio} />
        </IonItem>
        <IonItem lines="none">
          <IonLabel
            className={`${styles.label} ${checkValue() === "inactive" &&
              styles.selected}`}
          >
            {type === "sale" ? "Sold" : "Leased"}
          </IonLabel>
          <IonRadio slot="end" value="inactive" className={styles.radio} />
        </IonItem>
        <IonItem lines="none">
          <IonLabel
            className={`${styles.label} ${checkValue() === "de-listed" &&
              styles.selected}`}
          >
            De-listed
          </IonLabel>
          <IonRadio slot="end" value="de-listed" className={styles.radio} />
        </IonItem>
      </IonRadioGroup>
    </IonList>
  );
};

export default withFilters(StatusFilter);
