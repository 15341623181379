import { GraphQLQuery } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { ListShowingsQuery } from "../API";
import { listShowings } from "../graphql/queries";

const getShowings = async () => {
    const response = await API.graphql<GraphQLQuery<ListShowingsQuery>>({query: listShowings});
    return response.data?.listShowings?.items;
};

const getShowingsByDate = async (date: string) => {
    const response = await API.graphql<GraphQLQuery<ListShowingsQuery>>({query: listShowings, variables: {date}});
    return response.data?.listShowings?.items;
};

export const ShowingsService = {
    getShowings,
    getShowingsByDate,
};