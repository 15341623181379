import { useState, useEffect, memo, useRef } from "react";
import {
  IonContent,
  IonModal,
  IonButton,
  IonIcon,
  IonLabel,
  IonPopover,
} from "@ionic/react";
import {
  arrowForwardCircleOutline,
  chatbubblesOutline,
  chevronForwardOutline,
  navigateOutline,
  peopleOutline,
} from "ionicons/icons";
import styles from "./client-tour.module.scss";
import TourCard from "./component/TourCard/tour-card.component";
import TourElementContent from "./component/TourElementContent/tour-element-content.component";
import LoadingFullPage from "../../../components/Loading/loading-full-page.component";
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import {
  onCreateTourItemByTourId,
  onUpdateTourByTourId,
  onUpdateTourItemByTourId,
} from "../../../graphql/subscriptions";
import { getCurrentLocation } from "../../../components/Map/map-utils";
import {
  buildAddress,
  handleAppAddressNavigation,
} from "../../../utils/functions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUINotifications } from "../../../redux/ui/ui.selectors";
import { markNotification } from "../../../redux/ui/ui.actions";
import { TourItemService } from "../../../services/tourItemService";
import { TourService } from "../../../services/tourService";
import { ListingService } from "../../../services/listingService";

const AppClientTour = ({tourId}) => {
  const history = useHistory();
  const subscriptionRef = useRef([]);
  const dispatch = useDispatch();
  const [popover, setPopover] = useState({ show: false, event: undefined });
  const [loading, setLoading] = useState(true);
  const [tour, setTour] = useState();
  const [tourItems, setTourItems] = useState([]);
  const [type, setType] = useState("");
  const [showTourElements, setShowTourElements] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);
  const notifications = useSelector(selectUINotifications);

  useEffect(() => {
    const update = async (clientTour) => {
      await TourItemService.markTourAsSeen(clientTour);
      dispatch(markNotification(clientTour.id));
    };
    if (tour && notifications) {
      const isNotSeen = notifications.tours.find(
        (item) => item.id === tour.id
      );

      if (isNotSeen) {
        update(isNotSeen);
      }
    }
  }, [dispatch, notifications, tour]);

  useEffect(() => {
    const subscriptions = subscriptionRef.current;
    const fetchSingleItem = async (i) => {
      try {
        let item = {};
        item.tourItem = await TourItemService.getTourItemById(i.id);
        item.order = item.tourItem.order;
        item.listing = await ListingService.getListingByMlsNumber(i.mlsNumber);
        return item;
      } catch (err) {
        return;
      }
    };

    const fetchTourItems = async (tour, inBackground) => {
      const promises = [];
      for (let i = 0; i < tour.tourItems.items.length; i++) {
        promises.push(fetchSingleItem(tour.tourItems.items[i]));
      }
      const items = await Promise.all(promises);
      subscribeItems();
      setTourItems(items.sort(compareItems));

      !inBackground && setLoading(false);
    };

    const fetchTour = async (inBackground) => {
      subscriptions.forEach((s) => s.unsubscribe());
      !inBackground && setLoading(true);
      const t = await TourService.getTourById(tourId);

      if (!t) {
        history.replace("/tours", { direction: "root" });
        return;
      }
      if (t.shared === "unshared") {
        history.replace("/tours", { direction: "root" });
        return;
      }
      setTour(t);

      const updateSubscriptions = await API.graphql(
        graphqlOperation(onUpdateTourByTourId, { id: t.id })
      ).subscribe({ next: async (val) => await fetchTour(true) });
      subscriptionRef.current.push(updateSubscriptions);
      const createSubscription = await API.graphql(
        graphqlOperation(onCreateTourItemByTourId, { tourId: t.id })
      ).subscribe({ next: async (val) => await fetchTour(true) });
      subscriptionRef.current.push(createSubscription);

      await fetchTourItems(t, inBackground);
    };

    const subscribeItems = async () => {
      const itemSubscribe = await API.graphql(
        graphqlOperation(onUpdateTourItemByTourId, { tourId })
      ).subscribe({ next: (val) => fetchTour(true) });
      subscriptionRef.current.push(itemSubscribe);
    };
    const subscribe = async () => {
      const s = await API.graphql(
        graphqlOperation(onUpdateTourByTourId, { id: tourId })
      ).subscribe({ next: (val) => fetchTour(true) });
      subscriptionRef.current.push(s);
    };

    subscribe();
    fetchTour();

    return () => {
      subscriptions.forEach((s) => s.unsubscribe());
    };
  }, [history, tourId]);

  const compareItems = (a, b) => a.order - b.order;

  const handleMapView = async () => {
    setMapLoading(true);
    if (tourItems.length) {
      const currentLocation = await getCurrentLocation();
      let locations = "";
      if (currentLocation)
        locations += `${currentLocation.latitude},${currentLocation.longitude}/`;

      if (tour.meetupLocation) locations += `${tour.meetupLocation}/`;
      tourItems.forEach((item) => {
        if (
          !["skipped", "cancelled", "completed", "rejected"].includes(
            item.listing.status
          )
        )
          locations += `${buildAddress(item.listing.address, true)}/`;
      });

      window.open(
        `https://www.google.com/maps/dir/${locations.slice(
          0,
          locations.length
        )}&dirflg=d,t`,
        "_blank"
      );
    }
    setMapLoading(false);
  };

  return (
      <IonContent className={styles.clientTour}>
        <IonModal
          swipeToClose={true}
          isOpen={showTourElements}
          onDidDismiss={() => setShowTourElements(false)}>
          <TourElementContent
            setIsOpen={setShowTourElements}
            title="title"
            type={type}
            tour={tour}
          />
        </IonModal>

        {loading ? (
          <LoadingFullPage />
        ) : (
          <>
            <div
              className={`${styles.container} ${
                !tourItems.length && styles.containerFullHeight
              }`}>
              <div className={styles.header}>
                Review your tour details, map out your full route, or access
                individual showings!
              </div>
              <div className={styles.content}>
                <div className={styles.details}>
                  <div className={styles.title}>Tour details</div>
                  <div className={styles.item}>
                    <span className={styles.label}>Name</span>
                    <span className={styles.value}>
                      <span className={styles.text}>
                        {tour.title ? tour.title : "---"}
                      </span>
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span className={styles.label}>Date</span>
                    <span className={styles.value}>
                      {tour.date
                        ? moment(tour.date).format("MMM Do YYYY")
                        : "---"}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span className={styles.label}>Time</span>
                    <span className={styles.value}>
                      {`${
                        tour.startTime
                          ? moment(tour.startTime, "HH:mm").format("h:mm A")
                          : "-"
                      } - ${
                        tour.endTime
                          ? moment(tour.endTime, "HH:mm").format("h:mm A")
                          : "-"
                      }`}
                    </span>
                  </div>
                </div>
                <div className={styles.details}>
                  <div className={styles.title}>Meetup details</div>
                  <div className={styles.item}>
                    <span className={styles.label}>Address</span>
                    <span
                      className={styles.value}
                      onClick={() =>
                        handleAppAddressNavigation(tour.meetupLocation)
                      }>
                      <span className={styles.text}>
                        {tour.meetupLocation ? (
                          <u>{tour.meetupLocation}</u>
                        ) : (
                          "---"
                        )}
                      </span>
                      {tour.meetupLocation && (
                        <IonIcon
                          icon={arrowForwardCircleOutline}
                          className={styles.icon}
                        />
                      )}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span className={styles.label}>Time</span>
                    <span className={styles.value}>
                      {tour.meetupTime
                        ? moment(tour.meetupTime, "HH:mm").format("h:mm A")
                        : "---"}
                    </span>
                  </div>
                  <div className={styles.item}>
                    <IonPopover
                      event={popover.event}
                      isOpen={popover.show}
                      onDidDismiss={() =>
                        setPopover({ show: false, event: undefined })
                      }
                      cssClass={styles.popover}>
                      <p>{tour.meetupDescription}</p>
                    </IonPopover>
                    <span className={styles.label}>Description</span>
                    <span className={styles.value}>
                      <span
                        className={styles.text}
                        onClick={
                          tour.meetupDescription
                            ? (e) => setPopover({ show: true, event: e })
                            : null
                        }>
                        {tour.meetupDescription
                          ? tour.meetupDescription
                          : "---"}
                      </span>
                    </span>
                  </div>
                </div>
                <div className={styles.details}>
                  <div className={styles.title}>Note from agent</div>
                  <div className={styles.note}>
                    {tour.generalNote ? tour.generalNote : "---"}
                  </div>
                </div>
                <div className={styles.details}>
                  <div className={styles.title}>More details</div>
                  <div
                    className={styles.btn}
                    onClick={() => {
                      setType("tourAttendees");
                      setShowTourElements(true);
                    }}>
                    <div className={styles.btnLabel}>
                      <IonIcon
                        icon={peopleOutline}
                        className={styles.btnIcon}
                      />
                      <span className={styles.btnText}>Tour attendees</span>
                    </div>
                    <IonIcon
                      icon={chevronForwardOutline}
                      className={styles.btnChevron}
                    />
                  </div>
                  <div
                    className={styles.btn}
                    onClick={() => {
                      setType("quickContact");
                      setShowTourElements(true);
                    }}>
                    <div className={styles.btnLabel}>
                      <IonIcon
                        icon={chatbubblesOutline}
                        className={styles.btnIcon}
                      />
                      <span className={styles.btnText}>Quick contact</span>
                    </div>
                    <IonIcon
                      icon={chevronForwardOutline}
                      className={styles.btnChevron}
                    />
                  </div>
                </div>
                <IonButton
                  className={styles.navBtn}
                  onClick={handleMapView}
                  disabled={!tourItems || !tourItems.length}>
                  <IonIcon icon={navigateOutline} className={styles.icon} />
                  <IonLabel className={styles.label}>
                    {mapLoading ? "loading..." : "Full directions"}
                  </IonLabel>
                </IonButton>
              </div>
            </div>

            {tourItems.length > 0 &&
              tourItems.map((item) => {
                if (item)
                  return (
                    <TourCard
                      key={item.tourItem.id}
                      tourItem={item.tourItem}
                      listing={item.listing}
                    />
                  );
              })}
          </>
        )}
      </IonContent>
  );
};

export default memo(AppClientTour);
