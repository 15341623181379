import { cloneElement } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openModal, updateMenuTab } from "../../../../redux/ui/ui.actions";
import {
  selectIsFiltersOpen,
  selectUIMenuTab,
} from "../../../../redux/ui/ui.selectors";
import { selectCurrentUser } from "../../../../redux/user/user.selectors";
import styles from "./nav-button.module.scss";

const NavButton = ({
  label,
  tab,
  target,
  onClick,
  children,
  collapse,
  updates,
  isProtected,
  dimmed,
  isFilter,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const selectedTab = useSelector(selectUIMenuTab);
  const isFiltersOpen = useSelector(selectIsFiltersOpen);

  const updateTab = () => {
    dispatch(updateMenuTab(tab));
  };

  const renderLink = () => (
    <div
      className={`${styles.button} ${
        tab === selectedTab && !dimmed ? styles.active : ""
      } ${collapse && !dimmed && styles.hovered} ${
        !dimmed && collapse && tab === selectedTab && styles.selectedHovered
      } ${dimmed && styles.dimmed}`}>
      {updates && updates.length > 0 && <span className={styles.updates} />}
      {cloneElement(children, { className: styles.icon })}
      <span className={styles.label} style={{ display: collapse && "none" }}>
        {label}
      </span>
    </div>
  );

  return target ? (
    isProtected ? (
      user ? (
        <Link
          to={target}
          onClick={(e) => (!dimmed ? updateTab() : e.preventDefault())}
          id={`${label}-btn`}>
          {renderLink()}
        </Link>
      ) : (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => !dimmed && dispatch(openModal({ current: "join" }))}
          id={`${label}-btn`}>
          {renderLink()}
        </div>
      )
    ) : (
      <Link
        to={target}
        onClick={(e) => (!dimmed ? updateTab() : e.preventDefault())}
        id={`${label}-btn`}>
        {renderLink()}
      </Link>
    )
  ) : (
    <div
      onClick={() => !dimmed && onClick()}
      style={{ cursor: "pointer" }}
      id={`${label}-btn`}>
      <div
        className={`${styles.button} ${
          tab === selectedTab || (isFilter && isFiltersOpen)
            ? styles.active
            : ""
        }`}>
        {cloneElement(children, { className: styles.icon })}
        {!collapse && <span className={styles.label}>{label}</span>}
      </div>
    </div>
  );
};

export default NavButton;
