import styles from "./filters.module.scss";
import { memo, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  IonHeader,
  IonContent,
  IonLabel,
  IonToolbar,
  IonButton,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonRouterOutlet,
  IonItem,
  IonList,
  IonSegment,
  IonSegmentButton,
  IonModal,
} from "@ionic/react";
import { Route } from "react-router-dom";
import { ReactComponent as SaveSearchIcon } from "../../../assets/svg/search.svg";
import withFilters from "../../../HOC/withFilters/with-filters";
import PriceFilter from "./components/PriceFilter/price-filter.component";
import BedsFilter from "./components/BedsFilter/beds-filter.component";
import BathsFilter from "./components/BathsFilter/baths-filter.component";
import ParkingsFilter from "./components/ParkingsFilter/parkings-filter.component";
import PurposeFilter from "./components/PurposeFilter/purpose-filter.component";
import AnimatedTab from "./components/AnimatedTab/animated-tab.component";
import TypeFilter from "./components/TypeFilter/type-filter.component";
import KeywordsFilter from "./components/KeywordsFilter/keywords-filter.component";
import StatusFilter from "./components/StatusFilter/status-filter.component";
import AreaPage from "./pages/Area/area.component";
import CityPage from "./pages/City/city.component";
import NeighbourhoodPage from "./pages/Neighbourhood/neighbourhood.component";
import withMap from "../../../HOC/withMap/with-map";
import SQFTFilter from "./components/SQFTFilter/sqft-filter.componetn";
import MaintenanceFilter from "./components/MaintenanceFilter/maintenance-filter.component";
import LotWidthFilter from "./components/LotWidthFilter/lot-width-filter.component";
import LotDepthFilter from "./components/LotDepthFilter/lot-depth-filter.component";
import BasementFilter from "./components/BasementFilter/basement-filter.component";
import LockerFilter from "./components/LockerFilter/locker-filter.component";
import DateFilter from "./components/DateFilter/date-filter.component";
import _ from "lodash";
import {
  MAINTENANCE_TYPES,
  LOT_TYPES,
  BASEMENT_TYPES,
  LOCKER_TYPES,
} from "./types";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import Button from "../../../components/Form/Button/button.component";
import {
  renderAppTag,
  renderLocationTag,
} from "../../../components/FiltersMenu/utils";
import { capitalize } from "../../../utils/functions";
import SaveSearchModal from "../../../components/Map/components/MapControls/components/SaveSearchModal/save-search-modal.component";
import withClient from "../../../HOC/withClient/with-client";
import { selectDrawMode } from "../../../redux/ui/ui.selectors";
import { useHistory } from "react-router-dom";
import { selectIsPreConstruction } from "../../../redux/filters/filters.selectors";

const AppFiltersModal = ({
  filters,
  filters: {
    status,
    lastStatus,
    type,
    propertyType,
    minBeds,
    maxBeds,
    minBaths,
    maxBaths,
    minSqft,
    maxSqft,
    minPrice,
    maxPrice,
    area,
    city,
    neighborhood,
  },
  updateSingleFilter,
  extraFilters,
  resetFilters,
  activeFilters,
  updateMapLocations,
  currentClient,
  clientLoading,
  clientError,
  close,
  openSearchModal
}) => {
  const ref = useRef();
  const history = useHistory();
  const location = useLocation();
  const isAuthenticated = useSelector(selectCurrentUser);
  const [selected, setSelected] = useState();
  const [modal, setModal] = useState(false);
  const drawMode = useSelector(selectDrawMode);
  const isPreConstruction = useSelector(selectIsPreConstruction);

  const updateSelected = (opt) =>
    selected === opt ? setSelected(null) : setSelected(opt);

  const handleTypeChange = ({ detail: { value } }) => {
    updateSingleFilter({
      type: value,
      lastStatus: status === "A" ? null : value === "sale" ? "Sld" : "Lsd",
      minPrice: "0",
      maxPrice: "max",
    });
    setSelected(null);
  };

  const handleReset = () => {
    resetFilters();
    updateMapLocations({ reset: true });
    setSelected(null);
  };

  const renderTitle = () => {
    if (location.pathname === "/filters") return "More filters";
    if (location.pathname === "/filters/areas") return "Neighbourhoods";

    const title = location.pathname.split("/");
    if (title[3]) return title[3];
    return "More filters";
  };

  const show = (validTypes) => {
    if (!propertyType) return false;
    const intersections = _.intersection(validTypes, propertyType).length;
    if (intersections === propertyType.length) return true;
    return false;
  };

  const isActive = (types) => activeFilters.some((f) => types.indexOf(f) >= 0);

  const renderFilterTag = (type) => renderAppTag(type, filters, extraFilters);

  return (
    <>
      <IonHeader>
        <IonToolbar className={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton slot="start" className={styles.btn} onClick={close}>
              <IonLabel className={styles.label}>Close</IonLabel>
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton slot="end" className={styles.btn} onClick={handleReset}>
              <IonLabel className={styles.label}>Reset</IonLabel>
            </IonButton>
          </IonButtons>
          <IonTitle className={styles.title}>{renderTitle()}</IonTitle>
        </IonToolbar>
      </IonHeader>

      {/* <IonModal
        isOpen={modal}
        onDidDismiss={() => setModal(false)}
        presentingElement={ref.current}>
        <SaveSearchModal
          close={() => setModal(false)}
          drawMode={drawMode}
          filters={filters}
          extraFilters={extraFilters}
          client={currentClient}
          clientError={clientError}
          clientLoading={clientLoading}
        />
      </IonModal> */}

      <IonContent className={`${styles.content} ${styles.filtersContent}`}>
        <div className={styles.segments}>
          <IonSegment
            id="segment"
            value={type}
            onIonChange={handleTypeChange}
            className={styles.segment}>
            <IonSegmentButton value="sale">
              <IonLabel>{status === "U" ? "Sold" : "For Sale"}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="lease">
              <IonLabel>{status === "U" ? "Leased" : "For Lease"}</IonLabel>
            </IonSegmentButton>
            {/* <IonSegmentButton
                    value="pre-construction"
                    onClick={() => {
                      history.push("/listings");
                    }}>
                    <IonLabel>Pre-const</IonLabel>
                  </IonSegmentButton> */}
          </IonSegment>
        </div>

        <IonList>
          {/* {!isPreConstruction && (
            <>
              <IonItem
                routerDirection="forward"
                routerLink="/filters/areas"
                lines="full"
                detail={true}
                className={styles.item}>
                <IonLabel slot="start" className={styles.label}>
                  Neighbourhoods
                </IonLabel>
                <IonLabel slot="end" className={styles.labelTag}>
                  {renderLocationTag({ neighborhood, city, area })}
                </IonLabel>
              </IonItem>
             
          )} */}

          <AnimatedTab
            title="Property type"
            visible={selected === "type"}
            onClick={() => updateSelected("type")}
            active={isActive(["propertyType"])}
            tag={
              isActive(["propertyType"]) && capitalize(renderFilterTag("type"))
            }>
            <TypeFilter />
          </AnimatedTab>

          <AnimatedTab
            title="Price"
            visible={selected === "price"}
            onClick={() => updateSelected("price")}
            active={
              isActive([
                "minPrice",
                "maxPrice",
                "minSoldPrice",
                "maxSoldPrice",
              ]) &&
              !((minPrice === 0 || minPrice === "0") && maxPrice === "max")
            }
            tag={renderFilterTag("price")}>
            <PriceFilter />
          </AnimatedTab>
          <AnimatedTab
            title="Beds"
            visible={selected === "beds"}
            onClick={() => updateSelected("beds")}
            active={
              isActive(["minBeds", "maxBeds"]) &&
              !(minBeds === 0 && maxBeds === "any")
            }
            tag={renderFilterTag("beds")}>
            <BedsFilter />
          </AnimatedTab>
          <AnimatedTab
            title="Baths"
            visible={selected === "baths"}
            onClick={() => updateSelected("baths")}
            active={
              isActive(["minBaths", "maxBaths"]) &&
              !(minBaths === 0 && maxBaths === "any")
            }
            tag={renderFilterTag("baths")}>
            <BathsFilter />
          </AnimatedTab>
          {!isPreConstruction && (
            <AnimatedTab
              title="Parking"
              visible={selected === "parkings"}
              onClick={() => updateSelected("parkings")}
              active={isActive(["minParkingSpaces"])}
              tag={renderFilterTag("parkings")}>
              <ParkingsFilter />
            </AnimatedTab>
          )}

          <AnimatedTab
            title="Square footage"
            visible={selected === "sqft"}
            onClick={() => updateSelected("sqft")}
            active={
              isActive(["minSqft", "maxSqft"]) &&
              !(minSqft === 0 && maxSqft === "max")
            }
            tag={renderFilterTag("sqft")}>
            <SQFTFilter />
          </AnimatedTab>
          {!isPreConstruction && (
            <>
              <AnimatedTab
                title="Purpose"
                visible={selected === "purpose"}
                onClick={() => updateSelected("purpose")}
                active={isActive(["class"])}
                tag={capitalize(renderFilterTag("purpose"))}>
                <PurposeFilter />
              </AnimatedTab>
              <AnimatedTab
                title="Status"
                visible={selected === "status"}
                onClick={() => updateSelected("status")}
                active={isActive(["status"])}
                tag={renderFilterTag("status")}>
                <StatusFilter isAuthenticated={isAuthenticated} />
              </AnimatedTab>
              <AnimatedTab
                title={
                  status === "A"
                    ? "Days on market"
                    : lastStatus === "Sld"
                    ? "Sold in"
                    : lastStatus === "Lsd"
                    ? "Leased in"
                    : !lastStatus
                    ? "Days on market"
                    : "Days on market"
                }
                visible={selected === "date"}
                onClick={() => updateSelected("date")}
                active={isActive(["minSoldDate", "minListDate"])}
                tag={renderFilterTag("date")}
                disabled={lastStatus && lastStatus.includes("Ter")}>
                <DateFilter />
              </AnimatedTab>
              {show(MAINTENANCE_TYPES) && (
                <AnimatedTab
                  title="Max maintenance fee"
                  visible={selected === "maintenance"}
                  onClick={() => updateSelected("maintenance")}
                  active={extraFilters.maintenance}
                  tag={renderFilterTag("maintenance")}
                  type="maintenance">
                  <MaintenanceFilter />
                </AnimatedTab>
              )}
              {show(LOT_TYPES) && (
                <AnimatedTab
                  title="Lot width"
                  visible={selected === "width"}
                  onClick={() => updateSelected("width")}
                  active={
                    extraFilters.lotWidth.min || extraFilters.lotWidth.max
                  }
                  tag={renderFilterTag("lotWidth")}>
                  <LotWidthFilter />
                </AnimatedTab>
              )}
              {show(LOT_TYPES) && (
                <AnimatedTab
                  title="Lot depth"
                  visible={selected === "depth"}
                  onClick={() => updateSelected("depth")}
                  active={
                    extraFilters.lotDepth.min || extraFilters.lotDepth.max
                  }
                  tag={renderFilterTag("lotDepth")}>
                  <LotDepthFilter />
                </AnimatedTab>
              )}
              {!show(BASEMENT_TYPES) && propertyType && (
                <AnimatedTab
                  title="Basement"
                  visible={selected === "basement"}
                  onClick={() => updateSelected("basement")}
                  active={!extraFilters.basement.any}
                  tag={
                    !extraFilters.basement.any &&
                    capitalize(renderFilterTag("basement"))
                  }>
                  <BasementFilter />
                </AnimatedTab>
              )}
              {show(LOCKER_TYPES) && propertyType && (
                <AnimatedTab
                  title="Locker"
                  visible={selected === "locker"}
                  onClick={() => updateSelected("locker")}
                  active={extraFilters.locker}
                  tag={
                    extraFilters.locker && capitalize(renderFilterTag("locker"))
                  }>
                  <LockerFilter />
                </AnimatedTab>
              )}
            </>
          )}

          <KeywordsFilter />
        </IonList>

        <div className={styles.resultsBtnContainer}>
          <Button
            style={{
              flex: 1,
              width: "85%",
              margin: "0 auto",
              zIndex: "100",
            }}
            shape="block"
            title="Save search"
            type="gray"
            border
            onClick={() => {
              if (currentClient) {
                openSearchModal()
              } else {
                close();
                history.push("/join", { direction: "none " });
              }
            }
            }
            disabled={filters.status === "U"}>
            <SaveSearchIcon />
          </Button>

          <Button
            style={{
              flex: 1,
              width: "85%",
              margin: "0 auto",
              zIndex: "100",
            }}
            shape="block"
            title='See Results'
            type="green"
            border
            onClick={() => close()}
          />
        </div>

        {/* <IonRouterOutlet>
          <Route exact path="/filters">
         
          </Route>
          <Route exact path="/filters/areas">
            <IonContent>
              <AreaPage />
            </IonContent>
          </Route>
          <Route path="/filters/areas/:area">
            <IonContent>
              <CityPage />
            </IonContent>
          </Route>
          <Route path="/filters/cities/:city">
            <IonContent>
              <NeighbourhoodPage />
            </IonContent>
          </Route>
        </IonRouterOutlet> */}
      </IonContent>
    </>
  );
};

export default memo(
  withClient(withMap(withFilters(AppFiltersModal)))
);
