import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
} from "@ionic/react";
import styles from "./tour-elements-content.module.scss";
import { chevronBackOutline } from "ionicons/icons";
import TourDetails from "./component/TourDetails/tour-details.component";
import TourAttendees from "./component/TourAttendees/tour-attendees.component";
import AgentDetails from "./component/AgentDetails/agent-details.component";
import QuickContact from "./component/QuickContact/quick-contact.component";

const TourElementsContent = ({
  tour,
  close,
  type,
  updateDetails,
  updateUsers,
  archive,
  cancel
}) => {
  return (
    <>
      <IonHeader className={styles.appHeader}>
        <IonToolbar className={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              className={styles.backButton}
              slot="start"
              text="Back"
              onClick={close}
            >
              <IonIcon icon={chevronBackOutline} />
              Back
            </IonButton>
          </IonButtons>
          <IonTitle className={styles.title}>
            {type === "tourDetails" && "Tour details"}
            {type === "tourAttendees" && "Tour attendees"}
            {type === "agentDetails" && "Agent details"}
            {type === "quickContact" && "Quick contact"}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.tourElementsContent}>
        {type === "tourDetails" && (
          <TourDetails
            tour={tour}
            update={updateDetails}
          />
        )}
        {type === "tourAttendees" && (
          <TourAttendees
            tour={tour}
            update={updateUsers}
          />
        )}
        {type === "agentDetails" && (
          <AgentDetails tour={tour} update={updateDetails} archive={archive} cancel={cancel}/>
        )}
        {type === "quickContact" && (
          <QuickContact contacts={tour.users.items} />
        )}
      </IonContent>
    </>
  );
};

export default TourElementsContent;
