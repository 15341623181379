import { useState, useEffect } from "react";
import styles from "./loading.module.scss";
import Button from "../Form/Button/button.component";
import { useHistory } from "react-router-dom";
import { IonSpinner, useIonRouter } from "@ionic/react";
import LogoWithText from "../Logo/LogoWithText";

const LoadingFullPage = ({ details, onReload, style }) => {
  const [showBtn, setShowBtn] = useState(false);
  const history = useHistory();
  const router = useIonRouter();

  useEffect(() => {
    const to = setTimeout(() => {
      setShowBtn(true);
    }, 10000);

    return () => {
      if (to) clearTimeout(to);
    };
  }, []);

  const handleReload = () => {
    if (onReload) {
      onReload();
    } else {
      history.go(0);
    }
  };

  return (
    <div
      className={`${styles.fullPage} ${details && styles.details}`}
      style={style}>
      <LogoWithText dark />
      <IonSpinner className={styles.spinner} name="crescent" />
      {showBtn && (
        <>
        {router.canGoBack() && <Button
            style={{ marginTop: "5rem" }}
            type="green"
            border
            title="Back"
            onClick={() =>
              router.goBack()
            }
          />}
          
          <div className={styles.msg}>
            Loading seems to be taking longer than usual.
            <br />
            <div onClick={handleReload} className={styles.reload}>
              Reload
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LoadingFullPage;
