import { useState, useEffect } from "react";
import moment from "moment";
import styles from "./pre-left-section.module.scss";
import DetailPageCard from "../../../Detail/Sections/LeftSection/components/DetailPageCard/detail-page-card.component";
import noImage from "../../../../assets/img/no-photo.png";
import { handleAppNavigation } from "../../../../utils/functions";
import { openModal } from "../../../../redux/ui/ui.actions";
import PreConCardHeader from "./components/PreConCardHeader/pre-con-card-header.component";
import PreConCarousel from "./components/PreConCarousel/pre-con-carousel.component";
import PreConButtons from "./components/PreConButtons/pre-con-buttons.component";
import PreConDescription from "./components/PreConDescription/pre-con-description.component";
import PreConInformation from "./components/PreConInformation/pre-con-information.component";

const PreLeftSection = ({ project }) => {
  const [mapView, setMapView] = useState(false);

  const [preMap, setPreMap] = useState({
    latitude: project.latitude,
    point: `POINT (${project.longitude} ${project.latitude})`,
    longitude: project.longitude,
  });

  const [preAddress, setPreAddress] = useState({
    area: project.city,
    zip: null,
    country: "Canada",
    city: project.city,
    streetNumber: project.streetNumber,
    unitNumber: null,
    streetDirection: null,
    streetName: project.streetName,
    district: null,
    streetSuffix: null,
    neighborhood: null,
    state: project.province,
    majorIntersection: null,
    communityCode: null,
  });

  const [mediaObj, setMediaObj] = useState(JSON.parse(project.media));
  const [imagesObj, setImagesObj] = useState(
    mediaObj.filter((obj) => {
      return obj.type === "image";
    })
  );
  const [videosObj, setVideosObj] = useState(
    mediaObj.filter((obj) => {
      return obj.type === "video";
    })
  );

  const updateProjectDetails = () => {
    setPreMap({
      latitude: project.latitude,
      point: `POINT (${project.longitude} ${project.latitude})`,
      longitude: project.longitude,
    });
    setPreAddress({
      area: project.city,
      zip: null,
      country: "Canada",
      city: project.city,
      streetNumber: project.streetNumber,
      unitNumber: null,
      streetDirection: null,
      streetName: project.streetName,
      district: null,
      streetSuffix: null,
      neighborhood: null,
      state: project.province,
      majorIntersection: null,
      communityCode: null,
    });
    setMediaObj(JSON.parse(project.media));
  };

  const updateProjectMedia = () => {
    setImagesObj(
      mediaObj.filter((obj) => {
        return obj.type === "image";
      })
    );
    setVideosObj(
      mediaObj.filter((obj) => {
        return obj.type === "video";
      })
    );
  };

  useEffect(() => {
    updateProjectDetails();
  }, [project.id]);

  useEffect(() => {
    updateProjectMedia();
  }, [project.id, mediaObj]);

  const images =
    imagesObj && imagesObj.length > 0
      ? imagesObj.map((image) => {
          return {
            src: image.value,
          };
        })
      : [{ src: noImage }];

  const MediaHandler = () => {
    let newWindow;
    videosObj.length > 0 &&
      (newWindow = window.open(
        videosObj[0].value,
        "_blank",
        "noopener,noreferrer"
      ));
    if (newWindow) newWindow.opener = null;
  };

  const navigateHandler = () => {
    handleAppNavigation({
      map: preMap,
      address: preAddress,
      byAddress: true,
    });
  };

  const mapViewHandler = () => {
    setMapView(!mapView);
  };

  return (
    <div className={styles.container}>
      <DetailPageCard>
        <PreConCardHeader project={project} />
        <PreConCarousel images={images} location={preMap} showMap={mapView} />
        <PreConButtons
          project={project}
          MediaHandler={MediaHandler}
          hasMedia={videosObj.length > 0 ? true : false}
          mapViewHandler={mapViewHandler}
          showMap={mapView}
          navigateHandler={navigateHandler}
        />
        <PreConDescription project={project} />
        <PreConInformation
          project={project}
          // user={user}
        />

        <div className={styles.update}>
          <span>Last updated on AECORN:</span>{" "}
          {moment(project.updatedAt).format("LL")}
        </div>
      </DetailPageCard>
    </div>
  );
};

export default PreLeftSection;
