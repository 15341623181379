export const validURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const validLatLong = (coords) => {
  //validate latitude or longitude
  return /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/.exec(coords);
};

export const validYouTubeUrl = (str) => {
  return /^(https?\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/.exec(str);
};

export const DEFAULT_PROJECT_STATE = {
  id: undefined,
  isSoldOut: false,
  name: "",
  thumbnail: "",
  occupancy: "",
  address: {
    streetNumber: null,
    streetName: null,
    city: null,
    province: null,
    latitude: null,
    longitude: null,
  },
  price: {
    start: null,
    end: null,
    avg: null,
    parking: null,
    locker: null,
    maintenance: null,
  },
  beds: {
    min: null,
    max: null,
  },
  sqft: {
    min: null,
    max: null,
  },
  description: "",
  incentives: [],
  deposit: [],
  amenities: [],
  types: {
    condo: false,
    townhouse: false,
    single: false,
  },
  media: [],
  phase: "",
  availability: "",
  units: null,
  stories: null,
  builder: null,
  architect: null,
  designer: null,
};

export const DEFAULT_UNIT_STATE = {
  project: null,
  beds: null,
  baths: null,
  parkings: null,
  lockers: null,
  sqft: null,
  price: null,
  pricePerSqft: null,
  available: null,
  sold: null,
  type: "",
  media: [],
};

export const validateNewProjectForm = (data) => {
  const tempErrors = [];
  if (data.thubmnail && !validURL(data.thubmnail))
    tempErrors.push("Invalid thumbnail URL.");
  if (!data.name) tempErrors.push("Name is required.");
  if (!data.builder) tempErrors.push("Builder is required.");
  if (!data.address.province) tempErrors.push("Province is required.");
  if (!data.address.city) tempErrors.push("City is required.");
  if (!data.address.streetName) tempErrors.push("Street name is required.");
  if (!data.address.streetNumber) tempErrors.push("Street number is required.");
  if (!data.address.latitude) tempErrors.push("Latitude is required.");
  if (!data.address.longitude) tempErrors.push("Longitude is required.");
  if (data.units && data.units < 1) tempErrors.push("Invalid units value.");
  if (data.stories && data.stories < 1)
    tempErrors.push("Invalid stories value.");
  if (data.address.latitude && !validLatLong(data.address.latitude))
    tempErrors.push("Invalid latitude value.");
  if (data.address.longitude && !validLatLong(data.address.longitude))
    tempErrors.push("Invalid longitude value.");
  if (data.price.start && data.price.start < 0)
    tempErrors.push("Invalid start price value.");
  if (data.price.end && data.price.end < 1)
    tempErrors.push("Invalid end price value.");
  if (data.price.avg && data.price.avg < 1)
    tempErrors.push("Invalid average price value.");
  if (data.price.parking && data.price.parking < 1)
    tempErrors.push("Invalid parking price value.");
  if (data.price.locker && data.price.locker < 1)
    tempErrors.push("Invalid locker price value.");
  if (data.price.maintenance && data.price.maintenance < 1)
    tempErrors.push("Invalid maintenance fee value.");
  if (data.beds.min && data.beds.min < 0)
    tempErrors.push("Invalid min beds value.");
  if (data.beds.max && data.beds.max < 0)
    tempErrors.push("Invalid max beds value.");
  if (data.beds.min && data.beds.max && data.beds.min > data.beds.max)
    tempErrors.push("Min beds cannot be bigger than max beds.");
  if (data.sqft.min && data.sqft.min < 0)
    tempErrors.push("Invalid min sqft value.");
  if (data.sqft.max && data.sqft.max < 0)
    tempErrors.push("Invalid max sqft value.");
  if (
    data.sqft.min &&
    data.sqft.max &&
    parseInt(data.sqft.min) > parseInt(data.sqft.max)
  )
    tempErrors.push("Min sqft cannot be bigger than max sqft.");
  if (!data.phase) tempErrors.push("Phase is required.");
  if (!data.availability) tempErrors.push("Availability is required.");
  data.incentives.forEach((incentive, i) => {
    if (!incentive.value)
      tempErrors.push(`Incentive (#${i + 1}) value cannot be empty.`);
  });
  data.deposit.forEach((d, i) => {
    if (!d.value) tempErrors.push(`Deposit (#${i + 1}) value cannot be empty.`);
  });
  data.media.forEach((m, i) => {
    if (!m.value) {
      tempErrors.push(`Media (#${i + 1}) value cannot be empty.`);
    } else {
      // if (m.type === "video" && !validYouTubeUrl(m.value)) {
      // tempErrors.push(`Invalid YouTube URL (#${i + 1}).`);
      // }
      if (m.type === "image" && !validURL(m.value)) {
        tempErrors.push(`Invalid media URL (#${i + 1}).`);
      }
    }
  });

  return tempErrors;
};

export const validateNewUnitForm = (data) => {
  const { project } = data;

  const tempErrors = [];
  if (!project) {
    tempErrors.push(`Project is required.`);
    return tempErrors;
  }
  if (data.type && !project?.types.includes(data.type))
    tempErrors.push(`Unit type is not within valid project types.`);
  if (project.startPrice !== 0 && data.price && data.price < project.startPrice)
    tempErrors.push(`Project starting price is $${project.startPrice}`);
  // if (project.endPrice !== 0 && data.price && data.price > project.endPrice)
  // tempErrors.push(`Project highest price is $${project.endPrice}`);
  if (project.minBeds !== 0 && data.beds < project.minBeds)
    tempErrors.push(`Project min bedrooms is ${project.minBeds}`);
  if (project.maxBeds !== 0 && data.beds > project.maxBeds)
    tempErrors.push(`Project max bedrooms is ${project.maxBeds}`);
  if (project.minBaths !== 0 && data.baths < project.minBaths)
    tempErrors.push(`Project min bathrooms is ${project.minBaths}`);
  if (project.maxBaths !== 0 && data.baths > project.maxBaths)
    tempErrors.push(`Project max bathrooms is ${project.maxBaths}`);
  if (data.beds === null || data.beds === undefined)
    tempErrors.push("Number of bedrooms is required.");
  if (!data.baths) tempErrors.push("Number of bathroom is required.");
  if (!data.project) tempErrors.push("Project is required.");
  // if (!data.sqft) tempErrors.push("Sq.ft. is required.");
  // if (!data.price) tempErrors.push("Price is required.");
  // if (!data.parkings) tempErrors.push("Number of parkings is required.");
  // if (!data.lockers) tempErrors.push("Number of lockers is required.");
  // if (!data.pricePerSqft) tempErrors.push("Price per sq.ft. is required.");
  // if (!data.available)
  //   tempErrors.push("Number of available units is required.");
  // if (!data.sold) tempErrors.push("Number of sold units is required.");
  if (!data.type) tempErrors.push("Type is required.");

  data.media.forEach((m, i) => {
    if (!m.value) {
      tempErrors.push(`Media (#${i + 1}) value cannot be empty.`);
    } else {
      if (m.type === "video" && !validYouTubeUrl(m.value)) {
        tempErrors.push(`Invalid YouTube URL (#${i + 1}).`);
      }
      if (m.type === "image" && !validURL(m.value)) {
        tempErrors.push(`Invalid media URL (#${i + 1}).`);
      }
    }
  });

  return tempErrors;
};
