import { cloneElement } from "react";
import { Redirect } from "react-router-dom";
import withAuthentication from "../HOC/withAuthentication/with-authentication";
import withClient from "../HOC/withClient/with-client";

const ToursRoute = ({
  user,
  currentClient,
  agentComponent,
  clientComponent,
  ...rest
}) => {
  return user ? (
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "Agents"
    ) ? (
      cloneElement(agentComponent, { currentClient, user, ...rest })
    ) : (
      cloneElement(clientComponent, { currentClient, user, ...rest })
    )
  ) : (
    <Redirect to={"/"} />
  );
};

export default withAuthentication(withClient(ToursRoute));
