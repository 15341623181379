import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  IonIcon,
  IonButton,
  IonReorderGroup,
  IonItem,
  IonReorder,
  IonList,
  IonLoading,
  IonToast,
  useIonViewDidEnter,
} from "@ionic/react";
import {
  addOutline,
  eyeOutline,
  chevronForwardOutline,
  todayOutline,
  navigateOutline,
  arrowUndoOutline,
} from "ionicons/icons";
import styles from "./tour.module.scss";
import CardContainer from "../Tours/component/CardContainer/card-container.component";
import TourProperty from "./component/TourProperty/tour-property.component";
import AddShowing from "./component/AddShowing/add-showing.component";
import TourAttendees from "./component/TourAttendees/tour-attendees.component";
import TourDetails from "./component/TourDetails/tour-details.component";
import AgentDetails from "./component/AgentDetails/agent-details.component";
import QuickContact from "./component/QuickContact/quick-contact.component";
import { HashLink } from "react-router-hash-link";
import LoadingFullPage from "../../components/Loading/loading-full-page.component";
import withAuthentication from "../../HOC/withAuthentication/with-authentication";
import { API, graphqlOperation } from "aws-amplify";
import {
  onUpdateTourItemByTourId,
  onUpdateTourByTourId,
} from "../../graphql/subscriptions";
import { getCurrentLocation } from "../../components/Map/map-utils";
import { buildAddress } from "../../utils/functions";
import { TourService } from "../../services/tourService";
import { TourItemService } from "../../services/tourItemService";

const Tour = ({
  match: {
    params: { tourId },
  },
}) => {
  const history = useHistory();
  const [showAttendees, setShowAttendees] = useState(false);
  const [showTourDetails, setShowTourDetails] = useState(false);
  const [showAgentDetails, setShowAgentDetails] = useState(false);
  const [showQuickContact, setShowQuickContact] = useState(false);
  const [tour, setTour] = useState();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState({ type: undefined, text: "" });
  const [showToast, setShowToast] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [originalSort, setOriginalSort] = useState([]);
  const [listings, setListings] = useState([]);

  const sortItems = async (updatedItems) => {
    await updatedItems.forEach(async (item, i) => {
      try {
        setLoading(true);
        await TourItemService.updateTourItem({ id: item.id, order: i + 1 });
      } catch (err) {
        setMsg({ type: "error", text: "Something went wrong!" });
        setShowToast(true);
      } finally {
        setLoading(false);
      }
    });
  };
  const reorder = async ({ detail: { complete } }) => {
    const updatedItems = complete(items);
    setItems(updatedItems);
    sortItems(updatedItems);
  };

  const fetchTour = useCallback(async () => {
    let subscription;
    try {
      setLoading(true);
      const tourItem = await TourService.getTourById(tourId);
      if (!tourItem) {
        history.replace("/tours");
        return;
      }

      setTour(tourItem);

      if (subscription) subscription.unsubscribe();
      subscription = await API.graphql(
        graphqlOperation(onUpdateTourByTourId, { id: tourItem.id })
      ).subscribe({ next: async () => fetchTour() });
      
      setItems(
        tourItem.tourItems.items.sort((a, b) => {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        })
      );

      if (!originalSort) {
        const order = [];
        tourItem.tourItems.items.forEach((item) =>
          order.push({ id: item.id, order: item.order })
        );
        setOriginalSort(order);
      }
    } catch (err) {
      console.log(err);
      setMsg({ type: "error", text: "Something went wrong!" });
      setShowToast(true);
    } finally {
      setLoading(false);
    }

    return () => subscription && subscription.unsubscribe();
  }, [history, originalSort, tourId]);

  useEffect(() => {
    fetchTour();
  }, [fetchTour, tourId]);

  useIonViewDidEnter(() => {
    fetchTour();
  });

  const updateAttendees = async ({
    client,
    guests,
    leadAgent,
    showingAgents,
  }) => {
    setLoading(true);
    try {
      const tour = await TourService.updateTourUsers(tourId, [
        { role: "client", id: client.id },
        {
          role: "primaryagent",
          id: leadAgent.id,
        },
        ...showingAgents.map((a) => ({
          role: "showingagent",
          id: a.id,
        })),
        ...guests.map((g) => ({
          role: "guest",
          id: g.id,
        })),
      ]);

      setTour(tour);
      setMsg({ type: "success", text: "Tour saved successfully." });
      setShowToast(true);
    } catch (err) {
      setMsg({ type: "error", text: "Something went wrong!" });
      setShowToast(true);
    }
    setLoading(false);
  };

  const updateTourItem = (tourItem) => {
    const updatedItems = items.map((item) =>
      item.id === tourItem.id ? tourItem : item
    );
    setItems(updatedItems);
  };

  const updateTourDetails = async (updates) => {
    setLoading(true);
    try {
      const updatedTour = await TourService.updateTour({
        id: tour.id,
        ...updates,
      });
      setTour(updatedTour);
      setMsg({ type: "success", text: "Tour saved successfully." });
      setShowToast(true);
    } catch (err) {
      setMsg({ type: "error", text: "Something went wrong!" });
      setShowToast(true);
    }
    setLoading(false);
  };

  const revertOrder = async () => {
    if (originalSort && originalSort.length) {
      for (let i = 0; i < originalSort.length; i++) {
        await TourItemService.updateTourItem({
          id: originalSort[i].id,
          order: originalSort[i].order,
        });
      }
      setLoading(true);
      await fetchTour(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    let subscription;
    const subscribeItems = async () => {
      subscription = await API.graphql(
        graphqlOperation(onUpdateTourItemByTourId, { tourId })
      ).subscribe({
        next: async () => {
          const tour = await TourService.getTourById(tourId);
          const tourItems = tour.tourItems.items;
          setItems(
            tourItems.sort((a, b) => {
              if (a.order > b.order) return 1;
              if (a.order < b.order) return -1;
              return 0;
            })
          );
        },
      });
    };

    if (tour && items && items.length) {
      subscribeItems();
      if (originalSort.length !== items.length) {
        const order = [];
        tour.tourItems.items.forEach((item) =>
          order.push({ id: item.id, order: item.order })
        );
        setOriginalSort(order);
      }
    }

    return () => subscription && subscription.unsubscribe();
  }, [items, originalSort.length, tour, tourId]);

  const handleMapView = async () => {
    if (addresses.length) {
      const currentLocation = await getCurrentLocation();
      let locations = "";
      if (currentLocation)
        locations += `${currentLocation.latitude},${currentLocation.longitude}/`;

      if (tour.meetupLocation) locations += `${tour.meetupLocation}/`;
      addresses.forEach((item) => {
        locations += `${buildAddress(item.address, true)}/`;
      });

      window.open(
        `https://www.google.com/maps/dir/${locations.slice(
          0,
          locations.length
        )}&dirflg=d,t`,
        "_blank"
      );
    }
  };

  const addAddress = ({ address, mlsNumber }) => {
    if (!addresses.find((a) => a.mlsNumber === mlsNumber))
      setAddresses((old) => [...old, { mlsNumber, address }]);
  };

  const tourAttendeesAreComplete = () => {
    if (tour) {
      const attendees = tour.users.items;
      const client = attendees.find((a) => a.role === "client");
      const agent = attendees.find((a) => a.role === "primaryagent");
      const showingAgents = attendees.filter((a) => a.role === "showingagent");
      return client && agent && showingAgents.length > 0;
    }
    return false;
  };

  return (
    <div className={styles.tour}>
      <IonLoading isOpen={loading} />
      <IonToast
        position="top"
        cssClass={msg.type === "error" ? styles.error : styles.success}
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={msg.text}
        buttons={[
          {
            side: "end",
            icon: "close",
            handler: () => setShowToast(false),
          },
        ]}
        duration={4000}
      />
      <div className={styles.nav}>
        <div onClick={() => history.push("/tours")} className={styles.btn}>
          All tours
        </div>
        <div className={styles.icon}>
          <IonIcon icon={chevronForwardOutline} />
        </div>
        <div className={styles.bold}>{tour && tour.title}</div>
      </div>
      <div>
        {tour ? (
          tourAttendeesAreComplete() ? (
            <>
              <CardContainer>
                <div className={styles.header}>
                  <div className={styles.firstColumn}>
                    <div className={styles.icon}>
                      <IonIcon icon={todayOutline} />
                    </div>
                    <div className={styles.title}>Tour schedule</div>
                    <div className={styles.tag}>
                      <IonIcon icon={eyeOutline} className={styles.icon} />
                      <span>visible to client</span>
                    </div>
                  </div>
                  <div className={styles.secondColumn}>
                    <IonButton
                      className={`${styles.button} ${styles.directionsBtn}`}
                      disabled={!addresses.length}
                      onClick={handleMapView}>
                      <IonIcon icon={navigateOutline} />
                      Full directions
                    </IonButton>
                    <HashLink to={`/tours/${tourId}#add-showing`}>
                      <IonButton
                        className={`${styles.button} ${styles.showingBtn}`}
                        disabled={
                          !tour || !tour.status || tour.status === "archived"
                        }>
                        <IonIcon icon={addOutline} />
                        Add a showing
                      </IonButton>
                    </HashLink>
                  </div>
                </div>
                <div className={styles.items}>
                  <IonList className={styles.reorder}>
                    {!tour ? (
                      <LoadingFullPage />
                    ) : (
                      <IonReorderGroup
                        disabled={!tour.status || tour.status === "archived"}
                        onIonItemReorder={reorder}>
                        {items.length > 0 &&
                          items.map((item, i) => (
                            <IonItem
                              key={item.id}
                              className={styles.item}
                              lines="none">
                              <TourProperty
                                setListings={setListings}
                                item={item}
                                order={item.order}
                                onUpdate={updateTourItem}
                                addAddress={addAddress}
                              />
                              <IonReorder slot="start" />
                            </IonItem>
                          ))}
                      </IonReorderGroup>
                    )}
                  </IonList>
                </div>
                <div>
                  {originalSort && items && items.length > 0 && (
                    <div className={styles.revertItem} onClick={revertOrder}>
                      <IonIcon
                        className={styles.revertIcon}
                        icon={arrowUndoOutline}
                        slot="start"
                      />
                      <div className={styles.revertLabel}>
                        Revert showings to original order
                      </div>
                    </div>
                  )}
                </div>
              </CardContainer>
              <div className={styles.options}>
                <div className={styles.left}>
                  <AddShowing
                    tour={tour}
                    updateTourItems={(showings) =>
                      setItems([...items, ...showings])
                    }
                    nextListingOrder={tour && items && items.length + 1}
                  />
                </div>
                <div className={styles.right}>
                  <TourAttendees
                    isOpen={showAttendees}
                    tour={tour}
                    setIsOpen={setShowAttendees}
                    update={updateAttendees}
                  />
                  <TourDetails
                    isOpen={showTourDetails}
                    setIsOpen={setShowTourDetails}
                    tour={tour}
                    update={updateTourDetails}
                  />
                  <AgentDetails
                    isOpen={showAgentDetails}
                    setIsOpen={setShowAgentDetails}
                    tour={tour}
                    setTour={setTour}
                    update={updateTourDetails}
                  />
                  <QuickContact
                    isOpen={showQuickContact}
                    setIsOpen={setShowQuickContact}
                    tour={tour}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={styles.incompleteTour}>
              <TourAttendees
                isOpen={true}
                tour={tour}
                setIsOpen={setShowAttendees}
                update={updateAttendees}
              />
              <div className={styles.title}>
                Select tour attendees to continue
              </div>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};
export default withAuthentication(Tour);
