import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import styles from "./map.module.scss";
import {
  renderDetailsMarker,
  renderMapClusters,
} from "./components/Clusters/clusters.render.js";
import { styles as googleMapStyles } from "./google-map.styles";
import ZoomButton from "./components/ZoomButton/zoom-button.component";
import Marker from "./components/Marker/marker.component";
import { ReactComponent as LocationPin } from "../../assets/svg/location-pin.svg";
import { ReactComponent as SchoolPin } from "../../assets/svg/schoolpin.svg";
import MapListingCard from "./components/MapListingCard/map-listing.card.component";
import { getCurrentLocation } from "./map-utils";
import Button from "../Form/Button/button.component";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import {
  selectMapFavourites,
  selectActiveListing,
  selectDetailsListing,
  selectMapLoading,
} from "../../redux/map/map.selectors";
import {
  toggleFavourites,
  updateActiveListing,
  updateBoundaries,
  updateDetailsListing,
} from "../../redux/map/map.actions";
import { selectDrawMode } from "../../redux/ui/ui.selectors";
import { openModal, updateDrawMode } from "../../redux/ui/ui.actions";
import withFilters from "../../HOC/withFilters/with-filters";
import { selectCurrentClient } from "../../redux/client/client.selectors";
import { selectCurrencyRates } from "../../redux/currency/currency.selectors";
import MapSchoolCard from "./components/MapSchoolCard/map-school-card.component";
import {
  addSchoolBoundary,
  removeSchoolBoundary,
  toggleFilterListings,
  updateSelectedSchool,
} from "../../redux/schools/schools.actions";
import MobileCard from "../MobileCard/mobile-card.component";
import {
  IonIcon,
  IonSpinner,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { caretDownOutline, caretUpOutline, closeOutline } from "ionicons/icons";
import AppMapControls from "./components/MapControls/app-map-controles.component";
import AppSchoolCard from "./components/AppSchoolCard/app-school-card-component";
import { hapticsImpact } from "../../utils/functions";
import { selectIsPreConstruction } from "../../redux/filters/filters.selectors";
import MapButtonsBottom from "../shared/MapButtons/MapButtonsBottom/map-buttons-bottom.component";
import MapButtonsTop from "../shared/MapButtons/MapButtonsTop/map-buttons-top.component";

const MapContainer = ({
  isApp = false,
  clusters,
  selectedCluster,
  onSelectCluster,
  selectedListings,
  onSelectListings,
  onDeselectClusterAndListings,
  filters,
  extraFilters,
  updateSelectedLocation,
  mapLocations,
  updateBoundaryFilter,
  schools: {
    active: schoolsActive,
    selected: selectedSchool,
    schools,
    filters: schoolsFilters,
    filterListings: schoolsFilterListings,
  },
  view,
  setView,
  handleModals,
  onMapReady,
}) => {
  const dispatch = useDispatch();
  const [mapType, setMapType] = useState("roadmap");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const loading = useSelector(selectMapLoading);
  const favouritesOnly = useSelector(selectMapFavourites);
  const activeListing = useSelector(selectActiveListing);
  const detailsListing = useSelector(selectDetailsListing);
  const client = useSelector(selectCurrentClient);
  const rates = useSelector(selectCurrencyRates);
  const drawMode = useSelector(selectDrawMode);
  const isPreConstruction = useSelector(selectIsPreConstruction);
  const containerRef = useRef();
  const mapRef = useRef();
  const mapsRef = useRef();
  const selectedSchoolsRef = useRef(schoolsFilterListings);
  const drawingManagerRef = useRef();
  const drawingRef = useRef([]);
  const polygonsRef = useRef([]);
  const schoolPolygonRef = useRef([]);
  const selectedSchoolPolygonRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(14);
  const [center, setCenter] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [points, setPoints] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [isCentered, setIsCentered] = useState(false);
  const [showScroll, setShowScroll] = useState({ top: false, bottom: false });

  const currentLocationHandler = useCallback(
    async (initial = false) => {
      if (initial && detailsListing) {
        return;
      }
      const location = await getCurrentLocation();
      if (location) {
        setCurrentLocation(location);
        mapRef.current.setCenter({
          lat: location.latitude,
          lng: location.longitude,
        });
        mapRef.current.setZoom(14);
      }
    },
    [detailsListing]
  );

  const drawHandler = () =>
    dispatch(
      updateDrawMode(
        drawMode.active
          ? { active: false, isSchool: false, ref: null }
          : { active: true }
      )
    );

  const mapTypeHandler = () => {
    mapType === "roadmap" ? setMapType("satellite") : setMapType("roadmap");
  };

  const schoolsHandler = () => {
    dispatch(openModal({ current: "schools" }));
  };

  // Handlers

  const zoomInHandler = () => mapRef.current.setZoom(zoom + 1);
  const zoomOutHandler = () => mapRef.current.setZoom(zoom - 1);

  const onMapReadyHandler = async (map, maps) => {
    mapRef.current = map;
    mapsRef.current = maps;
    map.setMapTypeId(mapType);
    drawingManagerRef.current = new maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: false,
    });
    drawingManagerRef.current.setMap(map);
    displaySelectedListing();
    onMapReady(mapRef.current);

    if (localStorage.getItem("location") === "active") {
      try {
        await currentLocationHandler(true);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onChangeHandler = ({ zoom: newZoom, bounds: newBounds }) => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      setCenter({ lat: center.lat(), lng: center.lng() });
      if (currentLocation) {
        if (
          currentLocation.latitude === center.lat() &&
          currentLocation.longitude === center.lng()
        ) {
          setIsCentered(true);
        } else {
          setIsCentered(false);
        }
      }
    }

    setBounds(newBounds);
    setZoom(newZoom);
    if (newZoom !== zoom) {
      onDeselectClusterAndListings();
      if (detailsListing) {
        dispatch(
          updateDetailsListing({ listing: detailsListing.listing, view: false })
        );
      }
    }

    if (
      !drawingRef.current.length &&
      !schoolsFilterListings.active &&
      !drawMode.active
    )
      updateBoundaryFilter({
        map: [
          [
            [newBounds.sw.lng, newBounds.ne.lat],
            [newBounds.ne.lng, newBounds.ne.lat],
            [newBounds.ne.lng, newBounds.sw.lat],
            [newBounds.sw.lng, newBounds.sw.lat],
            [newBounds.sw.lng, newBounds.ne.lat],
          ],
        ],
      });

    dispatch(
      updateBoundaries([
        [
          [newBounds.sw.lng, newBounds.ne.lat],
          [newBounds.ne.lng, newBounds.ne.lat],
          [newBounds.ne.lng, newBounds.sw.lat],
          [newBounds.sw.lng, newBounds.sw.lat],
          [newBounds.sw.lng, newBounds.ne.lat],
        ],
      ])
    );
  };

  const drawCompleteHandler = useCallback(
    (poly) => {
      if (drawingRef.current.length) {
        drawingRef.current.forEach((p) => p.polygon.setMap(null));
        drawingRef.current.push({ polygon: poly });
      } else {
        drawingRef.current.push({ polygon: poly });
      }

      drawingManagerRef.current.setDrawingMode(null);
      const bounds = [];
      const paths = [];
      const vertices = poly.getPath();

      dispatch(updateDrawMode({ ref: drawingRef }));
      if (vertices) {
        for (let i = 0; i < vertices.getLength(); i++) {
          const xy = vertices.getAt(i);
          bounds.push([xy.lng(), xy.lat()]);
          paths.push({ lat: xy.lat(), lng: xy.lng() });
        }
        updateBoundaryFilter({ map: [bounds] });
      }
      enable();
    },
    [dispatch, updateBoundaryFilter]
  );

  const drawFreeHandHandler = useCallback(() => {
    const maps = mapsRef.current;
    const map = mapRef.current;
    let poly = new maps.Polyline({
      map: map,
      clickable: false,
      strokeColor: mapType === "satellite" ? "#dbdbdb" : "#6e6e6e",
      strokeWeight: 2,
      zIndex: 10,
    });

    const mouseMove = maps.event.addListener(map, "mousemove", (e) => {
      poly.getPath().push(e.latLng);
    });

    const touchMove = maps.event.addListener(map, "touchmove", (e) => {
      poly.getPath().push(e.latLng);
    });

    maps.event.addListenerOnce(map, "mouseup", () => {
      maps.event.removeListener(mouseMove);
      const path = poly.getPath();

      poly.setMap(null);
      poly = new maps.Polygon({
        map: map,
        paths: path,
        clickable: false,
        strokeColor: mapType === "satellite" ? "#dbdbdb" : "#6e6e6e",
        strokeWeight: 2,
        fillColor: mapType === "satellite" ? "#6e6e6e" : "#dbdbdb",
        fillOpacity: 0.6,
        zIndex: 10,
      });
      drawCompleteHandler(poly);
    });

    maps.event.addListenerOnce(map, "touchend", () => {
      maps.event.removeListener(touchMove);
      const path = poly.getPath();
      poly.setMap(null);
      poly = new maps.Polygon({
        map: map,
        paths: path,
        clickable: false,
        strokeColor: mapType === "satellite" ? "#dbdbdb" : "#6e6e6e",
        strokeWeight: 2,
        fillColor: mapType === "satellite" ? "#6e6e6e" : "#dbdbdb",
        fillOpacity: 0.6,
        zIndex: 10,
      });
      drawCompleteHandler(poly);
    });
  }, [drawCompleteHandler, mapType]);

  const handleSchoolCardToggle = async () => {
    await hapticsImpact();

    if (schoolsFilterListings.schools.length) {
      const foundSchool = schoolsFilterListings.schools.find(
        (s) => s.id === selectedSchool.id
      );

      if (foundSchool) {
        dispatch(removeSchoolBoundary(selectedSchool.id));
        if (schoolsFilterListings.schools.length === 1) {
          dispatch(toggleFilterListings());
        }
      } else {
        dispatch(updateDrawMode({ active: false, isSchool: false, ref: null }));
        dispatch(addSchoolBoundary(selectedSchool));
      }
    } else {
      dispatch(updateDrawMode({ active: false, isSchool: false, ref: null }));
      dispatch(toggleFilterListings(selectedSchool));
    }
    dispatch(updateSelectedSchool(null));
  };

  // Functions

  const cancelFavouritesHandler = () => {
    dispatch(toggleFavourites(false));
  };

  const resetSelectedLocation = (location) => {
    updateSelectedLocation(location ?? null);
    if (detailsListing) {
      dispatch(
        updateDetailsListing({ listing: detailsListing.listing, view: false })
      );
    }
  };

  const displaySelectedListing = useCallback(() => {
    if (detailsListing) {
      if (mapRef.current) {
        mapRef.current.setCenter({
          lat: +detailsListing.listing.map.latitude,
          lng: +detailsListing.listing.map.longitude,
        });
      }
    }
  }, [detailsListing]);

  const disable = () => {
    mapRef.current &&
      mapRef.current.setOptions({
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: false,
        gestureHandling: "none",
        disableDefaultUI: true,
        clickableIcons: false,
      });
  };

  const enable = () => {
    mapRef.current.setOptions({
      draggable: true,
      zoomControl: false,
      scrollwheel: true,
      disableDoubleClickZoom: false,
      gestureHandling: "auto",
      disableDefaultUI: false,
      clickableIcons: false,
    });
  };

  const calculateCardLocation = (type) => {
    const cardWith = type === "listing" ? 350 : 320;
    const cardHeight = type === "listing" ? 200 : 350;
    const position = {
      top: undefined,
      bottom: undefined,
      left: undefined,
      right: undefined,
      transform: undefined,
    };
    try {
      const mapWidth = mapRef.current.getDiv().offsetWidth;
      const mapHeight = mapRef.current.getDiv().offsetHeight;
      const overlay = new mapsRef.current.OverlayView();
      overlay.draw = function () {};
      overlay.setMap(mapRef.current);
      const { x, y } = overlay
        .getProjection()
        .fromLatLngToContainerPixel(
          new mapsRef.current.LatLng(
            type === "listing"
              ? selectedListings[0]?.map.latitude
              : selectedSchool.latitude,
            type === "listing"
              ? selectedListings[0]?.map.longitude
              : selectedSchool.longitude
          )
        );
      if (
        x > cardWith / 2 &&
        mapWidth - x > cardWith / 2 &&
        mapHeight - y > cardHeight
      ) {
        position.top = "4rem";
        position.right = "50%";
        position.transform = "translateX(50%)";
      } else if (
        x > cardWith / 2 &&
        mapWidth - x > cardWith / 2 &&
        y > cardHeight
      ) {
        position.bottom = "2rem";
        position.right = "50%";
        position.transform = "translateX(50%)";
      } else if (
        x > cardWith / 2 &&
        mapHeight - y > cardHeight / 2 &&
        y > cardHeight / 2
      ) {
        position.right = "2rem";
        position.top = "50%";
        position.transform = "translateY(-50%)";
      } else if (y > cardHeight / 2 && mapHeight - y > cardHeight) {
        position.left = "5rem";
        position.top = "50%";
        position.transform = "translateY(-50%)";
      } else if (y > cardHeight / 2) {
        position.left = "5rem";
        position.top = "75%";
        position.transform = "translateY(-50%)";
      } else if (mapWidth - x > cardWith) {
        position.left = "5rem";
      } else {
        position.right = "2rem";
      }
    } catch (err) {
      console.log(err);
    }

    return position;
  };

  const renderAppCards = () => {
    if (!selectedCluster && selectedListings.length === 0) {
      return;
    }

    return (
      <MobileCard
        listings={selectedListings}
        onCollapse={() =>
          setTimeout(() => onDeselectClusterAndListings(), [500])
        }
        header={
          selectedListings.length > 0 && (
            <>
              {selectedListings.length}{" "}
              {selectedListings.length > 1 ? "listings" : "listing"}
            </>
          )
        }
        headerType="count"
      />
    );
  };

  const renderCards = () => {
    const cards = selectedListings.map((listing) => (
      <MapListingCard
        listing={listing}
        key={listing.mlsNumber}
        isPre={!listing.mlsNumber}
      />
    ));

    return (
      <div
        className={styles.cards}
        style={calculateCardLocation("listing")}
        onMouseOver={() => {
          disable();
          if (cards && cards.length > 1 && !showScroll.top)
            setShowScroll({ ...showScroll, bottom: true });
        }}
        onMouseLeave={() => enable()}
        onTouchStart={() => disable()}
        onTouchEnd={() => enable()}
        onScroll={(e) => {
          let bottom = false;
          let top = false;
          if (
            e.target.scrollTop + e.target.offsetHeight <=
            e.target.scrollHeight
          ) {
            bottom = true;
          }
          if (cards && cards.length > 1 && e.target.scrollTop > 0) {
            top = true;
          }

          setShowScroll({ top, bottom });
        }}>
        <div
          className={`${styles.scroll} ${styles.scrollBottom} ${
            showScroll.bottom && styles.visible
          } ${cards.length === 1 && styles.hide}`}>
          <IonIcon
            icon={caretDownOutline}
            onClick={() => {
              const elements = document.getElementsByClassName(styles.cards);
              elements[0]?.scrollTo({
                top: elements[0]?.scrollTop + 158,
                behavior: "smooth",
              });
            }}
          />
        </div>

        <div
          className={`${styles.scroll} ${styles.scrollTop} ${
            showScroll.top && styles.visible
          } ${cards.length === 1 && styles.hide}`}>
          <IonIcon
            icon={caretUpOutline}
            onClick={() => {
              const elements = document.getElementsByClassName(styles.cards);
              elements[0]?.scrollTo({
                top: elements[0]?.scrollTop - 158,
                behavior: "smooth",
              });
            }}
          />
        </div>
        {cards}
      </div>
    );
  };

  const handleSchoolClick = (e, school) => {
    if (!isApp) {
      const map = mapRef.current;
      const mapBounds = containerRef.current.getBoundingClientRect();
      const mapXStart = mapBounds.left;
      const mapXEnd = mapBounds.right;
      const mapYStart = mapBounds.top;
      const mapYEnd = mapBounds.bottom;

      map.panTo({
        lat: school.latitude - 0.01,
        lng: school.longitude,
      });
      if (
        e.clientX + 150 > mapXEnd ||
        e.clientX < mapXStart + 200 ||
        e.clientY + 150 > mapYEnd
      ) {
        map.panTo({
          lat: school.latitude - 0.01,
          lng: school.longitude,
        });
      }
    }

    onDeselectClusterAndListings();
    dispatch(updateSelectedSchool(school));
  };

  const renderSchoolCard = () => {
    return (
      <div
        className={styles.schoolCard}
        style={calculateCardLocation("school")}>
        <MapSchoolCard
          school={selectedSchool}
          active={schoolsFilterListings.schools.find(
            (s) => s?.id === selectedSchool.id
          )}
          onToggle={handleSchoolCardToggle}
          isDisabled={schoolsFilterListings.schools.length >= 10}
        />
      </div>
    );
  };

  // useEffects
  useIonViewDidLeave(() => {
    dispatch(updateDetailsListing(null));
  });

  useEffect(() => {
    if (detailsListing && detailsListing.view) {
      displaySelectedListing();
    }
  }, [detailsListing, displaySelectedListing]);

  // useEffect(() => {
  //   if (!selectedLocation) setShowScroll({ top: false, bottom: false });
  // }, [selectedLocation]);

  useEffect(() => {
    if (!loading && !clusters.length) {
      setNoResults(true);
    } else {
      setNoResults(false);
    }
  }, [clusters, loading]);

  useEffect(() => {
    if (mapRef.current) mapRef.current.setMapTypeId(mapType);
  }, [mapType]);

  useEffect(() => {
    const { center, coordinates } = mapLocations;
    const map = mapRef.current;
    const maps = mapsRef.current;
    if (center && map) map.panTo({ lat: center.lat, lng: center.lng });

    if (coordinates && maps) {
      if (polygonsRef.current) {
        polygonsRef.current.forEach((p) => p.polygon.setMap(null));
        polygonsRef.current = [];
      }

      coordinates.forEach((loc) => {
        const shapeCoords = loc.coords.map((c) => {
          return c.map((coord) => {
            return { lat: coord[1], lng: coord[0] };
          });
        });

        const polygon = new maps.Polygon({
          paths: shapeCoords,
          map: map,
          clickable: false,
          strokeColor: mapType === "satellite" ? "#dbdbdb" : "#6e6e6e",
          strokeWeight: 2,
          fillColor: mapType === "satellite" ? "#6e6e6e" : "#dbdbdb",
          fillOpacity: 0.5,
          zIndex: 10,
          strokeOpacity: 0.6,
          draggable: false,
          editable: false,
          visible: true,
        });
        polygonsRef.current.push({
          polygon,
          name: loc.neighborhood,
        });
      });
    } else if (polygonsRef.current) {
      polygonsRef.current.forEach((p) => p.polygon.setMap(null));
      polygonsRef.current = [];
    }
  }, [mapLocations, mapType]);

  const showSchoolBoundary = useCallback(() => {
    if (schoolPolygonRef.current) {
      schoolPolygonRef.current.forEach((p) => p.polygon.setMap(null));
      schoolPolygonRef.current = [];
    }
    const maps = mapsRef.current;
    const map = mapRef.current;

    if (maps && schoolsFilterListings.schools.length) {
      schoolsFilterListings.schools.forEach((school) => {
        const boundary = JSON.parse(JSON.parse(school.boundaryArray)).Points;

        let points = [];
        for (let i = 0; i < boundary.length; i += 2) {
          points.push(boundary.slice(i, i + 2));
        }

        const bounds = new maps.LatLngBounds();
        const shapeCoords = points.map((loc) => {
          return { lat: loc[0], lng: loc[1] };
        });

        for (let i = 0; i < shapeCoords.length; i++) {
          bounds.extend(shapeCoords[i]);
        }

        const polygon = new maps.Polygon({
          map: map,
          paths: shapeCoords,
          clickable: true,
          strokeColor: school.strokeColor,
          strokeWeight: 2,
          fillColor: school.fillColor,
          fillOpacity: 0.6,
          zIndex: 10,
        });
        maps.event.addListener(polygon, "mouseover", function () {
          this.setOptions({
            fillColor: school.hoverFillColor,
            strokeColor: school.hoverStrokeColor,
            fillOpacity: "0.8",
            zIndex: 11,
          });
          const pin = document.getElementById(school.id);
          const foundSchool = selectedSchoolsRef.current?.schools?.find(
            (s) => s.id === school.id
          );
          if (pin && foundSchool) {
            pin.style.fill = school.hoverPinFillColor;
            pin.style.stroke = school.hoverPinStrokeColor;
          }
        });

        maps.event.addListener(polygon, "mouseout", function () {
          this.setOptions({
            fillColor: school.fillColor,
            strokeColor: school.strokeColor,
            fillOpacity: "0.6",
            zIndex: 10,
          });
          const pin = document.getElementById(school.id);

          const foundSchool = selectedSchoolsRef.current?.schools?.find(
            (s) => s.id === school.id
          );

          if (pin && foundSchool) {
            pin.style.fill = school.fillColor;
            pin.style.stroke = school.strokeColor;
          }
        });

        schoolPolygonRef.current.push({
          polygon,
          name: school.name,
          id: school.id,
        });
      });
    } else if (schoolPolygonRef.current) {
      schoolPolygonRef.current.forEach((p) => p.polygon.setMap(null));
      schoolPolygonRef.current = [];
    }
  }, [schoolsFilterListings]);

  useEffect(() => {
    selectedSchoolsRef.current = schoolsFilterListings;
  }, [schoolsFilterListings]);

  useEffect(() => {
    const maps = mapsRef.current;
    const map = mapRef.current;
    if (maps && selectedSchool) {
      if (selectedSchoolPolygonRef.current) {
        selectedSchoolPolygonRef.current.polygon.setMap(null);
        selectedSchoolPolygonRef.current = null;
      }
      try {
        const boundary = JSON.parse(
          JSON.parse(selectedSchool.boundaryArray)
        )?.Points;

        let points = [];
        for (let i = 0; i < boundary.length; i += 2) {
          points.push(boundary.slice(i, i + 2));
        }

        const bounds = new maps.LatLngBounds();
        const shapeCoords = points.map((loc) => {
          return { lat: loc[0], lng: loc[1] };
        });

        for (let i = 0; i < shapeCoords.length; i++) {
          bounds.extend(shapeCoords[i]);
        }

        const polygon = new maps.Polygon({
          map: map,
          paths: shapeCoords,
          clickable: true,
          strokeColor: schoolsFilterListings.schools?.find(
            (s) => s.id === selectedSchool.id
          )
            ? "#c24100"
            : "#dff7f0",
          strokeWeight: 2,
          fillColor: schoolsFilterListings.schools?.find(
            (s) => s.id === selectedSchool.id
          )
            ? "#ffeee5"
            : "#1f7a60",
          fillOpacity: schoolsFilterListings.schools?.find(
            (s) => s.id === selectedSchool.id
          )
            ? 1
            : 0.6,
          zIndex: 12,
        });

        selectedSchoolPolygonRef.current = {
          polygon,
          name: selectedSchool.name,
          id: selectedSchool.id,
        };
      } catch (err) {
        console.log(err);
      }
    } else {
      if (selectedSchoolPolygonRef.current) {
        selectedSchoolPolygonRef.current.polygon.setMap(null);
        selectedSchoolPolygonRef.current = null;
      }
    }
  }, [schoolsFilterListings.schools, selectedSchool]);

  useIonViewDidEnter(() => {
    if (mapRef.current) {
      if (mapRef.current.zoom === 10 && filters.area)
        mapRef.current.setZoom(11);
    }
  }, [filters.area]);

  useEffect(() => {
    const maps = mapsRef.current;
    const map = mapRef.current;
    const drawingManager = drawingManagerRef.current;
    if (map && maps) {
      const mouseListener = () => {
        drawFreeHandHandler();
      };
      const touchListener = () => {
        drawFreeHandHandler();
      };
      if (drawMode.active) {
        if (
          filters.map &&
          filters.map[0] &&
          filters.map[0].length > 5 &&
          maps &&
          map
        ) {
          drawingRef.current.forEach((p) => p?.polygon?.setMap(null));
          drawingRef.current = [];
          filters.map.forEach((m) => {
            const bounds = new maps.LatLngBounds();
            const shapeCoords = m.map((loc) => {
              return { lat: loc[1], lng: loc[0] };
            });
            for (let i = 0; i < shapeCoords.length; i++) {
              bounds.extend(shapeCoords[i]);
            }

            const polygon = new maps.Polygon({
              map: map,
              paths: shapeCoords,
              clickable: false,
              strokeColor: drawMode.isSchool
                ? "#dff7f0"
                : mapType === "satellite"
                ? "#dbdbdb"
                : "#6e6e6e",
              strokeWeight: 2,
              fillColor: drawMode.isSchool
                ? "#1f7a60"
                : mapType === "satellite"
                ? "#6e6e6e"
                : "#dbdbdb",

              fillOpacity: 0.6,
              zIndex: 10,
            });
            drawingRef.current.push({
              polygon,
            });
          });
        } else {
          disable();
          if (maps && drawingManager) {
            maps.event.addDomListenerOnce(map, "mousedown", mouseListener);
            maps.event.addDomListenerOnce(map, "touchstart", touchListener);
            drawingManager.setDrawingMode(maps.drawing.OverlayType.POLYLINE);
          }
        }
      } else {
        if (drawingManager) {
          maps.event.clearListeners(map, "mousedown");
          maps.event.clearListeners(map, "touchstart");
          enable();
          drawingManager.setDrawingMode(null);
          if (drawingRef.current.length) {
            drawingRef.current.forEach((p) => p.polygon.setMap(null));
            drawingRef.current = [];

            updateBoundaryFilter({
              map: [
                [
                  [bounds.sw.lng, bounds.ne.lat],
                  [bounds.ne.lng, bounds.ne.lat],
                  [bounds.ne.lng, bounds.sw.lat],
                  [bounds.sw.lng, bounds.sw.lat],
                  [bounds.sw.lng, bounds.ne.lat],
                ],
              ],
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    drawFreeHandHandler,
    drawMode,
    filters.map,
    mapType,
    updateBoundaryFilter,
  ]);

  useEffect(() => {
    if (schoolsFilterListings.active && schoolsFilterListings.schools.length) {
      try {
        const map = [];
        schoolsFilterListings.schools.forEach((s) => {
          if (!s.boundaryArray || s.boundaryArray === "[]") return;
          const boundary = JSON.parse(JSON.parse(s.boundaryArray)).Points;
          let points = [];
          for (let i = 0; i < boundary.length; i += 2) {
            points.push(boundary.slice(i, i + 2).reverse());
          }
          map.push([...points]);
          updateBoundaryFilter({ map });
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      if (bounds)
        updateBoundaryFilter({
          map: [
            [
              [bounds.sw.lng, bounds.ne.lat],
              [bounds.ne.lng, bounds.ne.lat],
              [bounds.ne.lng, bounds.sw.lat],
              [bounds.sw.lng, bounds.sw.lat],
              [bounds.sw.lng, bounds.ne.lat],
            ],
          ],
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolsFilterListings]);

  useEffect(() => {
    if (!schoolsActive && bounds)
      updateBoundaryFilter({
        map: [
          [
            [bounds.sw.lng, bounds.ne.lat],
            [bounds.ne.lng, bounds.ne.lat],
            [bounds.ne.lng, bounds.sw.lat],
            [bounds.sw.lng, bounds.sw.lat],
            [bounds.sw.lng, bounds.ne.lat],
          ],
        ],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolsActive]);

  useEffect(() => {
    if (!selectedSchool) {
      if (schoolPolygonRef.current && !schoolsFilterListings.active)
        schoolPolygonRef.current.forEach((p) => p.polygon.setMap(null));
    } else {
      showSchoolBoundary();
    }
  }, [selectedSchool, showSchoolBoundary, schoolsFilterListings]);

  useEffect(() => {
    const maps = mapsRef.current;
    const map = mapRef.current;
    if (favouritesOnly && maps && map) {
      const bounds = new maps.LatLngBounds();
      const shapeCoords = points.map((loc) => {
        return {
          lat: loc.geometry.coordinates[1],
          lng: loc.geometry.coordinates[0],
        };
      });
      for (let i = 0; i < shapeCoords.length; i++) {
        bounds.extend(shapeCoords[i]);
      }
      map.fitBounds(bounds);
    }
  }, [favouritesOnly, points]);

  const calculateSchoolClass = (s) => {
    if (!selectedSchool) return "schoolPinDeactive";
    if (selectedSchool.id === s.id) {
      if (schoolsFilterListings.schools.find((school) => school.id === s.id)) {
        return "schoolPinSelected";
      } else {
        return "schoolPinActive";
      }
    }
    return "schoolPinDeactive";
  };
  return (
    <div className={`${styles.map}`} ref={containerRef}>
      {selectedSchool && isApp && (
        <AppSchoolCard
          school={selectedSchool}
          active={
            schoolsFilterListings.schools.find(
              (s) => s?.id === selectedSchool.id
            )
              ? true
              : false
          }
          onToggle={handleSchoolCardToggle}
          isDisabled={schoolsFilterListings.schools.length >= 10}
          onCollapse={() =>
            setTimeout(() => dispatch(updateSelectedSchool(null)), [500])
          }
        />
      )}
      {isApp && renderAppCards()}
      {/* {view === "list" && listings && !selectedLocation && isApp && (
        <MobileCard
          listings={listings}
          allListings={true}
          onCollapse={() => setTimeout(() => setView(), [500])}
          header={
            <>
              {listings.length} {listings.length > 1 ? "listings" : "listing"}
            </>
          }
          headerType="count"
        />
      )} */}
      {favouritesOnly && (
        <span className={`${styles.btnContainer} ${styles.favouritesBtn}`}>
          <Button
            type="gray"
            title="showing favourites only"
            onClick={cancelFavouritesHandler}
            style={{ width: "25rem", top: isApp ? "8rem" : "1.5rem" }}>
            <CloseIcon />
          </Button>
        </span>
      )}

      {!isApp && drawMode.active && drawMode.ref && (
        <span className={styles.btnContainer}>
          <Button
            type="gray"
            title="remove outline"
            onClick={() =>
              dispatch(
                updateDrawMode({ active: false, isSchool: false, ref: null })
              )
            }
            style={{ width: "30rem", top: isApp ? "8rem" : "1.5rem" }}>
            <CloseIcon />
          </Button>
        </span>
      )}

      {!isApp && schoolsActive && schoolsFilterListings.schools.length > 0 && (
        <span className={styles.btnContainer}>
          <Button
            type="darkGreenApp"
            title="clear outline"
            onClick={() => dispatch(toggleFilterListings())}
            style={{ width: "30rem", top: isApp ? "8rem" : "1.5rem" }}>
            <CloseIcon />
          </Button>
        </span>
      )}

      {!isApp && (
        <>
          <MapButtonsTop
            isDrawingDisabled={schoolsFilterListings.active || selectedSchool}
            mapType={mapType}
            onChangeMapTyoe={mapTypeHandler}
            onStartDrawing={drawHandler}
          />
          <MapButtonsBottom
            isCentered={isCentered}
            onLocate={() => currentLocationHandler(false)}
          />
        </>
      )}
      {isApp && (
        <>
          {noResults && (
            <div className={styles.noResults}>
              <p>No results found. Try zooming out or changing your filters.</p>
              <IonIcon
                className={styles.close}
                icon={closeOutline}
                onClick={() => setNoResults(false)}
              />
            </div>
          )}

          {!favouritesOnly && (
            <AppMapControls
              view={view}
              setView={setView}
              loading={loading}
              mapType={mapType}
              mapTypeHandler={mapTypeHandler}
              drawMode={drawMode}
              drawHandler={drawHandler}
              activeListing={activeListing}
              clearOutline={() =>
                dispatch(
                  updateDrawMode({ active: false, isSchool: false, ref: null })
                )
              }
              currentLocationHandler={() => currentLocationHandler(false)}
              status={filters.status}
              disableDrawing={schoolsFilterListings.active || selectedSchool}
              mapRef={mapRef.current}
              isCentered={isCentered}
              handleModals={handleModals}
            />
          )}
        </>
      )}

      {isApp && loading && (
        <span className={styles.loadingContainer}>
          <IonSpinner name="crescent" className={styles.loading} />
        </span>
      )}

      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
          libraries: ["drawing"].join(","),
        }}
        resetBoundsOnResize={true}
        options={{
          fullscreenControl: false,
          panControl: false,
          minZoom: 8,
          maxZoom: 20,
          clickableIcons: false,
          styles: googleMapStyles,
          keyboardShortcuts: false,
        }}
        zoom={zoom}
        defaultZoom={10}
        defaultCenter={{
          lat: 43.642567,
          lng: -79.387054,
        }}
        center={center && { lat: center.lat, lng: center.lng }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => onMapReadyHandler(map, maps)}
        onChange={onChangeHandler}
        onClick={({ event }) => {
          if (!event.target.className) {
            onDeselectClusterAndListings();
          }
          if (!event.target.className) dispatch(updateActiveListing(null));
          if (
            typeof event.target.className !== "object" &&
            selectedSchool &&
            !event.target.className.includes("toggle") &&
            !event.target.className.includes("map-school")
          ) {
            dispatch(updateSelectedSchool(null));
          }
        }}>
        {currentLocation && (
          <Marker
            lat={currentLocation.latitude}
            lng={currentLocation.longitude}>
            <LocationPin
              className={`${styles.locationPin} ${
                mapType === "satellite" ? styles.lightLocationPin : ""
              }`}
            />
          </Marker>
        )}

        {/* {detailsListing &&
          detailsListing.listing &&
          renderDetailsMarker(
            detailsListing.listing,
            client,
            isPreConstruction,
            isApp,
            () => dispatch(updateSelectedSchool(null)),
            rates,
            containerRef,
            (location) => resetSelectedLocation(location)
          )} */}
        {schoolsActive &&
          zoom > 10 &&
          schools &&
          schools.slice(0, zoom <= 12 ? 50 : zoom <= 14 ? 35 : 25).map((s) => (
            <Marker
              key={s.id + Math.floor(Math.random() + (1000 - 1))}
              lat={s.latitude}
              lng={s.longitude}>
              <SchoolPin
                id={s.id}
                onClick={(e) => handleSchoolClick(e, s)}
                className={`${styles[calculateSchoolClass(s)]} ${
                  styles.schoolPin
                } ${isApp && styles.schoolAnimatedPin}`}
                style={{
                  fill: schoolsFilterListings.schools.find(
                    (school) => school.id === s.id
                  )?.fillColor,
                  stroke:
                    schoolsFilterListings.schools.find(
                      (school) => school.id === s.id
                    ) && "white",
                }}
              />
            </Marker>
          ))}

        {selectedListings.length && !isApp && (
          <Marker
            zIndex={100000}
            lat={selectedListings[0].map.latitude}
            lng={selectedListings[0].map.longitude}>
            {renderCards()}
          </Marker>
        )}

        {selectedSchool && !isApp && (
          <Marker lat={selectedSchool.latitude} lng={selectedSchool.longitude}>
            {renderSchoolCard()}
          </Marker>
        )}

        {renderMapClusters({
          clusters,
          client,
          map: mapRef.current,
          rates,
          activeListing,
          onSelectCluster,
          onSelectListings,
          selectedCluster,
          selectedListings,
          isSatellite: mapType === "satellite",
          setSchool: () => dispatch(updateSelectedSchool(null)),
          isApp,
          isPre: isPreConstruction,
        })}
      </GoogleMapReact>
    </div>
  );
};

export default withFilters(MapContainer);
